import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";


import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
//get DispensingLanguage



const AdjustmentTypeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  Typography,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "store-entry";
  const PickListMethodList = JSON.parse(localStorage.getItem("gPickListMethod"));
  const FlevelListList = JSON.parse(localStorage.getItem("FlevelList"));
  const LinkAdjTypeList = JSON.parse(localStorage.getItem("LinkAdjTypeList"));

  const AStoreList = JSON.parse(localStorage.getItem("getStore"));
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Store - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Store Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>

                     

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.LanguageText}
                          helperText={errorObject.LanguageText}

                          id="LanguageText"
                          name="LanguageText"
                          label={DispensingLanguage[lan][menukey]['Store Name']}
                          value={formData.LanguageText}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Facility Level"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="FLevelIds"
                            value={formData.FLevelIds}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            {FlevelListList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FLevelIds}>
                            {errorObject.FLevelIds}
                          </FormHelperText>
                        </FormControl>
                      </Grid>


                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="RecordId"
                          name="RecordId"
                          label="RecordId"
                          value={formData.RecordId}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="TranslationId"
                          name="TranslationId"
                          label="TranslationId"
                          value={formData.TranslationId}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/store_entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/store_entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div >
  );
};

export default AdjustmentTypeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
