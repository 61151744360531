import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";

import FundingSourceFormData from "./FundingSourceFormData";




const AddFundingSource = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "funding-source-entry";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    FundingSourceName: "",
    FundingSourceDesc: ""
  });
  const [errorObject, setErrorObject] = useState({});
  const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();


  useQuery(
    ["fundingsource", id],
    () =>
      api.getFundingSource(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveFundingSource, {
    onSuccess: (data) => {
      if (data.status == 200) {
        // api.getAllDropdown('Unitofmeasurelist').then((response) => {
        //   localStorage.setItem(
        //     "Unitofmeasurelist",
        //     JSON.stringify(response.datalist.Unitofmeasurelist)
        //   );
        // }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       // swal(data.data.message, "", "success");
      queryClient.getQueriesData("fundingsource");//api page name
      props.history.push("/funding_source");//

      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    // console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      FundingSourceName: "",
    FundingSourceDesc: ""
      
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FundingSourceName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =   DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    
    if (validateForm(formData)) {

      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     
    }
  };

  return (
    <>
      <FundingSourceFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddFundingSource;

