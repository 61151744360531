import React, { useState ,useEffect } from "react";//, useEffect
import {
  Typography
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';

import * as api from '../../../actions/api';
import DispenseItems from './DispenseItems.js';



const Adddispense = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "dispense";
const TransactionTypeId = 4;
const FacilityId=localStorage.getItem('FacilityId');
const StoreId = localStorage.getItem("StoreId");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  
const [selectedDate, setSelectedDate] = useState(
  new Date()
);
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
  ''
); 
  const [formData, setFormData] = useState({
      TransactionDate: selectedDate,
      SupplierInvDate: selectedDate,
     
      TransactionNo: generatedInvoiceNo, 
      "FacilityId":FacilityId,
      TransactionTypeId:TransactionTypeId,
      PreparedBy:"",
      ApprovedBy:"",
      StoreId:StoreId,
      Remarks:""
  });

  const [ManyTableData, setManyTableData] = useState( []); 
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

   
  
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};

 const handleDateChange = (date) => {
  setSelectedDate(date);
}; 
 

  const [errorObject, setErrorObject] = useState({});
   
  const { id } = useParams();
  const queryClient = useQueryClient();

  let params={"menukey": menukey, "FacilityId":FacilityId, "TransactionTypeId":TransactionTypeId, TransactionDate: ''};
  const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, 
    {onSuccess: (data) => {
      
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData }; 
      fdata["TransactionNo"] = data; 
      setFormData(fdata); 

    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  }
    );
  
 
  /*
  const { isLoading, mutate } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  }) 
  */ 


  const handleAdd = (NonOrderProductData) => {
  
      setManyTableData(NonOrderProductData);
      setUpdatedDetailGridData(NonOrderProductData);
      

  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
   // console.log('formData: ', e.target);

      let data = { ...formData };
      
      data[name] = value; 
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      SupplierInvDate: selectedDate,
     
      TransactionNo:generatedInvoiceNo, 
      FacilityId:FacilityId,
      TransactionTypeId:TransactionTypeId, 
      
      Remarks:"",
      PreparedBy:"",
      ApprovedBy:"",
      StoreId:StoreId,
    });
    setManyTableData([]);  
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ApprovedBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  /*
  const handleSubmit = async (e) => {
    console.log("from submit ", 
    
    );
    if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':ManyTableData,"menukey":menukey,"lan":lan,"UserName":UserName};
      mutate(fDtat);
      
    }
  };

  */
  const deleteReceiveInv=(data)=>{
    
    let newarray = ManyTableData.filter(element => element !== data); 
    setManyTableData(newarray);
     
    

  }

  /*
  const setUpdatedDetailsGridDataHandler = (rows) => { 
    if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':rows,"menukey":menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat); 
      }

    setManyTableData(rows); 
  };

  */


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,"menukey":menukey,"lan":lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };


  
  useEffect(() => { 
    
     
      
    
  }, []); 




  return (
    <> 
      <DispenseItems 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange} 
      handleCheck={handleCheck} 
      handleReset={handleReset} 
      //handleSubmit={handleSubmit} 
      ManyTableData={updatedDetailGridData}   
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      deleteReceiveInv={deleteReceiveInv}
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      bStockUpdate={0}
      handleAdd={handleAdd} 
      generatedInvoiceNo={generatedInvoiceNo}
      checkValidateForm={checkValidateForm}
     
      {...props} />
      
      
    </>

    
  );
};

export default Adddispense;