import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import FacilityFormData from "./FacilityFormData.js";
import { useTranslation } from "react-i18next";
const AddFacility = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "facility";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

const [getFacilityCode, setFacilityCode] = useState('');

const [formData, setFormData] = useState({
FacilityCode: getFacilityCode,
FacilityName: "",
RegionId:"",
ZoneId:"",
DistrictId:"",
FTypeId:"",
FClassificationId:"",
FLevelId:"",
FacilityAddress:"",
FacilityPhone:"",
FacilityEmail:"",
FacilityInCharge:"",
ServiceAreaId:"",
OwnerTypeId:"",
OwnerId:"",
ExternalFacilityId:"",
SOBAPSCode:"",
GLN:"",
eSIGLFacilityid:"",
location:"",
bESIGL:""
});
const [errorObject, setErrorObject] = useState({});
const { id } = useParams();
const queryClient = useQueryClient();
const { t, i18n } = useTranslation();

let params={"menukey": "facility", action: "getFacilityCode"};
  const {  data:gFacilityCode, refetch } = useQuery([params], api.getFacilityCode, 
    {onSuccess: (data) => {
      
      setFacilityCode(data);
      let fdata = { ...formData }; 
      fdata["FacilityCode"] = data; 
      setFormData(fdata); 

    }}
    );


const { mutate } = useMutation(api.saveFacility, {
onSuccess: (data) => {
  if(data.status==200) {
  // api.getAllDropdown("IntervalList").then((response) => {
  //   localStorage.setItem(
  //     "IntervalList",
  //     JSON.stringify(response.datalist.IntervalList)
  //   );
  // });
  props.openNoticeModal({
    isOpen: true,
    msg: data.message,
    msgtype: data.success,
  });
  queryClient.getQueriesData("facility"); //api page name
  props.history.push("/facility"); //
}else{

  props.openNoticeModal({
    isOpen: true,
    msg: data.message,
    msgtype: data.success,
  });
 
}
},
});


const handleChange = (e) => {
const { name, value } = e.target;

let data = { ...formData };
console.log("formData: ", formData);
data[name] = value;

setFormData(data);

setErrorObject({ ...errorObject, [name]: null });
};


const handleCheck = (e) => {
  const { name, checked } = e.target;
  
  let bDispenseVal = checked;
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text:
          bDispenseVal == true
            ? t(
                DispensingLanguage[lan][menukey][
                  "Do you want to create facility stock?"
                ]
              )
            : t(
                DispensingLanguage[lan][menukey][
                  "Do you want to delete facility stock and relevant transaction data?"
                ]
              ),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setFormData({ ...formData, [name]: checked });

        } else {
          //refetch();
        }
      });

  //setFormData({ ...formData, [name]: checked });
};



// const handleCheck = (e) => {
// const { name, checked } = e.target;
// setFormData({ ...formData, [name]: checked });
// };

const handleReset = () => {
setFormData({
FacilityCode: "",
FacilityName: "",
RegionId:"",
ZoneId:"",
DistrictId:"",
FTypeId:"",
FClassificationId:"",
FLevelId:"",
FacilityAddress:"",
FacilityPhone:"",
FacilityEmail:"",
FacilityInCharge:"",
Latitude:"",
Longitude:"",
ServiceAreaId:"",
OwnerTypeId:"",
OwnerId:"",
ExternalFacilityId:"",
SOBAPSCode:"",
GLN:"",
eSIGLFacilityid:"",
location:"",
bESIGL:""
});
};

const validateForm = (formData) => {
let validateFields = ["FacilityCode", "FacilityName","FTypeId","FClassificationId","FLevelId","OwnerTypeId","ServiceAreaId"];
let errorData = {};
let isValid = true;
validateFields.map((field) => {
  if (!formData[field]) {
    errorData[field] =
      DispensingLanguage[lan][menukey]["field is Required !"];
    isValid = false;
  }
});
setErrorObject(errorData);
return isValid;
};

const handleSubmit = async (e) => {
//console.log("from submit ", formData);
if (validateForm(formData)) {
  let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  mutate(fDtat);
}
};

return (
<>
  <FacilityFormData
    errorObject={errorObject}
    addProductForm={true}
    formData={formData}
    handleChange={handleChange}
    handleCheck={handleCheck}
    handleReset={handleReset}
    handleSubmit={handleSubmit}
    {...props}
  />
</>
);
};

export default AddFacility;
