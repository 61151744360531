import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  FormGroup,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const ProductFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ValidateProductForDispensing,
  ...props
}) => {
      //get DispensingLanguage
    const DispensingLanguage = JSON.parse(
      localStorage.getItem("DispensingLanguage")
    );
    const lan = localStorage.getItem("LangCode");
    const menukey = "generics";

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const GroupList = JSON.parse(localStorage.getItem("GroupList"));
  const ProductSubGroupList = JSON.parse(localStorage.getItem("ProductSubGroupList"));
  const Strengthlist = JSON.parse(localStorage.getItem("Strengthlist"));
  const DosageFormlist = JSON.parse(localStorage.getItem("DosageFormlist"));
  const PackSizelist = JSON.parse(localStorage.getItem("PackSizelist"));
  const Unitofmeasurelist = JSON.parse(localStorage.getItem("Unitofmeasurelist"));
  const Dcilist = JSON.parse(localStorage.getItem("Dcilist"));
  const productclassification = JSON.parse(localStorage.getItem("productclassification"));

  function tabChangeHandler(event, newValue) {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [ItemGroupValue, setItemGroupValue] = useState({ id: '', name: "" });
  const changeItemGroupId = (val) => {

    setItemGroupValue(val);

    if (val != null) {
      formData.ItemGroupId = val.id;
    } else {
      formData.ItemGroupId = "";
    }
  };

  const [ProductSubGroupValue, setProductSubGroupValue] = useState({ id: '', name: "" });
  const changeProductSubGroupId = (val) => {

    setProductSubGroupValue(val);

    if (val != null) {
      formData.ProductSubGroupId = val.id;
    } else {
      formData.ProductSubGroupId = "";
    }
  };

  const [StrengthValue, setStrengthValue] = useState({ id: '', name: "" });
  const changeStrengthId = (val) => {
    
    setStrengthValue(val);

    if (val != null) {
      formData.StrengthId = val.id;

      let target = {name: "StrengthId", value: val.id};
      handleChange({target});
    } else {
      formData.StrengthId = "";
      
      let target = {name: "StrengthId", value: ''};
      handleChange({target});
    }
  };

  const [PacksizeValue, setPacksizeValue] = useState({ id: '', name: "" });
  const changePacksizeId= (val) => {

    setPacksizeValue(val);

    if (val != null) {
      formData.PacksizeId = val.id;

      let target = {name: "PacksizeId", value: val.id};
      handleChange({target});
    } else {
      formData.PacksizeId = "";

      let target = {name: "PacksizeId", value: ''};
      handleChange({target});
    }
  };

  const [DosageFormValue, setDosageFormValue] = useState({ id: '', name: "" });
  const changeDosageFormId = (val) => {

    setDosageFormValue(val);

    if (val != null) {
      formData.DosageFormId = val.id;
      
      let target = {name: "DosageFormId", value: val.id};
      handleChange({target});
    } else {
      formData.DosageFormId = "";

      let target = {name: "DosageFormId", value: ''};
      handleChange({target});
    }
  };

  const [DciValue, setDciValue] = useState({ id: '', name: "" });
  const changeDciId = (val) => {

    setDciValue(val);

    if (val != null) {
      formData.DciId = val.id;

      let target = {name: "DciId", value: val.id};
      handleChange({target});
    } else {
      formData.DciId = "";

      let target = {name: "DciId", value: ''};
      handleChange({target});
    }
  };

  const [UnitValue, setUnitValue] = useState({ id: '', name: "" });
  const changeUnitId = (val) => {

    setUnitValue(val);

    if (val != null) {
      formData.UnitId = val.id;
    } else {
      formData.UnitId = "";
    }
  };

  const [ClassificationValue, setClassificationValue] = useState({ id: '', name: "" });
  const changeClassificationId = (val) => {

    setClassificationValue(val);

    if (val != null) {
      formData.ClassificationId = val.id;
    } else {
      formData.ClassificationId = "";
    }
  };

  useEffect(() => {

    setItemGroupValue(GroupList[GroupList.findIndex(Group_List => Group_List.id == formData.ItemGroupId)]);
    setProductSubGroupValue(ProductSubGroupList[ProductSubGroupList.findIndex(ProductSubGroup_List => ProductSubGroup_List.id == formData.ProductSubGroupId)]);
    setStrengthValue(Strengthlist[Strengthlist.findIndex(Strength_list => Strength_list.id == formData.StrengthId)]);
    setPacksizeValue(PackSizelist[PackSizelist.findIndex(PackSize_list => PackSize_list.id == formData.PacksizeId)]);
    setDosageFormValue(DosageFormlist[DosageFormlist.findIndex(DosageForm_list => DosageForm_list.id == formData.DosageFormId)]);
    setDciValue(Dcilist[Dcilist.findIndex(Dci_list => Dci_list.id == formData.DciId)]);
    setUnitValue(Unitofmeasurelist[Unitofmeasurelist.findIndex(Unitofmeasure_list => Unitofmeasure_list.id == formData.UnitId)]);
    setClassificationValue(productclassification[productclassification.findIndex(product_classification => product_classification.id == formData.ClassificationId)]);

  }, [formData.ItemGroupId]);

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Generic Entry"]} -{" "}
                {addProductForm
                  ? DispensingLanguage[lan][menukey]["Add"]
                  : DispensingLanguage[lan][menukey]["Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/generics")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/generics")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>

          <Grid item xs={3} sm={3}>
            <div className="sw_label_group">
              {/* <div className="label">{DispensingLanguage[lan][menukey]["Generic Code"]}</div> */}
              <div className="code">
              {formData.ItemCode ? (
                <>
                {formData.ItemCode}
                </>
              ) : (
                <>
                {formData.DosageFormId ? (formData.DosageFormId) : (<span className="red">XX</span>)}-
                {formData.DciId ? (formData.DciId) : (<span className="red">XXXX</span>)}-
                {formData.StrengthId ? (formData.StrengthId) : (<span className="red">XXXX</span>)}-
                {formData.PacksizeId ? (formData.PacksizeId) : (<span className="red">XXXX</span>)}
                </>
              )}
              </div>
            </div>

            <TextField
              hidden
              error={errorObject.ItemCode}
              helperText={errorObject.ItemCode}
              required
              id="ItemCode"
              name="ItemCode"
              label={DispensingLanguage[lan][menukey]["Generic Code"]}
              value={formData.ItemCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={9} sm={9}>
            <div className="sw_label_group">
              <div className="label">{DispensingLanguage[lan][menukey]["Generic"]}</div>
              <div className="name">{formData.ItemName}</div>
            </div>

            <TextField
                hidden
                error={errorObject.ItemName}
                helperText={errorObject.ItemName}
                required
                id="ItemName"
                name="ItemName"
                label={DispensingLanguage[lan][menukey]["Generic"]}
                value={formData.ItemName}
                fullWidth
                autoComplete="family-name"
                onChange={(e) => handleChange(e)}
              />

            <TextField
              hidden
              id="ShortName"
              name="ShortName"
              label="Item Short Name"
              value={formData.ShortName}
              fullWidth
              disabled={true}
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                    autoHighlight
                    disableClearable
                    id="ItemGroupId"
                    options={GroupList}
                    onChange={(event, value) => changeItemGroupId(value)}
                    getOptionLabel={(option) => option.name}
                    value={ItemGroupValue}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.ItemGroupId}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                        error={errorObject.ItemGroupId}
                        helperText={errorObject.ItemGroupId}
                        required
                        id="ItemGroupId"
                        name="ItemGroupId"
                        fullWidth
                      />
                    )}
                  />
              </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                    autoHighlight
                    disableClearable
                    id="ProductSubGroupId"
                    options={ProductSubGroupList}
                    onChange={(event, value) => changeProductSubGroupId(value)}
                    getOptionLabel={(option) => option.name}
                    value={ProductSubGroupValue}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.ProductSubGroupId}
                        label={DispensingLanguage[lan][menukey]["Product Subgroup"]}
                        variant="standard"
                        error={errorObject.ProductSubGroupId}
                        helperText={errorObject.ProductSubGroupId}
                        required
                        id="ProductSubGroupId"
                        name="ProductSubGroupId"
                        fullWidth
                      />
                    )}
                  />
              </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                  autoHighlight
                  disableClearable
                  id="StrengthId"
                  options={Strengthlist}
                  onChange={(event, value) => changeStrengthId(value)}
                  getOptionLabel={(option) => option.name}
                  value={StrengthValue}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={formData.StrengthId}
                      label={DispensingLanguage[lan][menukey]["Strength"]}
                      variant="standard"
                      error={errorObject.StrengthId}
                      helperText={errorObject.StrengthId}
                      required
                      id="StrengthId"
                      name="StrengthId"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
          </Grid>


          {/* New row */}
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
                <Autocomplete
                    autoHighlight
                    disableClearable
                    id="PacksizeId"
                    options={PackSizelist}
                    onChange={(event, value) => changePacksizeId(value)}
                    getOptionLabel={(option) => option.name}
                    value={PacksizeValue}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.PacksizeId}
                        label={DispensingLanguage[lan][menukey]["Pack Size"]}
                        variant="standard"
                        error={errorObject.PacksizeId}
                        helperText={errorObject.PacksizeId}
                        required
                        id="PacksizeId"
                        name="PacksizeId"
                        fullWidth
                      />
                    )}
                  />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="DosageFormId"
                options={DosageFormlist}
                onChange={(event, value) => changeDosageFormId(value)}
                getOptionLabel={(option) => option.name}
                value={DosageFormValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.DosageFormId}
                    label={DispensingLanguage[lan][menukey]["Form"]}
                    variant="standard"
                    error={errorObject.DosageFormId}
                    helperText={errorObject.DosageFormId}
                    required
                    id="DosageFormId"
                    name="DosageFormId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                  autoHighlight
                  disableClearable
                  id="DciId"
                  options={Dcilist}
                  onChange={(event, value) => changeDciId(value)}
                  getOptionLabel={(option) => option.name}
                  value={DciValue}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={formData.DciId}
                      label={DispensingLanguage[lan][menukey]["DCI"]}
                      variant="standard"
                      error={errorObject.DciId}
                      helperText={errorObject.DciId}
                      required
                      id="DciId"
                      name="DciId"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                  autoHighlight
                  disableClearable
                  id="UnitId"
                  options={Unitofmeasurelist}
                  onChange={(event, value) => changeUnitId(value)}
                  getOptionLabel={(option) => option.name}
                  value={UnitValue}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={formData.UnitId}
                      label={DispensingLanguage[lan][menukey]["Unit"]}
                      variant="standard"
                      error={errorObject.UnitId}
                      helperText={errorObject.UnitId}
                      required
                      id="UnitId"
                      name="UnitId"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
            {/* <div className="sw_overlay_text"> */}
              <TextField
                id="Price"
                name="Price"
                type="number"
                label={DispensingLanguage[lan][menukey]["Price (CFA)"]}
                value={formData.Price}
                fullWidth
                autoComplete="shipping address-level2"
                onChange={(e) => handleChange(e)}
              />
              {/* <span className="sw_pack_size">{getPricebesideLevel()}</span> 
            </div>*/}
          </Grid>

          <Grid item xs={3} sm={3}>
            <TextField
              id="SOBAPSCode"
              name="SOBAPSCode"
              label={DispensingLanguage[lan][menukey]["SOBAPS Code"]}
              value={formData.SOBAPSCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                  autoHighlight
                  disableClearable
                  id="ClassificationId"
                  options={productclassification}
                  onChange={(event, value) => changeClassificationId(value)}
                  getOptionLabel={(option) => option.name}
                  value={ClassificationValue}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={formData.ClassificationId}
                      label={DispensingLanguage[lan][menukey]["Product Classification"]}
                      variant="standard"
                      error={errorObject.ClassificationId}
                      helperText={errorObject.ClassificationId}
                      required
                      id="ClassificationId"
                      name="ClassificationId"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
          </Grid>
          {/* New row */}
          <Grid item xs={4} sm={3}>
            <Grid item xs={12} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={formData.bKeyItem}
                    onChange={(e) => handleCheck(e)}
                    name="bKeyItem"
                    value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["Key Product"]}
              />
            </Grid>
          </Grid>
          {/* new row */}
          
        </Grid>
      </div>
    </div>
  );
};

export default ProductFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
