import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";
import {
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import Autocomplete from '@material-ui/lab/Autocomplete';

const ShipmentEntry = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment-entry";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dataItems, setDataItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product].concat(Product_Group);
  const [ItemGroupId, setCurrProdutlist] = useState(0);
  const [ProductGroupName, setProductGroupName] = useState([]);

  // ProductGroup Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== ItemGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
     
    }
  };

  const FundingSource = JSON.parse(localStorage.getItem("fundingsourceData"));
  let All_FundingSource = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FundingSourceList = [All_FundingSource].concat(FundingSource);
  const [FundingSourceId, setFundingSource] = useState(0);
  const [FundingSourceName, setFundingSourceName] = useState([]);

  // FundingSource Change
  const handleFundingSourceChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== FundingSourceId) {
      setFundingSource(rowId);
      setFundingSourceName(rowName);
     
    }
  };


  const ShipmentStatu = JSON.parse(localStorage.getItem("getShipmentStatus"));
  let All_ShipmentStatu = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ShipmentStatusList = [All_ShipmentStatu].concat(ShipmentStatu);
  const [ShipmentStatusId, setShipmentStatus] = useState(0);
  const [ShipmentStatusName, setShipmentStatusName] = useState([]);

  // ShipmentStatus Change
  const handleShipmentStatusChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== ShipmentStatusId) {
      setShipmentStatus(rowId);
      setShipmentStatusName(rowName);
     
    }
  };


  let params = {
    menukey: menukey,
    action: "getDataList",
    ItemGroupId:ItemGroupId,
     FundingSourceId: FundingSourceId,
     ShipmentStatusId: ShipmentStatusId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getShipmentEntry,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
       

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const { mutate:deleteApi } = useMutation(api.deleteShipment, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        // api.getAllDropdown("PackSizelist").then((response) => {
        //   localStorage.setItem(
        //     "PackSizelist",
        //     JSON.stringify(response.datalist.PackSizelist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("shipmentEntry");
       
        
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          }
    },
  });

  
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ShipmentEntryListExport" +
        "&reportType=" +
        reportType +
        "&ItemGroupId=" +
        ItemGroupId +
        "&FundingSourceId=" +
        FundingSourceId +
        "&ShipmentStatusId=" +
        ShipmentStatusId +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["SL#"],
      formatter: "rownum",
      width: "5%",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      width: 160,
      headerFilter: true,
      frozen:true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Funding Source"],
      field: "FundingSourceName",
      headerFilter: true,
       width: 130,
      frozen:true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Status"],
      field: "ShipmentStatusDesc",
      width: 120,
      headerFilter: false,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of Expected Receive"],
      field: "ShipmentDate",
      width: 130,
      headerFilter: false,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Qty",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Port of Entry"],
      field: "EntryPort",
      width: 120,
      headerFilter: false,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Destination"],
      field: "FacilityName",
      width: 120,
      headerFilter: false,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 80,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

 

  const editShipment = (data) => {
    let route = `shipment-entry/edit/${data.id}`;
    props.history.push(route);
  };




  const deleteShipment = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {

      if (willDelete) {
        deleteApi({AgencyShipmentId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }

    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editShipment(rowData);
          }}
        />
          <DeleteOutline
          onClick={() => {
            deleteShipment(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Shipment Entry"]}
              </div>
              
            </div>
          </Grid>
        </div>



        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>

                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ItemGroupId"
                          disableClearable
                          options={ProductGroup}
                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == ItemGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                  </FormControl>
                  </Grid>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FundingSourceId"
                        disableClearable
                        options={FundingSourceList}
                        onChange={(event, newValue) => handleFundingSourceChange(event, newValue)}
                        getOptionLabel={(option) => option.name || ""}
                        defaultValue={FundingSourceList[FundingSourceList.findIndex(FundingSourceList => FundingSourceList.id == FundingSourceId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Funding Source"]}
                            variant="standard"

                            />
                        )}
                      />
                  </FormControl>
                  </Grid>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>

                    <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="ShipmentStatusId"
                        disableClearable
                        options={ShipmentStatusList}
                        onChange={(event, newValue) => handleShipmentStatusChange(event, newValue)}
                        getOptionLabel={(option) => option.name || ""}
                        defaultValue={ShipmentStatusList[ShipmentStatusList.findIndex(ShipmentStatusList => ShipmentStatusList.id == ShipmentStatusId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Shipment Status"]}
                            variant="standard"

                            />
                        )}
                      />



                  </FormControl>
                  </Grid>

            

              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/shipment-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button>

                
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                </div>
              </Grid>

             
            

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
          {isLoading && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={450}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentEntry;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
