import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { CenterFocusStrong, DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";


const StockConfiguration = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "initial-stock-configuration";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [Region, setRegion] = React.useState(true);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);
 
  const [Regionlist, setRegionlist] = useState([]);
  const [currRegionId, setcurrRegionId] = useState(0);

  

  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [currFacilityId, setcurrFacilitylist] = useState(0);
  const [Facilitylist, setFacilitylist] = useState([]);
  
  let TempCommuneList = useState([]);
  const [Communelist, setCommunelist] = useState(TempCommuneList);
  let TempZoneList = useState([]);
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  const [currFTypeId, setcurrFTypeId] = useState(0);
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));
  const [FacilityTableData, setFacilityTableData] = React.useState(true);
  const StoreId = localStorage.getItem("StoreId");
  const [GenerateBtn, setGenerateBtn] = React.useState(0);

  const [acRegionValue, setacRegionValue] = useState({ id: "0", name: t(DispensingLanguage[lan][menukey]["All Department"])});
  const [acZoneValue, setacZoneValue] = useState({ id: "0", name: t(DispensingLanguage[lan][menukey]["All ZS"])});
  const [acCommuneValue, setacCommuneValue] = useState({ id: "0", name: t(DispensingLanguage[lan][menukey]["All Commune"])});
  const [acFacilityValue, setacFacilityValue] = useState({ id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"])});


  let params = {
    menukey: menukey,
    action: "getDataList",
    bDispensingValue: "",
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    FLevelId: "",
    FTypeId: currFTypeId,
    ServiceAreaId: "",
    OwnerTypeId: "",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

  };


  const getFacilityData = () => {

    setFacilityTableData(false);
    Service.default
      .postApi("source/api/api_pages/facility_list.php", params)
      .then((res) => { 
        setDataItems(res.datalist);
        setLoading(false);  
      })
      .catch((err) => { });
    // }


  }


  if (Region) {
    setRegion(false);

    let ParamList = { action: "Regionlist",UserId: UserId }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        
        if(res.datalist.length==1) setcurrRegionId(res.datalist[0]['id']);       
        let All_Region = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Department"]) };
        const Region_list = [All_Region].concat(res.datalist);  
        setRegionlist(Region_list);
        setacRegionValue({ id: Region_list[0].id, name: Region_list[0].name});
        

      })
      .catch((err) => { });
  }

  const cZoneList = () => {

    let ZoneParam = { action: "Zonelist", RegionId: currRegionId, menukey: "" ,UserId: UserId}

    Service.default
      .postApi("source/combo_generic.php", ZoneParam)
      .then((res) => {
        if(res.datalist.length==1) setcurrZoneId(res.datalist[0]['id']);
        let All_Zone = { id: "0", name: t(DispensingLanguage[lan][menukey]["All ZS"]) };
        const Zone_list = [All_Zone].concat(res.datalist);
        setZoneListLocal(Zone_list);
        setacZoneValue({ id: Zone_list[0].id, name: Zone_list[0].name});
      })
      .catch((err) => { });



  }


  const cCommuneList = () => {
    let CommuneParam = { action: "Communelist", RegionId: currRegionId, ZoneId: currZoneId, menukey: "",UserId: UserId }

    Service.default
      .postApi("source/combo_generic.php", CommuneParam)
      .then((res) => {
        if(res.datalist.length==1) setcurrCommuneId(res.datalist[0]['id']);
        let All_Commune = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Commune"]) };
        const Commune_list = [All_Commune].concat(res.datalist);
        setCommunelist(Commune_list);
        setacCommuneValue({ id: Commune_list[0].id, name: Commune_list[0].name});
        //setCommunelist(res.datalist);

      })
      .catch((err) => { });



  }


  
  const cFacilityList = () => {
    let FacilityParam = { action: "cFacilityList", RegionId: currRegionId, ZoneId: currZoneId, CommuneId: currCommuneId, menukey: "" ,UserId: UserId}

    Service.default
      .postApi("source/combo_generic.php", FacilityParam)
      .then((res) => {

        if(res.datalist.length==1) setcurrFacilitylist(res.datalist[0]['id']); 
        let All_Facility = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
        const Facility_list = [All_Facility].concat(res.datalist);
        setFacilitylist(Facility_list); 
        
        if(res.datalist.length>=1) {
        let params1={action:"gStoreListByFacility","menukey": menukey, "FacilityId":res.datalist[0]['id'],"TransactionTypeId":3,"lan":lan};
         mutateStore(params1); 
        let params2={action:"gUserListByFacility","menukey": menukey, "FacilityId":res.datalist[0]['id']};
        mutategUserListByFacility(params2); 
        setcurrFacilitylist(res.datalist[0]['id']);
        setacFacilityValue({ id: res.datalist[0]['id'], name: res.datalist[0]['name']});
        }

      })
      .catch((err) => { });



  }

  /*
  const handleRegionChange = (event) => {
    setcurrRegionId(event.target.value);
  };
  */

  const handleRegionChange = (event, newValue) => {
    console.log("Titu", newValue);
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currRegionId) {
      setacRegionValue(newValue);
      setcurrRegionId(rowId);
      setacFacilityValue({ id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"])});
    }

  };

  

  /*
  const handleZoneChange = (event) => {

    setcurrCommuneId(0);
    let CommuneParam = { action: "gCommunelist", RegionId: currRegionId, ZoneId: event.target.value, menukey: "",UserId: UserId }
    cCommuneList(CommuneParam);
    setcurrZoneId(event.target.value);

  };
  */

  const handleZoneChange = (event, newValue) => {
    
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currZoneId) {
      setcurrCommuneId(0);
      let CommuneParam = { action: "gCommunelist", RegionId: currRegionId, ZoneId: rowId, menukey: "",UserId: UserId }
      cCommuneList(CommuneParam);
      setacZoneValue(newValue);
      setcurrZoneId(rowId);
    }

  };
  /*
  const handleCommuneChange = (event) => {
    setcurrCommuneId(event.target.value);
  };
  */

  const handleCommuneChange = (event, newValue) => {
    
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currCommuneId) {
      setacFacilityValue({ id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"])});
      setacCommuneValue(newValue);
      setcurrCommuneId(rowId);    
    }

  };

  React.useEffect(() => {
    setcurrZoneId(0);
    cZoneList();
  }, [currRegionId]);
  React.useEffect(() => {
    setcurrCommuneId(0);
    cCommuneList();
  }, [currRegionId, currZoneId]);
  React.useEffect(() => {
    setcurrFacilitylist(0);
    cFacilityList();
  }, [currRegionId, currZoneId, currCommuneId]);

  

  React.useEffect(() => {
    getFacilityData();
  }, ["", currRegionId, currZoneId, currCommuneId, currFTypeId, ""]);



  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );
  
  const [formData, setFormData] = useState({
    StockTakeDate: selectedDate,
    CompleteDate: selectedDate,
    ApprovedBy: '',
    PreparedBy: '',
    IsCompleted:0,
    ManyJsonSave:"" 
  });
   

const { mutate:mutateGetInitialStock } = useMutation(
  api.getInitialStockTakeInv, {
  onSuccess: (data) => { 
     

   Facilitylist.forEach((item) => {
    if(item.id==currFacilityId){
      localStorage.setItem(
        "FacilityName",
        item.name
      ); 
    }
      
   });
    
      let route = `initial-stock-configuration/edit/${currFacilityId}`;
      props.history.push(route);  

     
  },
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false
});


const {  mutate:mutateStore } = useMutation(api.gStoreListByFacility, {
  onSuccess: (data) => { 
    if (data.status == 200) {  
       
      localStorage.setItem(
        "StoreId",
        data.data.datalist[0]['id']
      );

      localStorage.setItem(
        "getStore",
        JSON.stringify(data.data.datalist)
      );



    }
  }
})


// Change User List By Facility

const {  mutate:mutategUserListByFacility } = useMutation(api.gUserListByFacility, {
  onSuccess: (data) => { 
    if (data.status == 200) {  
      
      localStorage.setItem(
        "UsersListByFacility",
        JSON.stringify(data.data.datalist)
      );

    }
  }
})

/*
const handleFacilityChange = (event) => {

  Facilitylist.map((item, index) => {
    if(item.id==event.target.value)
      setGenerateBtn(item.IsCompleted);
  })

  let params1={action:"gStoreListByFacility","menukey": menukey, "FacilityId":event.target.value,"TransactionTypeId":3,"lan":lan};
     mutateStore(params1);
  
      // Facility List By User Change
      let params2={action:"gUserListByFacility","menukey": menukey, "FacilityId":event.target.value};
      mutategUserListByFacility(params2);


  setcurrFacilitylist(event.target.value);
};
*/

const handleFacilityChange = (event, newValue) => {

  setacFacilityValue(newValue);
    
  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currFacilityId) {

    Facilitylist.map((item, index) => {
      if(item.id==rowId)
        setGenerateBtn(item.IsCompleted);
    })

    let params1={action:"gStoreListByFacility","menukey": menukey, "FacilityId":rowId,"TransactionTypeId":3,"lan":lan};
    mutateStore(params1);
  
    // Facility List By User Change
    let params2={action:"gUserListByFacility","menukey": menukey, "FacilityId":rowId};
    mutategUserListByFacility(params2);
    setcurrFacilitylist(rowId);
    
   
  }

};



  const nextinitialstocktake =()=>{ 
    let fData={FacilityId:currFacilityId,StoreId:StoreId,...formData};
    mutateGetInitialStock(fData); 

    // let route = `initial-stock-configuration/edit/${currFacilityId}`;
  //   let route = `initial-stock-configuration/edit/${currFacilityId}`;
   //  props.history.push(route); 
   }

  const generateinitialstocktake =()=>{ 
    let fData={FacilityId:currFacilityId,StoreId:StoreId,...formData};
    mutateGetInitialStock(fData); 
    //  let route = `initial-stock-configuration/add`; 
    //  props.history.push(route); 
   }
  return (
    <>
      <div className={classes.packSizePageTitle}>
        <div className="d-flex justify-packSize mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Initial Stock Configuration"]}
              </div>

            </div>
          </Grid>
        </div>

        <div className={classes.contentposition}>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                
                {/* <Grid item xs={12} sm={12}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Select Department"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="RegionId"
                      name="RegionId"
                      value={currRegionId}
                      onChange={handleRegionChange}
                      fullWidth
                    >
                      <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                      {Regionlist.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}


                    </Select>
                  </FormControl>
                </Grid> */}


                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.fullWidth}>
                        <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="RegionId"
                            disableClearable
                            options={Regionlist}  
                            value={acRegionValue}             
                            onChange={(event, newValue) => handleRegionChange(event, newValue)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={Regionlist[Regionlist.findIndex(ARegionlist => ARegionlist.id == currRegionId)]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Department"]}
                              variant="standard"
                              name="RegionId"
                            />
                            )}
                          />                   
                        </FormControl>
                    </Grid>

                            


                {/* <Grid item xs={12} sm={12}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Select ZS"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="ZoneId"
                      name="ZoneId"
                      value={currZoneId}
                      onChange={handleZoneChange}
                      fullWidth
                    >
                      <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All ZS"]}</MenuItem>

                      {Zonelist.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}


                    </Select>
                  </FormControl>
                </Grid> */}

                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.fullWidth}>
                        <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="ZoneId"
                            disableClearable
                            options={Zonelist}  
                            value={acZoneValue}             
                            onChange={(event, newValue) => handleZoneChange(event, newValue)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={Zonelist[Zonelist.findIndex(AZonelist => AZonelist.id == currZoneId)]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select ZS"]}
                              variant="standard"
                              name="ZoneId"
                            />
                            )}
                          />                   
                        </FormControl>
                    </Grid>

                {/* <Grid item xs={12} sm={12}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Select Commune"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="DistrictId"
                      name="DistrictId"
                      value={currCommuneId}
                      onChange={handleCommuneChange}
                      fullWidth
                    >

                      <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Commune"]}</MenuItem>
                      

                      {Communelist.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}


                    </Select>
                  </FormControl>
                </Grid> */}

                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.fullWidth}>
                        <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="DistrictId"
                            disableClearable
                            options={Communelist}  
                            value={acCommuneValue}             
                            onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={Communelist[Communelist.findIndex(ACommunelist => ACommunelist.id == currCommuneId)]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Commune"]}
                              variant="standard"
                              name="DistrictId"
                            />
                            )}
                          />                   
                        </FormControl>
                    </Grid>

                {/* <Grid item xs={12} sm={12}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Select Facility"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="FacilityId"
                      name="FacilityId"
                      value={currFacilityId}
                      onChange={handleFacilityChange}
                      fullWidth
                    >
                       <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility"]}</MenuItem>
                      {Facilitylist.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}

                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} sm={12}>
                        <FormControl className={classes.fullWidth}>
                        <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="FacilityId"
                            disableClearable
                            options={Facilitylist}  
                            value={acFacilityValue}             
                            onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={Facilitylist[Facilitylist.findIndex(AFacilitylist => AFacilitylist.id == currFacilityId)]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Facility"]}
                              variant="standard"
                              name="FacilityId"
                            />
                            )}
                          />                   
                        </FormControl>
                    </Grid>

                <div className="float-right sw_btn_control">
                  <div className={classes.buttonposition}>
                    <Button
                    disabled={currFacilityId==0?true:false}
                      color="info"
                      className="mr-2"
                      onClick={() => GenerateBtn==0?nextinitialstocktake():generateinitialstocktake()  }
                    >
                      {DispensingLanguage[lan][menukey]["Generate_Stock_take"]}
                    </Button>
                  </div>
                </div>
                 

              </Grid>
            </CardContent>
          </Card>

        </div>
       

      </div>
    </>
  );
};

export default StockConfiguration;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "80px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "100%",
  },
  filterDiv: {
    width: "60%",
    display: "flex",
  },
  contentposition: {
    position: "relative",
    left: "30%",
    width: "40%",


  },
  buttonposition: {
    position: "relative",
    left: "100%",
    width: "100%",
  }
});
