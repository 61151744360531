import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

const ESIGLDashboardView = (props) => {
  
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;

  const classes = useStyles();
  const { id } = useParams();
  const { useState } = React;
  const [reportTitle, setReportTitle] = useState([]);

  let params = {
    menukey: menukey,
    action: 'getDashboardNameById',
    UseSite: 'esigl',
    lan: lan,
    DashboardId: id
  };
  const { refetch } = useQuery(
    [params],
    api.getDashboardNameById,
    {
      onSuccess: (data) => {
        setReportTitle(data[0].DashboardName);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const REPORT_URL = removeStrFromURL.replace('backend/', '');
  let DashboardReportList = REPORT_URL+"reports/esigl_my_dashboardsview.php?DashboardId="+id+"&lan="+lan + "&UserId="+UserId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.PageTitle}>
        <div className="d-flex justify-packSize mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {reportTitle}
              </div>
              <div className="float-right sw_btn_control">
              <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/esigl-reports")}
                >
                  {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div className="uniqueName">
          <div className="main min-height">
            <div dangerouslySetInnerHTML={{ __html: '<iframe src='+DashboardReportList+' frameBorder="0" />'}} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ESIGLDashboardView;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
