import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const Dhis2accesssettings = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "dhis2-access-settings";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();



  const { isLoading, error, isError, data, refetch } = useQuery(
    "DHIS2AccessSettings",
    api.getDHIS2Access,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

      // defaultOptions: {
      //   queries: {
      //     refetchOnWindowFocus: false,
      //     refetchOnmount: false,
      //     refetchOnReconnect: false,
      //     retry: false,
      //     staleTime: 1000 * 60 * 60 * 24,
      //   },
      // },



    }
  );




  const { mutate:deleteApi } = useMutation(api.deletePackSize, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        api.getAllDropdown("PackSizelist").then((response) => {
          localStorage.setItem(
            "PackSizelist",
            JSON.stringify(response.datalist.PackSizelist)
          );
        });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("packSize");
       
        
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=PacksizeExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 100,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]['Settings Key'],

      field: "SKey",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]['Settings Value'],
      field: "SValue",
      headerSort: false,
      headerFilter: true,

    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 100,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editDHIS2 = (data) => {
    let route = `dhis2-access-settings/edit/${data.id}`;
    props.history.push(route);
  };



  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editDHIS2(rowData);
          }}
        />
       
      </>
    );
  }

  return (
    <>
      <div className={classes.packSizePageTitle}>
        <div className="d-flex justify-packSize mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["DHIS2 Access Settings"]}
              </div>
              <div className="float-right sw_btn_control">

                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/dhis2-access-settings/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button>



        
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={600}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dhis2accesssettings;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
