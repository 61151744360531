import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";


const Role = (props) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "role-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, error, isError, data, refetch } = useQuery(
    'role',
    api.getRole,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
    );
  
  const { mutate:deleteApi } = useMutation(api.deleteRole, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("roles");
       // swal(data.data.message, "", "success");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=RoleExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode")
      + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  const columns = [
    { title: DispensingLanguage[lan][menukey]['Sl#.'] , formatter: "rownum", width: 70, hozAlign: "center",headerSort:false, headerHozAlign:"center"},
    {
      title:  DispensingLanguage[lan][menukey]["Role_Id"],
      field: "id",
      width: 75,
      headerSort: false,
    },
    { title: DispensingLanguage[lan][menukey]['Role Name'] , width: 300,field: "role"},
    { title: DispensingLanguage[lan][menukey]['Default Redirect'] , field: "defaultredirect"},
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort:false,
      headerHozAlign:"center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];



  const editunit = (data) => {
    let route = `role-entry/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
      text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this Record!']),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Role Entry']}</div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/role-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]['Add Role']} 
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns} data={dataItems}
            layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
