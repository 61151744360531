import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import {
  Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch

} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import ChartStates from "../esigl_reports/ChartStates";
import statesData from '../../../services/mapjsonfile/benin_regions.json';
const PercentageofFacilitieswithStockoutofKeyItems = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);


  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));


  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currDepartmentName, setDepartmentName] = useState(DepartMentName[0].name);
  const [currZoneIdId, setZoneId] = useState(Zonelist[0].id);
  const [currZonename, setZonename] = useState(Zonelist[0].name);
  const [currCommuneId, setcurrCommuneId] = useState(Communelist[0].id);
  const [currCommuneame, setCommunename] = useState(Communelist[0].name);
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);


  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  const [currYearId, setYearId] = useState(currentDate.getFullYear());

  const [acZoneValue, setACZoneValue] = useState({ id: Zonelist[0].id, name: Zonelist[0].name });

  const [acCommnueValue, setACCommnueValue] = useState({ id: Communelist[0].id, name: Communelist[0].name });

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);


  //toggole button
  const [toggleButtonValue, setToggleButton] = React.useState("1");



  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {


      if (tmpToggleButtonValue == 1) {

      }

      // setLoadingPrescription(true);
      setToggleButton(tmpToggleButtonValue);
    }
  };

  exporting(Highcharts);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };


  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);


    }
  };


  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);



    }
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProvinceName: currDepartmentName

  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);

        // console.log("rate", data.data.datalist);
        // console.log("fl", data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  
  //Map api include
  const [dataItemsMap, setDataItemsMap] = useState([]);
  //console.log('Map Data', dataItemsMap);
  let params2 = {
    menukey: menukey,
    action: "PercentageoffacilitystockoutkeyitemsMap",
    lan: lan,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    toggleButtonValue: toggleButtonValue
  };

  const { data: scanloglist2 } = useQuery(
    [params2],
    api.getProductLotStockReportMap,
    {
      onSuccess: (data) => {

        // let tempData = [];
        // for (let i = 0; i < data.length; i++) {
        //   tempData.push([data[i]['RegionName'], Math.round(data[i]['StockoutPercentage'])]);

        // }
        // console.log("hhhhhhhhh", data);
        setDataItemsMap(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const [dataItems, setDataItems] = useState([]);
  const [geoJson, setGeoJson] = useState(statesData);
  let params = {
    menukey: menukey,
    action: "PercentageoffacilitystockoutkeyitemsTable",
    lan: lan,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    toggleButtonValue: toggleButtonValue
  };

  const { data } = useQuery(
    [params],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data.data.datalist);

        tableheaderlanchange();


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [gettablelanguage, settablelanguage] = React.useState(t(DispensingLanguage[lan][menukey]["Percentage of Facilities with a Stockout of Key Items"]));
  function tableheaderlanchange() {
    if (toggleButtonValue == 1) {
      settablelanguage(t(DispensingLanguage[lan][menukey]["Percentage of Facilities with a Stockout of Key Items"]));
    } else {
      settablelanguage(t(DispensingLanguage[lan][menukey]["Percentage of Facilities with a Stockout by MOS of Key Items"]));
    }
  }





  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: true,
      width: 200,
    },


    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "ProductGroup",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ProductName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,

    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClosingStock",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 100,
    },

    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
      formatter: "money",
    },
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 100,
    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=facilitywithstockoutofkeyitem" +
      "&reportType=" +
      reportType +
      "&ItemGroupId=" +
      currProductGroupId +
      "&MonthId=" +
      currmonthId +
      "&Year=" +
      currYearId +
      "&ProductGroup=" +
      currItemGroupname +
      "&MonthName=" +
      currMonthName +
      "&toggleButtonValue=" +
      toggleButtonValue +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {gettablelanguage}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                {/* <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["Start date"]
                        }
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid> */}

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid className="text-center" item xs={3} sm={3}>
                  <div className="float-right">
                    <ToggleButtonGroup
                      id="tglbuttongroup"
                      className="sw_ToggleButtonGroup"
                      color="primary"
                      value={toggleButtonValue}
                      exclusive
                      onChange={handleChangeToggle}
                    >
                      <ToggleButton value="1">
                        {
                          DispensingLanguage[lan][menukey]["By Stockout Days"]
                        }
                      </ToggleButton>
                      <ToggleButton value="2">
                        {
                          DispensingLanguage[lan][menukey]["By MOS"]
                        }
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Grid>

              </Grid>


            </CardContent>
          </Card>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent" style={{ backgroundColor: "#2ca8ff" }}>
              <Grid item xs={12} sm={12}>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>

                        <TableCell className="legentId" align="center" style={{ borderBottom: "none", color: "white" }} >

                          <Link>{DispensingLanguage[lan][menukey]["Reporting Rate"]} </Link>
                          <Link> {DispensingLanguage[lan][menukey]["Total"]} : {getReportingRate['Total']}%</Link>
                          <Link> {getFLvelelList[99]}:{getReportingRate['HealthFaclilities']}% </Link>
                          <Link> {getFLvelelList[3]}:{getReportingRate['DistrictWarehouse']}%</Link>
                          <Link> {getFLvelelList[2]}:{getReportingRate['RegionalWarhouse']}% </Link>
                          <Link> {getFLvelelList[1]}:{getReportingRate['CentralWarehouse']}% </Link>

                        </TableCell>



                      </TableRow>
                    </TableHead>

                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>

          </Card>


          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <ChartStates
                        mapGeoJson={geoJson}
                        data={dataItemsMap}
                      
                      />
                    </div>
                  </div>

                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}



          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={gettablelanguage}

                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          options={{
                            groupBy: ["ProvinceName", "ZoneName", "BCZName"],

                            columnCalcs: "both",
                          }}
                          layout={"fitColumns"}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PercentageofFacilitieswithStockoutofKeyItems;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
