import React, { useState } from "react";
import { Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

// services
import * as Service from "../../services/Service.js";
import LoadingSpinner from "../../services/LoadingSpinnerForRegister";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

import swal from "sweetalert";

//Css
import "assets/css/custom.css";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function SignUp(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [loginNameFocus, setLoginNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [organizationFocus, setOrganizationFocus] = React.useState(false);
  const [designationFocus, setDesignationFocus] = React.useState(false);
  const [contactFocus, setContactFocus] = React.useState(false);
  
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false);
  const [langCodeFocus, setLangCodeFocus] = React.useState(false);
  const [Language, setLangguage] = React.useState(true);
  const [languageList, setLanguageList] = React.useState([]);

  const [isLoading, setLoading] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);

  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    loginname: "",
    email: "",
    password: "",
    confirmPassword: "",
    service: Service,
    LangCode: "fr_FR",
    Organization: "",
    designation: "",
    Contact: "",
    reCaptcha: "",
  });

  const handleReset = () => {
    setState({
      firstName: "",
      lastName: "",
      loginname: "",
      email: "",
      password: "",
      confirmPassword: "",
      service: Service,
      LangCode: "fr_FR",
      Organization: "",
      designation: "",
      Contact: "",
      reCaptcha: "",
    });
  };

if (Language) {
    state.service.default
      .postApi("source/language_combo.php")
      .then((res) => {
        setLanguageList(res);
        setLangguage(false);
      })
      .catch((err) => {});
}

  React.useEffect(() => {}, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  
  function onChangereCaptcha(value) {
    setState({ ...state, reCaptcha: value });
  }

  /* ======Language====== */
  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = 'en_GB';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "login-signup-and-reset";
  /* ======Language====== */

  const submit = (e) => {
    e.preventDefault();
    if (
      state.firstName.length > 0 &&
      state.lastName.length > 0 &&
      state.loginname.length > 0 &&
      state.email.length > 0 &&
      state.password.length > 0 &&
      state.confirmPassword.length > 0 &&
      state.Organization.length > 0 &&
      state.designation.length > 0 &&
      state.Contact.length > 0 &&
      state.reCaptcha.length > 0 &&
      state.LangCode.length > 0
    ) {
      if (!regex.test(state.email)) {
        swal("Oops Error!", "Your email is not valid!", "error");
      } else if (state.password !== state.confirmPassword) {
        swal("Oops Error!", "Your Password is not same!", "error");
      } else if (state.reCaptcha === '') {
        swal("Oops Error!", "Please check the captcha", "error");
      } else if (state.loginname.indexOf(" ") >= 0) {
        swal(
          "Oops Error!",
          "White space is not allowed in login name.",
          "error"
        );
      } else {
        setLoading(true);
        setbuttonDisabled(true);

        const body = {
          menukey: menukey,
          lan: lan,
          action: "RegisterForm",
          name: state.firstName + " " + state.lastName,
          loginname: state.loginname,
          email: state.email,
          password: state.password,
          role: "User",
          LangCode: state.LangCode,
          Organization: state.Organization,
          designation: state.designation,
          Contact: state.Contact,
          reCaptcha: state.reCaptcha,
        };
        state.service.default
          .postApi("source/register.php", body)
          .then((res) => {
            if (res.success == 1) {

              handleReset();
              swal("Success!", `${res.message}`, "success");

              // props.history.push("/login");

            } else if (res.success == 0) {
              swal("Error!", `${res.message}`, "error");
            }

            setLoading(false);
            setbuttonDisabled(false);
            window.grecaptcha.reset();

          })
          .catch((err) => {
            swal("Error!", `${err}`, "error");
          });
      }
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <>
    {isLoading && <LoadingSpinner />}
  
    <ExamplesNavbar  {...props} />
      <div className="page-header h_PageHeader">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          }}
        ></div>
        <div className="content login_signup_content">
          <Container>
            <div className="login_signup_card">
              <Row noGutters={true} className="align-items-center__">
                <Col xs={12} sm={12} md={12} lg={7}>
                  <div className="image_content_card h_signup">
                    <div className="content_card">
                      <h3>{ DispensingLanguage[lan][menukey]["SignUP_Box_title"] }</h3>
                      <p>{ DispensingLanguage[lan][menukey]["SignUP_Box_Description"] }</p>
                    </div>
                    <div className="image_card">
                      <img src={require("assets/img/login_bg.png")} alt=""></img>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                  <div className="login_signup_form">
                    <h3>{ DispensingLanguage[lan][menukey]["Sign Up"] }</h3>
                    <p>{ DispensingLanguage[lan][menukey]["Please fill in the information below"] }</p>
                      
                        <InputGroup
                          className={
                            "input-md" + (loginNameFocus ? " input-group-focus" : "")
                          }
                        >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.loginNameValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Login Name"] }
                          type="text"
                          name="loginname"
                          value={state.loginname}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setLoginNameFocus(true)}
                          onBlur={() => setLoginNameFocus(false)}
                        ></Input>
                        {state.loginNameValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" + (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.firstNameValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["First Name"] }
                          name="firstName"
                          value={state.firstName}
                          onChange={(evt) => handleChange(evt)}
                          type="text"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                        {state.firstNameValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" + (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.lastNameValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Last Name"] }
                          type="text"
                          name="lastName"
                          value={state.lastName}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                        ></Input>
                        {state.lastNameValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" + (emailFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.emailValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Email"] }
                          type="text"
                          name="email"
                          value={state.email}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                        ></Input>
                        {state.emailValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" + (passwordFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.passwordValidate}
                          placeholder={ '*'+DispensingLanguage[lan][menukey]["Password"] }
                          name="password"
                          value={state.password}
                          onChange={(evt) => handleChange(evt)}
                          type={passwordShown ? "text" : "password"}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                        ></Input>
                        {state.passwordValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="password-eye">
                            <i
                              onClick={togglePasswordVisiblity}
                              className="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" +
                          (confirmPasswordFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.confirmPasswordValidate}
                          placeholder={ '*'+DispensingLanguage[lan][menukey]["Confirm Password"] }
                          name="confirmPassword"
                          value={state.confirmPassword}
                          onChange={(evt) => handleChange(evt)}
                          type={confirmPasswordShown ? "text" : "password"}
                          onFocus={() => setConfirmPasswordFocus(true)}
                          onBlur={() => setConfirmPasswordFocus(false)}
                        ></Input>
                        {state.confirmPasswordValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="password-eye">
                            <i
                              onClick={toggleConfirmPasswordVisiblity}
                              className="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" +
                          (organizationFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons business_briefcase-24"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.organizationValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Organization"] }
                          type="text"
                          name="Organization"
                          value={state.Organization}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setOrganizationFocus(true)}
                          onBlur={() => setOrganizationFocus(false)}
                        ></Input>
                        {state.organizationValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" +
                          (designationFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons business_briefcase-24"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.designationValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Designation"] }
                          type="text"
                          name="designation"
                          value={state.designation}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setDesignationFocus(true)}
                          onBlur={() => setDesignationFocus(false)}
                        ></Input>
                        {state.designationValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-md" +
                          (contactFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons business_briefcase-24"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.ContactValidate}
                          placeholder={ DispensingLanguage[lan][menukey]["Contact #"] }
                          type="text"
                          name="Contact"
                          value={state.Contact}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setContactFocus(true)}
                          onBlur={() => setContactFocus(false)}
                        ></Input>
                        {state.ContactValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                      
                      <InputGroup
                        className={
                          "input-md" + (langCodeFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons objects_globe"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid={state.langCodeValidate}
                          type="select"
                          name="LangCode"
                          value={state.LangCode || "fr_FR"}
                          onChange={(evt) => handleChange(evt)}
                          onFocus={() => setLangCodeFocus(true)}
                          onBlur={() => setLangCodeFocus(false)}
                        >
                          <option value="">{ '*'+DispensingLanguage[lan][menukey]["Select Language"] }</option>
                          {languageList.map((item, index) => {
                            return (
                              <option value={item.LangCode}>{item.LangName}</option>
                            );
                          })}
                        </Input>
                        {state.langCodeValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                      <ReCAPTCHA
                        sitekey="6Lej9c8hAAAAAKMe348CONHmOaU_wxc3U7vBJPLp" //Secret Key for epharmacie-bj.org
                        // sitekey="6Le88noUAAAAAJhsy0g8XFS4by9Qkd53j7MHtlYc" //Secret Key for Local
                        onChange = {onChangereCaptcha}
                      />
                      <Button
                        disabled={buttonDisabled}
                        block
                        className="btn-round"
                        color="info"
                        onClick={(e) => submit(e)}
                        size="lg"
                      >
                      { DispensingLanguage[lan][menukey]["Register"] }  
                      </Button>

                      <div className="footer_card">
                        <ul className="login_signup_list">
                          <li className="pull-left"><Link to={"./login"}>{ DispensingLanguage[lan][menukey]["View Login Page"] }</Link></li>
                        </ul>
                      </div>
                  </div>
                </Col>
              </Row>
            </div>
            
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default SignUp;
