import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import swal from "sweetalert";

import {
  DeleteOutline, ViewList
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";
import ProductsModal from '../../../services/ProductsModal'; 
import * as ScanBarcode from '../../../services/ScanBarcode';
import { cellFocusEditor, dateEditor  } from "../../../services/Common";
import moment from "moment";
import "../common.css";


import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import * as api from '../../../actions/api';
// react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';




let isDirty=false;
let deletedDatas=[];
let transactionItemID =0;
let masterDirty=false;
const StockTakeFormData = (props) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "stock-take";
  const FacilityId = localStorage.getItem('FacilityId');
  const FacilityName = localStorage.getItem('FacilityName');
  const StoreId = localStorage.getItem("StoreId");

  let tblRef = null;
  const classes = useStyles();
  const [manyTableData, setManyTableData] = useState([]);
  const [IsCompleted, setIsCompleted] = useState(false);


  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [SoureOpen, setSoureOpen] = useState(false);  
  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");

  const [currItemGroupId, setCurrItemGroupId] = useState('0');
  const GroupList = JSON.parse(localStorage.getItem("GroupList"));
  const { t, i18n } = useTranslation();

 





  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&StockTakeId=" + props.formData.StockTakeId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&StockTakeId=" + props.formData.StockTakeId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  

     const manycolumns = [ 
      { title: DispensingLanguage[lan][menukey]['Actions'], field: 'custom', headerSort:false, headerHozAlign:"center", visible: false, hozAlign: 'center',width: 100,
       formatter: reactFormatter(<ActionButton />) } ,
       { title: "StockTakeItemsId", field: "StockTakeItemsId", visible: false }, 
       {
        title: "dirty",
        field: "dirty",
        visible: false,
      }, 
       { title: "BrandNo", field: "BrandNo", visible: false }, 
       { title: 'Id', field: 'id',  visible:false },
       { title: DispensingLanguage[lan][menukey]['SL'], width:55, hozAlign: 'center', formatter: "rownum",headerSort:false, headerHozAlign:"center"},
       { title: DispensingLanguage[lan][menukey]['Product_Code'], field: 'ItemCode',width:135,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Product'], field: 'ItemName',width:240, headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Brand Code'], field: 'BrandCode',width:188,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Brand Name'], field: 'CommercialName',width:180,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Lot_No_Description'],
        field: 'BatchNo', hozAlign:"left",
        headerFilter: true,
        headerHozAlign:"left",width:100,
        editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '',   //"input" 
        cssClass: props.isFirstPhysical==0?"tabluator-column-editable":'',
        cellEdited: function (cell) {
        const currow = cell.getRow();
         
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        onSetDisable();

        },
      
       },
       { title: DispensingLanguage[lan][menukey]['Expiry_Date'], field: 'ExpiryDate', 
         hozAlign:"left",headerHozAlign:"left",width:100,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?dateEditor:''): '', 
          cellEdited: function (cell) {
            
            const curcellval = cell.getValue();
            const currow = cell.getRow();
            const rowdata = currow.getData(); 
            
            currow.getCell("dirty").setValue(1); 
            isDirty=true;
            onSetDisable(); 
          }
        },
        
        
       { title: DispensingLanguage[lan][menukey]['Stock_Quantity'], field: 'StoreQty',
        hozAlign:"right",headerHozAlign:"right",width:100},
       { 
       title: DispensingLanguage[lan][menukey]['Physical_Quantity'], 
       field: 'PhysicalQty',
       width:100, 
       hozAlign:"right",
       headerHozAlign:"right",
       editor: props.IsCompleted == 0 ? cellFocusEditor: '',   //"input"
       validator:["integer", "min:0"],
       cssClass: "tabluator-column-editable",
       cellEdited: function (cell) {

        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
       
        const pricecell = currow.getCell("AdjQuantity");
        const totprice =  curcellval!=""?curcellval-rowdata.StoreQty:"";
        pricecell.setValue(totprice); 
        
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.SKU;
        onSetDisable(); 
        

       },
      },
       { title: DispensingLanguage[lan][menukey]['Adj_Quantity'], field: 'AdjQuantity',width:123, hozAlign:"right",headerHozAlign:"right"},
      
       { title: DispensingLanguage[lan][menukey]['EAGTIN'], field: 'EAGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:120,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
          
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable(); 
          
  
         },
           
        },
        { title: DispensingLanguage[lan][menukey]['PKGTIN'], field: 'PKGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:115,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '',
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
           
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
          
  
         }, 
         
        },
        { title: DispensingLanguage[lan][menukey]['CAGTIN'], field: 'CAGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:125,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
          
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable(); 
          
  
         }
         
        },
        
        { title: DispensingLanguage[lan][menukey]['PLGTIN'], field: 'PLGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:115,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
           
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
          
  
         },
         
        },
        
        { title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: 'SSCCCarton', 
         hozAlign:"left",headerHozAlign:"left",width:125,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
          
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable(); 
          
  
         }
         
        },
        
        { title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: 'SSCCPallet', 
         hozAlign:"left",headerHozAlign:"left",width:130,
         headerFilter: true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) { 
          const currow = cell.getRow(); 
          
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable(); 
          
  
         }
         
        },

       { title: DispensingLanguage[lan][menukey]['Remarks'], 
       field: 'Remarks',
       width:110, 
       hozAlign:"left",  
       headerHozAlign:"left",
       headerFilter: true,
       cssClass: "tabluator-column-editable text-field-editable",
       editor: props.IsCompleted == 0? true : '',
       cellEdited: function (cell) { 
        const currow = cell.getRow(); 
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.SKU;
        onSetDisable(); 
        

       },
      },
    
      
    ];

    const onSetDisable = () => { 
    
      if(isDirty==true){   
        document.getElementById("is_dirty_message").classList.remove("dnone");  
        document.getElementById("FID_ItemGroupId").classList.add("swDisabled"); 

        let dataArray=[];
        let gridData=tblRef.table.getData();
        gridData.forEach((row,i) => { 
          //if(row.dirty==1)
          //dataArray.push(row);
          if(row.SKU==transactionItemID) {
            dataArray.push(row);
            transactionItemID=0;
          }

        });
        let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan,"action":"updateStockTake","UserName":UserName};  
        mutateCellUpdate(fDtat);
        
      }
      else{  
       document.getElementById("is_dirty_message").classList.add("dnone"); 
       document.getElementById("FID_ItemGroupId").classList.remove("swDisabled"); 
      }
      
 
      
   }

   const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }


   const mutateCellUpdate=(fDtat) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/stocktake";
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "PUT", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
          isDirty=false;
          onSetDisable();  
        })
        .catch(error => {
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     }

    const onPostBtnClick = () => { 
      setIsCompleted(true); 
      let dataArray=[];
      let gridData=tblRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.SKU==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      
      handlePost(dataArray);
  
    }

    const handlePost = async (rows) => {  

      let validForm = props.checkValidateFormPost(tblRef.table.getData(), 'post');
      //if (validateForm(formData,1)) {
         
        //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
        //mutateUpdate(fDtat);
  
        // Start Post Alert
  
      if (validForm) {
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
          icon: "warning",
          // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
          // t(DispensingLanguage[lan][menukey]["Yes"])],
          // dangerMode: true,
          buttons: {
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "sw_confirm_btn",
              closeModal: true,
            },
            
          },
        }).then((willAction) => {
          if (willAction) {
            //setStockPost(true);
   
            let FinalData={...validForm,'ManyJsonSave':rows,"IsCompleted":1,menukey: menukey,lan: lan, "UserName":UserName}; 
            mutateUpdate(FinalData); 
            setManyTableData(rows);
   
          }else{
            //props.setbStockUpdate(0);
            props.stockPosted(0);
            let route = `${validForm.StockTakeId}`;
            props.history.push(route);
          }
        });  
      }
        // End Post Alert
  
        
     // }
       
       
    };
  
    const onSaveUpdateBtnClick = () => { 
      let dataArray=[];
      let gridData=tblRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.SKU==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      setUpdatedDetailsGridDataHandler(dataArray); 
    
    }

  
    const setUpdatedDetailsGridDataHandler = (rows) => {
      let validForm = props.checkValidateForm(rows,'edit');
      // console.log('isval: ', validForm);
        if (validForm) {
  
          // console.log('props.addProductForm: ', props.addProductForm);
          if(props.addProductForm){
            //when save
            mutate(validForm);
          }else{
            //when update
            mutateUpdate(validForm); 
          }
          
  
          
        }
    };
    
  
    const { isLoading: isLoadingSave, mutate } = useMutation(api.saveStockTakeInv, {
      onSuccess: (data) => { 
        if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();    
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
        let route = `edit/${data.StockTakeId}`;
        props.history.push(route); 
        
        }else{
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    });
  
    
    const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateStockTakeInv, {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) { 
        //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          masterDirty=false;
          onMasterSetDirty(); 

          props.successSave(data.IsCompleted);
          if(data.IsCompleted==1){
           mutatePost(props.formData.StockTakeId);
          }
          else{
            props.successSave(data.IsCompleted); //refetch(); 
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
   
          }
  
        }else{ 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    });
    
  
  
    const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postStockTakeInv, {
      onSuccess: (data) => { 
        if (data.status == 200) { 
  
          //props.setbStockUpdate(1);
          //setDeletedDataSet([]);
  
          if(data.success==0){ 
            
            props.stockPosted(0);
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
           
           // swal(data.data.message, "", "warning"); 
           
           }else{
            props.stockPosted(1);
            props.refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
  
            //swal(data.data.message, "", "success");
            
    
           } 
  
        }else{
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    })
  
   

 

  useEffect(() => { 
    if (tblRef == null) return;
    if(props.bFirst){
    setManyTableData(props.ManyTableData);
    }
    setIsCompleted(props.IsCompleted);
    
    deletedDatas=[];  
  }, [props.ManyTableData,props.IsCompleted]); 


  /*

  useEffect(() => {  
    isDirty=props.dirty; 
    onSetDisable();
    deletedDatas=[]; 
  }, [props.dirty]);
  
  */

 
  function ActionButton (props) { 
    
   
    
    const rowData = props.cell._cell.row.data;  
  
   if(rowData.StockTakeItemsId==-1){
    if (tblRef !== null) tblRef.table.selectRow((rowData.id));
   }
   


    
    const cellValue = props.cell._cell.value || 'Edit | Show';
    if(props.IsCompleted == 1)
    
    return (
      <>
         
      </>
    );
    else{

      if(rowData.StockTakeItemsId==-1)

      return (
        <>
         <DeleteOutline
            onClick={() => {
              
           
              props.cell.getRow().delete(); 
              isDirty=true;
              onSetDisable();  
              if((rowData.StockTakeItemsId!=null)||(rowData.StockTakeItemsId!=-1)){
                let newDeletedDataSet=[rowData.StockTakeItemsId]; 
                deletedDatas=newDeletedDataSet.concat(deletedDatas);
 
              }  
          
            }}
          /> 
        </>
      );
      else return (
        <>
         <DeleteOutline
            onClick={() => {
              

              
           
              props.cell.getRow().delete(); 
              isDirty=true;
              onSetDisable();  
              if((rowData.TransactionItemId!=null)||(rowData.TransactionItemId!=-1)){
                let newDeletedDataSet=[rowData.TransactionItemId]; 
                deletedDatas=newDeletedDataSet.concat(deletedDatas);

                console.log(deletedDatas);
              }  
          


            }}
          /> 
        </>
      );



    } 
  }


  const handleRefetchCall = () => {
    props.refetch();
  };


  const handleChangeGrp = (e) => {
    props.filterHandleChange(e.target.value);
    setCurrItemGroupId(e.target.value);
  };

  const backToList = () => {
    if(masterDirty==true){
  
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) { 
          masterDirty=false;
          props.history.push("/stock-take");
  
        } 
      });
  
    }else props.history.push("/stock-take")
    
  };


   const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     masterDirty=true;
     onMasterSetDirty(); 
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

   };


const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   //console.log("On change ev",val.id);
  switch(cases){
    
    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;

    case 'ApprovedBy': 
      setApprovedBy(val);
      props.formData.ApprovedBy= val != null?val.id:"";
      break;  
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByApproveList => UsersListByApproveList.id == props.formData.ApprovedBy)]);    
}, [props.formData.PreparedBy, props.formData.ApprovedBy]);


  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

          <div className="sw_page_heading">
            <div className="sw_heading_title">{ DispensingLanguage[lan][menukey]['Stock Take - Add/Edit'] +' ('+FacilityName+')'}</div>
          
          <div className="float-right sw_btn_control">

            {/* Action buttons */}
          {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">

<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  //onClick={() => props.history.push("/stock-take")}
                  onClick={() => backToList()}
                >
                  { DispensingLanguage[lan][menukey]['Back_to_List'] } 
                </Button>
                {/* <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.handleReset()}
                >
                  { DispensingLanguage[lan][menukey]['Reset'] }
                </Button> */}
                <Button
                  disabled={isLoadingSave}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}
                  //onClick={() => props.handleSubmit()}
                >
                  { DispensingLanguage[lan][menukey]['Save'] } 
                </Button>
                
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">
<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => backToList()}
                  //onClick={event =>  window.location.href='/order'}
                  
                >
                  { DispensingLanguage[lan][menukey]['Back_to_List'] } 
                </Button>

                <Button
                  disabled={props.IsCompleted || isLoadingUpdate || isLoadingPost}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}
                  //onClick={() => props.handleUpdate()}
                  
                >
                  { DispensingLanguage[lan][menukey]['update'] }  
                </Button>

                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  disabled={props.IsCompleted || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost}
                  onClick={onPostBtnClick}
                >
                 { DispensingLanguage[lan][menukey]['STOCK_TAKE_COMPLETE'] }    
                </Button>

                

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => PrintMany()}
                >
                <i class="fas fa-print"></i>
                </Button>

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => ExcelMany()}
                >
                <i className="far fa-file-excel"></i>
                </Button>




              </Grid>
          }
           {/* End Action buttons */}

          </div>
          </div>
        </Grid>
      </div>


     

         

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
          <Grid item xs={12} sm={12}>
          
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                     

                    <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            required
                            error={props.errorObject.StockTakeDate}
                            helperText={props.errorObject.StockTakeDate}
                            id="StockTakeDate"
                            label= { DispensingLanguage[lan][menukey]['Start_Date'] } 
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            autoOk={true}
                            name="StockTakeDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.StockTakeDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            //onChange={(e) => props.handleRDateChange(e, "StockTakeDate")}
                            onChange={(e) =>formOnchange(e,'handleRDateChange','StockTakeDate')}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disabled={props.IsCompleted}
                            disableFuture={true}
                
                          />
                    </MuiPickersUtilsProvider>
                    </Grid>
                   



              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  
                    {DispensingLanguage[lan][menukey]["Prepared_By"]} *
                  </InputLabel>
                  <Select
                    error={props.errorObject.PreparedBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="PreparedBy"
                    name="PreparedBy"
                    value={props.formData.PreparedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                    disabled={props.IsCompleted}
                  >
                    {UsersListByFacility.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={props.errorObject.PreparedBy}>
                    {props.errorObject.PreparedBy}
                  </FormHelperText>
                </FormControl>
              </Grid> */}


              <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.IsCompleted}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Prepared_By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required
                        id="PreparedBy"
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>



              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  
                    {DispensingLanguage[lan][menukey]["Approved_By"]}
                  </InputLabel>
                  <Select
                    error={props.errorObject.ApprovedBy}
                   
                    labelId="demo-simple-select-helper-label"
                    id="ApprovedBy"
                    name="ApprovedBy"
                    value={props.formData.ApprovedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                    disabled={props.IsCompleted}
                  >
                  {UsersListByFacility.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={props.errorObject.ApprovedBy}>
                    {props.errorObject.ApprovedBy}
                  </FormHelperText>
                </FormControl>
              </Grid> */}


            <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                <Autocomplete
                  disabled={props.IsCompleted}                
                  autoHighlight
                  disableClearable
                  id="ApprovedBy"
                  options={UsersListByFacility}  
                  onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                  getOptionLabel={(option) => option.name}
                  value={ApprovedBy}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    value={props.formData.ApprovedBy}
                    label={DispensingLanguage[lan][menukey]["Approved_By"] }
                    variant="standard"
                    error={props.errorObject.ApprovedBy}
                    helperText={props.errorObject.ApprovedBy}
                    required
                    id="ApprovedBy"
                    name="ApprovedBy" 
                    fullWidth
                  />
                  )}
                />
                </FormControl>
              </Grid>  



            <Grid item xs={3} sm={3}>

                  <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                        <KeyboardDatePicker 
                          error={props.errorObject.CompleteDate}
                          helperText={props.errorObject.CompleteDate}
                          id="CompleteDate"
                          label= { DispensingLanguage[lan][menukey]['End_Date'] } 
                          className={classes.fullWidth}
                         // disableToolbar
                          variant="inline"
                          autoOk={true}
                          name="CompleteDate"
                          fullWidth
                          showTodayButton={true}
                          value={props.formData.CompleteDate||props.selecteEnddDate}
                          format="dd/MM/yyyy"
                          //onChange={(e) => props.handleRDateChange(e, "CompleteDate")}
                          onChange={(e) =>formOnchange(e,'handleRDateChange','CompleteDate')}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={props.IsCompleted}
                          disableFuture={true}
                        />
                  </MuiPickersUtilsProvider>
                    

            </Grid>
            

            

                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="StockTakeId"
                        name="StockTakeId"
                        label="StockTakeId"  
                        value={props.formData.StockTakeId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />


                    </Grid>  
            
                  </Grid>
                </Grid>





                
                <Grid container spacing={3}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>



                      <Grid container className="mb-2">
                        <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) =>handleChangeGrp(e)}
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid> 

                      <Grid item xs={2} sm={2}>
                        <div className="float-right"> 
                       
                        <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                        </div>
                      </Grid>

                      </Grid> 

 
                          
                          
                        <div>
                          <div className="uniqueName">
                            <ReactTabulator 

                            ref={(r) => (tblRef = r)} 
                            columns={manycolumns}                    
                            data={manyTableData} 
                            layout={"fitColumns"}
                            height="340px"
                            options={{
                              groupBy: "GroupName",
                              columnCalcs: "both",
                            }}
                            
                            

                            />


                          </div>
                        </div> 


                        </CardContent>
                      </Card>
                    </Grid>

                    
                  </Grid>
              </Grid>
          </Grid>


        
      </Grid>
    </div>
  </div>
  );
};

export default StockTakeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
