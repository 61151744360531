import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';

// services & auth
import * as Service from "../../services/Service.js";
import * as api from "actions/api";

import { UserContext } from "../../context/user-info-context";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";

import swal from "sweetalert";

//Css
import "assets/css/custom.css";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function LoginPage(props) {
  const userCtx = useContext(UserContext);

  const { buttonLabel, className } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [state, setState] = React.useState({
    service: Service,
    email: "",
    password: "",
  });
  const [modal, setModal] = useState(false);

  React.useEffect(() => {
    //
    // if(state.service.default.authToken() != null){
    //   swal("Oops!", "Your are already logged in!", "warning");
    //   props.history.push("/home");
    // }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + ";httpOnly=true; path=/;SameSite=Strict;Secure=true;";//
  }

  const LoginPage = (data) => {
    if (state.email.length > 0 && state.password.length > 0) {
      //if (!regex.test(state.email)) {
      // swal("Oops Error!", "Your email is not valid!", "error");
      //} else {

      const body = {
        email: state.email,
        password: state.password,
      };
      state.service.default
        .postApi("source/login.php", body)
        .then((res) => {
          if (res.success == 1) {
            sessionStorage.setItem("token", res.token);
            sessionStorage.setItem("iat", res.iat);
            sessionStorage.setItem("refreshToken", res.refreshToken);
            setCookie("randKey", res.RandomToken, 7);
           

           // console.log(jsonDecode res.token);
            sessionStorage.setItem("User_info", JSON.stringify(res.user_data));

         //   cookie="nbjhgjhvhjgvgjf";

           // cookie.setHeader("Set-Cookie", "jwt=jwt_value;Path=/;Domain=domainvalue;Max-Age=seconds;HttpOnly");

            const auth_token = sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : null;

            //Dispensing Language
            let options = {};
            options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth_token}`,
                "Consumer": process.env.REACT_APP_CONSUMER,
                "Secret": process.env.REACT_APP_SECRET,
              },
            };

            api.getLoginDispensingLanguage(options).then((res1) => {
              //console.log(res)
              let DispensingLanguageData = res1.datalist;
              localStorage.setItem(
                "DispensingLanguage",
                JSON.stringify(DispensingLanguageData)
              );

              api.getLoginAllDropdown(options, "ALL").then((response) => {
                if (response.success == 1) {

                  localStorage.setItem(
                    "AdjType",
                    JSON.stringify(response.datalist.AdjType)
                  );

                  localStorage.setItem(
                    "ProductGroup",
                    JSON.stringify(response.datalist.ProductGroup)
                  );

                  localStorage.setItem(
                    "OrderType",
                    JSON.stringify(response.datalist.OrderType)
                  );
                  
                  localStorage.setItem(
                    "CountryList",
                    JSON.stringify(response.datalist.CountryList)
                  );

                  localStorage.setItem(
                    "DosageFormlist",
                    JSON.stringify(response.datalist.DosageFormlist)
                  );

                  localStorage.setItem(
                    "FacilityList",
                    JSON.stringify(response.datalist.FacilityList)
                  );
                  
                  localStorage.setItem(
                    "PackSizelist",
                    JSON.stringify(response.datalist.PackSizelist)
                  );

                  localStorage.setItem(
                    "Currencylist",
                    JSON.stringify(response.datalist.Currencylist)
                  );

                  localStorage.setItem(
                    "DataSetDHIS2",
                    JSON.stringify(response.datalist.DataSetDHIS2)
                  );



                  localStorage.setItem(
                    "fundingsourceData",
                    JSON.stringify(response.datalist.fundingsourceData)
                  );
                  
                  localStorage.setItem(
                    "Strengthlist",
                    JSON.stringify(response.datalist.Strengthlist)
                  );
                  
                  localStorage.setItem(
                    "Unitofmeasurelist",
                    JSON.stringify(response.datalist.Unitofmeasurelist)
                  );
                  
                  localStorage.setItem(
                    "MenuList",
                    JSON.stringify(response.datalist.MenuList)
                  );
                  localStorage.setItem(
                    "ParentMenu",
                    JSON.stringify(response.datalist.ParentMenu)
                    );
                  localStorage.setItem(
                    "MenuUrl",
                    JSON.stringify(response.datalist.MenuUrl)
                    );
                  localStorage.setItem(
                    "UserList",
                    JSON.stringify(response.datalist.UserList)
                  );

                  localStorage.setItem(
                    "UserListEdit",
                    JSON.stringify(response.datalist.UserListEdit)
                  );

                  localStorage.setItem(
                    "YearList",
                    JSON.stringify(response.datalist.YearList)
                  );

                  localStorage.setItem(
                    "MonthList",
                    JSON.stringify(response.datalist.MonthList)
                  );
                  localStorage.setItem(
                    "MonthListReport",
                    JSON.stringify(response.datalist.MonthListReport)
                  );
                  
                  localStorage.setItem(
                    "RoleList",
                    JSON.stringify(response.datalist.RoleList)
                  );

                  localStorage.setItem(
                    "LanguageList",
                    JSON.stringify(response.datalist.LanguageList)
                  );

                  localStorage.setItem(
                    "MosTypelist",
                    JSON.stringify(response.datalist.MosTypelist)
                  );

                  localStorage.setItem(
                    "Dcilist",
                    JSON.stringify(response.datalist.Dcilist)
                  );
                  localStorage.setItem(
                    "TransactionType",
                    JSON.stringify(response.datalist.TransactionType)
                  );

                  localStorage.setItem(
                    "Commerciallist",
                    JSON.stringify(response.datalist.Commerciallist)
                  );

                  localStorage.setItem(
                    "BrandList",
                    JSON.stringify(response.datalist.BrandList)
                  );

                
                  localStorage.setItem(
                    "Regionlist",
                    JSON.stringify(response.datalist.Regionlist)
                  );

                  localStorage.setItem(
                    "Zonelist",
                    JSON.stringify(response.datalist.Zonelist)
                  );

                  localStorage.setItem(
                    "Communelist",
                    JSON.stringify(response.datalist.Communelist)
                  );

                  localStorage.setItem(
                    "FlevelList",
                    JSON.stringify(response.datalist.FlevelList)
                  );
                  localStorage.setItem(
                    "FlevelListForReport",
                    JSON.stringify(response.datalist.FlevelListForReport)
                  );

                  localStorage.setItem(
                    "FTypeList",
                    JSON.stringify(response.datalist.FTypeList)
                  );
                  localStorage.setItem(
                    "SupplychainownerList",
                    JSON.stringify(response.datalist.SupplychainownerList)
                  );

                

                  localStorage.setItem(
                    "FClassificationList",
                    JSON.stringify(response.datalist.FClassificationList)
                  );
                  
                  //shipment list
                  localStorage.setItem(
                    "getShipmentStatus",
                    JSON.stringify(response.datalist.getShipmentStatus)
                  );

                  localStorage.setItem(
                    "getshipmentItem",
                    JSON.stringify(response.datalist.getshipmentItem)
                  );

                  localStorage.setItem(
                    "Ownertypelist",
                    JSON.stringify(response.datalist.Ownertypelist)
                  );

                  localStorage.setItem(
                    "Servicearealist",
                    JSON.stringify(response.datalist.Servicearealist)
                  );

                  localStorage.setItem(
                    "GroupList",
                    JSON.stringify(response.datalist.GroupList)
                  );

                  localStorage.setItem(
                    "ProductSubGroupList",
                    JSON.stringify(response.datalist.ProductSubGroupList)
                  );
                  localStorage.setItem(
                    "GetWarehouse",
                    JSON.stringify(response.datalist.GetWarehouse)
                  );

                  localStorage.setItem(
                    "getstatusList",
                    JSON.stringify(response.datalist.getstatusList)
                  );

                  localStorage.setItem(
                    "gAllCountryListSelected",
                    JSON.stringify(response.datalist.gAllCountryListSelected)
                  );
                  localStorage.setItem(
                    "gAllCountryList",
                    JSON.stringify(response.datalist.gAllCountryList)
                  );
                  localStorage.setItem(
                    "getIssuedToGroupList",
                    JSON.stringify(response.datalist.getIssuedToGroupList)
                  );

                  localStorage.setItem(
                    "getSupplierList",
                    JSON.stringify(response.datalist.getSupplierList)
                  );

                  localStorage.setItem(
                    "getCWRWList",
                    JSON.stringify(response.datalist.getCWRWList)
                  );
                  
                  localStorage.setItem(
                    "getCentralWarehouseId",
                    JSON.stringify(response.datalist.getCentralWarehouseId)
                  );
                  localStorage.setItem(
                    "getFacilityListForStockinTransit",
                    JSON.stringify(response.datalist.getFacilityListForStockinTransit)
                  );
                  


                  localStorage.setItem(
                    "gPickListMethod",
                    JSON.stringify(response.datalist.gPickListMethod)
                  );

                  localStorage.setItem(
                    "ProductStatus",
                    JSON.stringify(response.datalist.ProductStatus)
                  );
                  localStorage.setItem(
                    "GenericProduct",
                    JSON.stringify(response.datalist.GenericProduct)
                  );
                  
                  localStorage.setItem(
                    "getSettingsData",
                    JSON.stringify(response.datalist.getSettingsData)
                  );

                  localStorage.setItem(
                    "getDataset",
                    JSON.stringify(response.datalist.getDataset)
                  );
                  
                  localStorage.setItem(
                    "GlobalSettings",
                    JSON.stringify(response.datalist.GlobalSettings)
                  );
                  // localStorage.setItem(
                  //   "getFacilityListEqual99",
                  //   JSON.stringify(response.datalist.getFacilityListEqual99)
                  // );

                  localStorage.setItem(
                    "FacilityId",
                    res.user_data[0].FacilityId
                  );
                  localStorage.setItem(
                    "DefaultFacilityId",
                    res.user_data[0].FacilityId
                  );

                  localStorage.setItem(
                    "UserId",
                    res.user_data[0].id
                  );

                  localStorage.setItem(
                    "StoreId",
                    res.user_data[0].StoreId
                  );

                  localStorage.setItem(
                    "DefaultStoreId",
                    res.user_data[0].StoreId
                  );

                  for (
                    let i = 0;
                    i < response.datalist.FacilityList.length;
                    i++
                  ) {
                    if (
                      response.datalist.FacilityList[i]["id"] ==
                      res.user_data[0].FacilityId
                    ) {
                      localStorage.setItem(
                        "FacilityName",
                        response.datalist.FacilityList[i]["name"]
                      );
                      localStorage.setItem(
                        "DefaultFacilityName",
                        response.datalist.FacilityList[i]["name"]
                      );

                      break;
                    } else {
                      localStorage.setItem("FacilityName", "");
                    }
                  }

                  localStorage.setItem("LangCode", res.user_data[0].LangCode);

                  userCtx.replaceFacilityHandler({
                    FacilityId: res.user_data[0].FacilityId,
                    FacilityName:
                      response.datalist.FacilityList[
                        res.user_data[0].FacilityId
                      ],
                    LangId: res.user_data[0].LangCode,
                  });
                  
                    // Dependency Combo List
                    api.getLoginDependencyCombo(options, "ALL")
                    .then((response) => {

                      localStorage.setItem(
                        "getStore",
                        JSON.stringify(response.datalist.getStore)
                      );
                     
                      
                      localStorage.setItem(
                        "OrderTo",
                        JSON.stringify(response.datalist.OrderTo)
                      );
                      
                      localStorage.setItem(
                        "UsersListByFacility",
                        JSON.stringify(response.datalist.UsersListByFacility)
                      );

                      localStorage.setItem(
                        "getFacilityListEqual99",
                        JSON.stringify(response.datalist.getFacilityListEqual99)
                      );
                      localStorage.setItem(
                        "getFacilityListForReport",
                        JSON.stringify(response.datalist.getFacilityListForReport)
                      );
                      
                      localStorage.setItem(
                        "Demanderlist",
                        JSON.stringify(response.datalist.Demanderlist)
                      );
                      localStorage.setItem(
                        "DemanderUserlist",
                        JSON.stringify(response.datalist.DemanderUserlist)
                      );
                      localStorage.setItem(
                        "OrderListApprovalUser",
                        JSON.stringify(response.datalist.OrderListApprovalUser)
                      );
                      localStorage.setItem(
                        "RegionlistByUser",
                        JSON.stringify(response.datalist.RegionlistByUser)
                      );
                      localStorage.setItem(
                        "getFacilityListFromApprover",
                        JSON.stringify(response.datalist.getFacilityListFromApprover)
                      );

                      localStorage.setItem(
                        "gAdjustmentTypeByFacility",
                        JSON.stringify(response.datalist.gAdjustmentTypeByFacility)
                      );

                      localStorage.setItem(
                        "getFacilityListNotEqual99ANDSupplier",
                        JSON.stringify(response.datalist.getFacilityListNotEqual99ANDSupplier)
                      );

                      localStorage.setItem(
                        "getFacilityListEqual99ByLevelId",
                        JSON.stringify(response.datalist.getFacilityListEqual99ByLevelId)
                      );

                      localStorage.setItem(
                        "getStoreListForStockTake",
                        JSON.stringify(response.datalist.getStoreListForStockTake)
                      );

                      localStorage.setItem(
                        "getRecipientGroup",
                        JSON.stringify(response.datalist.getRecipientGroup)
                      );

                      localStorage.setItem(
                        "getStoreListByFacilityForA7report",
                        JSON.stringify(response.datalist.getStoreListByFacilityForA7report)
                      );

                      localStorage.setItem(
                        "productclassification",
                        JSON.stringify(response.datalist.productclassification)
                      );

                      localStorage.setItem(
                        "gStoreList",
                        JSON.stringify(response.datalist.gStoreList)
                      );
                      localStorage.setItem(
                        "gTransactionTypeList",
                        JSON.stringify(response.datalist.gTransactionTypeList)
                      );
                      localStorage.setItem(
                        "gStoreListforreceivesummary",
                        JSON.stringify(response.datalist.gStoreListforreceivesummary)
                      );
                      localStorage.setItem(
                        "gProductGroup",
                        JSON.stringify(response.datalist.gProductGroup)
                      );

                      window.location.href = `${process.env.REACT_APP_BASE_NAME}`+res.user_data[0].roleredirect;

                    });

                    // End Dependency Combo List
                }
              });
            });
          
          } else if (res.success == 0) {
            swal("Error!", `${res.message}`, "error");
          }
        })
        .catch((err) => {
          swal("Error!", `${err}`, "error");
        });
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggle = () => setModal(!modal);

  /* ======Language====== */
  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "login-signup-and-reset";
  /* ======Language====== */

  return (
    <>
      <ExamplesNavbar  {...props} />
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          }}
        ></div>
        <div className="content login_signup_content">
          <Container>
            <div className="login_signup_card">
              <Row noGutters={true} className="align-items-center____">
                <Col xs={12} sm={12} md={12} lg={7}>
                  <div className="image_content_card h_login">
                    <div className="content_card">
                      <h3>{ DispensingLanguage[lan][menukey]["Login_Box_title"] }</h3>
                      <p>{ DispensingLanguage[lan][menukey]["Login_Box_Description"] }</p>
                    </div>
                    <div className="image_card">
                      <img src={require("assets/img/login_bg.png")} alt=""></img>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                  <div className="login_signup_form">
                    <h3>{ DispensingLanguage[lan][menukey]["LOGIN"] }</h3>
                    <p>{ DispensingLanguage[lan][menukey]["Please enter your username and password"] }</p>
                    <InputGroup
                        className={
                          "input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="placeholder_color"
                          placeholder={ DispensingLanguage[lan][menukey]["Username"] }
                          type="text"
                          name="email"
                          onChange={(e) => handleChange(e)}
                          valid={false}
                          invalid={false}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="placeholder_color"
                          placeholder={ DispensingLanguage[lan][menukey]["Password"] }
                          name="password"
                          onChange={(e) => handleChange(e)}
                          type={passwordShown ? "text" : "password"}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                        ></Input>
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="password-eye">
                            <i
                              onClick={togglePasswordVisiblity}
                              className="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
                          LoginPage();
                        }}
                        size="lg"
                      >
                        { DispensingLanguage[lan][menukey]["LOGIN"] }
                      </Button>
                      <div className="footer_card">
                        <ul className="login_signup_list">
                          <li className="pull-left"><Link to={"./signup"}>{ DispensingLanguage[lan][menukey]["Create Account"] }</Link></li>
                          <li className="pull-right"><Link to={"./reset-password-request"}>{ DispensingLanguage[lan][menukey]["Forget Password?"] }</Link></li>
                        </ul>
                      </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default LoginPage;
