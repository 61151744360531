import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import ShipmentEntryFormData from "./ShipmentEntryFormData.js";
import moment from "moment";

const AddShipmentEntry = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "shipment-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    FundingSourceId: "",
    ShipmentStatusId: "",
    ItemGroupId: "",
    ItemNo: "",
    ShipmentDate: "",
    Qty: "",
    EntryPort: "",
    FacilityId: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["shipmentEntry", id],
    () =>
      api.getShipmentData(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveShipment, {
    onSuccess: (data) => {

      if(data.status==200) {

      // api.getAllDropdown("PackSizelist").then((response) => {
      //   localStorage.setItem(
      //     "PackSizelist",
      //     JSON.stringify(response.datalist.PackSizelist)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("shipmentEntry");
      props.history.push("/shipment-entry");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  // const GrouphandleChange = (e) => {
  //   const { name, value } = e.target;

  //   let data = { ...formData };
  //   console.log("formData: ", formData);
  //   data[name] = value;

  //   setFormData(data);

  //   setErrorObject({ ...errorObject, [name]: null });
  // };



  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      FundingSourceId: "",
      ShipmentStatusId: "",
      ItemGroupId: "",
      ItemNo: "",
      ShipmentDate: "",
      Qty: "",
      EntryPort: "",
      FacilityId: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ShipmentStatusId","FundingSourceId","ItemNo","ShipmentDate","Qty","FacilityId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  
//Date formate code here

  const handleRDateChange = (date, field) => {
    let newDate;
    if(date==null || date==""){
      newDate="";
     }
     else{
       newDate = moment(date).format("YYYY-MM-DD");
     }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };






  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <ShipmentEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}  
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleRDateChange={handleRDateChange} 
       // GrouphandleChange={GrouphandleChange}
        {...props}
      />
    </>
  );
};

export default AddShipmentEntry;
