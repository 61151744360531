import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// import ChartStates from './ChartStates';

import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";


import Highcharts from "highcharts/highmaps";

import ChartStates from "../esigl_reports/ChartStates";
import statesData from '../../../services/mapjsonfile/benin_regions.json';



const PercentageofFacilityStockoutbyProducts = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);


  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  // let TempItemList = JSON.parse(localStorage.getItem("getshipmentItem"));
  const [Productlist, setProductlist] = useState([]);
  // let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const Productlist = [All_Product].concat(tmpProductlist);


  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currZoneIdId, setZoneId] = useState("0");
  const [currCommuneId, setcurrCommuneId] = useState("0");

  const [acValue, setACValue] = useState({ id: Productlist.length > 0 ? Productlist[0].id : "", name: Productlist.length > 0 ? Productlist[0].name : "" });



  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);
  // Facility Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;

    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );



  // Product Group Change
  const handleZoneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);

    }
  };






  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "PercentageofFacilityStockoutbyProducts",
    lan: lan,
    RegionId: currDepartmentId,
    GroupName: currItemGroupname,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    ItemNo: acValue.id,
    ProductName: acValue.name,
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getProductLotStockReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  //Map api include
  const [dataItemsMap, setDataItemsMap] = useState([]);
  //console.log('Map Data', dataItemsMap);
  let params2 = {
    menukey: menukey,
    action: "PercentageofFacilityStockoutbyProductsMap",
    lan: lan,
    RegionId: currDepartmentId,
    GroupName: currItemGroupname,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    ItemNo: acValue.id,
    ProductName: acValue.name,
  };

  const { data: scanloglist2 } = useQuery(
    [params2],
    api.getProductLotStockReportMap,
    {
      onSuccess: (data) => {

        // let tempData = [];
        // for (let i = 0; i < data.length; i++) {
        //   tempData.push([data[i]['RegionName'], Math.round(data[i]['StockoutPercentage'])]);

        // }
        // console.log("hhhhhhhhh", data);
        setDataItemsMap(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );








  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      width: 200,
      headerFilter: true,

    },


    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "ProductGroup",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 280,
      headerFilter: true,

    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ProductName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,

    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "StockQty",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },


  ];




  const [geoJson, setGeoJson] = useState(statesData);

  var data1111 = [
    ["Plateau", 728]
    /*,
    ["DE.BE", 710],
    ["DE.MV", 963],
    ["DE.HB", 541],
    ["DE.HH", 622],
    ["DE.RP", 866],
    ["DE.SL", 398],
    ["DE.BY", 785],
    ["DE.SN", 223],
    ["DE.ST", 605],
    ["DE.NW", 237],
    ["DE.BW", 157],
    ["DE.HE", 134],
    ["DE.NI", 136],
    ["DE.TH", 704],
    ["DE.", 361]*/
  ];




  async function getGeoJson() {
    try {
      console.log("start load");
      const result = await fetch(
        "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/germany.geo.json"
      );
      // const result = await fetch(
      //   "http://192.241.155.223/epharmacie/wp-content/themes/betheme-child/report/mapjsonfile/benin_regions.json"
      // );

      const data = await result.json();

      console.log("data = ", data);

      return data;
      // this line won't run until above has completed
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   //console.log('mounted')
  //   // load geoJson file
  //   getGeoJson().then((data) => {
  //     //setGeoJson(data);
  //     // console.log("start complete: geoJson ", data);
  //     // console.log("start complete: geoJson benin", statesData);
  //     // console.log("start complete: data loaded", data1111);
  //   });
  // }, []);



  React.useEffect(() => {

    if (currProductGroupId == ProductGroup[0].id) {

      let ProductlistParam1 = { action: "gProductList", ItemGroupId: currProductGroupId, menukey: "" }
      cProductList(ProductlistParam1);
    }


  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }

    let ProductlistParam = { action: "gProductList", ItemGroupId: newValue.id, menukey: "" }
    cProductList(ProductlistParam);
  };

  const { mutate: cProductList } = useMutation(
    api.aProductList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          // console.log("Dta list", data.data.datalist);

          setProductlist(data.data.datalist);
          setACValue({ id: data.data.datalist.length > 0 ? data.data.datalist[0].id : "", name: data.data.datalist.length > 0 ? data.data.datalist[0].name : "" });


        }

      },
    }
  );

  // product Change
  const handleProductChange = (event, newValue) => {

    setACValue(newValue);

  };










  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=PercentageOfFacilityStockOutbyProduct" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneIdId +
      "&DistrictId=" +
      currCommuneId +
      "&ItemGroupId=" +
      currProductGroupId +
      "&ItemNo=" +
      acValue.id +
      "&ProductName=" +
      acValue.name +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
 
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Percentage of Facility Stockout by Products"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>



                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}

                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

              </Grid>
              <Grid container>


              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) => handleProductChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField

                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
               

               





              </Grid>
            </CardContent>
          </Card>

          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                    <ChartStates 
                    mapGeoJson={geoJson} 
                    data={dataItemsMap}
                    
                  />
                    </div>
                  </div>

                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}
         

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["Percentage of Facility Stockout by Products"])}

                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          options={{
                            groupBy: ["ProvinceName", "ZoneName","BCZName"],
          
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>


          
        </div>
      </div>
    </>
  );
};

export default PercentageofFacilityStockoutbyProducts;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
