import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
   
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody"; 

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RcProductModal = ({ handleClose, open, addProduct, ...props }) => {
let tblLotRef = null;
let submitBtnRef = useRef();
const classes = useStyles();
const [dataItems, setDataItems] = useState([]);
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "rc-product-entry-form";

const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
const [selectedProducts, setselectedProducts] = useState([]);
const [currProductGroupId, setCurrProductGroupId] = useState(0);
const FacilityId = localStorage.getItem("FacilityId");
const StoreId = localStorage.getItem("StoreId");

  const [tabledata, setTabledata] = useState([]); 


  let params = {
    menukey: menukey,
    action: "getAllProductItems",
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan:lan,
    StoreId:StoreId,
    ItemGroupId:currProductGroupId,
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getAllProductItemsmodal,{
      onSuccess: (data) => {
       
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    

    {
      title: "Group",
      field: "GroupName",
      headerFilter: true,
      width: 165,
      visible: false,
    },
    
    { title: DispensingLanguage[lan][menukey]['SL'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Product Code'], field: "ItemCode", width: 200, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Product Name'], field: "ItemName", headerFilter:true },
    
  ];

  
  const ProdustDataItemsLot = tabledata?.map((item, index) => { 
    return item;
  });
  const SaveSelectedProducts = () => { 
    if(tblLotRef.table.getSelectedData().length>0){
      if (submitBtnRef == null) return;
      else submitBtnRef.current.disabled = true;
      addProduct(tblLotRef.table.getSelectedData());  
    }  
  };

  const checkSelect = (row) => { 
    console.log(row);
    console.log("row");
  };

const [all, setAll] = React.useState('0');
const handleProductGroupChange = (event) => {
  setAll(event.target.value);
  setCurrProductGroupId(event.target.value);
  
};

  useEffect(() => { 
    if (tblLotRef == null) return;
    


}, []); 
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg" 
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Products')}
        >
          {DispensingLanguage[lan][menukey]['Add Product']} 
        </BootstrapDialogTitle>


        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
                  <Grid item xs={5} sm={5}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Product Group"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="ItemGroupId"
                        name="ItemGroupId"
                        //value={currMonthId}
                        value={all}
                        onChange={handleProductGroupChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ProductGroupList.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>             
            </Grid>
          </CardContent>
        </Card>


        <DialogContent dividers>
          <ReactTabulator
            ref={(rf) => (tblLotRef = rf)}
            options={{
              selectableRangeMode: 'click',
              groupBy: "GroupName",
              columnCalcs: "both",
             }}
            selectable={true}
            columns={ProductColumns}
            data={dataItems}
            height="300px"
            layout={"fitColumns"}
            //selectableCheck={(row)=>checkSelect(row)}


          />
        </DialogContent>
        <DialogActions>
          <Button
            ref={submitBtnRef}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]['Submit']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Products')}
          >
           {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RcProductModal;



const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});