import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

import ImportASNDESADV from "./ImportASNDESADV";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  
  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  return (
    <div>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.PatientImportContainer}`}>
        <Switch>
          <Route path={`${path}/`} component={ImportASNDESADV}></Route>
        </Switch>
      </div>
    </div>
  );
};

export default Index;

const useStyles = makeStyles({
  PatientImportContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
