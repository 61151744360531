import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import GMap from "../../../services/GMap"; 

const FacilityFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "facilities-list";

  const classes = useStyles();
  
  return (

    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

          <div className="sw_page_heading">
            <div className="sw_heading_title">{ DispensingLanguage[lan][menukey]['Facility'] }</div>
          
          <div className="float-right sw_btn_control">

            {/* Action buttons */}
          {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.handleReset()}
                >
                  { DispensingLanguage[lan][menukey]['Reset'] }
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleSubmit()}
                >
                  { DispensingLanguage[lan][menukey]['Save'] } 
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/order")}
                >
                  { DispensingLanguage[lan][menukey]['Back to List'] } 
                </Button>
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">
              
                
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/facilities-list")}
                >
                  { DispensingLanguage[lan][menukey]['Back to List'] } 
                </Button>


              </Grid>
          }
           {/* End Action buttons */}

          </div>
          </div>
        </Grid>
      </div>


      {/* New row */}
      <Grid container spacing={3}>

          
          <Grid item xs={7} sm={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>

                <Card className="sw_card">
                <CardHeader title={ DispensingLanguage[lan][menukey]["Facility Details"] } />
                <CardContent className="map_body">

                  <Grid container spacing={3}>
                     
                     <Grid item xs={6} sm={6}>

                     <ul className="item_details_list">
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Code"]}</div>
                      <div className="desc">{ formData.FacilityCode ? (formData.FacilityCode) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Type"]}</div>
                      <div className="desc">{ formData.FTypeName ? (formData.FTypeName) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Department Name"]}</div>
                      <div className="desc">{ formData.RegionName ? (formData.RegionName) : ('---') }</div>
                    </li>
               
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Commune Name"]}</div>
                      <div className="desc">{ formData.DistrictName ? (formData.DistrictName) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Service Area"]}</div>
                      <div className="desc">{ formData.ServiceAreaName ? (formData.ServiceAreaName) : ('---') }</div>
                    </li>

                    

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Phone"]}</div>
                      <div className="desc">{ formData.FacilityPhone ? (formData.FacilityPhone) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility In-Charge"]}</div>
                      <div className="desc">{ formData.FacilityInCharge ? (formData.FacilityInCharge) : ('---') }</div>
                    </li>

                   
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Latitude"]}</div>
                      <div className="desc">{ formData.Latitude ? (formData.Latitude) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["SOBAPS Code"]}</div>
                      <div className="desc">{ formData.SOBAPSCode ? (formData.SOBAPSCode) : ('---') }</div>
                    </li>

                    

                    </ul>
                        
                     </Grid>

                     <Grid item xs={6} sm={6}>

                     <ul className="item_details_list">
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Name"]}</div>
                      <div className="desc">{ formData.FacilityName ? (formData.FacilityName) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Classification"]}</div>
                      <div className="desc">{ formData.FClassificationName ? (formData.FClassificationName) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["ZS Name"]}</div>
                      <div className="desc">{ formData.ZoneName ? (formData.ZoneName) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Owner Type"]}</div>
                      <div className="desc">{ formData.OwnerTypeName ? (formData.OwnerTypeName) : ('---') }</div>
                    </li>

                   
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Address"]}</div>
                      <div className="desc">{ formData.FacilityAddress ? (formData.FacilityAddress) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility Email"]}</div>
                      <div className="desc">{ formData.FacilityEmail ? (formData.FacilityEmail) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["DHIS2 Facility Uid"]}</div>
                      <div className="desc">{ formData.ExternalFacilityId ? (formData.ExternalFacilityId) : ('---') }</div>
                    </li>


                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Longitude"]}</div>
                      <div className="desc">{ formData.Longitude ? (formData.Longitude) : ('---') }</div>
                    </li>

                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["GLN"]}</div>
                      <div className="desc">{ formData.GLN ? (formData.GLN) : ('---') }</div>
                    </li>

                    </ul>
                        
                     </Grid>

                     {/* <Grid item xs={6} sm={6}>
                        <TextField
                         id="FTypeName"
                         name="FTypeName"
                         label={DispensingLanguage[lan][menukey]['Facility Type']}
                         value={formData.FTypeName}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="RegionName"
                         name="RegionName"
                         label={DispensingLanguage[lan][menukey]['Department Name']}
                         value={formData.RegionName}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>


                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="ZoneName"
                         name="ZoneName"
                         label={DispensingLanguage[lan][menukey]['ZS Name']}
                         value={formData.ZoneName}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="DistrictName"
                         name="DistrictName"
                         label={DispensingLanguage[lan][menukey]['Commune Name']}
                         value={formData.DistrictName}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="OwnerTypeName"
                         name="OwnerTypeName"
                         label={DispensingLanguage[lan][menukey]['Owner Type']}
                         value={formData.OwnerTypeName}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="ServiceAreaName"
                         name="ServiceAreaName"
                         label={DispensingLanguage[lan][menukey]['Service Area']}
                         value={formData.ServiceAreaName}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="FacilityAddress"
                         name="FacilityAddress"
                         label={DispensingLanguage[lan][menukey]['Facility Address']}
                         value={formData.FacilityAddress}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="FacilityInCharge"
                         name="FacilityInCharge"
                         label={DispensingLanguage[lan][menukey]['Facility In-Charge']}
                         value={formData.FacilityInCharge}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>



                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="FacilityPhone"
                         name="FacilityPhone"
                         label={DispensingLanguage[lan][menukey]['Facility Phone']}
                         value={formData.FacilityPhone}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="FacilityEmail"
                         name="FacilityEmail"
                         label={DispensingLanguage[lan][menukey]['Facility Email']}
                         value={formData.FacilityEmail}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="Latitude"
                         name="Latitude"
                         label={DispensingLanguage[lan][menukey]['Latitude']}
                         value={formData.Latitude}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="Longitude"
                         name="Longitude"
                         label={DispensingLanguage[lan][menukey]['Longitude']}
                         value={formData.Longitude}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>


                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="ExternalFacilityId"
                         name="ExternalFacilityId"
                         label={DispensingLanguage[lan][menukey]['DHIS2 Facility Uid']}
                         value={formData.ExternalFacilityId}
                         fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>

                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="SOBAPSCode"
                         name="SOBAPSCode"
                         label={DispensingLanguage[lan][menukey]['SOBAPS Code']}
                         value={formData.SOBAPSCode}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid>


                     <Grid item xs={6} sm={6}>
                        <TextField
                         id="GLN"
                         name="GLN"
                         label={DispensingLanguage[lan][menukey]['GLN']}
                         value={formData.GLN}
                           fullWidth
                         autoComplete="family-name"
                          disabled={true}
                          onChange={(e) => handleChange(e)}
                        />
                     </Grid> */}

                     

                     
            
                  </Grid>

                  </CardContent>
                 </Card>

                </Grid>
             </Grid>
          </Grid>

           
          <Grid item xs={5} sm={5}>

             <Card className="sw_card">
             <CardHeader title={ DispensingLanguage[lan][menukey]["Map"] } />
                <CardContent className="map_body"> 

             
                  <GMap
                   
                    formData={formData} 
                    {...props}
                  >

                  </GMap>
                           
            
                  

             </CardContent>
             </Card> 


          </Grid>



      </Grid>


    </div>
  </div>

    
  );
};

export default FacilityFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
