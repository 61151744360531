import React, { useState, useEffect,useContext,Component,useRef } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import {Select, FormControl,Grid, Card,CardHeader,CardContent,MenuItem,InputLabel,Typography,TextField} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import * as ScanBarcode from '../../services/ScanBarcode';


import {
  Launch, ViewList
 } from "@material-ui/icons";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Notification from "../../services/Notification";


const ContextDashboard = (props) => {


  exporting(Highcharts); 

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1=obj;
    if(obj.duration==undefined)
    obj1={...obj,duration:900}
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [tableDataList, settableDataList] = useState([]);

  const [toggleButtonValue, setToggleButton] = React.useState("30");

  const [scanObj, setscanObj] = useState({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:''});

  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      //console.log(tmpToggleButtonValue);
      setToggleButton(tmpToggleButtonValue);
     // console.log("Scan Object details");
    }
  };
  
  // Filter Start
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);

  const [isShown, setIsShown] = useState(false);
  
  let initialstock_by_expiry_date = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 160,
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',

    },
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: false
          , style: {
            textShadow: false,
            textOutline: false
          }
          , formatter: function () {
            return Highcharts.numberFormat(this.y);

          }
        },
        tooltip: {
          shared: true
        }
      },
      pie: {
        plotBorderWidth: 0,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
          , style: {
            textShadow: false,
            textOutline: false
          }
          , format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
        },
        showInLegend: true,
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
          shared: true
        }
      }
    },



    series: [
      {
        name: DispensingLanguage[lan][menukey]["# of Lots"],
        colorByPoint: true,
        data: [],
      },
    ],
  };
  const [stock_by_expiry_date, setOption_stock_by_expiry_date] = useState(initialstock_by_expiry_date);
  let initialDispenseBalanceLineChart={
    chart: {
      type: "spline",
    },
    title: {
      text: null
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: []
    },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: [],
        data: [],
        color: "#002f6c",
      },
    ],
    //series: data.DispenseTrend.series
  };
  const [DispenseBalanceLineChart, setDispenseBalanceLineChart] = useState(initialDispenseBalanceLineChart);
  const [TotalLotQty, setTotalLotQty] = useState(0);
  
  let initialBrandInfo=  {
    BrandCode: '---', CommercialName: '---', CameCode: '---',
    EAGTIN: '---', PKGTIN: '---', CAGTIN: '---',
    PLGTIN: '---', ItemName: '---', ManufacturerName: '---',
    ManufacturerAddress: '---', ProductStatus: '---', SIGIPCode: '---',
    StrengthName: '---', PacksizeName: '---', DosageFormName: '---',
    DciName: '---', DemanderName: '---', PaysExploitant: '---',
    SOBAPSCode: '---', RepresentantLocalouExploitant: '---', VoieAdministration: '---',
    DateExpirationDeLAMM: '---', CondDelivrance: '---', CodeAMM: '---',
    PGHTPresentation: '---', PGHTPrixPublic: '---', DateDeDebut: '---',
    DateExpiration: '---', ClasseTherapeutique: '---', GPCCode: '---'
  };

  const [BrandInfo, setBrandInfo] = useState(initialBrandInfo);

  
   

 // Facility Change
 const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname='';
    if(newValue == null){
      rowId = '';
      Fname='';
    }else{
      rowId = newValue.id;
      Fname=newValue.name;
    }

   if(rowId!==currFacilityId){
      setCurrFacilityId(rowId);
    }

 };

 // End Facility Change


 const handleClick = event => {
  // 👇️ toggle shown state
  setIsShown(current => !current);

  // 👇️ or simply set it to true
  // setIsShown(true);
};




  // End Filter

  


  /* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=getLotBalance" +
      "&reportType=" +
      reportType +
      "&CommercialName=" +
      BrandInfo.CommercialName +
      "&GenericCode=" +
      BrandInfo.CameCode +
      "&Generic=" +
      BrandInfo.ItemName +
      "&IntituleFabriquant=" +
      BrandInfo.ManufacturerName +
      "&GTIN=" +
      scanObj.GTIN +
      "&ExpiryDate=" +
      scanObj.ExpiryDate +
      "&SerialNo=" +
      scanObj.SerialNo +
      "&BatchNo=" +
      scanObj.BatchNo +
      "&Brandcode=" +
      BrandInfo.BrandCode +
      "&FacilityId=" +
      currFacilityId +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
  );
};

  /* =====End of Excel Export Code==== */


  
  const goToFacilityClassification = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/facilities-by-classification`
    );
   }


   const LotColumns = [
    {
      title: DispensingLanguage[lan][menukey]["SL"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Brand Code"],
      field: "BrandNo",
      headerSort: false,
      width: 190,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerSort: false,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["EAGTIN"],
      field: "EAGTIN",
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["PKGTIN"],
      field: "PKGTIN",
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["CAGTIN"],
      field: "CAGTIN",
      headerSort: false,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["PLGTIN"],
      field: "PLGTIN",
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["SSCCCarton"],
      field: "SSCCCarton",
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["SSCCPallet"],
      field: "SSCCPallet",
      headerSort: false,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "LotQty",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    }
  ];
   

  
  //------
const scan_gtin_or_brandcode = useRef(null);
const handleRefetchCall = (data) => {


  if(data.success==0){

       
    setscanObj(data.ScanObj);
    settableDataList([]);
    setTotalLotQty(0);
    setBrandInfo(initialBrandInfo);
    setOption_stock_by_expiry_date(initialstock_by_expiry_date);
    setDispenseBalanceLineChart(initialDispenseBalanceLineChart);

  }else{
  //props.refetch();
  //console.log("Data deeeeeT",data);
  scan_gtin_or_brandcode.current.value = "";
  //console.log("Amader  g",data.Data1.ExpiryStock.series_data);
  settableDataList(data.Data1.LotBalanceFacility);
  setTotalLotQty(data.Data1.TotalStock);
  setBrandInfo(data.Data1.productdetail[0]);
  setscanObj(data.ScanObj);
  


  // Stock by expiry date start
  setOption_stock_by_expiry_date({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 450,
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: false
          ,style: {
            textShadow: false,
            textOutline: false 
          }
          ,formatter: function () {
             return Highcharts.numberFormat(this.y);
  
          }
        },
        tooltip: {
          shared:true
        }
      },
      pie: {					
        plotBorderWidth: 0,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
          ,style: {
            textShadow: false,
            textOutline: false 
          }
          ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
        },
        showInLegend: true,
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
          shared:true
        }
      }
    },
    
    

    series: [
      {
        name: DispensingLanguage[lan][menukey]["# of Lots"],
        colorByPoint: true,
        data: data.Data1.ExpiryStock.series_data,
      },
    ],
  })

  // End Stock by expiry date

  

  // Dispensed Trend Line Chart
  setDispenseBalanceLineChart({
    chart: {
      type: "spline",
    },
    title: { 
      text: data.Data1.DispenseTrend.duration
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: data.Data1.DispenseTrend.category
    },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: data.Data1.DispenseTrend.name,
        data: data.Data1.DispenseTrend.series,
        color: "#002f6c",
      },
    ],
    //series: data.DispenseTrend.series
  });
  // End Dispensed Trend Line Chart

}

};


const [showscan, setshowscan] = React.useState("");
useEffect(() => {
 
 
}, [showscan]);


const doActionScanBarcode = () => {
  
  setshowscan(document.getElementById("scan_gtin_or_brandcode").value);

  const ScanBarcodeParams = {
      TransactionTypeId: 1,
      StoreId: '',
      TransactionId: '',
      FacilityId: currFacilityId,
      FacilityLevelId: 1,
      ReceiveFromId: '',
      AdjTypeId: 0,
      lan: lan,
      menukey: menukey,
      handleRefetchCall: handleRefetchCall,
      UserId:UserId,
      UserName:UserName,
      UserRole:UserRole,
      ScanFor:"ContextDashboard",
      ErrorModal:openNoticeModal
    }
    
 ScanBarcode.ScanBarcodeAction(ScanBarcodeParams,  {...props}); 
};


const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
     // scan_gtin_or_brandcode.current.value+= String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};

  //------

  useEffect(() => {
 // console.log("Column Values",tableDataList);
    }, [tableDataList]);

/*
  useEffect(() => {
    //getDepartmentWiseFacilityType.refetch();
    //getTotalFacility.refetch();
   // getFacilityTypeChart.refetch();
   // getFacilityClassification.refetch();
    //getFacilityOwnerType.refetch();
  }, [currRegionId]);

  */


  useEffect(() => {
    //console.log("Call scan obj",scanObj);
    //let New1 = scanObj.BrandCode;
    //console.log("Hello 1",scanObj.Brandcode)
    }, [scanObj]);
    
  

// useEffect(() => {
//   setManyTableData(manyTableData);    
// }, [props.ManyTableData]);




const gettoggleButtonValueType11 = useQuery(
  ["BrandLicenseExpiryDate"],
  () => api.getDispenseTrendContextDashboard(toggleButtonValue,scanObj.Brandcode,
    scanObj.GTIN,scanObj.BatchNo,scanObj.ExpiryDate,scanObj.SerialNo,currFacilityId),
  {
    onSuccess: (data) => {
      //setDataItems(data);
      //console.log("ppppppppppppppppppppp",data);

      if (data.status == 200) {  

        //console.log("fi  nnnnn",data.gstocktakelist.DispenseTrend.series);

      // Dispensed Trend Line Chart
  setDispenseBalanceLineChart({
    chart: {
      type: "spline",
    },
    title: { 
      text: data.gstocktakelist.DispenseTrend.duration
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: data.gstocktakelist.DispenseTrend.category
    },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: data.gstocktakelist.DispenseTrend.name,
        data: data.gstocktakelist.DispenseTrend.series,
        color: "#002f6c",
      },
    ],
    //series: data.DispenseTrend.series
  });
  // End Dispensed Trend Line Chart

      }


    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  }
);


useEffect(() => {
  gettoggleButtonValueType11.refetch();
}, [toggleButtonValue]);




  return (
    <>
     <AfterLoginNavbar {...props} />
     <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>

     <div className={`section signup-top-padding ${classes.Container}`}>
      <div className={classes.PageTitle}>

 
        <div className="d-flex justify-product">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
               {DispensingLanguage[lan][menukey]["Context Dashboard"]}
              </div>
            </div>
          </Grid>
        </div>





        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              

            <Grid item xs={4} sm={4}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole==1?false:true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>


                <Grid item xs={4} sm={4} id="FID_scan_gtin_or_brandcode">
                    <FormControl className={classes.fullWidth}>
                    <TextField
                        //disabled={props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                        
                        id="scan_gtin_or_brandcode"
                        name="scan_gtin_or_brandcode"
                        label={DispensingLanguage[lan][menukey]["SCAN GTIN"]} 
                        
                        autoComplete="family-name"
                        ref={scan_gtin_or_brandcode}
                        onKeyPress={handleKeyPress} 
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp}
                        
                        
                    />
                    </FormControl>
                            
                </Grid> 

                <Grid item xs={1} sm={1} id="FID_scanButton">
                    <Button

                   // disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                    id="scanButton"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickScanBarcode()} 
                    >
                    {DispensingLanguage[lan][menukey]["Scan"]}
                    </Button> 
                </Grid>
                    


                <div className="search_control_group dnone" id="scan_sscc_hide_show">
                    <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                    <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                </div>



            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

            {/* start of filter */}
             <Card className="sw_card sw_filter_card">
                  <CardContent className="sw_filterCardContent">
                    <Grid container>


                      <div className="d-flex justify-product">
                        <Grid item xs={12} sm={12}>
                          <div className="sw_page_heading">
                            <div style={{fontSize:"19px",fontWeight:"bold"}}>
                            {DispensingLanguage[lan][menukey]["SCAN GTIN"]} :  <span>{showscan}</span>
                            
                            </div>
                          </div>
                        </Grid>
                      </div>


                    </Grid>
                  </CardContent>
                </Card>
                {/* end of filter */}



        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              
              <Grid item xs={12} sm={2}>
                <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Brand Code"]}</div>
                        <div className="desc">{BrandInfo.BrandCode}</div>
                      </li>
                </ul>                    
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Commercial"]}</div>
                        <div className="desc">{BrandInfo.CommercialName}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Generic Code"]}</div>
                        <div className="desc">{BrandInfo.CameCode}</div>
                      </li>
                  </ul>                               
              </Grid>



              <Grid item xs={12} sm={2}>
                <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Generic"]}</div>
                        <div className="desc">{BrandInfo.ItemName}</div>
                      </li>
                </ul>                    
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Intitule Fabriquant"]}</div>
                        <div className="desc">{BrandInfo.ManufacturerName}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["EAGTIN"]}</div>
                        <div className="desc">{BrandInfo.EAGTIN}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["PKGTIN"]}</div>
                        <div className="desc">{BrandInfo.PKGTIN}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["CAGTIN"]}</div>
                        <div className="desc">{BrandInfo.CAGTIN}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["SIGIP Code"]}</div>
                        <div className="desc">{BrandInfo.SIGIPCode}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Product Status"]}</div>
                        <div className="desc">{BrandInfo.ProductStatus}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Strength"]}</div>
                        <div className="desc">{BrandInfo.StrengthName}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Form"]}</div>
                        <div className="desc">{BrandInfo.DosageFormName}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={12}>

   
                      <>
                          <ViewList
                            onClick={handleClick}
                          />
                          <br></br><br></br>
                      </>
              </Grid>


              {isShown && (
               <>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["DCI"]}</div>
                        <div className="desc">{BrandInfo.DciName}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Pays Exploitant"]}</div>
                        <div className="desc">{BrandInfo.PaysExploitant}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Pays Fabricant"]}</div>
                        <div className="desc">{BrandInfo.PaysFabricant}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["SOBAPS Code"]}</div>
                        <div className="desc">{BrandInfo.SOBAPSCode}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Date Expiration DeLAMM"]}</div>
                        <div className="desc">{BrandInfo.DateExpirationDeLAMM}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Date Expiration"]}</div>
                        <div className="desc">{BrandInfo.DateExpiration}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Code AMM"]}</div>
                        <div className="desc">{BrandInfo.CodeAMM}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Voie Administration"]}</div>
                        <div className="desc">{BrandInfo.VoieAdministration}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Demander"]}</div>
                        <div className="desc">{BrandInfo.DemanderName}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Classe Therapeutique"]}</div>
                        <div className="desc">{BrandInfo.ClasseTherapeutique}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Cond Delivrance"]}</div>
                        <div className="desc">{BrandInfo.CondDelivrance}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Date De Debut"]}</div>
                        <div className="desc">{BrandInfo.DateDeDebut}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={4}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["Representant Localou Exploitant"]}</div>
                        <div className="desc">{BrandInfo.RepresentantLocalouExploitant}</div>
                      </li>
                  </ul>                               
              </Grid>

              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["PGHT Presentation"]}</div>
                        <div className="desc">{BrandInfo.PGHTPresentation}</div>
                      </li>
                  </ul>                               
              </Grid>


              <Grid item xs={12} sm={2}>
                  <ul className="item_details_list">
                      <li>
                        <div className="title">{DispensingLanguage[lan][menukey]["PGHT Prix Public"]}</div>
                        <div className="desc">{BrandInfo.PGHTPrixPublic}</div>
                      </li>
                  </ul>                               
              </Grid>


              </>
              )}
              


            </Grid>
          </CardContent>
        </Card>
        {/*--------------------*/}


        
       
       





        <div className="row">          
          <div className="col-md-12 mb-12">
            <Card className="sw_card mb-2">
              <CardContent>

                <div className="row mb-3">
                    
                    <div className="col-md-12">
                      <div className="stat-cell bgPrimary">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{ TotalLotQty }</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Total_Stock"]}</span>
                      </div>
                    </div>

                </div>
                
              </CardContent>
            </Card>        
          </div>     
        </div>



            {/* new row */}
            <div className="row">

                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                   <CardHeader
                      title={DispensingLanguage[lan][menukey]["Stock by Expiry Date"]}
                      
                    /> 
                  
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={stock_by_expiry_date}
                        height={450}
                      />
                    </CardContent>
                  </Card>  
                </div>

              



                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                   <CardHeader
                      title={DispensingLanguage[lan][menukey]["Dispense Trend"]}
                    
                    /> 
                  
                    <CardContent>

                    <div className="col-md-12">

                  
                        <ToggleButtonGroup
                          className="sw_ToggleButtonGroup"
                          color="primary"
                          value={toggleButtonValue}
                          exclusive
                          onChange={handleChangeToggle}

                        >
                          <ToggleButton value="30">
                            {DispensingLanguage[lan][menukey]['1 Month']}
                          </ToggleButton>

                          <ToggleButton value="90">
                            {DispensingLanguage[lan][menukey]['3 Month']}
                          </ToggleButton>

                          <ToggleButton value="180">
                            {DispensingLanguage[lan][menukey]['6 Month']}
                          </ToggleButton>

                          <ToggleButton value="360">
                              {DispensingLanguage[lan][menukey]['1 Year']}
                          </ToggleButton>

                          
                        </ToggleButtonGroup>

                      </div>

                      <HighchartsReact
                        highcharts={Highcharts}
                        options={DispenseBalanceLineChart}
                        
                      />
                    </CardContent>
                  </Card>  
                </div>





            </div>
            {/* end of row */}


            {/* new row */}
            <div className="row">

                
                <div className="col-md-12 mb-4">

                <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Lot Balance"]}
                  action={
                    <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button> */}
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                   
                  </div>

                
                  }

      
                />
                


                <CardContent>
                  <ReactTabulator
                    columns={LotColumns}
                    data={tableDataList}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card> 

                </div>





            </div>
            {/* end of row */}

         

          

        </div>
      </div>
    </>
  );
};

export default ContextDashboard;

const useStyles = makeStyles({
    Container: {
      backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center",
      minHeight: "753px",
    },
    PageTitle: {
      marginTop: "60px",
      color: "white",
      background: "whitesmoke",
      color: "black",
      borderRadius: "10px",
      padding: "1rem",
    },
    tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
    fullWidth: {
      width: "95%",
    },
    filterDiv: {
      width: "80%",
      display: "flex",
    },
  });