import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, Card,CardContent,FormControl, InputLabel, Select,  MenuItem, } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";


let tblMenuRef = null;


const Errorlog = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "error-log";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;
const UserList = JSON.parse(localStorage.getItem("UserList"));

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  
  

  let params0 = {
    menukey: menukey,
    action: "getDataList",
  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params0],
    api.getErrorLogData,
    {
      onSuccess: (data) => {
        setDataItems(data);
    
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  


  

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    { title: DispensingLanguage[lan][menukey]["Remote IP"], width: 110,field: "RemoteIP",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["User"], width: 90, field: "userName",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["Date"],width: 140, field: "logDate",headerFilter: true, },
    
    
    { title: DispensingLanguage[lan][menukey]["Query Type"],width: 70, field: "queryType",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["Query"], field: "query",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["Error No"],width: 80, field: "errorNo",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["Error Message"], field: "errorMsg",headerFilter: true, },

    
  ];

  

  
  


 


  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Error Log"]}</div>
             
               
                </div>
          </Grid>

        </div>

        




        
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>

                <div className="sw_relative">
                  <div className="uniqueName">
                    <ReactTabulator 
                    ref={(r) => (tblMenuRef = r)}
                      
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      height={"600px"}
                     
                      
                    />
                   </div>
                </div>
              </Grid>

               
              </Grid>
            </Grid>
         
      </div>
    </>
  );
};

export default Errorlog;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
