import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import {
  Select,
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,

} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import * as ScanBarcode from '../../services/ScanBarcode';

import { Button } from "reactstrap";
import moment from "moment";
import Notification from "../../services/Notification";
import TracTrialModal from "../../services/TracTrialModal";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ViewList } from "@material-ui/icons";
const FacilityDashboard = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-serial-tracking-report";
  const FacilityName = localStorage.getItem("FacilityName");



  const FacilityId = localStorage.getItem("FacilityId");
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  // Facility Auto
  const Facility_List = JSON.parse(localStorage.getItem("getFacilityListEqual99"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const [currFacilityId, setCurrFacilityId] = useState(0);
  const [FacilityNames, setFacilityName] = useState([]);

  // Brand List
  const Brand_List = JSON.parse(localStorage.getItem("BrandList"));
  let All_Brand_List_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const BrandList = [All_Brand_List_label].concat(Brand_List);
  const [BrandName, setBrandName] = useState([]);

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product_Group].concat(Product_Group);
  const [GroupName, setGroupName] = useState([]);
  const [tmpProductlist, setProductlist] = useState([]);
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Productlist = [All_Product].concat(tmpProductlist);
  //let defaultBrandBrandList = BrandList[0].id;
  const [currBrandListId, setCurrBrandListId] = useState(0);
  const [currProductGroupId, setProductGroupId] = useState(0);
  const [acValue, setACValue] = useState("");
  const [currProductId, setProductId] = useState(0);
  const [scanObj, setscanObj] = useState({ GTIN: '', Brandcode: '', BatchNo: '', ExpiryDate: '', SerialNo: '', SSCC: '', SerialNo: '' });

  const [getCount, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [ToggleButtonDispensefilter, setToggleButtonDispensefilter] = React.useState("-1");
  const [ToggleButtonReceivefilter, setToggleButtonReceivefilter] = React.useState("-1");

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };



  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
      setFacilityName(rowName);
      setscanObj({ GTIN: '', Brandcode: '', BatchNo: '', ExpiryDate: '', SerialNo: '', SSCC: '', SerialNo: '' });
    }



    //getProductSerialTrackingReport.refetch();

  };

  // Facility Change
  const handleBrandChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currBrandListId) {
      setCurrBrandListId(rowId);
      setBrandName(rowName);
      setscanObj({ GTIN: '', Brandcode: '', BatchNo: '', ExpiryDate: '', SerialNo: '', SSCC: '', SerialNo: '' });
    }


  };


  React.useEffect(() => {
    if (currProductGroupId == 0) {

      let ProductlistParam1 = { action: "gProductList", ItemGroupId: "0", menukey: "" }
      cProductList(ProductlistParam1);
    }


  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowName = '';
    let rowId = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setGroupName(rowName);
      setscanObj({ GTIN: '', Brandcode: '', BatchNo: '', ExpiryDate: '', SerialNo: '', SSCC: '', SerialNo: '' });

    }

    let ProductlistParam = { action: "gProductList", ItemGroupId: newValue.id, menukey: "" }
    cProductList(ProductlistParam);


  };


  // isstck Change
  const handleProductChange = (event, newValue) => {
    setACValue(newValue);
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;


    }
    if (rowId !== currProductId) {
      setProductId(rowId);

    }
  };


  const { mutate: cProductList } = useMutation(
    api.aProductList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          // console.log("Dta list", data.data.datalist);

          setProductlist(data.data.datalist);
          setACValue({ id: '0', name: "All" });


        }

      },
    }
  );


  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getProductSerialTrackingReport",
    lan: lan,
    FacilityId: currFacilityId,
    BrandListId: currBrandListId,
    ItemGroupId: currProductGroupId,
    ItemNo: currProductId,
    bDispensed:ToggleButtonDispensefilter,
    IsReceived:ToggleButtonReceivefilter,
    // StartDate: selectedStartDate,
    // EndDate: selectedEndDate,

  };

  const { data } = useQuery(
    [params],
    api.getProductSerialTrackingReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
        setCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [NumberOfDispensecount, setNumberOfDispense] = useState([]);
  const [NumberOfNotDispensecount, setNumberOfNotDispense] = useState([])
  const [TotalProductSerialcount, setTotalProductSerial] = useState([])

  let params11 = {
    menukey: menukey,
    action: "getNumberOfDispense",
    lan: lan,
  };

  const { data: dispensecount } = useQuery(
    [params11],
    api.getNumberOfDispense,
    {
      onSuccess: (data) => {

        setNumberOfDispense(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  let params1 = {
    menukey: menukey,
    action: "getNumberOfNotDispense",
    lan: lan,


  };

  const { data: Notdispensecount } = useQuery(
    [params1],
    api.getNumberOfNotDispense,
    {
      onSuccess: (data) => {

        setNumberOfNotDispense(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  let params2 = {
    menukey: menukey,
    action: "getTotalNumberOfProductSerial",
    lan: lan,


  };
  const [BrandDemanderManufacturerTotal, setBrandDemanderManufacturerTotal] = useState([]);

  const { data: TotalProductserialcount } = useQuery(
    [params2],
    api.getTotalNumberOfPSerial,
    {
      onSuccess: (data) => {

        setTotalProductSerial(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [openSqlLog, setopenSqlLog] = useState(false);
  const [LogDataArray, setLogDataArray] = useState([]);
  const handleClose = (modalname) => {
    switch (modalname) {

      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };

  const handleChangeToggleDispense = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      setToggleButtonDispensefilter(tmpToggleButtonValue);
    }
  };

  const handleChangeTogglereceived = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      setToggleButtonReceivefilter(tmpToggleButtonValue);
    }
  };

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Invoice No"],
    //   field: "TransactionNo",
    //   headerSort: false,
    //   headerFilter: true,
    //   width: 160,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Invoice Date"],
    //   field: "TransactionDate",
    //   headerSort: false,
    //   width: 95,
    //   formatter: function (cell) {
    //     let value = cell.getValue();
    //     if (value != null)
    //       return moment(value).format("DD/MM/YYYY");
    //     else
    //       return "";
    //   },
    // },

    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FromFacilityName",
      headerSort: false,
      headerFilter: true,
      width: 130,
      //formatter: "textarea",
    },
    {
      title: DispensingLanguage[lan][menukey]["To"],
      field: "ToFacilityName",
      headerSort: false,
      headerFilter: true,
      width: 130,
      //formatter: "textarea",
    },
    {
      title: DispensingLanguage[lan][menukey]["Store"],
      field: "StoreName",
      headerSort: false,
      headerFilter: true,
      width: 60,
    },
/*
    {
      title: DispensingLanguage[lan][menukey]["Generic Code"],
      field: "ItemCode",
      headerSort: false,
      headerFilter: true,
      width: 120,
      // width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic"],
      field: "ItemName",
      headerFilter: true,
      headerSort: false,
      width: 120,
      //formatter: "textarea",
    },
    */
    {
      title: DispensingLanguage[lan][menukey]["Brand Code"],
      field: "BrandCode",
      headerFilter: true,
      headerSort: false,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Brand"],
      field: "BrandName",
      headerFilter: true,
      headerSort: false,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerFilter: true,
      headerSort: false,
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      headerSort: false,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 95,
      formatter: function (cell) {
        let value = cell.getValue();
        if (value != null)
          return moment(value).format("DD/MM/YYYY");
        else
          return "";
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Serial"],
      field: "SerialNo",
      headerSort: false,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispensed"],
      width: 100,
      headerHozAlign: "center",
      field: "bDispensed",
      hozAlign: "center",
      formatter: "tick",
    },
    {
      title: DispensingLanguage[lan][menukey]["Is Received"],
      width: 97,
      headerHozAlign: "center",
      field: "IsReceived",
      hozAlign: "center",
      formatter: "tick",
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 5,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },

  ];


  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {

            setLogDataArray(JSON.parse(rowData.TrackTrail));

            handleClose("ProductBatchOpen");

          }}
        />
      </>
    );

  }


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    if ((scanObj.GTIN == undefined) || ((scanObj.GTIN == '') && (scanObj.ExpiryDate == '') && (scanObj.SerialNo == '')
      && (scanObj.BatchNo == '') && (scanObj.Brandcode == '') && (scanObj.SSCC == ''))) {
      window.open(
        finalUrl +
        "?action=ProductSerialTrackingReportExport" +
        "&reportType=" +
        reportType +
        "&FacilityId=" +
        currFacilityId +
        "&BrandListId=" +
        currBrandListId +
        "&ItemGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        currProductId +
        "&FacilityName=" +
        FacilityNames +
        "&BrandName=" +
        BrandName +
        "&GroupName=" +
        GroupName +
        "&bDispensed=" +
        ToggleButtonDispensefilter +
        "&IsReceived=" +
        ToggleButtonReceivefilter +
        //  "&StartDate=" +
        //    StartDate +
        //    "&EndDate=" +
        //    EndDate +
        "&GTIN=" +
        scanObj.GTIN +
        "&ExpiryDate=" +
        scanObj.ExpiryDate +
        "&SerialNo=" +
        scanObj.SerialNo +
        "&BatchNo=" +
        scanObj.BatchNo +
        "&Brandcode=" +
        scanObj.Brandcode +
        "&SSCC=" +
        scanObj.SSCC +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
      )
    } else {
      window.open(
        finalUrl +
        "?action=ProductSerialTrackingReportExport" +
        "&reportType=" +
        reportType +
        "&FacilityId=" +
        currFacilityId +
        "&BrandListId=" +
        currBrandListId +
        "&ItemGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        currProductId +
        "&FacilityName=" +
        FacilityNames +
        "&BrandName=" +
        BrandName +
        "&GroupName=" +
        GroupName +
        "&bDispensed=" +
        ToggleButtonDispensefilter +
        "&IsReceived=" +
        ToggleButtonReceivefilter +
        // "&StartDate=" +
        //   StartDate +
        //   "&EndDate=" +
        //   EndDate +
        "&GTIN=" +
        scanObj.GTIN +
        "&ExpiryDate=" +
        scanObj.ExpiryDate +
        "&SerialNo=" +
        scanObj.SerialNo +
        "&BatchNo=" +
        scanObj.BatchNo +
        "&Brandcode=" +
        scanObj.Brandcode +
        "&SSCC=" +
        scanObj.SSCC +
        "&ScanFor=Reports" +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
      )
    }
  };

  /* =====End of Excel Export Code==== */




  // useEffect(() => {
  //   getProductSerialTrackingReport.refetch();

  // }, [currFacilityId, currBrandListId]);


  //-----------Scan Start--------------//

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1 = obj;
    if (obj.duration == undefined)
      obj1 = { ...obj, duration: 900 }
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgObj({ isOpen: false });
  };

  const handleRefetchCall = (data) => {
    console.log("Refetch Val", data.ScanObj);
    setDataItems(data.Data1);
    setscanObj(data.ScanObj);
  }

  const scan_gtin_or_brandcode = useRef(null);

  const doActionScanBarcode = () => {


    const ScanBarcodeParams = {
      ...params,
      TransactionTypeId: 1,
      FacilityLevelId: 1,
      AdjTypeId: 0,
      handleRefetchCall: handleRefetchCall,
      UserId: UserId,
      UserName: UserName,
      UserRole: UserRole,
      ScanFor: "Reports",
      ErrorModal: openNoticeModal
    }

    ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, { ...props });
  };


  const onClickScanBarcode = () => {
    doActionScanBarcode();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      doActionScanBarcode();
    }
  }


  let altValue = "";
  const handleKeyDown = (e) => {
    if (e.altKey) {
      if (e.which !== 18) {
        altValue += e.which;
      }
      if (altValue === "969698105") {
        document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.altKey === false) {
      altValue = "";
    }
  };


  function resetscan() {

    setscanObj({ GTIN: '', Brandcode: '', BatchNo: '', ExpiryDate: '', SerialNo: '', SSCC: '' });

  }
  //-----------Scan End---------------//





  return (
    <>
      <AfterLoginNavbar {...props} />
      <Notification
        closeNoticeModal={closeNoticeModal}
        msgObj={msgObj}
        {...props}
      ></Notification>
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>


          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Product Serial Tracking Report"]}
                </div>
              </div>
            </Grid>
          </div>

          <TracTrialModal
            handleClose={handleClose}
            open={openSqlLog}
            LogDataArray={LogDataArray}
            {...props}
          />


          {/* new row */}
          {/* <div className="row">

            <div className="col-md-12 mb-12">
              <Card className="sw_card mb-2">
                <CardContent>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="stat-cell bgPrimary">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{NumberOfDispensecount['Totaldispense']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Dispense"]}</span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="stat-cell bgInfo">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{NumberOfNotDispensecount['TotalNotDispense']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Not Dispense"]}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="stat-cell bgWarning">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{TotalProductSerialcount['TotalProductserial']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Total Number of Product Serial"]}</span>
                      </div>
                    </div>
                  </div>

                </CardContent>
              </Card>


            </div>



          </div> */}
          {/* end of row */}


          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                {/* <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["Start date"]
                        }
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid> */}


                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="BrandList"
                      disableClearable
                      options={BrandList}

                      onChange={(event, newValue) => handleBrandChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={BrandList[BrandList.findIndex(BrandList => BrandList.id == currBrandListId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Brand"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete

                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>


              </Grid>

              <Grid container>



                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) => handleProductChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Productlist[Productlist.findIndex(Productlist => Productlist.id == currProductId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField

                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={6} sm={6} id="FID_scan_gtin_or_brandcode">
                  <FormControl className={classes.fullWidth}>
                    <TextField
                      disabled={props.addProductForm == true ? true : props.bStockUpdate}

                      id="scan_gtin_or_brandcode"
                      name="scan_gtin_or_brandcode"
                      label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]}

                      autoComplete="family-name"
                      ref={scan_gtin_or_brandcode}
                      onKeyPress={handleKeyPress}
                      onKeyDown={handleKeyDown}
                      onKeyUp={handleKeyUp}


                    />
                  </FormControl>

                </Grid>

                <Grid item xs={2} sm={2} id="FID_scanButton">
                  <Button

                    // disabled={props.addProductForm == true ? true : props.bStockUpdate}
                    id="scanButton"
                    variant="contained"
                    color="info"
                    onClick={() => onClickScanBarcode()}
                  >
                    {DispensingLanguage[lan][menukey]["Scan"]}
                  </Button>
                </Grid>

                <div className="search_control_group dnone" id="scan_sscc_hide_show">
                  <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                  <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                </div>

                <Grid item xs={1} sm={1}>


                  <div className="float-right">
                    <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total'] + ": " + getCount}</h4>
                  </div>


                </Grid>



              </Grid>
              <Grid container>
                <Grid item xs={2} sm={2}>
                  <ToggleButtonGroup
                    className="sw_ToggleButtonGroup"
                    color="primary"
                    value={ToggleButtonDispensefilter}
                    exclusive
                    onChange={handleChangeToggleDispense}

                  >
                    <ToggleButton value="-1">
                      {DispensingLanguage[lan][menukey]['All']}
                    </ToggleButton>
                    <ToggleButton value="1">
                      {DispensingLanguage[lan][menukey]['Dispensed']}
                    </ToggleButton>
                    <ToggleButton value="0"
                    >
                      {DispensingLanguage[lan][menukey]['Not Dispensed']}
                    </ToggleButton>

                  </ToggleButtonGroup>

                </Grid>

                <Grid item xs={2} sm={2}>
                  <ToggleButtonGroup
                    className="sw_ToggleButtonGroup"
                    color="primary"
                    value={ToggleButtonReceivefilter}
                    exclusive
                    onChange={handleChangeTogglereceived}

                  >
                    <ToggleButton value="-1">
                      {DispensingLanguage[lan][menukey]['All']}
                    </ToggleButton>
                    <ToggleButton value="1">
                      {DispensingLanguage[lan][menukey]['Is Received']}
                    </ToggleButton>
                    <ToggleButton value="0"
                    >
                      {DispensingLanguage[lan][menukey]['Not Received']}
                    </ToggleButton>

                  </ToggleButtonGroup>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default FacilityDashboard;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});