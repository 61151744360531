import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "source/api/",
  // baseURL: "http://localhost/DISPENSINGMAIN/dispensing-mock/backend/source/api/",
});
const apiForRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "source/api/",
  // baseURL: "http://localhost/DISPENSINGMAIN/dispensing-mock/backend/source/api/",
});

export const body = {
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
};
const FacilityId = localStorage.getItem("FacilityId");
const StoreId = localStorage.getItem("StoreId");

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
const lan = localStorage.getItem("LangCode");

const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;

let options = {};
options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${auth_token}`,
    "Consumer": process.env.REACT_APP_CONSUMER,
    "Secret": process.env.REACT_APP_SECRET,
  },
};

//Products Api
const newToken = () => {

  apiForRefresh
    .post(
      `RefreshToken`,
      {
        ...body,
        action: "RefreshToken",
        menukey: "",
        userid: UserId
      },
      options
    )
    .then((res) => {
      
      return res.data;
    });

};

const refreshToken = () => {
  if (UserInfo != 0)
    if (Date.now() / 1000 > parseInt(sessionStorage.getItem("iat")))
      newToken();
  // else 
  // alert(Math.floor(Date.now()/1000)+'jjj'+sessionStorage.getItem("iat"))
};

api.interceptors.request.use((config) => {
  refreshToken();
  return config;
});

export const getProducts = (params) => {

  let param = params.queryKey[0];
  return api
    .post("productEntry", param, options)

    .then((res) => {
      return res.data.datalist;
    });
}


export const getProduct = (id) =>
  api.post(
    `productEntry`,
    { ...body, action: "getProduct", menukey: "generics", ItemNo: id },
    options
  );

export const updateProduct = ({ id, ...updatedProduct }) =>
  api
    .put(
      `productEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "generics",
        ItemNo: id,
        ...updatedProduct,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveProduct = (productData) =>
  api
    .post(
      `productEntry`,
      { ...body, action: "dataInsert", menukey: "generics", ...productData },
      options
    )
    .then((res) => res.data);

export const deleteProduct = (id) =>
  api
    .post(
      `productEntry`,
      {
        ...body,
        action: "dataDalete",
        menukey: "generics",
        ItemNo: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res.data);


//combo api
//Local Storage from Login Page
export const getLoginAllDropdown = async (options, task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
//Local Storage from meta or user entry page
export const getAllDropdown = (task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

//Local Storage from Login Page
// export const getLoginFacilityListNotEqual99ANDSuppliers = async (
//   options,
//   task
// ) =>
//   api
//     .post(
//       `comboscript`,
//       {
//         action: "getFacilityListNotEqual99ANDSupplier",
//         task: task,
//         menukey: "",
//         FacilityId: localStorage.getItem("FacilityId"),
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });
//Local Storage from meta or user entry page


//Admin Forms
export const getTotal = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Admin Forms
export const getunitofmeasurelist1 = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveunitofmeasure = (getunitofmeasure1) =>
  api
    .post(
      `unit_of_measure`,
      {
        ...body,
        action: "dataInsert",
        menukey: "Unitofmeasureentry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteunitofmeasure = (id) =>
  api.post(`unit_of_measure`, id, options);
export const getunitofmeasure = (id) =>
  api.post(
    `unit_of_measure`,
    {
      ...body,
      action: "getunit_measure",
      menukey: "Unitofmeasureentry",
      UnitId: id,
    },
    options
  );
export const updateunitof = ({ id, ...updatedunitof }) =>
  api
    .put(
      `unit_of_measure`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "Unitofmeasureentry",
        UnitId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

// manufacturer Api
export const getmanufacturer = () =>
  api.post("manufacturer", { ...body, action: "getDataList", menukey: "manufacturer" }, options)
    .then((res) => { return res.data.datalist; });

export const getmanufacturerData = (id) =>
  api.post(`manufacturer`, { ...body, action: "getmanufacturerData", menukey: "manufacturer", ManufacturerId: id, }, options);

export const updatemanufacturer = ({ id, ...updatedmanufacturer }) =>
  api.put(`manufacturer`, { ...body, action: "dataUpdate", menukey: "manufacturer", ManufacturerId: id, ...updatedmanufacturer, }, options)
    .then((res) => { return res; });

export const savemanufacturer = (manufacturerData) =>
  api.post(`manufacturer`, { ...body, action: "dataInsert", menukey: "manufacturer", ...manufacturerData }, options)
    .then((res) => res.data);
export const deletemanufacturer = (id) => api.post(`manufacturer`, id, options);


// Pack Size Api
export const getPackSizes = () =>
  api
    .post(
      "packSize",
      { ...body, action: "getDataList", menukey: "pack-size" },
      options
    )
    .then((res) => {
      console.log("Refresh Test: " + new Date());
      return res.data.datalist;
    });

export const getPackSizeData = (id) =>
  api.post(
    `packSize`,
    {
      ...body,
      action: "getPackSizeData",
      menukey: "pack-size",
      PacksizeId: id,
    },
    options
  );

export const updatePackSize = ({ id, ...updatedPackSize }) =>
  api
    .put(
      `packSize`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "pack-size",
        PacksizeId: id,
        ...updatedPackSize,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const savePackSize = (packSizeData) =>
  api
    .post(
      `packSize`,
      { ...body, action: "dataInsert", menukey: "pack-size", ...packSizeData },
      options
    )
    .then((res) => res.data);

export const deletePackSize = (id) => api.post(`packSize`, id, options);

// Form Api
export const getForms = () =>
  api
    .post("form", { ...body, action: "getDataList", menukey: "form" }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const getFormData = (id) =>
  api.post(
    `form`,
    { ...body, action: "getFormData", menukey: "form", DosageFormId: id },
    options
  );

export const updateForm = ({ id, ...updatedForm }) =>
  api
    .put(
      `form`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "form",
        DosageFormId: id,
        ...updatedForm,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveForm = (formData) =>
  api
    .post(
      `form`,
      { ...body, action: "dataInsert", menukey: "form", ...formData },
      options
    )
    .then((res) => res.data);

export const deleteForm = (id) => api.post(`form`, id, options);

// Strength Api
export const getStrengths = () =>
  api
    .post(
      "strength",
      { ...body, action: "getDataList", menukey: "strength" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getStrengthData = (id) =>
  api.post(
    `strength`,
    { ...body, action: "getStrengthData", menukey: "strength", StrengthId: id },
    options
  );

export const updateStrength = ({ id, ...updatedStrength }) =>
  api
    .put(
      `strength`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "strength",
        StrengthId: id,
        ...updatedStrength,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveStrength = (strengthData) =>
  api
    .post(
      `strength`,
      { ...body, action: "dataInsert", menukey: "strength", ...strengthData },
      options
    )
    .then((res) => res.data);

export const deleteStrength = (id) => api.post(`strength`, id, options);

//Dispensing Language List Api
export const getLoginDispensingLanguage = async (options) =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
export const getDispensingLanguage = () =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

// Ui Language Api

export const updateUiLanguage = (params) =>
  //console.log(params.id);
  api
    .put(
      `uiLanguage`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ui-language",
        LangLabelId: params.id,
        LangText: params.LangText,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUiLanguages = (params) =>
  api.post("uiLanguage", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getUiLanguageData = (id) =>
  api.post(
    `uiLanguage`,
    {
      ...body,
      action: "getUiLanguageData",
      menukey: "ui-language",
      LangLabelId: id,
    },
    options
  );

export const saveUiLanguage = (uiLanguageData) =>
  api
    .post(
      `uiLanguage`,
      {
        ...body,
        action: "dataInsert",
        menukey: "ui-language",
        ...uiLanguageData,
      },
      options
    )
    .then((res) => res.data);

// Facility Api api.js

export const updateFacility = (params) =>
  //console.log(params.id);
  api
    .put(
      `facility`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility",
        FacilityId: params.id,
        UserId: UserId,
        bDispense: params.bDispenseVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const AssignGroupFacility = (params) =>
  api
    .put(
      `facility`,
      {
        ...body,
        ...params,
        action: "insertUpdateFacilityGroupMapPopup",
        menukey: "facility",
        UserId: UserId
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getProductGroupMapPopup = (params) =>
  api
    .put(
      `facility`,
      {
        ...body,
        ...params,
        action: "getProductGroupMapPopup",
        menukey: "facility",
        UserId: UserId
      },
      options
    )
    .then((res) => {
      return res;
    });



export const getFacilityCode = (params) =>
  api.post("facility", { ...body, params }, options).then((res) => {
    return res.data.datalist;
  });

export const deleteaFacility = (id) => api.post(`facility`, id, options);

export const getFacilities = (params) =>
  api.post("facility", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const saveFacility = (saveFacilityData) =>
  api
    .post(
      `facility`,
      { ...body, action: "dataInsert", menukey: "facility", ...saveFacilityData },
      options
    )
    .then((res) => res.data);

export const updateSingleFacility = ({ id, ...updatedFacility }) =>
  api
    .put(
      `facility`,
      {
        ...body,
        action: "UpdateFacility",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "facility",
        FacilityId: id,
        UserId: UserId,
        ...updatedFacility,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilitiesList = (params) =>
  api.post("facility_list", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getSingleListView = (id) =>
  api.post(
    `facility_list_single_view`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility-list",
      FacilityId: id,
      UserId: UserId,
    },
    options
  );

export const getFacilitySingleView = (id) =>
  api.post(
    `facility`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility",
      FacilityId: id,
      UserId: UserId,
    },
    options
  );

export const getPatientTotal = (params) =>
  api.post("patients", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Role Entry from
export const getRole = () =>
  api
    .post("roles", { ...body, action: "getRoleList", menukey: "role" }, options)
    .then((res) => {
      return res.data.datalist;
    });
export const geRoles = (id) =>
  api.post(
    `roles`,
    { ...body, action: "geRole", menukey: "role", id: id },
    options
  );
export const saveRole = (getrole1) =>
  api
    .post(
      `roles`,
      { ...body, action: "dataInsert", menukey: "role", ...getrole1 },
      options
    )
    .then((res) => res.data);

export const deleteRole = (id) => api.post(`roles`, id, options);
export const updateRole = ({ id, ...updateRoles }) =>
  api
    .put(
      `roles`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "role",
        id: id,
        ...updateRoles,
      },
      options
    )
    .then((res) => {
      return res;
    });

//menu Insert
export const getmenuEntry = () =>
  api
    .post(
      "menuentry",
      { ...body, action: "getMenuList", menukey: "menuentry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveMenuentry = (userData) => api.post(`menuentry`, { ...body, action: "dataInsert", menukey: "menuentry", ...userData }, options)
  .then((res) => res.data);

export const updateMenuentry = ({ id, ...updatedMenu }) => api.put(`menuentry`, {
  ...body,
  action: "dataUpdate",
  menukey: "menuentry",
  id: id,
  ...updatedMenu,
},
  options
)
  .then((res) => {
    return res;
  });


export const deletemenu = (id) => api.post(`menuentry`, id, options);

export const geImenuentrys = (id) =>
  api.post(
    `menuentry`,
    { ...body, action: "gemenuEntry", menukey: "menuentry", id: id },
    options
  );

// User Entry Api
export const getUserList = () =>
  api
    .post(
      "userlist",
      { ...body, action: "getUserList", menukey: "user-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUser = (id) =>
  api.post(
    `userlist`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

export const updateUser = ({ id, ...updatedUser }) =>
  api
    .put(
      `userlist`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        ...updatedUser,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveUser = (userData) =>
  api
    .post(
      `userlist`,
      { ...body, action: "dataInsert", menukey: "user-entry", ...userData },
      options
    )
    .then((res) => res.data);
export const userDelete = (id) => api.post(`userlist`, id, options);

//Menu To Role Permission
export const getMenuListForRolePermission = (params) =>
	api.post("MenuToRolePermission", { params }, options).then((res) => {
		return res.data.datalist;
	});

export const getRoleListForMenuPermission = (params) =>
	api.post("MenuToRolePermission", { params }, options).then((res) => {
		return res.data.datalist;
	});
	
export const MenuToRoleInsert = (params) =>

  api
    .post(
      `MenuToRolePermission`,
      {
        ...body,
        action: "MenuToRoleDataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "role-access-entry",
        menu_id: params.selectedMenuId,
        role_id: params.role_id,
        bChecked: params.bCheckedVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

//role permission
export const getRoleAccessEntry = () =>
  api
    .post(
      "roleaccess",
      { ...body, action: "getRoleList", menukey: "roleaccess" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMenuPerEntry = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const rolesToMenuInsert = (params) =>
  //console.log(params.id);
  api
    .post(
      `roleaccess`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "role-access-entry",
        role_id: params.selectedRoleData,
        menu_id: params.menu_id,
        bChecked: params.bCheckedVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getMenuShortOrder = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const updateMenuShortOrder = (params) =>
  //console.log(params.id);
  api
    .put(
      `roleaccess`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "role-access-entry",
        MenuData: params.MenuData,
      },
      options
    )
    .then((res) => {
      return res;
    });


export const gZonelist = (zoneData) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...zoneData },
      options
    )
    .then((res) => {

      return res;

    });
};
export const aProductSubGroupList = (subgroupdata) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...subgroupdata },
      options
    )
    .then((res) => {

      return res;

    });
};
export const aShipmentItemsList = (shipmentitem) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...shipmentitem },
      options
    )
    .then((res) => {

      return res;

    });
};


export const gCommunelist = (communeData) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...communeData },
      options
    )
    .then((res) => {

      return res;

    });
};

//MOS Range Entry Api
export const getMOSRanges = () =>
  api
    .post(
      "mosrange",
      { ...body, action: "getDataList", menukey: "national-mos-type-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMOSRange = (id) =>
  api.post(
    `mosrange`,
    { ...body, action: "getMOSRange", menukey: "national-mos-type-entry", MosTypeId: id },
    options
  );

export const updateMOSRange = ({ id, ...updatedMOSRange }) =>
  api
    .put(
      `mosrange`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "mos-range",
        MosTypeId: id,
        ...updatedMOSRange,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveMOSRange = (mosrangeData) =>
  api
    .post(
      `mosrange`,
      { ...body, action: "dataInsert", menukey: "national-mos-type-entry", ...mosrangeData },
      options
    )
    .then((res) => res.data);

export const deleteMOSRange = (id) => api.post(`mosrange`, id, options);

// DCI Api
export const getDcis = () =>
  api
    .post(
      "dci",
      {
        ...body,
        action: "getDataList",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "dci",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getDciData = (id) =>
  api.post(
    `dci`,
    {
      ...body,
      action: "getDciData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "dci",
      DciId: id,
    },
    options
  );

export const updateDci = ({ id, ...updatedDci }) =>
  api
    .put(
      `dci`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "dci",
        DciId: id,
        ...updatedDci,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveDci = (dciData) =>
  api
    .post(
      `dci`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "dci",
        ...dciData,
      },
      options
    )
    .then((res) => res.data);

export const deleteDci = (id) =>
  api.post(
    `dci`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "dci",
      DciId: id,
    },
    options
  );

// Commercial Api
export const getCommercials = () =>
  api
    .post(
      "commercial",
      {
        ...body,
        action: "getDataList",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commercial",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getCommercialData = (id) =>
  api.post(
    `commercial`,
    {
      ...body,
      action: "getCommercialData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "commercial",
      CommercialId: id,
    },
    options
  );

export const updateCommercial = ({ id, ...updatedCommercial }) =>
  api
    .put(
      `commercial`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commercial",
        CommercialId: id,
        ...updatedCommercial,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveCommercial = (commercialData) =>
  api
    .post(
      `commercial`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commercial",
        ...commercialData,
      },
      options
    )
    .then((res) => res.data);

export const deleteCommercial = (id) =>
  api.post(
    `commercial`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "commercial",
      CommercialId: id,
    },
    options
  );

// Demander Api

export const getDemanders = (params) =>
  api.post("demander", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getDemanderData = (id) =>
  api.post(
    `demander`,
    {
      ...body,
      action: "getDemanderData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "demander",
      DemanderId: id,
    },
    options
  );

export const updateDemander = ({ id, ...updatedDemander }) =>
  api
    .put(
      `demander`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "demander",
        DemanderId: id,
        ...updatedDemander,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveDemander = (demanderData) =>
  api
    .post(
      `demander`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "demander",
        ...demanderData,
      },
      options
    )
    .then((res) => res.data);

export const deleteDemander = (id) =>
  api.post(
    `demander`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "demander",
      DemanderId: id,
    },
    options
  );

//Products List Api
export const getProductList = (params) =>
  api.post("productList", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductViewList = (params) =>
  api.post("productList", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const updateProductViewList = (params) =>
  //console.log(params.id);
  api
    .put(
      `productList`,
      {
        ...body,
        action: "UpdateESIGLFacilityProductListEntry",
        menukey: "product-list",
        ItemNo: params.ItemNo,
        colValue: params.colValue,
        FacilityId: params.FacilityId,
        fieldName: params.fieldName,
        SalesPriceVal: params.SalesPriceVal,
        StoreId: params.StoreId,
        UserId: params.UserId,
        lan: params.lan,
        UserName: UserName,
      },
      options
    )
    .then((res) => {
      return res;
    });



//Depatmnet
export const getdepartmententry = () => api.post("department", { ...body, action: "getDataList", menukey: "department" }, options).then((res) => {
  return res.data.datalist;
});
export const savedepartment = (departmentData) => api.post(`department`, { ...body, action: "dataInsert", menukey: "department", ...departmentData }, options)
  .then((res) => res.data);
export const getdepartmentData = (id) => api.post(`department`, { ...body, action: "getDepartmentData", menukey: "department", RegionId: id, }, options);
export const deletedepartment = (id) => api.post(`department`, id, options)
export const updatedepartment = ({ id, ...updatedepartments }) => api.put(`department`, { ...body, "action": "dataUpdate", "menukey": "direction", "RegionId": id, ...updatedepartments }, options).then((res) => {
  return res;
})


//ServiceArea
export const getServiceArea = () => api.post("Service", { ...body, action: "getDataList", menukey: "Service" }, options).then((res) => {
  return res.data.datalist;
});
export const saveServiceArea = (ServiceData) => api.post(`Service`, { ...body, action: "dataInsert", menukey: "Service", ...ServiceData }, options)
  .then((res) => res.data);
export const updateServiceArea = ({ id, ...updategetService }) => api.put(`Service`, { ...body, "action": "dataUpdate", "menukey": "Service", "ServiceAreaId": id, ...updategetService }, options).then((res) => {
  return res;
})
export const getServiceAreaData = (id) => api.post(`Service`, { ...body, action: "getServiceAreaData", menukey: "Service", ServiceAreaId: id, }, options);
export const deleteServiceArea = (id) => api.post(`Service`, id, options)


//Ownertype
export const getOwnertype = () => api.post("Ownertype", { ...body, action: "getDataList", menukey: "Ownertype" }, options).then((res) => {
  return res.data.datalist;
});
export const saveOwnertype = (OwnertypeData) => api.post(`Ownertype`, { ...body, action: "dataInsert", menukey: "Ownertype", ...OwnertypeData }, options)
  .then((res) => res.data);
export const updateOwnertype = ({ id, ...updateOwnertype }) => api.put(`Ownertype`, { ...body, "action": "dataUpdate", "menukey": "Ownertype", "OwnerTypeId": id, ...updateOwnertype }, options).then((res) => {
  return res;
})
export const getOwnertypeData = (id) => api.post(`Ownertype`, { ...body, action: "getOwnertypeData", menukey: "Ownertype", OwnerTypeId: id, }, options);
export const deleteOwnertype = (id) => api.post(`Ownertype`, id, options)

//FacilityType
export const getFacilityType = () => api.post("FacilityType", { ...body, action: "getDataList", menukey: "FacilityType" }, options).then((res) => {
  return res.data.datalist;
});
export const saveFacilityType = (FacilityTypeData) => api.post(`FacilityType`, { ...body, action: "dataInsert", menukey: "FacilityType", ...FacilityTypeData }, options)
  .then((res) => res.data);
export const updateFacilityType = ({ id, ...updateFacilityType }) => api.put(`FacilityType`, { ...body, "action": "dataUpdate", "menukey": "FacilityType", "FTypeId": id, ...updateFacilityType }, options).then((res) => {
  return res;
})
export const getFacilityTypeData = (id) => api.post(`FacilityType`, { ...body, action: "getFacilityTypeData", menukey: "FacilityType", FTypeId: id, }, options);
export const deleteFacilityType = (id) => api.post(`FacilityType`, id, options)


// ZS Api

export const getZSs = (params) =>
  api.post("zs", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getZSData = (id) =>
  api.post(
    `zs`,
    {
      ...body,
      action: "getZSData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "zs",
      ZoneId: id,
    },
    options
  );

export const updateZS = ({ id, ...updatedZS }) =>
  api
    .put(
      `zs`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        ZoneId: id,
        ...updatedZS,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveZS = (zsData) =>
  api
    .post(
      `zs`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        ...zsData,
      },
      options
    )
    .then((res) => res.data);

export const deleteZS = (id) =>
  api.post(
    `zs`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "zs",
      ZoneId: id,
    },
    options
  );


// Commune Api

export const getCommunes = (params) =>
  api.post("commune", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getCommuneData = (id) =>
  api.post(
    `commune`,
    {
      ...body,
      action: "getCommuneData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "commune",
      DistrictId: id,
    },
    options
  );

export const updateCommune = ({ id, ...updatedCommune }) =>
  api
    .put(
      `commune`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commune",
        DistrictId: id,
        ...updatedCommune,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveCommune = (communeData) =>
  api
    .post(
      `commune`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commune",
        ...communeData,
      },
      options
    )
    .then((res) => res.data);

export const deleteCommune = (id) =>
  api.post(
    `commune`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "commune",
      DistrictId: id,
    },
    options
  );

//User profile

export const UserProfileupdate = ({ id, ...updatedUser }) =>
  api
    .put(
      `Myprofile`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        ...updatedUser,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUserProfile = (id) =>
  api.post(
    `Myprofile`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

export const getSingleProductListBrandView = (id) =>
  api.post(
    `product_list_brand_view`,
    {
      ...body,
      action: "getProductBrandSingleView",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "brands-list",
      Id: id,
    },
    options
  );


// Audit Log

export const getAuditLogData = (params) =>
  api
    .post(
      "auditlog",
      { params },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getScanLog = (params) =>
  api
    .post(
      "scanlog",
      { params },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });




export const getLogSingleRow = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const gDataBaseInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    //console.log("dddddd",res.data.datalist);
    return res.data.datalist;
  });



export const gTableInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

// Error Log
export const getErrorLogData = (params) =>
  api
    .post(
      "errorlog",
      { params },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

/* product-dashboard*/

export const getNumberOfBrandsGenerics = () => {

  return api.post("ProductDashboard", { ...body, action: "getNumberOfBrandsGenerics", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
};

export const getNumberOfActiveDeactiveProducts = () => {

  return api.post("ProductDashboard", { ...body, action: "getNumberOfActiveDeactiveProducts", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
};

export const getBrandByExpiryDate = () => {

  return api.post("ProductDashboard", { ...body, action: "getBrandByExpiryDate", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getPercentageOfBrandsByCountry = () => {

  return api.post("ProductDashboard", { ...body, action: "getPercentageOfBrandsByCountry", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getTopTenFabriquant = () => {

  return api.post("ProductDashboard", { ...body, action: "getTopTenFabriquant", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getPercentageOfBrandsHaveGTIN = () => {

  return api.post("ProductDashboard", { ...body, action: "getPercentageOfBrandsHaveGTIN", menukey: "product-dashboard", lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

/* Facility Dashboard */

export const getDepartmentWiseFacilityType = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getDepartmentWiseFacilityType", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getNumberOfFacilityTypeTable = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getNumberOfFacilityTypeTable", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getFacilityClassificationTable = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getFacilityClassificationTable", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getFacilityOwnerTypeTable = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getFacilityOwnerTypeTable", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const TotalFacility = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "TotalFacility", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};



export const getNumberOfFacilityTypeChart = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getNumberOfFacilityTypeChart", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};





export const getFacilityClassificationChart = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getFacilityClassificationChart", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};




export const getFacilityOwnerTypeChart = (pRegionId = 0) => {
  return api
    .post(
      "FacilityDashboard",
      { ...body, action: "getFacilityOwnerTypeChart", menukey: "facility-dashboard", RegionId: pRegionId },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

/* End Facility Dashboard */



//single dashboard
export const getBrandByExpiryDateDetails = (toggleButtonValue = "3Month") => {
  return api
    .post(
      "BrandLicenseExpiryDate",
      { ...body, action: "getBrandByExpiryDateList", menukey: "Brand-License-Expiry-Date", toggleButtonValue: toggleButtonValue },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

//FacilityClassification
export const getFacilityclassification = () => api.post("FacilityClassification", { ...body, action: "getDataList", menukey: "facility-classification" }, options).then((res) => {
  return res.data.datalist;
});
export const saveFacilityclassification = (FacilityclassificationData) => api.post(`FacilityClassification`, { ...body, action: "dataInsert", menukey: "facility-classification", ...FacilityclassificationData }, options)
  .then((res) => res.data);
export const updateFacilityclassification = ({ id, ...updateFacilityclassification }) => api.put(`FacilityClassification`, { ...body, "action": "dataUpdate", "menukey": "facility-classification", "FClassificationId": id, ...updateFacilityclassification }, options).then((res) => {
  return res;
})
export const getFacilityclassificationData = (id) => api.post(`FacilityClassification`, { ...body, action: "getFacilityClassificationData", menukey: "facility-classification", FClassificationId: id, }, options);
export const deleteFacilityclassification = (id) => api.post(`FacilityClassification`, id, options)


// Facility Group Map Api

export const getFacilityGroupMap = (params) => {
  const FacilityId = params.queryKey[0].FacilityId;
  return api
    .post("facilityGroupMap", { ...body, FacilityId, action: "getDataList", menukey: "facilityGroupMap" }, options)
    .then((res) => {
      return res.data.datalist;
    });


};

export const saveFacilityGroupMap = (facilityGroupMapData) =>
  api.post(`facilityGroupMap`,
    {
      ...body,
      action: "dataInsert",
      menukey: "facilityGroupMap",
      ...facilityGroupMapData
    }
    , options
  )
    .then((res) => res.data);

export const updateFacilityGroupMap = ({ id, ...updateFacilityGroupMap }) =>
  api.put(
    `facilityGroupMap`,
    {
      ...body,
      "action": "dataUpdate",
      "menukey": "facilityGroupMap",
      "FacilityServiceId": id,
      ...updateFacilityGroupMap
    },
    options).then((res) => { return res; })

export const getFacilityGroupMapById = (id) =>
  api.post(
    `facilityGroupMap`,
    {
      ...body,
      action: "getDataById",
      menukey: "facilityGroupMap",
      FacilityServiceId: id,
    }
    , options
  );

export const deleteFacilityGroupMap = (params) =>
  api.post(
    `facilityGroupMap`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility",
      ...params,
      UserId: UserId,
    },
    options
  );

export const getSupplyFromList = (params) => api.post("facilityGroupMap", { ...body, ...params, action: "getFacilityWarehouseList", menukey: "facilityGr" }, options).then((res) => {

  return res.data.datalist;;
});

// Receive
export const getReceiveInvs = (params) =>
  api
    .post("receiveinvoicelist", { params }, options)
    .then((res) => {
      return res.data.receiveinvdata;
    });
export const saveReceiveInv = (dispenserData) =>
  api.post(`receiveSave`, dispenserData, options).then((res) => res.data);

export const updateReceiveInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `receiveUpdate`,
      { ...body, ...updatedDispenser },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getReceiveInv = (sParams) =>
  api.post(
    `receiveListSingle`,

    { ...body, menukey: "receive", ...sParams, "FacilityId": localStorage.getItem("FacilityId"), "UserId": UserId, StoreId: localStorage.getItem("StoreId") },

    options
  );



export const deleteReceiveInv = (id) =>
  api.post(
    `receiveDelete`,
    { ...body, menukey: "adjustment-invoice", TransactionId: id, "FacilityId": localStorage.getItem("FacilityId"), UserName: UserName, lan: localStorage.getItem("LangCode"), "UserId": UserId },
    options
  ).then((res) => {
    return res.data;
  });


export const deleteDispenseInv = (id) =>
  api.post(
    `dispenseDelete`,
    { ...body, menukey: "adjustment-invoice", TransactionId: id, "FacilityId": localStorage.getItem("FacilityId"), UserName: UserName, lan: localStorage.getItem("LangCode"), "UserId": UserId },
    options
  ).then((res) => {
    return res.data;
  });


export const getNonReceivedOrderList = () =>
  api
    .post(
      "receivedNonOrderList",
      { ...body, menukey: "receive", "FacilityId": localStorage.getItem("FacilityId"), "UserId": UserId },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });
export const saveNonReceivedOrder = (id) =>
  api
    .post(
      `selectFromOrder`,
      { ...body, menukey: "receipts", "FacilityId": localStorage.getItem("FacilityId"), ...id, "UserId": UserId },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });
export const getInvoiceNo = (params) =>
  api.post("InvoiceNo", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const getOrdInvoiceNo = (params) => api.post('OrdInvoiceNo', { ...body, params }, options).then((res) => {
  return res.data;
})

export const updateStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "receipts",
        "FacilityId": localStorage.getItem("FacilityId"),
        TransactionId: id,
        TransactionTypeId: 1,
        "UserId": UserId,
        lan: localStorage.getItem("LangCode"),
        "UserName": UserName
      },
      options
    )
    .then((res) => {
      return res;
    });

export const postDispenseStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "dispense",
        "FacilityId": localStorage.getItem("FacilityId"),
        TransactionId: id,
        TransactionTypeId: 4,
        "UserId": UserId,
        "UserName": UserName
      },
      options
    )
    .then((res) => {
      return res;
    });


export const updateAdjStock = (params) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        "FacilityId": localStorage.getItem("FacilityId"),
        ...params,
        "UserId": UserId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res.data;
    });


export const getProductsList = (params) =>
  api
    .post("products", { params }, options)
    .then((res) => {
      return res.data.productlist;
    });
export const getProductsForInitial = (params) =>
  api
    .post("products", { params }, options)
    .then((res) => {
      return res.data.productlist;
    });

export const getSourceList = () =>
  api.post("sourceoffund", { ...body, action: "getSources", menukey: "receive" }, options).then((res) => {
    return res.data.sourcelist;
  });

export const gReceiveFromFacility = (gRFromData) => {
  return api
    .post(
      "comboscript",
      { ...gRFromData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Download Issue Invoice
export const updateDownloadIssueInvoice = ({ id, ...issueInvDownload }) =>
  api
    .post(
      `downloadIssueInvoice`,
      { ...body, menukey: "receipts", ...issueInvDownload, TransactionTypeId: 1, "UserId": UserId, "webcall": 1, lan: localStorage.getItem("LangCode"), "UserName": UserName },
      options
    )
    .then((res) => {
      return res;
    });



export const saveSelectedIssueInvoice = (issueInvDownload) =>
  api
    .post(
      `SelectedIssueInvoice_oneMany`,
      { ...body, menukey: "receipts", ...issueInvDownload, FacilityId: localStorage.getItem("FacilityId"), TransactionTypeId: 1, "UserId": UserId, lan: localStorage.getItem("LangCode"), "UserName": UserName },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

// End Receive


// Adjustment

export const getAdjustmentInvs = (params) =>
  api
    .post("adjustmentinvoicelist", { params }, options)
    .then((res) => {
      return res.data.adjustmentinvdata;
    });

/*
export const getProductsLot = (id) =>
api.post("products", { ...body, action: "getProductLot", menukey: "adjustment-invoice",FacilityId:id,"UserId": UserId }, options).then((res) => {
 return res.data.productlist;
});

*/


export const getProductsLot = (params) =>
  api
    .post("products", { params }, options)
    .then((res) => {
      return res.data.productlist;
    });

export const getGeneric = (params) =>
api
  .post("products", { params }, options)
  .then((res) => {
    return res.data.productlist;
  });    

export const getWarehouseList = (amcData) => {
  return api
    .post(
      "comboscript",
      { ...amcData },
      options
    )
    .then((res) => {

      return res;
    });
};


export const gStoreListByFacility = (storeData) => {
  return api
    .post(
      "comboscript",
      { ...storeData },
      options
    )
    .then((res) => {

      return res;
    });
};

export const gUserListByFacility = (gUserData) => {
  return api
    .post(
      "comboscript",
      { ...gUserData },
      options
    )
    .then((res) => {

      return res;
    });
};

export const saveAdjustment = (adjustmentData) =>
  api.post(`receiveSave`, { ...body, ...adjustmentData, menukey: "adjustment-invoice" }, options).then((res) => res.data);
export const getLoginDependencyCombo = async (options, task) => api.post(`comboscript`, { "action": "getDependencyCombo", "task": task, menukey: "", FacilityId: localStorage.getItem("FacilityId"), UserId: localStorage.getItem("UserId"), StoreId: localStorage.getItem("StoreId"), lan: localStorage.getItem("LangCode") }, options).then((res) => { return res.data; });
export const getLoginDependencyCombo1 = async (options, task, FacilityId, StoreId) => api.post(`comboscript`, { "action": "getDependencyCombo", "task": task, menukey: "", FacilityId: FacilityId, UserId: localStorage.getItem("UserId"), StoreId: StoreId, lan: localStorage.getItem("LangCode") }, options).then((res) => { return res.data; });

export const getFacilityListNotEqual99ANDSuppliers = (task, FacilityId) => api.post(`comboscript`, { "action": "getFacilityListNotEqual99ANDSupplier", "task": task, menukey: "", FacilityId: localStorage.getItem("FacilityId"), UserId: UserId }, options).then((res) => { return res.data; });

// End Adjustment

// Issue Invoice

export const getIssueInvs = (params) =>
  api
    .post("issueinvoicelist", { params }, options)
    .then((res) => {
      return res.data.issueinvdata;
    });


export const gRecipientGroup = (gRecipientGroupData) => {
  return api
    .post(
      "comboscript",
      { ...gRecipientGroupData },
      options
    )
    .then((res) => {

      return res;
    });
};


export const gIssuedToList = (gIssuedToListData) => {
  return api
    .post(
      "comboscript",
      { ...gIssuedToListData },
      options
    )
    .then((res) => {

      return res;
    });
};

//downloadOrderInvoice
export const updateDownloadOrderInvoice = ({ id, ...orderInvDownload }) =>
  api
    .post(
      `downloadOrderInvoice_oneMany`,
      { ...body, menukey: "issue-invoice", ...orderInvDownload, TransactionTypeId: 2, "UserId": UserId, lan: localStorage.getItem("LangCode"), UserName: UserName },
      options
    )
    .then((res) => {
      return res;
    });


export const saveSelectedOrderInvoice = (orderInvoiceDownload) =>
  api
    .post(
      `downloadOrderInvoice_oneMany`,
      { ...body, menukey: "issue-invoice", ...orderInvoiceDownload, FacilityId: localStorage.getItem("FacilityId"), TransactionTypeId: 2, UserName: UserName },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

export const UpdatePaymentDateIssue = (...paymentdatedata) =>
  api.post(`UpdatePaymentStatusDate`, ...paymentdatedata, options).then((res) => res.data);

// End Issue Invoice


// Dispense
export const getDispenseInvs = (params) =>
  api
    .post("dispenseinvoicelist", { params }, options)
    .then((res) => {
      return res.data.dispenseinvdata;
    });


export const getDispenseLots = (params) =>
  api
    .post("products", { params }, options)
    .then((res) => {
      return res.data.productlist;
    });

  export const getIssueLots = (params) =>
    api
      .post("products", { params }, options)
      .then((res) => {
        return res.data.productlist;
      });  

// End Dispense


// Orders ///////



export const getOrderMasterdata = (params) =>
  api
    .post("ESIGLOrderStockEntryForm", { params }, options)
    .then((res) => {
      console.log("order data", res.data);
      return res.data.orderItemdata;
    });

export const saveOrderInv = (dispenserData) =>
  api
    .post(`orderSave`, { ...body, menukey: "orders", ...dispenserData }, options)
    .then((res) =>

      res.data
    );

export const saveOrderModalData = (dispenserData) =>
api
  .post(`orderModalSave`, { ...body, menukey: "orders", ...dispenserData }, options)
  .then((res) =>

    res.data
  );    

export const getOrderInv = (sParams) =>
  api.post(
    `orderListSingle`,
    { ...body, menukey: "orders", ...sParams, "FacilityId": localStorage.getItem("FacilityId") },
    options
  );

export const updateOrderInv = ({ id, ...updatedOrder }) =>
  api
    .put(`orderUpdate`, { ...body, menukey: "orders", ...updatedOrder }, options)
    .then((res) => {
      return res.data;
    });
export const deleteOrderInv = (id) =>
  api.post(
    `orderDelete`,
    { ...body, menukey: "orders", OrderId: id, "FacilityId": localStorage.getItem("FacilityId"), lan: localStorage.getItem("LangCode") },
    options
  );

export const deleteAllRecreateOrderInv = ({ id, ...updatedOrder }) =>
  api
    .post(`DeleteAllRecreateOrder`, { ...body, menukey: "orders", ...updatedOrder }, options)
    .then((res) => {
      return res.data;
    });
export const getOrderItemListdata = (params) =>
  api
    .post("getItemListData", { params }, options)
    .then((res) => {
      return res.data.orderItemdata;
    });

export const postOrderInv = (updatedOrder) =>
  api
    .post(
      "CompleteOrderStock",
      {
        ...body,
        menukey: "orders",
        ...updatedOrder
      },
      options
    )
    .then((res) => {
      return res;
    });

//End Orders ///////


// Stock Status


export const gStoreListForStockTake = (storeData) => {
  return api
    .post(
      "comboscript",
      { ...storeData },
      options
    )
    .then((res) => {

      return res;
    });
};

export const UpdateAMC = (amcData) => {
  return api
    .post(
      "StockStatus",
      { ...amcData },
      options
    )
    .then((res) => {

      return res;
    });
};


export const getstockstatus = (params) => api.post('StockStatus', { params }, options).then((res) => {
  return res.data.datalist;
})

export const getLotDataView = (id) => api.post('StockStatus', { ...body, "action": "getLotDataView", "menukey": "stock-status", "ItemNo": id, "FacilityId": localStorage.getItem("FacilityId"), "StoreId": localStorage.getItem("StoreId"), "lan": localStorage.getItem("LangCode") }, options).then((res) => {
  return res.data.datalist;
})

export const getStockCardDataView = (params) => api.post('StockStatus', { params }, options).then((res) => {
  return res.data.datalist;
})

export const getChartDataView = (params) => api.post('StockStatus', { params }, options).then((res) => {
  return res.data.datalist;
})


// End Stock Status

// Order approval
export const getOrderInvApproval = (params) =>
  api
    .post("orderInvoiceListApproval", { params }, options)
    .then((res) => {
      return res.data.orderinvdata;
    });

export const postOrderApproval = (id) =>
  api
    .post(
      `orderPostApproval`,
      {
        ...body,
        menukey: "order-approval",
        "FacilityId": localStorage.getItem("FacilityId"),
        OrderId: id,
        lan: localStorage.getItem("LangCode"),
        UserName: UserName
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const updateOrderApproval = ({ id, ...updatedOrder }) =>
  api
    .put(`orderUpdateApproval`, { ...body, menukey: "order-approval", ...updatedOrder }, options)
    .then((res) => {
      return res.data;
    });

// Order approval

//Stock Take
export const getStockTakeInvs = (params) =>
  api
    .post("stocktake", { params }, options)
    .then((res) => {
      return res.data.gstocktakelist;
    });

export const saveStockTakeInv = (stockTakeData) =>
  api
    .post(`stocktake`, { ...body, menukey: "stock-take", "action": "saveStockTake", ...stockTakeData, UserName: UserName, lan: localStorage.getItem("LangCode") }, options)
    .then((res) => res.data);

export const updateStockTakeInv = ({ id, ...updatedStockTake }) =>
  api
    .put(`stocktake`, { ...body, menukey: "stock-take", "action": "updateStockTake", ...updatedStockTake, UserName: UserName, lan: localStorage.getItem("LangCode") }, options)
    .then((res) => {
      return res.data;
    });
/*
 export const getStockTakeInv = (id) =>
   api.post(
     `stocktake`,
     { ...body, menukey: "stock-take", StockTakeId: id, "FacilityId": localStorage.getItem("FacilityId"),"StoreId": localStorage.getItem("StoreId"),"action":"stockTakeListSingle" },
     options
   )
   */

export const getStockTakeInv = (sParams) =>
  api.post(
    `stocktake`,
    { ...body, menukey: "stock-take", ...sParams, "FacilityId": localStorage.getItem("FacilityId"), "UserId": UserId, "UserName": UserName, StoreId: localStorage.getItem("StoreId"), "action": "stockTakeListSingle" },
    options
  );



export const deleteStockTakeInv = (id) =>
  api.post(
    `stocktake`,
    { ...body, menukey: "stock-take", StockTakeId: id, "FacilityId": localStorage.getItem("FacilityId"), "action": "stockTakeDelete", UserName: UserName, lan: localStorage.getItem("LangCode") },
    options
  ).then((res) => {
    return res.data;
  });

export const postStockTakeInv = (id) =>
  api
    .post(
      `StockTakePosted`,
      {
        ...body,
        menukey: "stock-take",
        "FacilityId": localStorage.getItem("FacilityId"),
        StockTakeId: id,
        UserName: UserName,
        UserId: localStorage.getItem("UserId"),
        lan: localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res.data;
    });


export const getAdjType = (amcData) => {
  return api
    .post(
      "comboscript",
      { ...amcData, "action": "getDependencyCombo", menukey: "", FacilityId: localStorage.getItem("FacilityId"), UserId: localStorage.getItem("UserId"), lan: localStorage.getItem("LangCode") },
      options
    )
    .then((res) => {

      return res;
    });
};
export const getAdjTypeList = (amcData) => {
  let amcData1 = amcData.queryKey[0];
  return api
    .post(
      "comboscript",
      { ...amcData1, "action": "getDependencyCombo", menukey: "", FacilityId: localStorage.getItem("FacilityId"), UserId: localStorage.getItem("UserId"), lan: localStorage.getItem("LangCode") },
      options
    )
    .then((res) => {

      return res;
    });
};

export const getDependencyCombo = async (amcData) => api.post(`comboscript`, {
  ...amcData, "action": "getDependencyCombo", menukey: "",
  UserId: localStorage.getItem("UserId"),
  lan: localStorage.getItem("LangCode")
}, options).then((res) => { return res.data; });



////////////////Initial Stock Configuration///////////////////////////////
// export const getInitialStockTakeInv = (fData) =>
//  api.post(
//    `InitialStockConfiguration`,
//    { ...body, menukey: "initial-stock-configuration",...fData,"action":"stockTakeListSingle", 
//    UserId: localStorage.getItem("UserId"),
//    language:lan },
//    options
//  ) , StoreId: localStorage.getItem("StoreId")

export const getInitialStockTakeInv = (sParams) =>
  api.post(
    `InitialStockConfiguration`,
    { ...body, menukey: "initial-stock-configuration", ...sParams, "action": "stockTakeListSingle", "UserId": UserId,"UserName": UserName },
    options
  );


export const updateInitialStockTakeInv = (fData) =>
  api.post(
    `InitialStockConfiguration`,
    {
      ...body, menukey: "initial-stock-configuration", ...fData, "action": "updateStockTake",
      UserId: localStorage.getItem("UserId"),
      lan: localStorage.getItem("LangCode")
    },
    options
  )


export const postInitialStockTake = (fData) =>
  api
    .post(
      `InitialStockConfiguration`,
      {
        ...body,
        menukey: "initial-stock-configuration",
        "FacilityId": localStorage.getItem("FacilityId"),
        ...fData,
        "action": "PostStockTake",
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res.data;
    });



//End Stock Take


// Start Field Dispense

// Get Field Dispense
export const getFieldDispese = (params) => api.post('ESIGLFieldDisp', { params }, options).then((res) => {
  //console.log("kkkkkkkkkk",res.data.bStockUpdated);
  return res;
  //return res.data.datalist;
})

// generate
export const GenarateFieldDispenseData = (FDispenseData) => {
  return api
    .post(
      "ESIGLFieldDisp",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Update
export const UpdateFieldDispenseData = (FieldDData) => {
  return api
    .post(
      "ESIGLFieldDisp",
      { ...FieldDData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Post
export const postFieldDispense = (FDispenseData) => {
  return api
    .post(
      "ESIGLFieldDisp",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Unpost
export const UnPostFieldDispense = (FDispenseData) => {
  return api
    .post(
      "ESIGLFieldDisp",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// End Field Dispense


// Start A7 Report

// Get A7 Report
export const getESIGLA7ReportData = (params) => api.post('ESIGLA7Report', { params }, options).then((res) => {
  //console.log("kkkkkkkkkk",res.data.bStockUpdated);
  return res;
  //return res.data.datalist;
})

// generate
export const GenarateESIGLA7ReportData = (FDispenseData) => {
  return api
    .post(
      "ESIGLA7Report",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Update
export const UpdateESIGLA7ReportData = (FieldDData) => {
  return api
    .post(
      "ESIGLA7Report",
      { ...FieldDData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Post
export const postESIGLA7Report = (FDispenseData) => {
  return api
    .post(
      "ESIGLA7Report",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// Unpost
export const UnPostESIGLA7Report = (FDispenseData) => {
  return api
    .post(
      "ESIGLA7Report",
      { ...FDispenseData },
      options
    )
    .then((res) => {

      return res;
    });
};

// End A7 Report


// Start Supplier

export const getSupplier = (params) =>
  api
    .post("supplier", { params }, options)
    .then((res) => {
      console.log("supplierdata", res.data);
      return res.data.datalist;
    });


export const getSupplierData = (id) =>
  api.post(
    `supplier`,
    {
      ...body,
      action: "getSupplierData",
      menukey: "supplier",
      SupplierId: id,
    },
    options
  );

export const updateSupplier = ({ id, ...updatedSupplier }) =>
  api
    .put(
      `supplier`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "supplier",
        SupplierId: id,
        ...updatedSupplier,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveSupplier = (SupplierData) =>
  api
    .post(
      `supplier`,
      { ...body, action: "dataInsert", menukey: "supplier", ...SupplierData },
      options
    )
    .then((res) => res.data);

export const deleteSupplier = (id) => api.post(`supplier`, id, options);

// End Supplier



// Start Recipients

export const getRecipients = (params) =>
  api
    .post("recipients", { params }, options)
    .then((res) => {
      console.log("supplierdata", res.data);
      return res.data.datalist;
    });


export const getRecipientsData = (id) =>
  api.post(
    `recipients`,
    {
      ...body,
      action: "getSupplierData",
      menukey: "recipients",
      IssuedToId: id,
    },
    options
  );

export const updateRecipients = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `recipients`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "recipients",
        IssuedToId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveRecipients = (RecipientsFormData) =>
  api
    .post(
      `recipients`,
      { ...body, action: "dataInsert", menukey: "recipients", ...RecipientsFormData },
      options
    )
    .then((res) => res.data);

export const deleteRecipients = (id) => api.post(`recipients`, id, options);

// End Recipients

// Facility Level Api
export const getFacilityLevel = () =>
  api
    .post(
      "facilityLevel",
      { ...body, action: "getDataList", menukey: "facility-label" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getFacilityLevelData = (id) =>
  api.post(
    `facilityLevel`,
    {
      ...body,
      action: "getSingleFacilityLevelData",
      menukey: "facility-label",
      FLevelId: id,
    },
    options
  );

export const updateFacilityLevel = ({ id, ...updatedFacilityLevel }) =>
  api
    .put(
      `facilityLevel`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility-label",
        FLevelId: id,
        ...updatedFacilityLevel,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveFacilityLevel = (FacilityLevelData) =>
  api
    .post(
      `facilityLevel`,
      { ...body, action: "dataInsert", menukey: "facility-label", ...FacilityLevelData },
      options
    )
    .then((res) => res.data);

export const deleteFacilityLevel = (id) => api.post(`facilityLevel`, id, options);

// End Facility Level Api

//Import ASN/DESADV
export const saveASNDESADVImport = (fromData) =>
  api.post(`asn_desadv_import`, {
    action: "ASNDESADVImportDataInsert",
    menukey: "import-asn-desadv",
    ...fromData
  }, options).then((res) => res.data);


/////User Permission
export const getProvinceData = (params) => api.post('userlist', { params }, options).then((res) => {
  return res.data.datalist;
})
export const getZoneData = (params) => api.post('userlist', { params }, options).then((res) => {
  return res.data.datalist;
})
export const getBCZData = (params) => api.post('userlist', { params }, options).then((res) => {
  return res.data.datalist;
})

export const InsertMappingProvince = (params) =>
  api
    .post(
      `userlist`,
      {
        ...body,
        action: "insertAllorOneMappingProvince",
        UserId: params.id,
        lan: localStorage.getItem("LangCode"),
        menukey: "user-entry",
        RegionMapId: params.RegionMapId,
        RegionId: params.RegionId,
        chkValue: params.chkValue,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const InsertMappingZone = (params) =>
  api
    .post(
      `userlist`,
      {
        ...body,
        action: "insertAllorOneMappingZone",
        UserId: params.id,
        lan: localStorage.getItem("LangCode"),
        menukey: "user-entry",
        ZoneMapId: params.ZoneMapId,
        ZoneId: params.ZoneId,
        checkVal: params.chkValue,
      },
      options
    )
    .then((res) => {
      return res;
    });


export const InsertMappingBCZ = (params) =>
  api
    .post(
      `userlist`,
      {
        ...body,
        action: "insertAllorOneMappingBCZ",
        UserId: params.id,
        lan: localStorage.getItem("LangCode"),
        menukey: "user-entry",
        DistrictMapId: params.DistrictMapId,
        DistrictId: params.DistrictId,
        checkVal: params.chkValue,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const InsertDeleteSelectDeselect = (params) =>
  api
    .post(
      `userlist`,
      {
        ...body,
        action: "InsertDeletePRSelectDeselect",
        UserId: params.id,
        lan: localStorage.getItem("LangCode"),
        menukey: "user-entry",
        ProductGroupRegion: params.ProductGroupRegion,
        PRSelectDeselect: params.PRSelectDeselect,

      },
      options
    )
    .then((res) => {
      return res;
    });

/////User Permission


/////product group admin form

export const getTotalProductgroup = (params) =>
  api.post("productgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductGroupList = (params) =>
  api.post("productgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveProductGroup = (getunitofmeasure1) =>
  api
    .post(
      `productgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-group-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteProductGroup = (id) =>
  api.post(`productgroup`, id, options);
export const getProductGroup = (id) =>
  api.post(
    `productgroup`,
    {
      ...body,
      action: "getProductGroup",
      menukey: "product-group-entry",
      ItemGroupId: id,
    },
    options
  );
export const upProductGroup = ({ id, ...updatedunitof }) =>
  api
    .put(
      `productgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-group-entry",
        ItemGroupId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End product group admin form



///////Product SubGroup admin form

export const getProductSubGroupList = (params) =>
  api
    .post("productsubgroup", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });


export const getProductSubGroupData = (id) =>
  api.post(
    `productsubgroup`,
    {
      ...body,
      action: "getData",
      menukey: "product-subgroup-entry",
      ProductSubGroupId: id,
    },
    options
  );

export const updateProductSubGroup = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `productsubgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-subgroup-entry",
        ProductSubGroupId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveProductSubGroup = (RecipientsFormData) =>
  api
    .post(
      `productsubgroup`,
      { ...body, action: "dataInsert", menukey: "recipients", ...RecipientsFormData },
      options
    )
    .then((res) => res.data);

export const deleteProductSubGroup = (id) => api.post(`productsubgroup`, id, options);


////// End Product SubGroup admin form


/////product Shipment Status admin form

export const getTotalShipmentStatus = (params) =>
  api.post("shipmentstatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getShipmentStatusList = (params) =>
  api.post("shipmentstatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveShipmentStatus = (getunitofmeasure1) =>
  api
    .post(
      `shipmentstatus`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-group-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteShipmentStatus = (id) =>
  api.post(`shipmentstatus`, id, options);
export const getShipmentStatus = (id) =>
  api.post(
    `shipmentstatus`,
    {
      ...body,
      action: "getData",
      menukey: "product-group-entry",
      ShipmentStatusId: id,
    },
    options
  );
export const updateShipmentStatus = ({ id, ...updatedunitof }) =>
  api
    .put(
      `shipmentstatus`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-group-entry",
        ShipmentStatusId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End product Shipment Status admin form


/////Product Classification admin form

export const getTotalproductclassification = (params) =>
  api.post("productclassification", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getproductclassificationList = (params) =>
  api.post("productclassification", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveproductclassification = (getunitofmeasure1) =>
  api
    .post(
      `productclassification`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-classification",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);

export const deleteproductclassification = (id) =>
  api.post(`productclassification`, id, options);

export const getproductclassification = (id) =>
  api.post(
    `productclassification`,
    {
      ...body,
      action: "getData",
      menukey: "product-classification",
      ClassificationId: id,
    },
    options
  );
export const updateproductclassification = ({ id, ...updatedunitof }) =>
  api
    .put(
      `productclassification`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-classification",
        ClassificationId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End Product Classification admin form




//SOBAPS Receipts

export const getASNInvoice = (params) =>
  api.post("SobapsReceiveFromAsnEntryForm", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const getESIGLReceivefromWarehouseEntryData = (params) =>
  api.post("SobapsReceiveFromAsnEntryForm", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const insertUpdateOrderStockItemsASN = (formData) =>
  api
    .post(
      `SobapsReceiveFromAsnEntryForm`,
      {
        ...body,
        action: "insertUpdateOrderStockItemsASN",
        menukey: "sobaps-receipts",
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const getSobapsReceiveFromAsnListSingle = (sParams) =>
  api.post(
    `SobapsReceiveFromAsnEntryForm`,
    {
      ...body,
      action: "getSobapsReceiveFromAsnListSingle",
      menukey: "sobaps-receipts",
      ...sParams,
      UserId: UserId,
      StoreId: localStorage.getItem("StoreId")
    },
    options
  );

export const insertReceivefromSupplierEntryData = (formData) =>
  api
    .post(
      `SobapsReceiveFromAsnEntryForm`,
      {
        ...body,
        action: "insertReceivefromSupplierEntryData",
        menukey: "sobaps-receipts",
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const updateReceivefromSupplierEntryData = ({ id, ...formData }) =>
  api
    .put(
      `SobapsReceiveFromAsnEntryForm`,
      {
        ...body,
        action: "updateReceivefromSupplierEntryData",
        menukey: "sobaps-receipts",
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });


export const SobapsReceiveDataDalete = (id) =>
  api
    .post(
      `SobapsReceiveFromAsnEntryForm`,
      {
        ...body,
        action: "SobapsReceiveDataDalete",
        menukey: "sobaps-receipts",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });


    export const JsonDataImportFromSobaps = (productData) =>
    api
      .post(
        `import_form_sobaps`,
        { ...body, action: "saveImportFromSoBAPSData", ...productData },
        options
      )
      .then((res) => res.data);   

/*
export const updateAdjustmentInvoiceStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "sobaps-receipts",
        FacilityId: FacilityId,
        TableName: "adjinv",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    }); */
//End of SOBAPS Receipts	



/////Funding Source admin form

export const getTotalFundingSource = (params) =>
  api.post("fundingsource", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getFundingSourceList = (params) =>
  api.post("fundingsource", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveFundingSource = (getunitofmeasure1) =>
  api
    .post(
      `fundingsource`,
      {
        ...body,
        action: "dataInsert",
        menukey: "funding-source-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteFundingSource = (id) =>
  api.post(`fundingsource`, id, options);
export const getFundingSource = (id) =>
  api.post(
    `fundingsource`,
    {
      ...body,
      action: "getData",
      menukey: "funding-source-entry",
      FundingSourceId: id,
    },
    options
  );
export const updateFundingSource = ({ id, ...updatedunitof }) =>
  api
    .put(
      `fundingsource`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "funding-source-entry",
        FundingSourceId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End Funding Source admin form


/////Recipient Group admin form

export const getTotalRecipientGroup = (params) =>
  api.post("recipientgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getRecipientGroupList = (params) =>
  api.post("recipientgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveRecipientGroup = (getunitofmeasure1) =>
  api
    .post(
      `recipientgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "funding-source-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteRecipientGroup = (id) =>
  api.post(`recipientgroup`, id, options);
export const getRecipientGroup = (id) =>
  api.post(
    `recipientgroup`,
    {
      ...body,
      action: "getData",
      menukey: "funding-source-entry",
      TranslationId: id,
    },
    options
  );
export const updateRecipientGroup = ({ id, ...updatedunitof }) =>
  api
    .put(
      `recipientgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "funding-source-entry",
        TranslationId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End Recipient Group admin form

//SVDL Report list Api
export const getSVDLDashboardList = (params) =>
  api.post("dashboard_list_server", { params, UserId: UserId,UserName: UserName }, options).then((res) => {
    return res.data.datalist;
  });

//eSIGL Report list Api
export const getESIGLDashboardList = (params) =>
  api.post("dashboard_list_server", { params, UserId: UserId,UserName: UserName }, options).then((res) => {
    return res.data.datalist;
  });

//Get Report Title By Id Api
export const getDashboardNameById = (params) =>
  api.post("dashboard_list_server", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Import From SoBAPS
export const saveImportFromSoBAPSData = (fromData) =>
  api.post(`receive_from_warehouse_data_import_server`, {
    action: "saveImportFromSoBAPSData",
    menukey: "receipts",
    ...fromData
  }, options).then((res) => res.data);


// Start Adjustment Type

export const getAdjustmentType = (params) =>
  api
    .post("adjustmenttype", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });


export const getAdjustmentTypeData = (id,LangCode) =>
  api.post(
    `adjustmenttype`,
    {
      ...body,
      action: "getData",
      menukey: "adjustment-type-entry",
      AdjTypeId: id,
      StoreId: localStorage.getItem("StoreId"),
      LangCode:LangCode,
      lan: localStorage.getItem("LangCode"),
    },
    options
  );

export const updateAdjustmentType = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `adjustmenttype`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "adjustment-type-entry",
        AdjTypeId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveAdjustmentType = (RecipientsFormData) =>
  api
    .post(
      `adjustmenttype`,
      { ...body, action: "dataInsert", menukey: "adjustment-type-entry", ...RecipientsFormData },
      options
    )
    .then((res) => res.data);

export const deleteAdjustmentType = (id) => api.post(`adjustmenttype`, id, options);


export const gLinkAdjTypeList = (data) => {
  return api
    .post(
      "comboscript",
      { ...data },
      options
    )
    .then((res) => {

      return res;
    });
};

// End Adjustment Type



// Start store Type

export const getStoreEntry = (params) =>
  api
    .post("storeentry", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });


export const getStoreEntryData = (id) =>
  api.post(
    `storeentry`,
    {
      ...body,
      action: "getData",
      menukey: "store-entry",
      StoreId: id,
      lan: localStorage.getItem("LangCode"),
      FacilityLevel: localStorage.getItem("FLevelId"),
    },
    options
  );

export const updateStoreEntry = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `storeentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "store-entry",
        StoreId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveStoreEntry = (RecipientsFormData) =>
  api
    .post(
      `storeentry`,
      { ...body, action: "dataInsert", menukey: "store-entry", ...RecipientsFormData },
      options
    )
    .then((res) => res.data);

export const deleteStoreEntry = (id) => api.post(`storeentry`, id, options);

// End store Type
//Products Api
export const getProductsListBrand = (params) => {
  console.log(params.queryKey[0]);
  let param = params.queryKey[0];
  return api
    .post("productListbrand", param, options)

    .then((res) => {
      return res.data;
    });
}
export const getProductsListBrandEntry = (params) => {
  let param = params.queryKey[0];
  return api
    .post("productListbrand", param, options)

    .then((res) => {
      return res.data;
    });
}

export const updateProductBrand = ({ id, ...updatedProduct }) =>
  api
    .put(
      `productListbrand`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "brands-list",
        BrandNo: id,
        ...updatedProduct,
      },
      options
    )
    .then((res) => {
      return res;
    });

    
export const saveProductBrand = (ProductBrand) =>
api
  .post(
    `productListbrand`,
    {
      ...body,
      action: "dataInsert",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "brands-list",
      ...ProductBrand,
    },
    options
  )
  .then((res) => res.data);

//Products Api
export const getGenericlist = (params) => {

  let param = params.queryKey[0];
  return api
    .post("productList", param, options)

    .then((res) => {
      return res.data;
    });
}



export const getGenericlistDetail = (params) => {
  let param = params.queryKey[0];
  return api
    .post("productList", param, options)

    .then((res) => {
      return res.data;
    });
}




/////Order Type admin form

export const getTotalOrderType = (params) =>
  api.post("ordertype", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getOrderTypeList = (params) =>
  api.post("ordertype", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveOrderType = (getunitofmeasure1) =>
  api
    .post(
      `ordertype`,
      {
        ...body,
        action: "dataInsert",
        menukey: "order-type-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteOrderType = (id) =>
  api.post(`ordertype`, id, options);
export const getOrderType = (id) =>
  api.post(
    `ordertype`,
    {
      ...body,
      action: "getData",
      menukey: "order-type-entry",
      TranslationId: id,
    },
    options
  );
export const updateOrderType = ({ id, ...updatedunitof }) =>
  api
    .put(
      `ordertype`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "order-type-entry",
        TranslationId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End Order Type admin form



// Year entry
export const getYearEntry = () => api.post("yearentry", { ...body, action: "getDataList", menukey: "year-entry" }, options).then((res) => {
  return res.data.datalist;
});
export const saveYearEntry = (ServiceData) => api.post(`yearentry`, { ...body, action: "dataInsert", menukey: "year-entry", ...ServiceData }, options)
  .then((res) => res.data);
export const updateYearEntry = ({ id, ...updategetService }) => api.put(`yearentry`, { ...body, "action": "dataUpdate", "menukey": "year-entry", "YearID": id, ...updategetService }, options).then((res) => {
  return res;
})
export const getYearEntryData = (id) => api.post(`yearentry`, { ...body, action: "getData", menukey: "year-entry", YearID: id, }, options);
export const deleteYearEntry = (id) => api.post(`yearentry`, id, options)

// end Year entry



//MOS Type For Facilty Entry Api

export const getMosTypeforFacility = (params) =>
  api
    .post("mostypeforfacility", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const getMosTypeforFacilityData = (id) =>
  api.post(
    `mostypeforfacility`,
    { ...body, action: "getData", menukey: "mos-type-for-facility-entry", MosTypeId: id },
    options
  );

export const updateMosTypeforFacility = ({ id, ...updatedMOSRange }) =>
  api
    .put(
      `mostypeforfacility`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "mos-type-for-facility-entry",
        MosTypeId: id,
        ...updatedMOSRange,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveMosTypeforFacility = (mosrangeData) =>
  api
    .post(
      `mostypeforfacility`,
      { ...body, action: "dataInsert", menukey: "mos-type-for-facility-entry", ...mosrangeData },
      options
    )
    .then((res) => res.data);

export const deleteMosTypeforFacility = (id) => api.post(`mostypeforfacility`, id, options);
//END MOS Type For Facilty Entry Api



/////Rc product admin form

export const getRCProduct = (params) =>
  api.post("rcproductentry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getRCProductList = (params) =>
  api.post("rcproductentry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getAllProductItemsmodal = (params) =>
  api
    .post("rcproductentry", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });




export const saveRCProduct = (getunitofmeasure1) =>
  api
    .post(
      `rcproductentry`,
      {

        action: "dataInsert",
        menukey: "rc-product-entry-form",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);


export const deleteRCProduct = (id) =>
  api.post(`rcproductentry`, id, options);
export const getRCProductData = (id) =>
  api.post(
    `rcproductentry`,
    {
      ...body,
      action: "getData",
      menukey: "product-group-entry",
      ShipmentStatusId: id,
    },
    options
  );



export const updateRCProduct = (params) =>
  //console.log(params.id);
  api
    .post(
      `rcproductentry`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "rc-product-entry-form",
        ItemNo: params.id,
        checkstatus: params.bCheckedVal,
      },
      options
    )
    .then((res) => {
      return res;
    });



/////End Rc product admin form


//Start Upload Log Api
export const getMasterTablelist = (params) => {

  let param = params.queryKey[0];
  return api
    .post("UploadLog", param, options)

    .then((res) => {
      return res.data;
    });
}


export const getBlockDataList = (params) =>
  api.post("UploadLog", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const getUploadLogDataForTableList = (params) =>
  api
    .post(
      "UploadLog",
      { params },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUploadLogListForTableList = (params) =>
  api.post("UploadLog", { params }, options).then((res) => {
    return res.data.datalist;
  });
//End Upload Log Api


//SVDL Product Entry


export const getSVDLProduct = (params) =>
  api.post("svdlProductEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const getSVDLProductItemsmodal = (params) =>
  api
    .post("svdlProductEntry", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });


export const saveSVDLProduct = (getunitofmeasure1) =>
  api
    .post(
      `svdlProductEntry`,
      {

        action: "dataInsert",
        menukey: "product-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);


export const deleteSVDLProduct = (id) =>
  api.post(`svdlProductEntry`, id, options);

export const getSingleSVDLProduct = (id) =>
  api.post(
    `svdlProductEntry`,
    {
      ...body,
      action: "getSingleSVDLProduct",
      menukey: "product-entry",
      ItemNo: id,
    },
    options
  );

export const upSVDLProduct = ({ id, ...updatesvdlpro }) =>
  api
    .put(
      `svdlProductEntry`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "product-entry",
        ItemNo: id,
        ...updatesvdlpro,
      },
      options
    )
    .then((res) => {
      return res;
    });

///////start Product Serial Tracking Report
export const getProductSerialTrackingReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });



export const getNumberOfDispense = (params) =>
  api.post("ProductSerialTrackingReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getNumberOfNotDispense = (params) =>
  api.post("ProductSerialTrackingReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getTotalNumberOfPSerial = (params) =>
  api.post("ProductSerialTrackingReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

///////end Product Serial Tracking Report

//SNIGS Logistics Report A7
// export const getFacilityListWithMonthStatus = (params) =>  {
//   return api.post('SNIGSLogisticsReportA7', {params}, options).then((res) =>{ 
//     return res.data.datalist;
//   })
// }



export const getFacilityListWithMonthStatus = (pRegionId, pZoneId, pCommuneId, pMonthId, pYearId, pFacilityId = 0, ptoggleButtonValue) => {

  return api
    .post(
      "SNIGSLogisticsReportA7",
      {
        ...body,
        action: "getFacilityListWithMonthStatus",
        menukey: "snigs-logistics-report-a7",
        UserId: UserId,
        UserName: UserName,
        RegionId: pRegionId,
        ZoneId: pZoneId,
        CommuneId: pCommuneId,
        Month: pMonthId,
        Year: pYearId,
        FacilityId: pFacilityId,
        panelParam: ptoggleButtonValue

      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

};


export const callPanelBlockDataRequest = (pRegionId, pZoneId, pCommuneId, pMonthId, pYearId, pFacilityId = 0) => {

  return api
    .post(
      "SNIGSLogisticsReportA7",
      {
        ...body,
        action: "callPanelBlockDataRequest",
        menukey: "snigs-logistics-report-a7",
        UserId: UserId,
        UserName: UserName,
        RegionId: pRegionId,
        ZoneId: pZoneId,
        CommuneId: pCommuneId,
        Month: pMonthId,
        Year: pYearId,
        FacilityId: pFacilityId,

      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

};



export const SubmitSnigsData = (id) =>
  api.post(
    `SNIGSLogisticsReportA7`,
    { ...body, action: "changeBsubmittedInMaster", ...id },
    options
  ).then((res) => {
    return res.data;
  });

export const UnSubmitSnigsData = (id) =>
  api.post(
    `SNIGSLogisticsReportA7`,
    { ...body, action: "makeUnpublished", ...id },
    options
  ).then((res) => {
    return res.data;
  });

export const getSnigsSingleData = (sParams) =>
  api.post(
    `SNIGSLogisticsReportA7`,
    { ...body, menukey: "snigs-logistics-report-a7", action: "snigsLogisticsSingleView", ...sParams, "UserId": UserId },
    options
  );

export const saveRemarksChain = (senddata) =>
  api.post(
    `SNIGSLogisticsReportA7`,
    { ...body, menukey: "snigs-logistics-report-a7", action: "insertRemarksData", ...senddata },
    options
  );

//single dashboard
export const getDispenseTrendContextDashboard = (toggleButtonValue, Brandcode, GTIN, BatchNo, ExpiryDate, SerialNo, FacilityId) => {
  return api
    .post(
      "BarcodeScanForConDashboard",
      {
        ...body,
        action: "getDispenseTrend",
        menukey: "reports",
        toggleButtonValue: toggleButtonValue,
        Brandcode: Brandcode,
        lan: localStorage.getItem("LangCode"),
        UserName: UserName,
        FacilityId: FacilityId,
        GTIN: GTIN,
        BatchNo: BatchNo,
        ExpiryDate: ExpiryDate,
        SerialNo: SerialNo

      },
      options
    )
    .then((res) => {
      return res.data;
    });
};


//Central dashboard
export const getDispenseTrendCentralDashboard = (toggleButtonValue, Brandcode, GTIN, BatchNo, ExpiryDate, SerialNo) => {
  return api
    .post(
      "CentralDashboard",
      {
        ...body,
        action: "getDispenseTrend",
        menukey: "reports",
        toggleButtonValue: toggleButtonValue,
        Brandcode: Brandcode,
        lan: localStorage.getItem("LangCode"),
        UserName: UserName,
        GTIN: GTIN,
        BatchNo: BatchNo,
        ExpiryDate: ExpiryDate,
        SerialNo: SerialNo

      },
      options
    )
    .then((res) => {
      return res.data;
    });
};



// DHIS2 Access Settings
export const getDHIS2Access = () =>
  api
    .post(
      "DHIS2AccessSettings",
      { ...body, action: "getDataList", menukey: "pack-size" },
      options
    )
    .then((res) => {
      console.log("Refresh Test: " + new Date());
      return res.data.datalist;
    });


export const saveDHIS2AccessSettings = (DHIS2AccessSettings3) =>
  api
    .post(
      `DHIS2AccessSettings`,
      { ...body, action: "dataInsert", menukey: "dhis2-access-settings", ...DHIS2AccessSettings3 },
      options
    )
    .then((res) => res.data);

export const getDHIS2AccessData = (id) =>
  api.post(
    `DHIS2AccessSettings`,
    {
      ...body,
      action: "getDHIS2AccessSettingsData",
      menukey: "dhis2-access-settings",
      SettingsId: id,
    },
    options
  );

export const updateDHIS2AccessSettings = ({ id, ...updatedDHIS2AccessSettingsa }) =>
  api
    .put(
      `DHIS2AccessSettings`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "dhis2-access-settings",
        SettingsId: id,
        ...updatedDHIS2AccessSettingsa,
      },
      options
    )
    .then((res) => {
      return res;
    });



/* Demander Manufacturer-dashboard*/

export const getNumberOfBrandsDemander = (params) =>
  api.post("demandermanufacturerdashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getNumberOfBrandsManufacturer = (params) =>
  api.post("demandermanufacturerdashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getBrandDemanderManufacturerTotal = (params) =>
  api.post("demandermanufacturerdashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getBrandInfoByDemander = (params) =>
  api.post("demandermanufacturerdashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getDemanderManufacturerInfo = (params) =>
  api.post("demandermanufacturerdashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });



//DHIS2 Data Transfer Settings

export const getDHIST2ransferSettings = (params) =>
  api.post("DHIS2DataTransferSettings", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveDHIST2ransferSettings = (DHIST2ransferSettings) =>
  api
    .post(
      `DHIS2DataTransferSettings`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "dhis2-data-transfer-settings",
        ...DHIST2ransferSettings,
      },
      options
    )
    .then((res) => res.data);

export const getDHIST2ransfer = (id) =>
  api.post(
    `DHIS2DataTransferSettings`,
    {
      ...body,
      action: "getDHIST2ransferData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "dhis2-data-transfer-settings",
      SettingId: id,
    },
    options
  );

export const updateDHIST2ransfer = ({ id, ...updatedDHIST2ransfers }) =>
  api
    .put(
      `DHIS2DataTransferSettings`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "dhis2-data-transfer-settings",
        SettingId: id,
        ...updatedDHIST2ransfers,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const deleteDHIST2ransfers = (id) =>
  api.post(
    `DHIS2DataTransferSettings`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "dhis2-data-transfer-settings",
      SettingId: id,
    },
    options
  );


//Dhis2datatranserlogd

export const getDhis2datatranserlogdDetails = (params) =>
  api.post("Dhis2datatranserlogd", { params }, options).then((res) => {
    return res.data.datalist;
  });

//CAME Data Import
export const isReportCreatedCAMEData = (fromData) =>
  api.post(`came_data_import`, {
    action: "isReportCreated",
    menukey: "came-data-import",
    ...fromData
  }, options).then((res) => res.data);

export const CreateReportCAMEDataImport = (fromData) =>
  api.post(`came_data_import`, {
    action: "createReport",
    menukey: "came-data-import",
    ...fromData
  }, options).then((res) => res.data);

//shipment entry Data
export const getShipmentEntry = (params) => {

  let param = params.queryKey[0];
  return api
    .post("shipmentEntry", param, options)

    .then((res) => {
      return res.data.datalist;
    });
}

export const saveShipment = (shipmentData) =>
  api
    .post(
      `shipmentEntry`,
      { ...body, action: "dataInsert", menukey: "shipment-entry", ...shipmentData },
      options
    )
    .then((res) => res.data);

export const deleteShipment = (id) => api.post(`shipmentEntry`, id, options);



export const updateShipmentData = ({ id, ...updatedShipment }) =>
  api
    .put(
      `shipmentEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "pack-size",
        AgencyShipmentId: id,
        ...updatedShipment,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getShipmentData = (id) =>
  api.post(
    `shipmentEntry`,
    { ...body, action: "getShipment", menukey: "shipment-entry", AgencyShipmentId: id },
    options
  );


//country entry api

export const getCountryEntery = (params) =>
  api
    .post("CountryEntry", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const saveCountryEntry = (CountryListData) =>
  api
    .post(
      `CountryEntry`,
      { ...body, action: "dataInsert", menukey: "country-entry", ...CountryListData },
      options
    )
    .then((res) => res.data);



export const getCountryEntryData = (id) =>
  api.post(
    `CountryEntry`,
    {
      ...body,
      action: "getData",
      menukey: "country-entry",
      CountryId: id,
      lan: localStorage.getItem("LangCode"),
    },
    options
  );

export const updateCountryEntry = ({ id, ...updatedCountryEntry }) =>
  api
    .put(
      `CountryEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "country-entry",
        CountryId: id,
        ...updatedCountryEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });


// start fake medicine dashboard


export const getScanLogData = (params) =>
  api.post("fakemadicinedashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getTotalScanCount = (params) =>
  api.post("fakemadicinedashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getNumberOfScanPageChart = (params) =>
  api.post("fakemadicinedashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getNumberOfErrormsgChart = (params) =>
  api.post("fakemadicinedashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });


// end fake medicine dashboard
// Report api link
//report name PercentageOfFacilityByMOSScale

export const PercentageOfFacilityByMOSScale = (pFacilityId = 0, pBrandListId = 0) => {
  return api
    .post(
      "PercentageOfFacilityByMOSScale",
      { ...body, action: "getProductSerialTrackingReport", menukey: "product-serial-tracking-report", FacilityId: pFacilityId, BrandListId: pBrandListId, lan: localStorage.getItem("LangCode") },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


///esigl report
export const getStockStatusData = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getStockCardData = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductLotStockReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getProductLotStockReportMap = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
  export const getNationalStockkReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res;
  });
  export const getSVDLDashboardReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res;
  });
export const getReceiveSummaryReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getA7ReportViewReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const aProductList = (productdata) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...productdata },
      options
    )
    .then((res) => {

      return res;

    });
};
export const StoreListForReport = (storedata) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...storedata },
      options
    )
    .then((res) => {

      return res;

    });
};
export const getProductGTIN = (ProductGTIN) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...ProductGTIN },
      options
    )
    .then((res) => {

      return res;

    });
};
export const getCartonGTIN = (CartonGTIN) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...CartonGTIN },
      options
    )
    .then((res) => {

      return res;

    });
};
export const getfacilitybyflevel = (CartonGTIN) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...CartonGTIN },
      options
    )
    .then((res) => {

      return res;

    });
};

export const getFacility = (getfacilitydata) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...getfacilitydata },
      options
    )
    .then((res) => {

      return res;

    });
};


export const getProductListData = (getProductList) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...getProductList },
      options
    )
    .then((res) => {

      return res;

    });
};



export const GeProductExpired = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const GeReceivedetailsreport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const GetIssueSummaryReport = (params) =>
  api.post("IssueSummaryReport", { params }, options).then((res) => {
    return res.data.datalist;
  });




export const GetIssueDetailsReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

/*==Get Data by API==*/
export const getDatasetIdList = (Data) => {
  return api.post("get_data_by_api_server", { ...Data }, options).then((res) => {
    return res;
  });
};

export const getDataFromDHIS2 = (Data) => {
  return api.post("get_data_by_api_server", { ...Data }, options).then((res) => {
    return res;
  });
};

export const dataImportFromDHIS2 = (Data) => {
  return api.post("dataimportfromdhis2", { ...Data }, options).then((res) => {
    return res;
  });
};

export const GetStockTakeDetailsReport = (params) =>
  api.post("EsiglProductExpiredReport", { params }, options).then((res) => {
    return res.data.datalist;
  });



export const GetAdjustmentReport = (params) =>
  api.post("AdjustmentsummaryReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const GetAdjustmentDetailsReport = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const GetIssueDetailsReport1 = (params) =>
  api.post("MasterProductList", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const ProductGroup = (params) =>
  api.post("MasterProductList", { params }, options).then((res) => {
    return res.data.datalist;
  });


//Upload Certificate and Manufacturer Authorization
export const UploadManufacturerAuthorization = (fromData) =>
  api.post(`CertificateManufacturerAuthorizationUpload`, {
    action: "UploadManufacturerAuthorization",
    menukey: "certificate-manufacturer-authorization",
    ...fromData
  }, options).then((res) => res.data);

export const UploadCertificate = (fromData) =>
  api.post(`CertificateManufacturerAuthorizationUpload`, {
    action: "UploadCertificate",
    menukey: "certificate-manufacturer-authorization",
    ...fromData
  }, options).then((res) => res.data);

export const getBrandDataById = (Data) => {
  return api.post("CertificateManufacturerAuthorizationUpload", { ...Data }, options).then((res) => {
    return res;
  });
};

export const getDispenseFromDispensary = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const getTotalTurnoverReport = (params) =>
  api.post("IssueSummaryReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


  export const getStore = (getStoredatLista) => { 
    //console.log("dddd",zoneData);
    return api
      .post(
        "comboscript",
        { ...getStoredatLista },
        options
      )
      .then((res) => {
  
        return res;
  
      });
  };

//Brand Data Import
export const saveBrandDataImport = (fromData) =>
  api.post(`brand_data_import`, {
	  action: "BrandDataImport", 
	  menukey: "brand-data-import", 
	  ...fromData
	}, options).then((res) => res.data);
  

  

  export const getOrderData = (getOrderdata) => { 
    //console.log("dddd",zoneData);
    return api
      .post(
        "comboscript",
        { ...getOrderdata },
        options
      )
      .then((res) => {
  
        return res;
  
      });
  };



  export const getOrderList = (params) =>
  api.post("OrderReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


  export const getOrderBoxList = (params) =>
  api.post("OrderReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

  export const getLotExpirein3Months = (params) =>
  api.post("EsiglProductStockReport", { params }, options).then((res) => {
    return res.data.datalist;
  });


  //SVDL data Report

export const getExpirein_3Month = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});

export const getshipmentReport = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});


export const getExpiryRates = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});


export const getFacilityStockonHandValuation = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});

export const getSvdlFacilityReportingStatus = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});


export const GetTimelinessDetailsReport = (params) =>
api.post("EsiglProductStockReport", { params }, options).then((res) => {
  return res.data.datalist;
});


// owner entry
export const getownerEntry = () => api.post("SupplyChainOwner", { ...body, action: "getDataList", menukey: "supply-chain-owner" }, options).then((res) => {
  return res.data.datalist;
});
export const saveownerEntry = (ServiceData) => api.post(`SupplyChainOwner`, { ...body, action: "dataInsert", menukey: "supply-chain-owner", ...ServiceData }, options)
  .then((res) => res.data);
export const updateownerEntry = ({ id, ...updategetownerService }) => api.put(`SupplyChainOwner`, { ...body, "action": "dataUpdate", "menukey": "supply-chain-owner", "OwnerId": id, ...updategetownerService }, options).then((res) => {
  return res;
})
export const getownerEntryData = (id) => api.post(`SupplyChainOwner`, { ...body, action: "getData", menukey: "supply-chain-owner", OwnerId: id, }, options);
export const deleteownerEntry = (id) => api.post(`SupplyChainOwner`, id, options)

// end Year entry

/////Settings Entry start
export const getSettingsEntryData = (params) => api.post('SettingsEntry', { params }, options).then((res) => {
  return res;
})

export const saveSettingsEntry = (userData) =>
  api
    .post(
      `SettingsEntry`,
      { ...body, action: "dataInsert", menukey: "settings-entry", ...userData },
      options
    )
    .then((res) => res.data);

export const UpdateSettingUpdate = (params) =>
  api
    .put(
      `SettingsEntry`,
      {
        ...body,
        action: "dataUpdate",
        UserName: params.UserName,
        UserId: params.userId,
        lan: params.lan,
        menukey: "settings-entry",
        SettingsId: params.SettingsId,
        TabMaster: params.TabMaster,
        SKey: params.SKey,
        SValue: params.SValue,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////Settings Entry End

export const svdlStockDashboardAnalyse = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res.data.datalist;
});


export const getsvdlStockDashboardAnalyse = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res;
});

export const StockDashboardAnalyseParentByProduct = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res;
});

export const svdlStockDashboardAnalyse2 = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res;
});

export const getsvdlStockDashboardFacilityList = (params) =>
api.post("SvdlReportAllDataSource", { params }, options).then((res) => {
  return res;
});

export const getFacilitySyncStatus = (params) =>
api.post("EsiglProductStockReport", { params }, options).then((res) => {
  return res;
});

export const getSyncStatusFacilityList = (getSyncStatusFacility) => {
  return api
    .post(
      "comboscript",
      { ...getSyncStatusFacility },
      options
    )
    .then((res) => {
      return res;

    });
};