import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

import swal from "sweetalert";
import * as Service from "../../services/Service.js";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function ResetPassword(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  // const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false);
  const [state, setState] = React.useState({
    password: "",
    confirmPassword: "",
    reCaptcha: "",
  });

  /* ======Language====== */
  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = 'en_GB';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "login-signup-and-reset";
  /* ======Language====== */

  function onChangereCaptcha(value) {
    setState({ ...state, reCaptcha: value });
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (
      state.reCaptcha.length > 0 &&
      state.password.length > 0 &&
      state.confirmPassword.length > 0
    ) {
      
      if (state.reCaptcha === '') {
        swal("Oops Error!", "Please check the captcha", "error");
      } else if (state.password.length < 8) {
        swal(
          "Oops Error!",
          "Password length should be 8 characters or more!",
          "error"
        );
      } else if (state.password !== state.confirmPassword) {
        swal("Oops Error!", "Your Password doesn't match!", "error");
      } else {
        swal("Success!", "", "success");
        window.grecaptcha.reset();
        // const payload = {
        //   email: state.email,
        //   password: state.password,
        // };

        // const response = await Service.default.postApi(
        //   "source/reset_password.php",
        //   payload
        // );
        // if (response.status === 200) {
        //   swal("Success!", `${response.message}`, "success");
        // } else {
        //   swal("Error!", `${response.message}`, "error");
        // }
      }
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  return (
    <>
      <ExamplesNavbar  {...props} />
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          }}
        ></div>
        <div className="content login_signup_content">
          <Container>
            <div className="login_signup_card">
              <Row noGutters={true}>
                <Col xs={12} sm={12} md={12} lg={7}>
                  <div className="image_content_card h_login">
                    <div className="content_card">
                      <h3>Lorem Ipsum is simply dummy text</h3>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                    <div className="image_card">
                      <img src={require("assets/img/login_bg.png")} alt=""></img>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                   <div className="login_signup_form">
                      <h3>{ DispensingLanguage[lan][menukey]["Reset Password"] }</h3>
                      <p>{ DispensingLanguage[lan][menukey]["Please enter your new password"] }</p>
                      <InputGroup
                        className={
                          "input-lg" + (passwordFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="placeholder_color"
                          invalid={state.passwordValidate}
                          placeholder={ '*'+DispensingLanguage[lan][menukey]["New Password"] }
                          name="password"
                          onChange={(evt) => handleChange(evt)}
                          type={passwordShown ? "text" : "password"}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                        ></Input>
                        {state.passwordValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="password-eye">
                            <i
                              onClick={togglePasswordVisiblity}
                              className="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <InputGroup
                        className={
                          "input-lg" +
                          (confirmPasswordFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="sw_login_padd">
                            <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="placeholder_color"
                          invalid={state.confirmPasswordValidate}
                          placeholder={ '*'+DispensingLanguage[lan][menukey]["Confirm Password"] }
                          name="confirmPassword"
                          onChange={(evt) => handleChange(evt)}
                          type={confirmPasswordShown ? "text" : "password"}
                          onFocus={() => setConfirmPasswordFocus(true)}
                          onBlur={() => setConfirmPasswordFocus(false)}
                        ></Input>
                        {state.confirmPasswordValidate ? (
                          <FormFeedback invalid>
                            { DispensingLanguage[lan][menukey]["This is a required field"] }
                          </FormFeedback>
                        ) : null}
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="password-eye">
                            <i
                              onClick={toggleConfirmPasswordVisiblity}
                              className="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <ReCAPTCHA
                        sitekey="6Lej9c8hAAAAAKMe348CONHmOaU_wxc3U7vBJPLp" //Secret Key for epharmacie-bj.org
                        //sitekey="6Le88noUAAAAAJhsy0g8XFS4by9Qkd53j7MHtlYc" //Secret Key for Local
                        onChange = {onChangereCaptcha}
                      />

                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={(e) => submit(e)}
                        size="lg"
                      >
                        { DispensingLanguage[lan][menukey]["Reset Password"] }
                      </Button>
                      <div className="footer_card">
                        <ul className="login_signup_list">
                          <li className="pull-left"><Link to={"./login"}>{ DispensingLanguage[lan][menukey]["View Login Page"] }</Link></li>
                        </ul>
                      </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default ResetPassword;
