import React, { useState , useEffect} from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';  
//import swal from "sweetalert";
import * as api from '../../../actions/api';
import ReceiptsItems from './ReceiptsItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";
//import { set } from "date-fns";



//let mApiUrl = api.updateReceiveInv;
 
const EditReceipts = (props) => {

  

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receipts";
const FacilityId=localStorage.getItem('FacilityId');
const TransactionTypeId = 1;
const [dirty, setDirty] = useState(false);

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
   

  //const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",  
    "FacilityId":FacilityId,
    TransactionTypeId:TransactionTypeId,
    ReceiveFrom:"",
    PreparedBy:"", 
    Remarks: "",
    StoreId:"",
    InvFrom:"",
    ItemGroupId:"0",
    });
    const [selectedDate, setSelectedDate] = useState(
      new Date()
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);
  const [SerialDetails, setSerialDetails] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  
  const [bFirst, setBFirst] = useState(true); 

  const [ItemGroupId, setItemGroupId] = useState("0");
   
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['receive', id], () =>

    api.getReceiveInv({TransactionId:id,ItemGroupId:ItemGroupId,TransactionTypeId:TransactionTypeId, SerialDetails:SerialDetails}).then((res) => {
      setDirty(true);
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDeletedDataSet([]);
      setDirty(false);
      setBFirst(true);

      
      return res.data
    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })




  
  /*
  
  const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
 
        setbStockUpdate(data.bStockUpdated);
        if(data.bStockUpdated==1){

          mutatePost(TransactionId);
        
        }else{
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }


      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const {  mutate:mutatePost } = useMutation(api.updateStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        setbStockUpdate(1);
        setDeletedDataSet([]);

        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         
         }else{

          refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


  */


 
  
  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
    

};
  const handleChange = (e) => {
    const { name, value } = e.target;
     
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      //setDirty(true);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const filterHandleChange = (e) => {
    setItemGroupId(e) ;
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "", 
      "FacilityId":FacilityId,
      TransactionTypeId:TransactionTypeId ,
      TransactionId:"",
      ReceiveFrom:"",      
      PreparedBy:"",
      Remarks: "",
      InvFrom:"",
    });
  };


  const handleModalCheck = () => {
    if (validateForm(formData,2)) {
      return true;
    }else{
      return false;
    }
  };

  const validateForm = (formData,checkSupplierInv) => {
    //let validateFields = [ "TransactionDate", "TransactionNo","PreparedBy"]
    let validateFields = checkSupplierInv==2?["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ReceiveFrom"]:["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","SupplierInvNo","ReceiveFrom"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }


  const deleteReceiveInv=(data,deletesetdata)=>{ 

    setUpdatedDetailGridData(data);
    setDeletedDataSet(deletesetdata);
     
  }

  /*
  const deleteReceiveInv=(data)=>{
    console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }

  */

  /*
  const handleUpdate = async (e) => {
    if (validateForm(formData,1)) {
    let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet,menukey: menukey,lan: lan,"UserName":UserName}; 
    mutateUpdate(fDtat); 
    }
  };
  */


  

  /*
  const handlePost = async (rows,deletedsetdata) => {  

    
    if (validateForm(formData,1)) {
       
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,'ManyJsonSave':rows,"bStockUpdated":1,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows);
          
        }else{
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      
      
    }
     
  };

  */



  const {  mutate:mutateDownloadIssueInvoice } = useMutation(api.updateDownloadIssueInvoice, {
    onSuccess: (data) => { 
      if (data.status == 200) { 
      
        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
                
         
         }else{

          refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })



  const DownloadIssueInvoice = async (e) => {  

    
    if (validateForm(formData,1)) {
       
          let fData={...formData};
          mutateDownloadIssueInvoice(fData); 
        
      };  
   
  };


  /*
  const setUpdatedDetailsGridDataHandler = (rows,deletedsetdata) => {
    if (validateForm(formData,1)) { 
      let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedsetdata, menukey: menukey,lan: lan,"UserName":UserName}; 
      mutateUpdate(fDtat);
      } 
  }
  */

  /*
  const setUpdatedDetailsGridDataHandler = (rows) => {
    
    
    if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
      mutateUpdate(fDtat); 
      }
    setManyTableData(rows); 
  };
  */

  

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData,1)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function successSave(bStockUpdated){
    if(bStockUpdated !==1 ){
      refetch();
    }
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };
  


  useEffect(() => {
    refetch();
   
  }, [ItemGroupId]);
 
   
  return (
    <>
      <ReceiptsItems 
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
       // handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
       // handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        bFirst={bFirst}
        refetch={refetch}
        DownloadIssueInvoice={DownloadIssueInvoice}
        handleModalCheck={handleModalCheck}
        filterHandleChange={filterHandleChange} 
        dirty={dirty} 

        checkValidateForm={checkValidateForm}
        successSave={successSave}
        stockPosted={stockPosted}

        {...props} />
    </>
  );
};

export default EditReceipts;