import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  CardHeader,
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { parse } from "date-fns";
// import GMap from "../../../services/GMap";
const ProductListBrandFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleRDateChange,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "brands-list";

  const classes = useStyles();
  const GroupList = JSON.parse(localStorage.getItem("GroupList"));
  const getProductStatus = JSON.parse(localStorage.getItem("ProductStatus"));
  const Commerciallist = JSON.parse(localStorage.getItem("Commerciallist"));
  const Strengthlist = JSON.parse(localStorage.getItem("Strengthlist"));
  const PackSizelist = JSON.parse(localStorage.getItem("PackSizelist"));
  const DosageFormlist = JSON.parse(localStorage.getItem("DosageFormlist"));
  const Dcilist = JSON.parse(localStorage.getItem("Dcilist"));
  const Demanderlist = JSON.parse(localStorage.getItem("Demanderlist"));
  const GenericProduct = JSON.parse(localStorage.getItem("GenericProduct"));
  const ProductSubGroupList = JSON.parse(localStorage.getItem("ProductSubGroupList"));

  const [ItemNoValue, setItemNoValue] = useState({ id: '', name: "" });
  const changeItemNo = (val) => {

    setItemNoValue(val);

    if (val != null) {
      formData.ItemNo = val.id;
    } else {
      formData.ItemNo = "";
    }
  };

  const [ItemGroupValue, setItemGroupValue] = useState({ id: '', name: "" });
  const changeItemGroupId = (val) => {

    setItemGroupValue(val);

    if (val != null) {
      formData.ItemGroupId = val.id;
    } else {
      formData.ItemGroupId = "";
    }
  };

  const [ProductSubGroupValue, setProductSubGroupValue] = useState({ id: '', name: "" });
  const changeProductSubGroupId = (val) => {

    setProductSubGroupValue(val);

    if (val != null) {
      formData.ProductSubGroupId = val.id;
    } else {
      formData.ProductSubGroupId = "";
    }
  };

  const [ProductStatusValue, setProductStatusValue] = useState({ id: '', name: "" });
  const changeProductStatusId = (val) => {

    setProductStatusValue(val);

    if (val != null) {
      formData.ProductStatusId = val.id;
    } else {
      formData.ProductStatusId = "";
    }
  };


  const [CommercialValue, setCommercialValue] = useState({ id: '', name: "" });
  const changeCommercialId = (val) => {

    setCommercialValue(val);

    if (val != null) {
      formData.CommercialId = val.id;
    } else {
      formData.CommercialId = "";
    }
  };

  const [StrengthValue, setStrengthValue] = useState({ id: '', name: "" });
  const changeStrengthId = (val) => {

    setStrengthValue(val);

    if (val != null) {
      formData.StrengthId = val.id;
    } else {
      formData.StrengthId = "";
    }
  };

  const [PackSizeValue, setPackSizeValue] = useState({ id: '', name: "" });
  const changePacksizeId = (val) => {

    setPackSizeValue(val);

    if (val != null) {
      formData.PacksizeId = val.id;
    } else {
      formData.PacksizeId = "";
    }
  };

  const [DosageFormValue, setDosageFormValue] = useState({ id: '', name: "" });
  const changeDosageFormId = (val) => {

    setDosageFormValue(val);

    if (val != null) {
      formData.DosageFormId = val.id;

      // let target = {name: "DosageFormId", value: val.id};
      // handleChange({target});
    } else {
      formData.DosageFormId = "";

      // let target = {name: "DosageFormId", value: ''};
      // handleChange({target});
    }
  };

  const [DciValue, setDciValue] = useState({ id: '', name: "" });
  const changeDciId = (val) => {

    setDciValue(val);

    if (val != null) {
      formData.DciId = val.id;

      // let target = {name: "DciId", value: val.id};
      // handleChange({target});
    } else {
      formData.DciId = "";

      // let target = {name: "DciId", value: ''};
      // handleChange({target});
    }
  };

  const [DemanderValue, setDemanderValue] = useState({ id: '', name: "" });
  const changeDemanderId = (val) => {

    setDemanderValue(val);

    if (val != null) {
      formData.DemanderId = val.id;


    } else {
      formData.DemanderId = "";
    }
  };

  const [ManufacturerIdValue, setManufacturerIdValue] = useState({ id: '', name: "" });
  const changeManufacturerId = (val) => {

    setManufacturerIdValue(val);

    if (val != null) {
      formData.ManufacturerId = val.id;


    } else {
      formData.ManufacturerId = "";
    }
  };

  const formOnchange = (e, cases, inputname) => {

    // console.log(e);

    if (cases == 'handleChange')
      handleChange(e);
    else if (cases == 'handleRDateChange')
      handleRDateChange(e, inputname);
  
  };

 

  useEffect(() => {

    setItemGroupValue(GroupList[GroupList.findIndex(Group_List => Group_List.id == formData.ItemGroupId)]);
    setProductSubGroupValue(ProductSubGroupList[ProductSubGroupList.findIndex(ProductSubGroup_List => ProductSubGroup_List.id == formData.ProductSubGroupId)]);
    setProductStatusValue(getProductStatus[getProductStatus.findIndex(getProduct_Status => getProduct_Status.id == formData.ProductStatusId)]);
    setCommercialValue(Commerciallist[Commerciallist.findIndex(Commercial_list => Commercial_list.id == formData.CommercialId)]);
    setStrengthValue(Strengthlist[Strengthlist.findIndex(Strength_list => Strength_list.id == formData.StrengthId)]);
    setPackSizeValue(PackSizelist[PackSizelist.findIndex(PackSize_list => PackSize_list.id == formData.PacksizeId)]);
    setDosageFormValue(DosageFormlist[DosageFormlist.findIndex(DosageForm_list => DosageForm_list.id == formData.DosageFormId)]);
    setDciValue(Dcilist[Dcilist.findIndex(Dci_list => Dci_list.id == formData.DciId)]);
    setDemanderValue(Demanderlist[Demanderlist.findIndex(Demander_list => Demander_list.id == formData.DemanderId)]);
    setManufacturerIdValue(Demanderlist[Demanderlist.findIndex(Demander_list => Demander_list.id == formData.ManufacturerId)]);
    setItemNoValue(GenericProduct[GenericProduct.findIndex(Generic_Product => Generic_Product.id == formData.ItemNo)]);
   

  }, [formData.ItemGroupId]);

  

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Brand Entry"]} -{" "}
                {addProductForm
                  ? DispensingLanguage[lan][menukey]["Add"]
                  : DispensingLanguage[lan][menukey]["Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">

                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/brands-list-entry")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/brands-list-entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>





          <TextField
            hidden
            id="BrandNo"
            name="BrandNo"
            label="Item Short Name"
            value={formData.BrandNo}
            fullWidth
            disabled={true}
            autoComplete="given-name"
            onChange={(e) => handleChange(e)}
          />



          {/* New row */}
          <Grid item xs={4} sm={3}>
            <TextField
              disabled={addProductForm?false:true}
              id="BrandCode"
              name="BrandCode"
              label={DispensingLanguage[lan][menukey]["Brand Code"]}
              value={formData.BrandCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
              error={errorObject.BrandCode}
              helperText={errorObject.BrandCode}
              required
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="CommercialId"
                options={Commerciallist}
                onChange={(event, value) => changeCommercialId(value)}
                getOptionLabel={(option) => option.name}
                value={CommercialValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.CommercialId}
                    label={DispensingLanguage[lan][menukey]["Commercial"]}
                    variant="standard"
                    error={errorObject.CommercialId}
                    helperText={errorObject.CommercialId}
                    required
                    id="CommercialId"
                    name="CommercialId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>


          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                disabled={addProductForm?false:true}
                autoHighlight
                disableClearable
                id="ItemNo"
                options={GenericProduct}
                onChange={(event, value) => changeItemNo(value)}
                getOptionLabel={(option) => option.name}
                value={ItemNoValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.ItemNo}
                    label={DispensingLanguage[lan][menukey]["Generic"]}
                    variant="standard"
                    error={errorObject.ItemNo}
                    helperText={errorObject.ItemNo}
                    required
                    id="ItemNo"
                    name="ItemNo"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="ItemGroupId"
                options={GroupList}
                onChange={(event, value) => changeItemGroupId(value)}
                getOptionLabel={(option) => option.name}
                value={ItemGroupValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.ItemGroupId}
                    label={DispensingLanguage[lan][menukey]["Product Group"]}
                    variant="standard"
                    error={errorObject.ItemGroupId}
                    helperText={errorObject.ItemGroupId}
                    required
                    id="ItemGroupId"
                    name="ItemGroupId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="ProductSubGroupId"
                options={ProductSubGroupList}
                onChange={(event, value) => changeProductSubGroupId(value)}
                getOptionLabel={(option) => option.name}
                value={ProductSubGroupValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.ProductSubGroupId}
                    label={DispensingLanguage[lan][menukey]["Product Subgroup"]}
                    variant="standard"
                    error={errorObject.ProductSubGroupId}
                    helperText={errorObject.ProductSubGroupId}
                    required
                    id="ProductSubGroupId"
                    name="ProductSubGroupId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="SIGIPCode"
              name="SIGIPCode"
              label={DispensingLanguage[lan][menukey]["SIGIP Code"]}
              value={formData.SIGIPCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
              error={errorObject.SIGIPCode}
              helperText={errorObject.SIGIPCode}
              required
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="ProductStatusId"
                options={getProductStatus}
                onChange={(event, value) => changeProductStatusId(value)}
                getOptionLabel={(option) => option.name}
                value={ProductStatusValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.ProductStatusId}
                    label={DispensingLanguage[lan][menukey]["Product Status"]}
                    variant="standard"
                    error={errorObject.ProductStatusId}
                    helperText={errorObject.ProductStatusId}
                    required
                    id="ProductStatusId"
                    name="ProductStatusId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>



          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="StrengthId"
                options={Strengthlist}
                onChange={(event, value) => changeStrengthId(value)}
                getOptionLabel={(option) => option.name}
                value={StrengthValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.StrengthId}
                    label={DispensingLanguage[lan][menukey]["Strength"]}
                    variant="standard"
                    error={errorObject.StrengthId}
                    helperText={errorObject.StrengthId}
                    required
                    id="StrengthId"
                    name="StrengthId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="PacksizeId"
                options={PackSizelist}
                onChange={(event, value) => changePacksizeId(value)}
                getOptionLabel={(option) => option.name}
                value={PackSizeValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.PacksizeId}
                    label={DispensingLanguage[lan][menukey]["Pack Size"]} 
                    variant="standard"
                    error={errorObject.PacksizeId}
                    helperText={errorObject.PacksizeId}
                    required
                    id="PacksizeId"
                    name="PacksizeId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="DosageFormId"
                options={DosageFormlist}
                onChange={(event, value) => changeDosageFormId(value)}
                getOptionLabel={(option) => option.name}
                value={DosageFormValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.DosageFormId}
                    label={DispensingLanguage[lan][menukey]["Form"]}
                    variant="standard"
                    error={errorObject.DosageFormId}
                    helperText={errorObject.DosageFormId}
                    required
                    id="DosageFormId"
                    name="DosageFormId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="DciId"
                options={Dcilist}
                onChange={(event, value) => changeDciId(value)}
                getOptionLabel={(option) => option.name}
                value={DciValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.DciId}
                    label={DispensingLanguage[lan][menukey]["DCI"]}
                    variant="standard"
                    error={errorObject.DciId}
                    helperText={errorObject.DciId}
                    required
                    id="DciId"
                    name="DciId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="DemanderId"
                options={Demanderlist}
                onChange={(event, value) => changeDemanderId(value)}
                getOptionLabel={(option) => option.name}
                value={DemanderValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.DemanderId}
                    label={DispensingLanguage[lan][menukey]["Demander"]}
                    variant="standard"
                    error={errorObject.DemanderId}
                    helperText={errorObject.DemanderId}
                    required
                    id="DemanderId"
                    name="DemanderId"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="DemanderAddress"
              name="DemanderAddress"
              label={DispensingLanguage[lan][menukey]["Demander Address"]}
              value={formData.DemanderAddress}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PaysExploitant"
              name="PaysExploitant"
              label={DispensingLanguage[lan][menukey]["Pays Exploitant"]}
              value={formData.PaysExploitant}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <Autocomplete
                autoHighlight
                disableClearable
                id="ManufacturerId"
                options={Demanderlist}
                onChange={(event, value) => changeManufacturerId(value)}
                getOptionLabel={(option) => option.name}
                value={ManufacturerIdValue}
                renderOption={(option) => (
                  <Typography className="sw_fontSize">{option.name}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.ManufacturerId}
                    label={DispensingLanguage[lan][menukey]["Manufacturer"]}
                    variant="standard"
                    error={errorObject.ManufacturerId}
                    helperText={errorObject.ManufacturerId}
                    id="ManufacturerId"
                    name="ManufacturerId"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={4} sm={3}>
            <TextField
              id="IntituleFabriquant"
              name="IntituleFabriquant"
              label={DispensingLanguage[lan][menukey]["Intitule Fabriquant"]}
              value={formData.IntituleFabriquant}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid> */}
          <Grid item xs={4} sm={3}>
            <TextField
              id="PaysFabricant"
              name="PaysFabricant"
              label={DispensingLanguage[lan][menukey]["Pays Fabricant"]}
              value={formData.PaysFabricant}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="RepresentantLocalouExploitant"
              name="RepresentantLocalouExploitant"
              label={DispensingLanguage[lan][menukey]["Representant Localou Exploitant"]}
              value={formData.RepresentantLocalouExploitant}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="VoieAdministration"
              name="VoieAdministration"
              label={DispensingLanguage[lan][menukey]["Voie Administration"]}
              value={formData.VoieAdministration}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>

            <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
              <KeyboardDatePicker
                error={errorObject.DateExpirationDeLAMM}
                helperText={errorObject.DateExpirationDeLAMM}
                id="DateExpirationDeLAMM"
                label={DispensingLanguage[lan][menukey]['Date Expiration DeLAMM']}
                className={classes.fullWidth}
                // disableToolbar
                variant="inline"
                autoOk={true}
                name="DateExpirationDeLAMM"
                fullWidth
                showTodayButton={true}
                value={formData.DateExpirationDeLAMM}
                format="dd/MM/yyyy"
                //onChange={(e) => props.handleRDateChange(e, "CompleteDate")}
                onChange={(e) => formOnchange(e, 'handleRDateChange', 'DateExpirationDeLAMM')}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}

              />
            </MuiPickersUtilsProvider>
           
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="CondDelivrance"
              name="CondDelivrance"
              label={DispensingLanguage[lan][menukey]["Cond Delivrance"]}
              value={formData.CondDelivrance}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="CodeAMM"
              name="CodeAMM"
              label={DispensingLanguage[lan][menukey]["Code AMM"]}
              value={formData.CodeAMM}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PGHTPresentation"
              name="PGHTPresentation"
              label={DispensingLanguage[lan][menukey]["PGHT Presentation"]}
              value={formData.PGHTPresentation}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <TextField
              id="PGHTPrixPublic"
              name="PGHTPrixPublic"
              label={DispensingLanguage[lan][menukey]["PGHT Prix Public"]}
              value={formData.PGHTPrixPublic}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
              <KeyboardDatePicker
                error={errorObject.DateDeDebut}
                helperText={errorObject.DateDeDebut}
                id="DateDeDebut"
                label={DispensingLanguage[lan][menukey]['Date De Debut']}
                className={classes.fullWidth}
                // disableToolbar
                variant="inline"
                autoOk={true}
                name="DateDeDebut"
                fullWidth
                showTodayButton={true}
                value={formData.DateDeDebut}
                format="dd/MM/yyyy"
                onChange={(e) => formOnchange(e, 'handleRDateChange', 'DateDeDebut')}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}

              />
            </MuiPickersUtilsProvider>

          </Grid>
          <Grid item xs={4} sm={3}>
            <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
              <KeyboardDatePicker
                error={errorObject.DateExpiration}
                helperText={errorObject.DateExpiration}
                id="DateExpiration"
                label={DispensingLanguage[lan][menukey]['Date Expiration']}
                className={classes.fullWidth}
                // disableToolbar
                variant="inline"
                autoOk={true}
                name="DateExpiration"
                fullWidth
                showTodayButton={true}
                value={formData.DateExpiration}
                format="dd/MM/yyyy"
                onChange={(e) => formOnchange(e, 'handleRDateChange', 'DateExpiration')}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}

              />
            </MuiPickersUtilsProvider>

          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="ClasseTherapeutique"
              name="ClasseTherapeutique"
              label={DispensingLanguage[lan][menukey]["Classe Therapeutique"]}
              value={formData.ClasseTherapeutique}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="GPCCode"
              name="GPCCode"
              label={DispensingLanguage[lan][menukey]["GPC Code"]}
              value={formData.GPCCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="EAGTIN"
              name="EAGTIN"
              label={DispensingLanguage[lan][menukey]["EA GTIN"]}
              value={formData.EAGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="EAQty"
              name="EAQty"
              label={DispensingLanguage[lan][menukey]["EA Qty"]}
              value={formData.EAQty}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="EAChildGTIN"
              name="EAChildGTIN"
              label={DispensingLanguage[lan][menukey]["EA Child GTIN"]}
              value={formData.EAChildGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PKGTIN"
              name="PKGTIN"
              label={DispensingLanguage[lan][menukey]["PK GTIN"]}
              value={formData.PKGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PKQty"
              name="PKQty"
              label={DispensingLanguage[lan][menukey]["PK Qty"]}
              value={formData.PKQty}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PKChildGTIN"
              name="PKChildGTIN"
              label={DispensingLanguage[lan][menukey]["PK Child GTIN"]}
              value={formData.PKChildGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="CAGTIN"
              name="CAGTIN"
              label={DispensingLanguage[lan][menukey]["CA GTIN"]}
              value={formData.CAGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="CAQty"
              name="CAQty"
              label={DispensingLanguage[lan][menukey]["CA Qty"]}
              value={formData.CAQty}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="CAChildGTIN"
              name="CAChildGTIN"
              label={DispensingLanguage[lan][menukey]["CA Child GTIN"]}
              value={formData.CAChildGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PLGTIN"
              name="PLGTIN"
              label={DispensingLanguage[lan][menukey]["PL GTIN"]}
              value={formData.PLGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PLQty"
              name="PLQty"
              label={DispensingLanguage[lan][menukey]["PL Qty"]}
              value={formData.PLQty}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="PLChildGTIN"
              name="PLChildGTIN"
              label={DispensingLanguage[lan][menukey]["PL Child GTIN"]}
              value={formData.PLChildGTIN}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          {/* <Grid item xs={4} sm={3}>
            <TextField
              id="SOBAPSCode"
              name="SOBAPSCode"
              label={DispensingLanguage[lan][menukey]["SOBAPS Code"]}
              value={formData.SOBAPSCode}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid> */}


          {/* <Grid item xs={4} sm={3}>
            <Grid item xs={12} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={formData.bSerialSupported}
                    onChange={(e) => handleCheck(e)}
                    name="bSerialSupported"
                    value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["bSerialSupported"]}
              />
            </Grid>
          </Grid> */}

          {/* new row */}

          <Grid item xs={4} sm={4}>
            <FormControl component="fieldset">
             
              <RadioGroup
               row
                aria-label="bSerialSupported"
                name="bSerialSupported"
                value="0"
                className="sw_radiogroup"
              >
                <FormControlLabel
                  value="0"
                  checked={formData.bSerialSupported == 0}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["None"]}
                  onChange={(e) => handleChange(e)}
                />
                <FormControlLabel
                  value="1"
                  checked={formData.bSerialSupported == 1}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["bSerialSupported"]}
                  onChange={(e) => handleChange(e)}
                />
                <FormControlLabel
                  value="2"
                  checked={formData.bSerialSupported == 2}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["Supplier_Generated"]}
                  onChange={(e) => handleChange(e)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

        </Grid>

      </div>
    </div>


  );
};

export default ProductListBrandFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
