import React, { useState, useEffect } from "react";

import {
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";

import * as api from '../../../actions/api';
import StockConfigurationFormData from './StockConfigurationFormData.js';

const AddStockConfiguration = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "initial-stock-configuration";
const FacilityId=localStorage.getItem('FacilityId');
const StoreId = localStorage.getItem("StoreId");
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [formData, setFormData] = useState({
  StockTakeDate: selectedDate,
  CompleteDate: selectedDate,
  ApprovedBy: '',
  PreparedBy: '',
  IsCompleted:0,
});

const [ManyTableData, setManyTableData] = useState( []);

  const handleRDateChange = (date, field) => {
    
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(api.getInitialStockTakeInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        let route = `edit/${data.StockTakeId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    
      let data = { ...formData };
      console.log('formData: ', formData);
      data[name] = value;
      setFormData(data);
   


    setErrorObject({ ...errorObject, [name]: null });
  };


  const handleReset = () => {
    setFormData({
      StockTakeDate: selectedDate,
      CompleteDate: selectedDate,
      ApprovedBy: '',
      PreparedBy: '',
      IsCompleted:0,
      ManyJsonSave:"" 
    
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["StockTakeDate", "CompleteDate","PreparedBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
    }
  };
 

  return (
    <>
      <StockConfigurationFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange}  
      handleReset={handleReset} 
      handleSubmit={handleSubmit} 
      ManyTableData={ManyTableData} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      
      {...props} />
    </>
  );
};

export default AddStockConfiguration;