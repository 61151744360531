import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
const FacilityDetailsReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist  = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);

  const FLevel_List = JSON.parse(localStorage.getItem("FlevelList"));
  let All_FLevel_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FLevelList = [All_FLevel_List].concat(FLevel_List);

  const FType_List = JSON.parse(localStorage.getItem("FTypeList"));
  let All_FType_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FTypeList = [All_FType_List].concat(FType_List);

  let UseSiteList = [
    { id: "All", name: t(DispensingLanguage[lan][menukey]["All"]) },
    { id: "eSIGL", name: "eSIGL" },
    { id: "SVDL", name: "SVDL" }
  ];


  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currZoneIdId, setZoneId] = useState("0");
  const [currCommuneId, setcurrCommuneId] = useState("0");
  const [currFLevelId, setFLevelId] = useState(FLevelList[0].id);
  const [currFTypelId, setFTypeId] = useState(FTypeList[0].id);
  const [currUseSiteId, setUseSiteId] = useState(UseSiteList[0].id);

  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);
 
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);
  // Facility Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
   
    if (newValue == null) {
      rowId = '';
    
    } else {
      rowId = newValue.id;
    
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);
   

  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );






  // React.useEffect(() => {
   
  //   if (currProductGTINId == 0) {
  //     let ProductGTINlistParam1 = { action: "gProductGTIN", menukey: "" }
  //     cproductgtinlist(ProductGTINlistParam1);
  //   }
  //   if (currCartonGTINId == 0) {
  //     let CartonGTINlistParam1 = { action: "gCartonGTIN", menukey: "" }
  //     cCartongtinlist(CartonGTINlistParam1);
  //   }

  // }, [currProductGroupId, currProductGTINId, currCartonGTINId]);


  // Product Group Change
  const handleZoneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);

    }
  };


  // FLevel  Change
  const handleFLevelChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currFLevelId) {
      setFLevelId(rowId);
    }
   
  };

  // FType  Change
  const handleFTypeChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currFTypelId) {
      setFTypeId(rowId);
    }
   
  };
  
  // usesite Change
  const handleUsesiteChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
      
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currUseSiteId) {
      setUseSiteId(rowId);

    }
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "ShowFacilityDetailsReport",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    FLevelId: currFLevelId,
    FTypeId: currFTypelId,
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getProductLotStockReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Level/ Department/ ZS"],
      field: "FacilityLevelDepartmentZS",
      headerSort: false,
      headerFilter: true,
      
    },


    {
      title: DispensingLanguage[lan][menukey]["Facility/Device Id/Device Type"],
      field: "FacilityDeviceIdDeviceType",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       
       width: 280,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Device Register Date Time"],
      field: "DeviceRegisterDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Meta Sync Start/ End Date Time"],
      field: "MetaSyncStartEnd",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      // headerFilter: true,
      width: 280,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sync Start/ End Time"],
      field: "SyncStartEndTime",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 200,
    },
   
   
  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=FacilityDetailsReport" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneIdId +
      "&DistrictId=" +
      currCommuneId +
      "&FTypeId=" +
       currFTypelId +
      "&FLevelId=" +
      currFLevelId +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Facility Details Report"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                {/* <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["Start date"]
                        }
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid> */}


                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}

                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={3} sm={3}>
                  <div className="float-right sw_btn_control">

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>

                  </div>
                </Grid>

              </Grid>
              <Grid container>
               
              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FLevelList"
                      disableClearable
                      options={FLevelList}
                      onChange={(event, newValue) => handleFLevelChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FLevelList[FLevelList.findIndex(FLevelList => FLevelList.id == currFLevelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Level"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
               
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FTypeList"
                      disableClearable
                      options={FTypeList}
                      onChange={(event, newValue) => handleFTypeChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FTypeList[FTypeList.findIndex(FTypeList => FTypeList.id == currFTypelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Type"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="UseSiteList"
                      disableClearable
                      options={UseSiteList}
                     
                      onChange={(event, newValue) => handleUsesiteChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={UseSiteList[UseSiteList.findIndex(UseSiteList => UseSiteList.id == currUseSiteId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Use Site"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid> */}



              </Grid>
            </CardContent>
          </Card>
          <div>
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={395}
                // options={{
                //   groupBy: ["FLevelName"],

                //   columnCalcs: "both",
                // }}
              />
            </div>
            {/* <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityDetailsReport;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
