import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select, Typography, TextField, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, useEventCallback } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const MasterProductList = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);


  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));

  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProdutlist] = useState(0);
  const [ProductGroupName, setFacilityLavel] = useState([]);

  // console.log(currProductGroupId);

  // Facility Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setFacilityLavel(rowName);
    }
   
  };

  let KeyProductList = [
    { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
    { id: "1", name: t(DispensingLanguage[lan][menukey]["Key Product"]) },
    { id: "2", name: t(DispensingLanguage[lan][menukey]["Non Key Product"]) }
  ];
  const [cutProductId, setProdutlist] = useState(0);
  const [KeyProductName, setKeyProduct] = useState([]);
  // console.log("cutProductId",cutProductId);

  // Facility Change
  const handleKeyProducthange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== cutProductId) {
      setProdutlist(rowId);
      setKeyProduct(rowName);
    }
   
  };





  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "MasterProduct",
    ProductGroupId: currProductGroupId,
    ProductKey: cutProductId,
    lan:lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.GetIssueDetailsReport1,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Product_sub_Group"],
      field: "ProductSubGroupName",
      headerFilter: false,
      headerSort: false,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "CameCode",
      headerFilter: true,
      headerSort: false,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      headerFilter: true,
      headerSort: false,

    },
    {
      title: DispensingLanguage[lan][menukey]["Short Name"],
      field: "ShortName",
      headerSort: false,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit Name"],
      field: "UnitName",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Key Product"],
      field: "KeyProducts",
      headerSort: false,
      headerFilter: false,
      hozAlign: "center",
      headerHozAlign: "left",
      width: 120,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Unit_Price_CFA"],
      field: "Price",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=KeyProductPrrint" +
      "&reportType=" +
      reportType +
      "&ProductGroupId=" +
      currProductGroupId +
      "&ProductKey=" +
      cutProductId +
      "&ProductGroupName=" +
      ProductGroupName +
      "&KeyProductName=" +
      KeyProductName +
      "&menukey=" +
      menukey +
      "&lan=" +
     localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
        <div className="d-flex justify-uiLanguage">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title1">
                {DispensingLanguage[lan][menukey]["Master Product List"]}
              </div>
              <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
            </div>
          </Grid>
        </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}

                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="KeyProductList"
                          disableClearable
                          options={KeyProductList}

                          onChange={(event, newValue) => handleKeyProducthange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={KeyProductList[KeyProductList.findIndex(KeyProductList => KeyProductList.id == cutProductId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Key Product"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height="600"
                    options={{
                      groupBy: "GroupName",
                      columnCalcs: "both",
                    }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default MasterProductList;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});