import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { conditionallyUpdateScrollbar } from "reactstrap/lib/utils";
const DemanderFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const UserList = JSON.parse(localStorage.getItem("DemanderUserlist"));
  const CountryList = JSON.parse(localStorage.getItem("gAllCountryList"));
  const UserListEdit = JSON.parse(localStorage.getItem("UserListEdit"));
  const menukey = "demander";
  const classes = useStyles();

  const [acValue, setACValue] = useState({ id: '', name: "" });
  const [countryacValue, setACcountryValue] = useState({ id: '', name: "" });
  // const [acValueManufac, setACValueManufac] = useState({ id: '', name: "" });

  // const [demanderactive, setdemanderac] = useState(true);
  // const [manufactureractive, setmanufacturer] = useState(true);

  useEffect(() => {

    setACValue(UserList[UserList.findIndex(user_list => user_list.id == formData.DemanderUserId)]);
    setACcountryValue(CountryList[CountryList.findIndex(Country_List => Country_List.id == formData.CountryCode)]);
    // setACValueManufac(UserList[UserList.findIndex(user_list => user_list.id == formData.ManufacturerUserId)]);

    // setdemanderac(formData.bDemander==1?false:true);
    // setmanufacturer(formData.bManufacturer==1?false:true);

  }, [formData.DemanderUserId]);


  // const manufacturerchange = (e) => {

  //   setmanufacturer(e.target.checked==true?false:true);

  // };
  // const demanderchange = (e) => {

  //   setdemanderac(e.target.checked==true?false:true);

  // };

  const changeDemanderUserId = (val) => {
    setACValue(val);
    if (val != null) {
      formData.DemanderUserId = val.id;
    } else {
      formData.DemanderUserId = "";
    }


  };
  const changeCountryCodeId = (val) => {

    setACcountryValue(val);


    if (val != null) {
      formData.CountryCode = val.id;
    } else {
      formData.CountryCode = "";
    }


  };

  // const changeManufacturerUserId = (val) => {

  //   setACValueManufac(val);

  //   if (val != null) {
  //     formData.ManufacturerUserId = val.id;
  //   }else{
  //     formData.ManufacturerUserId = "";
  //   }


  // };


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Demander - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Demander Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.DemanderId}
                          helperText={errorObject.DemanderId}
                          required
                          id="DemanderId"
                          name="DemanderId"
                          label={DispensingLanguage[lan][menukey]['Demander Code']}
                          value={formData.DemanderId}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 10 }}
                          disabled={!addProductForm}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8}>
                        <TextField
                          error={errorObject.DemanderName}
                          helperText={errorObject.DemanderName}
                          required
                          id="DemanderName"
                          name="DemanderName"
                          label={DispensingLanguage[lan][menukey]['Demander Name']}
                          value={formData.DemanderName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                     


                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                   

                    <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.AddressLine1}
                          helperText={errorObject.AddressLine1}
                         
                          id="AddressLine1"
                          name="AddressLine1"
                          label={DispensingLanguage[lan][menukey]['Address Line 1']}
                          value={formData.AddressLine1}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.AddressLine2}
                          helperText={errorObject.AddressLine2}
                          
                          id="AddressLine2"
                          name="AddressLine2"
                          label={DispensingLanguage[lan][menukey]['Address Line 2']}
                          value={formData.AddressLine2}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.City}
                          helperText={errorObject.City}
                         
                          id="City"
                          name="City"
                          label={DispensingLanguage[lan][menukey]['City']}
                          value={formData.City}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                  
                     
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Zip}
                          helperText={errorObject.Zip}
                         
                          id="Zip"
                          name="Zip"
                          label={DispensingLanguage[lan][menukey]['Zip']}
                          value={formData.Zip}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>


                          <Autocomplete
                            // disabled={demanderactive}
                            autoHighlight
                            id="atc"
                            options={CountryList}
                            onChange={(event, value) => changeCountryCodeId(value)}
                            getOptionLabel={(option) => option.name}
                            value={countryacValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.CountryCode}
                                label={DispensingLanguage[lan][menukey]["Country Name"]}
                                variant="standard"
                                error={errorObject.CountryCode}
                                helperText={errorObject.CountryCode}
                               required
                                id="CountryCode"
                                name="CountryCode"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ContactName}
                          helperText={errorObject.ContactName}
                         
                          id="ContactName"
                          name="ContactName"
                          label={DispensingLanguage[lan][menukey]['Contact Name']}
                          value={formData.ContactName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>




                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                    <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Contact}
                          helperText={errorObject.Contact}
                         
                          id="Contact"
                          name="Contact"
                          label={DispensingLanguage[lan][menukey]['Contact#']}
                          value={formData.Contact}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                     
                      {/* <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Phone}
                          helperText={errorObject.Phone}
                         
                          id="Phone"
                          name="Phone"
                          label={DispensingLanguage[lan][menukey]['Phone']}
                          value={formData.Phone}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> */}
                      
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Email}
                          helperText={errorObject.Email}
                         
                          id="Email"
                          name="Email"
                          label={DispensingLanguage[lan][menukey]['Email']}
                          value={formData.Email}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.URL}
                          helperText={errorObject.URL}
                         
                          id="URL"
                          name="URL"
                          label={DispensingLanguage[lan][menukey]['URL']}
                          value={formData.URL}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                     
                     

                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={4} className="marginTop10">


                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.bDemander}
                              onChange={(e) => handleCheck(e)}
                              name="bDemander"
                              value="1"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["IsDemander"]}
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.bManufacturer}
                              onChange={(e) => handleCheck(e)}
                              name="bManufacturer"
                              value="2"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["IsManufacturer"]}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>


                        <FormControl className={classes.fullWidth}>


                          <Autocomplete
                           disabled={UserRole == 1 ? false : true}
                            autoHighlight
                            id="atc"
                            options={UserList}
                            onChange={(event, value) => changeDemanderUserId(value)}
                            getOptionLabel={(option) => option.name}
                            value={acValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.DemanderUserId}
                                label={DispensingLanguage[lan][menukey]["Demander User"]}
                                variant="standard"
                                error={errorObject.DemanderUserId}
                                helperText={errorObject.DemanderUserId}
                                id="DemanderUserId"
                                name="DemanderUserId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>

                      </Grid>

                      {/* <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                           disabled={manufactureractive}
                          
                            autoHighlight
                            id="atc"
                            options={UserList}
                            onChange={(event, value) => changeManufacturerUserId(value)}
                            getOptionLabel={(option) => option.name}
                            value={acValueManufac}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ManufacturerUserId}
                                label={DispensingLanguage[lan][menukey]["Manufacturer User"]}
                                variant="standard"
                                error={errorObject.ManufacturerUserId}
                                helperText={errorObject.ManufacturerUserId}
                                required
                                id="ManufacturerUserId"
                                name="ManufacturerUserId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>

                      </Grid> */}


                    </Grid>
                  </Grid>
                </Grid>


              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']} 
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/demander")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/demander")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default DemanderFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
