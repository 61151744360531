
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as api from '../../../actions/api';

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';

import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Typography
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";
import "../common.css";


// react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import swal from "sweetalert";

import GenericModal from '../../../services/GenericModal'; 

let isDirty = false; 
let deletedDatas = [];
let transactionItemID =0;
let masterDirty=false;

const OrderFormData = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "orders";

  let tblRef = null;
  const classes = useStyles();

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;
  const FacilityName = localStorage.getItem('FacilityName');

  const [manyTableData, setManyTableData] = useState([]);
  const [bCompleted, setbCompleted] = useState(false);
  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  const OrderType = JSON.parse(localStorage.getItem("OrderType"));
  const OrderTo = JSON.parse(localStorage.getItem("getFacilityListNotEqual99ANDSupplier"));
  const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));

  const { t, i18n } = useTranslation();
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  const [open, setOpen] = useState(false);

 // const [toggleButtonValue, setToggleButton] = React.useState("1");

  
  const [bStockUpdate, setbStockUpdate] = useState(false); 

  /*
  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {

      setToggleButton(tmpToggleButtonValue);
    }
  };

  */

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_print.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&OrderId=" + props.formData.OrderId
      + "&FacilityId=" + props.formData.FacilityId
      + "&TimeStamp=" + Date.now()
    );
  };

  const ExcelMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_excel.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&OrderId=" + props.formData.OrderId
      + "&FacilityId=" + props.formData.FacilityId
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const handleClose = (modalname) => {
    switch (modalname) {

      case "Products":
        setOpen(false);
        break;

      case "ProductsOpen":
        setOpen(true);
        break;

      default:
        break;
    }
  }



  const manycolumns = [
    { title: 'Id', field: 'id', visible: false },

    { title: DispensingLanguage[lan][menukey]['Sl#'], width: 70, hozAlign: 'center', formatter: "rownum", headerSort: false, headerHozAlign: "center" },
    {
      title: "dirty",
      field: "dirty",

      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['Product Code'], field: 'ItemCode', width: 135, headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]['Product Name'], field: 'ItemName', headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]['AMC'], field: 'AMC', hozAlign: "right", headerHozAlign: "right", width: 70 },
    { title: DispensingLanguage[lan][menukey]['Minimum Quantity'], field: 'MinQty', hozAlign: "right", headerHozAlign: "right", width: 65 },
    { title: DispensingLanguage[lan][menukey]['Maximum Quantity'], field: 'MaxQty', hozAlign: "right", headerHozAlign: "right", width: 65 },
    { title: DispensingLanguage[lan][menukey]['Stock on Hand'], field: 'StockOnHand', width: 75, hozAlign: "right", headerHozAlign: "right" },
    { title: DispensingLanguage[lan][menukey]['Calculated Order Quantity'], field: 'CalcOrderQty', width: 123, hozAlign: "right", headerHozAlign: "right" },

    {
      title: DispensingLanguage[lan][menukey]['Actual Order Quantity'],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 121,

      editor: props.bCompleted == -1 ? (props.isFirstPhysical == 0 ? cellFocusEditor : '') : '',   //"input" 
      cssClass: props.isFirstPhysical == 0 ? "tabluator-column-editable" : '',
      cellEdited: function (cell) {
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        transactionItemID=rowdata.SKU;
        onSetDisable();
        //  setDirty(true);

        //  return;

      },
      // editor: bCompleted === -1 ? cellFocusEditor : '',
      // validator: ["integer", "min:0"],
      // cssClass: "tabluator-column-editable",
      // cellEdited: function (cell) {

      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      //   const pricecell = currow.getCell("LineTotal");
      //   const totprice = rowdata.UnitPrice * curcellval;
      //   pricecell.setValue(totprice);

      // },
    },

    //{ title: 'Actual Order Quantity', field: 'ActualOrderQty', hozAlign:"right", editor:true},
    {
      title: DispensingLanguage[lan][menukey]['Comment'],
      field: 'Remarks',
      width: 110,
      hozAlign: "left",
      headerHozAlign: "left",
      editor: props.bCompleted == -1 ? (props.isFirstPhysical == 0 ? cellFocusEditor : '') : '',   //"input" 
      cssClass: props.isFirstPhysical == 0 ? "tabluator-column-editable text-field-editable" : '',
      cellEdited: function (cell) {
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        transactionItemID=rowdata.SKU;
        onSetDisable();
        //  setDirty(true);

        //  return;

      },
      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: bCompleted === -1 ? true : ''
    },
    { title: DispensingLanguage[lan][menukey]['Unit Price'], field: 'UnitPrice', hozAlign: "right", headerHozAlign: "right", width: 90 },

    {
      title: DispensingLanguage[lan][menukey]['Line Total'],
      field: 'LineTotal',
      formatter: "money",
      hozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      headerHozAlign: "right",
      width: 105
    }

  ];


  const onSetDisable = () => {

    if (isDirty == true) {

      document.getElementById("fltProductgroup").classList.add("swDisabled");
      //document.getElementById("tglbuttongroup").classList.add("swDisabled");
      document.getElementById("is_dirty_message").classList.remove("dnone");

      let dataArray = [];
      let gridData = tblRef.table.getData();
      gridData.forEach((row, i) => {
        if(row.SKU==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      let fDtat = { ...props.formData, 'ManyJsonSave': dataArray, menukey: menukey, lan: lan, UserName:UserName };
      mutateCellUpdate(fDtat);

    }
    else {

      document.getElementById("fltProductgroup").classList.remove("swDisabled");
      //document.getElementById("tglbuttongroup").classList.remove("swDisabled");
      document.getElementById("is_dirty_message").classList.add("dnone");



    }

  }

  const onMasterSetDirty = () => {    
    if(masterDirty==true){   
      
      document.getElementById("master_dirty_message").classList.remove("dnone");    
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    } 
   // props.formData.masterDirty=masterDirty;
  }

  const mutateCellUpdate = (fDtat) => {

    const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null;
    const apiUrl = process.env.REACT_APP_API_URL + "source/api/orderUpdate";
    let data = fDtat;

    fetch(apiUrl, {
      method: "PUT",
      headers: {

        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth_token}`,
        "Consumer": process.env.REACT_APP_CONSUMER,
        "Secret": process.env.REACT_APP_SECRET,

      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        console.log(res);

        return res.json();
      })
      .then(function (data) {
        isDirty = false;
        onSetDisable();
      })
      .catch(error => {

        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
          msgtype: 0,
        });

      });


  }




  const ManyDataItems = props.ManyTableData?.map((item, index) => {
    item["LineTotal"] = item.ActualOrderQty * item.UnitPrice;
    return item
  })

/*
  useEffect(() => {
    if (!props.addProductForm) {
      //props.handleUpdate();
      props.TabRefresh(toggleButtonValue);
    }


  }, [toggleButtonValue]);

  */

  const addProduct = (NonOrderProductData) => {  

    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;

      newRow.SKU=autoId;
      newRow.id=autoId;  
      rows.push(newRow);
    
  });

 
        if(rows.length>0){
        let fDtat={'ManyJsonSave':rows,UserId:UserId,lan:lan, OrderId:props.formData.OrderId, 
        FacilityId:props.formData.FacilityId, OrderTypeId:props.formData.OrderTypeId,UserName:UserName,
        masterDirty:props.formData.masterDirty}; 
        mutateModal(fDtat); 
        }

  };


  const { isLoading: isLoadingSaveModal, mutate:mutateModal } = useMutation(api.saveOrderModalData, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      masterDirty=false;
      onMasterSetDirty(); 
      handleClose('Products');
      props.refetch();

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });


  useEffect(() => {
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setbCompleted(props.bCompleted);

    onSetDisable();
    deletedDatas = [];
  }, [props.ManyTableData, props.bCompleted]);



  // const handleChange = (e) => {
  //   props.filterFandleChange(e.target.value);
  //   setCurrItemGroupId(e.target.value);
  // };

  const handleProductGroupChange = (event, newValue) => {

    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currItemGroupId) {
      if(props.addProductForm==false){
      props.filterFandleChange(rowId);
      }
      setCurrItemGroupId(rowId); 
    }
  };

  const onPostBtnClick = () => {

    //setbCompleted(true); 
    onSetDisable();
    let dataArray = [];
    let gridData = tblRef.table.getData();
    gridData.forEach((row, i) => {
      if(row.SKU==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    })

     handlePost(dataArray);
    //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 

  }



  const handlePost = async (rows) => {  

    let validForm = props.checkValidateForm(tblRef.table.getData(), 'post');
    

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
 
          let FinalData={...validForm,'ManyJsonSave':rows,"IsCompleted":0,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows);
 
        }else{
          //setbCompleted(false); 
          props.stockPosted(-1);
          let route = `${validForm.OrderId}`;
          props.history.push(route);
        }
      });  
    }
      
    
     
     
  };




  const onSaveUpdateBtnClick = () => {

    let dataArray = [];
    let gridData = tblRef.table.getData();
    gridData.forEach((row, i) => {
      if(row.SKU==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    })

   setUpdatedDetailsGridDataHandler(dataArray);
    //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 

  }

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveOrderInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      masterDirty=false;
      onMasterSetDirty(); 

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.id}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  



  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateOrderInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 

        masterDirty=false;
        onMasterSetDirty();

      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        props.successSave(data.IsCompleted);
        if(data.IsCompleted==0){
         mutatePost({OrderId:props.formData.OrderId , FacilityId:props.formData.FacilityId, lan:lan, UserName:UserName});
        }
        else{
          props.successSave(data.IsCompleted); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });
  


  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postOrderInv, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        //props.setbStockUpdate(1);
        //setDeletedDataSet([]);

        if(data.data.success==0){ 
          
          props.stockPosted(-1);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          props.stockPosted(0);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        props.stockPosted(-1);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })



  const handleDeleteAllRecreateOrder = async (e) => {

    let validForm = props.checkValidateForm(e,'del');
  
    if (validForm) {
    let FinalData={OrderId:props.formData.OrderId,FacilityId:props.formData.FacilityId,lan:lan,UserId:UserId,UserName:UserName}; 
   
    mutateDeleteAllReOrder(FinalData); 
    }
  };


  const { isLoading:isLoadingRecreate, mutate:mutateDeleteAllReOrder } = useMutation(api.deleteAllRecreateOrderInv, {
  
    onSuccess: (data) => {
      
      if (data.status == 200) {  
        props.openNoticeModal({
          isOpen: true,
          
          msg: data.message,
          msgtype: data.success,
        });
  
        props.refetch(); 
     
      }else{
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


  const backToList = () => {
    if(masterDirty==true){

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          masterDirty=false;
          props.history.push("/orders");

        }
      });

    } else props.history.push("/orders")

  };

  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",e);
    //isDirty=true;
     masterDirty=true;
     onMasterSetDirty(); 
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

};


const [OrderingFrom, setOrderingFrom] = useState({ id: '', name: "" });
const [OrderTypeId, setOrderTypeId] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });
const [OrderBy, setOrderBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   console.log(event);
  switch(cases){
    case 'OrderingFrom': 
      setOrderingFrom(val);
      props.formData.OrderingFrom= val != null?val.id:"";
      break;
    case 'OrderTypeId': 
        setOrderTypeId(val);
        props.formData.OrderTypeId= val != null?val.id:"";
      break;
   case 'ApprovedBy': 
      setApprovedBy(val);        
      props.formData.ApprovedBy= val != null?val.id:""; 
    break;
    case 'OrderBy': 
    setOrderBy(val); 
    props.formData.OrderBy= val != null?val.id:""; 
   
  break;
  

  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
  setOrderingFrom(OrderTo[OrderTo.findIndex(OrderToList => OrderToList.id == props.formData.OrderingFrom)]);  
  setOrderTypeId(OrderType[OrderType.findIndex(OrderTypeList => OrderTypeList.id == props.formData.OrderTypeId)]);  
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.ApprovedBy)]);  
  setOrderBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.OrderBy)]);  
}, [props.formData.OrderingFrom,props.formData.OrderTypeId,props.formData.ApprovedBy,props.formData.OrderBy]);

 

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>

            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Order - Add/Edit']+' ('+FacilityName+')'}</div>

        <div className="float-right sw_btn_control">

                {/* Action buttons */}
                {
                  props.addProductForm ?
                    <Grid item xs={12} className="mt-4 text-center">

  <div className="mr-4 float-left">
    <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
  </div>
  
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => backToList()}
                        //onClick={() => props.history.push("/orders")}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]['Reset']}
                      </Button> */}
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        //onClick={() => props.handleSubmit()}
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]['Save']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        {DispensingLanguage[lan][menukey]['DELETE ALL and RECREATE']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        {DispensingLanguage[lan][menukey]['COMPLETE']}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>

                    </Grid>
                    :
                    <Grid item xs={12} className="mt-2 text-center">
        
        <div className="mr-4 float-left">
          <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
        </div>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => backToList()}
                      // onClick={() => props.history.push("/orders")}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        // onClick={() => props.handleUpdate()}
                        onClick={onSaveUpdateBtnClick}
                         //disabled={bCompleted == -1 ? false : true}
                        disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost || isLoadingRecreate}
                      >
                        {DispensingLanguage[lan][menukey]['update']}
                      </Button>


                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleDeleteAllRecreateOrder()}
                        //disabled={bCompleted == -1 ? false : true}
                        disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost || isLoadingRecreate}
                      >
                        {DispensingLanguage[lan][menukey]['DELETE ALL and RECREATE']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onPostBtnClick}
                        // onClick={() => props.handlePost()}
                        //disabled={bCompleted == -1 ? false : true}
                        disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost || isLoadingRecreate}
                      >
                        {DispensingLanguage[lan][menukey]['COMPLETE']}
                      </Button>



                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>

                    </Grid>
                }
                {/* End Action buttons */}

              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>

                  <Grid item xs={3} sm={3}>

                    <TextField
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      required
                      id="OrderNo"
                      name="OrderNo"
                      label={DispensingLanguage[lan][menukey]['Order#']}
                      value={props.formData.OrderNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','OrderNo')}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]['Order Date']}
                        className={classes.fullWidth}
                        disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "OrderDate")}
                        onChange={(e) =>formOnchange(e,'handleRDateChange','OrderDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={bCompleted == -1 ? false : true}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>



                  <Grid item xs={3} sm={3}>

                    <FormControl className={classes.fullWidth}>

                  

                    <Autocomplete
                        disabled={props.addProductForm == false ? true : false}                     
                        autoHighlight
                        disableClearable
                        id="OrderingFrom"
                        options={OrderTo}  
                        onChange={(event, value) => changeVal(event,value,'OrderingFrom')} 
                        getOptionLabel={(option) => option.name}
                        value={OrderingFrom}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.OrderingFrom}
                          label={DispensingLanguage[lan][menukey]["Order To"] }
                          variant="standard"
                          error={props.errorObject.OrderingFrom}
                          helperText={props.errorObject.OrderingFrom}
                          required
                          id="OrderingFrom"
                          name="OrderingFrom" 
                          fullWidth
                        />
                        )}
                      />
                    


 

                    </FormControl>


                    
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>

                      
                    <Autocomplete
                       disabled={props.addProductForm == false ? true : false}                     
                        autoHighlight
                        disableClearable
                        id="OrderTypeId"
                        options={OrderType}  
                        onChange={(event, value) => changeVal(event,value,'OrderTypeId')} 
                        getOptionLabel={(option) => option.name}
                        value={OrderTypeId}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.OrderTypeId}
                          label={DispensingLanguage[lan][menukey]["Order Type"] }
                          variant="standard"
                          error={props.errorObject.OrderTypeId}
                          helperText={props.errorObject.OrderTypeId}
                          required
                          id="OrderTypeId"
                          name="OrderTypeId" 
                          fullWidth
                        />
                        )}
                      /> 
                    </FormControl>
                  </Grid>





                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        disabled={bCompleted == -1 ? false : true}                   
                        autoHighlight
                        disableClearable
                        id="OrderBy"
                        options={UsersListByFacility}  
                        onChange={(event, value) => changeVal(event,value,'OrderBy')} 
                        getOptionLabel={(option) => option.name}
                        value={OrderBy}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.OrderBy}
                          label={DispensingLanguage[lan][menukey]["Entry By"] }
                          variant="standard"
                          error={props.errorObject.OrderBy}
                          helperText={props.errorObject.OrderBy}
                          required
                          id="OrderBy"
                          name="OrderBy" 
                          fullWidth
                        />
                        )}
                      /> 
                        
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        disabled={bCompleted == -1 ? false : true}                   
                        autoHighlight
                        id="ApprovedBy"
                        options={UsersListByFacility}  
                        onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                        getOptionLabel={(option) => option.name}
                        value={ApprovedBy}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.ApprovedBy}
                          label={DispensingLanguage[lan][menukey]["Approved By"] }
                          variant="standard"
                          error={props.errorObject.ApprovedBy}
                          helperText={props.errorObject.ApprovedBy}
                          required
                          id="ApprovedBy"
                          name="ApprovedBy" 
                          fullWidth
                        />
                        )}
                      />

                       
                         
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={3} sm={3}>

                    <TextField

                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]['Remarks']}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','Remarks')}
                      disabled={bCompleted == -1 ? false : true}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="OrderId"
                      name="OrderId"
                      label="OrderId"
                      value={props.formData.OrderId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} sm={12}>
                <Card className="sw_card">
                  <CardContent className="sw_filterCardContent">
                    <Grid container className="mb-2">


                      {/* <Grid item xs={2} sm={2}>
                        <FormControl id="fltProductgroup" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                            error={props.errorObject.ItemGroupId}
                            labelId="demo-simple-select-helper-label"
                            id="ItemGroupId"
                            name="ItemGroupId"
                            value={currItemGroupId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid> */}

              <Grid item xs={3} sm={3}>
               <FormControl id="fltProductgroup" className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ItemGroupId"
                      disabled={props.addProductForm==true?true:false}
                      disableClearable
                      options={GroupList}               
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                        name="ItemGroupId"
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>

                      <Grid item xs={4} sm={4}>
                        <div className="float-right">
                          <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                        </div>
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <div className="float-right sw_btn_control">

                        <Button
                          
                          //disabled={props.addProductForm == false ? false : true}
                          disabled={(props.addProductForm == true ? true : false)||(bCompleted == -1 ? false : true)|| isLoadingUpdate || isLoadingPost || isLoadingRecreate}
                          className="mr-2"
                          variant="contained"
                          color="primary"
                          onClick={() => handleClose("ProductsOpen")}
                        >
                          {DispensingLanguage[lan][menukey]['Add Product']}  
                        </Button>


                        <GenericModal handleClose={handleClose} open={open} 
                              addProduct={addProduct} 
                          
                              {...props} />

                          {/* <ToggleButtonGroup
                            id="tglbuttongroup"
                            className="sw_ToggleButtonGroup"
                            color="primary"
                            value={toggleButtonValue}
                            exclusive
                            onChange={handleChangeToggle}

                          >
                            <ToggleButton value="2">
                              {
                                DispensingLanguage[lan][menukey][
                                "All"
                                ]
                              }
                            </ToggleButton>
                            <ToggleButton value="1">
                              {
                                DispensingLanguage[lan][menukey][
                                "ONLY ORDERED ITEMS"
                                ]
                              }
                            </ToggleButton>


                          </ToggleButtonGroup> */}





                        </div>
                      </Grid>
                    </Grid>

                    <div>
                      <div className="uniqueName">
                        <ReactTabulator

                          ref={(r) => (tblRef = r)}
                          columns={manycolumns}
                          data={manyTableData}
                          layout={"fitColumns"}
                          height="340px"
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </CardContent>

                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
