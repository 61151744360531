import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Notification from "../../../services/Notification";
import swal from "sweetalert";
// Facility screen
import FacilityList from "./FacilityList";
import FacilityMap from "./FacilityMap";
import FacilityView from "./ViewFacility";



const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };
  let auth =sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0; 
   
  // let auth = Constants.USER_INFO;
 // if (auth) {

  return (
    

        <div>
          {
          auth==0?<ExamplesNavbar {...props} />:<AfterLoginNavbar {...props} />
  
          }
        
        <div
          className={`section signup-top-padding ${classes.facilityContainer}`}
        >
          <Switch>
		  

         <Route
          path={`${path}/edit/:id`}
          render={(props) => (
            <FacilityView {...props} openNoticeModal={openNoticeModal} />
          )}
        ></Route>
		  
		  
			  <Route
			  path={`${path}/add`}
			  render={(props) => (
				<FacilityMap {...props} openNoticeModal={openNoticeModal} />
			  )}
			  ></Route>
            
        <Route
          path={`${path}/`}
          render={(props) => (
            <FacilityList {...props} openNoticeModal={openNoticeModal} />
          )}
        ></Route>
			
          </Switch>
          <Notification
            closeNoticeModal={closeNoticeModal}
            msgObj={msgObj}
            {...props}
          ></Notification>
        </div>
      </div>

  );
};

export default Index;

const useStyles = makeStyles({
  facilityContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
