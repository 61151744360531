import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent, } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";


const ZS = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "zs";


  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [RegionIdValue, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [isLoadingUi, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  
  const [Regionlist, setRegionlist] = useState([]);
  const [Region, setRegion] = React.useState(true);

  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }


  let params = {
    menukey: menukey,
    action: "getDataList",
    RegionId: RegionIdValue,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { refetch } = useQuery(
    [params],
    api.getZSs,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  const { mutate } = useMutation(api.deleteZS, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("zs");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
    },
  });



  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ZSExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")+
        "&RegionId=" + RegionIdValue+
        "&RegionText=" + currRegionText
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title:  DispensingLanguage[lan][menukey]["ZS_Id"],
      field: "id",
      width: 70,
      visible: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      width: 120,
      headerFilter:true,
      field: "RegionName",
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      headerFilter:true,
      field: "ZoneName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editZS = (data) => {
    let route = `zs/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteZS = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this ZS!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editZS(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteZS(rowData);
          }}
        />
      </>
    );
  }


  const [all, setAll] = React.useState('0');

  const handleChange = (event, index) => {
    setAll(event.target.value);
    setLoading(true);
    //console.log(e.target.value);
    setRegionIdvalue(event.target.value);
    setRegionText(index.props.children);

  };

  return (
    <>
      {/* <div className={classes.zsPageTitle}>
        <div className="d-flex justify-zs mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["ZS"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/zs/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add ZS"]}
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={600}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div> */}

<div className={classes.zsPageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["ZS"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department"]}
                  </InputLabel>
                  <Select
                    //error={errorObject.MenuKey}
                    labelId="demo-simple-select-helper-label"
                    id="MenuKey"
                    name="MenuKey"
                    value={all}
                    onChange={handleChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                    {Regionlist.map((item, index) => {
                    
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/zs/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add ZS"]}
                  </Button>

                  <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>

                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZS;

const useStyles = makeStyles({
  zsPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
