import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import ServiceAreaFormData from "./YearEntryFormData.js";


const AddService = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "year-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    YearName : "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();


  const { isLoading, mutate } = useMutation(api.saveYearEntry, {
    onSuccess: (data) => {

      if(data.status==200) {

        api.getAllDropdown("YearList").then((response) => {
          localStorage.setItem(
            "YearList",
            JSON.stringify(response.datalist.YearList)
          );
        });
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("yearentry");
      props.history.push("/year_entry");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    // console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      YearName : "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["YearName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
    }
  };

  return (
    <>
      <ServiceAreaFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddService;
