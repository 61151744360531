import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import { getDefaultMonthYear } from "../../../services/Common";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField
} from "@material-ui/core";



import Autocomplete from '@material-ui/lab/Autocomplete';
//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import swal from "sweetalert";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";





let isDirty=false;
let transactionItemID =0;
const A7Report = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "a7-report";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  const [MosTypelist, setMosTypelist] = useState([]);


  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const [currYearId, setCurrYearId] = useState(getDefaultMonthYear().defaultYear);
  const [currMonthId, setCurrMonthId] = useState(getDefaultMonthYear().defaultMonthId);

  const [currItemGroupId, setCurrItemGroupId] = useState('0');
  const [bStockUpdate, setbStockUpdate] = useState("0");

  
  // Facility Auto
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);


  const [currStoreId, setcurrStoreId] = useState(-999);
  const AStoreList = JSON.parse(localStorage.getItem("getStoreListByFacilityForA7report"));
   

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    FacilityId: currFacilityId,
    action: "getESIGLA7ReportData",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    MonthId:currMonthId,
    YearId:currYearId,
    StoreId:currStoreId
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.getESIGLA7ReportData,
    {
      onSuccess: (data) => {
        

          setbStockUpdate(data.data.bStockUpdated);
          setDataItems(data.data.datalist);
          setMosTypelist(data.data.moslegend);
          setLoading(false);
         // setFirstLoad(false);
       
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }, {
      refetchOnWindowFocus: false,
      enabled: true
  }
  ); 


  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
    // setLoading(true);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
    // setLoading(true);
  };

  const handleChangeGrp = (event) => {
    setCurrItemGroupId(event.target.value);
    // setLoading(true);
  };

  // Facility Change
const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname = '';
    if(newValue == null){
      rowId = '';
      Fname = '';
    }else{
      rowId = newValue.id;
      Fname = newValue.name;
    }
  
    if(rowId!==currFacilityId){
      setCurrFacilityId(rowId);
      
   // Store Change
   let params1={action:"gStoreListByFacility","menukey": menukey, "FacilityId":rowId,"lan":lan};
   //mutateStore(params1);
  

  
    // Receive From Facility Change
    let params3={action:"gFacilityListNotEqual99ANDSupplier","menukey": menukey, "FacilityId":rowId};
    //mutategReceiveFromFacility(params3);
  
    //setLoading(true);
  
    }
  
  };
  
  // End Facility Change


  const handleStoreChange = (event) => {

    if(currStoreId!==event.target.value){
      setcurrStoreId(event.target.value); 
      setLoading(true);
    }
  
    };


    // Store

const {  mutate:mutateStore } = useMutation(api.gStoreListByFacility, {
    onSuccess: (data) => { 
      if (data.status == 200) {  
        
        setcurrStoreId(data.data.datalist[0]['id']);
    
        localStorage.setItem(
          "getStore",
          JSON.stringify(data.data.datalist)
        );
    
    
    
      }
    }
    })


  const { isLoading3, mutate: mutateUpdate } = useMutation(api.UpdateESIGLA7ReportData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        
        setbStockUpdate(data.data.bStockUpdated);
        if(data.data.bStockUpdated==5){
          let PFData = {
            action: "PostA7Report",
            menukey: menukey,
            FacilityId: currFacilityId,
            lan: lan,
            UserName: UserName,
            YearId:currYearId,
            MonthId:currMonthId,
            UserId:UserId
          };

          mutatePost(PFData);
        
        }else{
          isDirty=false;
          onSetDisable();
          stockData();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }


       
        
      }else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  const { isLoading5, mutate:mutatePost } = useMutation(api.postESIGLA7Report, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

         setbStockUpdate(5);
      
        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         
         }else{

          isDirty=false;
          onSetDisable();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })

  const handleUpdate = async (e) => {

    let dataArray=[];
    let gridData=tblRef.table.getData();
    gridData.forEach((row,i) => { 
      if(row.aid==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    });

    let FinalData = {
      action: "UpdateA7Report",
      ManyJsonSave: dataArray,
      menukey: menukey,
      FacilityId: currFacilityId,
      lan: lan,
      UserName: UserName,
      bStockUpdated:1
    };
    mutateUpdate(FinalData);
  };



  const { isLoading4, mutate: GenerateFieldReport } = useMutation(api.GenarateESIGLA7ReportData, {
    onSuccess: (data) => {
      
      if (data.status == 200) {
       // console.log("FFFFFFFFFFFFFFFF",data);
         isDirty=false;
         onSetDisable();
         stockData();
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        //swal("Success!", "", "success");
      }else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  const handleSubmit = async (e) => {

    let dataArray=[];
    let gridData=tblRef.table.getData();
    gridData.forEach((row,i) => { 
      if(row.aid==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    });

    let FinalData = {
      action: "GenerateA7Report",
      ManyJsonSave: dataArray,
      menukey: menukey,
      FacilityId: currFacilityId,
      lan: lan,
      UserName: UserName,
      YearId:currYearId,
      MonthId:currMonthId,
      UserId:UserId
    };
    GenerateFieldReport(FinalData);
  };

  const handlePost = async (e) => {


    // Start Post Alert
      
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willAction) => {
      if (willAction) {

        let dataArray=[];
        let gridData=tblRef.table.getData();
        gridData.forEach((row,i) => { 
          if(row.aid==transactionItemID) {
            dataArray.push(row);
            transactionItemID=0;
          }
        });
        
        let FinalData = {
          action: "UpdateA7Report",
          ManyJsonSave: dataArray,
          menukey: menukey,
          FacilityId: currFacilityId,
          lan: lan,
          UserName: UserName,
          "bStockUpdated":5,
        };
        mutateUpdate(FinalData);
        
      }else{
       // setbStockUpdate(0);
        
      }
    });  
    // End Post Alert

  }


  const { isLoading6, mutate: UnPFieldDispense } = useMutation(api.UnPostESIGLA7Report, {
    onSuccess: (data) => {
      
      if (data.status == 200) {
       
        stockData();
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
       
      }else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  const handleUnpost = async (e) => {

    let FinalData = {
      action: "UnpostA7Report",
      menukey: menukey,
      FacilityId: currFacilityId,
      lan: lan,
      UserName: UserName,
      YearId:currYearId,
      MonthId:currMonthId,
      UserId:UserId
    };
    UnPFieldDispense(FinalData);

  }

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: "CFMStockStatusId",
      field: "CFMStockStatusId",
      visible: false,
    },
    {
      title: "CFMStockId",
      field: "CFMStockId",
      visible: false,
    },

    {
      title: "DispenseQty1",
      field: "DispenseQty1",
      visible: false,
    },

    {
      title: "DispenseQty2",
      field: "DispenseQty2",
      visible: false,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["SL"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "ItemCode",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "ItemName",
      headerFilter: true,
      width: 250,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Stock"],
      field: "OpStock",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
    },

    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "ReceiveQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense"],
      field: "DispenseQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "AdjustQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 105,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["Expired"],
      field: "TotalDispenseQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClStock",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Risk_of_Expry_less_than_3_Months"],
      field: "ExpiryQty",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry_Date"],
      field: "ExpiryDate",
      width: 85,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: bStockUpdate == 1 ? cellFocusEditor: '',
      cellEdited: function (cell) {

       
        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        transactionItemID=rowdata.aid;
        onSetDisable();

      },
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      width: 70,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      width: 80,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter:"html"
    },
    
  ];


  const onSetDisable = () => { 

    if(isDirty==true){  
      document.getElementById("FID_MonthList").classList.add("swDisabled");
      document.getElementById("FID_YearList").classList.add("swDisabled");
      document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("FID_FacilityList").classList.add("swDisabled"); 
      document.getElementById("FID_StoreList").classList.add("swDisabled");

      let dataArray=[];
         let gridData=tblRef.table.getData();
         gridData.forEach((row,i) => { 
          if(row.aid==transactionItemID) {
            dataArray.push(row);
            transactionItemID=0;
          }
         });
         let fDtat={...props.formData,'ManyJsonSave':dataArray,menukey: menukey,lan: lan,action: "UpdateA7Report",FacilityId: currFacilityId,UserName: UserName,bStockUpdated:1};  
         mutateCellUpdate(fDtat);

    }
    else{

     document.getElementById("FID_MonthList").classList.remove("swDisabled");
     document.getElementById("FID_YearList").classList.remove("swDisabled");
     document.getElementById("is_dirty_message").classList.add("dnone");
     document.getElementById("FID_FacilityList").classList.remove("swDisabled"); 
     document.getElementById("FID_StoreList").classList.remove("swDisabled"); 

    }

 }



 const mutateCellUpdate=(fDtat) => { 
  
  const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null; 
    const apiUrl = process.env.REACT_APP_API_URL + "source/api/ESIGLA7Report";
    let data=fDtat;
    
      fetch(apiUrl, {
        method: "POST", 
        headers: {
            
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${auth_token}`,
          "Consumer": process.env.REACT_APP_CONSUMER,
          "Secret": process.env.REACT_APP_SECRET,
        
        },
        body: JSON.stringify(data),
      })
      .then(function (res) {
        console.log(res);
       
        return res.json();
      })
      .then(function (data) {
        isDirty=false;
        onSetDisable();  
      })
      .catch(error => {
  
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
          msgtype: 0,
        });
      
       });
  
  
   }

  
 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);


  React.useEffect(() => {
   
    localStorage.setItem(
      "StoreId",
      currStoreId
    );
    
  }, [currStoreId]);



  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["A7 Report"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container className="mb-4">


                <Grid item xs={1} sm={1}>
                  <FormControl id="FID_YearList" className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Year']}
                          </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="YearList"
                      name="YearList"
                      value={currYearId}
                      onChange={handleYearChange}
                      fullWidth
                    >
                      {YearList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <FormControl id="FID_MonthList" className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Month']}
                          </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="MonthList"
                      name="MonthList"
                      value={currMonthId}
                      onChange={handleMonthChange}
                      fullWidth
                    >
                      {MonthList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl id="FID_FacilityList" className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole==1?false:true}
                      
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>


              <Grid item xs={2} sm={2}>
                <div className="float-left"> 
                <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                </div>
              </Grid> 



              <Grid item xs={2} sm={2}>
                <FormControl id="FID_StoreList" className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Store"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="AStoreId"
                    name="AStoreId"
                    value={currStoreId}
                   // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                  >
                    
                    {AStoreList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              
                           
              
              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">

                <Button
                    className="mr-2"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => handleSubmit()}
                    //hidden={dataItems.length==0?(FacilityList.length==0?true:false):true} 
                    hidden={((bStockUpdate==0)||(bStockUpdate==1))?(FacilityList.length==0?true:false):true} 
                  >
                    {DispensingLanguage[lan][menukey]["GENERATE_A7_REPORT"]}
                    {/* t('Save') */}
                  </Button>

                  {/* <Button
                    className="mr-2"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => handleUpdate()}               
                    hidden={dataItems.length==0? true:(bStockUpdate==5?true:false)}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}               
                  </Button> */}

                  <Button
                    className="mr-2"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => handlePost()}
                    //hidden={dataItems.length==0? true:(bStockUpdate==1?true:false)}
                    hidden={dataItems.length==0? true:(bStockUpdate==5?true:false)}
                   
                  >
                    {DispensingLanguage[lan][menukey]["POST"]}
                    {/* t('Save') */}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => handleUnpost()}
                    hidden={bStockUpdate==5?false:true}
                  >
                    {DispensingLanguage[lan][menukey]["UNPOST"]}
                    {/* t('Save') */}
                  </Button>

                  

                  
                </div>
              </Grid>





              








            </Grid>

              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mylegend_area">
                        {MosTypelist.map((item, index) => {
                          return (
                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: item.ColorCode }}
                              ></div>
                              <div className="mylegend_label">{item.name}</div>
                              <div className="mylegend_val">
                                {item.MosLabel}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>









                  {/* <Grid container spacing={3}>                  
                    <Grid item xs={12} sm={12}>                     
                     <Grid container className="mb-2">                                              
                      <Grid item xs={2} sm={2}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                            
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={handleChangeGrp}                           
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All_Product_Group"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>                      
                        </FormControl>           
                      </Grid>                         
                     </Grid>                                         
                    </Grid>
                  </Grid> */}

           
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default A7Report;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
