import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
import * as ScanBarcode from '../../../services/ScanBarcode';
// material components
import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Notification from "../../../services/Notification";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
const StockStatusReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListForReport"));

  // let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const FacilityList = [All_Facility_label].concat(Facility_List);

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [tmpProductlist, setProductlist] = useState([]);
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Productlist = [All_Product].concat(tmpProductlist);

  const [tmpProductGTINlist, setProductGTINlist] = useState([]);
  let All_Product_GTIN = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGTINlist = [All_Product_GTIN].concat(tmpProductGTINlist);

  const [tmpCartonGTINlist, setCartonGTINlist] = useState([]);
  let All_Carton_GTIN = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const CartonGTINlist = [All_Carton_GTIN].concat(tmpCartonGTINlist);

 
  const tmpStoreList = JSON.parse(localStorage.getItem("gStoreList"));
  const [AStoreList, setStorelist] = useState(tmpStoreList);
  // let All_StoreList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const AStoreList = [All_StoreList].concat(StoreList);

  const [currFacilityId, setCurrFacilityId] = useState(FacilityList.length>0? FacilityList[0].id:"");
  const [currFacilityName, setCurrFacilityName] = useState(FacilityList.length>0? FacilityList[0].name:"");

  const [currProductGroupId, setProductGroupId] = useState("0");
  const [currProductId, setProductId] = useState();
  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  const [currStoreId, setStoreId] = useState();
  const [currStoreName, setStoreName] = useState();
 

  const [acValue, setACValue] = useState({ id: Productlist[0].id, name: Productlist[0].name});
  const [scanObj, setscanObj] = useState({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);
  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    resetscan();
    let rowId = '';
    let Fname = '';
    if (newValue == null) {
      rowId = '';
      Fname = '';
    } else {
      rowId = newValue.id;
      Fname = newValue.name;
    }


    if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);
    }
    let StoreListParam = { action: "getStoreForReport", FacilityId: newValue.id,lan:lan, menukey: "" }
    cStoreList(StoreListParam);

  };


//store load by facility
const { mutate: cStoreList } = useMutation(
  api.StoreListForReport,
  {
    onSuccess: (data) => {
      if (data.status == 200) {

        // console.log("Dta list", data.data.datalist);
       
        setStorelist(data.data.datalist);
        setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});
        setStoreId(AStoreList[0].id);
        setStoreName(AStoreList[0].name);

      }

    },
  }
);



  React.useEffect(() => {

    if (currFacilityId == (FacilityList.length>0? FacilityList[0].id:"")) {
     
      let StoreListParam = { action: "getStoreForReport", FacilityId: currFacilityId,lan:lan, menukey: "" }
      cStoreList(StoreListParam);
    }
    if (currProductGroupId == 0) {
     
      let ProductlistParam1 = { action: "gProductList", ItemGroupId: "0", menukey: "" }
      cProductList(ProductlistParam1);
    }
    

  }, [currFacilityId,currProductGroupId]);


  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    resetscan();
    
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;

    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
     
    }
   
    let ProductlistParam = { action: "gProductList", ItemGroupId: newValue.id, menukey: "" }
    cProductList(ProductlistParam);
   

  };


  const { mutate: cProductList } = useMutation(
    api.aProductList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          // console.log("Dta list", data.data.datalist);
         
          setProductlist(data.data.datalist);
          setACValue({ id: Productlist[0].id, name: Productlist[0].name});
          setProductId(Productlist[0].id);

        }

      },
    }
  );

  // isstck Change
  const handleProductChange = (event, newValue) => {
    resetscan();
    setACValue(newValue);
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;


    }
    if (rowId !== currProductId) {
      setProductId(rowId);

    }
  };
  // Store Change
  const handleStoreChange = (event, newValue) => {
    resetscan();
    setACstoreValue(newValue);
    let rowId = '';
    let Sname = '';
    if (newValue == null) {
      rowId = '';
      Sname = '';
    } else {
      rowId = newValue.id;
      Sname = newValue.name;
    }
    if (rowId !== currStoreId) {
      setStoreId(rowId);
      setStoreName(Sname);


    }
  };

 


  const { mutate: cproductgtinlist } = useMutation(
    api.getProductGTIN,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setProductGTINlist(data.data.datalist);

        }

      },
    }
  );

  const { mutate: cCartongtinlist } = useMutation(
    api.getCartonGTIN,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCartonGTINlist(data.data.datalist);

        }

      },
    }
  );
  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "ShowProductLotStockReport",
    lan: lan,
    FacilityId: currFacilityId,
    ProductGroupId: currProductGroupId,
    ItemNo: currProductId,
    StoreId: currStoreId,
    StoreName: currStoreName
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getProductLotStockReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      headerSort: false,
       width: 130,
       headerFilter: true,
    },


    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 70,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["BrandCode"],
      field: "BrandCode",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       headerFilter: true,
       width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["CommercialName"],
      field: "CommercialName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       headerFilter: true,
       width: 150,
       formatter: "textarea"
       
    },
    {
      title: DispensingLanguage[lan][menukey]["EAGTIN"],
      field: "EAGTIN",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       headerFilter: true,
      // width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["PKGTIN"],
      field: "PKGTIN",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["CAGTIN"],
      field: "CAGTIN",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["PLGTIN"],
      field: "PLGTIN",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["SSCCCarton"],
      field: "SSCCCarton",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["SSCCPallet"],
      field: "SSCCPallet",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "LotQty",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Store"],
    //   field: "StoreName",
    //   headerSort: false,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   width: 120,
    // },
  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    if( (scanObj.GTIN == undefined) || ((scanObj.GTIN == '' ) && (scanObj.ExpiryDate == '') && (scanObj.SerialNo =='')
    && (scanObj.BatchNo == '') && (scanObj.Brandcode =='') && (scanObj.SSCC ==''))){
      
    window.open(
      finalUrl +
      "?action=ProductLotStockReport" +
      "&reportType=" +
      reportType +
      "&FacilityId=" +
      currFacilityId +
      "&ProductGroupId=" +
      currProductGroupId +
      "&ItemNo=" +
      currProductId +
      "&StoreId=" +
      currStoreId +
      "&StoreName=" +
      currStoreName +
      "&GTIN=" +
      scanObj.GTIN +
      "&ExpiryDate=" +
      scanObj.ExpiryDate +
      "&SerialNo=" +
      scanObj.SerialNo +
      "&BatchNo=" +
      scanObj.BatchNo +
      "&Brandcode=" +
      scanObj.Brandcode +
      "&SSCC=" +
      scanObj.SSCC +
      "&menukey=" +
      menukey +
      "&lan=" +
      
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    )
     }else{
      window.open(
        finalUrl +
        "?action=ProductLotStockReport" +
        "&reportType=" +
        reportType +
        "&FacilityId=" +
        currFacilityId +
        "&ProductGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        currProductId +
        "&StoreId=" +
        currStoreId +
        "&StoreName=" +
        currStoreName +
        "&GTIN=" +
        scanObj.GTIN +
        "&ExpiryDate=" +
        scanObj.ExpiryDate +
        "&SerialNo=" +
        scanObj.SerialNo +
        "&BatchNo=" +
        scanObj.BatchNo +
        "&Brandcode=" +
        scanObj.Brandcode +
        "&SSCC=" +
        scanObj.SSCC +
        "&ScanFor=Reports"+
        "&menukey=" +
        menukey +
        "&lan=" +
        
        localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
      )
     }
  };

  /* =====End of Excel Export Code==== */





  //-----------Scan Start--------------//

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1=obj;
    if(obj.duration==undefined)
    obj1={...obj,duration:900}
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  const handleRefetchCall = (data) => {
        console.log("Refetch Val",data.ScanObj);
         setDataItems(data.Data1);
         setscanObj(data.ScanObj);
  }

  const scan_gtin_or_brandcode = useRef(null);

  const doActionScanBarcode = () => {
 
    
    const ScanBarcodeParams = {
        ...params,
        TransactionTypeId: 1,         
        FacilityLevelId: 1,         
        AdjTypeId: 0, 
        handleRefetchCall: handleRefetchCall,
        UserId:UserId,
        UserName:UserName,
        UserRole:UserRole,
        ScanFor:"Reports",
        ErrorModal:openNoticeModal
      }
      
   ScanBarcode.ScanBarcodeAction(ScanBarcodeParams,  {...props}); 
  };
  
  
  const onClickScanBarcode = () => {  
    doActionScanBarcode();
  };
  
  const handleKeyPress = (event) => { 
    if(event.key === 'Enter'){    
      doActionScanBarcode();
    }
  }
  
  
  let altValue = "";
  const handleKeyDown = (e) => { 
    if (e.altKey) {    
      if (e.which !== 18) {
        altValue += e.which;
      }
      if (altValue === "969698105") {      
        document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
      }
    }
  };
  
  const handleKeyUp = (e) => {
    if (e.altKey === false) {
      altValue = "";
    }
  };


  function resetscan(){

    setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
  
  }
  //-----------Scan End---------------//



  return (
    <>
      <AfterLoginNavbar {...props} />
      <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Product_Lot_Stock_Report"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                 
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) => handleProductChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                     // defaultValue={Productlist[Productlist.findIndex(Productlist => Productlist.id == currProductId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                        
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={3} sm={3}>
                  <div className="float-right sw_btn_control">

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>

                  </div>
                </Grid>

              </Grid>
              <Grid container>
               
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreList"
                      disableClearable
                      options={AStoreList}
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                     // defaultValue={AStoreList[AStoreList.findIndex(AStoreList => AStoreList.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Store"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>



              <Grid item xs={3} sm={3} id="FID_scan_gtin_or_brandcode">
                    <FormControl className={classes.fullWidth}>
                    <TextField
                        //disabled={props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                        
                        id="scan_gtin_or_brandcode"
                        name="scan_gtin_or_brandcode"
                        label={DispensingLanguage[lan][menukey]["SCAN GTIN"]} 
                        
                        autoComplete="family-name"
                        ref={scan_gtin_or_brandcode}
                        onKeyPress={handleKeyPress} 
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp}
                        
                        
                    />
                    </FormControl>
                            
                </Grid> 

                <Grid item xs={1} sm={1} id="FID_scanButton">
                    <Button

                   // disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                    id="scanButton"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickScanBarcode()} 
                    >
                    {DispensingLanguage[lan][menukey]["Scan"]}
                    </Button> 
                </Grid>
                    


                <div className="search_control_group dnone" id="scan_sscc_hide_show">
                    <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                    <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                </div>








              </Grid>
            </CardContent>
          </Card>
          <div>
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={400}
                options={{
                  groupBy: ["FacilityName", "GroupName", "ItemName"],

                  columnCalcs: "both",
                }}
              />
            </div>
            {/* <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockStatusReport;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
