import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

const RoleFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "role-entry";
const MenuUrl = JSON.parse(localStorage.getItem("MenuUrl"));
const { t, i18n } = useTranslation();

  const [RedirectValue, setRedirectValue] = useState({ url: '', name:"" });

  useEffect(() => {
    setRedirectValue(MenuUrl[MenuUrl.findIndex(dire_List => dire_List.url == formData.defaultredirect)]);

  }, [formData.defaultredirect]);

  const changeRedirect = (val) => {
    setRedirectValue(val);
    if (val != null) {
      formData.defaultredirect = val.url;
    } else {
      formData.defaultredirect = "";
    }
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner"> {DispensingLanguage[lan][menukey]['Role Entry Form - Add/Edit']} </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title= {DispensingLanguage[lan][menukey]['Role Entry from']}/>
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.role}
                          helperText={errorObject.role}
                          required
                          id="role"
                          name="role"
                          label= {DispensingLanguage[lan][menukey]['Role Name'] }
                          value={formData.role}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="defaultredirect"
                        options={MenuUrl}
                        onChange={(event, value) => changeRedirect(value)}
                        getOptionLabel={(option) => option.name || ''}
                        value={RedirectValue || ''}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.defaultredirect}
                            label={DispensingLanguage[lan][menukey]["Default Redirect"]}
                            variant="standard"
                            error={errorObject.defaultredirect}
                            helperText={errorObject.defaultredirect}  
                            id="defaultredirect"
                            name="defaultredirect"
                            fullWidth
                          />
                        )}
                      />
                        </FormControl>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]['Reset'])}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t( DispensingLanguage[lan][menukey]['Save'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/role-entry")}
              >
                {t( DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]['update'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/role-entry")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default RoleFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
