import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

const ProductViewFormData = ({
  formData,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "generics-list";

  const classes = useStyles();
  
  return (

    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">{ DispensingLanguage[lan][menukey]['Generic View'] }</div>
          <div className="float-right sw_btn_control">
            {/* Action buttons */}
              <Grid item xs={12} className="mt-2 text-center">
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/generics-list")}
                >
                  { DispensingLanguage[lan][menukey]['Back to List'] } 
                </Button>
              </Grid>
           {/* End Action buttons */}
          </div>
          </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>

        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardHeader title={ DispensingLanguage[lan][menukey]["Generic Details"] } />
            <CardContent>
              <Grid container spacing={3}>
                
                <Grid item xs={6} sm={6}>
                  <ul className="item_details_list">
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Generic Code"]}</div>
                      <div className="desc">{ formData.ItemCode ? (formData.ItemCode) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Strength"]}</div>
                      <div className="desc">{ formData.StrengthName ? (formData.StrengthName) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Form"]}</div>
                      <div className="desc">{ formData.DosageFormName ? (formData.DosageFormName) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Unit"]}</div>
                      <div className="desc">{ formData.UnitName ? (formData.UnitName) : ('---') }</div>
                    </li>
                  </ul>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <ul className="item_details_list">
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Generic"]}</div>
                      <div className="desc">{ formData.ItemName ? (formData.ItemName) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Pack Size"]}</div>
                      <div className="desc">{ formData.PacksizeName ? (formData.PacksizeName) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["DCI"]}</div>
                      <div className="desc">{ formData.DciName ? (formData.DciName) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["SOBAPS Code"]}</div>
                      <div className="desc">{ formData.SOBAPSCode ? (formData.SOBAPSCode) : ('---') }</div>
                    </li>
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["eSIGLProductId"]}</div>
                      <div className="desc">{ formData.eSIGLItemNo ? (formData.eSIGLItemNo) : ('---') }</div>
                    </li>
                  </ul>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
    </div>
  </div>
  );
};

export default ProductViewFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
