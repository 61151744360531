import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";

import ProductViewFormData from "./ProductViewFormData.js";
import * as Service from "../../../services/Service.js";

const ViewProduct = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const menukey = "generics-list";

  const [ProductTableData, setProductTableData] = React.useState(true);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    ItemNo: "",
    ItemCode: "",
    ItemName: "",
    ShortName: "",
    ItemGroupId: "",
    GroupName: "",
    ProductSubGroupId: "",
    ProductSubGroupName: "",
    StrengthId: "",
    StrengthName: "",
    PacksizeId: "",
    PacksizeName: "",
    DosageFormId: "",
    DosageFormName: "",
    DciId: "",
    DciName: "",
    UnitId: "",
    UnitName: "",
    bKeyItem: "",
    Price: "",
    SOBAPSCode: "",
    SortOrder: "",
    eSIGLItemNo: ""
  });

  let params = {
    menukey: menukey,
    action: "getProductDetails",
    ItemNo: id
  };


  
  const { error, isError, data, refetch:getProductsData } = useQuery(
    [params],
    api.getGenericlistDetail,
    {
      onSuccess: (data) => {
        setFormData(data.datalist[0]);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


//   const getProductsData = () => {
//     Service.default
//         .postApi("source/api/api_pages/productList.php", params)
//         .then((res) => {
//           setFormData(res.datalist[0]);
//         })
//         .catch((err) => {});
// }

if(ProductTableData){
  setProductTableData(false);
  getProductsData();
}

  return (
    <>
      <ProductViewFormData
        formData={formData}
        {...props}
      />
    </>
  );
};

export default ViewProduct;
