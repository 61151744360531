import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import AdjustmentFormData from './AdjustmentFormData';
import Constants from "../../../services/Constants";
import { useTranslation } from "react-i18next";


const EditAdjustment = (props) => {
   
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-invoice";
  const FacilityId=localStorage.getItem('FacilityId');
  const TransactionTypeId = 3;
  const StoreId = localStorage.getItem("StoreId");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    TransactionDate: "",
    AdjTypeId: "", 
    TransactionNo: "", 
    "FacilityId":localStorage.getItem('FacilityId'),
    TransactionId:"", 
    IssueToFacility:"",
    PreparedBy:"",
    ApprovedBy:"",
    SupplierInvDate:"",
    StoreId:"",
    Remarks:"",
    ItemGroupId:"0",
    bfacility:0,
    adjIssueToFacility:""
    });
    const [selectedDate, setSelectedDate] = useState(
      new Date()
    );
    const [selectedEndDate, setselectedEndDate] = useState(new Date());
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [pickListMethod, setPickListMethod] = useState();
  const [renderAfterPost, setRenderAfterPost] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [SerialDetails, setSerialDetails] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  const [bFirst, setBFirst] = useState(true); 
  const [ItemGroupId, setItemGroupId] = useState("0"); 
   
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


const handleADateChange = (date, field) => {
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};



  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['adjustment-invoice', id], () =>
  api.getReceiveInv({TransactionId:id,ItemGroupId:ItemGroupId, TransactionTypeId:TransactionTypeId, SerialDetails:SerialDetails}).then((res) => {
      setDirty(true);
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo); 
      let IsPositive=res.data.IsPositive=="-1"?true:false; 
      setIsPositive(IsPositive);
      setPickListMethod(res.data.PickListMethod); 
      setBFirst(true);
      setDirty(false);
      return res.data
    }), {
      refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })

  /*
  const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

          if(data.bStockUpdated==1){
            mutatePost({ 
              menukey: menukey, 
              TransactionId: TransactionId,
              TransactionTypeId: 3,
              });
          
          }else{
            
            refetch(); 
            setDeletedDataSet([]);

            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    } 
  })
 
  
  const { isLoading1, mutate:mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => { 

      if (data.status == 200) {

        setbStockUpdate(data.bStockUpdated);
        setDeletedDataSet([]);

       if(data.success==0){

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
       }else{

          refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
       } 
      }
    }
  })

  */

  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);

};
  const handleChange = (e) => {

    if(e=='IssuedToFacility'){
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else{

    const { name, value } = e.target;
    // if (name == "price" || name == "sellingPrice" || name == "markUp") {
    //   checkNumberValidation(name, value);
    // } else {
      let data = { ...formData };
      data[name] = value;            
      // let productData = generateProductCodeAndName(data);      
      // if (productData.productCode) {
      //   data["productCode"] = productData.productCode
      // }
      // if (productData.productName) {
      //   data["productName"] = productData.productName
      // }
      setFormData(data);
    // }
    setErrorObject({ ...errorObject, [name]: null });
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "", 
      "FacilityId":FacilityId,
      TransactionId:"",
      PreparedBy:"",
      ApprovedBy:"",
      bfacility:0
  
    });
  };

  const filterHandleChange = (e) => {
    setItemGroupId(e) ;
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo","adjIssueToFacility","PreparedBy","PreparedBy","SupplierInvDate","StoreId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv=(data)=>{ 
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }

  /*
  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
    let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet,"menukey":menukey,"lan":lan,"UserName":UserName}; 
    mutateUpdate(fDtat); 
    }
  };
  */

  /*
  const handlePost = async (rows) => {  

    
    if (validateForm(formData)) {
      
      // Start Post Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do_you_really_want_to_post_the_stock"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
         
         let FinalData={...formData,'ManyJsonSave':rows.data,"bStockUpdated":1,'deletedDataSet':rows.deletedDataSet,"menukey":menukey,"lan":lan,"UserName":UserName}; 
         mutateUpdate(FinalData); 
        
          
        }else{ 
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
     
      
    }
  };

  */

/*
  const setUpdatedDetailsGridDataHandler = (rows) => { 
    
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
     
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "adjustment-invoice",
          "lan":lan,
          'deletedDataSet':rows.deletedDataSet,
          "UserName":UserName
        };
        mutateUpdate(fDtat); 
      } 
     
    }
  
  
  };

  */

  const updateIspositive = (isPosVal) => { 
    setIsPositive(isPosVal);

   };
  useEffect(() => { 
     
    
  }, [isPositive,pickListMethod,deletedDataSet]); 


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function successSave(bStockUpdated){
    if(bStockUpdated !==1 ){
      refetch();
    }
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

  function setFormDataForAdjust(val){
    setFormData(val);
  };
 


  
  useEffect(() => {
    refetch();
   
  }, [ItemGroupId]);
   
  
  return (
    <>
      <AdjustmentFormData 
        
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        //handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        handleADateChange={handleADateChange}
        selectedDate={selectedDate} 
        selectedEndDate={selectedEndDate}
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        //handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        DisableAddBtn={false}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        pickListMethod={pickListMethod}
        bFirst={bFirst}
        filterHandleChange={filterHandleChange} 
        refetch={refetch}
        dirty={dirty}

        checkValidateForm={checkValidateForm}
        successSave={successSave}
        stockPosted={stockPosted}
        setFormDataForAdjust={setFormDataForAdjust}
        {...props} />
    </>
  );
};

export default EditAdjustment;