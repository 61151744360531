import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Select,
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  useEventCallback,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  Link,
} from "@material-ui/core";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const SVDLFeedback = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);

  let TempZoneList = useState([]);

  const [acValue, setACValue] = useState("");
  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const [activedep, setDepartment] = useState({ id: '0', name: "All" });
  const [activecommun, setCommune] = useState({ id: '0', name: "All" });
  //  console.log('active valuie',activedep);
  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [DepartmentName, setDepartmentName] = useState([]);

  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);
  const [currYearId, setYearId] = useState(currentDate.getFullYear());


  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currDepartmentId) {
      setDepartmentName(rowName);
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };
  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setDepartment({ id: '0', name: "All" })
        }

      },
    }
  );
  // useEffect(()=>{
  //   setDepartment({ id: '0', name: "All"});

  // },[])

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);
  // console.log(Zonelist);
  const [currZoneIdId, setZoneId] = useState("0");

  const [ZoneName, setZoneName] = useState([]);

  const handleZoneChange = (event, newValue) => {
    setDepartment(newValue)
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);
      setZoneName(rowName);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };


  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setCommune({ id: '0', name: "All" });
        }

      },
    }
  );

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);
  const [currCommuneId, setcurrCommuneId] = useState("0");
  const [DistrictName, setDistrictName] = useState([]);
  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setCommune(newValue)
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setDistrictName(rowName)

    }
  };


  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);


    }
  };


  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);



    }
  };



  //Flevel filter section
  const FacilityLists = JSON.parse(localStorage.getItem("getFacilityListForReport"));
  let All_Facility = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility].concat(FacilityLists);

  //console.log(FacilityList);
  const [currFacilityId, setCurrFacilitylist] = useState(0);
  const [FacilityName, setFacilityName] = useState([]);

  // Facility Change
  const handleReginalListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currFacilityId) {
      setCurrFacilitylist(rowId);
      setFacilityName(rowName);
    }

  };



  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: '',
    MonthId: currmonthId,
    Year: currYearId,
    ZoneName: ZoneName,
    BCZName: DistrictName,
    ProvinceName: DepartmentName

  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [RemarksChainData, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getSVDLFeedBackReport",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    FacilityId: currFacilityId,
    MonthId: currmonthId,
    ProvinceName: DepartmentName,
    ZoneName: ZoneName,
    BCZName: DistrictName,
    FacilityName: FacilityName,
    MonthName: currMonthName,
    Year: currYearId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    // mode: bEsigl

  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("yyyyyy",data.data.datalist);
        setDataItems(data.data.datalist);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

let tempFacility ="";

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title1">
                  {DispensingLanguage[lan][menukey]["SVDL_Feedback"]}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>



          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="DepartMentName"
                          disableClearable
                          options={DepartMentName}
                          //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                          //disabled={UserRole == 1 ? false : true}
                          onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Department Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Zonelist"
                          disableClearable
                          options={Zonelist}
                          value={activedep}
                          onChange={(event, newValue) => handleZoneChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["ZS Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activecommun}
                          onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Commune Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FacilityList"
                          disableClearable
                          options={FacilityList}
                          onChange={(event, newValue) => handleReginalListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FacilityList[FacilityList.findIndex(FacilityList => FacilityList.id == currFacilityId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>


                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="MonthList"
                          disableClearable
                          options={MonthList}
                          onChange={(event, newValue) => handleMonthChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Month"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="YearList"
                          disableClearable
                          options={YearList}
                          onChange={(event, newValue) => handleYearChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Year"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>



                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent" style={{ backgroundColor: "#2ca8ff" }}>
              <Grid item xs={12} sm={12}>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>

                        <TableCell className="legentId" align="center" style={{ borderBottom: "none", color: "white" }} >

                          <Link>{DispensingLanguage[lan][menukey]["Reporting Rate"]} </Link>
                          <Link> {DispensingLanguage[lan][menukey]["Total"]} : {getReportingRate['Total']}%</Link>
                          <Link> {getFLvelelList[99]}:{getReportingRate['HealthFaclilities']}% </Link>
                          <Link> {getFLvelelList[3]}:{getReportingRate['DistrictWarehouse']}%</Link>
                          <Link> {getFLvelelList[2]}:{getReportingRate['RegionalWarhouse']}% </Link>
                          <Link> {getFLvelelList[1]}:{getReportingRate['CentralWarehouse']}% </Link>

                        </TableCell>



                      </TableRow>
                    </TableHead>

                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>

          </Card>

          <Grid container spacing={1} className="mt-2">
            {/* new row */}
            <Grid item xs={12} sm={12}>
              <Card className="sw_card">
                <CardContent>


                  <div className="sw_page_heading">


                    <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['SVDL Report Feedback']}
                    </div>
                    <br></br>
                    <br></br>

                    <Grid item xs={12} sm={12}>

                      <div class="qtitle-ctm">{DispensingLanguage[lan][menukey]['Previous Feedback']}</div>

                      <aside class="widget widget_mfn_recent_comments" id="widget_mfn_recent_comments-2">
                        <div className="Recent_comments activity_area">
                          <ul id="ChainDataId">

                           
                             {RemarksChainData.map((item, index) => {
                              if(tempFacility != item.FacilityName){
                                tempFacility =item.FacilityName;
                                return (
                                  <li>
                                    <div className="ActivityList">
                                      <div > {item.FacilityName}</div>
                                    </div>
                                  </li>
  
                                );
                              }else{
                                return (
                                  <li>
                                    <div className="ActivityList">
  
                                      <div className="dateClass"><i className="fa fa-clock"></i> {item.EntryTime}</div>
                                      <div className="userClass"><i className="fa fa-user"></i> {item.display_name}</div>
                                      <br></br>
                                      <p>{item.Comments}</p>
                                    </div>
                                  </li>
  
                                );
                              }
                             

                            })}

                          </ul></div>
                      </aside>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>



        </div>
      </div>
    </>
  );
};



export default SVDLFeedback;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  button_group: {
    backgroundColor: "green"
  }
});