
import React, { useState, useEffect } from "react";

// reactstrap components
import { container, Row, Col, Button } from "reactstrap";

// core components
function AboutPageContent() {

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "about";

  return (
    <>
      {/* Selection 2 */}
      <div className="inner-section" style={{backgroundColor: "#f7f7f7"}}>
       <div className="container-fluid">
         <div className="row">
            {/* <div className="col-lg-6 mb-30">
                <div className="about-img">
                  <img src={require("assets/img/about-2.jpg")} alt=""></img>
                </div>
            </div> */}
            <div className="col-lg-12">
            <div className="about-text">
                  {/* <h1>{ DispensingLanguage[lan][menukey]["How ePharmacie works"] }</h1> */}
                  <h3 className="title">{ DispensingLanguage[lan][menukey]["ePharmacie Logistics Flow"] }</h3>
                  <p>
                    <ul>
                      <li>{ DispensingLanguage[lan][menukey]["The logistics management of health commodities.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["The health commodities arriving in..."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["For certain commodities, DRZS submit...."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["At the final stage, FoSa typically travel.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["From FS dispenses directly to.."] }</li>
                    </ul>
                  </p>
                  
                </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 2/ */}


      {/* Selection 1.3 */}
      <div className="inner-section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 mb-30">
                <div className="about-text">
                  <h3 className="title">{ DispensingLanguage[lan][menukey]["ePharmacie, eSIGL and GS1 data flows"] }</h3>
                  <p>
                    <ul>
                      <li>{ DispensingLanguage[lan][menukey]["Management of all transactions orders.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["In local mode from the database installed in each site.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["Online via the SVDL platform"] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["Synchronization with the local.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["Management of transactions.."] }</li>
                      <li>{ DispensingLanguage[lan][menukey]["Stock management of community.."] }</li>
                    </ul>
                    </p>
                  
                </div>
            </div>
            <div className="col-lg-6 mb-30">
                <div className="about-img">
                  {/* <img src={require("assets/img/Slide6_English.jpg")} alt=""></img> */}
                  <img src={require("assets/img/"+ DispensingLanguage[lan][menukey]["ePharmacie_eSIGL_and_GS1_data_flows"] )} alt=""></img>
                </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 1/ */}

      {/* Selection 2 */}
      <div className="sw_section" style={{backgroundColor: "#f7f7f7"}}>
       <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 mb-30">
                <div className="about-img">
                  {/* <img src={require("assets/img/Slide2_English.png")} alt=""></img> */}
                  <img src={require("assets/img/"+ DispensingLanguage[lan][menukey]["ePharmacie_process_flow"] )} alt=""></img>
                </div>
            </div>
            <div className="col-lg-6 mb-30">
                <div className="about-text">
                  <h3 className="title">{ DispensingLanguage[lan][menukey]["ePharmacie process flow"] }</h3>
                  <p>{ DispensingLanguage[lan][menukey]["The following diagram shows the.."] }</p>
                  <h4>{ DispensingLanguage[lan][menukey]["The supplier downloads the purchase.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["After the issuance of the purchase.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["The supplier downloads the purchase order.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["The supplier uploads the serial.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["ABRP uploads shipment details.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["The Beninese Pharmaceutical Regulatory.."] }</p>              
                 
                  <h4>{ DispensingLanguage[lan][menukey]["ABRP examines the uploaded data and confirms.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["ABRP confirms or rejects shipping information.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["SoBAPS Centrale receives and downloads.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["When a delivery arrives in the Central.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["SoBAPS Centrale downloads the serial.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Each time SoBAPS Centrale sends goods.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["SoBAPS regional downloads the serial number of.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Once the Regional SoBAPS receives the.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["SoBAPS regional downloads issued product serial number.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Each time the Regional SoBAPS.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["DRZS downloads the series of products received.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Once DRZS receives.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["DRZS downloads the serial.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Each time the DRZS distributes.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["FS/HZ downloads the series.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Once FoSa receives the DRZS invoice.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["FZ/HZ downloads the series of.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["Whenever FoSa dispenses products to.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["RC downloads the series.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["RC uses an application on phone.."] }</p>
                 
                  <h4>{ DispensingLanguage[lan][menukey]["Anyone can scan a series of products.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["A mobile phone application will.."] }</p>
                 
                </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 2/ */}
 {/* Selection 1.3 */}
 <div className="inner-section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 mb-30">
                <div className="about-text">
                  <h3 className="title">{ DispensingLanguage[lan][menukey]["Interoperability of ePharmacie.."] }</h3>

                  <h4>{ DispensingLanguage[lan][menukey]["The supplier downloads.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["It is intended that the.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["ePharmacie downloads the product metadata.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["The ePharmacie downloads the.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["ABRP confirms the product registration.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["As the ABRP acts as the regulatory.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["Order details shared with.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["The controlling entity.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["Supplier sends shipment.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["When shipping takes place.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["SoBAPS SAGE downloads the shipment details.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["After receipt of any shipment.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["Product serial publications.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["When the products are distributed.."] }</p>

                  <h4>{ DispensingLanguage[lan][menukey]["Serial numbers marked as.."] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["When a FoSa or RC delivers.."] }</p>

                </div>
            </div>
            <div className="col-lg-6 mb-30">
                <div className="about-img">
                  {/* <img src={require("assets/img/Slide5_English.png")} alt=""></img> */}
                  <img src={require("assets/img/"+ DispensingLanguage[lan][menukey]["Interoperability_of_ePharmacie"] )} alt=""></img>
                </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 1/ */}
    </>
  );
}

export default AboutPageContent;
