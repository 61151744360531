import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "sobaps-receipts";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const ASNModal =({ handleClose, openOrder, addOrder, OrderingFrom, TransactionId, gStoreId, ...props }) => {
  
  
let tblOrderRef = null;
  
  const [dataItems, setDataItems] = useState([]);
  // const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");

  const FacilityId = JSON.parse(localStorage.getItem("getCentralWarehouseId"));
  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [TransactionNo, setTransactionNo] = useState();

  let params1={"menukey": menukey, "FacilityId":FacilityId, "TransactionTypeId":1, TransactionDate: ''};
  const {  data:invoiceNo } = useQuery([params1], api.getInvoiceNo, 
    {onSuccess: (data) => {
      setTransactionNo(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  }
  );

  let params = {
    menukey: menukey,
    action: "getASNInvoice",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan:lan
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getASNInvoice,{
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  
  const ASNModalColumns = [
    { title: "id", field: "id", visible: false },
    { title: "ASNMasterId", field: "ASNMasterId", visible: false },  
    { title: "SupplierId", field: "SupplierId", visible: false },
    { 
      title: DispensingLanguage[lan][menukey]['SL'], 
      formatter: "rownum",
       width:80, 
       hozAlign: "center",
      headerHozAlign:"center", 
      headerSort:false 
  },
  { 
    title: DispensingLanguage[lan][menukey]['Supplier'],
    field: "SupplierName"
  },{ 
    title: DispensingLanguage[lan][menukey]['Warehouse_Invoice_No'],
     field: "ASNInvoice"  
  },{ 
    title: DispensingLanguage[lan][menukey]['Warehouse_Invoice_Date'],
    field: "ASNEntryDate", 
    width:210,
    formatter: function (cell, formatterParams) {
      let value = cell.getValue();
      return (value==null?'':moment(value).format("DD/MM/YYYY"));
    }
  },
  ]; 

  const {mutate} = useMutation(api.insertUpdateOrderStockItemsASN, {
    onSuccess: (data)=>{
      addOrder(data);
    }
  })
  const SaveSelectedOrder = () => { 
    let PreparedBy = '';
    if(UsersListByFacility.length>0){
      PreparedBy = UsersListByFacility[0].id;
    }

    if(tblOrderRef.table.getSelectedData().length>0)
    mutate({
      ASNMasterId: tblOrderRef.table.getSelectedData()[0].ASNMasterId, 
      SupplierInvNo: tblOrderRef.table.getSelectedData()[0].ASNInvoice, 
      SupplierId: tblOrderRef.table.getSelectedData()[0].SupplierId, 
      SupplierInvDate: tblOrderRef.table.getSelectedData()[0].ASNEntryDate, 
      TransactionNo: TransactionNo,
      PreparedBy: PreparedBy,
      FacilityId: FacilityId,
      TransactionTypeId: 1,
      StoreId: gStoreId, 
      lan:lan
    }); 
   
  };

  
useEffect(() => { 
    if (tblOrderRef == null) return;
 

}, []); 

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('ASNModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ASNModal')}
        >
      
        {DispensingLanguage[lan][menukey]['Add_ASN']} 

        </BootstrapDialogTitle>
        <DialogContent dividers>  
          <ReactTabulator ref={(r) => (tblOrderRef = r)} 
           selectable={1} 
           columns={ASNModalColumns}
           data={dataItems}
           height="300px" 
          />
        </DialogContent>
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={SaveSelectedOrder}>
          {DispensingLanguage[lan][menukey]['Add']}  
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('ASNModal')}>           
            {DispensingLanguage[lan][menukey]['Cancel']}  
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ASNModal;
