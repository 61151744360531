import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api"; 
import ShipmentStatusFormData from "./RCProductEntryFormData"




const EditShipmentStatus = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "shipment-status-entry";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;


  const [formData, setFormData] = useState({
    ShipmentStatusDesc: ""
  });
  
  
  
  
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

   const { data } = useQuery(
    ["shipmentstatus", id],
    () =>
      api.getShipmentStatus(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  ); 

  const { mutate } = useMutation(api.updateShipmentStatus, {
    onSuccess: (data) => {
      if (data.status == 200) {

        // api.getAllDropdown('Unitofmeasurelist').then((response) => {
        //   localStorage.setItem(
        //     "Unitofmeasurelist",
        //     JSON.stringify(response.datalist.Unitofmeasurelist)
        //   );
        // }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        //swal(data.data.message, "", "success");
        queryClient.getQueriesData("shipmentstatus");//api page name
        props.history.push("/shipment_status");//
  
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ShipmentStatusDesc: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ShipmentStatusDesc"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =  DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
       
    }
  };

  return (
    <>
      <ShipmentStatusFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default EditShipmentStatus;
