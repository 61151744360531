import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone } from "@material-ui/icons";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import Autocomplete from '@material-ui/lab/Autocomplete';
//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "ui-language";
const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

let isDirty = false;
let transactionItemID = 0;
const UiLanguage = (props) => {
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const Menu_List = JSON.parse(localStorage.getItem("MenuList"));
  let All_Menu_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const MenuList = [All_Menu_List].concat(Menu_List);

  const queryClient = useQueryClient();

  const [MenuKeyValue, setMenuKeyValue] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    action: "getDataList",
    MenuKeyValue: MenuKeyValue,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getUiLanguages,
    {
      onSuccess: (data) => {
         setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  // const { mutate: LangTextUpdate } = useMutation(api.updateUiLanguage, {
  //   onSuccess: (data) => {
  //     if (data.data.status == 200) {

  //       console.log("RRRR", data);
  //       api.getDispensingLanguage().then((response) => {
  //         localStorage.setItem(
  //           "DispensingLanguage",
  //           JSON.stringify(response.datalist)
  //         );

  //       })
  //       isDirty = false;
  //       cellDataUpdate();
  //       // props.openNoticeModal({
  //       //   isOpen: true,
  //       //   msg: data.data.message,
  //       //   msgtype: data.data.success,
  //       // });


  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });

  //     }

  //   },

  // })

  const mutateCellUpdate=(fDtat) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/uiLanguage";
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "PUT", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log("kkkkkkk",res);
         
          return res.json();
        })
        .then(function (data) {
          isDirty=false;
          cellDataUpdate();
        })
        .catch(error => {
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     }


  const cellDataUpdate = (id, cell) => {
    if (isDirty == true) {

      document.getElementById("is_dirty_message").classList.remove("dnone");
      let curcellval = cell.getValue();
      let LangText = curcellval;
      let Celldata = cell.getRow().getData();

      // console.log('LangTextUpdate: ', id);

      //console.log(id, LangText);

      let fDtat={action: "dataUpdate",menukey: "ui-language",LangLabelId: id,LangText: LangText};  
      mutateCellUpdate(fDtat);

      // LangTextUpdate({ id, LangText });


    } else {

      document.getElementById("is_dirty_message").classList.add("dnone");
    }




  };




  const columns = [
    { title: "Id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 70, formatter: "rownum", headerHozAlign: "center", headerSort: false },
    {
      title: DispensingLanguage[lan][menukey]['Page Name'],
      headerFilter: true,
      width: 200,
      field: lan == "en_GB" ? ("EnglishMenu") : ("FranceMenu")

    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['Language Tag'], field: "LangKey", headerFilter: true, },
    {
      title: DispensingLanguage[lan][menukey]['English Text'],
      field: "EnglishText",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerFilter: true,
      editor: true,
         
      cellEdited: function (cell) {
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        const rowdata = currow.getData();
        const id = rowdata.LangLabelIdEn;
        cellDataUpdate(id, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]['French Text'],
      field: "FranceText",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerFilter: true,
      editor: true,
      cellEdited: function (cell) {
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        const rowdata = currow.getData();
        const id = rowdata.LangLabelIdFr;
        cellDataUpdate(id, cell);
      },

    },


  ];

  const editUiLanguage = (data) => {
    let route = `ui-language/edit/${data.id}`;
    props.history.push(route);
  };


  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editUiLanguage(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteUiLanguage(rowData);
          }}
        />
      </>
    );
  }

  const handleMenuChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {

      rowId = newValue.id;

    }

    if (rowId !== MenuKeyValue) {

      // Facility List By User Change
      setMenuKeyValue(rowId);
      setLoading(true);
    }


  };


  React.useEffect(() => {
    localStorage.setItem(
      "MenuId",
      MenuKeyValue
    );

  }, [MenuKeyValue]);


  // const [all, setAll] = React.useState('0');

  // const handleChange = event => {
  //   setAll(event.target.value);
  //   setLoading(true);
  //   //console.log(e.target.value);
  //   setMenuKeyValue(event.target.value);

  // };


  return (
    <>
      <div className={classes.uiLanguagePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["UI Language"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={4} sm={4}>

                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="MenuList"
                    disableClearable
                    options={MenuList}
                    // onChange={handleChange}
                    onChange={(event, newValue) => handleMenuChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={MenuList[MenuList.findIndex(MenuList => MenuList.id == 0)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Page Name"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>


                {/* <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Page Name"]}
                  </InputLabel>
                  <Select
                    //error={errorObject.MenuKey}
                    labelId="demo-simple-select-helper-label"
                    id="MenuKey"
                    name="MenuKey"
                    value={all}
                    onChange={handleChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                    {MenuList.map((item, index) => {
                    
                      return <MenuItem value={item.id}>{DispensingLanguage[lan]['menu'][item.name]}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className="float-right">
                  <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/ui-language/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add UI Language"]}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
             
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UiLanguage;

const useStyles = makeStyles({
  uiLanguagePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },


});
