/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const UserInfo = sessionStorage.getItem("User_info")  ? JSON.parse(sessionStorage.getItem("User_info"))  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }

  const menukey = "menu";

  return (
    <footer className="footer">
      <Container>
          <nav>
            <ul>
              <li>
                <Link to="/faq" className="text-capitalize">{DispensingLanguage[lan][menukey]["FAQ"]}</Link>
              </li>
              <li>
                <Link to="/about" className="text-capitalize">{DispensingLanguage[lan][menukey]["About"]}</Link>
              </li>
              <li>
                <Link to="/contact" className="text-capitalize">{DispensingLanguage[lan][menukey]["Contact"]}</Link>
              </li>
            </ul>
          </nav>
        <div className="copyright float-left" id="copyright">
          {/* © {new Date().getFullYear()},{" "}  Supported by GHSC-TA Francophone TO, Benin */}
        </div>
        <div className="copyright">
        Developed by <a href="https://softworksbd.com/" target={"_blank"}>SoftWorks Ltd</a>.
        </div>
      </Container>
    </footer>
  );
}

export default TransparentFooter;
