import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useParams } from "react-router-dom";
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";


const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const userId = UserInfo == 0 ? '' : UserInfo[0].id;

const DispenserFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();



  const RoleList = JSON.parse(localStorage.getItem("RoleList"));
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));


  const getRxLevel = (type = null) => {
    let level = "";
    if (formData.rxLevel) {
      level = formData.rxLevel;
    }
    return level;
  };

  // const { id } = useParams();
  // const [value, setValue] = React.useState(0);
  // const [ProvinceData, setProvinceData] = useState([]);
  // const [ZoneData, setZoneData] = useState([]);
  // const [BCZData, setBCZData] = useState([]);

  // const { refetch:refetch1} = useQuery(
  //     ["userlist", id],
  //     () =>
  //       api.getProvinceData(id).then((data) => {

  //         // console.log("provi data", res);

  //         setProvinceData(data);


  //       }),
  //     {
  //       enabled: Boolean(id),
  //     }
  //   );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["User - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/user_list")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/user_list")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  {/* <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.name}
                      helperText={errorObject.name}
                      required
                      id="name"
                      name="name"
                      label={DispensingLanguage[lan][menukey]["Name"]}
                      value={formData.name}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid> */}
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Language"]}*
                      </InputLabel>
                      <Select
                        error={errorObject.LangCode}
                        labelId="demo-simple-select-helper-label"
                        id="LangCode"
                        name="LangCode"
                        value={formData.LangCode || 'fr_FR'}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {LanguageList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.LangCode}>
                        {errorObject.LangCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.email}
                      helperText={errorObject.email}
                      required
                      id="email"
                      name="email"
                      label={DispensingLanguage[lan][menukey]["Email"]}
                      value={formData.email}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  {/* <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.loginname}
                      helperText={errorObject.loginname}
                      required
                      id="loginname"
                      name="loginname"
                      label={DispensingLanguage[lan][menukey]["Login Name"]}
                      value={formData.loginname}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid> */}
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.password}
                      helperText={errorObject.password}
                      required
                      id="password"
                      name="password"
                      type="password"
                      label={DispensingLanguage[lan][menukey]["Password"]}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  {/* <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.designation}
                      helperText={errorObject.designation}
                      required
                      id="designation"
                      name="designation"
                      label={DispensingLanguage[lan][menukey]["Designation"]}
                      value={formData.designation}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Role"]}*
                      </InputLabel>
                      <Select
                        disabled={formData.id==1?true:false}
                        error={errorObject.role_id}
                        labelId="demo-simple-select-helper-label"
                        id="role_id"
                        name="role_id"
                        value={formData.role_id}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                     
                      >
                        {RoleList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.role_id}>
                        {errorObject.role_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                     
                      {DispensingLanguage[lan][menukey]["Facility Name"]}
                      </InputLabel>
                      <Select
                        error={errorObject.FacilityId}
                        labelId="demo-simple-select-helper-label"
                        id="FacilityId"
                        name="FacilityId"
                        value={formData.FacilityId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                      <MenuItem value="">
                        {DispensingLanguage[lan][menukey]["All Facility"]}
                      </MenuItem>

                        {FacilityList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.FacilityId}>
                        {errorObject.FacilityId}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}


                  {/* <Grid item xs={4} sm={4} className="sw_active_patient">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={formData.id==1?true:false}
                          checked={formData.IsActive}
                          onChange={(e) => handleCheck(e)}
                          name="IsActive"
                          value="no"
                        />
                      }
                      label="Active"
                    />
                  </Grid> */}

                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
