import React, { forwardRef, useRef , useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/export_import.css";

import {
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  CardActions,
  RadioGroup,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

// import { DeleteOutline, Edit, CloudUploadOutlined } from "@material-ui/icons";

//Services
// import { getDefaultMonthYear } from "../../../services/Common";

//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

import swal from "sweetalert";

// import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { el } from "date-fns/locale";

const GetDataByAPI = (props) => {
  const classes = useStyles();
  const { useState } = React;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const menukey = "get-data-by-api";

  const SettingsDataObj = sessionStorage.getItem("User_info") ? JSON.parse(localStorage.getItem("getSettingsData")) : 0;
  const SettingsData = SettingsDataObj == 0 ? "" : SettingsDataObj[0];
  const getDataset = JSON.parse(localStorage.getItem("getDataset"));

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const SelectedDatasetId = getDataset ? getDataset[0].DatasetId : 0;
  const [currDatasetId, setCurrDatasetId] = useState(SelectedDatasetId);

  const [DataPreview, setDataPreview] = useState('');

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    menukey: menukey,
    lan: lan,
    txturl: SettingsData.URL+'dataValueSets.json',
    txtuserid: SettingsData.user,
    txpassword: SettingsData.PASSWORD,
    txtdataset: '',
    txtdatasetid: '',
    txtorgunitgroupid: '',
    txorgunit: '',
    txtduration: '',
    txtdurationerror: '',
    LogStartDate: selectedStartDate,
    LogEndDate: selectedEndDate,
    byDurationRange: 1
  });

  const {  mutate: mutateDataset } = useMutation(api.getDatasetIdList, {
    onSuccess: (data) => { 
      if (data.status === 200) {  
        let DataObj = data.data.datalist[0];

        let setData = { ...formData };
        
        setData['txtdataset'] = DataObj.DatasetId;
        setData['txtdatasetid'] = DataObj.DatasetId;
        setData['txtorgunitgroupid'] = DataObj.OrgGroupId;
        setData['txtduration'] = DataObj.DurationDay;
        setData['txtdurationerror'] = DataObj.ErrorHour;
    
        setFormData(setData);
      }
    }
  });

 const getDatasetIdCall = (gDatasetId) => {

     let params={
       action:"getDatasetId",
       menukey: menukey, 
       DatasetId: gDatasetId,
     };
     mutateDataset(params);
  };

  const {  mutate: mutateDataFromDHIS2 } = useMutation(api.getDataFromDHIS2, {
    onSuccess: (data) => { 

      if (data.status === 200) {  
        let DataObj = data.data.datalist;
        setDataPreview(DataObj);
        setLoading(false);
      }
    }
  });

 const onDataFromDHIS2 = () => {
  setDataPreview('');
    setLoading(true);

     let params={
       action:"getDataFromDHIS2",
       lan: lan,
       menukey: menukey, 
       txturl: formData.txturl,
       txtuserid: formData.txtuserid,
       txpassword: formData.txpassword,
       txtdataset: formData.txtdataset,
       txtdatasetid: formData.txtdatasetid,
       txtorgunitgroupid: formData.txtorgunitgroupid,
       txorgunit: formData.txorgunit,
       txtduration: formData.txtduration,
       txtdurationerror: formData.txtdurationerror,
       logstartdate: formData.LogStartDate,
       logenddate: formData.LogEndDate,
       durationtype: formData.byDurationRange
     }; 
     mutateDataFromDHIS2(params);
  };

  const {  mutate: mutateDataImportFromDHIS2 } = useMutation(api.dataImportFromDHIS2, {
    onSuccess: (data) => {

      // if (data.status === 200) {  
      //   let DataObj = data.data.datalist;
      //   setDataPreview(DataObj);
      //   setLoading(false);
      // }
    }
  });

  const importdatafromdhis2Facility = () => {
      // setDataPreview('');
      setLoading(true);

      let params={
        action:"dataImportFromDHIS2",
        lan: lan,
        menukey: menukey, 
        txturl: formData.txturl,
        txtuserid: formData.txtuserid,
        txpassword: formData.txpassword,
        txtdataset: formData.txtdataset,
        txtdatasetid: formData.txtdatasetid,
        txtorgunitgroupid: formData.txtorgunitgroupid,
        txorgunit: formData.txorgunit,
        txtduration: formData.txtduration,
        txtdurationerror: formData.txtdurationerror,
        logstartdate: formData.LogStartDate,
        logenddate: formData.LogEndDate,
        durationtype: formData.byDurationRange
      }; 
      mutateDataImportFromDHIS2(params);
  };

  const comboChangeEvnt = (e) => {
    getDatasetIdCall(e.target.value);
    setCurrDatasetId(e.target.value);

    handleChange(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    getDatasetIdCall(currDatasetId);

  }, [currDatasetId]);

  return (

    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        {/* New row */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Get Data by API']} />
              <CardContent className="paddingBottom">

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      {/* Start of Row */}
                      <Grid item xs={4} sm={4}>
                        <TextField
                          required
                          id="txturl"
                          name="txturl"
                          label={DispensingLanguage[lan][menukey]['URL']}
                          value={formData.txturl}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          required
                          id="txtuserid"
                          name="txtuserid"
                          label={DispensingLanguage[lan][menukey]['User Id']}
                          value={formData.txtuserid}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          required
                          id="txpassword"
                          name="txpassword"
                          type="password"
                          label={DispensingLanguage[lan][menukey]['Password']}
                          value={formData.txpassword}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      {/* end of Row */}

                      {/* Start of Row */}
                      <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                             {DispensingLanguage[lan][menukey]["DataSet"]} *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            fullWidth
                            id="txtdataset"
                            name="txtdataset"
                            value={currDatasetId}
                            onChange={(e) => comboChangeEvnt(e)}
                          >
                            {getDataset.map((item, index) => {
                              return (
                                <MenuItem value={item.DatasetId}>
                                  {item.DatasetLevel}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          disabled={true}
                          required
                          id="txtdatasetid"
                          name="txtdatasetid"
                          label={DispensingLanguage[lan][menukey]['Dataset Id']}
                          value={formData.txtdatasetid}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          disabled={true}
                          required
                          id="txtorgunitgroupid"
                          name="txtorgunitgroupid"
                          label={DispensingLanguage[lan][menukey]['Organisation Unit Group Id']}
                          value={formData.txtorgunitgroupid}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          id="txorgunit"
                          name="txorgunit"
                          label={DispensingLanguage[lan][menukey]['Organization Unit']}
                          value={formData.txorgunit}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      {/* End of Row */}

                      {/* Start of Row */}
                      <Grid item xs={4} sm={4}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="byDurationRange"
                            name="byDurationRange"
                            value={formData.byDurationRange}
                            className="sw_radiogroup"
                          >
                            <FormControlLabel
                              value="1"
                              checked={formData.byDurationRange == 1}
                              control={<Radio />}
                              label={DispensingLanguage[lan][menukey]["By Duration"]}
                              onChange={(e) => handleChange(e)}
                            />
                            <FormControlLabel
                              value="2"
                              checked={formData.byDurationRange == 2}
                              control={<Radio />}
                              label={DispensingLanguage[lan][menukey]["By Date Range"]}
                              onChange={(e) => handleChange(e)}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {formData.byDurationRange == 1 ? 
                        <>
                          <Grid item xs={4} sm={4}>
                          <TextField
                            required
                            id="txtduration"
                            name="txtduration"
                            type="number"
                            label={DispensingLanguage[lan][menukey]['Duration (Hour)']}
                            value={formData.txtduration}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <TextField
                            required
                            id="txtdurationerror"
                            name="txtdurationerror"
                            type="number"
                            label={DispensingLanguage[lan][menukey]['Duration Error (Hour)']}
                            value={formData.txtdurationerror}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                      </>

                      : 

                      <>
                        <Grid item xs={4} sm={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justifyContent="space-around">
                                <KeyboardDatePicker
                                  className={classes.fullWidth}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  id="date-picker-inline"
                                  label={t(DispensingLanguage[lan][menukey]["Start Date"])}
                                  autoOk={true}
                                  value={selectedStartDate}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  disableFuture={true}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={t(DispensingLanguage[lan][menukey]["End Date"])}
                                autoOk={true}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </>
                      }

                      {/* End of Row */}

                      {/* Start of Row */}
                      <Grid item xs={4} sm={4}>
                        <Button
                          className="mr-2"
                          variant="contained"
                          color="primary"
                          onClick={() => onDataFromDHIS2()}
                        >
                          {t(DispensingLanguage[lan][menukey]["Data Preview"])}
                        </Button>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <div className="sw_relative">
                          {isLoading && <LoaderForGlobal />}
                        </div>
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <Button
                          className="float-right"
                          variant="contained"
                          color="primary"
                          onClick={() => importdatafromdhis2Facility()}
                        >
                          {t(DispensingLanguage[lan][menukey]["Import Data from DHIS2"])}
                        </Button>
                      </Grid>
                      {/* End of Row */}
                      
                      {/* Start of Row */}
                      <Grid item xs={12} sm={12}>
                          <TextField
                            id="DataPreview"
                            name="DataPreview"
                            multiline
                            rows={14}
                            label={DispensingLanguage[lan][menukey]['Output Data']}
                            value={DataPreview}
                            fullWidth
                            autoComplete="family-name"
                          />
                      </Grid>
                      {/* End of Row */}

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GetDataByAPI;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  facilitygroupmapTitle: {
    marginTop: "40px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
