import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid ,
   Select,
   MenuItem,
   TextField,
   Typography,
   FormControl,
   InputLabel,
   Card,
   CardContent
  } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const MOSRange = (props) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "mos-type-for-facility-entry";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));
  const FLevelId = localStorage.getItem('FLevelId');
  
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [currFLevel, setcurrFLevel] = useState(FLevelId);
  const [isLoading, setLoading] = useState(true);
  const [currFTypeId, setcurrFTypeId] = useState(0);




  let params = {
    menukey: menukey,
    "action": "getDataList",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    FLevelID: currFLevel,
    FTypeId: currFTypeId,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getMosTypeforFacility,
    {
      onSuccess: (data) => {
       
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );

  const { mutate:deleteApi } = useMutation(api.deleteMosTypeforFacility, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        refetch();
        queryClient.getQueriesData("mostypeforfacility");   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
		
    
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
      
        }
    },
  });



// Store Combo Change
const handleStoreChange = (event) => {

  if(currFLevel!==event.target.value){
    setcurrFLevel(event.target.value); 
    setLoading(true);
  }
};
const handleFTypeChange = (event) => {
  setcurrFTypeId(event.target.value);
};



React.useEffect(() => {
 
  localStorage.setItem(
    "FLevelId",
    currFLevel
  );
  
}, [currFLevel]);

//End Store




/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=getMOSTypeForFacilityData" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FLevelID=" +
      currFLevel  +
      "&FTypeId=" +
       currFTypeId
      + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  const columns = [
    { title: "Id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 70, formatter: "rownum", headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['MOS Type'], field: "MosTypeName"},
    { title: DispensingLanguage[lan][menukey]['Minimum MOS'], hozAlign: "center", headerHozAlign: "center", width: 150, field: "MinMos"},
    { title: DispensingLanguage[lan][menukey]['Maximum MOS'], hozAlign: "center", headerHozAlign: "center", width: 150, field: "MaxMos"},
    { title: DispensingLanguage[lan][menukey]['Color Code'], width: 100, field: "ColorCode", formatter:"color"},
    { title: DispensingLanguage[lan][menukey]['Icon Mos'], width: 150, field: "IconMos"},
    { title: DispensingLanguage[lan][menukey]['Icon Mos Width'], width: 100, field: "IconMos_Width"},
    { title: DispensingLanguage[lan][menukey]['Icon Mos Height'], width: 100, field: "IconMos_Height"},
    { title: DispensingLanguage[lan][menukey]['MOS Label'], width: 100, field: "MosLabel"},
  
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign:"center",
      headerSort:false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editMOSRange = (data) => {
    let route = `mos_type_for_facility/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteMOSRange = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
  //     text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this MOS Range!']),
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       mutate(data.id);
  //       swal(t(DispensingLanguage[lan][menukey]['Poof! MOS Range has been deleted!']), {
  //         icon: "success",
  //       });
  //     }
  //   });
  // };

  const deleteMOSRange = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({MosTypeId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editMOSRange(rowData);
          }}
        />
        {/* <DeleteOutline
          onClick={() => {
            deleteMOSRange(rowData);
          }}
        /> */}
      </>
    );
  }

  return (
    <>
      <div className={classes.mosrangePageTitle}>

      <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["MOS Range"])}
              </div>
            </div>
          </Grid>
        </div>

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Level"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FLevelId"
                    name="FLevelId"
                    value={currFLevel}
                   // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                  >
                     {/* <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Level"]}</MenuItem> */}
                    {FlevelList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FTypeId"
                    name="FTypeId"
                    value={currFTypeId}
                    onChange={handleFTypeChange}
                    fullWidth
                  >
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Type"]}</MenuItem>

                    {FTypeList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}


                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={7} sm={7}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {

                      props.history.push("/store_entry/add");
                    
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add MOS Range"])}
                  </Button> */}

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>




            </Grid>
          </CardContent>
        </Card>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns}
             data={dataItems}
             //height={600}
             layout={"fitColumns"}
             options={{
              groupBy: "FTypeName",
              columnCalcs: "both",
            }}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default MOSRange;

const useStyles = makeStyles({
  mosrangePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
