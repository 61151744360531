import React, { useState , useEffect} from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';  
import swal from "sweetalert";
import * as api from '../../../actions/api';
import SnigsItems from './SnigsItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";

 
const EditSnigs = (props) => {


  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "snigs-logistics-report-a7";
const FacilityId=localStorage.getItem('FacilityId');
const [dirty, setDirty] = useState(false);

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")): 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
   

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({  
    "FacilityId":FacilityId,
    FacilityName:"",
    MonthName:"",
    YearId:"",
    StatusName:""
    });

    const [formData2, setFormData2] = useState({  
      Feedback:""
      });


    
  const [ManyTableData, setManyTableData] = useState([]);
  const [LegendData, setLegendData] = useState([]);
  const [RemarksChainData, setRemarksChainData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  
  
  
  const [bFirst, setBFirst] = useState(true); 

  
  const [errorObject, setErrorObject] = useState({});
  const [errorObject2, setErrorObject2] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['singsdata', id], () =>

    api.getSnigsSingleData({CFMStockId:id}).then((res) => {
      setDirty(true);
      setTransactionId(id); 
      setFormData(res.data); 
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setLegendData(res.data.LegendJson);
      setRemarksChainData(res.data.RemarksChain);
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      
      setDirty(false);
      setBFirst(true);

      
      return res.data
    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })


  const { isLoading, mutate } = useMutation(api.SubmitSnigsData, {
    onSuccess: (data) => {
      if (data.status == 200) {

          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });





  const { isLoading1, mutate:mutateUnsubmit } = useMutation(api.UnSubmitSnigsData, {
    onSuccess: (data) => {
      if (data.status == 200) {

          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });

  

  


  const validateForm = (formData) => {
    //let validateFields = [ "TransactionDate", "TransactionNo","PreparedBy"]
    let validateFields = ["FacilityId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

 
 
  const handleSubmit = async () => {  

    if (validateForm(formData)) {
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Msg_Submit"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,menukey: menukey,lan: lan,"UserName":UserName,"UserId":UserId}; 
          mutate(FinalData); 
          
        }else{
      
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert 
    }
        
  };


  const handlePublish = async () => {  

    if (validateForm(formData)) {
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Msg_Publish"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,menukey: menukey,lan: lan,"UserName":UserName,"UserId":UserId}; 
          mutate(FinalData); 
          
        }else{
      
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert 
    }
        
  };


  const handleUnsubmit = async () => {  

    if (validateForm(formData)) {
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Msg_Unsubmit"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,menukey: menukey,lan: lan,"UserName":UserName,"UserId":UserId}; 
          mutateUnsubmit(FinalData); 
          
        }else{
      
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert 
    }
        
  };



  const handleUnpublish = async () => {  

    if (validateForm(formData)) {
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Msg_Unpublish"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,menukey: menukey,lan: lan,"UserName":UserName,"UserId":UserId}; 
          mutateUnsubmit(FinalData); 
          
        }else{
      
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert 
    }
        
  };

  const { isLoading3, mutate:mutateRemarksChain } = useMutation(api.saveRemarksChain, {
    onSuccess: (data) => { 
      if (data.status == 200) {

        //console.log("sssss", data.data.message);

        refetch();

        
       
        

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      setFormData2({
        Feedback: ""
      });

     
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.message,
        });
      }
    }
  }) 

  const validateForm2 = (formData2) => {

    let validateFields = ["Feedback"]
    let errorData2 = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData2[field]) {
        errorData2[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject2(errorData2);
    return isValid
  }

  const handleFeedbackSubmit = async (e) => {

    
    
    if (validateForm2(formData2)) {
      let url_val = props.match.params.id;
      //console.log("hdaaaaaaaaaaaaaaaaaaaaaaaaaa",url_val);
      let fDtat={...formData2,"menukey": menukey,"lan":lan,"UserName":UserName,"UserId":UserId,"CFMStockId":url_val}; 
      
      mutateRemarksChain(fDtat);
     
    }
  };


  const handleChange2 = (e) => {
    const { name, value } = e.target;
    let data = { ...formData2 };
    data[name] = value;

    setFormData2(data);
    setErrorObject2({ ...errorObject, [name]: null });
  };


   
  return (
    <>
      <SnigsItems 
        errorObject={errorObject} 
        errorObject2={errorObject2} 
        addProductForm={false}
        formData={formData} 
        formData2={formData2} 
        handlePublish={handlePublish}  
        ManyTableData={updatedDetailGridData} 
        bStockUpdate={bStockUpdate} 
        handleSubmit={handleSubmit} 
        handleUnsubmit={handleUnsubmit} 
        handleUnpublish={handleUnpublish}
        bFirst={bFirst}
        refetch={refetch}
        dirty={dirty} 

        LegendData={LegendData} 
        RemarksChainData={RemarksChainData}
        handleFeedbackSubmit={handleFeedbackSubmit}
        handleChange2={handleChange2}

        {...props} />
    </>
  );
};

export default EditSnigs;