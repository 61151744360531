import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  TextField, 
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel, 
  MenuItem, 
  FormGroup,
  Card,
  CardContent,
  FormHelperText
} from "@material-ui/core";

import {
  DeleteOutline,
  Edit,
  ViewList,
  FileCopy
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar"; 
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import ProductsModal from '../../../services/ProductsModal';

import * as ScanBarcode from '../../../services/ScanBarcode';

import { cellFocusEditor, dateEditor } from "../../../services/Common";
import OrderModal from './OrderModal';

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = '';

// import Modal from '@mui/material/Modal'; 
// import { Modal } from '@mui/material';

//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const ReceiptsItems = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "sobaps-receipts";
// const FacilityId=localStorage.getItem('FacilityId');
const FacilityId = JSON.parse(localStorage.getItem("getCentralWarehouseId"));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef =null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  //console.log("Set r batch");
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [openWarehouseInvoice, setWarehouseInvoice] = useState(false);


  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const [bDownloadIssueInvoice, setbDownloadIssueInvoice] = useState(false);
  

  // const getFacilityListNotEqual99ANDSupplier =JSON.parse(localStorage.getItem("getFacilityListNotEqual99ANDSupplier"));

  const getSupplierList =JSON.parse(localStorage.getItem("getSupplierList"));
  
  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [currItemGroupId, setCurrItemGroupId] = useState('0');
  const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);
  // Store
  const StoreId = localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const StoreList = JSON.parse(localStorage.getItem("getStore"));

  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_receive_from_warehouse_entry_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_receive_from_warehouse_entry_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  
  
  const handleClose = (modalname) => {
    switch (modalname) {

      case "Products":
        setOpen(false);
        break;

      case "ProductsOpen":
        setOpen(true);
        break;

      case "OrderModal":
          setOpenOrder(false);
          break;

      case "OrderOpen":
          setOpenOrder(true);
          break; 

      case "WarehouseInvoiceClose":
        setWarehouseInvoice(false);
        break;

      case "WarehouseInvoicOpen":
        let modal_open = props.handleModalCheck();

        if(modal_open)
        setWarehouseInvoice(true);
         break; 

      default:
        break;
    }
  }
  
  const addProduct = (NonOrderProductData) => { 
 
    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;
      
      newRow.ItemCode =row.ItemCode;
      newRow.BrandNo =row.BrandNo;
      
      newRow.ItemName =row.ItemName;
      newRow.BrandCode =row.BrandCode;
      newRow.CommercialName =row.CommercialName;
      newRow.UnitPrice =row.UnitPrice;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      manyTableData.forEach((rowssss,ij) => {
       
        if(rowssss.ItemNo==row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;

        }
      });

      newRow.CurrentStockQty = 0;
      newRow.Quantity =0;
      newRow.BatchNo = null;
      
      newRow.EAGTIN = null;
      newRow.PKGTIN = null;
      newRow.CAGTIN = null;
      newRow.PLGTIN = null;
      newRow.SSCCCarton = null;
      newRow.SSCCPallet = null;

      newRow.ExpiryDate = null;
      newRow.SKU=autoId;
      newRow.id=autoId;  
      newRow.LineTotal=0; 
      rows.push(newRow);
    
  });

  manyTableData.forEach((row,i) => {
    autoId++;
    let newRow={} ; 
    newRow.ItemNo =row.ItemNo;
    newRow.ItemCode =row.ItemCode;
    
    newRow.ItemName =row.ItemName;
    newRow.BrandCode =row.BrandCode;
    newRow.CommercialName =row.CommercialName;
    newRow.UnitPrice =row.UnitPrice; 
    newRow.TransactionItemId = row.TransactionItemId;
    newRow.OrderRemainingQty = row.OrderRemainingQty;
    newRow.CurrentStockQty = row.CurrentStockQty;
    newRow.Quantity =row.Quantity;
    newRow.BatchNo = row.BatchNo;

    newRow.EAGTIN = row.EAGTIN;
    newRow.PKGTIN = row.PKGTIN;
    newRow.CAGTIN = row.CAGTIN;
    newRow.PLGTIN = row.PLGTIN;
    newRow.SSCCCarton = row.SSCCCarton;
    newRow.SSCCPallet = row.SSCCPallet;
    newRow.BrandNo = row.BrandNo;

    newRow.ExpiryDate =row.ExpiryDate;
    newRow.SKU=autoId;
    newRow.id=autoId; 
    newRow.LineTotal=row.Quantity*row.UnitPrice; 

    rows.push(newRow);

}); 

    setManyTableData(rows);   
    props.handleAdd(rows); 
    handleClose('Products');
  }; 

  const addOrder = (OrderProductData) => {
    props.refetch();
    handleClose('OrderModal');
    //editReceiveInv(OrderProductData);
  };

  const addWarehouseInvoice = (WarehouseInvoiceData) => {
    props.handleAddWarehouseInvoice(WarehouseInvoiceData);
    handleClose("WarehouseInvoice");
  };


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false }, 
    { title: "id", field: "id", visible: false }, 
    { title: DispensingLanguage[lan][menukey]['SL'],  width: 60, formatter: "rownum", hozAlign: "center", 
    headerHozAlign:"center", headerSort:false  },

    { 
      title: DispensingLanguage[lan][menukey]['Actions'], 
      field: 'custom', 
      headerSort:false, 
      headerHozAlign:"center", 
      hozAlign: 'center',
      width: 65,
     formatter: reactFormatter(<ActionButton 
     /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */ />) } ,

    { title: DispensingLanguage[lan][menukey]['Product_Code'], field: "ItemCode", width: 130 ,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName", width: 250, headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 190,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Brand Name'], field: "CommercialName", width: 150,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Quantity_Ordered'], field: "OrderedQuantity", width: 100, hozAlign: "right", headerHozAlign:"right", visible: false},
    { title: DispensingLanguage[lan][menukey]['Prev_Received_Quantity'], field: "OrderRemainingQty", width: 100, hozAlign: "right", headerHozAlign:"right", visible: false},
   
    { title: DispensingLanguage[lan][menukey]['Quantity_Received'], field: "Quantity",  width: 100, hozAlign: "right", headerHozAlign:"right",
      validator:["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);
       // ReceiveDetailsRef.table.getData();
      //  console.log('ReceiveDetailsRef.table.getData(): ', ReceiveDetailsRef.table.getData());
       // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
      } 
    },
    { title: DispensingLanguage[lan][menukey]['Lot_No_Description'], field: "BatchNo", width: 100, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
     //   props.setUpdatedDetailsGridDataHandler(rowdata);
      }
    },
    { title: DispensingLanguage[lan][menukey]['Expiry_Date'], field: "ExpiryDate" , width: 100,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? dateEditor: '', 
      cellEdited: function (cell) {
         
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
       // props.setUpdatedDetailsGridDataHandler(rowdata);
      }
    },
    { title: DispensingLanguage[lan][menukey]['EAGTIN'], field: "EAGTIN", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['PKGTIN'], field: "PKGTIN", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['CAGTIN'], field: "CAGTIN", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['PLGTIN'], field: "PLGTIN", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: "SSCCCarton", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: "SSCCPallet", width: 120, 
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
      }
    },
    { title: DispensingLanguage[lan][menukey]['Unit_Price'], field: "UnitPrice" , width: 100, hozAlign: "right", headerHozAlign:"right", 
      validator:["min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.Quantity * curcellval;
        pricecell.setValue(totprice);
        
       // props.setUpdatedDetailsGridDataHandler(currow._row.data);
      } 
    },
    { title: DispensingLanguage[lan][menukey]['Line_Total'], field: "LineTotal" , width: "10%",
      formatter: "money", hozAlign: "right", headerHozAlign:"right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money"
    }
  ];
   
  // const ReceiveDetailsDataItems = manyTableData?.map((item, index) => {
   
  //  // item["sl"] = index + 1;
    
  //   item["LineTotal"] = item.Quantity*item.UnitPrice;
  //   return item;
  // }); 
  
 
  // setManyTableData((preManyTableData) => {

  //   const updatedManyTableData = preManyTableData?.map((item, index) => {

  //     // item["sl"] = index + 1;

  //     item["LineTotal"] = item.Quantity * item.UnitPrice;
  //     return item;
  //   });

  //   return updatedManyTableData;

  // });



   const onPostBtnClick = () => { 
    setbStockUpdate(true); 
    props.handlePost();

  }

  const onDownloadIssueInvoiceBtnClick = () => { 
   // setbDownloadIssueInvoice(true); 
    props.DownloadIssueInvoice();
  }

  const onSaveUpdateBtnClick = () => { 
   
    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData()); 
  //  props.handleUpdate();
  }

  const deleteReceiveInv = (data) => { 
    let newarray = manyTableData.filter(element => element !== data); 
    setManyTableData(newarray); 
    props.deleteReceiveInv(data); 
  };




  function ActionButton (props) {  
    const rowData = props.cell._cell.row.data;  

    const goToDetails = () => {

      let rowobj = props.cell._cell.row.data;

      timeStamp = Math.round(new Date().getTime() / 100); 
     let tabledata=(prevRows) => {

        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU ==rowobj.SKU);
    
        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";

        // obj.EAGTIN = "";
        // obj.PKGTIN = "";
        // obj.CAGTIN = "";
        // obj.PLGTIN = "";
        // obj.SSCCCarton = "";
        // obj.SSCCPallet = "";
        // obj.BrandNo = "";

        obj.Quantity = 0;
        obj.SKU = Date.now();

        console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition+1, 0, obj)

        return tmpPrevRows;
      }
      ;
      setManyTableData(tabledata);
 
      CopyBatch(tabledata);
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);

    };
   

    const cellValue = props.cell._cell.value || 'Edit | Show';
    //if(props.formData.InvFrom!=null)
    if(bStockUpdate == 0)
    return (
      <>

        <FileCopy
          onClick={() => {
            if (!bStockUpdate) goToDetails(rowData);
          }}
        />

        <DeleteOutline
          onClick={() => {
            if (!bStockUpdate) deleteReceiveInv(rowData);
          }}
        />
      </>
    );
    else  return (
      <>
         
      </>
    );
  }

  
  
  useEffect(() => {
    if (ReceiveDetailsRef == null) return; 
    if(props.bFirst){
      setManyTableData(props.ManyTableData); 
    }
    
    setbStockUpdate(props.bStockUpdate);
}, [props.ManyTableData,props.bStockUpdate]); //


useEffect(() => {
  if (ReceiveDetailsRef.table != null && timeStamp != '') { 
    ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
    ReceiveDetailsRef.table.selectRow(timeStamp);


  }
}, [newBatchRowAdded]); 

const CopyBatch = (tabledata) => {
    
  props.handleAdd(tabledata); 
  
};


const handleChange = (e) => {
  props.filterFandleChange(e.target.value);
  setCurrItemGroupId(e.target.value);
};

const handleReset = () => {
  setManyTableData([]);  
  props.handleReset();
};

const doActionScanBarcode = () => {
  let SupplierString = props.formData.ReceiveFrom;
  const SupplierArr = SupplierString.split("_");
  const SupplierId = SupplierArr[1];

  const ScanBarcodeParams = {
      TransactionTypeId: 1,
      StoreId: props.formData.StoreId,
      TransactionId: props.formData.TransactionId,
      FacilityId: props.formData.FacilityId,
      FacilityLevelId: 1,
      ReceiveFromId: SupplierId,
      AdjTypeId: 0,
      lan: lan,
      menukey: menukey
    }

  ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, {...props});
}

const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}

let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};

  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

        <div className="sw_page_heading">
            
            
            <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Recieve_from_warehouse_create_edit']}</div>
            
            <div className="float-right sw_btn_control">

            {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">
               <div className="float-right sw_btn_control">

               <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/sobaps-receipts")}
                >
                  {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => handleReset()}
                  // onClick={() => props.handleReset()}
                >
                  {DispensingLanguage[lan][menukey]['Reset']}
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
                >
                  {DispensingLanguage[lan][menukey]['Submit']}
                </Button>
                
               </div>
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">
                
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/sobaps-receipts")}
                >
                 {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                <Button
                  disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}
                   
                >
                 {DispensingLanguage[lan][menukey]['Update']}
                </Button>
                
                <Button 
                  disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onPostBtnClick}
                >
                  {DispensingLanguage[lan][menukey]['POST']}
                </Button>
                

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => PrintMany()}
                >
                <i class="fas fa-print"></i>
                </Button>

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => ExcelMany()}
                >
                <i className="far fa-file-excel"></i>
                </Button>

              </Grid>
          }

            </div>
         </div>
        </Grid>
      </div>

      
      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}
 

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                  <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Store"]} *
                    </InputLabel>
                    <Select
                      error={props.errorObject.StoreId}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="StoreId"
                      name="StoreId"               
                      value={ props.formData.StoreId}
                    // value={all}
                      onChange={(e) => props.handleChange(e)}
                      
                      fullWidth
                      disabled={props.addProductForm==false?true: props.bStockUpdate}
                    >
                      
                      {StoreList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>

                    <FormHelperText error={props.errorObject.StoreId}>
                      {props.errorObject.StoreId}
                    </FormHelperText>

                  </FormControl>
                </Grid>

                  <Grid item xs={3} sm={3}>
                      <TextField
                         disabled={true/*bStockUpdate*/}
                         error={props.errorObject.TransactionNo}
                         helperText={props.errorObject.TransactionNo}
                         required
                        id="TransactionNo"
                        name="TransactionNo"
                        label={DispensingLanguage[lan][menukey]['Receive_Invoice_No']} 
                        // value={formData.FirstName || "Mahmudul"}
                        value={props.formData.TransactionNo||props.generatedInvoiceNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => props.handleChange(e)}
                      />
                    </Grid>
                     

                    <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.TransactionDate}
                            helperText={props.errorObject.TransactionDate}
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            id="TransactionDate"
                            label={DispensingLanguage[lan][menukey]['Receive_Date']} 
                            autoOk={true}
                            name="TransactionDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.TransactionDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider> 
                    </Grid>

                    <Grid item xs={3} sm={3}>
                     <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label-receive-from">
                        { DispensingLanguage[lan][menukey]['Supplier'] } *  
                        </InputLabel>
                        <Select
                           
                          error={props.errorObject.ReceiveFrom} 
                          required                                
                          labelId="demo-simple-select-helper-label-receive-from"
                          id="ReceiveFrom"
                          name="ReceiveFrom"
                          value={props.formData.ReceiveFrom}
                          fullWidth
                          onChange={(e) => props.handleChange(e)}
                          disabled={ manyTableData.length>0? true:props.bStockUpdate}
                        >
                        {
                        getSupplierList.map(
                        (item, index) => {
                          return (
                            <MenuItem value={'SUP_'+item.SupplierId}>
                              {item.SupplierName}
                            </MenuItem>
                          );
                        }
                        )}
                      </Select>
                      <FormHelperText error={props.errorObject.ReceiveFrom}>
                        {props.errorObject.ReceiveFrom}
                      </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        error={props.errorObject.SupplierInvNo}
                        helperText={props.errorObject.SupplierInvNo}
                        required
                        id="SupplierInvNo"
                        name="SupplierInvNo"
                        label={DispensingLanguage[lan][menukey]['Warehouse_Invoice_No']} 
                        value={props.formData.SupplierInvNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) =>props.handleChange(e)}
                      />
                    </Grid> 

                    {/* <Grid item xs={1} sm={1}>
                        <Button
                          disabled={props.bStockUpdate}
                          className="pick_btn"
                          variant="contained"
                          color="success"
                          //onClick={props.handleModalCheck}
                          onClick={() => handleClose("WarehouseInvoicOpen")}
                        >
                          ...
                        </Button>

                        <WarehouseInvoiceModal
                          handleClose={handleClose}
                          open={openWarehouseInvoice}
                          addWarehouseInvoice={addWarehouseInvoice}
                          IssuedTo={props.formData.ReceiveFrom}
                          FormData={props.formData}
                          {...props}
                        />
                    </Grid> */}


                    <Grid item xs={2} sm={2}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.SupplierInvDate}
                            helperText={props.errorObject.SupplierInvDate}
                            className={classes.fullWidth}
                           // disableToolbar
                            variant="inline"                            
                            id="SupplierInvDate"
                            label={DispensingLanguage[lan][menukey]['Warehouse_Invoice_Date']} 
                            autoOk={true}
                            name="SupplierInvDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.SupplierInvDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            onChange={(e) => props.handleRDateChange(e, "SupplierInvDate")}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>                      
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                        
                          {DispensingLanguage[lan][menukey]["Receive_By"]} *
                        </InputLabel>
                        <Select
                          error={props.errorObject.PreparedBy}
                         
                          required
                          labelId="demo-simple-select-helper-label"
                          id="PreparedBy"
                          name="PreparedBy"
                          value={props.formData.PreparedBy}
                          fullWidth
                          onChange={(e) => props.handleChange(e)}
                          disabled={props.IsCompleted}
                        >
                        {UsersListByFacility.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={props.errorObject.PreparedBy}>
                          {props.errorObject.PreparedBy}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="Remarks"
                        name="Remarks"
                        label={DispensingLanguage[lan][menukey]['Remarks']} 
                        value={props.formData.Remarks}
                        fullWidth
                        multiline                    
                        autoComplete="family-name"
                        onChange={(e) => props.handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="TransactionId"
                        name="TransactionId"
                        label="TransactionId"  
                        value={props.formData.TransactionId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />

                    </Grid>  
            
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          <Grid container className="mb-2">

                      <Grid item xs={2} sm={2}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                            error={props.errorObject.ItemGroupId}
                            labelId="demo-simple-select-helper-label"
                            id="ItemGroupId"
                            name="ItemGroupId"
                            value={currItemGroupId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid item xs={7} sm={7}>

                        <div className="search_card">
                          <form>  
                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                              <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                              <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                            </div>
                            <div className="search_control_group">
                              <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                              <input type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                            </div>
                            <div className="search_control_group">
                              <Button
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()}
                              >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                              </Button>
                            </div>
                          </form>
                        </div>

                      </Grid>
                      <Grid item xs={3} sm={3}>

                      <div className="float-right sw_btn_control">
                                
                                {/* <Button
                                  disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=null?true: props.bStockUpdate)}
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  {DispensingLanguage[lan][menukey]['Select_From_Order']}
                                </Button>

                                <Button
                                  disabled={props.addProductForm==true?true: props.formData.InvFrom!=null?true: props.bStockUpdate}
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={onDownloadIssueInvoiceBtnClick}
                                >
                                  {DispensingLanguage[lan][menukey]['Download_Issue_Invoice']}
                                </Button>                               
                                */}

                                <Button
                                  disabled={props.addProductForm==true?true: props.bStockUpdate}
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("ProductsOpen")}
                                >
                                  {DispensingLanguage[lan][menukey]['Add_Non_Ordered_Products']}  
                                </Button> 

                                </div>

                          </Grid>

                              {/* <ProductsModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} /> */}
                              
                              <ProductsModal 
                              handleClose={handleClose} 
                              open={open} 
                              addProduct={addProduct} 
                              gFacilityId={props.formData.FacilityId} 
                              gStoreId={props.formData.StoreId} 
                              {...props} />

                              <OrderModal handleClose={handleClose} openOrder={openOrder} addOrder={addOrder} 
                              OrderingFrom={props.formData.ReceiveFrom} 
                              TransactionId={props.formData.TransactionId} 
                              gStoreId={props.formData.StoreId} {...props} />

                          </Grid> 
                           <ReactTabulator
                                 
                                  ref={(r) => (ReceiveDetailsRef = r)}
                                  columns={ReceiveDetailsColumns}
                                  data={manyTableData}
                                  height="350px"
                                  layout={"fitColumns"}
                                />
                         
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* new row */} 
                  </Grid>
              </Grid>

            {/* </CardContent>
          </Card> */}
        </Grid>

       {/* Action buttons */}
         
      </Grid>
    </div>
  </div>
  );
};

export default ReceiptsItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
