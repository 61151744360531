import React, { useState,useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";
import * as api from '../../../actions/api';
import OrderApprovalFormData from './OrderApprovalFormData.js';
import { useTranslation } from "react-i18next";




const EditApproveOrder = (props) => {

  const FacilityId=localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  ); 
  const menukey = "order-approval";
  const lan = localStorage.getItem("LangCode");

  const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;  
const UserId = UserInfo==0?'': UserInfo[0].id;
const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
const [ItemGroupId, setItemGroupId] = useState("0");
//console.log("User INffffffffffff",UserInfo);

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    OrderDate: "",
    OrderNo: "",
    OrderingFromText:"",
    OrderTypeId:"",
    ManyJsonSave:"",
    "FacilityId":FacilityId,
    OrderBy: "",
    InternalApprover:"",
    ApprovedBy: "",
    ItemGroupId:"0",
    Remarks:"",
    OrderByName:""
  });


const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [bCompleted, setbCompleted] = useState("0");
const [ManyTableData, setManyTableData] = useState([]);
const [OrderId, setOrderId] = useState("0");
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
const [bFirst, setBFirst] = useState(true); 
const [isFirstPhysical, setIsFirstPhysical] = useState(0);
const handleDataTableChange = (e) => {};

const [errorObject, setErrorObject] = useState({});
const { id } = useParams();
const queryClient = useQueryClient();
const [dirty, setDirty] = useState(false);

const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


const { data,refetch } = useQuery(['order', id], () =>
  api.getOrderInv({OrderId:id,OnlyOrderedItem:1,ItemGroupId:ItemGroupId}).then((res) => {
    setDirty(true);
    setOrderId(id);
    //res.data.ApprovedBy=res.data.ApprovedBy>0?res.data.ApprovedBy:UserId;
    res.data.ApprovedBy=res.data.ApprovedBy;
    setFormData(res.data); 
    
    setbCompleted(res.data.bCompleted);
    setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
    setUpdatedDetailGridData(res.data.ManyJsonSave);
    setGeneratedInvoiceNo(res.data.OrderNo);
    setBFirst(true);
    setDirty(true);
    return res.data

  }), {
  refetchOnWindowFocus: false,  
  enabled: Boolean(id)
}) 

/*
const { isLoading, mutate:mutateUpdate } = useMutation(api.updateOrderApproval, {
  onSuccess: (data) => {
    if (data.status == 200) {  
      if(data.data.IsCompleted==1){
        //console.log("Is commmmmmmmmm",OrderId);
        mutatePost(OrderId);
      }else{
      let route = `${data.data.data.OrderId}`;
      setbCompleted(data.data.data.bCompleted); 
      setManyTableData(data.data.data.ManyJsonSave);
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        //swal("Success!", "", "success");
      }
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  },
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 0,//1000 * 60 * 60 * 24,
})

*/

/*
const { isLoading1, mutate:mutatePost } = useMutation(api.postOrderApproval, {
  onSuccess: (data) => { 
    if (data.status == 200) { 
     let route = `${OrderId}`;
     setbCompleted(data.data.data.bCompleted); 
     setManyTableData(data.data.data.ManyJsonSave);
     
     props.history.push(route);

     props.openNoticeModal({
      isOpen: true,
      msg: data.data.message,
      msgtype: data.data.success,
    });
    refetch();
    
    
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

*/

// const setUpdatedDetailsGridDataHandler = (rows) => {

//   if (validateForm(formData)) {
//     let fDtat={...formData,'ManyJsonSave':rows}; 
//     mutateUpdate(fDtat); 
//     }
//     setManyTableData(rows);  
  
// }


/*

const setUpdatedDetailsGridDataHandler = (rows,deletedsetdata) => {

  if (validateForm(formData)) { 
   let fDtat={...formData,lan:lan, 'ManyJsonSave':rows,'deletedDataSet':deletedsetdata,'UserName':UserName}; 
   mutateUpdate(fDtat); 
    } 
  
}
*/

const handleChange = (e) => {
  const { name, value } = e.target;
  
    let data = { ...formData };
    data[name] = value;            
    setFormData(data);

  setErrorObject({ ...errorObject, [name]: null });
};

const filterFandleChange = (e) => {
  setItemGroupId(e) ;
};


const handleReset = () => {
  setFormData({
    OrderDate: "",
    OrderNo: "" ,
    ApprovedBy: "", 
    OrderByName:""
  });
};

const validateForm = (formData) => {
console.log("foo",formData);
  let validateFields = ["OrderDate", "OrderNo","OrderingFromText","OrderTypeId", "OrderBy", "ApprovedBy"]
  let errorData = {}
  let isValid = true
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = "field is Required !"
      isValid = false
    }
  })
  setErrorObject(errorData);
  return isValid
}

// const handleUpdate = async (e) => {
//   if (validateForm(formData)) {
//   let FinalData={...formData,'ManyJsonSave':ManyTableData}; 
//   mutateUpdate(FinalData); 
//   }
// };

/*
const handlePost = async (rows) => {  

  if (validateForm(formData)) {
   
   
   swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willAction) => {
    if (willAction) {

      let FinalData={...formData,'ManyJsonSave':rows,"IsCompleted":1,'UserName':UserName}; 
     mutateUpdate(FinalData);
     setManyTableData(rows);
    }
  });
    
  }
 
};

*/

function checkValidateForm(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function successSave(bStockUpdated){
  if(bStockUpdated !==1 ){
    refetch();
  }
};

function stockPosted(val){
  setbCompleted(val);
};


useEffect(() => {
  refetch();
 
}, [ItemGroupId]);


  return (
    <>
      <OrderApprovalFormData 
      errorObject={errorObject} 
      addProductForm={false} 
      formData={formData} 
      handleChange={handleChange} 
      filterFandleChange={filterFandleChange} 
      handleReset={handleReset} 
      // handleUpdate={handleUpdate}  
      ManyTableData={ManyTableData} 
      handleDataTableChange={handleDataTableChange} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate}
      bCompleted={bCompleted} 
      dirty={dirty} 
      isFirstPhysical={isFirstPhysical}
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      //handlePost={handlePost}
      generatedInvoiceNo={generatedInvoiceNo} 
      bFirst={bFirst}

      refetch={refetch}
      checkValidateForm={checkValidateForm}
      successSave={successSave}
      stockPosted={stockPosted}
      {...props} />
    </>
  );
};

export default EditApproveOrder;