import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import * as Service from "../../../services/Service.js";
import { useMutation } from "react-query";
import * as api from "../../../actions/api";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";

const ShipmentEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ValidateProductForDispensing,
  ...props
}) => {
      //get DispensingLanguage
    const DispensingLanguage = JSON.parse(
      localStorage.getItem("DispensingLanguage")
    );
    const lan = localStorage.getItem("LangCode");
    const menukey = "shipment-entry";
  const classes = useStyles();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const GroupList = JSON.parse(localStorage.getItem("GroupList"))

  const fundingsourceData = JSON.parse(localStorage.getItem("fundingsourceData"));
  
  const getShipmentStatus = JSON.parse(localStorage.getItem("getShipmentStatus"));

  const getCWRWList = JSON.parse(localStorage.getItem("getCWRWList"));

  // const [ItemGroupId, setItemGroupId] = useState(9);
//console.log("ItemGroupId",ItemGroupId);


  let TempItemList = JSON.parse(localStorage.getItem("getshipmentItem"));
  // console.log('get Groupd item',TempItemList)
  const [ItemList, setItemListLocal] = useState(TempItemList);


  // React.useEffect(() => {
  //   cItemList();
  // }, [ItemGroupId]);


  // const cItemList = () => {

  //   let ItemListParam = { action: "ItemListWithItemCode", ItemGroupId: ItemGroupId, menukey: "" }

  //   Service.default
  //     .postApi("source/combo_generic.php", ItemListParam)
  //     .then((res) => {
  //       setItemListLocal(res.datalist);
  //     })
  //     .catch((err) => { });

  // }

  const { mutate: cProductSubGroupList } = useMutation(

    api.aShipmentItemsList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setItemListLocal(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );



  const GrouphandleChange = (event) => {
    
    // setFirstLoad(true);
    // setLoading(false);

    let ProductGroupParam = { action: "ItemListWithItemCode", ItemGroupId: event.target.value, menukey: "" }
    cProductSubGroupList(ProductGroupParam);
    //formData["ItemGroupId"] = event.target.value;
    // setItemGroupId(event.target.value);
   

   
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Shipment Entry"]} -{" "}
                {addProductForm
                  ? DispensingLanguage[lan][menukey]["Add"]
                  : DispensingLanguage[lan][menukey]["Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Submit"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/shipment-entry")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/shipment-entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="demo-simple-select-helper-label">
                  {DispensingLanguage[lan][menukey]["Product Group"]}*
                </InputLabel>
                <Select
                  error={errorObject.ItemGroupId}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="ItemGroupId"
                  value={formData.ItemGroupId}
                  fullWidth
                   onChange={(e) => {handleChange(e);GrouphandleChange(e);}}
                  // onChange={GrouphandleChange}
                >
                  {GroupList.map((item, index) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                {/* <FormHelperText error={errorObject.ItemGroupId}>
                  {errorObject.ItemGroupId}
                </FormHelperText> */}
              </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="demo-simple-select-helper-label">
                  {DispensingLanguage[lan][menukey]["Product Name"]}*
                </InputLabel>
                <Select
                  error={errorObject.ItemNo}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="ItemNo"
                  value={formData.ItemNo}
                  fullWidth
                  onChange={(e) => handleChange(e)}
                >
                  {ItemList.map((item, index) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText error={errorObject.ItemNo}>
                  {errorObject.ItemNo}
                </FormHelperText>
              </FormControl>
          </Grid>



          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="demo-simple-select-helper-label">
                  {DispensingLanguage[lan][menukey]["Funding Source"]}*
                </InputLabel>
                <Select
                  error={errorObject.FundingSourceId}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="FundingSourceId"
                  value={formData.FundingSourceId}
                  fullWidth
                  onChange={(e) => handleChange(e)}
                >
                  {fundingsourceData.map((item, index) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText error={errorObject.FundingSourceId}>
                  {errorObject.FundingSourceId}
                </FormHelperText>
              </FormControl>
          </Grid>

        


          {/* New row */}
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Shipment Status"]}*
              </InputLabel>
              <Select
                error={errorObject.ShipmentStatusId}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="ShipmentStatusId"
                value={formData.ShipmentStatusId}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {getShipmentStatus.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.ShipmentStatusId}>
                {errorObject.ShipmentStatusId}
              </FormHelperText>
            </FormControl>
          </Grid>


                <Grid item xs={4} sm={3}>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            //disabled={bStockUpdate}
                           // required
                            error={errorObject.ShipmentDate}
                            helperText={errorObject.ShipmentDate}
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            id="ShipmentDate"
                            label={DispensingLanguage[lan][menukey]["Date of Expected Receive"]}
                            autoOk={true}
                            name="ShipmentDate"
                            fullWidth
                            showTodayButton={true}
                            disableFuture={true}
                            value={
                              formData.ShipmentDate || null
                            }
                            format="dd/MM/yyyy"
                            onChange={(e) =>
                              props.handleRDateChange(e, "ShipmentDate")
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

          <Grid item xs={3} sm={3}>
            <TextField
              id="Qty"
              name="Qty"
              error={errorObject.Qty}
              label={DispensingLanguage[lan][menukey]["Shipment Quantity"]}
              value={formData.Qty}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <TextField
              id="EntryPort"
              name="EntryPort"
              label={DispensingLanguage[lan][menukey]["Port of Entry"]}
              value={formData.EntryPort}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Destination"]}*
              </InputLabel>
              <Select
                
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="FacilityId"
                value={formData.FacilityId}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {getCWRWList.map((item, index) => {
                  return <MenuItem value={item.FacilityId}>{item.FacilityName}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.FacilityId}>
                {errorObject.FacilityId}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ShipmentEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
