import React, { useState, useEffect,useContext } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from "@material-ui/core";

import {
  Launch
 } from "@material-ui/icons";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import * as Service from "../../services/Service.js";
import Constants from "services/Constants.js";

import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";

const DashboardPage = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-dashboard";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  

  const [NumberOfBrandsGenerics, setNumberOfBrandsGenerics] = useState([]);

  const { data:BrandsGenerics } = useQuery(
    "NumberOfBrandsGenerics",
    api.getNumberOfBrandsGenerics,
    {
      onSuccess: (data) => {
        setNumberOfBrandsGenerics(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [PercentageOfBrandsHaveGTIN, setPercentageOfBrandsHaveGTIN] = useState([]);

  const { data:PercOfBrandsHaveGTIN } = useQuery(
    "PercentageOfBrandsHaveGTIN",
    api.getPercentageOfBrandsHaveGTIN,
    {
      onSuccess: (data) => {
        console.log('data= ', data);
        setPercentageOfBrandsHaveGTIN(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [option_NumberOfActiveDeactiveProducts, setOption_NumberOfActiveDeactiveProducts] = useState([]);

  const { refetch } = useQuery(
    "NumberOfActiveDeactiveProducts",
    api.getNumberOfActiveDeactiveProducts,
    {
      onSuccess: (data) => {
        setOption_NumberOfActiveDeactiveProducts({
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 220,
          },
          title: {
            text: "",
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            series: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                enabled: false
                ,style: {
                  textShadow: false,
                  textOutline: false 
                }
                ,formatter: function () {
                   return Highcharts.numberFormat(this.y);
        
                }
              },
              tooltip: {
                shared:true
              }
            },
            pie: {					
              plotBorderWidth: 0,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
                ,style: {
                  textShadow: false,
                  textOutline: false 
                }
                ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
              },
              showInLegend: true,
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
                shared:true
              }
            }
          },
  
          credits: {
            enabled: false,
          },
          series: [
            {
              name: DispensingLanguage[lan][menukey]["Brands"],
              colorByPoint: true,
              data: data.series_data,
            },
          ],
        })
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [option_BrandByExpiryDate, setOption_BrandByExpiryDate] = useState([]);

  const { data:BrandByExpiryDate } = useQuery("BrandByExpiryDat", api.getBrandByExpiryDate, {
    onSuccess: (data) => {

      setOption_BrandByExpiryDate({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 420,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        },
        series: [
          {
            name: DispensingLanguage[lan][menukey]["Brands"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });
  
  const [option_PercentageOfBrandsByCountry, setOption_PercentageOfBrandsByCountry] = useState([]);

  const { data:PercentageOfBrandsByCountry } = useQuery("PercentageOfBrandsByCountry", api.getPercentageOfBrandsByCountry, {
    onSuccess: (data) => {

      setOption_PercentageOfBrandsByCountry({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 290,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        },
        series: [
          {
            name: DispensingLanguage[lan][menukey]["Brands"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  const [TopTenFabriquant, setTopTenFabriquantt] = useState([]);

  const { data:TopFiveFabr } = useQuery(
    "TopTenFabriquant",
    api.getTopTenFabriquant,
    {
      onSuccess: (data) => {
        setTopTenFabriquantt(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      headerFilter:false,
      field: "IntituleFabriquant",
    },{
      title: DispensingLanguage[lan][menukey]["Number of Brands"],
      headerFilter:false,
      field: "TotalBrands",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
  ];

  const goToBrandLicenseExpiryDate = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/brand-license-expiry-date`
    );
  }
  
  const goToNumberOfBrands = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/brands-list`
    );
  }

  const goToPercentageOfBrandsByCountry = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/brands-list`
    );
  }

  const goToTopTenFabriquant = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/brands-list`
    );
  }

  const current = new Date();
  const currentDate = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;

  return (
    <>
     <AfterLoginNavbar {...props} />
     <div className={`section signup-top-padding ${classes.Container}`}>
      <div className={classes.PageTitle}>
 
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
               {DispensingLanguage[lan][menukey]["Product Dashboard"]}  (Date: {currentDate})
              </div>
            </div>
          </Grid>
        </div>

          {/* new row */}
          <div className="row">
          
            <div className="col-md-6 mb-4">
              <Card className="sw_card mb-2">
                <CardContent>
                  <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="stat-cell bgPrimary">
                          <i className="fa fa-file bg-icon"></i>
                          <span className="text-xlg">{ NumberOfBrandsGenerics.TotalBrands }</span>
                          <br></br>
                          <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Brands"]}</span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="stat-cell bgInfo">
                          <i className="fa fa-file bg-icon"></i>
                          <span className="text-xlg">{ NumberOfBrandsGenerics['TotalGenerics'] }</span>
                          <br></br>
                          <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Generics"]}</span>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-6">
                        <div className="stat-cell bgWarning">
                          <i className="fa fa-file bg-icon"></i>
                          <span className="text-xlg">{ PercentageOfBrandsHaveGTIN }</span>
                          <br></br>
                          <span className="text-bg">{DispensingLanguage[lan][menukey]["Percentage of Brands have GTIN"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                  </div>
                </CardContent>
              </Card>

              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Number of Brands"]}
                  action={
                    <a href="javascript:void(0);" className="gotoBtn" onClick={goToNumberOfBrands}><Launch/></a>
                    }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={option_NumberOfActiveDeactiveProducts}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card> 
            </div>
            
            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Brand License Expiry Date"]}
                  action={
                    <a href="javascript:void(0);" className="gotoBtn" onClick={goToBrandLicenseExpiryDate}><Launch/></a>
                    }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={option_BrandByExpiryDate}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>  
            </div>

          </div>
          {/* end of row */}

          {/* new row */}
          <div className="row">

            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Percentage of Brands by Country"]}
                  action={
                    <a href="javascript:void(0);" className="gotoBtn" onClick={goToPercentageOfBrandsByCountry}><Launch/></a>
                    }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={option_PercentageOfBrandsByCountry}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card> 
            </div>
            
            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Top Ten Fabriquant"]}
                  action={
                    <a href="javascript:void(0);" className="gotoBtn" onClick={goToTopTenFabriquant}><Launch/></a>
                    }
                />
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={TopTenFabriquant}
                    // height={600}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card> 
            </div>

          </div>
          {/* end of row */}

        </div>
      </div>
    </>
  );
};

export default DashboardPage;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});