import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import RecipientsFormData from "./AdjustmentTypeFormData.js";


const AddAdjustmentType = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const LangCode = localStorage.getItem("LangCodeforadjtype");

const menukey = "adjustment-type-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;
const StoreId = localStorage.getItem("StoreId");
  const [formData, setFormData] = useState({
    StoreId:StoreId,
    LanguageText: "",
    PickListMethod: "",
    FLevelIds: "",
    LinkAdjTypeId:"",
    IsPositive: false,
    isStockTake: false,
    isExpired: false,
    TranslationId:"",
    UserName:UserName,
    LangCode:LangCode,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  // useQuery(
  //   ["supplier", id],
  //   () =>
  //     api.getSupplierData(id).then((res) => {
  //       setFormData(res.data);
  //     }),
  //   {
  //     enabled: Boolean(id),
  //   }
  // );

  const { isLoading, mutate } = useMutation(
    api.saveAdjustmentType,
     {
    onSuccess: (data) => {
      if(data.status==200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("adjustmenttype");
      props.history.push("/adjustment_type");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    // console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      StoreId:"",
      LanguageText: "",
      PickListMethod: "",
      FLevelIds: "",
      LinkAdjTypeId:"",
      IsPositive: false,
      isStockTake: false,
      isExpired: false,
      TranslationId:"",
   
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["StoreId","LanguageText","PickListMethod","FLevelIds"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    // console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <RecipientsFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddAdjustmentType;
