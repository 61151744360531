import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { addDays, subDays } from "date-fns";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

import {
  Typography,
  TextField,
  Card,
  InputLabel,
  CardHeader,
  CardContent,
  Select,
  FormLabel,
  MenuItem,
  FormControl,
  Grid,
  Hidden,
   
} from "@material-ui/core";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from "reactstrap";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";


import { getDefaultMonthYear } from "../../../services/Common";

import { useTranslation } from "react-i18next";


import { DeleteOutline, Edit, ViewList,DynamicFeed } from "@material-ui/icons";


const Snigs = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation();
  const menukey = "snigs-logistics-report-a7";

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")): 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const FacilityId = localStorage.getItem("FacilityId");

  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  
  const Item_List = JSON.parse(localStorage.getItem("Itemlist"));

  let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All Product"])};
  const Itemlist = [All_Item_label].concat(Item_List); 
  const [currPorductId, setcurrPorductId] = useState(0);

/* start new filter */

const [toggleButtonValue, setToggleButton] = React.useState("0");
//console.log(toggleButtonValue);

//change functions button

const handleChangeToggle = (event, tmpToggleButtonValue) => {
  if (tmpToggleButtonValue) {
    //console.log(tmpToggleButtonValue);
    setToggleButton(tmpToggleButtonValue);
  }
};

const [activedep, setDepartment] = useState({ id: '0', name: "All" });
  
const [activecommun, setCommune] = useState({ id: '0', name: "All" });


  //const Regionlist = JSON.parse(localStorage.getItem("RegionlistByUser"));

  const Regionlist_Name = JSON.parse(localStorage.getItem("RegionlistByUser"));
  let All_Regionlist_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Regionlist = [All_Regionlist_Name].concat(Regionlist_Name);

  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  // console.log('Zone Data Id',currZoneId);

  const [currCommuneId, setcurrCommuneId] = useState(0);
  // console.log('Commune Entry Id',currCommuneId);
  let TempZoneList = JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState([]);

  let TempCommuneList = JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState([]);

  let TempFacilitylist = JSON.parse(localStorage.getItem("FacilityList"));
  let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All Facility"])};
  const Facility_List = [All_Facility_label].concat(TempFacilitylist); 
  const [Facilitylist, setFacilitylist] = useState(Facility_List);
  const [currFacilityId, setCurrFacilityId] = useState(0);

 
  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) { 

          setZoneListLocal(data.data.datalist);
          setDepartment({ id: '0', name: "All" });
          setCommune({ id: '0', name: "All" });
         // console.log("Dta list", data.data.RegionId);

        //  let CParam = {action:"gCommunelist",RegionId:data.data.RegionId,ZoneId:0,menukey:""}
        //   cCommuneList(CParam);
  
                  
        }
        
      },
    }
  );


  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) { 

          setCommunelist(data.data.datalist);
         
          let FParam = {action:"gFacilitylist",RegionId:data.data.RegionId,ZoneId:data.data.ZoneId,CommuneId:0,menukey:""}
          setCommune({ id: '0', name: "All" });
          
          
        }
        
      },
    }
  );

  


  const handleRegionChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
      //rowName = newValue.name;
    }

    if (rowId !== currRegionId) {
      setcurrRegionId(rowId);
      setcurrZoneId(0);
      setcurrCommuneId(0);
      setCommunelist([]);
    }

    setCurrFacilityId(0);
    let ZoneParam = {action:"gZonelistByUser",RegionId:newValue.id,UserId:UserId,UserRole:UserRole, menukey:""}
    cZoneList(ZoneParam);
    //setcurrRegionId(event.target.value);
  };

  const handleZoneChange = (event,newValue) => {
    setDepartment(newValue);
    let rowId = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }
    if (rowId !== currZoneId) {
      setcurrZoneId(rowId);
      setcurrCommuneId(0);
      setCurrFacilityId(0);
    }
  
    let CommuneParam = {action:"gCommunelistbyUser",RegionId:currRegionId,ZoneId:newValue.id,countryId:1, UserId:UserId,UserRole:UserRole,menukey:""}
    cCommuneList(CommuneParam);
    
  };


  const handleCommuneChange = (event,newValue) => {
    setCommune(newValue);
    let rowId = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }
    if (rowId !== currZoneId) {
      setcurrCommuneId(rowId);

    }
    setCurrFacilityId(0);
    let FacilityParam = {action:"gFacilitylist",RegionId:currRegionId,ZoneId:currZoneId,CommuneId:newValue.id,menukey:""}
    


    //setcurrCommuneId(event.target.value);
  };

  
 /*  const handleFacilityChange = (event) => {

    setCurrFacilityId(event.target.value);
  }; */


const [acValue, setACValue] = useState({ id: '0', name: t(DispensingLanguage[lan][menukey]["All Facility"])});

const handleFacilityChange = (val) => {
  setACValue(val);
  if(val != null){
  //formData.IssuedTo = val.id;
  setCurrFacilityId(val.id);


  }
  
  
};



/* const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if(newValue == null){
      rowId = '';
    }else{
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
 };  */


 /* end new filter */

  //exporting(Highcharts); 
  const classes = useStyles();

  const [pickerData, setPickerData] = useState([{
      startDate: subDays(new Date(), 29),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );



    // Year Change
    const handleYearChange = (event, newValue) => {
      let rowId = '';
  
      if (newValue == null) {
        rowId = '';
      } else {
        rowId = newValue.id;
      }
      if (rowId !== currYearId) {
        setCurrYearId(rowId);
  
  
  
      }
    };


  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currMonthId) {
      setCurrMonthId(rowId);
     // setMonthName(rowName);


    }
  };
 
 

  /*
  let params1 = {
    action: "getFacilityListWithMonthStatus",
    menukey: menukey,
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    Month: currMonthId,
    Year: currYearId,
    UserId:UserId,
    UserName:UserName
  };
  const [dataItems, setDataItems] = useState([]);
  const { refetch } = useQuery([params1], api.getFacilityListWithMonthStatus, {
    onSuccess: (data) => {
      setDataItems(data);
	},
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
  });

  */

  const [dataItems, setDataItems] = useState([]);
  const getFacilityListWithMonthStatus = useQuery(
    ["MasterGrid"],
    () => api.getFacilityListWithMonthStatus(currRegionId,currZoneId,currCommuneId,currMonthId,currYearId,currFacilityId,toggleButtonValue),
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
      
    }
  );


  const [PanelItems, setPanelItems] = useState([]);
  const callPanelBlockDataRequest = useQuery(
    ["PanelBlock"],
    () => api.callPanelBlockDataRequest(currRegionId,currZoneId,currCommuneId,currMonthId,currYearId,currFacilityId),
    {
      onSuccess: (data) => {
        setPanelItems(data);
        console.log("panel view",data.SHOWALL)
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
      
    }
  );


  

  

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };

  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
  }, []);

  const { mutate:mutateUpdate } = useMutation(api.SubmitSnigsData, {
    onSuccess: (data) => {
      if (data.status == 200) {

        getFacilityListWithMonthStatus.refetch();
      //refetch();
   
      //queryClient.getQueriesData("SnigsLogApi");
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
        duration: 10000,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });  
           
        }
    },
  });

  const SubmitSnigs = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Msg_Submit"
        ]
      ),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willDelete) => {
      if (willDelete) {

          let FinalData={...data,menukey: menukey, lan: lan, UserName:UserName, UserId:UserId,
            pRegionId:currRegionId,pZoneId:currZoneId,pCommuneId:currCommuneId,MonthId:currMonthId,YearId:currYearId}; 
          mutateUpdate(FinalData); 
        //mutate(data.id);
      }
    });
  };


  const PublishSnigs = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Msg_Publish"
        ]
      ),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willDelete) => {
      if (willDelete) {

          let FinalData={...data,menukey: menukey, lan: lan, UserName:UserName, UserId:UserId,
            pRegionId:currRegionId,pZoneId:currZoneId,pCommuneId:currCommuneId,MonthId:currMonthId,YearId:currYearId}; 
          mutateUpdate(FinalData); 
        //mutate(data.id);
      }
    });
  };

  
  function SubmitPopup(propss: any) {
    const rowData = propss.cell._cell.row.data; 
   
      if(rowData.StatusId==1){
        if(UserRole ==10 ||UserRole ==2){
         return (
          <>
          </>
         )
        }else{
          return (
            <>
            <DynamicFeed
                onClick={() => {
                  SubmitSnigs(rowData);
                  //setCurrRowId(rowData.id);
                  //handleModal("PaymentDateOpen");
                }}
              />
          
              
            </>
          );
        }
     
          }
      else{
        //if(rowData.SubmittedBy!=null){
      return (<>
      
      {rowData.LastSubmittedDt}<br></br>{rowData.SubmittedBy}
      
      </>);
        //}
      }

  }


  function PublishPopup(propss: any) {
    const rowData = propss.cell._cell.row.data; 
   
      if(rowData.StatusId==2){

        if(UserRole ==11 ||UserRole ==2){
          return (
           <>
           </>
          )
         }else{
          return (
            <>
              <DynamicFeed
                onClick={() => {
                  PublishSnigs(rowData);
                  //setCurrRowId(rowData.id);
                  //handleModal("PaymentDateOpen");
                }}
              />
            </>
          );
         }
        
     
          }
      else{
        //if(rowData.SubmittedBy!=null){
      return (<>
      
      {rowData.PublishedDt}<br></br>{rowData.PublishedBy}
      
      </>);
        //}
      }

  }
  

  const columns = [
   /*  {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    }, */
    {
      title: DispensingLanguage[lan][menukey]["Facility ID"],
      field: "FacilityId",
      width: 80,
      headerFilter: true,
      visible: false,
    },
    {
      title: "CFMStockId",
      field: "CFMStockId",
      width: 110,
      headerFilter: true,
      visible: false,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      width: 180,
      headerFilter: true,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "DistrictName",
      headerFilter: true,
      width: 180,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerFilter: true,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["DHIS2 Last Updated User/Date"],
      field: "Dhis2UserStatus",
      width: 220,
      formatter:"html",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },

    {
      title: DispensingLanguage[lan][menukey]["Entry"],
      field: "EntryStatus",
      width: 200,
      formatter:"html",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Submit"],
      //field: "SubmitStatus",
      field: "custom",
      width: 200,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      //formatter:"html",
      formatter: reactFormatter(<SubmitPopup />),
    },
    /*
    {
      title: "S",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 7,
      formatter: reactFormatter(<SubmitPopup />),
      visible:false,
    },
    */

    {
      title: t(DispensingLanguage[lan][menukey]["Publish"]),
      //field: "PublishStatus",
      field: "custom",
      width: 200,
      //formatter:"html",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      formatter:reactFormatter(<PublishPopup />),
    },
    {
        title: DispensingLanguage[lan][menukey]["Action"],
        field: "custom",
        headerSort: false,
        hozAlign: "center", 
        headerHozAlign: "center", 
        width: 100,
        formatter: reactFormatter(<ActionButton />),
    },

   
  ];

  const editReceiveInv = (data) => {
    let route = `/snigs_logistics_report_a7/edit/${data.id}`;
    props.history.push(route);
  };


  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.CFMStockId)
      return (
        <>
          <Edit
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
          
        </>
      );
    else
      return (<></>);
  }

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ProductsNearingExpiryReportExcelExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") + 
        "&TimeStamp=" + Date.now() + 
        "&RegionId=" + currRegionId +
        "&ZoneId=" + currZoneId +
        "&CommuneId=" + currCommuneId +
        "&ItemNo=" + currPorductId +
        "&FacilityId=" + currFacilityId
    );
  };
  /* =====End of Excel Export Code==== */


  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      getFacilityListWithMonthStatus.refetch();
      callPanelBlockDataRequest.refetch();
    }
  }, [currRegionId,currZoneId,currCommuneId,currMonthId, currYearId,currFacilityId]);

  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      getFacilityListWithMonthStatus.refetch();
    }
  }, [toggleButtonValue]);

  return (
    <>
     <div className={classes.ReceivePageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["SNIGS Logistics Report A7"]}
              </div>
            </div>
          </Grid>
        </div>

      

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container spacing={1}>
                

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>

                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="RegionId"
                      disableClearable
                      options={Regionlist}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      //disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleRegionChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Regionlist[Regionlist.findIndex(Regionlist => Regionlist.id == currRegionId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department"]}
                          variant="standard"

                        />
                      )}
                    />

                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ZoneId"
                      disableClearable
                      options={Zonelist}
                      value={activedep}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS"]}
                          variant="standard"

                        />
                      )}
                    />


                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DistrictId"
                      disableClearable
                      options={Communelist}
                      value={activecommun}
                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune"]}
                          variant="standard"

                        />
                      )}
                    />


                </FormControl>
              </Grid>

                


               
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                      <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currMonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                          />
                          )}
                        />

                  </FormControl>
                </Grid>

                
                <Grid item xs={12} sm={12}>

                    <ToggleButtonGroup
                        className="sw_ToggleButtonGroup2"
                        color="primary"
                        value={toggleButtonValue}
                        exclusive
                        onChange={handleChangeToggle}
                      >
                       <ToggleButton value="0">
                        {PanelItems.SHOWALL}<br></br> {DispensingLanguage[lan][menukey]['SHOW ALL']}
                        </ToggleButton>

                        <ToggleButton value="99">
                        {PanelItems.WFENTRY}<br></br> {DispensingLanguage[lan][menukey]['WAITING FOR ENTRY']}
                        </ToggleButton>

                        <ToggleButton value="1">
                        {PanelItems.WFSUBMIT}<br></br> {DispensingLanguage[lan][menukey]['WAITING FOR SUBMIT']}
                        </ToggleButton>

                        <ToggleButton value="2">
                        {PanelItems.WFPUBLISH}<br></br> {DispensingLanguage[lan][menukey]['WAITING FOR PUBLISH']}
                        </ToggleButton>

                        <ToggleButton value="5">
                        {PanelItems.PUBLISHED}<br></br> {DispensingLanguage[lan][menukey]['PUBLISHED']}
                        </ToggleButton>
                       
                        
                      </ToggleButtonGroup>

                </Grid>




                
                
                

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          
        <div className="sw_relative">
          
          <div className="uniqueName">
          <ReactTabulator
            columns={columns}
            data={dataItems}
            layout={"fitColumns"}
            options={{
            groupBy: "DistrictName",
            columnCalcs: "both",
            }}
            height={410}
        //   rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
        />
          </div>
        </div>

             

                


              
        </div>
      
    </>
  );
};

export default Snigs;

const useStyles = makeStyles({
    ReceivePageTitle: {
      marginTop: "60px",
      color: "white",
      background: "whitesmoke",
      color: "black",
      borderRadius: "10px",
      padding: "1rem",
    },
    tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
    fullWidth: {
      width: "95%",
    },
    filterDiv: {
      width: "80%",
      display: "flex",
    },
  });
