
import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Typography, Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Checkbox, TextField } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone, ViewList } from "@material-ui/icons";

import * as Service from "../../../services/Service.js";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const ProductsList = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "generics-list";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dataItems, setDataItems] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [Strength, setStrength] = React.useState(true);
  const [StrengthList, setStrengthList] = useState([]);
  const [currStrengthId, setStrengthId] = useState(0);

  const [Packsize, setPacksize] = React.useState(true);
  const [PacksizeList, setPacksizeList] = useState([]);
  const [currPacksizeId, setPacksizeId] = useState(0);

  const [DosageForm, setDosageForm] = React.useState(true);
  const [DosageFormList, setDosageFormList] = useState([]);
  const [currDosageFormId, setDosageFormId] = useState(0);

  const [Dci, setDci] = React.useState(true);
  const [DciList, setDciList] = useState([]);
  const [currDciId, setDciId] = useState(0);
  const [GenericCount, setGenericCount] = useState(0);
  const [ProductTableData, setProductTableData] = React.useState(true);

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
  const [currProductGroupId, setProductGroupId] = useState(0);

  let params = {
    menukey: menukey,
    action: "getProductList",
    StrengthId: currStrengthId,
    PacksizeId: currPacksizeId,
    DosageFormId: currDosageFormId,
    DciId: currDciId,
    ItemGroupId: currProductGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

  };
  const handleProductGroupChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    setProductGroupId(rowId);
    refetch();
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getGenericlist,
    {
      onSuccess: (data) => {
        setDataItems(data.datalist);
        setLoading(false);
        setGenericCount(data.datalist.length);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  // const getProductsData = () => {
  //     Service.default
  //         .postApi("source/api/api_pages/productList.php", params)
  //         .then((res) => {
  //           setDataItems(res.datalist);
  //           setLoading(false);
  //           setGenericCount(res.datalist.length);
  //         })
  //         .catch((err) => {});
  // }

  if (ProductTableData) {
    setProductTableData(false);
    refetch();
  }

  if (Strength) {
    setStrength(false);
    let ParamList = { action: "getStrengthList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setStrengthList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleStrengthChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: rowId,
      PacksizeId: currPacksizeId,
      DosageFormId: currDosageFormId,
      DciId: currDciId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setStrengthId(rowId);
    refetch();
  };

  if (Packsize) {
    setPacksize(false);
    let ParamList = { action: "getPacksizeList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setPacksizeList(res.datalist);
      })
      .catch((err) => { });
  }

  const handlePacksizeChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: rowId,
      DosageFormId: currDosageFormId,
      DciId: currDciId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setPacksizeId(rowId);
    refetch();
  };

  if (DosageForm) {
    setDosageForm(false);
    let ParamList = { action: "getDosageFormList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setDosageFormList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleDosageFormChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId: rowId,
      DciId: currDciId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDosageFormId(rowId);
    refetch();
  };

  if (Dci) {
    setDci(false);
    let ParamList = { action: "getDciList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setDciList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleDciChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId: currDosageFormId,
      DciId: rowId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDciId(rowId);
    refetch();
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=ProductsListExport" +
      "&reportType=" + reportType +
      "&menukey=" + menukey +
      "&lan=" + localStorage.getItem("LangCode") +
      "&StrengthId=" + currStrengthId +
      "&PacksizeId=" + currPacksizeId +
      "&DosageFormId=" + currDosageFormId +
      "&DciId=" + currDciId
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    viewProduct(rowData);
  };

  const viewProduct = (data) => {
    let route = `generics-list/view/${data.ItemNo}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <ViewList
          onClick={() => {
            viewProduct(rowData);
          }}
        />
      </>
    );
  }

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 45,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen: true,
    },
    {
      title: "",
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 40,
      frozen: true,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic"],
      field: "ItemName",
      headerFilter: true,
      // width: 450,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Strength"],
      field: "StrengthName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pack Size"],
      field: "PacksizeName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Form"],
      field: "DosageFormName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["DCI"],
      field: "DciName",
      width: 250,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 100,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Code"],
      field: "SOBAPSCode",
      width: 100,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["eSIGLProductId"],
      field: "eSIGLItemNo",
      width: 100,
      headerFilter: true,
    }
  ];

  return (
    <>
      <div className={classes.productPageTitle}>

        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Generics List"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    id="ItemGroupId"
                    name="ItemGroupId"
                    options={ProductGroupList}
                    onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    id="DciId"
                    name="DciId"
                    options={DciList}
                    onChange={(event, newValue) => handleDciChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["DCI"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button> */}

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>


              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="StrengthId"
                        name="StrengthId"
                        options={StrengthList}
                        onChange={(event, newValue) => handleStrengthChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Strength"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="PacksizeId"
                        name="PacksizeId"
                        options={PacksizeList}
                        onChange={(event, newValue) => handlePacksizeChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Pack Size"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="DosageFormId"
                        name="DosageFormId"
                        options={DosageFormList}
                        onChange={(event, newValue) => handleDosageFormChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Form"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <div className="float-right">
                      <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Generic'] + ": " + GenericCount}</h4>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
            {isLoading && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              height={450}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsList;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
