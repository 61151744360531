import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import * as api from '../../../actions/api';
import {
  Grid,
  Typography,
  TextField, 
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel, 
  MenuItem, 
  FormGroup,
  Card,
  CardContent,
  FormHelperText
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'


import {
  DeleteOutline,
  Edit,
  ViewList,
  FileCopy
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';


import "../common.css";


import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar"; 
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import ProductsModal from '../../../services/ProductsModal'; 
import WarehouseInvoiceModal from '../../../services/WarehouseInvoiceModal'; 

import * as ScanBarcode from '../../../services/ScanBarcode';

import { cellFocusEditor, dateEditor } from "../../../services/Common";
import OrderModal from './OrderModal';

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = '';


let isDirty=false; 
let deletedDatas=[];
let transactionItemID =0;
let masterDirty=false;

const ReceiptsItems = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receipts";
const FacilityId=localStorage.getItem('FacilityId');
const FacilityName = localStorage.getItem("FacilityName");

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;



  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef =null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  //console.log("Set r batch");
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [openWarehouseInvoice, setWarehouseInvoice] = useState(false);


  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false); 

  const [bDownloadIssueInvoice, setbDownloadIssueInvoice] = useState(false);
  

  const getFacilityListNotEqual99ANDSupplier =JSON.parse(localStorage.getItem("getFacilityListNotEqual99ANDSupplier"));

  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);
  // Store
  const StoreId = localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const StoreList = JSON.parse(localStorage.getItem("getStore"));

  const [timeStamp, setTimeStamp] = useState('');

  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_receive_from_warehouse_entry_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_receive_from_warehouse_entry_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  
  
  const handleClose = (modalname) => {
    switch (modalname) {


      

      case "Products":
        setOpen(false);
        break;

      case "ProductsOpen":
        setOpen(true);
        break;

      case "OrderModal":
          setOpenOrder(false);
          break;

      case "OrderOpen":
          setOpenOrder(true);
          break; 

      case "WarehouseInvoiceClose":
        masterDirty=false;
        setWarehouseInvoice(false);
        break;

      case "WarehouseInvoicOpen":
        let modal_open = props.handleModalCheck();
       // alert(modal_open);
       // console.log("Modal opensssssss",modal_open.Promise)

        if(modal_open)
        setWarehouseInvoice(true);
         break; 

     

      default:
        break;
    }
  }
  
  const addProduct = (NonOrderProductData) => {  
 
    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;
      newRow.InvFrom ='';
      
      newRow.ItemCode =row.ItemCode;
    
      newRow.ItemName =row.ItemName;
      newRow.UnitPrice =row.UnitPrice;

      
      


      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      manyTableData.forEach((rowssss,ij) => {
       
        if(rowssss.ItemNo==row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;

        } 
      });

      // Brand Info
      newRow.BrandNo =row.BrandNo;
      newRow.BrandCode =row.BrandCode;
      newRow.CommercialName =row.CommercialName;
      newRow.EAGTIN =row.EAGTIN;
      newRow.PKGTIN =row.PKGTIN;
      newRow.CAGTIN =row.CAGTIN;
      newRow.PLGTIN =row.PLGTIN;
      newRow.SSCCCarton =null;
      newRow.SSCCPallet =null;
     
      newRow.CurrentStockQty = 0;
      newRow.Quantity =0;
      newRow.BatchNo = null;
      newRow.ExpiryDate = null;
      newRow.SKU=autoId;
      newRow.id=autoId;  
      newRow.LineTotal=0; 
      rows.push(newRow);
    
  });

   setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":rows});
    //setUpdatedDetailsGridDataHandler(rows); 
 
    handleClose('Products');
  }; 

  const addOrder = (OrderProductData) => {
    props.refetch();
    handleClose('OrderModal');
    //editReceiveInv(OrderProductData);
  };


  const addWarehouseInvoice = (WarehouseInvoiceData) => {
    props.handleAddWarehouseInvoice(WarehouseInvoiceData);
    handleClose("WarehouseInvoice");
  };


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false }, 
    { title: "id", field: "id", visible: false }, 
    {
      title: "GroupName",
      field: "GroupName",
      visible: false,
    },

   

    { title: DispensingLanguage[lan][menukey]['SL'],  width: 60, formatter: "rownum", hozAlign: "center", 
    headerHozAlign:"center", headerSort:false  },

    { 
      title: DispensingLanguage[lan][menukey]['Actions'], 
      field: 'custom', 
      headerSort:false, 
      headerHozAlign:"center", 
      hozAlign: 'center',
      width: 65,
     formatter: reactFormatter(<ActionButton />) } ,

     {
      title: "dirty",
      field: "dirty",
      visible: false,
    },

    { title: DispensingLanguage[lan][menukey]['Product_Code'], field: "ItemCode", width: 130 ,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName",headerFilter:true,width: 240},
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 190 ,headerFilter:true},
    { title: DispensingLanguage[lan][menukey]['Brand'], field: 'CommercialName',width:180,headerFilter:true,},

    { title: DispensingLanguage[lan][menukey]['Quantity_Ordered'], field: "OrderedQuantity", width: 100, hozAlign: "right", headerHozAlign:"right"},
    { title: DispensingLanguage[lan][menukey]['Prev_Received_Quantity'], field: "OrderRemainingQty", width: 100, hozAlign: "right", headerHozAlign:"right"},
   
    { title: DispensingLanguage[lan][menukey]['Quantity_Received'], field: "Quantity",  width: 100, hozAlign: "right", headerHozAlign:"right",
      validator:["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor) : '',      
      cellEdited: function (cell) {
        
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        const rowdata = currow.getData();
 
        console.log(rowdata);
         console.log(ReceiveDetailsRef.table.getData());
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();
      
      } 
    },//
    { title: DispensingLanguage[lan][menukey]['Lot_No_Description'], 
    field: "BatchNo",
     width: 100, 
     headerFilter: true,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ?  (props.formData.InvFrom!=''?false: cellFocusEditor): '',      
      cellEdited: function (cell) {
        
        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();
       
     
      }
    },
    { title: DispensingLanguage[lan][menukey]['Expiry_Date'], field: "ExpiryDate" , width: 100,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ?  (props.formData.InvFrom!=''?false: dateEditor): '', 
      cellEdited: function (cell) {
         
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();            
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        transactionItemID=rowdata.id;
        onSetDisable(); 

      }
    },

    
    { 
      title: DispensingLanguage[lan][menukey]['EAGTIN'], field: "EAGTIN", width: 125, headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',    
      editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",  
      cellEdited: function (cell) {

       
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

      }    
   },

   { 
    title: DispensingLanguage[lan][menukey]['PKGTIN'], field: "PKGTIN", width: 115, headerFilter: true,
    cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
    //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',  
    editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",      
    cellEdited: function (cell) {
      
        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

    }    
  },

  { 
    title: DispensingLanguage[lan][menukey]['CAGTIN'], field: "CAGTIN", width: 115, headerFilter: true,
    cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
    //editor: props.bStockUpdate == 0 ? cellFocusEditor: '', 
    editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",       
    cellEdited: function (cell) {

        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

    }    
  },

  { 
    title: DispensingLanguage[lan][menukey]['PLGTIN'], field: "PLGTIN", width: 115, headerFilter: true,
    cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
    //editor: props.bStockUpdate == 0 ? cellFocusEditor: '', 
    editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",       
    cellEdited: function (cell) {
      
        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

    }    
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: "SSCCCarton", width: 125, headerFilter: true,
    cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
    //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',  
    editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",      
    cellEdited: function (cell) {
      
       
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

    }    
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: "SSCCPallet", width: 115,headerFilter: true, 
    cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
    //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',    
    editor: props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor)   : "",    
    cellEdited: function (cell) {
      
        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);
        isDirty=true;
        
        const rowdata = currow.getData(); 
        transactionItemID=rowdata.id;
        onSetDisable();

    }    
  },

  // { 
  //   title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: 'SSCCPallet', 
  //   hozAlign:"left",headerHozAlign:"left",width:115,
  //   cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
  //   editor: props.bStockUpdate == 0 ? (props.bStockUpdate==0?cellFocusEditor:''): '',    
  // },

  


    { title: DispensingLanguage[lan][menukey]['Unit Price (CFA)'], field: "UnitPrice" , width: 100, hozAlign: "right", headerHozAlign:"right", 
      validator:["min:0"],
      cssClass: "tabluator-column-editable",
      editor:  props.bStockUpdate == 0 ? (props.formData.InvFrom!=''?false: cellFocusEditor): '',      
      cellEdited: function (cell) {

        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1);

        const curcellval = cell.getValue();
        
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.Quantity * curcellval;
        pricecell.setValue(totprice);
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();
        
       // props.setUpdatedDetailsGridDataHandler(currow._row.data);
      } 
    },
    { title: DispensingLanguage[lan][menukey]['Line Total (CFA)'], field: "LineTotal" , width: "10%",
      formatter: "money", hozAlign: "right", headerHozAlign:"right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money"
       
    }
    
  ];
   
  

  const onSetDisable = () => { 
    
       if(isDirty==true){ 

         //document.getElementById("FID_AStoreId").classList.add("swDisabled");
         document.getElementById("FID_scanButton").classList.add("swDisabled");
         document.getElementById("is_dirty_message").classList.remove("dnone");
         document.getElementById("FID_scan_gtin_or_brandcode").classList.add("swDisabled");
         document.getElementById("FID_ItemGroupId").classList.add("swDisabled");
         document.getElementById("FID_pick_items_btn").classList.add("swDisabled");

        let dataArray=[];
        let gridData=ReceiveDetailsRef.table.getData();
         
        gridData.forEach((row,i) => { 
         // if(row.dirty==1)
          if(row.id==transactionItemID) {
            dataArray.push(row);
            transactionItemID=0;
          }
          
        });
       
        let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan, UserName:UserName};  
        mutateCellUpdate(fDtat);
         
       }
       else{

        //document.getElementById("FID_AStoreId").classList.remove("swDisabled");
        document.getElementById("FID_scanButton").classList.remove("swDisabled");
        document.getElementById("is_dirty_message").classList.add("dnone");
        document.getElementById("FID_scan_gtin_or_brandcode").classList.remove("swDisabled"); 
        document.getElementById("FID_ItemGroupId").classList.remove("swDisabled");
        document.getElementById("FID_pick_items_btn").classList.remove("swDisabled");
        
       }
       
    }

    const onMasterSetDirty = () => { 
    
      if(masterDirty==true){ 
        props.formData.masterDirty=masterDirty;
        document.getElementById("master_dirty_message").classList.remove("dnone");
    
          
      }else{ 
        props.formData.masterDirty=masterDirty;
        document.getElementById("master_dirty_message").classList.add("dnone");
       
      }
      
    }

    const mutateCellUpdate=(fDtat) => { 
  
      const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null; 
        const apiUrl = process.env.REACT_APP_API_URL + "source/api/receiveUpdate";
        let data=fDtat;
        
          fetch(apiUrl, {
            method: "PUT", 
            headers: {
                
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${auth_token}`,
              "Consumer": process.env.REACT_APP_CONSUMER,
              "Secret": process.env.REACT_APP_SECRET,
            
            },
            body: JSON.stringify(data),
          })
          .then(function (res) {
            console.log(res);
           
            return res.json();
          })
          .then(function (data) {
            isDirty=false;
            onSetDisable();  
          })
          .catch(error => {
      
            props.openNoticeModal({
              isOpen: true,
              msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
              msgtype: 0,
            });
          
           });
      
      
       }


         



   const onPostBtnClick = () => { 
    //setbStockUpdate(true); 
    //props.handlePost(ReceiveDetailsRef.table.getData(),deletedDatas);

      setbStockUpdate(true); 
      
      onSetDisable();
      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
       // if(row.dirty==1)
       if(row.id==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
      });
      //props.handlePost(dataArray,deletedDatas);
      handlePost(dataArray,deletedDatas);

  }

  const handlePost = async (rows,deletedsetdata) => {  

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    //if (validateForm(formData,1)) {
       
      //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
      //mutateUpdate(fDtat);

      // Start Post Alert

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
 
          let FinalData={...validForm,'ManyJsonSave':rows,"bStockUpdated":1,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows);
 
        }else{
          //props.setbStockUpdate(0);
          props.stockPosted(0);
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
    }
      // End Post Alert

      
   // }
     
     
  };



  const onDownloadIssueInvoiceBtnClick = () => { 
   // setbDownloadIssueInvoice(true); 
   setUpdatedDetailsGridDataHandler({"datatype":"DownloadData","data":[],'deleteddata':[]}); 
  }






   
 
 const onDownloadIssueInvoiceBtnClickCopy=()=>{
  props.DownloadIssueInvoice();
   masterDirty=false;
   onMasterSetDirty();
 }



  const onSaveUpdateBtnClick = () => { 
   
      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
        //if(row.dirty==1)
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      })
      
    //  setUpdatedDetailsGridDataHandler(dataArray); 
      setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":dataArray});
  
    
  }


  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows.data,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else if(rows.datatype == "ScanData"){
        
          mutateUpdateForScan(validForm); 
        }
        else if(rows.datatype == "DownloadData"){
        
          mutateUpdateForDownload(validForm); 
        }
        
        else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      masterDirty=false;
      onMasterSetDirty();

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  



  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 

        masterDirty=false;
        onMasterSetDirty();
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        props.successSave(data.bStockUpdated);
        if(data.bStockUpdated==1){
         mutatePost(props.formData.TransactionId);
        }
        else{
          props.successSave(data.bStockUpdated); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });
  
  const {mutate:mutateUpdateForScan } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        masterDirty=false;
        onMasterSetDirty(); 
        doActionScanBarcodeCopy();
          props.successSave(data.bStockUpdated); //refetch(); 
         
  
        
  
      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  const {mutate:mutateUpdateForDownload } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        masterDirty=false;
        onMasterSetDirty(); 
        onDownloadIssueInvoiceBtnClickCopy();
          props.successSave(data.bStockUpdated); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
  
        
  
      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  
  

  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.updateStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        //props.setbStockUpdate(1);
        //setDeletedDataSet([]);

        if(data.data.success==0){ 
          
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          props.stockPosted(1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


  

  const deleteReceiveInv = (data) => { 
    let newarray = manyTableData.filter(element => element !== data); 
    setManyTableData(newarray); 
    props.deleteReceiveInv(data); 
  };



  
  const mutatedeleteTransactionItems=(pdata) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/deleteTransactionItems";
      //console.log("ddddd",currTransactionId);
      //let data=fDtat;
      //let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:CurFacility,TransactionId:CurTransactionId};
      let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:props.formData.FacilityId,TransactionId:props.formData.TransactionId};
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "POST", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
          
           
        })
        .catch(error => {

          props.refetch();
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     }  


  



  function ActionButton (props) {  
    const rowData = props.cell._cell.row.data;  

    console.log(rowData.InvFrom);


    const goToDetails = (rowData) => {

      //let rowobj = props.cell._cell.row.data; //{};
      //console.log("I copied this line", rowobj);
      //console.log("I copied this line", props.cell._cell.row);

      //timeStamp = Math.round(new Date().getTime() / 100); 

      let rowobj = rowData; //{};
      let timeStamp = Math.round(new Date().getTime() / 100);
      setTimeStamp(timeStamp);
      let autoId=ReceiveDetailsRef.table.getData().length;


     let tabledata=(prevRows) => {
      autoId++;

        //const tmpPrevRows = [...prevRows];
        const tmpPrevRows =ReceiveDetailsRef.table.getData();
        const max = tmpPrevRows.reduce((prev, current)=> ( (prev.TransactionItemId > current.TransactionItemId) ? prev : current),0) //returns object
        // console.log(max.StockTakeItemsId+1)
   
   

        let setRposition = tmpPrevRows.findIndex((o) => o.TransactionItemId ==rowobj.TransactionItemId);
        //console.log("Rposition",setRposition);

        const obj = { ...rowobj };
      
        /*
        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition+1, 0, obj)
        */
        obj.ItemNo =rowData.ItemNo;
        obj.ItemCode =rowData.ItemCode;
        
        obj.ItemName =rowData.ItemName; 
        obj.TransactionItemId =  max.TransactionItemId+1;  
        obj.SKU=autoId;
        obj.id=timeStamp;   
  
        obj.BatchNo = null;
        obj.ExpiryDate =null;
         
    
        obj.Quantity = null;
     
    
       
        obj.EAGTIN = rowData.EAGTIN;  
        obj.PKGTIN = rowData.PKGTIN;  
        obj.CAGTIN = rowData.CAGTIN;  
        obj.PLGTIN = rowData.PLGTIN;  
        obj.SSCCCarton = rowData.SSCCCarton;  
        obj.SSCCPallet = rowData.SSCCPallet;  
        obj.BrandNo = rowData.BrandNo;  
        obj.dirty = 0;   
        obj.GroupName = rowData.GroupName;
        obj.BrandCode = rowData.BrandCode; 
        obj.CommercialName = rowData.CommercialName; 
        obj.OrderedQuantity=null;
        obj.OrderRemainingQty=null;
        obj.UnitPrice=rowData.UnitPrice;
        obj.LineTotal=0;
        obj.CurrentStockQty=rowData.CurrentStockQty;
             
        tmpPrevRows.splice(setRposition+1, 0, obj);

        return tmpPrevRows;
      }
      ;
      setManyTableData(tabledata);
  
      CopyBatch(tabledata);
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
      

    };
   

    const cellValue = props.cell._cell.value || 'Edit | Show';

     
    if(rowData.InvFrom==''){
    if(bStockUpdate == 0)
    return (
      <>

         <FileCopy
          onClick={() => {
            if (!bStockUpdate) goToDetails(rowData);
          }}
        /> 

        {/* <DeleteOutline
          onClick={() => {
            props.cell.getRow().delete();
            if((rowData.TransactionItemId!=null)||(rowData.TransactionItemId!=-1)){
              let newDeletedDataSet=[rowData.TransactionItemId]; 
              deletedDatas=newDeletedDataSet.concat(deletedDatas);
              isDirty=true;
              onSetDisable();
            
            }

          }}
        /> */}

          <DeleteOutline
            onClick={() => {
              props.cell.getRow().delete();
              mutatedeleteTransactionItems(rowData);
            }}
          />




        
      </>
    );
    else  return (
      <>
         
      </>
    );
    }
    else
    {
      return (
        <>
           
        </>
      );

    }
  }

  
  
  useEffect(() => {
    if (ReceiveDetailsRef == null) return; 
    if(props.bFirst){
      setManyTableData(props.ManyTableData); 
    }
    deletedDatas=[];
    setbStockUpdate(props.bStockUpdate);
    
     
}, [props.ManyTableData,props.bStockUpdate]); //

/*
useEffect(() => {

  isDirty=props.dirty;
  onSetDisable();
  deletedDatas=[];

}, [props.dirty]);

*/


useEffect(() => {
  if (ReceiveDetailsRef.table != null && timeStamp != '') { 
    ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
    ReceiveDetailsRef.table.selectRow(timeStamp);


  }
}, [newBatchRowAdded]); 

const CopyBatch = (tabledata) => {
    
  props.handleAdd(tabledata); 
  
};


// const handleChangeGrp = (e) => {
//   props.filterHandleChange(e.target.value);
//   setCurrItemGroupId(e.target.value);
// };

const handleProductGroupChange = (event, newValue) => {

  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currItemGroupId) {
    if(props.addProductForm==false){
    props.filterHandleChange(rowId);
    }
    setCurrItemGroupId(rowId); 
  }
};

const handleReset = () => {
  setManyTableData([]);  
  props.handleReset();
};

const scan_gtin_or_brandcode = useRef(null);
const handleRefetchCall = () => {
  props.refetch();
  scan_gtin_or_brandcode.current.value = "";
};
// const doActionScanBarcode = () => {
//   let SupplierString = props.formData.ReceiveFrom; 
//   const SupplierArr = (typeof SupplierString === 'string')?SupplierString.split('_'):SupplierString.toString().split('_'); 
//   let SupplierId=0; 
//   if((SupplierArr.length)>1)
// 	 SupplierId = SupplierArr[1];

//   const ScanBarcodeParams = {
//       TransactionTypeId: 1,
//       StoreId: props.formData.StoreId,
//       TransactionId: props.formData.TransactionId,
//       FacilityId: props.formData.FacilityId,
//       FacilityLevelId: 1,
//       ReceiveFromId: SupplierId,
//       AdjTypeId: 0,
//       lan: lan,
//       menukey: menukey,
//       handleRefetchCall: handleRefetchCall,
//       UserId:UserId,
//       UserName:UserName,
//       UserRole:UserRole
//     }
    
//  ScanBarcode.ScanBarcodeAction(ScanBarcodeParams,  {...props}); 
// };




const doActionScanBarcode = () => { 
  setUpdatedDetailsGridDataHandler({"datatype":"ScanData","data":[],'deleteddata':[]});
    
 };
 
 const doActionScanBarcodeCopy=()=>{
  let SupplierString = props.formData.ReceiveFrom; 
  const SupplierArr = (typeof SupplierString === 'string')?SupplierString.split('_'):SupplierString.toString().split('_'); 
  let SupplierId=0; 
  if((SupplierArr.length)>1)
	 SupplierId = SupplierArr[1];

  const ScanBarcodeParams = {
      TransactionTypeId: 1,
      StoreId: props.formData.StoreId,
      TransactionId: props.formData.TransactionId,
      FacilityId: props.formData.FacilityId,
      FacilityLevelId: 1,
      ReceiveFromId: SupplierId,
      AdjTypeId: 0,
      lan: lan,
      menukey: menukey,
      handleRefetchCall: handleRefetchCall,
      UserId:UserId,
      UserName:UserName,
      UserRole:UserRole
    }
    
 ScanBarcode.ScanBarcodeAction(ScanBarcodeParams,  {...props});
 }



const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
     // scan_gtin_or_brandcode.current.value+= String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};


const backToList = () => {
  if(masterDirty==true){

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willAction) => {
      if (willAction) { 
        masterDirty=false;
        props.history.push("/receipts");

      } 
    });

  }else props.history.push("/receipts")
  
};

const formOnchange = (e,cases,inputname) => { 
  console.log("On form change",inputname);
  //isDirty=true;
   masterDirty=true;
   onMasterSetDirty(); 
   if(cases=='handleChange')
   props.handleChange(e);
   else if(cases=='handleRDateChange')
   props.handleRDateChange(e,inputname);

};

const [FormStoreId, setFormStoreId] = useState({ id: '', name: "" });
const [ReceiveFrom, setReceiveFrom] = useState({ id: '', name: "" });
const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });


const changeVal = (event,val,cases) => {
   //console.log(event);
  switch(cases){
    case 'StoreId': 
      setFormStoreId(val);        
      props.formData.StoreId= val != null?val.id:""; 
      break;
    case 'ReceiveFrom': 
      setReceiveFrom(val);
      props.formData.ReceiveFrom= val != null?val.id:"";
      break;
    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
  setFormStoreId(StoreList[StoreList.findIndex(FormStoreList => FormStoreList.id == props.formData.StoreId)]); 
  setReceiveFrom(getFacilityListNotEqual99ANDSupplier[getFacilityListNotEqual99ANDSupplier.findIndex(ReceiveFromList => ReceiveFromList.id == props.formData.ReceiveFrom)]);   
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
    
}, [props.formData.StoreId, props.formData.ReceiveFrom, props.formData.PreparedBy]);

  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

        <div className="sw_page_heading">
            
            
            <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Recieve_from_warehouse_create_edit'] +' ('+FacilityName+')'}</div>
            
            <div className="float-right sw_btn_control">

            {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">

<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>

               <div className="float-right sw_btn_control">

               <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  //onClick={() => props.history.push("/receipts")}
                  onClick={() => backToList()}
                >
                  {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                {/* <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => handleReset()}
                >
                  {DispensingLanguage[lan][menukey]['Reset']}
                </Button> */}
                <Button
                  disabled={isLoadingSave}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
                >
                  {DispensingLanguage[lan][menukey]['Submit']}
                </Button>
                
               </div>
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">
                
<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>

                  <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  //onClick={() => props.history.push("/receipts")}
                  onClick={() => backToList()}
                >
                 {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                <Button
                  disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}
                   
                >
                 {DispensingLanguage[lan][menukey]['Update']}
                </Button>
                
                <Button 
                  disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onPostBtnClick}
                >
                  {DispensingLanguage[lan][menukey]['POST']}
                </Button>
                

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => PrintMany()}
                >
                <i class="fas fa-print"></i>
                </Button>

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => ExcelMany()}
                >
                <i className="far fa-file-excel"></i>
                </Button>

              </Grid>
          }

            </div>
         </div>   
          
          
        </Grid>
       

      </div>

      
      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}
 

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                {/* <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Store"]} *
                    </InputLabel>
                    <Select
                      error={props.errorObject.StoreId}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="StoreId"
                      name="StoreId"               
                      value={ props.formData.StoreId}
                    // value={all}
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','StoreId')}
                      
                      fullWidth
                      disabled={props.addProductForm==false?true: props.bStockUpdate}
                    >
                      
                      {StoreList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>

                    <FormHelperText error={props.errorObject.StoreId}>
                      {props.errorObject.StoreId}
                    </FormHelperText>

                  </FormControl>
                </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.addProductForm==false?true: props.bStockUpdate}               
                      autoHighlight
                      disableClearable
                      id="StoreId"
                      options={StoreList}  
                      onChange={(event, value) => changeVal(event,value,'StoreId')} 
                      getOptionLabel={(option) => option.name}
                      value={FormStoreId}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.StoreId}
                        label={DispensingLanguage[lan][menukey]["Store"] }
                        variant="standard"
                        error={props.errorObject.StoreId}
                        helperText={props.errorObject.StoreId}
                        required
                        id="StoreId"
                        name="StoreId" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


                  

                  <Grid item xs={3} sm={3}>
                      <TextField
                         disabled={true/*bStockUpdate*/}
                         error={props.errorObject.TransactionNo}
                         helperText={props.errorObject.TransactionNo}
                         required
                        id="TransactionNo"
                        name="TransactionNo"
                        label={DispensingLanguage[lan][menukey]['Receive_Invoice_No']} 
                        // value={formData.FirstName || "Mahmudul"}
                        value={props.formData.TransactionNo||props.generatedInvoiceNo}
                        fullWidth
                        autoComplete="family-name"
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','TransactionNo')}
                      />
                    </Grid>
                     

                    <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.TransactionDate}
                            helperText={props.errorObject.TransactionDate}
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            id="TransactionDate"
                            label={DispensingLanguage[lan][menukey]['Receive_Date']} 
                            autoOk={true}
                            name="TransactionDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.TransactionDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            //onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                            onChange={(e) =>formOnchange(e,'handleRDateChange','TransactionDate')}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </MuiPickersUtilsProvider> 
                    </Grid>


                    {/* <Grid item xs={3} sm={3}>


                     <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label-receive-from">
                        { DispensingLanguage[lan][menukey]['Supplier'] } *  
                        </InputLabel>
                        <Select
                           
                          error={props.errorObject.ReceiveFrom} 
                          required                                
                          labelId="demo-simple-select-helper-label-receive-from"
                          id="ReceiveFrom"
                          name="ReceiveFrom"
                          value={props.formData.ReceiveFrom}
                          fullWidth
                          //onChange={(e) => props.handleChange(e)}
                          onChange={(e) =>formOnchange(e,'handleChange','ReceiveFrom')}
                          disabled={ manyTableData.length>0? true:props.bStockUpdate}
                        >
                        {
                        getFacilityListNotEqual99ANDSupplier.map(
                        (item, index) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        }
                        )}
                  
                        </Select>

                      <FormHelperText error={props.errorObject.ReceiveFrom}>
                        {props.errorObject.ReceiveFrom}

                      </FormHelperText>

                      </FormControl>
              
                    </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={ manyTableData.length>0? true:props.bStockUpdate}                    
                      autoHighlight
                      disableClearable
                      id="ReceiveFrom"
                      options={getFacilityListNotEqual99ANDSupplier}  
                      onChange={(event, value) => changeVal(event,value,'ReceiveFrom')} 
                      getOptionLabel={(option) => option.name}
                      value={ReceiveFrom}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.ReceiveFrom}
                        label={DispensingLanguage[lan][menukey]["Supplier"] }
                        variant="standard"
                        error={props.errorObject.ReceiveFrom}
                        helperText={props.errorObject.ReceiveFrom}
                        required
                        id="ReceiveFrom"
                        name="ReceiveFrom" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>



                    {/* <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                        
                          {DispensingLanguage[lan][menukey]["Receive_By"]} *
                        </InputLabel>
                        <Select
                          error={props.errorObject.PreparedBy}
                         
                          required
                          labelId="demo-simple-select-helper-label"
                          id="PreparedBy"
                          name="PreparedBy"
                          value={props.formData.PreparedBy}
                          fullWidth
                          //onChange={(e) => props.handleChange(e)}
                          onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                          disabled={props.bStockUpdate}
                        >
                        {UsersListByFacility.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={props.errorObject.PreparedBy}>
                          {props.errorObject.PreparedBy}
                        </FormHelperText>
                      </FormControl>
                    </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Receive_By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required
                        id="PreparedBy"
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>
                   
                    
                   
                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={ manyTableData.length>0? true:props.bStockUpdate}
                        error={props.errorObject.SupplierInvNo}
                        helperText={props.errorObject.SupplierInvNo}
                        required
                        id="SupplierInvNo"
                        name="SupplierInvNo"
                        label={DispensingLanguage[lan][menukey]['Warehouse_Invoice_No']} 
                        value={props.formData.SupplierInvNo}
                        fullWidth
                        autoComplete="family-name"
                        //onChange={(e) =>props.handleChange(e)} 
                        onChange={(e) =>formOnchange(e,'handleChange','SupplierInvNo')}
                      />
                    </Grid> 

                    <Grid item xs={1} sm={1}>
                        <Button
                          disabled={ manyTableData.length>0? true:props.bStockUpdate}
                          className="pick_btn"
                          variant="contained"
                          color="success"
                          //onClick={props.handleModalCheck}
                          onClick={() => handleClose("WarehouseInvoicOpen")}
                        >
                          ...
                        </Button>

                        <WarehouseInvoiceModal
                          handleClose={handleClose}
                          open={openWarehouseInvoice}
                          addWarehouseInvoice={addWarehouseInvoice}
                          IssuedTo={props.formData.ReceiveFrom}
                          FormData={props.formData}
                          InvoiceType={"ISSUE"}
                          refetch={props.refetch}
                          {...props}
                        />
                    </Grid>


                    <Grid item xs={2} sm={2}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.SupplierInvDate}
                            helperText={props.errorObject.SupplierInvDate}
                            className={classes.fullWidth}
                           // disableToolbar
                            variant="inline"                            
                            id="SupplierInvDate"
                            label={DispensingLanguage[lan][menukey]['Warehouse_Invoice_Date']} 
                            autoOk={true}
                            name="SupplierInvDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.SupplierInvDate||props.selectedDate}
                            format="dd/MM/yyyy"
                           // onChange={(e) => props.handleRDateChange(e, "SupplierInvDate")}
                            onChange={(e) =>formOnchange(e,'handleRDateChange','SupplierInvDate')}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </MuiPickersUtilsProvider>                      
                    </Grid>


                    

                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="Remarks"
                        name="Remarks"
                        label={DispensingLanguage[lan][menukey]['Remarks']} 
                        value={props.formData.Remarks}
                        fullWidth
                        multiline                    
                        autoComplete="family-name"
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','Remarks')}
                      />
                    </Grid>




                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="TransactionId"
                        name="TransactionId"
                        label="TransactionId"  
                        value={props.formData.TransactionId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />


                    </Grid>  
            
                  </Grid>
                </Grid>

                
                <Grid container spacing={1}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          <Grid container className="mb-2">
                           
                              


                  <Grid container spacing={1}>
                     {/* <Grid item xs={2} sm={2}>
                      
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) =>handleChangeGrp(e)}
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid> */}

                      <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                            <Autocomplete
                                autoHighlight
                                className="sw_chosen_filter"
                                id="ItemGroupId"
                                disabled={props.addProductForm==true?true:false}
                                disableClearable
                                options={GroupList}               
                                onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                                getOptionLabel={(option) => option.name}
                                defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
                                
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={DispensingLanguage[lan][menukey]["Product Group"]}
                                  variant="standard"
                                  name="ItemGroupId"
                                />
                                )}
                              />                   
                            </FormControl>
                        </Grid>

                      {/* <Grid item xs={5} sm={5} > */}
  
                        
                            <Grid item xs={2} sm={2} id="FID_scan_gtin_or_brandcode">
                              <FormControl className={classes.fullWidth}>
                                <TextField
                                  disabled={(props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  
                                  id="scan_gtin_or_brandcode"
                                  name="scan_gtin_or_brandcode"
                                  label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                   
                                  autoComplete="family-name"
                                  ref={scan_gtin_or_brandcode}
                                  onKeyPress={handleKeyPress} 
                                  onKeyDown={handleKeyDown} 
                                  onKeyUp={handleKeyUp}
                                  
                                  
                                />
                              </FormControl>
                                      
                            </Grid> 

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button

                                disabled={(props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>
                    




                    <div className="search_control_group dnone" id="scan_sscc_hide_show">
                      <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                      <input disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                    </div>
                       

                        {/* <div className="search_card">
                          <form>  
                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                              <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                              <input disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                            </div>
                            <div className="search_control_group" id="FID_scan_gtin_or_brandcode">
                              <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                              <input disabled={props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate)} type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" ref={scan_gtin_or_brandcode} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                            
                            
                            </div>
                            <div className="search_control_group" id="FID_scanButton">
                              <Button
                                disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()}
                              >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                              </Button>
                            </div>





                          </form>
                        </div> */}

                      {/* </Grid> */}


                      <Grid item xs={2} sm={2}>
                        <div className="float-left"> 
                        <span id="is_dirty_message" className={'redtextcolor dnone'}> 
                        {DispensingLanguage[lan][menukey]["Form has dirty data"]}
                        </span>
                        </div>
                      </Grid>

                      

                      <Grid item xs={5} sm={5}>

                            <div className="float-right sw_btn_control" id="FID_pick_items_btn">
                                
                                <Button
                                  disabled={(props.addProductForm==true?true: ( props.formData.InvFrom!=''?true: props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  //disabled={props.bStockUpdate}
                                  hidden={true}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  {DispensingLanguage[lan][menukey]['Select_From_Order']}
                                </Button>

                                <Button
                                  disabled={(props.addProductForm==true?true: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={onDownloadIssueInvoiceBtnClick}
                                >
                                  {DispensingLanguage[lan][menukey]['Download_Issue_Invoice']}
                                </Button>                              

                                <Button
                                  disabled={(props.addProductForm==true?true: (props.formData.InvFrom!=''?true: props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("ProductsOpen")}
                                >
                                  {DispensingLanguage[lan][menukey]['Add_Non_Ordered_Products']}  
                                </Button> 

                                </div>

                          </Grid>
                       </Grid>

                      {/* <Grid item xs={12} sm={12}>
                           
                          <p id="is_dirty_message" className={classes.redtextcolor+' dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</p>
                          
                      </Grid> */}

                             

                              <ProductsModal handleClose={handleClose} open={open} 
                              addProduct={addProduct} 
                              gFacilityId={props.formData.FacilityId} 
                              gStoreId={props.formData.StoreId} 
                              {...props} />

                              <OrderModal handleClose={handleClose} openOrder={openOrder} addOrder={addOrder} 
                              OrderingFrom={props.formData.ReceiveFrom} 
                              TransactionId={props.formData.TransactionId} 
                              gStoreId={props.formData.StoreId} {...props} />

                              
                             
                           
                          </Grid> 
                           <ReactTabulator
                                 
                                  ref={(r) => (ReceiveDetailsRef = r)}
                                  columns={ReceiveDetailsColumns}
                                  data={manyTableData}
                                  height="350px"
                                  options={{
                                    groupBy: "GroupName",
                                    columnCalcs: "both",
                                  }}
                                  layout={"fitColumns"}
                                />
                         
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* new row */} 
                  </Grid>
              </Grid>

 

            {/* </CardContent>
          </Card> */}
        </Grid>


       {/* Action buttons */}
         
      </Grid>
    </div>
  </div>
  );
};

export default ReceiptsItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  // redtextcolor: {
  //   color: "red", 
  //   fontWeight:600,
  //   textAlign: "center",
  //   lineHeight: "0px",
  //   paddingTop: "15px",
  // }
});
