
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import DateFnsUtils from "@date-io/date-fns";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useParams } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';

const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "settings-entry";
let tblRef = null;

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const userId = UserInfo == 0 ? '' : UserInfo[0].id;

const DispenserFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Settings Entry - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">

              {addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">
                    <div className="float-right sw_btn_control">
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/settings_entry")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/settings_entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
               
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
      
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={2}>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.TabMaster}
                      helperText={errorObject.TabMaster}
                      required
                      id="TabMaster"
                      name="TabMaster"
                      label={DispensingLanguage[lan][menukey]["TabMaster"]}
                      value={formData.TabMaster}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.SKey}
                      helperText={errorObject.SKey}
                      required
                      id="SKey"
                      name="SKey"
                      label={DispensingLanguage[lan][menukey]["SKey"]}
                      value={formData.SKey}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.SValue}
                      helperText={errorObject.SValue}
                      required
                      id="SValue"
                      name="SValue"
                      label={DispensingLanguage[lan][menukey]["SValue"]}
                      value={formData.SValue}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                </Grid>
              

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        

      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
