
import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Typography, Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Checkbox, TextField } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone, ViewList } from "@material-ui/icons";

import * as Service from "../../../services/Service.js";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
const ProductsList = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "esigl-upload-log";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dataItems, setDataItems] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [ProductTableData, setProductTableData] = React.useState(true);
  // Facility Auto
  const FacilityId = localStorage.getItem("FacilityId");
  let Facility_List = JSON.parse(localStorage.getItem("FacilityList"));
  let All_Facility_label = { id: "", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  //let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
  //const FacilityList = [All_Facility_label].concat(Facility_List); 
  const [currFacilityId, setCurrFacilityId] = useState("");
  const [currDesktopFacilityId, setCurrDesktopFacilityId] = useState(0);
  const [currNoSYNC, setNoSYNC] = useState(0);
  const [currSyncProblemFacilityId, setCurrSyncProblemFacilityId] = useState("0");
  const [currSyncSuccessFacilityId, setCurrSyncSuccessFacilityId] = useState(0);
  const [currWEBFacilityId, setCurrWEBFacilityId] = useState(0);
  const [currtotalFacilityId, setCurrtotalFacilityId] = useState(0);



  const [toggleButtonValue, setToggleButton] = React.useState("1");
  

const handleChangeToggle = (event, tmpToggleButtonValue) => {
  if (tmpToggleButtonValue) { 
   
    setToggleButton(tmpToggleButtonValue);
  }
};


  // Facility
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;
    }

    if (rowId !== currFacilityId) {

      // Facility List By User Change
      setCurrFacilityId(rowId);
      setLoading(true);
    }


  };

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );

  }, [currFacilityId]);



  // End Facility





  let params = {
    menukey: menukey,
    action: "getMasterTable",
    FacilityId: currFacilityId,
    FList: toggleButtonValue,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };


  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getMasterTablelist,
    {
      onSuccess: (data) => {
        setDataItems(data.datalist);
        setLoading(false);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  let params1 = {
    menukey: menukey,
    action: "getBlockData",
  };


  const {data:data1, refetch:refetch1 } = useQuery(
    [params1],
    api.getBlockDataList,
    {
      onSuccess: (data) => {
        // console.log(data);
        setCurrDesktopFacilityId(data.DesktopFacilityId);
        setNoSYNC(data.totalFacilityId - (data.SyncSuccessFacilityId + data.SyncProblemFacilityId));
        setCurrSyncProblemFacilityId(data.SyncProblemFacilityId);
        setCurrSyncSuccessFacilityId(data.SyncSuccessFacilityId);
        setCurrWEBFacilityId(data.WEBFacilityId);
        setCurrtotalFacilityId(data.totalFacilityId);
        // setLoading(false);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  


  if (ProductTableData) {
    setProductTableData(false);
    refetch();
  }




  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=ProductsListExport" +
      "&reportType=" + reportType +
      "&menukey=" + menukey +
      "&lan=" + localStorage.getItem("LangCode") +
      "&StrengthId=" + "" +
      "&PacksizeId=" + "" +
      "&DosageFormId=" + "" +
      "&DciId=" + ""
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    viewProduct(rowData);
  };

  const viewProduct = (data) => {
    let route = `esigl_upload_log/view/${data.FacilityId}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
            viewProduct(rowData);
          }}
        />
      </>
    );
  }

  var StatusIcon = function (cell, formatterParams) { //plain text value
    const CellValueForOrder = cell._cell.row.data;
    //console.log("bbbbb",CellValueForOrder);
    //console.log("sssss",CellValueForOrder.bCompleted);
    if (CellValueForOrder.WebLogInsertDt == 1) {
      return "<i class='fa fa-check' style='color:green'></i>";
    } else if (CellValueForOrder.WebLogInsertDt == 2) {
      return "<i class='fa fa-times' style='color:red'></i>";
    } else if (CellValueForOrder.WebLogInsertDt == null) {
      
    }
  };



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Departments"],
      field: "RegionName",
      width: 130,
      headerFilter: true,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      headerFilter: true,
      width: 150,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Type"],
      field: "FTypeName",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Level"],
      field: "FLevelName",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      // width: 250,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Device Type"],
      field: "DeviceType",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["STATUS"],

      width: 80,
      headerHozAlign: "center",
      field: "WebLogInsertDt",
      hozAlign: "center",

      formatter: StatusIcon,
    },

  ];

  return (
    <>
      <div className={classes.productPageTitle}>

        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["eSIGL Upload Log"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={9} sm={9}>
                  <div className="float-left sw_btn_control">
                    <ToggleButtonGroup
                      className="sw_ToggleButtonGroup"
                      color="primary"
                      value={toggleButtonValue}
                      exclusive
                    onChange={handleChangeToggle}
                    >

                      <ToggleButton value="1">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currtotalFacilityId}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["eSIGL FACILITY"]}</span>
                          </div>
                        </div>
                      </ToggleButton>

                      <ToggleButton value="2">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currDesktopFacilityId}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["DESKTOP"]}</span>
                          </div>
                        </div>
                      </ToggleButton>
                      <ToggleButton value="3">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currWEBFacilityId}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["WEB"]}</span>
                          </div>
                        </div>
                      </ToggleButton>
                      <ToggleButton value="4">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currSyncSuccessFacilityId}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["Sync Success"]}</span>
                          </div>
                        </div>
                      </ToggleButton>
                      <ToggleButton value="5">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currNoSYNC}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["No SYNC"]}</span>
                          </div>
                        </div>
                      </ToggleButton>
                      <ToggleButton value="6">
                        <div className="col-md-12">
                          <div>
                            <span className="text-xlg">{currSyncProblemFacilityId}</span>
                            <br></br>
                            <span className="text-bg">{DispensingLanguage[lan][menukey]["Sync Problem"]}</span>
                          </div>
                        </div>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>


                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      // defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName != "admin")) ? -1 : FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole==1?false:true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>





                {/* <Grid item xs={9} sm={9}>
                <div className="float-right">
                    <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Generic']+": "+GenericCount}</h4>
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
            {isLoading && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={450}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsList;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
