import React, { useRef,useState } from "react";
import {
Accordion,
AccordionSummary,
AccordionDetails,
} from "@material-ui/core";

//import * as Faq from "../../services/Faq.js";
import * as Service from "../../services/Service.js";

const FAQPageContent = (props) => {

  const [dataItems, setDataItems] = useState([]);
  const [getFaqData, setFaq] = React.useState(true);

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "faq";

  if(getFaqData){
    setFaq(false);
    let ParamList={
      action:"getDataList",
      lan: lan
    }
    Service.default
        .postApi("source/api/api_pages/faq.php",ParamList)
        .then((res) => {

          setDataItems(res.datalist);
        })
        .catch((err) => {});
  }

  React.useEffect(() => {
    setFaq(true);
  }, [lan]);

  return (
    <>
      <div className="inner-section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <div className="row">
           <div className="col-lg-12">
              <h4 className="faq_title">{ DispensingLanguage[lan][menukey]["Frequently Asked Questions"] }</h4>
              {dataItems.map((item, index) => {
                  return (
                    // <MenuItem value={item.id}>{item.name}</MenuItem>
                    <Accordion className="faq_card">
                    <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                    <h5 key={index}>{item.Question}</h5>
                    </AccordionSummary>
                    <AccordionDetails className="faq_desc">
                      <p>{item.Answer}</p>
                    </AccordionDetails>
                  </Accordion>
                  );
              })}
              </div>
            </div>
         </div>
       </div>
    </>
  );
};

export default FAQPageContent;