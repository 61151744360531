import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import ProductFormData from "./ProductFormData.js";
import Constants from "../../../services/Constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AddProduct = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "generics";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [formData, setFormData] = useState({
    ItemCode: "",
    ItemName: "",
    ShortName: "",
    ItemGroupId: "",
    ProductSubGroupId: "",
    StrengthId: "",
    PacksizeId: "",
    DosageFormId: "",
    DciId: "",
    UnitId: "",
    bKeyItem: "",
    Price: "",
    SOBAPSCode: "",
    ClassificationId:""
  });
  const [errorObject, setErrorObject] = useState({});
  const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["product", id],
    () =>
      api.getProduct(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveProduct, {
    onSuccess: (data) => {

      if (data.status == 200) {

        if(data.success == 1){
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("generics");
          props.history.push("/generics");
        }else{
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
        
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const generateProductCodeAndName = (data) => {
    //Generic Code = Form + DCI + Strength + Packsize

    const {
      DosageFormId,
      DciId,
      StrengthId,
      PacksizeId
    } = data;

    let ItemCode = "";

    let ItemName = "";
    let ShortName = "";

    if (DosageFormId && DciId && StrengthId && PacksizeId) {
      ItemCode =
        DosageFormId +
        DciId.toString().padStart(4, "0") +
        StrengthId.toString().padStart(4, "0") +
        PacksizeId.toString().padStart(4, "0");
    }

    const Dcilist = JSON.parse(localStorage.getItem("Dcilist"));
    const strengthSizes = JSON.parse(localStorage.getItem("Strengthlist"));
    const forms = JSON.parse(localStorage.getItem("DosageFormlist"));
    const packSizes = JSON.parse(localStorage.getItem("PackSizelist"));

    let DciNameStr = "";
    if (DciId != "") {
      DciNameStr = Dcilist.find((g) => g.id == DciId)?.name;
    }

    let strengthSizeStr = "";
    if (StrengthId != "") {
      strengthSizeStr = strengthSizes.find((st) => st.id == StrengthId)?.name;
    }

    let packSizeStr = "";
    if (PacksizeId != "") {
      packSizeStr = packSizes.find((pack) => pack.id == PacksizeId)?.name;
    }

    let formStr = "";
    if (DosageFormId != "") {
      formStr = forms.find((f) => f.id == DosageFormId)?.name;
    }

    //Generic Name = DCI + Strength + Packsize + Form

    ItemName =
      (DciNameStr || "") +
      " " +
      (strengthSizeStr || "") +
      " " +
      (packSizeStr || "") +
      " " +
      (formStr || "");
    
    ShortName =
      (DciNameStr || "") +
      " " +
      (strengthSizeStr || "") +
      " " +
      (packSizeStr || "") +
      " " +
      (formStr || "");

   return {
      ItemCode: ItemCode,
      ItemName: ItemName,
      ShortName: ShortName,
    };
  };

  const checkNumberValidation = (name, value) => {
    if (name == "Price") {
      let newValue = "";
      if (
        value.split(".") &&
        value.split(".")[1] &&
        value.split(".")[1].length > 2
      ) {
        newValue = parseFloat(value).toFixed(2);
      } else {
        newValue = value;
      }
      setFormData({ ...formData, [name]: newValue });
    }

    /*if (name == "markUp") {
      value = value && value != "" ? parseInt(value) : value;
      if (value == "" || value == 0 || (value >= 0 && value <= 100)) {
        setFormData({ ...formData, [name]: value });
      }
    }*/

  };

  const handleChange = (e) => {

    const { name, value } = e.target;

    if (name == "Price") {
      checkNumberValidation(name, value);
    } else {
      let data = { ...formData };
      data[name] = value;

      let productData = generateProductCodeAndName(data);
      if (productData.ItemCode) {
        data["ItemCode"] = productData.ItemCode;
      }
      if (productData.ItemName) {
        data["ItemName"] = productData.ItemName;
      }
      if (productData.ShortName) {
        data["ShortName"] = productData.ShortName;
      }

      setFormData(data);
    }

    setErrorObject({ ...errorObject, [name]: null });

  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ItemCode: "",
      ItemName: "",
      ShortName: "",
      ItemGroupId: "",
      ProductSubGroupId: "",
      StrengthId: "",
      PacksizeId: "",
      DosageFormId: "",
      DciId: "",
      UnitId: "",
      bKeyItem: "",
      Price: "",
      SOBAPSCode: "",
      ClassificationId:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "ItemCode",
      "ItemName",
      "ItemGroupId",
      "ProductSubGroupId",
      "StrengthId",
      "PacksizeId",
      "DosageFormId",
      "DciId",
      "UnitId",
      "ClassificationId"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData,  menukey: menukey,  lan: lan,  UserName: UserName};
      mutate(fDtat);
    }
  };

  const ValidateProductForDispensing = async (e) => {
    return;
  };

  return (
    <>
      <ProductFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        ValidateProductForDispensing={ValidateProductForDispensing}
        {...props}
      />
    </>
  );
};

export default AddProduct;
