
import React, { useState, useEffect } from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

//Google Map Component
import GoogleMapForContactPage from "../pages-content/GoogleMapForContactPage.js";

// core components
function ContactPageContent() {

    /* ======Language====== */
    const getCookie = (cName) => {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie);
      const cArr = cDecoded .split('; ');
      let res;
      cArr.forEach(val => {
          if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res;
    }
  
    const UserInfo = sessionStorage.getItem("User_info")
      ? JSON.parse(sessionStorage.getItem("User_info"))
      : 0;
    const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  
    //get DispensingLanguage
    const DispensingLanguage = JSON.parse(
      localStorage.getItem("DispensingLanguage")
    );
  
    let lan = '';
    if(UserName == ''){
      lan = getCookie('LangCode');
    }else{
      lan = localStorage.getItem("LangCode");
    }
    const menukey = "contact";
    /* ======Language====== */

  return (
    <>

      {/* Contact Selection */}
      <div className="contact-section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6">
              <div className="contact-form">
                <h3>{ DispensingLanguage[lan][menukey]["Send us a message"] }</h3>
                <p>{ DispensingLanguage[lan][menukey]["If you need more information about ePharmacie or want to provide any feedback, please fill in the Contact Form below."] }</p>
                <form id="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="text" className="form-control" name="yourname" id="yourname" placeholder={ DispensingLanguage[lan][menukey]["Your Name"] } />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder={ DispensingLanguage[lan][menukey]["Email Address"] } />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder={ DispensingLanguage[lan][menukey]["Subject"] } />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea className="form-control" rows="5" name="message" id="message" placeholder={ DispensingLanguage[lan][menukey]["Message"] }></textarea>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0);" class="btn btn-info">{ DispensingLanguage[lan][menukey]["Send Message"] }</a>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="google-map">
                <GoogleMapForContactPage />
              </div>
            </div>
         </div>
       </div>
      </div>
      {/* /Contact selection/ */}

    </>
  );
}

export default ContactPageContent;
