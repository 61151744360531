import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  FormGroup,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import { useMutation } from "react-query";
import * as api from "../../../actions/api";
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-entry";

let ProductGroupList=JSON.parse(localStorage.getItem("ProductGroup"));
let TempProductSubGroupList = JSON.parse(localStorage.getItem("ProductSubGroupList"));

let UnitList=JSON.parse(localStorage.getItem("Unitofmeasurelist"));



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const SvdlProductEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const [ProductSubGroupList, setProductSubGroup] = useState(TempProductSubGroupList);
  function tabChangeHandler(event, newValue) {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { mutate: cProductSubGroupList } = useMutation(

    api.aProductSubGroupList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setProductSubGroup(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );



  const handleProductGroupChange = (event) => {
    let ProductGroupParam = { action: "gProductSubgroup", ItemGroupId: event.target.value, menukey: "" }
    cProductSubGroupList(ProductGroupParam);
    // setcurrItemGroupId(event.target.value);
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Product Entry Form - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title= {DispensingLanguage[lan][menukey]['Product Entry Form'] }/>
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>

                    <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Product Group"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="ItemGroupId"
                            value={formData.ItemGroupId}
                            
                            fullWidth
                            onChange={(e) => {handleChange(e);handleProductGroupChange(e)}}
                            
                          >
                            
                            {ProductGroupList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ItemGroupId}>
                            {errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Product Subgroup"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="ProductSubGroupId"
                            value={formData.ProductSubGroupId}
                            
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            
                          >
                            <MenuItem value=""> {DispensingLanguage[lan][menukey]["Select Product Subgroup"]}</MenuItem>
                            {ProductSubGroupList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ProductSubGroupId}>
                            {errorObject.ProductSubGroupId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>



                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ItemCode}
                          helperText={errorObject.ItemCode}
                          required
                          id="ItemCode"
                          name="ItemCode"
                          label={DispensingLanguage[lan][menukey]['Product Code'] }
                         
                          value={formData.ItemCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ItemName}
                          helperText={errorObject.ItemName}
                          required
                          id="ItemName"
                          name="ItemName"
                          label={DispensingLanguage[lan][menukey]['Product Name'] }
                         
                          value={formData.ItemName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ShortName}
                          helperText={errorObject.ShortName}
                          required
                          id="ShortName"
                          name="ShortName"
                          label={DispensingLanguage[lan][menukey]['Short Name'] }
                         
                          value={formData.ShortName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Unit Name"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="UnitId"
                            value={formData.UnitId}
                            
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            
                          >
                            
                            {UnitList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.UnitId}>
                            {errorObject.UnitId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Price}
                          helperText={errorObject.Price}
                          required
                          id="Price"
                          name="Price"
                          label={DispensingLanguage[lan][menukey]['Cost (CFA)'] }
                         
                          value={formData.Price}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.PackSize}
                          helperText={errorObject.PackSize}
                          required
                          id="PackSize"
                          name="PackSize"
                          label={DispensingLanguage[lan][menukey]['Pack Size'] }
                         
                          value={formData.PackSize}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.CameCode}
                          helperText={errorObject.CameCode}
                          required
                          id="CameCode"
                          name="CameCode"
                          label={DispensingLanguage[lan][menukey]['CAME Code'] }
                         
                          value={formData.CameCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              disabled={formData.id == 1 ? true : false}
                              checked={formData.bKeyItem}
                              onChange={(e) => handleCheck(e)}
                              name="bKeyItem"
                              value="no"
                            />
                          }
                          label="Key Product"
                        />
                      </Grid>

                      <Grid item xs={4} sm={4} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              disabled={formData.id == 1 ? true : false}
                              checked={formData.isActive}
                              onChange={(e) => handleCheck(e)}
                              name="isActive"
                              value="no"
                            />
                          }
                          label="Active"
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>

                      </Grid>


                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.OpStock}
                          helperText={errorObject.OpStock}                        
                          id="OpStock"
                          name="OpStock"
                          label={DispensingLanguage[lan][menukey]['Stock initial Element Id'] }                      
                          value={formData.OpStock}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ReceiveQty}
                          helperText={errorObject.ReceiveQty}
                          id="ReceiveQty"
                          name="ReceiveQty"
                          label={DispensingLanguage[lan][menukey]['Total Entrées Element Id'] }
                         
                          value={formData.ReceiveQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.DispenseQty}
                          helperText={errorObject.DispenseQty}
                          id="DispenseQty"
                          name="DispenseQty"
                          label={DispensingLanguage[lan][menukey]['Total Sorties DD Element Id'] }
                         
                          value={formData.DispenseQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.AdjustQty}
                          helperText={errorObject.AdjustQty}
                          id="AdjustQty"
                          name="AdjustQty"
                          label={DispensingLanguage[lan][menukey]['Total Sorties DDD Element Id'] }
                         
                          value={formData.AdjustQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.TotalDispenseQty}
                          helperText={errorObject.TotalDispenseQty}
                          id="TotalDispenseQty"
                          name="TotalDispenseQty"
                          label={DispensingLanguage[lan][menukey]['Total Perime Element UID'] }
                         
                          value={formData.TotalDispenseQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ClStock}
                          helperText={errorObject.ClStock}
                          id="ClStock"
                          name="ClStock"
                          label={DispensingLanguage[lan][menukey]['Inventaire physiq Element UID'] }
                         
                          value={formData.ClStock}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ExpiryQty}
                          helperText={errorObject.ExpiryQty}
                          id="ExpiryQty"
                          name="ExpiryQty"
                          label={DispensingLanguage[lan][menukey]['Stck Risq Qtite Element UID'] }
                         
                          value={formData.ExpiryQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.ExpiryDate}
                          helperText={errorObject.ExpiryDate}
                          id="ExpiryDate"
                          name="ExpiryDate"
                          label={DispensingLanguage[lan][menukey]['Date Peremtion Element UID'] }
                         
                          value={formData.ExpiryDate}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.StockoutDays}
                          helperText={errorObject.StockoutDays}
                          id="StockoutDays"
                          name="StockoutDays"
                          label={DispensingLanguage[lan][menukey]['Nbre Jrs Ruptur Element UID'] }
                         
                          value={formData.StockoutDays}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.AMC}
                          helperText={errorObject.AMC}
                          id="AMC"
                          name="AMC"
                          label={DispensingLanguage[lan][menukey]['Conso moy Element UID'] }
                         
                          value={formData.AMC}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.DMM}
                          helperText={errorObject.DMM}
                          id="DMM"
                          name="DMM"
                          label={DispensingLanguage[lan][menukey]['DMM Element UID'] }
                         
                          value={formData.DMM}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.MOS}
                          helperText={errorObject.MOS}
                          id="MOS"
                          name="MOS"
                          label={DispensingLanguage[lan][menukey]['MSD Element UID'] }
                         
                          value={formData.MOS}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Remarks}
                          helperText={errorObject.Remarks}
                          id="Remarks"
                          name="Remarks"
                          label={DispensingLanguage[lan][menukey]['Obs Element UID'] }
                         
                          value={formData.Remarks}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.PriceUID}
                          helperText={errorObject.PriceUID}
                          id="PriceUID"
                          name="PriceUID"
                          label={DispensingLanguage[lan][menukey]['Inventaire physiq Element UID'] }
                         
                          value={formData.PriceUID}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/svdl-product-entry")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Submit']} 
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/svdl-product-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default SvdlProductEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
