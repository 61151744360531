import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";

import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";


const User = (props) => {
//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const userId = UserInfo==0?'': UserInfo[0].id;

const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const { useState } = React;
 const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, error, isError, data, refetch } = useQuery(
    "userlist",
    api.getUserList ,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  const { mutate:deleteApi } = useMutation(api.userDelete, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

      api.getAllDropdown('UserList').then((response) => {
        localStorage.setItem(
          "UserList",
          JSON.stringify(response.datalist.UserList)
        );
      }) 
  
    api.getAllDropdown('UserListEdit').then((response) => {
        localStorage.setItem(
          "UserListEdit",
          JSON.stringify(response.datalist.UserListEdit)
        );
      }) 
      
      refetch();
      queryClient.getQueriesData("userlist");

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });



    }else{   
      //swal(data.data.message, "", "error");
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      }); 
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getUserList" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editUser(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["User Name"],
      field: "name",
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["User_Id"],
      field: "loginname",
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Email"],
      field: "email",
      headerFilter:true,
    },
    
    // {
    //   title: DispensingLanguage[lan][menukey]["Designation"],
    //   field: "designation",
    //   width: 150,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Role"],
    //   field: "role",
    //   width: 150,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Language"],
      field: "LangName",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 70,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  // const dataItems = data?.map((item, index) => {
  //   item["sl"] = index + 1;
  //   return item;
  // });

  const editUser = (data) => {
    let route = `user_list/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteUser = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this user!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({user_id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "userDelete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    if(rowData.id==1)
    return (
      <>
        <Edit
          onClick={() => {
            editUser(rowData);
          }}
        />
         
      </>
    );
    else
    return (
      <>
        <Edit
          onClick={() => {
            editUser(rowData);
          }}
        />
        {/* <DeleteOutline
          onClick={() => {
           
            deleteUser(rowData);
          }}
        /> */}
      </>
    );
  }

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["User List"]}
              </div>
              <div className="float-right sw_btn_control">
                {/* <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/user_list/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add User"]}
                </Button> */}
                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={600}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default User;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
