import React, { Component } from "react";
import { Button } from "reactstrap";

//import data from "./dataset.json";
//import fromCDN from "from-cdn";

//  const PIVOT = (props) => { 

//   alert("")
  
//     return (
//         <div className="dhx_container_inner"  id="pivot"></div>
//     );

// }

class PIVOT extends Component {

    constructor(props) {
       
         super(props);
         
         
         this.state={data:this.props.pivotdata, pivotfieldlist:this.props.pivotfieldlist,pivotfields:{
          rows: ["ProductName"],
          columns: ["FacilityLevel"],
          values: [
              { id: "AMC", method: "count" },
              { id: "SDU", method: "max" },
              { id: "MOS", method: "max" },
          ]
      }
    } 
    }
    
    componentDidMount() {

     
       
        // eslint-disable-next-line no-undef
       this.pivot=new dhx.Pivot("pivot1", {
           data:this.state.data,
          
          fields: this.state.pivotfields, 
          fieldList: //this.state.pivotfieldlist,
          
          [
           //    { id: "ProductName", label: "Name" },
              // { id: "year", label: "Year" },
              // { id: "continent", label: "Continent" },
              // { id: "form", label: "Form" },
              // { id: "gdp", label: "GDP" },
              // { id: "oil", label: "Oil" },
              // { id: "balance", label: "Balance" } 
          ],
     
          layout: { 
            gridOnly:"Show",
           // columnsWidth:"auto",   
            rowsHeadersWidth : 200 ,  
          //  showFilters:false
             gridMode:"tree",
            // footer:true,
             //repeatRowsHeaders:true,
            // fieldsCloseBtn: false, 
            // showFilters:false,
            // hideSettings:true,
            // showSettings:false 
            //leftMargin: 300, 

          }
      });
     
  
    }
       componentWillUnmount() {
        this.pivot && this.pivot.destructor();
      }
    
      runExport(type) {
        if (type === "xlsx") {
          this.pivot.export.xlsx({
            name:"Pivot Table Export",
            url: "//export.dhtmlx.com/excel",
          });
        }
        if (type === "csv") {
          this.pivot.export.csv();
        }
      }

    componentDidUpdate(){
        this.pivot.setData(this.props.pivotdata);
        if(Object.keys(this.props.pivotfields).length>0){
         // alert("")
          this.pivot.setFields(this.props.pivotfields);
        }
        
       // console.log('data showing',this.props.pivotdata);
       
       // this.pivot.se tFieldList(this.props.fieldList);
      //  this.pivot.load(this.props.pivotdata, "json");
      //this.state.data=this.props.pivotdata;
    ////  this.state.pivotfields= this.props.pivotfields;
    //   console.log("pivotfieldlist"); 
    //  console.log(this.props.pivotfieldlist);
       
        // console.log(this.state.pivotfields);
        // console.log(this.props.pivotfields);

    }
 
    render() {
        
        return (
        <div>
          <div class="controls1">
            <div className="float-right sw_btn_control">
              <Button
              color="info"
              className="mr-2 sw_icons"
              onClick={() => this.runExport("xlsx")}
              >
            <i className="far fa-file-excel"></i>
              </Button>

            {/* <Button
            color="info"
            className="mr-2 sw_icons"
            onClick={() => this.runExport("csv")}
            >
            <i className="far fa-file-excel"></i>
            </Button> */}
            </div>
          </div>

        <div className="dhx_container_inner" id="pivot1"></div>
      </div>

        );
    }
}

export default PIVOT; 
