import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import DispenseItems from './DispenseItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";




//let mApiUrl = api.updateReceiveInv;
 
const Editdispense = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "dispense";
const FacilityId=localStorage.getItem('FacilityId');
const TransactionTypeId = 4;

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const userid = UserInfo == 0 ? "" : UserInfo[0].id;
const RoleId = UserInfo == 0 ? "" : UserInfo[0].RoleId;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
   

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",  
    "FacilityId":localStorage.getItem('FacilityId'),
    TransactionTypeId:TransactionTypeId, 
    PreparedBy:"",
    ApprovedBy:"", 
    Remarks: "",
    StoreId:"",
    ItemGroupId:"0",
    });
    const [selectedDate, setSelectedDate] = useState(
      new Date()
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);
  const [SerialDetails, setSerialDetails] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  
  const [bFirst, setBFirst] = useState(true); 
  const [ItemGroupId, setItemGroupId] = useState("0");
  const [dirty, setDirty] = useState(false);
   
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['dispense', id], () =>
      api.getReceiveInv({TransactionId:id,ItemGroupId:ItemGroupId,TransactionTypeId:TransactionTypeId,SerialDetails:SerialDetails}).then((res) => { 
    //  
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDeletedDataSet([]);
      
      setBFirst(true);

      
      return res.data
    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })

  /*
  const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setDirty(true);
        setbStockUpdate(data.bStockUpdated);
        if(data.bStockUpdated==1){

          mutatePost(TransactionId);
        
        }else{
          refetch();
          setDirty(false);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }


      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading1, mutate:mutatePost } = useMutation(api.postDispenseStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        setbStockUpdate(1);
        setDeletedDataSet([]);

        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })

  */


 
  
  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
    

};
  const handleChange = (e) => {
    const { name, value } = e.target;
     
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      TransactionNo: "", 
      "FacilityId":FacilityId,
      TransactionTypeId:TransactionTypeId ,
      PreparedBy:"",
      ApprovedBy:"", 
      Remarks: ""
    });
  };


  const filterHandleChange = (e) => {
    setItemGroupId(e) ;
  };

  const serialHandleChange = (e) => {
    setSerialDetails(e) ;
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ApprovedBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  /*
  const deleteReceiveInv=(data)=>{
    console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }
  */

  const deleteReceiveInv=(data,deletesetdata)=>{ 

    setUpdatedDetailGridData(data);
    setDeletedDataSet(deletesetdata);

  }
 
 /*
  const handlePost = async (rows,deletedsetdata) => {  

    
    if (validateForm(formData)) {
       
      // Start Post Alert
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedsetdata, menukey: menukey,lan: lan,'UserName':UserName};
          mutateUpdate(fDtat); 
          setManyTableData(rows); 
          
        }else{
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

      
    }
     
     
  };

  */

  /*
  const setUpdatedDetailsGridDataHandler = (rows,deletedsetdata) => {
    if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan,'UserName':UserName}; 
      mutateUpdate(fDtat); 
      }
  }; 
  */

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function successSave(bStockUpdated){
    if(bStockUpdated !==1 ){
      refetch();
    }
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

 
  useEffect(() => {
    refetch();
   
  }, [ItemGroupId]);
  

  useEffect(() => {
    refetch();
  }, [SerialDetails]);
  
   
  return (
    <>
      <DispenseItems 
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        //handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        bFirst={bFirst}
        filterHandleChange={filterHandleChange} 
        refetch={refetch}
        dirty={dirty}
        checkValidateForm={checkValidateForm}
        successSave={successSave}
        stockPosted={stockPosted}
        serialHandleChange={serialHandleChange}

        {...props} />
    </>
  );
};

export default Editdispense;