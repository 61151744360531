import React, { forwardRef, useRef } from "react";
import { makeStyles, alpha } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services

import swal from "sweetalert";
import Autocomplete from '@material-ui/lab/Autocomplete';

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";



const Order = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "orders";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;


  const [dataItems, setDataItems] = useState([]);
  const [AFacilityName, setFacilityName] = useState(FacilityName);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  // Facility Auto
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  //let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
  //const FacilityList = [All_Facility_label].concat(Facility_List); 
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);



  
  // Facility
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fnane = '';
    if (newValue == null) {
      rowId = '';
      Fnane = '';
    } else {

      rowId = newValue.id;
      Fnane = newValue.name;
    }

    if (rowId !== currFacilityId) {

      // Facility List By User Change
      setCurrFacilityId(rowId);
      setFacilityName(Fnane);

      let params2 = { action: "gUserListByFacility", "menukey": menukey, "FacilityId": rowId };
      mutategUserListByFacility(params2);

      // Receive From Facility Change
      let params3={action:"gFacilityListNotEqual99ANDSupplier","menukey": menukey, "FacilityId":rowId};
      mutategReceiveFromFacility(params3);

      setLoading(true);
    }

  };

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);

  

  // End Facility

  // Change User List By Facility

  const { mutate: mutategUserListByFacility } = useMutation(api.gUserListByFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {

        localStorage.setItem(
          "UsersListByFacility",
          JSON.stringify(data.data.datalist)
        );

      }
    },

  })

  // End Change User List By Facility

  // Order To

  const {  mutate:mutategReceiveFromFacility } = useMutation(api.gReceiveFromFacility, {
    onSuccess: (data) => { 
      if (data.status == 200) {  
        
        localStorage.setItem(
          "getFacilityListNotEqual99ANDSupplier",
          JSON.stringify(data.data.datalist)
        );
    
      }
    }
    })



  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: currFacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    lan: lan,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getOrderMasterdata,
    {
      onSuccess: (data) => {
        FacilityList.forEach((item) => {
          if(item.id==currFacilityId){
            localStorage.setItem(
              "FacilityName",
              item.name
            ); 
          }            
         });
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { mutate } = useMutation(
    api.deleteOrderInv,
    {
      onSuccess: (data) => {
        if (data.data.status == 200) {
          refetch();
          queryClient.getQueriesData("orders");

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
            duration: 10000,
          });

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }
      },
    });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getExportOrdersBook" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      AFacilityName +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editOrderInvs(rowData);
  };


  var StatusIcon = function (cell, formatterParams) { //plain text value
    const CellValueForOrder = cell._cell.row.data;
    //console.log("bbbbb",CellValueForOrder);
    //console.log("sssss",CellValueForOrder.bCompleted);
    if (CellValueForOrder.bCompleted == 1) {
      return "<i class='fa fa-check' style='color:green'></i>";
    } else if (CellValueForOrder.bCompleted == 0) {
      return "<i class='fa fa-check' style='color:orange'></i>";
    } else if (CellValueForOrder.bCompleted == -1) {
      return "<i class='fa fa-times' style='color:red'></i>";
    }
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: t(DispensingLanguage[lan][menukey]["Sl#"]),
      hozAlign: "center",
      width: 55,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Date"]),
      width: 85,
      field: "OrderDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order#"]),
      field: "OrderNo",
      width: 175,
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Type"]),
      field: "OrderType",
      width: 104,
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Amount"]),
      field: "OrderAmount",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right", 

    }
    ,
    {
      title: t(DispensingLanguage[lan][menukey]["Order To"]),
      field: "OrderingFromName",
      width: 200,
      headerFilter: true,
    }
    ,
    {
      title: t(DispensingLanguage[lan][menukey]["Entry By"]),
      field: "OrderByName"
    }
    ,
    {
      title: t(DispensingLanguage[lan][menukey]["Approved By"]),
      field: "ApprovedByName",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Receive Status"]),
      field: "OrderStatus",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Status"]),
      field: "bCompleted",
      hozAlign: "center",
      width: 85,

      formatter: StatusIcon,
      headerHozAlign: "center",
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Remarks"]),
      field: "Remarks",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Actions"]),
      field: "custom",
      headerHozAlign: "center",
      headerSort: false,
      hozAlign: "center",
      width: 60,
      formatter: reactFormatter(<ActionButton />),
    },
  ];




  const editOrderInvs = (data) => {
    let route = `orders/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteOrderInvs = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this order!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bCompleted == 1 || rowData.bCompleted == 0)
      return (
        <>
          <ViewList
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <Edit
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              deleteOrderInvs(rowData);
            }}
          />
        </>
      );
  }

  return (
    <>
      <div className={classes.OrderPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Order"])}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["From"])}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["To"])}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="FacilityList"
                    disableClearable
                    options={FacilityList}
                    // defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName != "admin")) ? -1 : FacilityList.find(item => item.id == currFacilityId)}
                    disabled={UserRole == 1 ? false : true}
                    onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>
              </Grid>


              <Grid item xs={6} sm={6}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/orders/add");
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add"])}
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              height="450px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;

const useStyles = makeStyles({
  OrderPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
