import React, { useState,useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";
import * as api from '../../../actions/api';
import OrderFormData from './OrderFormData.js';
import { useTranslation } from "react-i18next";




const EditOrder = (props) => {

  const FacilityId=localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  ); 
  const menukey = "orders";
  const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;
const UserId = UserInfo==0?'': UserInfo[0].id;
  
  const lan = localStorage.getItem("LangCode");

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    OrderId:"",
    OrderDate: "",
    OrderNo: "",
    OrderingFrom:"",
    OrderTypeId:"",
    ManyJsonSave:"",
    "FacilityId":FacilityId,
    OrderBy:"",
    ApprovedBy:"",
    Remarks:"",
    ItemGroupId:"0"
  });


const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [bCompleted, setbCompleted] = useState("-1");
const [ItemGroupId, setItemGroupId] = useState("0");
const [OnlyOrderedItem, setOnlyOrderedItem] = useState("1");
const [ManyTableData, setManyTableData] = useState([]);
const [OrderId, setOrderId] = useState("0");
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
const [bFirst, setBFirst] = useState(true); 
const [isFirstPhysical, setIsFirstPhysical] = useState(0);
const handleDataTableChange = (e) => {};
const [dirty, setDirty] = useState(false);

const [errorObject, setErrorObject] = useState({});
const { id } = useParams();
const queryClient = useQueryClient();


const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


const TabRefresh = (value) => {
  setOnlyOrderedItem(value);
   
};


const { data,refetch } = useQuery(['orders', id], () =>
  api.getOrderInv({OrderId:id,OnlyOrderedItem:2,ItemGroupId:ItemGroupId}).then((res) => {
    setDirty(true);
    
    setOrderId(id);
    setFormData(res.data); 
    setbCompleted(res.data.bCompleted);
    setManyTableData(res.data.ManyJsonSave);
    setUpdatedDetailGridData(res.data.ManyJsonSave);
    setGeneratedInvoiceNo(res.data.OrderNo);
    setBFirst(true);
    setDirty(false);
    return res.data

  }),
   {
    refetchOnWindowFocus: false,
  enabled: Boolean(id)
}) 

/*
const { isLoading, mutate:mutateUpdate } = useMutation(api.updateOrderInv, {
  onSuccess: (data) => {

   
    
    if (data.status == 200) {
      
      if(data.data.success == 1){
      
      if(data.data.IsCompleted==0){
        mutatePost({OrderId:OrderId,FacilityId:FacilityId,lan:lan,UserName:UserName});
      }else{


        
      let route = `${data.data.data.OrderId}`;
      setbCompleted(data.data.data.bCompleted); 
      setManyTableData(data.data.data.ManyJsonSave);
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        
        refetch();
      }

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message ,
        msgtype: data.data.success,
      });
    }


    
    refetch();


    }else{

      
      

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

*/

/*

const { isLoading2, mutate:mutateDeleteAllReOrder } = useMutation(api.deleteAllRecreateOrderInv, {
  
  onSuccess: (data) => {
    
    if (data.status == 200) {  
      props.openNoticeModal({
        isOpen: true,
        
        msg: data.message,
        msgtype: data.success,
      });

      refetch(); 
   
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

*/


/*
const { isLoading1, mutate:mutatePost } = useMutation(
  api.postOrderInv,
  {
  onSuccess: (data) => { 
    if (data.status == 200) { 
     let route = `${OrderId}`;
     setbCompleted(data.data.data.bCompleted); 
     setManyTableData(data.data.data.ManyJsonSave);
     
     props.history.push(route);

     props.openNoticeModal({
      isOpen: true,
      msg: data.data.message,
      msgtype: data.data.success,
    });
    refetch();
     //swal("Success!", "", "success");
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

*/

/*

const setUpdatedDetailsGridDataHandler = (rows) => {

  if (validateForm(formData,1)) { 
  
    let fDtat={...formData,"menukey": menukey,"lan":lan,'ManyJsonSave':rows,"UserName":UserName}; 
 
   mutateUpdate(fDtat); 
    } 

}

*/

const handleChange = (e) => {
  const { name, value } = e.target;
  
    let data = { ...formData };
    data[name] = value;            
    setFormData(data);
    setDirty(true);
  setErrorObject({ ...errorObject, [name]: null });
};

const filterFandleChange = (e) => {
  setItemGroupId(e) ;
};


const handleReset = () => {
  setFormData({
    OrderDate: "",
    OrderNo: ""  
  });
};

const validateForm = (formData) => {
  let validateFields = [ "OrderDate", "OrderNo","OrderingFrom","OrderTypeId", "OrderBy"]
  let errorData = {}
  let isValid = true
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = "field is Required !"
      isValid = false
    }
  })
  setErrorObject(errorData);
  return isValid
}




/*
const handleDeleteAllRecreateOrder = async (e) => {
  
  if (validateForm(formData)) {
  let FinalData={OrderId:props.match.params.id,FacilityId:formData.FacilityId,lan:lan,UserId:UserId}; 
 
  mutateDeleteAllReOrder(FinalData); 
  }
};

*/

/*
const handlePost = async (rows) => {  

  if (validateForm(formData)) {

   swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willAction) => {
    if (willAction) {

      let FinalData={...formData,'ManyJsonSave':rows,"IsCompleted":0,"UserName":UserName}; 
      mutateUpdate(FinalData);
      setManyTableData(rows);
      
    }
  });
    
  }
 
};
*/

function checkValidateForm(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function successSave(bStockUpdated){
  if(bStockUpdated !==0 ){
    refetch();
  }
};

function stockPosted(val){
  setbCompleted(val);
};

useEffect(() => {
  //let newarray = ManyTableData.filter(element => ((element.ActualOrderQty>0) ));
  //let FinalData={...formData,'ManyJsonSave':newarray,"IsCompleted":0}; 
  //mutateUpdate(FinalData);

  refetch();
 
}, [ItemGroupId]);
//}, [OnlyOrderedItem,ItemGroupId]);


  return (
    <>
      <OrderFormData 
      errorObject={errorObject} 
      addProductForm={false} 
      formData={formData} 
      handleChange={handleChange} 
      filterFandleChange={filterFandleChange} 
      
      handleReset={handleReset} 
      // handleUpdate={handleUpdate}  
      //handleDeleteAllRecreateOrder={handleDeleteAllRecreateOrder}
      ManyTableData={ManyTableData} 
      handleDataTableChange={handleDataTableChange} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate}
      bCompleted={bCompleted} 
      dirty={dirty} 
      isFirstPhysical={isFirstPhysical}
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      //handlePost={handlePost}
      generatedInvoiceNo={generatedInvoiceNo} 
      bFirst={bFirst}
      //TabRefresh={TabRefresh}
      //OnlyOrderedItem={OnlyOrderedItem}
      refetch={refetch}

      checkValidateForm={checkValidateForm}
      successSave={successSave}
      stockPosted={stockPosted}
      {...props} />
    </>
  );
};

export default EditOrder;