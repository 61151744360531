import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, CardContent } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

let tblMenuRef = null;

const Roleaccess = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "role-access-entry";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedMenuId, setSelectedMenuId] = useState(1);
  const [selectedrow, setSelectedrow] = useState(false);

  let params = {
    menukey: menukey,
    action: "getMenuList",
  };

  const { data: menuData, refetch: MenuRefetch } = useQuery([params], api.getMenuListForRolePermission, {
    onSuccess: (menuData) => {
      setDataItems1(menuData);
      setSelectedrow(true);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  let params1 = {
    menukey: menukey,
    action: "getRoleList",
    menu_id: selectedMenuId,
  };

  const { data: RoleData, refetch: RoleRefetch } = useQuery([params1], 
    api.getRoleListForMenuPermission, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    console.log(rowData.id);
    setSelectedMenuId(rowData.id);
  };

  const { isLoadingLang, mutate: bCheckedUpdate } = useMutation(
    api.MenuToRoleInsert,
    {
      onSuccess: (data) => {
        if (data.data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }else{   
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
        }
      },
    }
  );

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }

  useEffect(() => {
    if (selectedMenuId > 0) {
      RoleRefetch();
    }
  }, [selectedMenuId]);

  const MenuColumns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    // { 
    //   title: DispensingLanguage[lan][menukey]["Menu Name"], 
    //   field: "menuname",
    //   headerFilter: true, 
    // },
       {
      title: DispensingLanguage[lan][menukey]["Menu Name"],
      field: "menuname",
      headerSort: false,
      headerFilter: true,
      formatter:function(cell, formatterParams){
        let value = cell.getValue();
        let subMenu = "-";
        let subSubMenu = "--";
        let subMenuIndex = value.indexOf(subMenu);
        let subSubMenuIndex = value.lastIndexOf(subMenu);

         if (subMenuIndex !== -1 && value[subMenuIndex + 1] === subMenu) {
           return "<span style='margin-left: 40px;'>" + value + "</span>";
         } else if (subSubMenuIndex !== -1) {
           return "<span style='margin-left: 20px;'>" + value + "</span>";
         }else{
           return value;
         }

         
      },
    },
  ];

  const RoleColumns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Role Access"],
      field: "bChecked",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bChecked"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();

        const role_id = rowdata.role_pkid;
        cellDataUpdatePop(role_id, cell);
      },
    },
    { 
      title: DispensingLanguage[lan][menukey]["Role Name"], 
      field: "role" 
    },
  ];

  const cellDataUpdatePop = (role_id, cell) => {
    let curcellval = cell.getValue();
    let bCheckedVal = curcellval;

    bCheckedUpdate({ selectedMenuId, role_id, bCheckedVal });
  };

  useEffect(() => {
    if(selectedrow == true){
      tblMenuRef.table.selectRow(1);
    }
  }, [selectedrow]);

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Role to Menu Permission"]}</div>
            </div>
          </Grid>
        </div>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>

                <Grid item xs={6} sm={6}>
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={MenuColumns}
                      data={dataItems1}
                      layout={"fitColumns"}
                      height={"600px"}
                      ref={(r) => (tblMenuRef = r)}
                      options={{
                        selectable:1,
                        selectableRangeMode: 'click',
                       }}
                      rowClick={(e, row) => rowClick(e, row)}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <div className="uniqueName">
                    <ReactTabulator 
                      columns={RoleColumns}
                      data={dataItems}
                      layout={"fitColumns"}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </>
  );
};

export default Roleaccess;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
