import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

const SVDLReports = (props) => {
  
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "menu";


  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;


  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));

  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const Image_URL = removeStrFromURL.replace('backend/', '');
  const reportImages = GlobalSettings.report_images;

  let params = {
    menukey: menukey,
    action: 'getSVDLDashboardList',
    UseSite: 'svdl',
    lan:lan,
    UserId: UserId,
    UserName: UserName

  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getSVDLDashboardList,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  return (
    <>
      <div className={classes.PageTitle}>
        <div className="d-flex justify-packSize mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Report list"]}
              </div>
            </div>
          </Grid>
        </div>

        <div className="uniqueName">
          
          <Grid container spacing={3}>

           {dataItems.map((item, index) => {

              let Description = '';
              if((item.Description !='') && (item.Description !=null)){
                Description = item.Description;
              }else{
                Description = '';
              }

              let DashboardImage = '';

              if((item.DashboardImage !='') && (item.DashboardImage !=null)){
                // reportImages
                 DashboardImage =  <img src={ Image_URL + reportImages +'/'+ item.DashboardImage} alt={item.DashboardName} className="scale-with-grid"></img>;
                             
                 //  DashboardImage = <img src={require("../../../../writable_files/report-images/"+item.DashboardImage)} alt={item.DashboardName} className="scale-with-grid" />
                }else{
                 DashboardImage =  <img src={require(Image_URL + reportImages+"/no_image_available.jpg")} alt={item.DashboardName} className="scale-with-grid" /> 
               }

              

              return (
                <Grid item xs={4} sm={4}>
                    <div className="reports_feature"  key={index.DashboardId}>
                      <span className="reports_info_icon reports_tooltip">
                        <i className="fa fa-info-circle fa-lg"></i>
                        <div className="tooltiptext">
                        <h4>
                          <Link onClick={(e) => {

                             // let route = `esigl-dashboard-view/${item.DashboardId}`;
                             let route = `${item.url}`;
                              props.history.push(route);
                          }} >
                            { DispensingLanguage[lan][menukey][item.DashboardName] }</Link></h4>
                        <p>{Description}</p>
                        </div>
                      </span>
                      <div className="photo">
                        <Link onClick={(e) => {
                            // let route = `esigl-dashboard-view/${item.DashboardId}`;
                             let route = `${item.url}`;
                            props.history.push(route);
                        }} >
                        {DashboardImage}</Link></div>
                      <div className="desc">
                        <div className="t">
                          <div className="c">
                            <h5>
                                <Link onClick={(e) => {
                                    // let route = `esigl-dashboard-view/${item.DashboardId}`;
                                     let route = `${item.url}`;
                                    props.history.push(route);
                                }} >
                                  
                               { DispensingLanguage[lan][menukey][item.DashboardName] }

                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
               );
              })}

          </Grid>
        </div>
      </div>
    </>
  );
};

export default SVDLReports;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
