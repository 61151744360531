import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useQuery,useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,

} from "@material-ui/core";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";




import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const Dhis2datatranserlogd = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "dhis2-data-transfer-log";

  const classes = useStyles();
  const { t, i18n } = useTranslation();

const [toggleButtonValue, setToggleButton] = React.useState("General");
//console.log(toggleButtonValue);
const [productGroupData, selectProductGroupData] = useState(0);
//change functions button
const DataSetDHIS2List = JSON.parse(localStorage.getItem("DataSetDHIS2"));

const handleChangeToggle = (event, tmpToggleButtonValue) => {
  if (tmpToggleButtonValue) {
    //console.log(tmpToggleButtonValue);
    setToggleButton(tmpToggleButtonValue);
  }
};
const [isLoading, setLoading] = useState(false);

const [selectedStartDate, setSelectedStartDate] = React.useState(
  moment().format("YYYY-MM-DD")
);

const [selectedEndDate, setSelectedEndDate] = React.useState(
  moment().format("YYYY-MM-DD")
);
//const [BrandCount,setBrandCount] = useState(0);
   const [dataItems, setDataItems] = useState([]);
   const [firstLoad, setFirstLoad] = useState(true);


   const [DatasetId, setDatasetvalue] = useState(0);

   const handleChange = (event, index) => {
     setDatasetvalue(event.target.value);
   };


   const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);

    let newStartDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newStartDate);

    //console.log("date: ",date);
    //console.log("selectedStartDate: ",selectedStartDate);
  };

   const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);

    let newEndDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newEndDate);
    //console.log("date: ",date);
    //console.log("selectedStartDate: ",selectedStartDate);
  };


  let params = {
    menukey: menukey,
    action: "getDhis2datatranserlogdList",
    toggleButtonValue: toggleButtonValue,
    DatasetId: DatasetId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const gettoggleButtonValueType11 = useQuery(
    [params],
    api.getDhis2datatranserlogdDetails,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  useEffect(() => {
    gettoggleButtonValueType11.refetch();
  }, [toggleButtonValue]);



  // React.useEffect(() => {
  //   prescriptionData.refetch();
  // },[toggleButtonValue]);

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Log Date"],
      field: "LogDate",
      headerSort: true,
      headerFilter: true,
      width:"10%",

    },
    {
      title: DispensingLanguage[lan][menukey]["Dataset Id"],
      field: "DatasetId",
      headerSort: true,
      headerFilter: true,
      width:"8%",

    },
    {
      title: DispensingLanguage[lan][menukey]["Organisation Unit Group Id"],
      field: "OrgGroupId",
      headerSort: false,
      headerFilter: true,
      width:"12%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Duration Hour"],
      field: "DurationDay",
      headerSort: false,
      headerFilter: true,
      width:"8%",
    },
  
    {
      title: DispensingLanguage[lan][menukey]["Duration Error Hour"],
      field: "ErrorHour",
      headerSort: false,
      headerFilter: true,
      width:"8%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Log Message"],
      field: "LogMessage",
      headerSort: true,
      headerFilter: true,
      width:"49%",
    },
  
  ];


  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }





  // const current = new Date();
  // const currentDate = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;
  
  return (
    <>
      <AfterLoginNavbar {...props} />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["DHIS2 Data Transfer Log"]}
              </div>
            </div>
          </div>


          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
              <CardContent className="sw_filterCardContent">
                  <Grid container  spacing={3}>
                      <Grid item xs={4} sm={4}>
                          <FormControl className={classes.fullWidth}>
                            <InputLabel id="">
                              {DispensingLanguage[lan][menukey]["DataSet"]} 
                            </InputLabel>
                            <Select
                            // error={props.errorObject.SupCode}
                              labelId="DatasetId"
                              id="DatasetId"
                              name="DatasetId"
                              value={DatasetId}
                            defaultValue='0'
                              fullWidth
                              onChange={handleChange}
                              // disabled={
                              //   manyTableData.length > 0 ? true : props.bStockUpdate
                              // }
                            >
                              <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                              {DataSetDHIS2List.map((item, index) => {
                                return (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                );
                              })}
                            </Select>

                            {/* <FormHelperText error={props.errorObject.SupCode}>
                              {props.errorObject.SupCode}
                            </FormHelperText> */}
                          </FormControl>
                  </Grid>


              <Grid item xs={4} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log End Date"]}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> 


            </Grid>
          </CardContent>

                <CardContent>
                <div className="row">
                    <div className="col-md-12 alignCenter">
                      <ToggleButtonGroup
                        className="sw_ToggleButtonGroup"
                        color="primary"
                        value={toggleButtonValue}
                        exclusive
                        onChange={handleChangeToggle}
                       
                      >
                        <ToggleButton value="General">
                        {DispensingLanguage[lan][menukey]['General']}
                        </ToggleButton>

                        <ToggleButton value="Errors">
                        {DispensingLanguage[lan][menukey]['Errors']}
                        </ToggleButton>
                  
                      </ToggleButtonGroup>
                    </div>
                    

                    <div className="col-md-4">
                    <div className="float-right">
                    
                    </div>
                  </div>
                  </div>
                
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

          {/* new row */}
    <div className="row mt-3">
      <div className="col-md-12">
        <Card className="sw_card">
            <CardContent className="sw_filterCardContent bdcolor">
                <Grid container>
                <div className="d-flex justify-product mb-2 ">
                  <Grid item xs={12} sm={12}>
                    <div className="sw_page_heading">
                      <div className="sw_heading_title">
                      { toggleButtonValue === "General" ? <>{DispensingLanguage[lan][menukey]["DHIS2 Data Transfer Log - General"]}</> : <>{DispensingLanguage[lan][menukey]["DHIS2 Data Transfer Log - Errors"]}</> }
                        
                      </div>
                    </div>
                  </Grid>
                </div>
                </Grid>

            </CardContent>

                <CardContent>
                <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                       <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={"350"}
                          options={{
                            groupBy: "DatasetLevel",
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>

            </CardContent>
        </Card>
      </div>
    </div>
          {/* end row */}

        </div>
      </div>
    </>
  );
};

export default Dhis2datatranserlogd;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },

});
