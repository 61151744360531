import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as api from '../../../actions/api';
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';

import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";
import "../common.css";

// react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import swal from "sweetalert";

let isDirty = false;
let deletedDatas = [];
let transactionItemID = 0;
let masterDirty = false;

const OrderApprovalFormData = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "order-approval";
  const { t, i18n } = useTranslation();
  let tblRef = null;
  const classes = useStyles();
  const FacilityName = localStorage.getItem("FacilityName");



  const [manyTableData, setManyTableData] = useState([]);
  const [bCompleted, setbCompleted] = useState(false);
  const OrderType = JSON.parse(localStorage.getItem("OrderType"));
  const OrderTo = JSON.parse(localStorage.getItem("FacilityList"));//JSON.parse(localStorage.getItem("OrderTo"));
  const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));
  const OrderListApprovalUser = JSON.parse(localStorage.getItem("OrderListApprovalUser"));
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));
  //toggole button
  const [toggleButtonValue, setToggleButton] = React.useState("1");
  //const [currItemGroupId, setCurrItemGroupId] = React.useState("0");

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {


      if (tmpToggleButtonValue == 2) {

      }

      // setLoadingPrescription(true);
      setToggleButton(tmpToggleButtonValue);
    }
  };

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? '' : UserInfo[0].id;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  //console.log("User Idssss",UserId);

  /*
  if(props.formData.ApprovedBy>0){
    props.formData.formData["ApprovedBy"] = props.formData.ApprovedBy;
  }else{
    props.formData.formData["ApprovedBy"] = UserId;
  }
  
  */





  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_print.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&OrderId=" + props.formData.OrderId
      + "&TimeStamp=" + Date.now()
    );
  };

  const ExcelMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_excel.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&OrderId=" + props.formData.OrderId
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */



  const manycolumns = [
    { title: 'Id', field: 'id', visible: false },
    {
      title: "dirty",
      field: "dirty",

      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['Sl#'], width: 50, hozAlign: 'center', formatter: "rownum", headerSort: false, headerHozAlign: "center" },
    { title: DispensingLanguage[lan][menukey]['Product Code'], field: 'ItemCode', width: 120, headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]['Product Name'], field: 'ItemName', headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]['AMC'], field: 'AMC', hozAlign: "right", headerHozAlign: "right", width: 65 },
    { title: DispensingLanguage[lan][menukey]['Min Qty'], field: 'MinQty', hozAlign: "right", headerHozAlign: "right", width: 60 },
    { title: DispensingLanguage[lan][menukey]['Max Qty'], field: 'MaxQty', hozAlign: "right", headerHozAlign: "right", width: 60 },
    { title: DispensingLanguage[lan][menukey]['Stock on Hand'], field: 'StockOnHand', width: 75, hozAlign: "right", headerHozAlign: "right" },
    { title: DispensingLanguage[lan][menukey]['Calculated Order Quantity'], field: 'CalcOrderQty', width: 100, hozAlign: "right", headerHozAlign: "right" },
    { title: DispensingLanguage[lan][menukey]['Submitted Order Quantity'], field: 'SubmittedOrderQty', width: 100, hozAlign: "right", headerHozAlign: "right" },

    {
      title: DispensingLanguage[lan][menukey]['Actual Order Quantity'],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,

      editor: props.bCompleted == 0 ? (props.isFirstPhysical == 0 ? cellFocusEditor : '') : '',   //"input" 
      cssClass: props.isFirstPhysical == 0 ? "tabluator-column-editable" : '',
      cellEdited: function (cell) {

        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        transactionItemID = rowdata.SKU;
        onSetDisable();
        //  setDirty(true);

        //  return;

      },


      // editor: bCompleted === 0 ? cellFocusEditor : '',
      // validator: ["integer", "min:0"],
      // cssClass: "tabluator-column-editable",
      // cellEdited: function (cell) {

      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      //   const pricecell = currow.getCell("LineTotal");
      //   const totprice = rowdata.UnitPrice * curcellval;
      //   pricecell.setValue(totprice);

      // },
    },

    //{ title: 'Actual Order Quantity', field: 'ActualOrderQty', hozAlign:"right", editor:true},
    {
      title: DispensingLanguage[lan][menukey]['Comment'],
      field: 'Remarks',
      width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
      editor: props.bCompleted == 0 ? (props.isFirstPhysical == 0 ? cellFocusEditor : '') : '',   //"input" 
      cssClass: props.isFirstPhysical == 0 ? "tabluator-column-editable text-field-editable" : '',
      cellEdited: function (cell) {

        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1);
        isDirty = true;
        transactionItemID = rowdata.SKU;
        onSetDisable();
        //  setDirty(true);

        //  return;

      },

      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: bCompleted === 0 ? true : ''
    },
    { title: DispensingLanguage[lan][menukey]['Unit Price'], field: 'UnitPrice', hozAlign: "right", headerHozAlign: "right", width: 90 },

    {
      title: DispensingLanguage[lan][menukey]['Line Total'],
      field: 'LineTotal',
      formatter: "money",
      hozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      headerHozAlign: "right",
      width: 105
    }

  ];


  const onSetDisable = () => {

    if (isDirty == true) {

      document.getElementById("fltProductgroup").classList.add("swDisabled");
      document.getElementById("tglbuttongroup").classList.add("swDisabled");
      document.getElementById("is_dirty_message").classList.remove("dnone");

      let dataArray = [];
      let gridData = tblRef.table.getData();
      gridData.forEach((row, i) => {
        if (row.SKU == transactionItemID) {
          dataArray.push(row);
          transactionItemID = 0;
        }
      });
      let fDtat = { ...props.formData, 'ManyJsonSave': dataArray, menukey: menukey, lan: lan, UserName: UserName };
      mutateCellUpdate(fDtat);


    }
    else {

      document.getElementById("fltProductgroup").classList.remove("swDisabled");
      document.getElementById("tglbuttongroup").classList.remove("swDisabled");
      document.getElementById("is_dirty_message").classList.add("dnone");



    }

  }


  const onMasterSetDirty = () => {
    if (masterDirty == true) {
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }


  const mutateCellUpdate = (fDtat) => {

    const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null;
    const apiUrl = process.env.REACT_APP_API_URL + "source/api/orderUpdateApproval";
    let data = fDtat;

    fetch(apiUrl, {
      method: "PUT",
      headers: {

        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth_token}`,
        "Consumer": process.env.REACT_APP_CONSUMER,
        "Secret": process.env.REACT_APP_SECRET,

      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        console.log(res);

        return res.json();
      })
      .then(function (data) {
        isDirty = false;
        onSetDisable();
      })
      .catch(error => {

        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
          msgtype: 0,
        });

      });


  }


  const ManyDataItems = props.ManyTableData?.map((item, index) => {
    item["LineTotal"] = item.ActualOrderQty * item.UnitPrice;
    return item
  })



  useEffect(() => {
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setbCompleted(props.bCompleted);
    onSetDisable();
    deletedDatas = [];
  }, [props.ManyTableData, props.bCompleted]);



  // const handleChange = (e) => {
  //   props.filterFandleChange(e.target.value);
  //   setCurrItemGroupId(e.target.value);
  // };

  const handleProductGroupChange = (event, newValue) => {

    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currItemGroupId) {
      if (props.addProductForm == false) {
        props.filterFandleChange(rowId);
      }
      setCurrItemGroupId(rowId);
    }
  };

  const onSaveUpdateBtnClick = () => {

    let dataArray = [];
    let gridData = tblRef.table.getData();
    gridData.forEach((row, i) => {
      if (row.SKU == transactionItemID) {
        dataArray.push(row);
        transactionItemID = 0;
      }
    })

    setUpdatedDetailsGridDataHandler(dataArray);
    //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 

  }



  const setUpdatedDetailsGridDataHandler = (rows) => {
    // let validForm = props.checkValidateForm(rows, 'edit');
    let validForm = props.checkValidateForm(tblRef.table.getData(), 'edit');

    if (validForm) {

      if (props.addProductForm) {
        //when save
        //mutate(validForm);
      } else {
        //when update
        let FinalData = { ...validForm, 'ManyJsonSave': rows };
        mutateUpdate(FinalData);

      }



    }
  };



  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.updateOrderApproval, {
    onSuccess: (data) => {

      if (data.status == 200) {

        masterDirty = false;
        onMasterSetDirty();

        props.successSave(data.IsCompleted);
        if (data.IsCompleted == 1) {
          mutatePost(props.formData.OrderId);
        }
        else {
          props.successSave(data.IsCompleted); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.postOrderApproval, {
    onSuccess: (data) => {
      if (data.status == 200) {


        if (data.success == 0) {

          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });


        } else {
          props.stockPosted(1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal(data.data.message, "", "success");


        }

      } else {
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const onPostBtnClick = () => {

    setbCompleted(true);
    onSetDisable();
    let dataArray = [];
    let gridData = tblRef.table.getData();
    gridData.forEach((row, i) => {
      if (row.SKU == transactionItemID) {
        dataArray.push(row);
        transactionItemID = 0;
      }
    })

    handlePost(dataArray);
    //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 

  }


  const handlePost = async (rows) => {

    let validForm = props.checkValidateForm(tblRef.table.getData(), 'post');


    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);

          let FinalData = { ...validForm, 'ManyJsonSave': rows, "IsCompleted": 1, menukey: menukey, lan: lan, "UserName": UserName };
          mutateUpdate(FinalData);
          setManyTableData(rows);

        } else {
          //setbCompleted(false); 
          props.stockPosted(0);
          let route = `${validForm.OrderId}`;
          props.history.push(route);
        }
      });
    }




  };

  const backToList = () => {
    if (masterDirty == true) {

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          masterDirty = false;
          props.history.push("/order-approval");

        }
      });

    } else props.history.push("/order-approval")

  };


  const formOnchange = (e, cases, inputname) => {
    console.log("On form change", inputname);
    //isDirty=true;
    masterDirty = true;
    onMasterSetDirty();
    if (cases == 'handleChange')
      props.handleChange(e);
    else if (cases == 'handleRDateChange')
      props.handleRDateChange(e, inputname);

  };


  const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

  const changeVal = (event, val, cases) => {
    console.log(event);
    switch (cases) {

      case 'ApprovedBy':
        setApprovedBy(val);
        props.formData.ApprovedBy = val != null ? val.id : "";
        break;

        break;


    }
    // if(val != null){    
    props.handleChange(event);
    // }

    masterDirty = true;
    onMasterSetDirty();

  };

  useEffect(() => {
    setApprovedBy(OrderListApprovalUser[OrderListApprovalUser.findIndex(OrderListApprovalUser => OrderListApprovalUser.id == props.formData.ApprovedBy)]);
  }, [props.formData.ApprovedBy]);


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>

            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Order - Add/Edit'] + ' (' + FacilityName + ')'}</div>

              <div className="float-right sw_btn_control">

                {/* Action buttons */}
                {
                  props.addProductForm ?
                    <Grid item xs={12} className="mt-4 text-center">
                      <div className="mr-4 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                      </div>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/order-approval")}
                        onClick={() => backToList()}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]['Reset']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]['Save']}
                      </Button>

                    </Grid>
                    :
                    <Grid item xs={12} className="mt-2 text-center">

                      <div className="mr-4 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                      </div>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => backToList()}

                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>

                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        // onClick={() => props.handleUpdate()}
                        onClick={onSaveUpdateBtnClick}
                        //disabled={bCompleted === 1}
                        disabled={(bCompleted === 1) || isLoadingUpdate || isLoadingPost}
                      >
                        {DispensingLanguage[lan][menukey]['update']}
                      </Button>


                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onPostBtnClick}
                        // onClick={() => props.handlePost()}
                        // disabled={bCompleted === 1}
                        disabled={(bCompleted === 1) || isLoadingUpdate || isLoadingPost}
                      >
                        {DispensingLanguage[lan][menukey]['APPROVE']}
                      </Button>




                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>

                    </Grid>
                }
                {/* End Action buttons */}

              </div>
            </div>
          </Grid>
        </div>






        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>



                  <Grid item xs={3} sm={3}>

                    <TextField
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      required
                      id="OrderNo"
                      name="OrderNo"
                      label={DispensingLanguage[lan][menukey]['Order#']}
                      value={props.formData.OrderNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />

                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]['Order Date']}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        onChange={(e) => props.handleRDateChange(e, "OrderDate")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={true}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3} sm={3}>



                    {/* 
    <FormControl className={classes.fullWidth}>
      <InputLabel id="demo-simple-select-helper-label">
      { DispensingLanguage[lan][menukey]['Order To'] }  
      </InputLabel>
      <Select
        error={props.errorObject.OrderingFrom}
        // helperText={props.errorObject.OrderingFrom}
        required
        labelId="demo-simple-select-helper-label"
        id="OrderingFrom"
        name="OrderingFrom"
        value={props.formData.OrderingFrom}
        fullWidth
        onChange={(e) => props.handleChange(e)}
        disabled={true}
        //disabled={bCompleted===1}
      >
      {
      OrderTo.map(
      (item, index) => {
        return (
          <MenuItem value={item.id}>
            {item.name}
          </MenuItem>
        );
      }
      )}
    </Select>

    <FormHelperText error={props.errorObject.OrderingFrom}>
      {props.errorObject.OrderingFrom}
    </FormHelperText>
    </FormControl> */}

                    <TextField
                      error={props.errorObject.OrderingFrom}
                      helperText={props.errorObject.OrderingFrom}
                      required
                      id="OrderingFrom"
                      name="OrderingFrom"
                      label={DispensingLanguage[lan][menukey]['Order To']}
                      value={props.formData.OrderingFromText}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />

                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="OrderByName"
                      name="OrderByName"
                      label={DispensingLanguage[lan][menukey]['Entry By']}
                      value={props.formData.OrderByName}
                      fullWidth
                      multiline
                      // onChange={(e) => props.handleChange(e)}
                      disabled
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['Entry By']}
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderBy}
                        // helperText={props.errorObject.OrderBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderBy"
                        name="OrderBy"
                        value={props.formData.OrderBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                      //disabled={bCompleted===1}
                      >
                        {
                          UsersListByFacility.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>

                      <FormHelperText error={props.errorObject.OrderBy}>
                        {props.errorObject.OrderBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}






                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['Approved By']}
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}
                        // helperText={props.errorObject.ApprovedBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={props.formData.ApprovedBy}
                        // value={props.formData.ApprovedBy > 0 ? props.formData.ApprovedBy : UserId}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) => formOnchange(e, 'handleChange', 'ApprovedBy')}
                        //disabled={true}
                        disabled={bCompleted === 1}
                      >
                        {
                          UsersListByFacility.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>

                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={bCompleted === 1}
                        autoHighlight
                        disableClearable
                        id="ApprovedBy"
                        options={OrderListApprovalUser}
                        onChange={(event, value) => changeVal(event, value, 'ApprovedBy')}
                        getOptionLabel={(option) => option.name}
                        value={ApprovedBy}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={DispensingLanguage[lan][menukey]["Approved By"]}
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            required
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>






                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['Order Type']}
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderTypeId}

                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderTypeId"
                        name="OrderTypeId"
                        value={props.formData.OrderTypeId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                      //disabled={bCompleted===1}
                      >
                        {
                          OrderType.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}

                      </Select>
                      <FormHelperText error={props.errorObject.OrderTypeId}>
                        {props.errorObject.OrderTypeId}

                      </FormHelperText>

                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>

                    <TextField

                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]['Remarks']}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      onChange={(e) => props.handleChange(e)}
                      disabled
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['InternalApprover']}
                      </InputLabel>
                      <Select
                        error={props.errorObject.InternalApprover}

                        labelId="demo-simple-select-helper-label"
                        id="InternalApprover"
                        name="InternalApprover"
                        value={props.formData.InternalApprover}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                      >
                        {
                          OrderListApprovalUser.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                      <FormHelperText error={props.errorObject.InternalApprover}>
                        {props.errorObject.InternalApprover}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}





                  <Grid item xs={3} sm={3}>

                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="OrderId"
                      name="OrderId"
                      label="OrderId"
                      value={props.formData.OrderId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                     <TextField
                      id="OrderBy"
                      name="OrderBy"
                      label="OrderBy"
                      value={props.formData.OrderBy}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />


                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">

                        {/* <Grid item xs={2} sm={2}>
                          <FormControl id="fltProductgroup" className={classes.fullWidth}>
                            <InputLabel id="demo-simple-select-helper-label">
                              {DispensingLanguage[lan][menukey]['Product Group']}
                            </InputLabel>
                            <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) => handleChange(e)}
                            >
                              <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                              {
                                GroupList.map(
                                  (item, index) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                            <FormHelperText error={props.errorObject.ItemGroupId}>
                              {props.errorObject.ItemGroupId}
                            </FormHelperText>
                          </FormControl>

                        </Grid> */}

                        <Grid item xs={3} sm={3}>
                          <FormControl id="fltProductgroup" className={classes.fullWidth}>
                            <Autocomplete
                              autoHighlight
                              className="sw_chosen_filter"
                              id="ItemGroupId"
                              disableClearable
                              options={GroupList}
                              onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                              getOptionLabel={(option) => option.name}
                              defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                              renderOption={(option) => (
                                <Typography className="sw_fontSize">{option.name}</Typography>
                              )}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={DispensingLanguage[lan][menukey]["Product Group"]}
                                  variant="standard"
                                  name="ItemGroupId"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                          <div className="float-right">
                            <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                          </div>
                        </Grid>

                        <Grid className="text-center" item xs={5} sm={5}>
                          <div className="float-right">
                            <ToggleButtonGroup
                              hidden={true}
                              id="tglbuttongroup"
                              className="sw_ToggleButtonGroup"
                              color="primary"
                              value={toggleButtonValue}
                              exclusive
                              onChange={handleChangeToggle}
                            >
                              <ToggleButton value="2">
                                {
                                  DispensingLanguage[lan][menukey][
                                  "All"
                                  ]
                                }
                              </ToggleButton>
                              <ToggleButton value="1">
                                {
                                  DispensingLanguage[lan][menukey][
                                  "ONLY ORDERED ITEMS"
                                  ]
                                }
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        </Grid>
                      </Grid>


                      <div>
                        <div className="uniqueName">
                          <ReactTabulator

                            ref={(r) => (tblRef = r)}
                            columns={manycolumns}
                            data={manyTableData}
                            layout={"fitColumns"}
                            height="340px"
                            options={{
                              groupBy: "GroupName",
                              columnCalcs: "both",
                            }}



                          />


                        </div>
                      </div>


                    </CardContent>
                  </Card>
                </Grid>


              </Grid>
            </Grid>
          </Grid>



        </Grid>
      </div>
    </div>
  );
};

export default OrderApprovalFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
