import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import { 
  Typography,
   TextField,
  Grid, 
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,

} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

import SVDLProductModal from "../../../services/SVDLProductModal";
import Autocomplete from '@material-ui/lab/Autocomplete';
//const UserInfo = JSON.parse(sessionStorage.getItem("User_info"));



const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const SvdlProductEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(15);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  //const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [ManyselectData, setManySelectData] = useState([]);
  //const [currProductGroupId, setCurrProductGroupId] = useState(0);
  const [all, setAll] = React.useState('0');
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();



  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProdutlist] = useState(0);
  const [ProductGroupName, setProductGroupName] = useState([]);


  // Facility Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
     
    }

  };







let params = {
  action: "getSVDLProduct",
  menukey: menukey,
  ItemGroupId:currProductGroupId,
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
};

  const {
    error,
    isError,
    
    refetch: tableData,
  } = useQuery([params], api.getSVDLProduct, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });



  const { mutate: deleteApi } = useMutation(api.deleteSVDLProduct, {
    onSuccess: (data) => {

      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        tableData();
        queryClient.getQueriesData("svdlproductentry");

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }



    },
    onError: (data) => {

    }


  });


  const { isLoading, mutate:mutateUpdate } = useMutation(api.saveSVDLProduct, {
    onSuccess: (data) => {
      if (data.status == 200) {
        handleClose('Products');
        tableData();

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })

  // const handleProductGroupChange = (event) => {
  //   setAll(event.target.value);
  //   setCurrProductGroupId(event.target.value);
    
  // };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=getSVDLProductEntryData" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&ItemGroupId=" +
      currProductGroupId +
      "&lan=" +
      localStorage.getItem("LangCode")+
     "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      default:
        break;
    }
  }

  const addProduct = (NonOrderProductData) => {  

    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;

      newRow.SKU=autoId;
      newRow.id=autoId;  
      rows.push(newRow);
    
  });

 
  if(rows.length>0){
    let fDtat={'ManyJsonSave':rows,UserId:UserId,lan:lan}; 

       mutateUpdate(fDtat); 
   
   }

  };




  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }




  let sl = 0;
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      // formatter: "rownum",
      formatter: function (cell, formatterParams) {
        sl++;
        return (pageNumber - 1) * numberRowPerPage + sl;
      },
      width: 50,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",

    },
    {
      title:  "",
      field: "id",
      width: 80,
      visible: false,
      headerSort: false,
    },
    { 
    title: DispensingLanguage[lan][menukey]["Subgroup"],
    headerFilter: true, 
    width: 120, 
    field: "ProductSubGroupName" 
  },
    { 
    title: DispensingLanguage[lan][menukey]["Product Code"],
    headerFilter: true, 
    field: "ItemCode" ,
    width: 85, 
    },
    { 
    title: DispensingLanguage[lan][menukey]["Product Name"],
    headerFilter: true, 
    field: "ItemName" ,
    
    },
    { 
    title: DispensingLanguage[lan][menukey]["Short Name"],
    headerFilter: true, 
    field: "ShortName" ,
    width:250, 
    },
    { 
    title: DispensingLanguage[lan][menukey]["Unit Name"],
    headerFilter: true, 
    field: "UnitName" ,
    width:80, 
    },
    { 
    title: DispensingLanguage[lan][menukey]["Pack Size"],
    headerFilter: true, 
    field: "PackSize" ,
    width:70, 
    },
    { 
    title: DispensingLanguage[lan][menukey]["Cost (CFA)"],
    headerFilter: true, 
    field: "Price",
    width:80,  
    },
    {
      title: DispensingLanguage[lan][menukey]["Key Product"],
      field: "bKeyItem",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 65,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bKeyItem"
          fieldType="check-box"
        />
      ),
      
    },
    {
      title: DispensingLanguage[lan][menukey]["Active"],
      field: "isActive",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 55,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="isActive"
          fieldType="check-box"
        />
      ),
      
    },
    { 
      title: DispensingLanguage[lan][menukey]["CAME Code"],
      headerFilter: true, 
      field: "CameCode" ,
      width: 130,
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 60,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

 

  const editunit = (data) => {
    let route = `svdl-product-entry/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Data!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({ ItemNo: data.id, "menukey": menukey, "lan": lan, "UserName": UserName, "action": "dataDelete" });

      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        />
      </>
    );
  }

  
  


  return (
    <>
      <div className={classes.dispenserPageTitle}>
      <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["SVDL Product Entry"])}
              </div>
            </div>
          </Grid>
        </div>


      {/* start of filter */}
      <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
          
            <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ItemGroupId"
                          disableClearable
                          options={ProductGroup}
                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                    </FormControl>
                  </Grid> 


              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">
                <Button
                 
                  className="mr-2"
                  variant="contained"
                  color="info"
                  onClick={() => handleClose("ProductsOpen")}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i><span>&nbsp;</span>
                  {DispensingLanguage[lan][menukey]['Add Product']}
                </Button>


                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                <SVDLProductModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} />
                </div>
               
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

       
       
          <div className="uniqueName">

            
            <ReactTabulator
              columns={columns}
              data={dataItems}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              layout={"fitColumns"}
              height={400}

            />
          </div>
          
          
       
      </div>
    </>
  );
};

export default SvdlProductEntry;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
