
import React, { useState, useEffect } from "react";

// Google Map
import { Map, InfoWindow, GoogleApiWrapper, Marker  } from 'google-maps-react';

// core components
function GoogleMapForContactPage(props) {
  const mapStyles = {
    width: '95%',
    height: '100%',
    overflow:"hidden !important",
  };

  return (
    <>

      <Map
          google={props.google}
          zoom={9}
          style={mapStyles}
          initialCenter={{
              lat: 9.314623823,
              lng: 2.31184834
            } }
        >
          <Marker 
            title="ePharmacie"
            position={{ lat: 9.314623823, lng: 2.31184834}}
          />
      </Map>

    </>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCD7OEdGUC1V__0-mBJIoYifI5UtEILYbg'
})(GoogleMapForContactPage);