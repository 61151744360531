import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select, Typography, TextField, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, useEventCallback } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const AdjustmentSummaryReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);

  const [acValue, setACValue] = useState("");

  const tmpStoreList = JSON.parse(localStorage.getItem("gStoreListforreceivesummary"));
  const [AStoreList, setStorelist] = useState(tmpStoreList);
  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  const [currStoreId, setStoreId] = useState();
  const [StoreName, setStoreName] = useState();

  //Flevel filter section
  const FlevelList = JSON.parse(localStorage.getItem("FlevelListForReport"));
  const [currFlavelId, setCurrFacilitylist] = useState(FlevelList[0].id);
  const [FacilityLavel, setFacilityLavel] = useState([]);

  // Facility Level Change
  const handleFacilityLevelChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currFlavelId) {
      setCurrFacilitylist(rowId);
      setFacilityLavel(rowName);
      //getStoreListData(rowId);
    }
    //console.log('Cureent id',rowId)

    getFacilityData(rowId);
    let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: newValue.id,lan:lan, menukey: "" }
    cStoreList(StoreListParam);
  };


  React.useEffect(() => {
 
    
    if (currFlavelId == FlevelList[0].id) {
      setFacilityLavel(FlevelList[0].name);
      let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: currFlavelId,lan:lan, menukey: "" }
      cStoreList(StoreListParam);
    }

  }, [currFlavelId]);



  const getFacilityData = (rowId) =>{
    let FacilityParam = { action: "getFacility", FLevelId:rowId, menukey: "" }
    cFacilityList(FacilityParam);
  }

  const { mutate: cFacilityList } = useMutation(

    api.getFacility,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
//console.log(data.data.datalist);
          setFacilityData(data.data.datalist);
          setACValue({ id: '0', name: "All"});
        }
      },
    }
  );

  //ZoneList Filter Section

  let TempZoneList = useState([]);
  const [Facility_list, setFacilityData] = useState(TempZoneList);

  let All_FacilityList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityListDataItem = [All_FacilityList].concat(Facility_list);
  const [currFacilityId, setFacilityId] = useState("0");
  const [FacilityName, setFacilityName] = useState([]);


  const handleFacilityListChange = (event, newValue) => {
    setACValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if(rowId !== currFacilityId) {
      setFacilityId(rowId);
      setFacilityName(rowName);
    }
  };


React.useEffect(() => {
  if(currFacilityId=='0'){
    getFacilityData(currFlavelId);
  }
    
  }, [currFacilityId]);


 
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM-DD') 
  );
  //console.log("Selected Date",selectedStartDate);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD') 
  );
  
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };


  const AdjustmentType = JSON.parse(localStorage.getItem("gAdjustmentTypeByFacility"));

  let All_Adjustment = { id: "0", AdjType: t(DispensingLanguage[lan][menukey]["All"]) };
  const getAdjustmentList = [All_Adjustment].concat(AdjustmentType);
  const [currAdjustmentId, setAdjustmentList] = useState(0);
  const [AdjustmentName, setAdjustment] = useState([]);
//  console.log(getAdjustmentList);
  //Facility Change
  const handleAdjustmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.AdjType;
    }if (rowId !== currAdjustmentId) {
      setAdjustmentList(rowId);
      setAdjustment(rowName);
    }
   
  };


   // Store Change
   const handleStoreChange = (event, newValue) => {
    setACstoreValue(newValue);
    let rowId = '';
    let Sname = '';
    if (newValue == null) {
      rowId = '';
      Sname = '';
    } else {
      rowId = newValue.id;
      Sname = newValue.name;
    }
    if (rowId !== currStoreId) {
      setStoreId(rowId);
      setStoreName(Sname);
      

    }
  };

//store load by facility
const { mutate: cStoreList } = useMutation(
  api.StoreListForReport,
  {
    onSuccess: (data) => {
      if (data.status == 200) {

        // console.log("Dta list", data.data.datalist);
       
        setStorelist(data.data.datalist);
        setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});
        setStoreId(AStoreList[0].id);
        setStoreName(AStoreList[0].name);

      }

    },
  }
);


  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getAdjustmentSummaryList",
    FLevelId: currFlavelId,
    FacilityId: currFacilityId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    AdjustmentId: currAdjustmentId,
    lan:lan,
    StoreId: currStoreId,
    // TransactionId: currTransactionId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.GetAdjustmentReport,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["To_From"],
      field: "Recipient",
      headerSort: false,
      headerFilter: false,

    },
   
    {
      title: DispensingLanguage[lan][menukey]["Adjustment_date"],
      field: "TransactionDate",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
      formatter: function (cell) {
        let value = cell.getValue();
        if (value != null)
          return moment(value).format("DD/MM/YYYY");
        else
          return "";
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment_Invoice#"],
      field: "AdjustmentInvoice",
      headerFilter: true,
      headerSort: false,

    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment_type"],
      field: "AdjType",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",

    },
    {
      title: DispensingLanguage[lan][menukey]["Total_CFA"],
      field: "Amount",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width:100

    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
      "?action=getAdjustmentSummeryReport" +
      "&reportType=" +
      reportType +
      "&FLevelId=" +
      currFlavelId +
      "&FacilityId=" +
      currFacilityId +
      "&AdjustmentId=" +
      currAdjustmentId +
      "&StoreId=" +
      currStoreId +
      "&FacilityLavel=" +
      FacilityLavel +
      "&FacilityName=" +
      FacilityName +
      "&AdjustmentName=" +
      AdjustmentName +
      "&StoreName=" +
      StoreName +
      "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
      "&menukey=" +
      menukey +
      "&lan=" +
     localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
        <div className="d-flex justify-uiLanguage">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title1">
                {DispensingLanguage[lan][menukey]["Adjustment_Summary_Report"]}
              </div>
              <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
            </div>
          </Grid>
        </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FlevelList"
                          disableClearable
                          options={FlevelList}
                          onChange={(event, newValue) => handleFacilityLevelChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FlevelList[FlevelList.findIndex(FlevelList => FlevelList.id == currFlavelId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility Level"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FacilityListDataItem"
                          disableClearable
                          options={FacilityListDataItem}
                          value={acValue}
                          onChange={(event, newValue) => handleFacilityListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FacilityListDataItem[FacilityListDataItem.findIndex(FacilityListDataItem => FacilityListDataItem.id == currFacilityId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start date"]
                                }
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End date"]
                                }
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                  
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>

                  <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getAdjustmentList"
                          disableClearable
                          options={getAdjustmentList}
                          onChange={(event, newValue) => handleAdjustmentChange(event, newValue)}
                          getOptionLabel={(option) => option.AdjType}
                          defaultValue={getAdjustmentList[getAdjustmentList.findIndex(getAdjustmentList => getAdjustmentList.id == currAdjustmentId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.AdjType}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Adjustment_type"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreList"
                      disableClearable
                      options={AStoreList}
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                     // defaultValue={AStoreList[AStoreList.findIndex(AStoreList => AStoreList.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Store"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                    </Grid>
                    </Grid>

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    options={{
                      groupBy: "Recipient",
                      columnCalcs: "both",
                    }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default AdjustmentSummaryReport;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});