

import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  CardContent,
  Card
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import Autocomplete from '@material-ui/lab/Autocomplete';
const Products = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "generics";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dataItems, setDataItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [DosageFormList, setDosageFormList] = useState([]);
  const [PacksizeList, setPacksizeList] = useState([]);
  const [StrengthList, setStrengthList] = useState([]);
  const [currStrengthId, setStrengthId] = useState(0);
  const [currDciId, setDciId] = useState(0);
  const [currDosageFormId, setDosageFormId] = useState(0);
  const [currPacksizeId, setPacksizeId] = useState(0);
  const [DciList, setDciList] = useState([]);
  const [Dci, setDci] = React.useState(true);
  const [DosageForm, setDosageForm] = React.useState(true);
  const [Packsize, setPacksize] = React.useState(true);
  const [Strength, setStrength] = React.useState(true);
  const [ProductTableData, setProductTableData] = React.useState(true);
  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
  const [currProductGroupId, setProductGroupId] = useState(0);

  let params = {
    menukey: menukey,
    action: "getDataList",
    StrengthId: currStrengthId,
    PacksizeId: currPacksizeId,
    DosageFormId: currDosageFormId,
    DciId: currDciId,
    DciId: currDciId,
    ItemGroupId: currProductGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProducts,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  // const { error, isError, data, refetch } = useQuery(
  //   "generics",
  //   api.getProducts,
  //   {
  //     onSuccess: (data) => {
  //       setDataItems(data);
  //       setLoading(false);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );

  const { mutate } = useMutation(api.deleteProduct, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        refetch();
        queryClient.getQueriesData("generics");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  if (ProductTableData) {
    setProductTableData(false);
    refetch();
  }

  if (Strength) {
    setStrength(false);
    let ParamList = { action: "getStrengthList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setStrengthList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleStrengthChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getDataList",
      StrengthId: rowId,
      PacksizeId: currPacksizeId,
      DosageFormId: currDosageFormId,
      DciId: currDciId,
      ItemGroupId: currProductGroupId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setStrengthId(rowId);
    refetch();
  };

  if (Packsize) {
    setPacksize(false);
    let ParamList = { action: "getPacksizeList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setPacksizeList(res.datalist);
      })
      .catch((err) => { });
  }

  const handlePacksizeChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getDataList",
      StrengthId: currStrengthId,
      PacksizeId: rowId,
      DosageFormId: currDosageFormId,
      DciId: currDciId,
      ItemGroupId: currProductGroupId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setPacksizeId(rowId);
    refetch();
  };

  if (DosageForm) {
    setDosageForm(false);
    let ParamList = { action: "getDosageFormList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setDosageFormList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleDosageFormChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getDataList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId: rowId,
      DciId: currDciId,
      ItemGroupId: currProductGroupId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDosageFormId(rowId);
    refetch();
  };

  if (Dci) {
    setDci(false);
    let ParamList = { action: "getDciList" }
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setDciList(res.datalist);
      })
      .catch((err) => { });
  }

  const handleDciChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getDataList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId: currDosageFormId,
      DciId: rowId,
      ItemGroupId: currProductGroupId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDciId(rowId);
    refetch();
  };


  const handleProductGroupChange = (event, newValue) => {
    let rowId = 0;
    if (newValue == null) {
      rowId = 0;
    } else {
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getDataList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId: currDosageFormId,
      DciId: currDciId,
      ItemGroupId: rowId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setProductGroupId(rowId);
    refetch();
  };
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=ProductsListExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode")
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 50,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen: true,
    },
    {
      title: "",
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 40,
      frozen: true,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic Id"],
      field: "ItemNo",
      width: 85,
      headerFilter: true,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic"],
      field: "ItemName",
      headerFilter: true,
      // width: 450,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Strength"],
      field: "StrengthName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pack Size"],
      field: "PacksizeName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Form"],
      field: "DosageFormName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["DCI"],
      field: "DciName",
      width: 250,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 100,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Code"],
      field: "SOBAPSCode",
      width: 100,
      headerFilter: true,
    }
  ];

  const editProduct = (data) => {
    let route = `generics/edit/${data.ItemNo}`;
    props.history.push(route);
  };

  const deleteProduct = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Once deleted, you will not be able to recover this Product!"]),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.ItemNo);
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editProduct(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteProduct(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Generics"]}
              </div>

            </div>
          </Grid>
        </div>
        
        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    id="ItemGroupId"
                    name="ItemGroupId"
                    options={ProductGroupList}
                    onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    id="DciId"
                    name="DciId"
                    options={DciList}
                    onChange={(event, newValue) => handleDciChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["DCI"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={4}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/generics/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Generic"]}
                  </Button>
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="StrengthId"
                        name="StrengthId"
                        options={StrengthList}
                        onChange={(event, newValue) => handleStrengthChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Strength"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="PacksizeId"
                        name="PacksizeId"
                        options={PacksizeList}
                        onChange={(event, newValue) => handlePacksizeChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Pack Size"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="DosageFormId"
                        name="DosageFormId"
                        options={DosageFormList}
                        onChange={(event, newValue) => handleDosageFormChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Form"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
            {isLoading && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              layout={"fitColumns"}
              height={450}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
