import React, { useRef,useState} from "react";
import { Link } from "react-router-dom";
import moment from 'moment'
import {
 CardMedia,
 Grid,
 Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as Service from "../../services/Service.js";

const PostDetailsContent = (props) => {
  

    
  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "details-post";


//Details Post code here
  const [dataItems, setDataItems] = useState([]);
  const [getNewsData, setNewsPost] = React.useState(true);
  const { id } = useParams();
  let params = {
    menukey: menukey,
    action: "getDetailsList",
    id:id,
    lan: lan
  };

  if(getNewsData){
    setNewsPost(false);
    Service.default
        .postApi("source/api/api_pages/news.php",params)
        .then((res) => {
          setDataItems(res.datalist);
        })
        .catch((err) => {});
  }

 //All News code here
  const [dataAll, setNewALl] = useState([]);
  const [getNewsDataAll, setNewsAll] = React.useState(true);
  let paramsAll = {
    menukey: menukey,
    action: "getNewsAllPost",
    lan: lan
  };

  if(getNewsDataAll){
    setNewsAll(false);
    Service.default
        .postApi("source/api/api_pages/news.php",paramsAll)
        .then((res) => {
          setNewALl(res.datalist);
        })
        .catch((err) => {});
  }

  const onClickHandaler = (item) => {
    setNewsPost(true);
    let route = `${item.id}`;
    props.history.push(route);
  };

  React.useEffect(() => {
    setNewsPost(true);
    setNewsAll(true);
  }, [lan]);

  return (
    <>
      <div className="inner-section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>

            <Grid item xs={4} sm={4}>
              <h4>All Post</h4>
              {dataAll.map((item, index) => {
                  return (
                    <>
                    <Typography variant="subtitle1" gutterBottom component="div" key={index.id}>
                   
                    <Link href="javascript:void(0)" onClick={() => onClickHandaler(item,id)}>{item.title}</Link>
                   </Typography>
                    <div className="Post_metaDataCss1">
                      <ul>
                      <li>{moment(item.date).format("DD MMM YYYY, h:mm A")}</li>
                    
                      </ul>
                    </div>
                    <p style={{textAlign: "justify"}}>{item.short_description}</p>
                    </>
                    );
                  })}
              </Grid>
              <Grid item xs={8} sm={8}>
               
                {dataItems.map((item, index) => {
                  return (
                    <>
                    <CardMedia component="img" height="240" src={ item.image ? (item.image) : require("assets/img/bg_3.jpg") } alt="Post image name"/>
                    <h3 className="title" key={index.id}>{item.title}</h3>
                    <div className="Post_metaDataCss">
                      <ul>
                      <li> {moment(item.date).format("DD MMM YYYY, h:mm A")}</li>
                      
                      </ul>
                    </div>
                    <p style={{textAlign: "justify"}}>{item.full_description}</p>
                    </>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
         </div>
       </div>
    </>
  );
};

export default PostDetailsContent;


  