import React, { useState, useEffect } from "react";
import { makeStyles} from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import { CloudUploadOutlined, Clear } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

const MenuEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  changeHandlerForFileUpload,
  DeleteUploadFile,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "menu-entry";
  const ParentMenu = JSON.parse(localStorage.getItem("ParentMenu"));
  const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));

  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const Image_URL = removeStrFromURL.replace('backend/', '');
  const reportImages = GlobalSettings.report_images;
  const [ParentMenuValue, setParentIdValue] = useState({ id: '', name: "" });

  useEffect(() => {
    setParentIdValue(ParentMenu[ParentMenu.findIndex(ParentMenu_List => ParentMenu_List.id == formData.parentId)]);

  }, [formData.parentId]);

  const changeParentId = (val) => {
    setParentIdValue(val);
    if (val != null) {
      formData.parentId = val.id;
    } else {
      formData.parentId = "";
    }
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Menu Entry - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Menu Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                  <Grid container spacing={4}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.title}
                          helperText={errorObject.title}
                          required
                          id="title"
                          name="title"
                          label={DispensingLanguage[lan][menukey]['Menu Name']}
                          value={formData.title}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                      <TextField
                          error={errorObject.url}
                          helperText={errorObject.url}
                          required
                          id="url"
                          name="url"
                          label={DispensingLanguage[lan][menukey]['Menu Url']}
                          value={formData.url}
                          fullWidth
                          autoComplete="family-name"
                          disabled={!addProductForm}
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu Position']}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="position"
                          value={formData.position}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          <MenuItem value={"left_position"}>Left Position</MenuItem>
                          <MenuItem value={"right_position"}>Right Position</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu MenuLevel']}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="MenuLevel"
                            value={formData.MenuLevel}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={"menu_level_1"}>Menu Level 1</MenuItem>
                            <MenuItem value={"menu_level_2"}>Menu Level 2</MenuItem>
                            <MenuItem value={"menu_level_3"}>Menu Level 3</MenuItem>
                            <MenuItem value={"page_menu_level"}>Page Menu Level</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} sm={3}>
                          <FormControl className={classes.fullWidth}>

                        <Autocomplete
                        autoHighlight
                        id="parentId"
                        options={ParentMenu}
                        onChange={(event, value) => changeParentId(value)}
                        getOptionLabel={(option) => option.name}
                        value={ParentMenuValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.parentId}
                            label={DispensingLanguage[lan][menukey]["Parent menu"]}
                            variant="standard"
                            error={errorObject.parentId}
                            helperText={errorObject.parentId}  
                            id="parentId"
                            name="parentId"
                            fullWidth
                          />
                        )}
                      />
                      </FormControl>
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Use Site']} </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="UseSite"
                            value={formData.UseSite}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={"svdl"}>SVDL</MenuItem>
                            <MenuItem value={"esigl"}>eSIGL</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="Description"
                          name="Description"
                          label={DispensingLanguage[lan][menukey]["Description"]}
                          value={formData.Description}
                          fullWidth
                          multiline
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <div className="tp-body" style={{paddingLeft: 0, paddingRight:0 }}>
                          <div className="tp-file-upload">
                            <input
                              type="text"
                              name="selectFile"
                              id="selectFile"
                              value={formData.DashboardImage}
                              placeholder={
                                DispensingLanguage[lan][menukey]["Choose a file"] 
                              }
                              className="tp-form-control"
                              readOnly={true}
                            />
                            <div className="file_browse_box">
                              <input
                                type="file"
                                name="DashboardImage"
                                id="DashboardImage"
                                className="tp-upload"
                                onChange={changeHandlerForFileUpload}
                              />
                              <label for="DashboardImage" className="file_browse_icon">
                                <span className="icon-upload">
                                  <CloudUploadOutlined />
                                </span>
                                {DispensingLanguage[lan][menukey]["Browse"] }
                              </label>
                            </div>
                          </div>
                          { formData.DashboardImage  && (
                          <div className="up_image_card">
                            <Button
                                className="delete_icon"
                                onClick={() => DeleteUploadFile()}
                              >
                              <Clear />
                            </Button>
                            
                            <div className="imagecard">
                              <img src={ Image_URL + reportImages +'/'+ formData.DashboardImage} alt=""></img>
                            </div>
                          </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                 {DispensingLanguage[lan][menukey]['Reset']} 
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
               {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/menu-entry")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/menu-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default MenuEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
