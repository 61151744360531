import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const Commercial = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "commercial";

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { refetch } = useQuery(
    "commercial",
    api.getCommercials,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  const { mutate } = useMutation(api.deleteCommercial, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

         api.getAllDropdown("Commerciallist").then((response) => {
          localStorage.setItem(
            "Commerciallist",
           JSON.stringify(response.datalist.Commerciallist)
           );
         });

        refetch();
        queryClient.getQueriesData("commercial");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{   
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
    },
  });



  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=CommercialExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Commercial Code"],
      width: 150,
      headerFilter:true,
      field: "id",
    },
    {
      title: DispensingLanguage[lan][menukey]["Commercial Name"],
      headerFilter:true,
      field: "CommercialName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editCommercial = (data) => {
    let route = `commercial/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteCommercial = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Commercial!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editCommercial(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteCommercial(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.commercialPageTitle}>
        <div className="d-flex justify-commercial mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Commercial"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/commercial/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add Commercial"]}
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={600}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commercial;

const useStyles = makeStyles({
  commercialPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
