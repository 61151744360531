
import moment from "moment";
import axios from "axios";
import { PermCameraMicSharp } from "@material-ui/icons";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "source/api/",
  // baseURL: "http://localhost/DISPENSINGMAIN/dispensing-mock/backend/source/api/",
});

const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;

let options = {};
options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${auth_token}`,
    "Consumer": process.env.REACT_APP_CONSUMER,
    "Secret": process.env.REACT_APP_SECRET,
  },
};

const BRAND_LEN = 22;
const EAN_13_LEN = 13;
const SSCC_LEN = 20;
const EAN_14_LEN = 14;
let data = "";

const IDENTIFIER_GTIN = "01";
const IDENTIFIER_EXPIRY = "17";
const IDENTIFIER_BATCH = "10";
const IDENTIFIER_SERIAL = "21";
const IDENTIFIER_MFG = "11";
const IDENTIFIER_SSCC = "00";
const IDENTIFIER_BRANDCODE = "93";


const barcodeScan = (barCode) => {

  try {

    //When first character is not a number then it is a brand code
    const Brand_code = barCode.substring(0, 1);

    if (!Brand_code.match(/^[0-9]+$/)) {

      if( barCode.length < BRAND_LEN ) {
        throw "Invalid Barcode.";
      }

      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'BRAND_CODE',
        SSCC: "",
        GTIN: "",
        Brandcode: barCode,
        BatchNo: '',
        ExpiryDate: '',
        SerialNo: "",
      }
    } else if (barCode.length === EAN_13_LEN) {
      //console.log("GTIN EAN_13: ", barCode);
      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'EAN_13',
        SSCC: "",
        GTIN: barCode,
        Brandcode: "",
        BatchNo: '',
        ExpiryDate: '',
        SerialNo: "",
      }
    } else if (barCode.length === EAN_14_LEN) {
      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'EAN_14',
        SSCC: "",
        GTIN: barCode,
        Brandcode: "",
        BatchNo: '',
        ExpiryDate: '',
        SerialNo: "",
      }
    } else if (barCode.startsWith(IDENTIFIER_GTIN)) {
      //console.log("GTIN CODE_128/DATA_MATRIX: ", barCode);
      const parsedVal = parseCodeForGTIN(barCode);
      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'GTIN',
        SSCC: "",
        GTIN: parsedVal.gtinValue,
        Brandcode: "",
        BatchNo: parsedVal.batchValue,
        ExpiryDate: getDate(parsedVal.expValue),
        SerialNo: parsedVal.serialValue,
      }
    } else if (
      barCode.startsWith(IDENTIFIER_SSCC) &&
      barCode.length === SSCC_LEN
    ) {
      //console.log("SSCC CODE_128: ", barCode);
      const sscc = barCode.substring(2);
      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'SSCC',
        SSCC: sscc,
        GTIN: '',
        Brandcode: "",
        BatchNo: '',
        ExpiryDate: '',
        SerialNo: "",
      }
    } else if (barCode.startsWith(IDENTIFIER_BRANDCODE)) {
      const parsedVal = parseCodeForGTIN(barCode);
      data = {
        bSuccess: true,
        OriginalCode: barCode,
        Type: 'GTIN',
        SSCC: "",
        GTIN: '',
        Brandcode: parsedVal.brandValue,
        BatchNo: parsedVal.batchValue,
        ExpiryDate: getDate(parsedVal.expValue),
        SerialNo: parsedVal.serialValue,
      }
    } else {
      throw "Invalid Barcode!";
    }

    return data;

  } catch (error) {
    return {
      OriginalCode: barCode,
      SSCC: '',
      GTIN:'',
      Brandcode: '',
      BatchNo: '',
      ExpiryDate: '',
      SerialNo: '',
      bSuccess: false,
      Error: error,
    };
  }
}

const parseCodeForGTIN = (payload) => {
  let gtinValue = "";
  let brandValue = "";
  let expValue = "";
  let mfgValue = "";
  let batchValue = "";
  let serialValue = "";

  const splitData = payload.split("\u001d");

  for (let index = 0; index < splitData.length; index++) {
    let inputStr = splitData[index];

    while (inputStr.length > 0) {
      const indentity = inputStr.substring(0, 2);
      if (indentity === IDENTIFIER_GTIN) {
        gtinValue = inputStr.substring(2, 16);
        inputStr = inputStr.substring(16);
      } else if (indentity === IDENTIFIER_BRANDCODE) {
        brandValue = inputStr.substring(2, BRAND_LEN + 2);
        inputStr = inputStr.substring(brandValue.length + 2);       
      } else if (indentity === IDENTIFIER_MFG) {
        mfgValue = inputStr.substring(2, 8);
        inputStr = inputStr.substring(8);
      } else if (indentity === IDENTIFIER_EXPIRY) {
        expValue = inputStr.substring(2, 8);
        inputStr = inputStr.substring(8);
      } else {
        const restString = inputStr.substring(2, inputStr.length);
        //const restString = inputStr;       
        const nextIndex1 = -1;//findNextIndex(restString);
        if (indentity === IDENTIFIER_SERIAL) {
          serialValue = inputStr.substring(
            2,
            nextIndex1 != -1 ? nextIndex1 : inputStr.length
          );

          if (nextIndex1 != -1)
            inputStr = inputStr.substring(serialValue.length + 2);
          else inputStr = "";
        } else if (indentity === IDENTIFIER_BATCH) {
          batchValue = inputStr.substring(
            2,
            nextIndex1 != -1 ? nextIndex1 : inputStr.length
          );
          //console.log('batchValue: ', batchValue);
          if (nextIndex1 != -1)
            inputStr = inputStr.substring(batchValue.length + 2);
          else inputStr = "";
        } else inputStr = "";
      }
    }
  }

  return {
    splitData,
    payload,
    gtinValue,
    brandValue,
    expValue,
    mfgValue,
    batchValue,
    serialValue,
  };
};

const findNextIndex = (payload) => {
  if (payload.toString().startsWith(IDENTIFIER_GTIN)) return 0;

  let index = payload.toString().indexOf(IDENTIFIER_BATCH, 5);
  if (index != -1) return index;

  index = payload.toString().indexOf(IDENTIFIER_EXPIRY, 6);
  if (index != -1) return index;

  index = payload.toString().indexOf(IDENTIFIER_MFG, 6);
  if (index != -1) return index;

  index = payload.toString().indexOf(IDENTIFIER_SERIAL, 5);
  if (index != -1) return index;

  return -1;
};

const getDate = (input) => {

  const currentYear = moment().format("YYYY");
  let date_Value = input.substring(4, 6);
  let dateValue = input.substring(4, 6);
  if (dateValue && dateValue === "00") {
    dateValue = "01";
  }
  const dateString = `${currentYear.substring(0, 2)}${input.substring(
    0,
    2
  )}-${input.substring(2, 4)}-${dateValue}`;

  let date = moment(dateString, "YYYY-MM-DD");

  if (date_Value && date_Value === "00") {
    return moment(date, 'YYYY-MM-DD h:m').endOf('month').format('YYYY-MM-DD');
  } else {
    return date.format("YYYY-MM-DD");
  }
};

//GS1 Datamatrix <GS> in Firefox, Chrome & IE
/*
let altValue = '';
document.getElementById('scan_gtin_or_brandcode').addEventListener('keydown', function (e) {
  if (e.altKey) {
    if (e.which != 18) {
      altValue += e.which
    }
    if (altValue === '969698105') {
      this.value += String.fromCharCode(29);
    }
  }
});

document.getElementById('scan_gtin_or_brandcode').addEventListener('keyup', function (e) {
  if (e.altKey === false) {
    altValue = '';
  }
});


$("#scan_gtin_or_brandcode").focus();

$("#scan_gtin_or_brandcode").keydown(function (e) {

  let scan_barcode = $("#scan_gtin_or_brandcode").val();
  if (!scan_barcode) {
    return;
  }

  // $("#scan_result").text('');

  let code = (e.keyCode ? e.keyCode : e.which);
  if (code == 13)// Enter key hit
  {

    ScanBarcodeAction();
  }
  else if (code == 9)// Tab key hit
  {

  } else {

    // barcode=barcode+String.fromCharCode(code);
  }
});*/

// function mergeSSCCandGTIN() {

const mergeSSCCandGTIN = () => {

  let SSCC = '';
  let scanObj = {};

  // let scan_sscc = $("#scan_sscc").val();
  let scan_sscc = document.getElementById('scan_sscc').value

  if ((scan_sscc !== '') && (scan_sscc !== undefined)) {
    let scanSSCCObj = barcodeScan(scan_sscc);
    SSCC = scanSSCCObj.SSCC;
  }

  // let scan_barcode = $("#scan_gtin_or_brandcode").val();

  let scan_barcode = document.getElementById('scan_gtin_or_brandcode').value

  if (scan_barcode != '') {
    scanObj = barcodeScan(scan_barcode);    
    scanObj.SSCC = SSCC;
  }

  // $("#scan_sscc").val('');
  // $("#scan_gtin_or_brandcode").val('');
  // $("#scan_gtin_or_brandcode").focus();

  document.getElementById('scan_gtin_or_brandcode').value = '';
  document.getElementById("scan_gtin_or_brandcode").focus();

  return scanObj;
}


const mergeSSCCandGTIN1 = () => {

  let SSCC = '';
  let scanObj = {};

  // let scan_sscc = $("#scan_sscc").val();
  let scan_sscc = document.getElementById('scan_sscc').value

  if ((scan_sscc !== '') && (scan_sscc !== undefined)) {
    let scanSSCCObj = barcodeScan(scan_sscc);
    SSCC = scanSSCCObj.SSCC;
  }

  // let scan_barcode = $("#scan_gtin_or_brandcode").val();

  let scan_barcode = document.getElementById('scan_gtin_or_brandcode').value

  if (scan_barcode != '') {
    scanObj = barcodeScan(scan_barcode);    
    scanObj.SSCC = SSCC;
  }

  // $("#scan_sscc").val('');
  // $("#scan_gtin_or_brandcode").val('');
  // $("#scan_gtin_or_brandcode").focus();

  //document.getElementById('scan_gtin_or_brandcode').value = '';
  document.getElementById("scan_gtin_or_brandcode").focus();

  return scanObj;
}

/*
    1, //TransactionTypeId
    props.formData.StoreId, //StoreId
    props.formData.TransactionId, //TransactionId
    props.formData.FacilityId, //FacilityId
    1, //FacilityLevelId
    props.formData.ReceiveFrom, //ReceiveFromId
    0, //AdjTypeId
    lan,
    menukey
*/

// function ScanBarcodeAction() {
// export const ScanBarcodeAction = (TransactionTypeId, StoreId, TransactionId, FacilityId, FacilityLevelId, ReceiveFromId, AdjTypeId, lan, menukey) => {
  export const ScanBarcodeAction = (params, props) => {
  // let scan_barcode = $("#scan_gtin_or_brandcode").val();
  
  let scan_barcode = document.getElementById('scan_gtin_or_brandcode').value;
  if(params.ScanFor==undefined){
    if (!scan_barcode) {
      return;
    }
  }
  

  let scanObj;
  if(params.ScanFor==undefined){
        scanObj = mergeSSCCandGTIN();
  }else{
    if(params.ScanFor=="Reports")
      scanObj = mergeSSCCandGTIN1();
    else
    scanObj = mergeSSCCandGTIN();
  }
 // console.log('BarCode Details: ', scanObj);
  
  const SSCC = scanObj.SSCC;
  const GTIN = scanObj.GTIN;
  const Brandcode = scanObj.Brandcode;
  const BatchNo = scanObj.BatchNo;
  const ExpiryDate = scanObj.ExpiryDate;
  const SerialNo = scanObj.SerialNo;
  const OriginalCode = scanObj.OriginalCode;
 

  // const formData = {  
  //   OriginalCode:OriginalCode,  
  //   SSCC: SSCC,
  //   GTIN: GTIN,
  //   Brandcode: Brandcode,
  //   BatchNo: BatchNo,  
  //   ExpiryDate: ExpiryDate,
  //   SerialNo: SerialNo,
  //   TransactionTypeId: params.TransactionTypeId,
  //   StoreId: params.StoreId, 
  //   TransactionId: params.TransactionId,
  //   FacilityId: params.FacilityId,
  //   FacilityLevelId: params.FacilityLevelId,
  //   ReceiveFromId: params.ReceiveFromId,
  //   AdjTypeId: params.AdjTypeId,
  //   lan: params.lan,
  //   menukey: params.menukey,
  //   UserName: params.UserName,
  //   handleRefetchCall: params.handleRefetchCall,
  //   ErrorModal:params.ErrorModal,
  //   action:'ShowProductLotStockReport'
  //   };

  let scanDataobj = {  
      OriginalCode:OriginalCode,  
      SSCC: SSCC,
      GTIN: GTIN,
      Brandcode: Brandcode,
      BatchNo: BatchNo,  
      ExpiryDate: ExpiryDate,
      SerialNo: SerialNo 
      };
  
  const formData = {  
    ...scanDataobj,
    ...params
    };
 


    if(params.ScanFor==undefined){
    ServerAction(formData, props);
    }else if(params.ScanFor=="ContextDashboard"){
      //alert("ContextDashboard");
      ContextDashboardAction(formData, props, scanObj);
    }else if(params.ScanFor=="CentralDashboard"){
      //alert("Central Dashboard");
      CentralDashboardAction(formData, props, scanObj);
    }else if(params.ScanFor=="Reports"){
      //alert("Reports");
      let scan_barcode = document.getElementById('scan_gtin_or_brandcode').value; 
      if (!scan_barcode) {
      delete params.ScanFor;
      delete formData.ScanFor;
      

      }
      ReportsAction(formData, props, scanObj);
    }
}

export const ServerAction = (formData, props) => 
	api.post(`BarcodeScanAndInvoiceItemAdd`, {
		action: "insertUpdateInvoiceItem",
		...formData,
	}, options).then((res) => {

    //console.log("bacode .hjggyugj");
   //console.log(res.data.gstocktakelist.lotdata);
  //  return res;
  
    if (res.data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: res.data.message,
        msgtype: res.data.success,
      });
    //  formData
     formData.handleRefetchCall(res.data.gstocktakelist.lotdata);
     // let route = `${res.data.TransactionId}`;
    //  props.history.push(route); 
      
      }else{
       // document.getElementById('scan_gtin_or_brandcode').value = formData.OriginalCode;
        props.openNoticeModal({
          isOpen: true,
          msg: formData.OriginalCode+' - '+res.data.message,
          msgtype: res.data.success,
        });
      }
      console.log(res.data);
      // console.log(res);


      
  });



  export const ContextDashboardAction = (formData, props,ScanoObjDetails) => 
	api.post(`BarcodeScanForConDashboard`, {
		action: "insertUpdateInvoiceItem",
		...formData,
	}, options).then((res) => {

    //console.log("bacode .hjggyugj");
   //console.log(res.data.gstocktakelist.lotdata);
  //  return res;
  
    if (res.data.status == 200) {

      // formData.ErrorModal({
      //   isOpen: true,
      //   msg: res.data.message,
      //   msgtype: res.data.success,
      // });
    //  formData
     formData.handleRefetchCall({success:1,Data1:res.data.gstocktakelist,ScanObj:ScanoObjDetails}); 
     // let route = `${res.data.TransactionId}`;
    //  props.history.push(route); 
      
      }else{
       // document.getElementById('scan_gtin_or_brandcode').value = formData.OriginalCode;
       //formData.ErrorModal
       formData.ErrorModal({
          isOpen: true,
          msg: formData.OriginalCode+' - '+res.data.message,
          msgtype: res.data.success,
        });
        formData.handleRefetchCall({success:0,ScanObj:ScanoObjDetails});
      }
      //console.log(res.data);
      // console.log(res);


      
  });




export const CentralDashboardAction = (formData, props,ScanoObjDetails) => 
	api.post(`CentralDashboard`, {
		action: "insertUpdateInvoiceItem",
		...formData,
	}, options).then((res) => {

    //console.log("bacode .hjggyugj");
   //console.log(res.data.gstocktakelist.lotdata);
  //  return res;
  
    if (res.data.status == 200) {

      // formData.ErrorModal({
      //   isOpen: true,
      //   msg: res.data.message,
      //   msgtype: res.data.success,
      // });
    //  formData
     formData.handleRefetchCall({success:1,Data1:res.data.gstocktakelist,ScanObj:ScanoObjDetails});
     
     // let route = `${res.data.TransactionId}`;
    //  props.history.push(route); 
      
      }else{
       // document.getElementById('scan_gtin_or_brandcode').value = formData.OriginalCode;
       //formData.ErrorModal
       formData.ErrorModal({
          isOpen: true,
          msg: formData.OriginalCode+' - '+res.data.message,
          msgtype: res.data.success,
        });
        formData.handleRefetchCall({success:0,ScanObj:ScanoObjDetails});
      }
      //console.log(res.data);
      // console.log(res);


      
  });



  export const ReportsAction = (formData, props,ScanoObjDetails) => 
	api.post(`EsiglProductStockReport`, {
		action: formData.action,
		...formData,
	}, options).then((res) => {

     // console.log("bacode Scan reports",ScanoObjDetails);
      

    if (res.data.status == 200) {
      //console.log("bacode Scan reports",res.data);
      
       formData.handleRefetchCall({Data1:res.data.datalist, ScanObj:ScanoObjDetails});
      
      }else{
       // console.log("bacode Scan reports",res.data);
             
       formData.ErrorModal({
          isOpen: true,
          msg: formData.OriginalCode+' - '+res.data.message,
          msgtype: res.data.success,
        });
      }
      
  });





  
