import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
const StockStatusReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const [tmpFacilitylist, setFacilitylist] = useState([]);

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(tmpFacilitylist);


  let PaymentStatusList = [
    { id: "All", name: t(DispensingLanguage[lan][menukey]["All"]) },
    { id: "Paid", name: t(DispensingLanguage[lan][menukey]["Paid"]) },
    { id: "Not Paid", name: t(DispensingLanguage[lan][menukey]["Not Paid"]) }
  ];

  const tmpFLevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FLevelList = tmpFLevelList.filter(tmpFLevelList => {
    return tmpFLevelList.id === 3;
  });


  const tmpStoreList = JSON.parse(localStorage.getItem("gStoreListforreceivesummary"));
  const [AStoreList, setStorelist] = useState(tmpStoreList);
  // let All_StoreList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const AStoreList = [All_StoreList].concat(StoreList);

  const [currFacilityId, setCurrFacilityId] = useState("0");
  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);


  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  const [currStoreId, setStoreId] = useState();
  const [currStoreName, setStoreName] = useState();
  const [currPaymentStatusId, setPaymentStatusId] = useState("All");
  const [currFLevelId, setFLevelId] = useState(FLevelList[0].id);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(12, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);
  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname = '';
    if (newValue == null) {
      rowId = '';
      Fname = '';
    } else {
      rowId = newValue.id;
      Fname = newValue.name;
    }


    if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);



    }
  };

  React.useEffect(() => {

    let Flevelparam = { action: "gFacilityByFLevel", FLevelId: currFLevelId, menukey: "" }
    cfacilityList(Flevelparam);
    if (currFLevelId == FLevelList[0].id) {
     
      let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: currFLevelId,lan:lan, menukey: "" }
      cStoreList(StoreListParam);
    }

  }, [currFLevelId]);







  // Store Change
  const handleStoreChange = (event, newValue) => {
    setACstoreValue(newValue);
    let rowId = '';
    let Sname = '';
    if (newValue == null) {
      rowId = '';
      Sname = '';
    } else {
      rowId = newValue.id;
      Sname = newValue.name;
    }
    if (rowId !== currStoreId) {
      setStoreId(rowId);
      setStoreName(Sname);


    }
  };

  // Product GTIN Change
  const handlePaymentStatuschange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currPaymentStatusId) {
      setPaymentStatusId(rowId);



    }
  };

  // FLevel  Change
  const handleFLevelChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currFLevelId) {
      setFLevelId(rowId);
    }
    let Flevelparam = { action: "gFacilityByFLevel", FLevelId: newValue.id, menukey: "" }
    cfacilityList(Flevelparam);

    let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: newValue.id,lan:lan, menukey: "" }
    cStoreList(StoreListParam);
  };


//store load by facility
const { mutate: cStoreList } = useMutation(
  api.StoreListForReport,
  {
    onSuccess: (data) => {
      if (data.status == 200) {

        // console.log("Dta list", data.data.datalist);
       
        setStorelist(data.data.datalist);
        setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});
        setStoreId(AStoreList[0].id);
        setStoreName(AStoreList[0].name);

      }

    },
  }
);



  const { mutate: cfacilityList } = useMutation(
    api.getfacilitybyflevel,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          // console.log("Dta list", data.data.datalist);
          setFacilitylist(data.data.datalist);



        }

      },
    }
  );




  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "ShowIssueSummarybyPaymentStatusReport",
    lan: lan,
    FacilityId: currFacilityId,
    StoreId: currStoreId,
    IsPaid: currPaymentStatusId,
    FLevelId: currFLevelId,
    StoreName: currStoreName,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getReceiveSummaryReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      // width: 100,
       headerFilter: true,
    },


    {
      title: DispensingLanguage[lan][menukey]["Issued_To"],
      field: "IssuedToFacilityName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 200,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue_Date"],
      field: "TransactionDate",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue_Invoice"],
      field: "IssueInvoice",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Payment_Status"],
      field: "PaymentStatus",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Total (CFA)"],
      field: "IssueAmount",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 150,
      formatter: "money",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let formatedstartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formatedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
      "?action=IssueSummaryByPaymentStatusReport" +
      "&reportType=" +
      reportType +
      "&FacilityId=" +
      currFacilityId +
      "&StoreId=" +
      currStoreId +
      "&IsPaid=" +
      currPaymentStatusId +
      "&FLevelId=" +
      currFLevelId +
      "&StartDate=" +
      formatedstartDate +
      "&EndDate=" +
      formatedEndDate +
      "&StoreName=" +
      currStoreName +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Issue Summary by Payment Status"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>



                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FLevelList"
                      disableClearable
                      options={FLevelList}
                      onChange={(event, newValue) => handleFLevelChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FLevelList[FLevelList.findIndex(FLevelList => FLevelList.id == currFLevelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Level"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["Start date"]
                        }
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>



                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>

                  </div>
                </Grid>

              </Grid>
              <Grid container>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreList"
                      disableClearable
                      options={AStoreList}
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={AStoreList[AStoreList.findIndex(AStoreList => AStoreList.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Store"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="PaymentStatusList"
                      disableClearable
                      options={PaymentStatusList}
                      onChange={(event, newValue) => handlePaymentStatuschange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={PaymentStatusList[PaymentStatusList.findIndex(PaymentStatusList => PaymentStatusList.id == currPaymentStatusId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Payment_Status"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>







              </Grid>
            </CardContent>
          </Card>
          <div>
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={400}
              // options={{
              //   groupBy: ["FacilityName", "GroupName", "ItemName"],

              //   columnCalcs: "both",
              // }}
              />
            </div>
            {/* <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockStatusReport;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
