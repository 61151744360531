import React, { useState, useEffect } from "react";
import { useTheme, styled } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {



  const { children, onClose, ...other } = props;



  return (

    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const FacilityGroupMapEditModal = ({ ...props }) => {


  
//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;
const menukey = "facility";
//get dropdown list
const MonthList = JSON.parse(localStorage.getItem("MonthList"));
const YearList = JSON.parse(localStorage.getItem("YearList"));
 

 
    const classes = useStyles();
    
    //const [GroupList, setGroupList] = useState(props.GroupList);
    const [errorObject, setErrorObject] = useState({});
    const [formData, setFormData] = useState({
      GroupName:props.FacilityServiceId.GroupName,
      FacilityServiceId:props.FacilityServiceId.FacilityServiceId , 
      Supply_From:""
      }); 

    const [datasup, setDatasupp] = useState([]);
    
    const { data } = useQuery(
      "facilityGr",
      api.getSupplyFromList,{
        onSuccess: (data) => {       
          setDatasupp(data);          
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,//1000 * 60 * 60 * 24,
      }
    );
   
 

    const onChange = (event) => {  
        setFormData({ ...formData, [event.target.name]: event.target.value });
        props.FacilityServiceId.SupplyFrom=event.target.value;
        setErrorObject({ ...errorObject, [event.target.name]: null });
 
    };

    
    const handleSubmit = async (e) => {
      
    //  if (validateForm(formData)) { 
        let fDtat={  
        FacilityId:props.match.params.id,
        FacilityServiceId:props.FacilityServiceId.FacilityServiceId,
        SupplyFrom:formData.Supply_From
      }; 
        AssignGroupFacility(fDtat); 
     
    //  }
    };

    const { isLoading, mutate:AssignGroupFacility } = useMutation(api.updateFacilityGroupMap, {
      onSuccess: (data) => {
        if (data.data.status == 200) { 
          props.handleClose('closeEditModal');
          
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
    
        
          
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    });

  

    const validateForm = (formData) => {
      let validateFields = ["MonthId", "YearID","Supply_From"];
      let errorData = {};
      let isValid = true;
      validateFields.map((field) => {
        if (!formData[field]) {
          errorData[field] =
            DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        }
      });
      setErrorObject(errorData);
      return isValid;
    };

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.handleClose('closeEditModal')}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.handleClose('closeEditModal')}
        >
         {DispensingLanguage[lan][menukey]['Assign groups']}
          
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>



            <Grid item xs={12} sm={12}>

              <Grid item xs={6} sm={6}>


                <FormControl className={classes.fullWidth}>
                  
                  <TextField
                          disabled="true"
                          error={errorObject.GroupName}
                          helperText={errorObject.GroupName}
                          id="GroupName"
                          name="GroupName"
                          label={DispensingLanguage[lan][menukey]['Product Group']}
                          value={props.FacilityServiceId.GroupName}
                          fullWidth
                          autoComplete="family-name"
                          
                   />

 
                </FormControl>

              </Grid>


              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                   
                  <TextField
                   disabled="true"
                          error={errorObject.GroupName}
                          helperText={errorObject.GroupName}
                          id="MonthName"
                          name="MonthName"
                          label={DispensingLanguage[lan][menukey]['Start Month']}
                          value={props.FacilityServiceId.MonthName}
                          fullWidth
                          autoComplete="family-name"
                          
                   />
                </FormControl>

              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                <TextField
                 disabled="true"
                          error={errorObject.GroupName}
                          helperText={errorObject.GroupName}
                          id="StartYearId"
                          name="StartYearId"
                          label={DispensingLanguage[lan][menukey]['Start Year']}
                          value={props.FacilityServiceId.StartYearId}
                          fullWidth
                          autoComplete="family-name"
                          
                   />
                </FormControl>

              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                  
                  <Select
                   error={errorObject.FacilityId}
                   helperText={errorObject.FacilityId}
                   required 
                   label={DispensingLanguage[lan][menukey]['Supply From']}
                    id="FacilityId"
                    name="Supply_From"
                    value={props.FacilityServiceId.SupplyFrom}
                    fullWidth
                     onChange={(e) => onChange(e)}
                  >
                   
                    {datasup.map((item, index) => {
                      return (
                        <MenuItem value={item.FacilityId}>{item.FacilityName}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={errorObject.FacilityId}>
                            {errorObject.FacilityId}
                          </FormHelperText>
                </FormControl>

              </Grid>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]["ADD"]}
            
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => props.handleClose('closeEditModal')}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}
            
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default FacilityGroupMapEditModal;


const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  facilitygroupmapTitle: {
    marginTop: "40px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});


