import React, { useState,useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";
import * as api from '../../../actions/api';
import StockTakeFormData from './StockTakeFormData.js';

import { useTranslation } from "react-i18next";

const EditStockTake = (props) => {

  const FacilityId=localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage")); 
  const menukey = "stock-take";
  const lan = localStorage.getItem("LangCode");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [ItemGroupId, setItemGroupId] = useState("0");

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    StockTakeDate: "",
    CompleteDate: "",
    PreparedBy:"",
    ApprovedBy:"",
    ManyJsonSave:"",
    "FacilityId":FacilityId,
    ItemGroupId:"0",
  });

const [IsCompleted, setIsCompleted] = useState("0");
const [ManyTableData, setManyTableData] = useState([]);
const [StockTakeId, setStockTakeId] = useState("0");
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
const [isFirstPhysical, setIsFirstPhysical] = useState(0);
const [bFirst, setBFirst] = useState(true); 

const [dirty, setDirty] = useState(false);


const handleDataTableChange = (e) => {

 };


const [errorObject, setErrorObject] = useState({});
const { id } = useParams();

const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


  
const [selecteEnddDate, setSelecteEnddDate] = useState(
  null
 ); 

const { data,refetch } = useQuery(['stock-take', id,], () =>
    api.getStockTakeInv({StockTakeId:id,ItemGroupId:ItemGroupId}).then((res) => {
      setDirty(true);
  //api.getStockTakeInv(id).then((res) => {
    setStockTakeId(id);
    setFormData(res.data); 
    setIsCompleted(res.data.IsCompleted);
    setManyTableData(res.data.ManyJsonSave);
    setUpdatedDetailGridData(res.data.ManyJsonSave);
    setIsFirstPhysical(res.data.IsFirstPhysical);
    setDirty(false);
    setBFirst(true);
    return res.data

  }), {
  refetchOnWindowFocus: false,  
  enabled: Boolean(id)
}) 

/*
const { isLoading, mutate:mutateUpdate } = useMutation(api.updateStockTakeInv, {
  onSuccess: (data) => {

    if (data.status == 200) {
       refetch();
      if(data.success == 1){

        if(data.IsCompleted==1){

          mutatePost(StockTakeId);
        
        
        }else{

          
          let route = `${data.data.StockTakeId}`;
          setIsCompleted(data.IsCompleted); 
          setManyTableData(data.data.ManyJsonSave);
          props.history.push(route);

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success
          });

        }

      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }

    }else{
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
})

const { isLoading1, mutate:mutatePost } = useMutation(api.postStockTakeInv, {
  onSuccess: (data) => { 
    if (data.status == 200) { 
      refetch();
      setIsCompleted(1);
      if(data.success==0){ 

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       } 

    }
  }
})

*/


const handleAdd = (NonOrderProductData) => {
      
  setManyTableData(NonOrderProductData);
  setUpdatedDetailGridData(NonOrderProductData);
  

};
/*
const setUpdatedDetailsGridDataHandler = (rows) => {


  if (validateForm(formData,1)) {
    let fDtat={...formData,'ManyJsonSave':rows}; 
    mutateUpdate(fDtat); 
    }

  setManyTableData(rows);  
  

}
*/

const handleChange = (e) => {
  const { name, value } = e.target;
  
    let data = { ...formData };
    data[name] = value;            
    setFormData(data);

  setErrorObject({ ...errorObject, [name]: null });
};


const handleReset = () => {
  setFormData({
  StockTakeDate: "",
  CompleteDate: "",
  StockTakeId:"",
  PreparedBy: "",
  ApprovedBy: ""
  
  });
};

const filterHandleChange = (e) => {
  setItemGroupId(e) ;
};

const validateForm = (formData,checkSupplierInv) => {
 // let validateFields = [ "StockTakeDate", "CompleteDate","PreparedBy"]

  let validateFields = checkSupplierInv==2?[ "StockTakeDate", "PreparedBy", "ApprovedBy", "CompleteDate"]:[ "StockTakeDate", "PreparedBy"]
 
  let errorData = {}
  let isValid = true
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = "field is Required !"
      isValid = false
    }
  })
  setErrorObject(errorData);
  return isValid
}
/*
const handleUpdate = async (e) => {
  if (validateForm(formData,1)) {
  let FinalData={...formData,'ManyJsonSave':ManyTableData}; 
  mutateUpdate(FinalData); 
  }
};

*/

/*
const handlePost = async (rows) => {  

  if (validateForm(formData,2)) {
     
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...formData,'ManyJsonSave':rows,"IsCompleted":1}; 
          mutateUpdate(FinalData);
          
        }else{
          setIsCompleted(0);
          let route = `${StockTakeId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

    
  }
 
};

*/




function checkValidateForm(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function checkValidateFormPost(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,2)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function successSave(bStockUpdated){
  if(bStockUpdated !==1 ){
    refetch();
  }
};

function stockPosted(val){
  setIsCompleted(val);
  // setDeletedDataSet([]);
};

/*
useEffect(() => {    
}, [ManyTableData]); 

*/
useEffect(() => {
  refetch();
 
}, [ItemGroupId]);


  return (
    <>
      <StockTakeFormData 
      errorObject={errorObject} 
      addProductForm={false} 
      formData={formData} 
      handleChange={handleChange} 
      handleRDateChange={handleRDateChange} 
      handleReset={handleReset} 
      //handleUpdate={handleUpdate}  
      selecteEnddDate={selecteEnddDate} 
      handleAdd={handleAdd} 

      ManyTableData={updatedDetailGridData} 
      handleDataTableChange={handleDataTableChange} 
      IsCompleted={IsCompleted} 
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      //handlePost={handlePost}
      bFirst={bFirst}
      isFirstPhysical={isFirstPhysical}
      refetch={refetch} 
      filterHandleChange={filterHandleChange}      
      dirty={dirty}
      checkValidateForm={checkValidateForm}
      checkValidateFormPost={checkValidateFormPost}
      successSave={successSave}
      stockPosted={stockPosted}
      {...props} />
    </>
  );
};

export default EditStockTake;