import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import ReactExport from "react-data-export";

import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";

import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";



const Products = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "stock-status";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [productsData, selectProductsData] = useState([]);
  const [productsAllData, selectProductsAllData] = useState([]);
  const [productGroupData, selectProductGroupData] = useState([]);
  const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState("");

  const [TableDataUpdate, setTableDataUpdate] = useState([]);
  const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(2);
  const [dataItems, setDataItems] = useState([]);
   

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  // Facility Auto
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);
  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);
  // Store
  
  // const AStoreList = JSON.parse(localStorage.getItem("getStore"));

  const AStoreList = JSON.parse(localStorage.getItem("getStore")).length==0?[{id:0,name:''}]:JSON.parse(localStorage.getItem("getStore"));

  const [currStoreId, setcurrStoreId] = useState(AStoreList[0].id);
  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  

  //const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroupList = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProductGroupId] = useState(0);
  

  let params = {
    menukey: menukey,
    FacilityId: currFacilityId,
    action: "getDataList",
    StartDate: selectedStartDate,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
    StoreId: currStoreId,
    ProductGroupId: currProductGroupId,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.getstockstatus,
    {
      onSuccess: (data) => {
        if(firstLoad){
          localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );

          FacilityList.forEach((item) => {
            if(item.id==currFacilityId){
              localStorage.setItem(
                "FacilityName",
                item.name
              ); 
            }             
           });
          
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);

        }
       
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  ); 


  const setUpdatedDetailsGridDataHandler = (rows) => {
    const updateTableData = data.map((row) => {
      return row;
    });
    //setTableDataUpdate(updateTableData);
    //setDataItems(data);
  };

  

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=StockMasterExcelExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedDate +
        "&FacilityId=" +
        currFacilityId +
        "&RadioValue=" +
        radioValue +
        "&FacilityName=" +
        currFacilityName + 
        "&StoreId="+ currStoreId +
        "&ProductGroupId=" + currProductGroupId+
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    console.log(row._row);
    row._row.parent.rows.forEach((rowss,i) => {

      
      if(row._row.data.ItemNo==rowss.data.ItemNo)
      {
        localStorage.setItem(
          "StockDataIndex",
          JSON.stringify(i)
        );
        editUser(rowData);
        return;
      }
        
      }) ;

    
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "GroupName",
      headerFilter: true,
      width: 165,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      headerFilter: true,
      width: 135,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
    
    },

    {
      title: DispensingLanguage[lan][menukey]["Minimum Quantity"],
      field: "MinQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Maximum Quantity"],
      field: "MaxQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "UsableQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit Price"],
      field: "SalesPrice",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Line Total"],
      field: "LineTotal",
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center", 
      headerHozAlign: "center", 
      width: 70,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
   // console.log(props.cell._cell.row);
    //const cellValue = props.cell._cell.value || 'Edit | Show';
    return (
      <>
        <ViewList
          onClick={() => {
            dataItems.forEach((row,i) => {
              if(rowData.ItemNo==row.ItemNo)
              {
                localStorage.setItem(
                  "StockDataIndex",
                  JSON.stringify(i)
                );
                editUser(rowData);
                return;
              } 
             
            });  
           
          }}
        />
      </>
    );
  }



  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname='';
    if(newValue == null){
      rowId = '';
      Fname='';
    }else{
      rowId = newValue.id;
      Fname=newValue.name;
    }


   if(rowId!==currFacilityId){
      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);

      // Store Change
      let params1={action:"gStoreListForStockTake","menukey": menukey, "FacilityId":rowId,"lan":lan};
      mutateStore(params1);

        setFirstLoad(true);
        setLoading(true);

    }




 };

 // End Facility Change


 // Store

 const {  mutate:mutateStore } = useMutation(api.gStoreListForStockTake, {
  onSuccess: (data) => { 
    if (data.status == 200) {  
      
      setcurrStoreId(data.data.datalist[0]['id']);
      setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});
      localStorage.setItem(
        "getStore",
        JSON.stringify(data.data.datalist)
      );


    }
  }
})

// Store Combo Change
/*
const handleStoreChange = (event) => {

  if(currStoreId!==event.target.value){
    setcurrStoreId(event.target.value); 
    setFirstLoad(true);
    setLoading(true);
  }

  };
*/

const handleStoreChange = (event, newValue) => {
  setACstoreValue(newValue);
  let rowId = '';

  if (newValue == null) {
    rowId = '';
  
  } else {
    rowId = newValue.id;
   
  }
  if (rowId !== currStoreId) {
    setcurrStoreId(rowId);
    setFirstLoad(true);
    setLoading(true);
  }
};

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);
  
   React.useEffect(() => {
    localStorage.setItem(
      "FacilityName",
      currFacilityName
    );
  
   }, [currFacilityName]);
  
  React.useEffect(() => {
   
    localStorage.setItem(
      "StoreId",
      currStoreId
    );
    
  }, [currStoreId]);

// End Store

/*
const handleProductGroupChange = (event, newValue) => {
  let rowId = 0;
  if(newValue == null){
    rowId = 0;
  }else{
    rowId = newValue.id;
  }
};
*/
/*
const [all, setAll] = React.useState('0');
const handleProductGroupChange = (event) => {
  setAll(event.target.value);
  setFirstLoad(true);
  setLoading(true);
  setCurrProductGroupId(event.target.value);
  //setLoading(true);
};
*/

const handleProductGroupChange = (event, newValue) => {

  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currProductGroupId) {
    setCurrProductGroupId(rowId);
    setFirstLoad(true);
    setLoading(true);
  }
};

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  
  const handleChangePage = (data) => {
    setPageNumber(data);
    setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };
  const editUser = (data,dataItemss) => { 

    let route = `stock-status/edit/${data.ItemNo}`;
    props.history.push(route);

    


   
  };

  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
  };

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);


  // React.useEffect(() => {
  //   let token = Constants.token;
  //   if (token) {
  //   }
  // }, []);

  // React.useEffect(() => {}, [
  //   pageNumber,
  //   numberRowPerPage,
  //   query,
  //   productGroupFilter,
  // ]);


  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Stock Status"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>




            <Grid item xs={4} sm={4}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole==1?false:true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>


              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Store"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="AStoreId"
                    name="AStoreId"
                    value={currStoreId}
                   // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                  >
                    
                    {AStoreList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>  */}

               <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreId"
                      disableClearable
                      options={AStoreList}               
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={AStoreList[AStoreList.findIndex(AStoreListA => AStoreListA.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Store"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>



              {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Product Group"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ItemGroupId"
                    name="ItemGroupId"
                    //value={currMonthId}
                    value={all}
                    onChange={handleProductGroupChange}
                    fullWidth
                  >
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {ProductGroupList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>  */}


              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ItemGroupId"
                      disableClearable
                      options={ProductGroupList}               
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroupList[ProductGroupList.findIndex(AProductGroupList => AProductGroupList.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>





              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Stock Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="SHOW_NONE_ZERO"
                  >
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW ALL"]}
                      onClick={() => changeRadio(0)}
                    />
                    <FormControlLabel
                      value="SHOW_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW ZERO"]}
                      onClick={() => changeRadio(1)}
                    />
                    <FormControlLabel
                      value="SHOW_NONE_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW NONE-ZERO"]}
                      onClick={() => changeRadio(2)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="float-right sw_btn_control">
                  
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
