import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";

import ProductListBrandFormData from "./ProductListBrandFormData.js";
import * as Service from "../../../services/Service.js";

const ProductListBrandAdd = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const menukey = "brands-list";

  const [formData, setFormData] = useState({
    ItemNo: "",
    ItemGroupId:"",
    ProductSubGroupId:"",
    SIGIPCode:"",
    ProductStatusId:"",
    BrandCode:"",
    CommercialId:"",
    StrengthId:"",
    PacksizeId:"",
    DosageFormId:"",
    DciId:"",
    DemanderId:"",
    DemanderAddress:"",
    ManufacturerId:"",
    PaysExploitant:"",
    PaysFabricant:"",
    RepresentantLocalouExploitant:"",
    VoieAdministration:"",
    DateExpirationDeLAMM:"",
    CondDelivrance:"",
    CodeAMM:"",
    PGHTPresentation:"",
    PGHTPrixPublic:"",
    DateDeDebut:"",
    DateExpiration:"",
    ClasseTherapeutique:"",
    GPCCode:"",
    EAGTIN:"",
    EAQty:"",
    EAChildGTIN:"",
    PKGTIN:"",
    PKQty:"",
    PKChildGTIN:"",
    CAGTIN:"",
    CAQty:"",
    CAChildGTIN:"",
    PLGTIN:"",
    PLQty:"",
    PLChildGTIN:"",
    // SOBAPSCode:"",
    bSerialSupported:"",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();
  
  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };
  
  const { mutate } = useMutation(api.saveProductBrand, {
    onSuccess: (data) => {
      if (data.status == 200) {
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
        queryClient.getQueriesData("dci");
        props.history.push("/brands-list-entry");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
      
    },
  });

/*============= */
const generateProductCodeAndName = (data) => {
  //Generic Code = Form + DCI + Strength + Packsize
console.log(data);

  const {
    DosageFormId,
    DciId,
    StrengthId,
    PacksizeId
  } = data;

  let ItemCode = "";
console.log('PacksizeId= ', PacksizeId);
  // let ItemName = "";
  // let ShortName = "";

  if (DosageFormId && DciId && StrengthId && PacksizeId) {
    ItemCode =
      DosageFormId +
      DciId.toString().padStart(4, "0") +
      StrengthId.toString().padStart(4, "0") +
      PacksizeId.toString().padStart(4, "0");
  }

  /*
  const Dcilist = JSON.parse(localStorage.getItem("Dcilist"));
  const strengthSizes = JSON.parse(localStorage.getItem("Strengthlist"));
  const forms = JSON.parse(localStorage.getItem("DosageFormlist"));
  const packSizes = JSON.parse(localStorage.getItem("PackSizelist"));

  let DciNameStr = "";
  if (DciId != "") {
    DciNameStr = Dcilist.find((g) => g.id == DciId)?.name;
  }

  let strengthSizeStr = "";
  if (StrengthId != "") {
    strengthSizeStr = strengthSizes.find((st) => st.id == StrengthId)?.name;
  }

  let packSizeStr = "";
  if (PacksizeId != "") {
    packSizeStr = packSizes.find((pack) => pack.id == PacksizeId)?.name;
  }

  let formStr = "";
  if (DosageFormId != "") {
    formStr = forms.find((f) => f.id == DosageFormId)?.name;
  }

  //Generic Name = DCI + Strength + Packsize + Form

  ItemName =
    (DciNameStr || "") +
    " " +
    (strengthSizeStr || "") +
    " " +
    (packSizeStr || "") +
    " " +
    (formStr || "");
  
  ShortName =
    (DciNameStr || "") +
    " " +
    (strengthSizeStr || "") +
    " " +
    (packSizeStr || "") +
    " " +
    (formStr || "");*/

 return {
    ItemCode: ItemCode,
    // ItemName: ItemName,
    // ShortName: ShortName,
  };
};

/*================ */

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;


    let productData = generateProductCodeAndName(data);
    if (productData.ItemCode) {
      data["ItemCode"] = productData.ItemCode;
    }
    // if (productData.ItemName) {
    //   data["ItemName"] = productData.ItemName;
    // }
    // if (productData.ShortName) {
    //   data["ShortName"] = productData.ShortName;
    // }

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      BrandCode: "",
     
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["BrandCode","CommercialId","ItemNo","ItemGroupId","ProductSubGroupId","SIGIPCode","ProductStatusId","StrengthId","PacksizeId","DosageFormId","DciId","DemanderId","ManufacturerId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  const handleSubmit = async (e) => {
    //console.log("from submit ", formData);
    if (validateForm(formData)) {
      mutate(formData);
      
    }
  };
 
  return (
    <>
      <ProductListBrandFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleRDateChange={handleRDateChange} 
        {...props}
      />
    </>
  );
};

export default ProductListBrandAdd;
