import React, { useState, useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';
import swal from "sweetalert";

import * as api from '../../../actions/api';
import OrderFormData from './OrderFormData.js';

const AddOrder = (props) => {

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "orders";
const FacilityId=localStorage.getItem('FacilityId');
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;
const UserId = UserInfo==0?'': UserInfo[0].id;
  
const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
//const [bCompleted, setbCompleted] = useState("-1");

const [formData, setFormData] = useState({
  OrderDate: selectedDate,
  OrderNo: generatedInvoiceNo,
  OrderingFrom:"",
  ApprovedBy:"",
  OrderTypeId:"",
  bCompleted:-1,
  ManyJsonSave:"",
  "FacilityId":FacilityId,
  OrderBy:"",
  Remarks:""
});

const [ManyTableData, setManyTableData] = useState( []);


  const handleRDateChange = (date, field) => {
    
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();


  let params={"menukey": "orders", "FacilityId":FacilityId, OrderDate: ''};
  const {  data:invoiceNo, refetch } = useQuery([params], api.getOrdInvoiceNo, 
    {onSuccess: (data) => {
      
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData }; 
      fdata["OrderNo"] = data; 
      setFormData(fdata); 

    }}
    );

  
    /*
  const { isLoading, mutate } = useMutation(
    api.saveOrderInv, 
    {
    onSuccess: (data) => {

      if(data.status==200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      let route = `edit/${data.id}`;
      props.history.push(route);

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }

    }
  })
  */

  const handleChange = (e) => {
    const { name, value } = e.target;
    
      let data = { ...formData };
      console.log('formData: ', formData);
      data[name] = value;
      setFormData(data);
   
    setErrorObject({ ...errorObject, [name]: null });
  };


  const handleReset = () => {
    setFormData({
      OrderNo: generatedInvoiceNo,
      OrderingFrom:"",
      ApprovedBy:"",
      OrderTypeId:"",
      ManyJsonSave:"",
      "FacilityId":FacilityId,
      OrderBy:"",
      Remarks:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["OrderDate", "OrderNo","OrderingFrom","OrderTypeId", "OrderBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,"menukey":menukey,"lan":lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  /*
  const handleSubmit = async (e) => {
   
    if (validateForm(formData)) {
      
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,"UserId":UserId}; 
      mutate(fDtat);
     
    }
  };
  */

  return (
    <>
      <OrderFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange}  
      handleReset={handleReset} 
      //handleSubmit={handleSubmit} 
      ManyTableData={ManyTableData} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      generatedInvoiceNo={generatedInvoiceNo}
      bCompleted={-1} 
      checkValidateForm={checkValidateForm}
      {...props} />
    </>
  );
};

export default AddOrder;