import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select,
Typography,
TextField, 
FormControl,
Grid, Card,
CardHeader,
CardContent,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow
}
from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles, } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";

const OrderReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);
  const [acValue, setACValue] = useState("");
  let TempZoneList = useState([]);
  //Flevel filter section
  
  const FlevelList = JSON.parse(localStorage.getItem("getFacilityListForReport"));
  const [currFacilityId, setCurrFacilitylist] = useState(FlevelList.length>0? FlevelList[0].id:"");
  // console.log('Database',FlevelList)
  const [FacilityLavel, setFacilityLavel] = useState([]);

  // Facility Change
  const handleReginalListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currFacilityId) {
      setCurrFacilitylist(rowId);
      setFacilityLavel(rowName);
    }
   
    getOrderListData(rowId);

  };
  useEffect(()=>{
    if(currFacilityId == (FlevelList.length>0? FlevelList[0].id:"")){
      setFacilityLavel((FlevelList.length>0? FlevelList[0].name:""));
    }
   
  },[currFacilityId]);

  const getOrderListData = (rowId) =>{
    let OrderParam = { action: "getOrderListdata", FacilityId:rowId, menukey: "" }
    cOrderList(OrderParam);
  }

  const { mutate: cOrderList } = useMutation(

    api.getOrderData,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setFacilityData(data.data.datalist);
          //console.log(setFacilityData);
          setACValue({ id: '0', name: "All"});
          
        }
      },
    }
  );


  const [OrderLists, setFacilityData] = useState(TempZoneList);
  let All_Order = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const OrderList = [All_Order].concat(OrderLists);
  //console.log("data",OrderList[0].name);
  const [currOrderTypeId, setFacilityId] = useState("0");
  const [OrderName, setOrderName] = useState([]);
// console.log('Order Name',OrderName);

  const handleFacilityListChange = (event, newValue) => {
    setACValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName='';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currOrderTypeId) {
      setFacilityId(rowId);
      setOrderName(rowName);
    }
  
  };


React.useEffect(() => {
  if(currOrderTypeId=='0'){
    getOrderListData(currFacilityId);
  }
   

  }, [currOrderTypeId]);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getOrderlistItem",
    lan: lan,
    FacilityId: currFacilityId,
    OrderTypeId: currOrderTypeId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getOrderList,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [item, setitem] = useState([]);

  // console.log(item[0]);
  let params1 = {
    menukey: menukey,
    action: "getOrderDetails",
    lan: lan,
    FacilityId: currFacilityId,
    OrderTypeId: currOrderTypeId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isError1, data1, } = useQuery(
    [params1],
    api.getOrderBoxList,
    {
      onSuccess: (data1) => {
        setitem(data1);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  // useEffect(()=>{
  //   setitem(getOrderDetails);
  // },[]);

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "CameCode",
      headerFilter: false,
      headerSort: false,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      headerFilter: true,
      headerSort: false,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerFilter: true,
      headerSort: false,

    },
    {
      title: DispensingLanguage[lan][menukey]["Minimum_Quantity"],
      field: "MinQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Maximum_Quantity"],
      field: "MaxQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "center",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock on Hand"],
      field: "StockOnHand",
      headerSort: false,
      headerFilter: false,
      hozAlign: "center",
      headerHozAlign: "left",
      width: 120,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Calculated Order Quantity"],
      field: "CalcOrderQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },
    {
      title: DispensingLanguage[lan][menukey]["Actual Order Quantity"],
      field: "ActualOrderQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },
    {
      title: DispensingLanguage[lan][menukey]["Actual Received Quantity"],
      field: "ActualReceived",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },
    {
      title: DispensingLanguage[lan][menukey]["Unit Price"],
      field: "UnitPrice",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },
    {
      title: DispensingLanguage[lan][menukey]["Total_CFA"],
      field: "LineTotal",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "left",
      width: 100,

    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=OrderType" +
      "&reportType=" +
      reportType +
      "&FacilityId=" +
      currFacilityId +
      "&OrderTypeId=" +
      currOrderTypeId +
      "&FacilityLavel=" +
      FacilityLavel +
      "&OrderName=" +
      OrderName +
      "&menukey=" +
      menukey +
      "&lan=" +
     localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
        <div className="d-flex justify-uiLanguage">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title1">
                {DispensingLanguage[lan][menukey]["Order_Report"]}
              </div>
              <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
            </div>
          </Grid>
        </div>

   

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>

                  <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FlevelList"
                          disableClearable
                          options={FlevelList}
                          onChange={(event, newValue) => handleReginalListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FlevelList[FlevelList.findIndex(FlevelList => FlevelList.id == currFacilityId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="OrderList"
                          disableClearable
                          options={OrderList}
                          value={acValue}
                         // filterSelectedOptions={true}
                       //   loading={true}
                          onChange={(event, newValue) => handleFacilityListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          // defaultValue={OrderList[0]}
                          defaultValue={OrderList[OrderList.findIndex(OrderList => OrderList.id == currOrderTypeId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Order#"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
          <Grid item xs={12} sm={12}>
              <TableContainer>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> {DispensingLanguage[lan][menukey]['Order Type']}</TableCell>
                      <TableCell align="center"> {DispensingLanguage[lan][menukey]['Order Date']}</TableCell>
                      <TableCell align="center"> {DispensingLanguage[lan][menukey]['Ordering From']}</TableCell>
                      <TableCell align="center"> {DispensingLanguage[lan][menukey]['Receive Status']}</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.map((row) => (
                      <TableRow key={row.id} >
                        
                        <TableCell style={{ border: 0 }} align="center">{row.OrderType}</TableCell>
                        <TableCell style={{ border: 0 }} align="center">{row.OrderDate}</TableCell>
                        <TableCell style={{ border: 0 }} align="center">{row.OrderingFromName}</TableCell>
                        <TableCell style={{ border: 0 }} align="center">{row.ReceivedStatus}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </TableContainer>
          </Grid>
          </CardContent>
          
        </Card>
          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height="600"
                    options={{
                      groupBy: ["GroupName","FacilityName"],
                      columnCalcs: "both",
                    }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default OrderReport;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },

});