import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";
import CommuneFormData from "./CommuneFormData.js";


const EditCommune = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "commune";

  const [formData, setFormData] = useState({
    RegionId: "",
    ZoneId: "",
    DistrictName: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["commune", id],
    () =>
      api.getCommuneData(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateCommune, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        // api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //     JSON.stringify(response.datalist.ZSlist)
        //   );
        //  });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
  
        queryClient.getQueriesData("commune");
        props.history.push("/commune");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      RegionId: "",
      ZoneId: "",
      DistrictName: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["RegionId","ZoneId", "DistrictName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
      
    }
  };

  return (
    <>
      <CommuneFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default EditCommune;
