import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import MenuEntryFormData from "./MenuEntryFormData.js";

const AddMenu = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "menu-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;
const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));

const [formData, setFormData] = useState({
    title: "",
    url: "",
    parentId: "",
    position: "",
    MenuLevel: "",
    Description: "",
    DashboardImage: "",
    UseSite: "", 
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["menuentery", id],
    () =>
      api.geImenuentrys(id).then((res) => {
        setFormData(res.data);
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000  60  60 * 24,
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveMenuentry, {
    onSuccess: (data) => {

      if(data.status==200) {

         api.getDispensingLanguage().then((response) => {
           localStorage.setItem(
             "DispensingLanguage",
             JSON.stringify(response.datalist)
           );
         })

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("menuentry");
      props.history.push("/menu-entry");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      title: "",
      url: "",
      parentId: "",
      position: "",
      MenuLevel: "",
      Description: "",
      DashboardImage: "", 
      UseSite: "",  
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["title","url"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
        // if (formData[field] != 0) {
        //   errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        //   isValid = false;
        //   }
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

/* =====Upload Image========= */

const APP_URL = process.env.REACT_APP_API_URL;

const changeHandlerForFileUpload = (event) => {

    let selectedFile = event.target.files[0];

    if(selectedFile == undefined){
      return;
    }

    handleFileUpload(selectedFile);
};

const handleFileUpload = (selectedFile) => {
  const Form_Data = new FormData();

  Form_Data.append("FileName", selectedFile);
  let reportImages = GlobalSettings.report_images;
  Form_Data.append("FileUploadPath", reportImages);

  let finalUrl = APP_URL + "upload/report_image_upload.php";

  fetch(finalUrl, {
    method: "POST",
    body: Form_Data,
  })
    .then((response) => response.json())
    .then((res) => {

      if (res.msgType == "success") {
        let data = { ...formData };
        data['DashboardImage'] = res.FileName;
        setFormData(data);

      } else {
        let data = { ...formData };
        data['DashboardImage'] = '';
        setFormData(data);
      }
    })
    .catch((error) => {
      let data = { ...formData };
      data['DashboardImage'] = '';
      setFormData(data);
    });
};

const DeleteUploadFile = () => {
  let data = { ...formData };
  data['DashboardImage'] = '';
  setFormData(data);
}

  return (
    <>
      <MenuEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        changeHandlerForFileUpload={changeHandlerForFileUpload}
        DeleteUploadFile={DeleteUploadFile}
        {...props}
      />
    </>
  );
};

export default AddMenu;
