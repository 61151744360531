import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { 
  Grid, FormControl, InputLabel, Select,  MenuItem, Card, CardContent,
  TextField,
  Typography,

} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

//get DispensingLanguage


const ProductView = (props) => {

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
 const [dataItems, setDataItems] = useState([]);

 const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
 const lan = localStorage.getItem('LangCode');
 const menukey = "product-list";
 const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoadingUi, setLoading] = useState(true);
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroupList = [All_Product_Group].concat(Product_Group);
  const StoreId = localStorage.getItem("StoreId");
  const UserId = localStorage.getItem("UserId");
  

  let FacilityList=JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [currFacilityId, setCurrFacilityId] = useState(FacilityList[0].id);
  const [currFacilityName, setCurrFacilityName] = useState(FacilityList[0].name);
  const [currProductGroupId, setCurrProductGroupId] = useState(ProductGroupList[0].id);
  const [all, setAll] = React.useState('0');
  const [firstLoad, setFirstLoad] = useState(true);

  // const AStoreList = JSON.parse(localStorage.getItem("getStore"));
  const AStoreList = JSON.parse(localStorage.getItem("getStore")).length==0?[{id:0,name:''}]:JSON.parse(localStorage.getItem("getStore"));
  const [currStoreId, setcurrStoreId] = useState(AStoreList[0].id);
  // Facility
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let FName = '';
    if (newValue == null) {
      rowId = '';
      FName='';
    } else {

      rowId = newValue.id;
      FName =newValue.name;
    }

    if (rowId !== currFacilityId) {

      // Facility List By User Change
      setCurrFacilityId(rowId);
      setCurrFacilityName(FName);
       setLoading(true);
    }

  };

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);

  

  // End Facility

  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
  
    if (rowId !== currProductGroupId) {
      setCurrProductGroupId(rowId);
       setLoading(true);
    }
  
  };

  // const handleProductGroupChange = (event) => {
  //   setAll(event.target.value);
  //   setCurrProductGroupId(event.target.value);
    
  // };


// Store Combo Change

const handleStoreChange = (event, newValue) => {
  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }

  if (rowId !== currStoreId) {
    setcurrStoreId(rowId);
     setLoading(true);
  }

};

// const handleStoreChange = (event) => {

//   if(currStoreId!==event.target.value){
//     setcurrStoreId(event.target.value); 
//     setFirstLoad(true);
//     setLoading(true);
//   }

//   };


  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);
  
   React.useEffect(() => {
    localStorage.setItem(
      "FacilityName",
      currFacilityName
    );
  
   }, [currFacilityName]);
  
  React.useEffect(() => {
   
    localStorage.setItem(
      "StoreId",
      currStoreId
    );
    
  }, [currStoreId]);

// End Store




  let params = {
    menukey: menukey,
    action: "getStockStatusDataForTable",
    lan: lan,
    FacilityId: currFacilityId,
    StoreId:currStoreId,
    ItemGroupId:currProductGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getProductViewList,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { mutate:productListUpdate } = useMutation(api.updateProductViewList, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        // console.log("RRRR",data);
               refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

          }else{
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

          }

    }
  })


  
  const cellDataUpdate = (ItemNo,FacilityId,fieldName,StoreId, cell) => {
    let curcellval = cell.getValue();
    let colValue = curcellval;
    let Celldata = cell.getRow().getData();
    let SalesPriceVal =(Celldata.PurchasePrice * (1 + (Celldata.PriceMarkupMax - Celldata.PriceMarkupMin)));
    // console.log('gggid: ', colValue);
   
    
    productListUpdate({ItemNo,colValue,FacilityId,fieldName,SalesPriceVal,StoreId,UserId,lan});
    

  };


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getFacilityProductListData" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&FacilityId=" +
        currFacilityId +
        "&ItemGroupId=" +
        currProductGroupId +
        "&StoreId=" +
        currStoreId +
        "&FacilityName=" +
        currFacilityName +
        "&lan=" +
        localStorage.getItem("LangCode")
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    { title: "Id", field: "id", visible: false },
    // { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 70, formatter: "rownum", headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Product_Code'],  
      headerFilter: true, 
      width: 130, 
      field: "ItemCode",
      
    },
    { title: DispensingLanguage[lan][menukey]['Product Name'], field: "ItemName", headerFilter: true,},
    { title: DispensingLanguage[lan][menukey]['Quantity'], field: "StockQty", hozAlign:"right",headerHozAlign:"right",width: 130, },
    { title: DispensingLanguage[lan][menukey]['Unit'], field: "UnitName", visible:false},
    
    { title: DispensingLanguage[lan][menukey]['AMC'], 
      field: "AMC",
      hozAlign:"right",  
      headerHozAlign:"right",
      cssClass: "tabluator-column-editable",
      editor:true,
      width: 130, 
      cellEdited: function(cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const ItemNo = rowdata.ItemNo;
        const FacilityId = rowdata.FacilityId;
        const fieldName = "AMC";
        const StoreId = rowdata.StoreId;
        cellDataUpdate(ItemNo,FacilityId,fieldName,StoreId,cell);
    },
    },
    {
      title: DispensingLanguage[lan][menukey]['Purchase Price'],
      field: "PurchasePrice",
      hozAlign:"right",
      headerHozAlign:"right",
      cssClass: "tabluator-column-editable",
      editor:true,
      width: 130, 
      cellEdited: function(cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const ItemNo = rowdata.ItemNo;
        const FacilityId = rowdata.FacilityId;
        const fieldName = "PurchasePrice";
       
        const StoreId = rowdata.StoreId;
        cellDataUpdate(ItemNo,FacilityId,fieldName,StoreId,cell);
    },
      
    },
    {
      title: DispensingLanguage[lan][menukey]['Sales Price'],
      field: "SalesPrice",
      hozAlign:"right",
      headerHozAlign:"right",
      cssClass: "tabluator-column-editable",
      editor:true,
      width: 130, 
      cellEdited: function(cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const ItemNo = rowdata.ItemNo;
        const FacilityId = rowdata.FacilityId;
        const fieldName = "SalesPrice";
        const StoreId = rowdata.StoreId;
        cellDataUpdate(ItemNo,FacilityId,fieldName,StoreId,cell);
    },
      
    },


  ];

  const editUiLanguage = (data) => {
    let route = `ui-language/edit/${data.id}`;
    props.history.push(route);
  };


  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editUiLanguage(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteUiLanguage(rowData);
          }}
        />
      </>
    );
  }






  return (
    <>
      <div className={classes.uiLanguagePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Product List"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={3}>
              <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="FacilityList"
                    disableClearable
                    options={FacilityList}
                    // defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName != "admin")) ? -1 : FacilityList.find(item => item.id == currFacilityId)}
                    // disabled={UserName == "admin" ? false : true}
                    onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                   
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"

                      />
                    )}
                  />
                  
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>

              <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="ProductGroupList"
                    disableClearable
                    options={ProductGroupList}
                    onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={ProductGroupList[ProductGroupList.findIndex(ProductGroupList => ProductGroupList.id == currProductGroupId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                   
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"

                      />
                    )}
                  />
                  
                </FormControl>


                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Product Group"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="ItemGroupId"
                        name="ItemGroupId"
                        //value={currMonthId}
                        value={all}
                        onChange={handleProductGroupChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ProductGroupList.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl> */}
                  </Grid> 
                  <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="AStoreList"
                    disableClearable
                    options={AStoreList}
                    onChange={(event, newValue) => handleStoreChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={AStoreList[AStoreList.findIndex(AStoreList => AStoreList.id == currStoreId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                   
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Store"]}
                        variant="standard"

                      />
                    )}
                  />
                  
                </FormControl>
              </Grid> 

              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
               }}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductView;

const useStyles = makeStyles({
  uiLanguagePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },


});
