import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";

import swal from "sweetalert";
import Notification from "../../../services/Notification";
//Role screen
import Auditlog from "./Scanlog";



const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }


  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);


  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

 

  

  return (
    <div>
<AfterLoginNavbar {...props} />
<div
  className={`section signup-top-padding ${classes.dispenserContainer}`}
>
  <Switch>

    <Route
      path={`${path}/`}
      render={(props) => (
        <Auditlog {...props} openNoticeModal={openNoticeModal} />
      )}
    ></Route>
    
  </Switch>
  <Notification
    closeNoticeModal={closeNoticeModal}
    msgObj={msgObj}
    {...props}
  ></Notification>
</div>
</div>

  );
};



export default Index;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
