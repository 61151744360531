import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const MOSRange = (props) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "national-mos-type-entry";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, error, isError, data, refetch } = useQuery(
    "mosrange",
    api.getMOSRanges,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      } 
    }
  );
  
  const { mutate:deleteApi } = useMutation(api.deleteMOSRange, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        refetch();
        queryClient.getQueriesData("mosrange");   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
		
    
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
      
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=MOSRangeExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode")
      + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  const columns = [
    { title: "Id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 70, formatter: "rownum", headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['MOS Type'], field: "MosTypeName"},
    { title: DispensingLanguage[lan][menukey]['Minimum MOS'], hozAlign: "center", headerHozAlign: "center", width: 200, field: "MinMos"},
    { title: DispensingLanguage[lan][menukey]['Maximum MOS'], hozAlign: "center", headerHozAlign: "center", width: 200, field: "MaxMos"},
    { title: DispensingLanguage[lan][menukey]['Color Code'], width: 200, field: "ColorCode", formatter:"color"},
    { title: DispensingLanguage[lan][menukey]['MOS Label'], width: 200, field: "MosLabel"},
  
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign:"center",
      headerSort:false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editMOSRange = (data) => {
    let route = `mos_range/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteMOSRange = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
  //     text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this MOS Range!']),
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       mutate(data.id);
  //       swal(t(DispensingLanguage[lan][menukey]['Poof! MOS Range has been deleted!']), {
  //         icon: "success",
  //       });
  //     }
  //   });
  // };

  const deleteMOSRange = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({MosTypeId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editMOSRange(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteMOSRange(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.mosrangePageTitle}>
        <div className="d-flex justify-mosrange mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['MOS Range']}  </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/mos_range/add");
                  }}
                >
                {DispensingLanguage[lan][menukey]['Add MOS Range']}
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns}
             data={dataItems}
             //height={600}
             layout={"fitColumns"}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default MOSRange;

const useStyles = makeStyles({
  mosrangePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
