import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issue-invoice";
// const classes = useStyles();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));



const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SourceModal = ({ handleClose, open,TransactionId,FacilityId, ...props }) => {

  const [selectedPaymentDate, setSelectedPaymentDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

const handleEndDateChange = (date) => {
  setSelectedPaymentDate(date);
};

const { isLoading, mutate } = useMutation(api.UpdatePaymentDateIssue, {

  onSuccess: (data) => { 
    handleClose('SourceClose');
    if (data.status == 200) {
     
    props.openNoticeModal({
      isOpen: true,
      msg: data.message,
      msgtype: data.success,
    });

    }else{
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
})  

const handleSubmit = async (e) => {

  // if (validateForm(formData,1)) {
    let fDtat={FacilityId:FacilityId,TransactionId:TransactionId,PaymentDate:selectedPaymentDate,lan:lan, "menukey":menukey};
   
    mutate(fDtat);
    
  // }
};

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('SourceClose')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SourceClose')}
        >
        {DispensingLanguage[lan][menukey]['Update Payment Date and Status']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
     
        <Grid item xs={8} sm={8}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      // className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                       label=  
                      {DispensingLanguage[lan][menukey]["Payment Date"]}
                      autoOk={true}
                      value={selectedPaymentDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
           
           {DispensingLanguage[lan][menukey]['Submit']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('SourceClose')}
          >
            
            {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SourceModal;


