import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent, } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";


const Commune = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "commune";


  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [currRegionId, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [currZoneText, setZoneText] = useState("");
  const [currZoneId, setcurrZoneId] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  
  const [Regionlist, setRegionlist] = useState([]);
  let TempZoneList = useState([]); 
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  const [Region, setRegion] = React.useState(true);

 

  let params = {
    menukey: menukey,
    action: "getDataList",
    RegionId: currRegionId,
    ZoneId: currZoneId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { refetch } = useQuery(
    [params],
    api.getCommunes,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  const { mutate } = useMutation(api.deleteCommune, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("commune");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
    },
  });



  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }

  const cZoneList = () => {  
  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
  


}

  const handleRegionChange = (event, index) => {
    //console.log(index.props.children);
    //setAll(event.target.value);
    setLoading(true);
    //console.log(e.target.value);
    setRegionIdvalue(event.target.value);
    setRegionText(index.props.children);

  };


  
  const handleZoneChange = (event, index) => {

    params = {
      menukey: menukey,
      action: "getDataList",
      RegionId: currRegionId,
      ZoneId: event.target.value,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
      
    };
    setLoading(true);
    setcurrZoneId(event.target.value);
    setZoneText(index.props.children);
    //getFacilityData();
  };


  React.useEffect(() => {
    setcurrZoneId(0); 
    cZoneList();
  }, [currRegionId]);

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=CommuneExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")+
        "&RegionId=" + currRegionId+
        "&ZoneId=" + currZoneId+
        "&RegionText=" + currRegionText+
        "&ZoneText=" + currZoneText
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title:  DispensingLanguage[lan][menukey]["Commune_Id"],
      field: "id",
      width: 100,
      visible: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      width: 120,
      headerFilter:true,
      field: "RegionName",
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      headerFilter:true,
      field: "ZoneName",
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      headerFilter:true,
      width: 150,
      field: "DistrictName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editCommune = (data) => {
    let route = `commune/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteCommune = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Commune!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editCommune(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteCommune(rowData);
          }}
        />
      </>
    );
  }


  //const [all, setAll] = React.useState('0');



  return (
    <>
     
<div className={classes.communePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Commune"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department"]}
                  </InputLabel>
                  <Select
                    //error={errorObject.MenuKey}
                    labelId="demo-simple-select-helper-label"
                    id="MenuKey"
                    name="MenuKey"
                    value={currRegionId}
                    onChange={handleRegionChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                    {Regionlist.map((item, index) => {
                    
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["ZS"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ZoneId"
                    name="ZoneId"
                    value={currZoneId}
                    onChange={handleZoneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                   {Zonelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={7} sm={7}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/commune/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Commune"]}
                  </Button>

                  <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>

                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commune;

const useStyles = makeStyles({
  communePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
