import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, FormControl, InputLabel, Select,  MenuItem, Card, CardContent,Checkbox,TextField} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone ,ViewList} from "@material-ui/icons";

import * as Service from "../../../services/Service.js";



//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";


const FacilityList = (props) => {

  const { useState } = React;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facilities-list";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const [Regionlist, setRegionlist] = useState([]); //JSON.parse(localStorage.getItem("Regionlist"));

  let TempZoneList = useState([]); // JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);

  let TempCommuneList = useState([]); //JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);

  
  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));


  const [Ownertypelist, setOwnertypelist] = useState([]);
  const [Servicearealist, setServicearealist] = useState([]);

  const [Facilitylist, setFacilitylist] = useState([]);

  const classes = useStyles();
  const tableRef = useRef();
 
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [bDispensingValue, setbDispensing] = useState(false);
  const [isLoadingUi, setLoading] = useState(true);
  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [currFLevelId, setcurrFLevelId] = useState(0);
  const [currFTypeId, setcurrFTypeId] = useState(0);
  const [currOwnerTypeId, setOwnerTypeId] = useState(0);
  const [currServiceAreaId, setServiceAreaId] = useState(0);

  const [Region, setRegion] = React.useState(true);
  const [Zone, setZone] = React.useState(true);
  const [Commune, setCommune] = React.useState(true);
  const [Ownertype, setOwnertype] = React.useState(true);
  const [ServiceArea, setServiceArea] = React.useState(true);
  
  const [FacilityCount,setFacilityCount] = useState(0);

  const [FacilityTableData, setFacilityTableData] = React.useState(true);

  let params = {
    menukey: menukey,
    action: "getDataList",
    bDispensingValue: bDispensingValue,
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    FLevelId:currFLevelId,
    FTypeId:currFTypeId,
    ServiceAreaId:currServiceAreaId,
    OwnerTypeId:currOwnerTypeId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const getFacilityData = () => { 
  
      setFacilityTableData(false);
      Service.default
          .postApi("source/api/api_pages/facility_list.php",params)
          .then((res) => {
           
            //setFacilitylist(res.datalist);
            setDataItems(res.datalist);
            setLoading(false);

            setFacilityCount(res.datalist.length);

            //console.log("T F",res.datalist.length);
            
    
          })
          .catch((err) => {});
   // }
  
  
  }

  
  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }
   

  
  const cZoneList = () => {  
  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
  


}


const cCommuneList = () => {  
  let CommuneParam = {action:"Communelist",RegionId:currRegionId,ZoneId:currZoneId,menukey:""}

  Service.default
      .postApi("source/combo_generic.php",CommuneParam)
      .then((res) => {
 
         setCommunelist(res.datalist);
         
      })
      .catch((err) => {});
// }


}
 


  if(Ownertype){
    setOwnertype(false);
    let ParamList={action:"Ownertypelist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setOwnertypelist(res.datalist);
        })
        .catch((err) => {});
  }

  if(ServiceArea){
    setServiceArea(false);
    let ParamList={action:"Servicearealist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setServicearealist(res.datalist);
        })
        .catch((err) => {});
  }


  
  

   

  const { isLoadingLang, mutate: bDispenseUpdate } = useMutation(
    api.updateFacility,
    {
      onSuccess: (data) => {
        if (data.data.status == 200) {
        api.getAllDropdown('FacilityList').then((response) => {
          console.log(response);
          localStorage.setItem(
            "FacilityList",
            JSON.stringify(response.datalist.FacilityList)
          );
        }) 

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

      }
        
      },
    }
  );

  
 


  

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setbDispensing(event.target.checked);
  };

  

  const handleRegionChange = (event) => { 
     setcurrRegionId(event.target.value);
         // let ZoneParam = {action:"Zonelist",RegionId:event.target.value,menukey:""}
    // cZoneList();
    
    // getFacilityData();
    
  };

  const handleZoneChange = (event) => {

    setcurrCommuneId(0);
    let CommuneParam = {action:"gCommunelist",RegionId:currRegionId,ZoneId:event.target.value,menukey:""}
    cCommuneList(CommuneParam);
    setcurrZoneId(event.target.value);
    
  };

  const handleCommuneChange = (event) => {
    setcurrCommuneId(event.target.value);
  };

  const handleFlevelChange = (event) => {
    setcurrFLevelId(event.target.value);
  };

  const handleFTypeChange = (event) => {
    setcurrFTypeId(event.target.value);
    

  };


  const handleOwnerTypeChange = (event) => {
    setOwnerTypeId(event.target.value);
  
  };

  const handleServiceAreaChange = (event) => {
    
    setServiceAreaId(event.target.value);
    
  };

  React.useEffect(() => {
    setcurrZoneId(0); 
    cZoneList();
  }, [currRegionId]);
  React.useEffect(() => {
    setcurrCommuneId(0);
    cCommuneList();
  }, [currRegionId,currZoneId]);


  React.useEffect(() => {
    getFacilityData();
  }, [currServiceAreaId,currRegionId,currZoneId,currCommuneId,currFTypeId,currOwnerTypeId]);



  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=FacilityExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&RegionId=" +
        currRegionId+
        "&ZoneId=" +
        currZoneId+
        "&CommuneId=" +
        currCommuneId+
        "&FTypeId=" +
        currFTypeId+
        "&ServiceAreaId=" +
        currServiceAreaId+
        "&OwnerTypeId=" +
        currOwnerTypeId
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    viewFacility(rowData);
  };

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      frozen:true,
    },
    {
      title: "",
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 40,
      frozen:true,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      headerFilter: true,
      width: 120,
      field: "FacilityCode",
      frozen:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerFilter: true,
      width: 260,
      frozen:true,
     // frozen:true,
      //hozAlign:"left",
    },
    { title: "Id", field: "id", visible: false },
    
    /*
    {
      title: DispensingLanguage[lan][menukey]["Facility Level"],
      field: "FLevelName",
      hozAlign: "left",
      width: 150,
      headerFilter: true,
      headerHozAlign: "left",
    },  */
    {
      title: DispensingLanguage[lan][menukey]["Facility Type"],
      field: "FTypeName",
      hozAlign: "left",
      width: 135,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["Facility Classification"],
      field: "FClassificationName",
      hozAlign: "left",
      width: 165,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["Department Name"],
      field: "RegionName",
      hozAlign: "left",
      width: 147,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS Name"],
      field: "ZoneName",
      hozAlign: "left",
      width: 120,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune Name"],
      field: "DistrictName",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Owner Type"],
      field: "OwnerTypeName",
      hozAlign: "left",
      width: 125,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Service Area"],
      field: "ServiceAreaName",
      hozAlign: "left",
      width: 125,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Address"],
      field: "FacilityAddress",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },{
      title: DispensingLanguage[lan][menukey]["Latitude"],
      field: "Latitude",
      hozAlign: "left",
      width: 90,
      headerFilter: true,
      headerHozAlign: "left",
    },{
      title: DispensingLanguage[lan][menukey]["Longitude"],
      field: "Longitude",
      hozAlign: "left",
      width: 100,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility In-Charge"],
      field: "FacilityInCharge",
      hozAlign: "left",
      width: 145,
      headerFilter: true,
      headerHozAlign: "left",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Facility Phone"],
      field: "FacilityPhone",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Email"],
      field: "FacilityEmail",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["DHIS2 Facility Uid"],
      field: "ExternalFacilityId",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Code"],
      field: "SOBAPSCode",
      hozAlign: "left",
      width: 130,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["GLN"],
      field: "GLN",
      hozAlign: "left",
      width: 100,
      headerFilter: true,
      headerHozAlign: "left",
    },

    // {
    //   title: DispensingLanguage[lan][menukey]["bDispensing"],
    //   field: "bDispense",
    //   hozAlign: "center",
    //   headerHozAlign: "center",
    //   width: 90,
    //   headerSort: false,
    //   formatter: reactFormatter(
    //     <RowInputData
    //       labelName="bDispensing"
    //       field="bDispense"
    //       fieldType="check-box"
    //     />
    //   ),
    //   cellClick: function (e, cell) {
    //     cell.setValue(!cell.getValue());
    //     const currow = cell.getRow();
    //     const rowdata = currow.getData();
    //     const id = rowdata.id;
    //     cellDataUpdatePop(id, cell);
    //   },
    // },
  ];

  const viewFacility = (data) => {
    
    let route = `facilities-list/edit/${data.id}`;
    props.history.push(route);
  };



  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    //console.log("Did",props.cell._cell.row.data);
    //const cellValue = props.cell._cell.value || 'Edit | Show';
    return (
      <>
        <ViewList
          onClick={() => {
            viewFacility(rowData);
          }}
        />
      </>
    );
  }

  const cellDataUpdatePop = (id, cell) => {
    let curcellval = cell.getValue();
    let bDispenseVal = curcellval;

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text:
        bDispenseVal == true
          ? t(
              DispensingLanguage[lan][menukey][
                "You want to update this facility!"
              ]
            )
          : t(
              DispensingLanguage[lan][menukey][
                "You want to delete this facilities relevant transaction records!"
              ]
            ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        bDispenseUpdate({ id, bDispenseVal });

      } else {
        //refetch();
      }
    });
  };

  const [all, setAll] = React.useState('0');

  return (
    <>
      <div className={classes.facilityPageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Facility"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              {/* <Grid item xs={2} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    //checked={checked} 
                    onChange={handleChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["bDispensing"]}
              />

              </Grid> */}

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RegionId"
                    name="RegionId"
                    value={currRegionId}
                    onChange={handleRegionChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                   {Regionlist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["ZS Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ZoneId"
                    name="ZoneId"
                    value={currZoneId}
                    onChange={handleZoneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All ZS"]}</MenuItem>

                   {Zonelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Commune Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="DistrictId"
                    name="DistrictId"
                    value={currCommuneId}
                    onChange={handleCommuneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Commune"]}</MenuItem>

                   {Communelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={1} sm={1}>
              <div className="float-right sw_btn_control">

                dfsdfsf
                </div>
              </Grid> */}

              <Grid item xs={3} sm={3}>

              
                <div className="float-right sw_btn_control">

                 {/* <Button
                  disabled={true}
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/facilities-list/add");
                  }}
                >
                 <i class="fas fa-map-marker-alt"></i>
                </Button> */}


                  {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button> */}

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                </div>
              </Grid>



              {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Level"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FLevelId"
                    name="FLevelId"
                    value={currFLevelId}
                    onChange={handleFlevelChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Level"]}</MenuItem>

                   {FlevelList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid> */}


              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FTypeId"
                    name="FTypeId"
                    value={currFTypeId}
                    onChange={handleFTypeChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Type"]}</MenuItem>

                   {FTypeList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Owner Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="OwnerTypeId"
                    name="OwnerTypeId"
                    value={currOwnerTypeId}
                    onChange={handleOwnerTypeChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Owner Type"]}</MenuItem>

                   {Ownertypelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Service Area"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ServiceAreaId"
                    name="ServiceAreaId"
                    value={currServiceAreaId}
                    onChange={handleServiceAreaChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Service Area"]}</MenuItem>

                   {Servicearealist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={3} sm={3}>
                
                  
                <div className="float-right">
                 <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Facility']+": "+FacilityCount}</h4>
                 </div>
                  
                
              </Grid>


              


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
         
			    <div className="uniqueName">
           {isLoadingUi && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={450}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityList;

const useStyles = makeStyles({
  facilityPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
