import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import * as Service from "../../../services/Service.js";

const ZSFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "zs";
  const [Regionlist, setRegionlist] = useState([]);
  const [Region, setRegion] = React.useState(true);

  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }

  const classes = useStyles();
  
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['ZS - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['ZS Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>
              
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                    <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["Department"]}*
                        </InputLabel>
                        <Select
                          error={errorObject.MenuKey}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="RegionId"
                          value={formData.RegionId}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          {Regionlist.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={errorObject.RegionId}>
                            {errorObject.RegionId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                      <Grid item xs={8} sm={8}>
                        <TextField
                          error={errorObject.ZoneName}
                          helperText={errorObject.ZoneName}
                          required
                          id="ZoneName"
                          name="ZoneName"
                          label={DispensingLanguage[lan][menukey]['ZS']}
                          value={formData.ZoneName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}></Grid>

                    </Grid>
                  </Grid>

                </Grid>

              
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']} 
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/zs")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
               {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/zs")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default ZSFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
