import React from "react";
import { Link } from "react-router-dom";
import * as Service from "../../services/Service.js";
// reactstrap components
import {
  
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  
  Service.default
  .postApi("source/combo_generic.php",{action:"getDispensingLanguageList"})
  .then((res) => {
    if (res.success == 1) {

      let DispensingLanguageData = res.datalist;
      localStorage.setItem(
        "DispensingLanguage",
        JSON.stringify(DispensingLanguageData)
      );
      
    }});

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        //setNavbarColor("");
        setNavbarColor("sticky");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        //setNavbarColor("navbar-transparent");
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };

   
  });


  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

        <div id="sticky-header" className={"header-menu " + navbarColor}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">

              <div className="logo_card">
                <div className="logo">
                  <Link to="/home"><img alt="..." src={require("assets/img/logo.png")}></img></Link>
                </div>
                <div className="logo_desc">
                  <div className="title"><Link to="/home">ePharmacie</Link></div>
                  <div className="name"><Link to="/home">BENIN</Link></div>
                </div>
              </div>

            </div>
            <div className="col-lg-9">
              <div className="tp-mega-full">
                <div className="tp-menu align-self-center">
                  <nav className="desktop_menu">
                    <ul> 
                    <li><a href="/home">HOME</a></li>
                      <li><a href="/facilities-list">Facilities</a></li>
                      <li>
                        <a href="/home">
                         Products
                          <span className="tp-angle pull-right">
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </a>
                        <ul className="submenu right_position">
                          <li><a href="/products-list">Generics</a></li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamplesNavbar;
