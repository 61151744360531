import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import RecipientsFormData from "./RecipientsFormData.js";


const AddRecipients = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "recipients";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;
const FacilityId = localStorage.getItem("FacilityId");
  const [formData, setFormData] = useState({
    FacilityId:FacilityId,
    IssuedToGroupId: "",
    IssuedToName: "",
    
    
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  // useQuery(
  //   ["supplier", id],
  //   () =>
  //     api.getSupplierData(id).then((res) => {
  //       setFormData(res.data);
  //     }),
  //   {
  //     enabled: Boolean(id),
  //   }
  // );

  const { isLoading, mutate } = useMutation(
    api.saveRecipients,
     {
    onSuccess: (data) => {

      if(data.status==200) {

      // api.getAllDropdown("PackSizelist").then((response) => {
      //   localStorage.setItem(
      //     "PackSizelist",
      //     JSON.stringify(response.datalist.PackSizelist)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("recipients");
      props.history.push("/recipients");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      FacilityId:"",
      IssuedToGroupId: "",
    IssuedToName: "",
   
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FacilityId","IssuedToGroupId","IssuedToName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <RecipientsFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddRecipients;
