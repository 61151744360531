/*eslint-disable*/
import React, { useState } from "react";

// reactstrap components
import { Modal, ModalBody } from "reactstrap";

function IndexHeader() {

  const [open, setModalOpen] = useState(false);

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "home";

  return (
    <>

    {/* selection 1 */}
    <div className="sw_section pt100" style={{backgroundImage: "url("+require("assets/img/bg_1.jpg") +")"}}>
       <div className="container-fluid">
         <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section_desc">
                <h1 className="title">{ DispensingLanguage[lan][menukey]["All medicines are under control with Drug Track and Trace System"] }</h1>
                <p className="desc">{ DispensingLanguage[lan][menukey]["The Pharmaceutical Track and Trace System ensures the supply of original and reliable medicines by preventing drug counterfeiting and drug smuggling."] } </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video_card black-overlay">
                <img src={require("assets/img/video_bg.jpg")} alt=""></img>
                <div className="play_card">
                  <a href="javascript:void(0)" className="play-btn" onClick={function noRefCheck(){setModalOpen(true);}}>
                  <i class="fa fa-play-circle"></i>
                  </a>
                </div>
              </div>
            </div>
         </div>
       </div>
      </div>

    {/* <div className="sw_section" style={{backgroundImage: "url("+require("assets/img/bg_1.jpg") +")"}}>
       <div className="container">
         <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section_desc">
                <h1 className="title">All medicines are under control with Drug Track and Trace System </h1>
                <p className="desc">The Pharmaceutical Track and Trace System ensures the supply of original and 
                  reliable medicines by preventing drug counterfeiting and drug smuggling. </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image_card">
                <img src={require("assets/img/app_image_1.png")} alt=""></img>
              </div>
            </div>
         </div>
       </div>
      </div> */}
      {/* /selection 1/ */}

      {/* /Popup Modal Video/ */}
      <div>
        <Modal className="video_iframe_modal" centered={true} size={'lg'} isOpen={open} toggle={function noRefCheck(){}}>
          <ModalBody>
            <div className="video_iframe">
            <a href="javascript:void(0)" className="sw_close_btn" onClick={function noRefCheck(){
              setModalOpen(false);
            }}>
              <i class="fas fa-times"></i>
            </a>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/S9ZvAKblHpA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          
          </div>
          </ModalBody>
        </Modal>
      </div>
      {/* /Popup Modal Video/ */}

    </>
  );
}

export default IndexHeader;
