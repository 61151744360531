import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";


import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
//get DispensingLanguage



const AdjustmentTypeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,

  ...props

}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-type-entry";
  const PickListMethodList = JSON.parse(localStorage.getItem("gPickListMethod"));
  const FlevelListList = JSON.parse(localStorage.getItem("FlevelList"));
  const LinkAdjTypeList = JSON.parse(localStorage.getItem("LinkAdjTypeList"));
  const AStoreList = JSON.parse(localStorage.getItem("gStoreList"));

  const [getFlevel, setFlevel] = useState([]);
  const [LinkedadjtypeValue, setACLinkedadjtypeValue] = useState({ id: '', name: "" });


  useEffect(() => {

    let tmp = formData.FLevelIds;
    if (tmp != "") {
      let tmpFLevelIds = tmp.split(',');
      let myArray = [];
      for (let i = 0; i < tmpFLevelIds.length; i++) {
        const tmparrayobject = FlevelListList[FlevelListList.findIndex(FlevelLis_tList => FlevelLis_tList.id == tmpFLevelIds[i])];
        myArray.push(tmparrayobject);
      }
      setFlevel(myArray);

    }

  }, [formData.PickListMethod]);


  const changeHandler = (val) => {
    setFlevel(val);
    let FLevelId = "";
    for (let i = 0; i < val.length; i++) {
      if (FLevelId == "") {
        FLevelId = val[i].id;
      } else {
        FLevelId = FLevelId + "," + val[i].id;
      }

    }

    if (FLevelId != "") {
      formData.FLevelIds = FLevelId;
    } else {
      formData.FLevelIds = "";
    }


  };

  useEffect(() => {

  setACLinkedadjtypeValue(LinkAdjTypeList[LinkAdjTypeList.findIndex(LinkAdjType_List => LinkAdjType_List.id == formData.LinkAdjTypeId)]);


  }, [formData.LinkAdjTypeId]);

  const changeLinkedAdjTypeId = (val) => {

    setACLinkedadjtypeValue(val);


    if (val != null) {
      formData.LinkAdjTypeId = val.id;
    } else {
      formData.LinkAdjTypeId = "";
    }


  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Adjustment Type - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Adjustment Type Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>

                      <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Store"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="StoreId"
                            value={formData.StoreId}
                            fullWidth
                            onChange={(e) => handleChange(e)}

                          >
                            {AStoreList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.StoreId}>
                            {errorObject.StoreId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.LanguageText}
                          helperText={errorObject.LanguageText}

                          id="LanguageText"
                          name="LanguageText"
                          label={DispensingLanguage[lan][menukey]['Adjustment Type']}
                          value={formData.LanguageText}
                          fullWidth
                          required
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Pick List Method"]}
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="PickListMethod"
                            value={formData.PickListMethod}
                            fullWidth
                            required
                            onChange={(e) => handleChange(e)}
                          >
                            {PickListMethodList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.PickListMethod}>
                            {errorObject.PickListMethod}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Facility Level"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="FLevelIds"
                            value={formData.FLevelIds}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            {FlevelListList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FLevelIds}>
                            {errorObject.FLevelIds}
                          </FormHelperText>
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={3} sm={3}>

                        <Autocomplete
                          multiple
                          id="size-small-standard-multi"
                          size="small"
                          options={FlevelListList}
                          value={getFlevel}
                          getOptionLabel={(option) => option.name}
                          filterSelectedOptions

                          onChange={(event, value) => changeHandler(value)}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility Level"]}
                              error={errorObject.FLevelIds}
                              helperText={errorObject.FLevelIds}
                              required
                              id="FLevelIds"
                              name="FLevelIds"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="AdjTypeId"
                          name="AdjTypeId"
                          label="AdjTypeId"
                          value={formData.AdjTypeId}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="TranslationId"
                          name="TranslationId"
                          label="TranslationId"
                          value={formData.TranslationId}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="LangCode"
                          name="LangCode"
                          label="LangCode"
                          value={formData.LangCode}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                    </Grid>



                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>


                          <Autocomplete
                            // disabled={demanderactive}
                            autoHighlight
                            id="atc"
                            options={LinkAdjTypeList}
                            onChange={(event, value) => changeLinkedAdjTypeId(value)}
                            getOptionLabel={(option) => option.name}
                            value={LinkedadjtypeValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={DispensingLanguage[lan][menukey]["Linked Adjustment Type"]}
                                variant="standard"
                                error={errorObject.LinkAdjTypeId}
                                helperText={errorObject.LinkAdjTypeId}
                                id="LinkAdjTypeId"
                                name="LinkAdjTypeId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>

                      </Grid>

                      <Grid item xs={3} sm={3} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.IsPositive}
                              onChange={(e) => handleCheck(e)}
                              name="IsPositive"
                              value="on"
                            />
                          }
                          label="Is Positive"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.isStockTake}
                              onChange={(e) => handleCheck(e)}
                              name="isStockTake"
                              value="on"
                            />
                          }
                          label="Is Stock Take"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.isExpired}
                              onChange={(e) => handleCheck(e)}
                              name="isExpired"
                              value="on"
                            />
                          }
                          label="Is Required"
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/adjustment_type")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/adjustment_type")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div >
  );
};

export default AdjustmentTypeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
