import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent, } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";


const Shis2DataTranstersetting = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "dhis2-data-transfer-settings";


  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [currRegionId, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [currZoneText, setZoneText] = useState("");
  const [currZoneId, setcurrZoneId] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();


  let params = {
    menukey: menukey,
    action: "getDataList",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { refetch } = useQuery(
    [params],
    api.getDHIST2ransferSettings,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  const { mutate } = useMutation(api.deleteDHIST2ransfers, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("DHIS2DataTransferSettings");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
    },
  });



 
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=DHIS2DataTransferSettings" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title:  DispensingLanguage[lan][menukey]["Dataset Id"],
      field: "DatasetId",
      width: 120,
      visible: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dataset Type"],
      width: 150,
      headerFilter:false,
      headerSort: false,
      field: "DatasetType",
    },
    {
      title: DispensingLanguage[lan][menukey]["Organisation Unit Group Id"],
      headerFilter:true,
      field: "OrgGroupId",
      width: 250,
      headerSort: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dataset Label"],
      headerFilter:true,
      field: "DatasetLevel",
    },
    {
      title: DispensingLanguage[lan][menukey]["Duration Hour"],
      headerFilter:true,
      field: "DurationDay",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Duration Error Hour"],
      headerFilter:true,
      field: "ErrorHour",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editCommune = (data) => {
    let route = `shis2-data-transter-setting/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteDHIS2DataTransferSettings = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this DHIS2 Data Transfer Settings!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editCommune(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteDHIS2DataTransferSettings(rowData);
          }}
        />
      </>
    );
  }


  //const [all, setAll] = React.useState('0');



  return (
    <>
     
<div className={classes.communePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["DHIS2 Data Transfer Settings"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
        <Grid container>
             <Grid item xs={12} sm={12}>
               <div className="float-right sw_btn_control">
                 <Button
                   color="info"
                   className="mr-2"
                   onClick={() => {
                     props.history.push("/shis2-data-transter-setting/add");
                   }}
                 >
                   {DispensingLanguage[lan][menukey]["Add"]}
                 </Button>

                 <Button
                 color="info"
                 className="mr-2 sw_icons"
                 onClick={() => PrintPDFExcelExportFunction("print")}
               >
                 <i class="fas fa-print"></i>
               </Button>

               <Button
                 color="info"
                 className="mr-2 sw_icons"
                 onClick={() => PrintPDFExcelExportFunction("excel")}
               >
                 <i className="far fa-file-excel"></i>
               </Button>

               </div>
             </Grid>
           </Grid>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Shis2DataTranstersetting;

const useStyles = makeStyles({
  communePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
