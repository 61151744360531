import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { addDays, subDays } from "date-fns";

import { Button } from "reactstrap";
import moment from "moment";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useQuery,useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
   
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official"; 

import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";



const ProductsDetails = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "brand-license-expiry-date";

  exporting(Highcharts); 
  const classes = useStyles();
  const { t, i18n } = useTranslation();

const [toggleButtonValue, setToggleButton] = React.useState("3Month");
//console.log(toggleButtonValue);

//change functions button

const handleChangeToggle = (event, tmpToggleButtonValue) => {
  if (tmpToggleButtonValue) {
    //console.log(tmpToggleButtonValue);
    setToggleButton(tmpToggleButtonValue);
  }
};


  const [option_BrandByExpiryDate, setOption_BrandByExpiryDate] = useState([]);

  const { data:BrandByExpiryDate } = useQuery("BrandByExpiryDat", api.getBrandByExpiryDate, {
    onSuccess: (data) => {

      setOption_BrandByExpiryDate({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 300,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        },
        series: [
          {
            name: DispensingLanguage[lan][menukey]["Brand License Expiry Date"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });
const [BrandCount,setBrandCount] = useState(0);
   const [dataItems, setDataItems] = useState([]);
   
  const gettoggleButtonValueType11 = useQuery(
    ["BrandLicenseExpiryDate"],
    () => api.getBrandByExpiryDateDetails(toggleButtonValue),
    {
      onSuccess: (data) => {
        setDataItems(data);
        //console.log("CountData", data.length);
        setBrandCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  useEffect(() => {
    gettoggleButtonValueType11.refetch();
  }, [toggleButtonValue]);



  // React.useEffect(() => {
  //   prescriptionData.refetch();
  // },[toggleButtonValue]);

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Status"],
      field: "ProductStatus",
      width:"100",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Brand Code"],
      field: "ItemCode",
      headerSort: true,
      headerFilter: true,
      width:"130",

    },
    {
      title: DispensingLanguage[lan][menukey]["Brand Name"],
      field: "ItemName",
      headerSort: true,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacture Name"],
      field: "IntituleFabriquant",
      headerSort: true,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "DateExpiration",
      headerSort: true,
      width:"150",
      formatter: function (cell) {
        let value = cell.getValue();
        if(value != null)
        return moment(value).format("DD/MM/YYYY");
        else
        return "";
      },

    }
  ];


  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=BrandLicenseExpiryExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
        +"&toggleButtonValue=" + toggleButtonValue
        + "&currentDate=" + currentDate
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  const current = new Date();
  const currentDate = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;
  
  return (
    <>
      <AfterLoginNavbar {...props} />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Brand License Expiry Details"]} (Date: {currentDate})
              </div>
            </div>
          </div>


          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey]["Brand License Expiry Date"]
                  )}
          
                />
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={option_BrandByExpiryDate}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* new row */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["Brand Details"])}

                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                  </div>
                  }
                />

                <CardContent>
                <div className="row">
                    <div className="col-md-8">

                  
                      <ToggleButtonGroup
                        className="sw_ToggleButtonGroup"
                        color="primary"
                        value={toggleButtonValue}
                        exclusive
                        onChange={handleChangeToggle}
                       
                      >
                        <ToggleButton value="3Month">
                        {DispensingLanguage[lan][menukey]['3 M']}
                        </ToggleButton>
                        <ToggleButton
                          value="6Month"
                        >
                        {DispensingLanguage[lan][menukey]['6 M']}
                        </ToggleButton>
                        <ToggleButton value="1Year">
                        {DispensingLanguage[lan][menukey]['1 Y']}
                        </ToggleButton>
                        <ToggleButton value="GreaterThan1Year">
                        {DispensingLanguage[lan][menukey]['> 1 Y']}
                        </ToggleButton>
                        <ToggleButton value="Expired">
                        {DispensingLanguage[lan][menukey]['Expired']}
                        </ToggleButton>
                        <ToggleButton value="NoExpriry">
                        {DispensingLanguage[lan][menukey]['No Expiry']}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    

                    <div className="col-md-4">
                    <div className="float-right">
                    <h4 className="totalbrand">{DispensingLanguage[lan][menukey]['Total Brands']+": "+BrandCount}</h4>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                    <div  className="col-md-12">
                      <div className="uniqueName">
                       <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={"350"}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

        </div>
      </div>
    </>
  );
};

export default ProductsDetails;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },

});
