import React, { useState ,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@material-ui/core";


import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
//get DispensingLanguage



const SupplierFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "supplier";
  const CountryList = JSON.parse(localStorage.getItem("gAllCountryListSelected"));
  const [countryacValue, setACcountryValue] = useState({ id: '', name: "" });


  useEffect(() => {

    setACcountryValue(CountryList[CountryList.findIndex(Country_List => Country_List.id == formData.ISO3)]);
   

  }, [formData.ISO3]);

  const changeCountryCodeId = (val) => {

    setACcountryValue(val);

    if (val != null) {
      formData.ISO3 = val.id;
    } else {
      formData.ISO3 = "";
    }
   
    


  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Supplier - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Supplier Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.SupplierName}
                          helperText={errorObject.SupplierName}
                          required
                          id="SupplierName"
                          name="SupplierName"
                          label={DispensingLanguage[lan][menukey]['SupplierName']}
                          value={formData.SupplierName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>

                        <FormControl className={classes.fullWidth}>


                          <Autocomplete
                            // disabled={demanderactive}
                            required
                            autoHighlight
                            disableClearable
                            id="atc"
                            options={CountryList}
                            onChange={(event, value) => changeCountryCodeId(value)}
                            getOptionLabel={(option) => option.name}
                            value={countryacValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ISO3}
                                label={DispensingLanguage[lan][menukey]["Country"]}
                                variant="standard"
                                error={errorObject.ISO3}
                                helperText={errorObject.ISO3}
                                required
                                id="ISO3"
                                name="ISO3"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>



                        {/* <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Country"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="ISO3"
                            value={formData.ISO3}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            {CountryList.map((item, index) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ISO3}>
                            {errorObject.ISO3}
                          </FormHelperText>
                        </FormControl> */}

                      </Grid>



                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.Email}
                          helperText={errorObject.Email}

                          id="Email"
                          name="Email"
                          label={DispensingLanguage[lan][menukey]['Email']}
                          value={formData.Email}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.ContactName}
                          helperText={errorObject.ContactName}

                          id="ContactName"
                          name="ContactName"
                          label={DispensingLanguage[lan][menukey]['Contact Name']}
                          value={formData.ContactName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                    </Grid>



                  </Grid>

                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        error={errorObject.ContactNo}
                        helperText={errorObject.ContactNo}
                        required
                        id="ContactNo"
                        name="ContactNo"
                        label={DispensingLanguage[lan][menukey]['Contact No']}
                        value={formData.ContactNo}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        error={errorObject.Fax}
                        helperText={errorObject.Fax}

                        id="Fax"
                        name="Fax"
                        label={DispensingLanguage[lan][menukey]['Fax']}
                        value={formData.Fax}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        error={errorObject.SupplierAddress}
                        helperText={errorObject.SupplierAddress}

                        id="SupplierAddress"
                        name="SupplierAddress"
                        label={DispensingLanguage[lan][menukey]['Supplier Address']}
                        value={formData.SupplierAddress}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        error={errorObject.Url}
                        helperText={errorObject.Url}

                        id="Url"
                        name="Url"
                        label={DispensingLanguage[lan][menukey]['URL']}
                        value={formData.Url}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <TextField
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"
                        value={formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name"
                      />
                      <TextField
                        id="SupplierId"
                        name="SupplierId"
                        label="SupplierId"
                        value={formData.SupplierId}
                        fullWidth
                        hidden
                        autoComplete="family-name"
                      />
                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
              className="mr-2"
              variant="contained"
              type="reset"
              onClick={() => handleReset()}
            >
              {DispensingLanguage[lan][menukey]['Reset']}
            </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/supplier")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/supplier")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div >
  );
};

export default SupplierFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
