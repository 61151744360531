import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

const MyDatasources = (props) => {
  
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "my-datasources";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;

  const classes = useStyles();

  const { useState } = React;
 
  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const REPORT_URL = removeStrFromURL.replace('backend/', '');
  let MyDashboardURL = REPORT_URL+"reports/my_tables_entry_form.php?lan="+lan + "&UserId="+UserId;

  return (
    <>
      <div className={classes.PageTitle}>
        <div className="d-flex justify-packSize mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["My Datasources"]}
              </div>
            </div>
          </Grid>
        </div>

        <div className="uniqueName">
          <div className="main min-height">
            <div dangerouslySetInnerHTML={{ __html: '<iframe src='+MyDashboardURL+' frameBorder="0" />'}} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDatasources;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
