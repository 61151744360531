import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api"; 
import SvdlProductEntryFormData from "./SvdlProductEntryFormData"




const EditSvdlProductEntry = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-entry";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].name;


  const [formData, setFormData] = useState({
    ItemGroupId: "",
    ProductSubGroupId:"",
    ItemCode:"", 
    ItemName:"", 
    ShortName:"", 
    UnitId:"", 
    Price:"", 
    PackSize:"", 
    CameCode:"", 
    bKeyItem:"", 
    isActive:"",
    OpStock:"", 
    ReceiveQty:"", 
    DispenseQty:"", 
    AdjustQty:"", 
    TotalDispenseQty:"", 
    ClStock:"", 
    ExpiryQty:"", 
    ExpiryDate:"", 
    StockoutDays:"",
    AMC:"",
    DMM:"",
    MOS:"",
    Remarks:"",
    PriceUID:"",
    
  });
  
  
  
  
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

   const { data } = useQuery(
    ["svdlproduct", id],
    () =>
      api.getSingleSVDLProduct(id).then((res) => {
       
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  ); 

  const { mutate } = useMutation(api.upSVDLProduct,  {
   
    onSuccess: (data) => {
      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        //swal(data.data.message, "", "success");
        queryClient.getQueriesData("svdlProductEntry");//api page name
        props.history.push("/svdl-product-entry");//
  
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ItemGroupId: "",
      ProductSubGroupId:"",
      ItemCode:"", 
      ItemName:"", 
      ShortName:"", 
      UnitId:"", 
      Price:"", 
      PackSize:"", 
      CameCode:"", 
      bKeyItem:"", 
      isActive:"",
      OpStock:"", 
      ReceiveQty:"", 
      DispenseQty:"", 
      AdjustQty:"", 
      TotalDispenseQty:"", 
      ClStock:"", 
      ExpiryQty:"", 
      ExpiryDate:"", 
      StockoutDays:"",
      AMC:"",
      DMM:"",
      MOS:"",
      Remarks:"",
      PriceUID:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ProductSubGroupId","ItemCode","ItemName","ShortName","UnitId","PackSize"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =  DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      
      mutate(fDtat);
       
    }
  };

  return (
    <>
      <SvdlProductEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default EditSvdlProductEntry;
