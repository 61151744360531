import React, { forwardRef, useRef , useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/export_import.css";

import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import { DeleteOutline, Edit, CloudUploadOutlined } from "@material-ui/icons";

//Services
import { getDefaultMonthYear } from "../../../services/Common";

//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

import swal from "sweetalert";

// import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { el } from "date-fns/locale";

const CAMEDataImport = (props) => {
  const classes = useStyles();
  const { useState } = React;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "came-data-import";
  // const FacilityId = localStorage.getItem("FacilityId");
  // const getCentralWarehouseId = JSON.parse(localStorage.getItem("getCentralWarehouseId"));
  const getCWRWList = JSON.parse(localStorage.getItem("getCWRWList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  
  const [currYearId, setCurrYearId] = useState(getDefaultMonthYear().defaultYear);
  const [currMonthId, setCurrMonthId] = useState(getDefaultMonthYear().defaultMonthId);

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [UploadedFileName, setUploadedFileName] = useState();
  const [ErrorMgs, setErrorMgs] = useState();
  const [isLoading, setLoading] = useState(false);
  const [ErrorMsgShowing, setErrorMsgShowing] = useState(false);
  const [ShowErrorMsg, setShowErrorMsg] = useState();

  const SelectedFacilityId = getCWRWList ? getCWRWList[0].FacilityId : 0;
  const [currFacilityId, setCurrFacilityId] = useState(SelectedFacilityId);

  const [FacilityErrorMgs, setFacilityErrorMgs] = useState();
  const [MonthErrorMgs, setMonthErrorMgs] = useState();
  const [YearErrorMgs, setYearErrorMgs] = useState();
  
  const [uploadFormData, setFormData] = useState({
    FileName: "",
    menukey: menukey,
    lan: lan,
    // FacilityId: getCentralWarehouseId,
    FacilityId: '',
    MonthId: '',
    Year: '',
    UserId: UserId
  });

  const APP_URL = process.env.REACT_APP_API_URL;

  const changeHandlerForFileUpload = (event) => {

      let selectedFile = event.target.files[0];

      if(selectedFile == undefined){
        return;
      }

      setErrorMsgShowing(false);
      setLoading(true);
      setbuttonDisabled(false);

      let FileName = selectedFile.name;
      let FileExt = FileName.split(".").pop();
      let Filetype = FileExt.toLowerCase();

      //The file Check extension
      if (Filetype == "xlsx" || Filetype == "xls") {
        setErrorMgs("");
        handleFileUpload(selectedFile);
      } else {
        setErrorMgs(
          DispensingLanguage[lan][menukey]["Sorry, only excel file are allowed"]
        );
        setUploadedFileName("");
        uploadFormData["FileName"] = "";
        setbuttonDisabled(true);
        setLoading(false);
      }
  };

  const handleFileUpload = (selectedFile) => {
    const formData = new FormData();

    formData.append("FileName", selectedFile);

    let finalUrl = APP_URL + "upload/upload.php";

    fetch(finalUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.msgType == "success") {
          setUploadedFileName(res.FileName);
          uploadFormData["FileName"] = res.FileName;
          setErrorMgs("");
        } else {
          setErrorMgs(res.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMgs(error);
        setLoading(false);
      });
  };

  const { mutate } = useMutation(api.isReportCreatedCAMEData, {
    onSuccess: (data) => {

      let msg = data.message;
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(msg),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          mutateUpdate(uploadFormData);
        }
      });
    },
  });

  const { mutate: mutateUpdate } = useMutation(api.CreateReportCAMEDataImport, {
    onSuccess: (data) => {
      let msg = data.message;
      if (data.success == 1) {
        swal(msg, "", "success");
      } else {
        setShowErrorMsg(msg);
        setErrorMsgShowing(true);
      }

      uploadFormData["FileName"] = "";
      setUploadedFileName("");
      setLoading(false);
      setbuttonDisabled(true);
    },
  });

  const handleSubmit = (data) => {
    setLoading(true);
    setbuttonDisabled(true);
    let FileName = uploadFormData.FileName;
    if (FileName != "") {
      mutate(uploadFormData);
    } else {
      setErrorMgs(DispensingLanguage[lan][menukey]["Select a excel file"]);
      setLoading(false);
    }
  }

  const handleSubmitForExcelImport = async (e) => {
    if(currFacilityId == ''){
      setFacilityErrorMgs(
        DispensingLanguage[lan][menukey]["Select a Facility"]
      );
      return;
    }

    if(currMonthId == ''){
      setMonthErrorMgs(
        DispensingLanguage[lan][menukey]["Select a Month"]
      );
      return;
    }

    if(currYearId == ''){
      setYearErrorMgs(
        DispensingLanguage[lan][menukey]["Select a Year"]
      );
      return;
    }
    
    uploadFormData["FacilityId"] = currFacilityId;
    uploadFormData["MonthId"] = currMonthId;
    uploadFormData["Year"] = currYearId;
    handleSubmit();

    // swal({
    //   title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    //   text: t(DispensingLanguage[lan][menukey]["Confirm CAME Data Import"]),
    //   icon: "warning",
    //   buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    //   t(DispensingLanguage[lan][menukey]["Yes"])],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     uploadFormData["FacilityId"] = currFacilityId;
    //     uploadFormData["MonthId"] = currMonthId;
    //     uploadFormData["Year"] = currYearId;
    //     handleSubmit();
    //   }
    // });
  };

  const handleFacilityChange = (event) => {
    setCurrFacilityId(event.target.value);
    setFacilityErrorMgs('');
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
    setMonthErrorMgs('');
  };

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
    setYearErrorMgs('');
  };

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["CAME Data Import"]}
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2}></Grid>
              <Grid item xs={8} sm={8}>
                {/* ====Upload Box=== */}
                <div className="tp-upload-box marginTopBottom">
                  <div className="tp-header">
                    <Grid container spacing={3}>
                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth} sx={{ m: 1 }} size="small">
                          <InputLabel id="demo-simple-select-helper-label">
                             {DispensingLanguage[lan][menukey]["Facility"]}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            fullWidth
                            id="FacilityId"
                            name="FacilityId"
                            value={currFacilityId}
                            onChange={handleFacilityChange}
                          >
                            {getCWRWList.map((item, index) => {
                              return (
                                <MenuItem value={item.FacilityId}>
                                  {item.FacilityName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div className="selectFile text-left">
                          <span className="red">{FacilityErrorMgs}</span>
                        </div>
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth} sx={{ m: 1 }} size="small">
                          <InputLabel id="demo-simple-select-helper-label">
                             {DispensingLanguage[lan][menukey]["Month"]}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            fullWidth
                            id="MonthId"
                            name="MonthId"
                            value={currMonthId}
                            onChange={handleMonthChange}
                          >
                            {MonthList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div className="selectFile text-left">
                          <span className="red">{MonthErrorMgs}</span>
                        </div>
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth} sx={{ m: 1 }} size="small">
                          <InputLabel id="demo-simple-select-helper-label">
                             {DispensingLanguage[lan][menukey]["Year"]}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            fullWidth
                            id="Year"
                            name="Year"
                            value={currYearId}
                            onChange={handleYearChange}
                          >
                            {YearList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div className="selectFile text-left">
                          <span className="red">{YearErrorMgs}</span>
                        </div>
                      </Grid>
                      </Grid>
                  </div>
                  <div className="tp-body">
                    <div className="tp-file-upload">
                      <input
                        type="text"
                        name="selectFile"
                        id="selectFile"
                        value={UploadedFileName}
                        placeholder={
                          DispensingLanguage[lan][menukey]["Choose a file"]
                        }
                        className="tp-form-control"
                        readOnly={true}
                      />
                      <div className="file_browse_box">
                        <input
                          type="file"
                          name="FileName"
                          id="FileName"
                          className="tp-upload"
                          onChange={changeHandlerForFileUpload}
                        />
                        <label for="FileName" className="file_browse_icon">
                          <span className="icon-upload">
                            <CloudUploadOutlined />
                          </span>
                          {DispensingLanguage[lan][menukey]["Browse"]}
                        </label>
                      </div>
                    </div>
                    <div className="selectFile text-left">
                      <span className="red">{ErrorMgs}</span>
                    </div>
                    <div className="SuccessMgs display-none"></div>
                  </div>
                  <div className="tp-footer text-right sw_relative">
                    {isLoading && <LoaderForGlobal />}
                   
                    <Button
                      disabled={buttonDisabled}
                      className="mr-3 pull-right"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitForExcelImport()}
                    >
                      {DispensingLanguage[lan][menukey]["IMPORT DATA"]}
                    </Button>
                  </div>
                </div>
                {/* ====Upload Box=== */}
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
            {/* {ErrorMsgShowing && 
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div className="msg-error">
                  <textarea className="error-msg-box" rows="5" value={ShowErrorMsg} readOnly={true}></textarea>
                </div>
              </Grid>
            </Grid>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CAMEDataImport;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
