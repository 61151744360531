import React, { useState } from "react";
import { useParams } from "react-router-dom";

import ManufacturerAuthorizationFormData from "./ManufacturerAuthorizationFormData.js";

const ManufacturerAuthorizationEdit = (props) => {

  const { id } = useParams();

  return (
    <>
      <ManufacturerAuthorizationFormData
        BrandNo={ id }
        {...props}
      />
    </>
  );
};

export default ManufacturerAuthorizationEdit;
