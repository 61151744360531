import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
 
} from "@material-ui/core";


import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
//get DispensingLanguage



const RecipientsFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,

  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "recipients";
  const getIssuedToGroupList = JSON.parse(localStorage.getItem("getIssuedToGroupList"));
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [FacilityacValue, setACFacilityValue] = useState({ id: '', name: "" });
  const [GroupacValue, setACGroupValue] = useState({ id: '', name: "" });


  useEffect(() => {
    setACFacilityValue(FacilityList[FacilityList.findIndex(Facility_List => Facility_List.id == formData.FacilityId)]);
    setACGroupValue(getIssuedToGroupList[getIssuedToGroupList.findIndex(getIssuedTo_GroupList => getIssuedTo_GroupList.id == formData.IssuedToGroupId)]);
  }, [formData.FacilityId,formData.IssuedToGroupId]);

  const changeFacilityId = (val) => {

    // console.log(val);
    setACFacilityValue(val);


    if (val != null) {
      formData.FacilityId = val.id;
    } else {
      formData.FacilityId = "";
    }


  };

  const changeGroupId = (val) => {

    // console.log(val);
    setACGroupValue(val);


    if (val != null) {
      formData.IssuedToGroupId = val.id;
    } else {
      formData.IssuedToGroupId = "";
    }


  };


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Recipients - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Recipients Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>

                      <Grid item xs={4} sm={4}>

                        <FormControl className={classes.fullWidth}>


                          <Autocomplete
                            disabled={addProductForm ? false : true}
                            autoHighlight
                            id="atc"
                            options={FacilityList}
                            onChange={(event, value) => changeFacilityId(value)}

                            getOptionLabel={(option) => option.name}
                            value={FacilityacValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FacilityId}
                                label={DispensingLanguage[lan][menukey]["Facility"]}
                                variant="standard"
                                error={errorObject.FacilityId}
                                helperText={errorObject.FacilityId}
                                required
                                id="FacilityId"
                                name="FacilityId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>
                      </Grid>


                      <Grid item xs={4} sm={4}>

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                           
                            autoHighlight
                            id="atc"
                            options={getIssuedToGroupList}
                            onChange={(event, value) => changeGroupId(value)}

                            getOptionLabel={(option) => option.name}
                            value={GroupacValue}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.IssuedToGroupId}
                                label={DispensingLanguage[lan][menukey]["Issued To Group"]}
                                variant="standard"
                                error={errorObject.IssuedToGroupId}
                                helperText={errorObject.IssuedToGroupId}
                                required
                                id="IssuedToGroupId"
                                name="IssuedToGroupId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>

                       
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.IssuedToName}
                          helperText={errorObject.IssuedToName}
                          required
                          id="IssuedToName"
                          name="IssuedToName"
                          label={DispensingLanguage[lan][menukey]['Issued To Name']}
                          value={formData.IssuedToName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>



                      <Grid item xs={3} sm={3}>

                        <TextField
                          id="IssuedToId"
                          name="IssuedToId"
                          label="IssuedToId"
                          value={formData.IssuedToId}
                          fullWidth
                          hidden
                          autoComplete="family-name"
                        />
                      </Grid>
                    </Grid>



                  </Grid>

                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipients")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipients")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div >
  );
};

export default RecipientsFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
