import React, { useState, useRef, useEffect } from "react"; //forwardRef,
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";
import Autocomplete from '@material-ui/lab/Autocomplete';
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import 
{ 
Grid,
Card,
CardContent,
MenuItem,
Select,
NativeSelect,
FormControl,
InputLabel,
FormHelperText,
TextField,
Typography
   }
 from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import OrderModal from "./OrderModal";




// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";



const Dispense = (props) => {
  //get epharmaciyLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "dispense";
const TransactionTypeId = 4;

//const FacilityId = localStorage.getItem("DefaultFacilityId");
//const StoreId = localStorage.getItem("DefaultStoreId");
//const FacilityName = localStorage.getItem("DefaultFacilityName");
const FacilityId = localStorage.getItem("FacilityId");
const StoreId = localStorage.getItem("StoreId");
const FacilityName = localStorage.getItem("FacilityName");

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  

  const classes = useStyles();
  const tableRef = useRef();

  const [selectedRow, setSelectedRow] = useState(null);
  const [productsData, selectProductsData] = useState([]);
  const [productsAllData, selectProductsAllData] = useState([]);
  const [productGroupData, selectProductGroupData] = useState([]);
  const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

 

// Facility Auto
const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99ByLevelId"));
const [currFacilityId, setCurrFacilityId] = useState(FacilityId);
const [currFacilityName, setCurrFacilityName] = useState(FacilityName);

const [currStoreId, setcurrStoreId] = useState(3);
const AStoreList = JSON.parse(localStorage.getItem("getStore"));



  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  


  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };



// Facility Change
const handleFacilityChange = (event, newValue) => {
  let rowId = '';
  let Fname = '';
  if(newValue == null){
    rowId = '';
    Fname = '';
  }else{
    rowId = newValue.id;
    Fname = newValue.name;
  }

  if(rowId!==currFacilityId){
    setCurrFacilityId(rowId);
    setCurrFacilityName(Fname);

 // Store Change
 let params1={action:"gStoreListByFacility","menukey": menukey, "FacilityId":rowId,"TransactionTypeId":TransactionTypeId,"lan":lan};
 mutateStore(params1);

  // Facility List By User Change
  let params2={action:"gUserListByFacility","menukey": menukey, "FacilityId":rowId};
  mutategUserListByFacility(params2);


  setLoading(true);

  }

};

// End Facility Change


// Store Combo Change
const handleStoreChange = (event) => {

  if(currStoreId!==event.target.value){
    setcurrStoreId(event.target.value); 
    setLoading(true);
  }

  };

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);
  
   React.useEffect(() => {
    localStorage.setItem(
      "FacilityName",
      currFacilityName
    );
  
   }, [currFacilityName]);
  
  React.useEffect(() => {
   
    localStorage.setItem(
      "StoreId",
      currStoreId
    );
    
  }, [currStoreId]);
  
  // End Store


// Store

const {  mutate:mutateStore } = useMutation(api.gStoreListByFacility, {
onSuccess: (data) => { 
  if (data.status == 200) {  
    
    setcurrStoreId(data.data.datalist[0]['id']);

    localStorage.setItem(
      "getStore",
      JSON.stringify(data.data.datalist)
    );

  }
}
})




// Change User List By Facility

const {  mutate:mutategUserListByFacility } = useMutation(api.gUserListByFacility, {
onSuccess: (data) => { 
  if (data.status == 200) {  
    
    localStorage.setItem(
      "UsersListByFacility",
      JSON.stringify(data.data.datalist)
    );

  }
}
})

// End Change User List By Facility


  let params = {
    menukey: menukey,
    FacilityId: currFacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    TransactionTypeId: TransactionTypeId,
    lan:lan,
    StoreId:currStoreId,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getDispenseInvs,
    {
      onSuccess: (data) => {
      // console.log("ddddddddd");
        FacilityList.forEach((item) => {
          if(item.id==currFacilityId){
            localStorage.setItem(
              "FacilityName",
              item.name
            ); 
          }            
        });
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { mutate } = useMutation(api.deleteDispenseInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
      refetch();
   
      queryClient.getQueriesData("dispense");
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
        duration: 10000,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });  
           
        }
    },
  });



  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=getExportDispense" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&StoreId=" +
        currStoreId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editReceiveInv(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense Date"],
      width: 90,
      field: "TransactionDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
  
    {
      title: DispensingLanguage[lan][menukey]["Dispense Invoice#"],
      width: 210,
      field: "TransactionNo",
      headerFilter: true,
    },
    { 
      title: DispensingLanguage[lan][menukey]["Amount"], 
      field: "Amount",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right", 
     },
    {
      title: DispensingLanguage[lan][menukey]["Prepared By"],
      width: 200,
      field: "PreparedByName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Prepared Date"],
      width: 90,
      field: "SupplierInvDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Approved By"],
      field: "ApprovedByName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Status"],
      width: 85,
      headerHozAlign: "center",
      field: "bStockUpdated",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];


  const editReceiveInv = (data) => {
    let route = `/dispense/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };


  
  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";

    if (!rowData.bStockUpdated)
      return (
        <>
          <Edit
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
          <DeleteOutline
            onClick={() => {
              deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <ViewList
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
        </>
      );
  }


  useEffect(() => {
    refetch();
  }, [selectedEndDate, selectedEndDate]);

  return (
    <>
      <div className={classes.ReceivePageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Dispense"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
            <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]
                    }
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                       label=  
                      {DispensingLanguage[lan][menukey]["To"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole==1?false:true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>


              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Store"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="AStoreId"
                    name="AStoreId"
                    value={currStoreId}
                    disabled={true}
                   // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                  >
                    
                    {AStoreList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid> 

              
              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                  
                  <Button
                   disabled={FacilityId==0?true:false}
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/dispense/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add dispense"]}
                  </Button>
                  
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>


                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
              
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              height="450px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dispense;

const useStyles = makeStyles({
  ReceivePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
