
import Highcharts from "highcharts/highmaps";

export const chartOptions = ({ mapGeoJson, data }) => {
  const seriesData = parseData(mapGeoJson);

  window.Highcharts = Highcharts;

  return {
    chart: {
      // map: mapGeoJson,
      borderWidth: 0,
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true,
      title: {
          text: '',
          style: {
              color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
          }
      },
      layout: 'vertical',
      verticalAlign: 'bottom',
      align: 'right',
      floating: true,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255, 255, 255, 0.85)',
      symbolRadius: 0,
      borderWidth: 1,
      backgroundColor: '#FFFFFF',
      shadow: true,
      labelFormatter: function () {
        return '';
        // return ' <span style="font-family: \'Advent Pro\', sans-serif; font-size:10px">' + this.name + '</span>';
      }
    },
    colorAxis: {
      dataClasses: false,
      min: 0,
      max: data.maxVal,
      // max: 100,
      minColor: '#0000ff',
      maxColor: '#ff0000',
      stops: [
            [0, '#fef0d9'],
            [.25, '#fdcc8a'],
            [.5, '#fc8d59'],
            [.75, '#e34a33'],
            [100, '#b30000']
        ]
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
          verticalAlign: 'left'
      }
    },

    series: [{
      data: data.mapdata,
      mapData: mapGeoJson,
      // joinBy: ['name', 'name'],
      joinBy: [data.mapkey, 'name'],
      // name: 'v ',
      allowPointSelect: false,
      cursor: 'pointer',
      enableMouseTracking: true,
      events: {
          click: function (e) {
              e.point.zoomTo();
          }
      },
      states: {
          hover: {
              color: '#b30100',
              enabled: true,
              borderWidth: .2,
              borderColor: '#feefd6'
          }
      },
      dataLabels: {
          enabled: true,
          style: {
              fontSize: '11px',
              fontWeight: 300
          },
          formatter: function () {
              // return this.point.name;
                  return eval("this.point."+data.mapkey);
                }
            }
        }],
      tooltip: {
        useHTML: true,
        // headerFormat: '<large><b>{point.key}</b></large> ',
        headerFormat: '',
        pointFormat: '{point.toltip}'
      }

    /*tooltip: {
      borderColor: '#2c3e50',
      shared: true
    },
    series: [
      {
        data: data,
        keys: ["code_hasc", "value"],
        joinBy: "code_hasc",
        name:  "  Area name",
        states: {
          hover: {
            color: "#a4edba"
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.properties.code_hasc}"
        } 
      }
    ]*/




  };
};

function parseData(data) {
  return data;
}
