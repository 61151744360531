import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import DemanderFormData from "./DemanderFormData.js";

const AddDemander = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "demander";

  const [formData, setFormData] = useState({
    DemanderId: "",
    DemanderName: "",
    bDemander: false,
    bManufacturer: false,
    DemanderUserId: "",
    CountryCode:"",
    City:"",
    Zip:"",
    ContactName:"",
    Contact:"",
    Email:"",
    URL:"",
    AddressLine1:"",
    AddressLine2:"",
    // ManufacturerUserId: "",

  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["demander", id],
    () =>
      api.getDemanderData(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveDemander, {
    onSuccess: (data) => {
      if (data.status == 200) {
         api.getAllDropdown("Demanderlist").then((response) => {
           localStorage.setItem(
             "Demanderlist",
             JSON.stringify(response.datalist.Demanderlist)
           );
         });

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
        queryClient.getQueriesData("demander");
        props.history.push("/demander");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
      
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      DemanderId: "",
      DemanderName: "",
      bDemander: false,
      bManufacturer: false,
      DemanderUserId: "",
      CountryCode:"",
      City:"",
      Zip:"",
      ContactName:"",
      Contact:"",
      Email:"",
      URL:"",
      AddressLine1:"",
      AddressLine2:"",
      // ManufacturerUserId: "",
  
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["DemanderId", "DemanderName","CountryCode"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      mutate(formData);
      
    }
  };

  return (
    <>
      <DemanderFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddDemander;
