import React, { Suspense, useState, useLayoutEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "assets/css/custom.css";

// pages for this kit
import Index from "views/Index.js";
import DetailsPost from "views/index-sections/DetailsPost.js";
import FAQPage from "views/screens/FAQPage.js";
import AboutPage from "views/screens/AboutPage.js";
import ContactPage from "views/screens/ContactPage.js";
import LoginPage from "views/screens/LoginPage.js";
import SignUpPage from "views/screens/SignUp.js";
import ResetPasswordRequest from "views/screens/ResetPasswordRequest";
import ResetPassword from "views/screens/ResetPasswordPage";
import DashboardPage from "views/screens/DashboardPage";
import Products from "views/screens/products/index.js";
import Uom from "views/screens/uom/index.js";
import Role from "views/screens/roleentryfrom/index.js";
import Roleaccessentry from "views/screens/roleaccessentry/index.js";
import PackSize from "views/screens/pack_size/index.js";
import DHIS2accesssettings from "views/screens/dhis2accesssettings/index.js";
import Form from "views/screens/form/index.js";
import Strength from "views/screens/strength/index.js";
import UiLanguage from "views/screens/ui_language/index.js";
import Facility from "views/screens/facility/index.js";
import UserEntry from "views/screens/user_entry/index.js";
import Dci from "views/screens/dci/index.js";
import FacilityList from "views/screens/facilities-list/index.js";
import Commercial from "views/screens/commercial/index.js";
import Demander from "views/screens/demander/index.js";
import ProductsList from "views/screens/products_list/index.js";
import ProductsListBrand from "views/screens/products_list_brands/index.js";
import ProductsListBrandEntry from "views/screens/brands-list-entry/index.js";
import MenuEntry from "views/screens/menuentry/index.js";
import Department from "views/screens/Department/index.js";
import Servicearea from "views/screens/servicearea/index.js";
import Ownertype from "views/screens/Ownertype/index.js";
import FacilityType from "views/screens/FacilityType/index.js";
import ZS from "views/screens/zs/index.js";
import SVDLProductEntry from "views/screens/svdlproductEntry/index.js";
import Commune from "views/screens/commune/index.js";
import DHIS2DataTransferSettings from "views/screens/Shis2DataTranstersetting/index.js";
import MyProfile from "views/screens/myprofile/index.js";
import Auditlog from "views/screens/audit-log/index.js";
import Errorlog from "views/screens/error-log/index.js";
import ProductDashboard from "views/screens/ProductDashboard";
import FacilityDashboard from "views/screens/FacilityDashboard";
import ContextDashboard from "views/screens/ContextDashboard";
import FacilitySyncStatus from "views/screens/FacilitySyncStatus";
import CentralDashboard from "views/screens/CentralDashboard";
import FakeMedicineDashboard from "views/screens/FakeMedicineDashboard";
import Manufacturer from "views/screens/manufacturer/index.js";
import BrandLicenseExpiryDate from "views/screens/BrandLicenseExpiryDate";
import FacilityClassification from "views/screens/FacilityClassification";
import FacilitiesByType from "views/screens/FacilitiesByType";
import ProductSerialTrackingReport from "views/screens/ProductSerialTrackingReportNew";
import FacilityByClassification from "views/screens/FacilityByClassification";
import FacilityOwnerType from "views/screens/FacilityOwnerType";
import UserContextProvider from "./context/user-info-context";
//import Receipts from "views/screens/receive/index.js";
import Dispense from "views/screens/dispense/index.js";
import Issueinvoice from "views/screens/issueinvoice/index.js";
import Receipts from "views/screens/receipts/index.js";
import Adjustment from "views/screens/adjustment/index.js";
import Stocktake from "views/screens/stock_take/index.js";
import Order from "views/screens/order/index.js";
import StockStatus from "views/screens/stock_status/index.js";
import OrderApproval from "views/screens/order_approval/index.js";
import Supplier from "views/screens/supplier/index.js";
import Recipients from "views/screens/recipients/index.js";

import ScanLog from "views/screens/scanlog/index.js";

import InitialStockConfiguration from "views/screens/initial-stock-configuration/index.js";
import FacilityLevel from "views/screens/facility-label/index.js";
import ImportASNDESADV from "views/screens/import_asn_desadv/index.js";
import SOBAPSReceipts from "views/screens/sobaps_receipts/index.js";
import ProductGroup from "views/screens/product_group/index.js";
import ProductSubgroup from "views/screens/product_subgroup/index.js";

import SettingsEntry from "views/screens/settings_entry/index.js";
import ShipmentStatus from "views/screens/shipment_status/index.js";
import FundingSource from "views/screens/funding_source/index.js";
import RecipientGroup from "views/screens/recipient_group/index.js";
import Dhis2datatranserlogd from "views/screens/Dhis2datatranserlogd";
import SVDLReportsPage from "views/screens/svdl_reports/index.js";
import ESIGLReportsPage from "views/screens/esigl_reports/index.js";
import MyDashboards from "views/screens/my_dashboards/index.js";
import MyDatasources from "views/screens/my_datasources/index.js";
import SVDLDashboardView from "views/screens/my_dashboards_view/index.js";
import ESIGLDashboardView from "views/screens/esigl_dashboard_view/index.js";
import MyCustomQuery from "views/screens/my_custom_query/index.js";
import MyReports from "views/screens/my_reports/index.js";
import FieldDispense from "views/screens/field-dispense/index.js";
import A7Report from "views/screens/a7-report/index.js";
import AdjustmentType from "views/screens/adjustment_type/index.js";
import StoreEntry from "views/screens/store_entry/index.js";
import CountryEntry from "views/screens/CountryEntry/index.js";
import ShipmentEntry from "views/screens/ShipmentEntry/index.js";
import OrderType from "views/screens/order_type/index.js";
import YearEntry from "views/screens/year_entry/index.js";
import Owner from "views/screens/Owner/index.js";
import MosType from "views/screens/mos_range/index.js";
import MosTypeforFacility from "views/screens/mos_type_for_facility/index.js";
import RCProductEntry from "views/screens/rc_product_entry/index.js";
import ESIGLUploadLog from "views/screens/esigl_upload_log/index.js";
import ProductsGroupView from "views/screens/product_group_View/index.js";
import UserList from "views/screens/user_list/index.js";
import ProductView from "views/screens/product_view/index.js";
import ProductClassification from "views/screens/product_classification/index.js";
import ScanBarcode from "views/screens/scan_barcode/index.js";
//import SNIGSLogisticsReportA7 from "views/screens/SNIGSLogisticsReportA7";
import Stocktakedetailsreports from "views/screens/esigl_reports/Stocktakedetailsreports.js";
import PercentageofFacilitiesStockedout from "views/screens/esigl_reports/PercentageofFacilitiesStockedout.js";

import PercentageoffacilitybyMOSscale from "views/screens/esigl_reports/PercentageoffacilitybyMOSscale.js";
import PercentageoffacilitybyMOSscaleSVDL from "views/screens/svdl_reports/PercentageoffacilitybyMOSscaleSVDL.js";

import NationalStockStatusReportSVDL from "views/screens/svdl_reports/NationalStockStatusReportSVDL.js";
import FacilityStockStatusbyProduct from "views/screens/svdl_reports/FacilityStockStatusbyProduct.js";
import PercentageofFacilitieswithStockoutofKeyItems from "views/screens/svdl_reports/PercentageofFacilitieswithStockoutofKeyItems.js";
import StockoutTrendofKeyItems from "views/screens/svdl_reports/StockoutTrendofKeyItems.js";

import PercentageOfFacilitiesStockOutSVDL from "views/screens/svdl_reports/PercentageOfFacilitiesStockOutSVDL.js";
import Completeness from "views/screens/svdl_reports/Completeness.js";
import TimelinessReport from "views/screens/svdl_reports/TimelinessReport.js";
import StockDetailsReport from "views/screens/svdl_reports/StockDetailsReport.js";
import PercentageStructuresHavingReceivedFeedback from "views/screens/svdl_reports/PercentageStructuresHavingReceivedFeedback.js";
import StockStatusatDifferentLevelSVDL from "views/screens/svdl_reports/StockStatusatDifferentLevelSVDL.js";
import StockDashboard from "views/screens/svdl_reports/StockDashboard.js";

import CompletenessEqualHundredPercent from "views/screens/svdl_reports/CompletenessEqualHundredPercent.js";
import CompletenessLessThanHundredPercent from "views/screens/svdl_reports/CompletenessLessThanHundredPercent.js";




import Error404 from "views/screens/Error404.js";
import StockStatusReport from "views/screens/esigl_reports/StockStatusReport.js";
import StockinTransit from "views/screens/StockinTransit";

import ProductStockReport from "views/screens/esigl_reports/ProductStockReport.js";
import StockCardReport from "views/screens/esigl_reports/StockCardReport.js";
import ProductLotStockReport from "views/screens/esigl_reports/ProductLotStockReport.js";
import StockStatusatDifferentLevel from "views/screens/esigl_reports/StockStatusatDifferentLevel.js";

import ReceiveSummaryReport from "views/screens/esigl_reports/ReceiveSummaryReport.js";
import DispenseFromRC from "views/screens/esigl_reports/DispenseFromRC.js";
import A7ReportView from "views/screens/esigl_reports/A7ReportView.js";
import HealthFacilities from "views/screens/esigl_reports/HealthFacilities.js";
import FacilityDetailsReport from "views/screens/esigl_reports/FacilityDetailsReport.js";
import IssueSummarybyPaymentStatus from "views/screens/esigl_reports/IssueSummarybyPaymentStatus.js";
import PercentageofFacilityStockoutbyProducts from "views/screens/esigl_reports/PercentageofFacilityStockoutbyProducts.js";
import ReportCurrentvalueofstock from "views/screens/esigl_reports/ReportCurrentvalueofstock.js";
import NationalStockStatusReport from "views/screens/esigl_reports/NationalStockStatusReport.js";
import OrderReport from "views/screens/esigl_reports/OrderReport.js";
import LotExpirein_3MonthsReport from "views/screens/esigl_reports/LotExpirein_3MonthsReport.js";


import ProductExpiredReport from "views/screens/esigl_reports/ProductExpiredReport.js";
import Receivedetailsreport from "views/screens/esigl_reports/Receivedetailsreport.js";
import IssueSummaryReport from "views/screens/esigl_reports/IssueSummaryReport.js";
import AdjustmentSummaryReport from "views/screens/esigl_reports/AdjustmentSummaryReport.js";
import IssueDetailsReport from "views/screens/esigl_reports/IssueDetailsReport.js";
import MasterProductList from "views/screens/esigl_reports/MasterProductList.js";
import ProductGroups from "views/screens/esigl_reports/ProductGroups.js";
import DispenseFromDispensary from "views/screens/esigl_reports/DispenseFromDispensary.js";
import TotalTurnoverReport from "views/screens/esigl_reports/TotalTurnoverReport.js";
import TimelinessDetailsReport from "views/screens/esigl_reports/TimelinessDetailsReport.js";
//SVDL Report input link
import ProdcutgoingtoExpirein_3Month from "views/screens/svdl_reports/ProdcutgoingtoExpirein_3Month.js";
import PercentageofProductStockbyFacility from "views/screens/svdl_reports/PercentageofProductStockbyFacility.js";
import StockoutReportbyProduct from "views/screens/svdl_reports/StockoutReportbyProduct.js";
import ExpiryRates from "views/screens/svdl_reports/ExpiryRates.js";
import FacilityStockonHandValuation from "views/screens/svdl_reports/FacilityStockonHandValuation.js";
import ConsumptionTrend from "views/screens/svdl_reports/ConsumptionTrend.js";
// import AmcTrend from "views/screens/svdl_reports/AmcTrend.js";
import ConsumptionTrendbyFacility from "views/screens/svdl_reports/ConsumptionTrendbyFacility.js";
// import AMCTrendbyFacility from "views/screens/svdl_reports/AMCTrendbyFacility.js";
import SVDLFeedback from "views/screens/svdl_reports/SVDLFeedback.js";
import SvdlDashboard from "views/screens/svdl_reports/SvdlDashboard.js";
import SvdlFacilityReportingStatus from "views/screens/svdl_reports/SvdlFacilityReportingStatus.js";
import SVDLStockDashboardAnalyse from "views/screens/svdl_reports/SVDLStockDashboardAnalyse.js";
 

import ShipmentReport from "views/screens/svdl_reports/ShipmentReport.js";
import DemanderManufacturerDashboard from "views/screens/DemanderManufacturerDashboard";
import SNIGSLogisticsA7Entry from "views/screens/snigs-logistics-report-a7/index.js";
import AdjustmentDetailsReport from "views/screens/esigl_reports/AdjustmentDetailsReport.js";
import CAMEDataImport from "views/screens/came_data_import/index.js";
import GetDataByAPI from "views/screens/get_data_by_api/index.js";

import CertificateManufacturerAuth from "views/screens/certificate_manufacturer_auth/index.js";
import BrandDataImport from "views/screens/brand_data_import/index.js";

import { QueryClient, QueryClientProvider } from "react-query";

import * as Service from "./services/Service.js";

import LoadingSpinner from "./services/LoadingSpinner";



const queryClient = new QueryClient();

let userInfo = null;

userInfo = {
  FacilityId: 0,
  FacilityName: "CHUZ-SURU-LERE",
  LangId: "fr_FR",
};

const MyApp = () => {
  const [Dispensing_Language, setDispensing_Language] = useState(false);

  const [DispensingLanguage, setDispensingLanguage] = useState({});

  useLayoutEffect(() => {
    console.log("useLayoutEffect: ");
    if (!Dispensing_Language) {
      Service.default
        .postApi("source/combo_generic.php", {
          action: "getDispensingLanguageList",
        })
        .then((res) => {
          if (res.success == 1) {
            let DispensingLanguageData = res.datalist;

            localStorage.setItem(
              "DispensingLanguage",
              JSON.stringify(DispensingLanguageData)
            );

            setDispensingLanguage(
              JSON.parse(localStorage.getItem("DispensingLanguage"))
            );

            setDispensing_Language(true);
          }
        })
        .catch((err) => { });
    }
  }, []);

  return (
    <>
      {!Dispensing_Language && <LoadingSpinner />}
      {Dispensing_Language && Object.keys(DispensingLanguage).length !== 0 && (
        <UserContextProvider userInfo={userInfo}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
              <Suspense>
                <Switch>
                  <Route
                    path="/home"
                    render={(props) => <Index {...props} />}
                  />
                  <Route
                    path="/details-post/:id"
                    render={(props) => <DetailsPost {...props} />}
                  />
                  <Route
                    path="/faq"
                    render={(props) => <FAQPage {...props} />}
                  />
                  <Route
                    path="/about"
                    render={(props) => <AboutPage {...props} />}
                  />
                  <Route
                    path="/contact"
                    render={(props) => <ContactPage {...props} />}
                  />
                  <Route
                    path="/login"
                    render={(props) => <LoginPage {...props} />}
                  />
                  <Route
                    path="/signup"
                    render={(props) => <SignUpPage {...props} />}
                  />
                  <Route
                    path="/reset-password-request"
                    render={(props) => <ResetPasswordRequest {...props} />}
                  />
                  <Route
                    path="/reset-password"
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route
                    path="/dashboard"
                    render={(props) => <DashboardPage {...props} />}
                  />
                  <Route
                    path="/generics"
                    render={(props) => <Products {...props} />}
                  />
                  <Route
                    path="/unit-of-measure-entry-form"
                    render={(props) => <Uom {...props} />}
                  />
                  <Route
                    path="/shipment-entry"
                    render={(props) => <ShipmentEntry {...props} />}
                  />
                  <Route
                    path="/role-entry"
                    render={(props) => <Role {...props} />}
                  />
                  <Route
                    path="/role-access-entry"
                    render={(props) => <Roleaccessentry {...props} />}
                  />
                  <Route
                    path="/pack-size"
                    render={(props) => <PackSize {...props} />}
                  />
                  <Route
                    path="/dhis2-access-settings"
                    render={(props) => <DHIS2accesssettings {...props} />}
                  />
                  <Route path="/form" render={(props) => <Form {...props} />} />
                  <Route
                    path="/strength"
                    render={(props) => <Strength {...props} />}
                  />
                  <Route
                    path="/ui-language"
                    render={(props) => <UiLanguage {...props} />}
                  />
                  <Route
                    path="/facility"
                    render={(props) => <Facility {...props} />}
                  />
                  <Route
                    path="/user-entry"
                    render={(props) => <UserEntry {...props} />}
                  />
                  <Route path="/dci" render={(props) => <Dci {...props} />} />
                  <Route
                    path="/facilities-list"
                    render={(props) => <FacilityList {...props} />}
                  />
                  <Route
                    path="/commercial"
                    render={(props) => <Commercial {...props} />}
                  />
                  <Route
                    path="/demander"
                    render={(props) => <Demander {...props} />}
                  />
                  <Route
                    path="/generics-list"
                    render={(props) => <ProductsList {...props} />}
                  />
                  <Route
                    path="/brands-list"
                    render={(props) => <ProductsListBrand {...props} />}
                  />
                   <Route
                    path="/brands-list-entry"
                    render={(props) => <ProductsListBrandEntry {...props} />}
                  />
                  <Route
                    path="/menu-entry"
                    render={(props) => <MenuEntry {...props} />}
                  />
                  <Route
                    path="/department-entry"
                    render={(props) => <Department {...props} />}
                  />
                  <Route
                    path="/service-area"
                    render={(props) => <Servicearea {...props} />}
                  />
                  <Route
                    path="/ownertype-entry"
                    render={(props) => <Ownertype {...props} />}
                  />
                  <Route
                    path="/facilitytype-entry"
                    render={(props) => <FacilityType {...props} />}
                  />
                  <Route path="/zs" render={(props) => <ZS {...props} />} />
                  <Route
                    path="/svdl-product-entry"
                    render={(props) => <SVDLProductEntry {...props} />}
                  />
                  <Route
                    path="/commune"
                    render={(props) => <Commune {...props} />}
                  />
                  <Route
                    path="/shis2-data-transter-setting"
                    render={(props) => <DHIS2DataTransferSettings {...props} />}
                  />
                  <Route
                    path="/my-profile"
                    render={(props) => <MyProfile {...props} />}
                  />
                  <Route
                    path="/audit-log"
                    render={(props) => <Auditlog {...props} />}
                  />
                  <Route
                    path="/error-log"
                    render={(props) => <Errorlog {...props} />}
                  />
                  <Route
                    path="/product-dashboard"
                    render={(props) => <ProductDashboard {...props} />}
                  />
                  <Route
                    path="/facility-dashboard"
                    render={(props) => <FacilityDashboard {...props} />}
                  />
                  <Route
                    path="/context-dashboard"
                    render={(props) => <ContextDashboard {...props} />}
                  />
                  <Route
                    path="/facility-sync-status"
                    render={(props) => <FacilitySyncStatus {...props} />}
                  />
                  <Route
                    path="/central-dashboard"
                    render={(props) => <CentralDashboard {...props} />}
                  />
                  <Route
                    path="/fake-medicine-dashboard"
                    render={(props) => <FakeMedicineDashboard {...props} />}
                  />
                  <Route
                    path="/manufacturer-entry"
                    render={(props) => <Manufacturer {...props} />}
                  />
                  <Route
                    path="/brand-license-expiry-date"
                    render={(props) => <BrandLicenseExpiryDate {...props} />}
                  />
                  <Route
                    path="/facility-classification"
                    render={(props) => <FacilityClassification {...props} />}
                  />
                  <Route
                    path="/facilities-by-type"
                    render={(props) => <FacilitiesByType {...props} />}
                  />
                  <Route
                    path="/product-serial-tracking-report"
                    render={(props) => <ProductSerialTrackingReport {...props} />}
                  />
                  <Route
                    path="/facilities-by-classification"
                    render={(props) => <FacilityByClassification {...props} />}
                  />
                  <Route
                    path="/facility-owner-type"
                    render={(props) => <FacilityOwnerType {...props} />}
                  />
                  {/* <Route path="/receipts" render={(props) => <Receipts {...props} />} /> */}
                  <Route
                    path="/dispense"
                    render={(props) => <Dispense {...props} />}
                  />
                  <Route
                    path="/dhis2-data-transter-log"
                    render={(props) => <Dhis2datatranserlogd {...props} />}
                  />
                  <Route
                    path="/issue-invoice"
                    render={(props) => <Issueinvoice {...props} />}
                  />
                  <Route
                    path="/receipts"
                    render={(props) => <Receipts {...props} />}
                  />
                  <Route
                    path="/adjustment-invoice"
                    render={(props) => <Adjustment {...props} />}
                  />
                  <Route
                    path="/stock-take"
                    render={(props) => <Stocktake {...props} />}
                  />
                  <Route
                    path="/orders"
                    render={(props) => <Order {...props} />}
                  />
                  <Route
                    path="/stock-status"
                    render={(props) => <StockStatus {...props} />}
                  />
                  <Route
                    path="/order-approval"
                    render={(props) => <OrderApproval {...props} />}
                  />

                  <Route
                    path="/initial-stock-configuration"
                    render={(props) => <InitialStockConfiguration {...props} />}
                  />
                  <Route
                    path="/supplier"
                    render={(props) => <Supplier {...props} />}
                  />
                  <Route
                    path="/recipients"
                    render={(props) => <Recipients {...props} />}
                  />
                  <Route
                    path="/facility-label"
                    render={(props) => <FacilityLevel {...props} />}
                  />
                  <Route
                    path="/import-asn-desadv"
                    render={(props) => <ImportASNDESADV {...props} />}
                  />
                  <Route
                    path="/sobaps-receipts"
                    render={(props) => <SOBAPSReceipts {...props} />}
                  />
                  <Route
                    path="/product_group"
                    render={(props) => <ProductGroup {...props} />}
                  />
                  <Route
                    path="/product_subgroup"
                    render={(props) => <ProductSubgroup {...props} />}
                  />
                  
                  <Route
                    path="/settings_entry"
                    render={(props) => <SettingsEntry {...props} />}
                  />
                  <Route
                    path="/shipment_status"
                    render={(props) => <ShipmentStatus {...props} />}
                  />
                  <Route
                    path="/funding_source"
                    render={(props) => <FundingSource {...props} />}
                  />
                  <Route
                    path="/recipient_group"
                    render={(props) => <RecipientGroup {...props} />}
                  />

                  <Route
                    path="/svdl-reports"
                    render={(props) => <SVDLReportsPage {...props} />}
                  />
                  <Route
                    path="/esigl-reports"
                    render={(props) => <ESIGLReportsPage {...props} />}
                  />
                  <Route
                    path="/my-dashboards"
                    render={(props) => <MyDashboards {...props} />}
                  />
                  <Route
                    path="/my-datasources"
                    render={(props) => <MyDatasources {...props} />}
                  />
                  <Route
                    path="/dashboard-view"
                    render={(props) => <SVDLDashboardView {...props} />}
                  />
                  <Route
                    path="/esigl-dashboard-view"
                    render={(props) => <ESIGLDashboardView {...props} />}
                  />
                  <Route
                    path="/my-custom-query"
                    render={(props) => <MyCustomQuery {...props} />}
                  />
                  <Route
                    path="/my-reports"
                    render={(props) => <MyReports {...props} />}
                  />
                  <Route
                    path="/field-dispense"
                    render={(props) => <FieldDispense {...props} />}
                  />
                  <Route
                    path="/a7-report"
                    render={(props) => <A7Report {...props} />}
                  />
                  <Route
                    path="/adjustment_type"
                    render={(props) => <AdjustmentType {...props} />}
                  />
                  <Route
                    path="/store_entry"
                    render={(props) => <StoreEntry {...props} />}
                  />
                  <Route
                    path="/country-entry"
                    render={(props) => <CountryEntry {...props} />}
                  />

                  <Route
                    path="/order_type"
                    render={(props) => <OrderType {...props} />}
                  />
                  <Route
                    path="/year_entry"
                    render={(props) => <YearEntry {...props} />}
                  />
                  <Route
                    path="/owner-entry"
                    render={(props) => <Owner {...props} />}
                  />
                  <Route
                    path="/mos_range"
                    render={(props) => <MosType {...props} />}
                  />
                  <Route
                    path="/mos_type_for_facility"
                    render={(props) => <MosTypeforFacility {...props} />}
                  />
                  <Route
                    path="/rc_product_entry"
                    render={(props) => <RCProductEntry {...props} />}
                  />
                  <Route
                    path="/esigl_upload_log"
                    render={(props) => <ESIGLUploadLog {...props} />}
                  />

                  <Route
                    path="/scan-barcode"
                    render={(props) => <ScanBarcode {...props} />}
                  />

                  <Route
                    path="/product_group_View"
                    render={(props) => <ProductsGroupView {...props} />}
                  />
                  <Route
                    path="/user_list"
                    render={(props) => <UserList {...props} />}
                  />
                  <Route
                    path="/product_classification"
                    render={(props) => <ProductClassification {...props} />}

                  />
                  <Route
                    path="/product_view"
                    render={(props) => <ProductView {...props} />}

                  />

                  <Route
                    path="/demander-manufacturer-dashboard"
                    render={(props) => <DemanderManufacturerDashboard {...props} />}

                  />

                  {/* <Route
                    path="/snigs_logistics_report_a7"
                    render={(props) => <SNIGSLogisticsReportA7 {...props} />}

                  /> */}

                  <Route
                    path="/snigs_logistics_report_a7"
                    render={(props) => <SNIGSLogisticsA7Entry {...props} />}

                  />

                  <Route
                    path="/came-data-import"
                    render={(props) => <CAMEDataImport {...props} />}
                  />
                  <Route
                    path="/get-data-by-api"
                    render={(props) => <GetDataByAPI {...props} />}
                  />

                  <Route
                    path="/scan-log"
                    render={(props) => <ScanLog {...props} />}
                  />

                  <Route
                    path="/stock-status-report"
                    render={(props) => <StockStatusReport {...props} />}
                  />
                  <Route
                    path="/product-stock-report"
                    render={(props) => <ProductStockReport {...props} />}
                  />
                  <Route
                    path="/stock-card-report"
                    render={(props) => <StockCardReport {...props} />}
                  />
                  <Route
                    path="/product-lot-stock-report"
                    render={(props) => <ProductLotStockReport {...props} />}
                  />

                  <Route
                    path="/receive-summary-report"
                    render={(props) => <ReceiveSummaryReport {...props} />}
                  />
                  <Route
                    path="/dispense-from-rc"
                    render={(props) => <DispenseFromRC {...props} />}
                  />
                  <Route
                    path="/a7-report-view"
                    render={(props) => <A7ReportView {...props} />}
                  />
                  <Route
                    path="/health-facilities"
                    render={(props) => <HealthFacilities {...props} />}
                  />
                  <Route
                    path="/facility-details-report"
                    render={(props) => <FacilityDetailsReport {...props} />}
                  />
                  <Route
                    path="/issue-summary-by-paymentStatus"
                    render={(props) => <IssueSummarybyPaymentStatus {...props} />}
                  />
                  <Route
                    path="/report-Current-value-of-stock"
                    render={(props) => <ReportCurrentvalueofstock {...props} />}
                  />
                  <Route
                    path="/national-stock-status-report"
                    render={(props) => <NationalStockStatusReport {...props} />}
                  />
                  <Route
                    path="/product-expired-report"
                    render={(props) => <ProductExpiredReport {...props} />}
                  />
                  <Route
                    path="/order-report"
                    render={(props) => <OrderReport {...props} />}
                  />
                  <Route
                    path="/lot-expire-in-months-report"
                    render={(props) => <LotExpirein_3MonthsReport {...props} />}
                  />
                  <Route
                    path="/receive-details-report"
                    render={(props) => <Receivedetailsreport {...props} />}
                  />
                  <Route
                    path="/adjustment-details-report"
                    render={(props) => <AdjustmentDetailsReport {...props} />}
                  />
                  <Route
                    path="/issue-summary-report"
                    render={(props) => <IssueSummaryReport {...props} />}
                  />
                  <Route
                    path="/adjustment-summary-report"
                    render={(props) => <AdjustmentSummaryReport {...props} />}
                  />
                  <Route
                    path="/issue-details-repor"
                    render={(props) => <IssueDetailsReport {...props} />}
                  />
                  <Route
                    path="/master-product-list"
                    render={(props) => <MasterProductList {...props} />}
                  />
                  <Route
                    path="/product-groups"
                    render={(props) => <ProductGroups {...props} />}
                  />


                  <Route
                    path="/dispense-from-dispensary"
                    render={(props) => <DispenseFromDispensary {...props} />}
                  />
                  <Route
                    path="/total-turnover-report"
                    render={(props) => <TotalTurnoverReport {...props} />}
                  />
                  <Route
                    path="/timeliness-details-report"
                    render={(props) => <TimelinessDetailsReport {...props} />}
                  />
                  <Route

                    path="/stock-take-details-reports"
                    render={(props) => <Stocktakedetailsreports {...props} />}
                  />
                  {/* SVDL Report router */}
                  <Route
                    path="/products-going-to-Expire-in-3Months"
                    render={(props) => <ProdcutgoingtoExpirein_3Month {...props} />}
                  />
                  <Route
                    path="/percentage-of-product-stock-by-facility"
                    render={(props) => <PercentageofProductStockbyFacility {...props} />}
                  />
                  <Route
                    path="/stockout-report-by-product"
                    render={(props) => <StockoutReportbyProduct {...props} />}
                  />
                  <Route
                    path="/expiry-rates"
                    render={(props) => <ExpiryRates {...props} />}
                  />
                  <Route
                    path="/facility-stock-on-hand-valuation"
                    render={(props) => <FacilityStockonHandValuation {...props} />}
                  />
                  <Route
                    path="/consumption-trend"
                    render={(props) => <ConsumptionTrend {...props} />}
                  />
                  {/* <Route
                    path="/amc-trend"
                    render={(props) => <AmcTrend {...props} />}
                  /> */}
                  <Route
                    path="/consumption-trend-by-facility"
                    render={(props) => <ConsumptionTrendbyFacility {...props} />}
                  />
                  {/* <Route
                    path="/amc-trend-by-facility"
                    render={(props) => <AMCTrendbyFacility {...props} />}
                  /> */}
                  <Route
                    path="/svdl-feedback"
                    render={(props) => <SVDLFeedback {...props} />}
                  />
                  <Route
                    path="/svdl-dashboard"
                    render={(props) => <SvdlDashboard {...props} />}
                  />
                  <Route
                    path="/svdl-facility-reporting-status"
                    render={(props) => <SvdlFacilityReportingStatus {...props} />}
                  />
                  <Route
                    path="/svdl-stock-dashboard-analyse"
                    render={(props) => <SVDLStockDashboardAnalyse {...props} />}
                  />
                  <Route
                    path="/shipment-report"
                    render={(props) => <ShipmentReport {...props} />}
                  />

                  <Route
                    path="/percentage-of-facilities-stockedout"
                    render={(props) => <PercentageofFacilitiesStockedout {...props} />}
                  />
                  <Route
                    path="/percentage-of-facilities-stockedout-SVDL"
                    render={(props) => <PercentageOfFacilitiesStockOutSVDL {...props} />}
                  />
                  <Route
                    path="/completeness"
                    render={(props) => <Completeness {...props} />}
                  />
                  <Route
                    path="/timeliness-report"
                    render={(props) => <TimelinessReport {...props} />}
                  />
                  <Route
                    path="/percentage-of-facility-by-MOS-scale"
                    render={(props) => <PercentageoffacilitybyMOSscale {...props} />}
                  />
                   <Route
                    path="/percentage-of-facility-by-MOS-scale-SVDL"
                    render={(props) => <PercentageoffacilitybyMOSscaleSVDL {...props} />}
                  />
                  
                  <Route
                    path="/national-stock-status-report-SVDL"
                    render={(props) => <NationalStockStatusReportSVDL {...props} />}
                  />
                   <Route
                    path="/facility-stock-status-by-product"
                    render={(props) => <FacilityStockStatusbyProduct {...props} />}
                  />
                  
                   <Route
                    path="/Percentage-facilities-stockout-KeyItems"
                    render={(props) => <PercentageofFacilitieswithStockoutofKeyItems {...props} />}
                  />
                   <Route
                    path="/stockout-trend-of-keyitems"
                    render={(props) => <StockoutTrendofKeyItems {...props} />}
                  />
                  <Route
                    path="/stock-details-report"
                    render={(props) => <StockDetailsReport {...props} />}
                  />
                   <Route
                    path="/stockin-transit"
                    render={(props) => <StockinTransit {...props} />}
                  />
                  <Route
                    path="/percentage-structures-having-received-the-feedbac"
                    render={(props) => <PercentageStructuresHavingReceivedFeedback {...props} />}
                  />
                  <Route
                    path="/completeness-equal-hundred-percent"
                    render={(props) => <CompletenessEqualHundredPercent {...props} />}
                  />
                    <Route
                    path="/completeness-lessthan-hundred-percent"
                    render={(props) => <CompletenessLessThanHundredPercent {...props} />}
                  />
                  <Route
                    path="/stock-statusat-different-level-SVDL"
                    render={(props) => <StockStatusatDifferentLevelSVDL {...props} />}
                  />
                   <Route
                    path="/stock-dashboard"
                    render={(props) => <StockDashboard {...props} />}
                  />
                   <Route
                    path="/stock-statusat-different-level"
                    render={(props) => <StockStatusatDifferentLevel {...props} />}
                  />

                  <Route
                    path="/percentage-facility-Stockout-by-Products"
                    render={(props) => <PercentageofFacilityStockoutbyProducts {...props} />}
                  />
                  <Route
                    path="/certificate-manufacturer-authorization"
                    render={(props) => <CertificateManufacturerAuth {...props} />}
                  />
                  <Route
                    path="/brand-data-import"
                    render={(props) => <BrandDataImport {...props} />}
                  />

                  {/* <Route
                    path="*"
                    render={(props) => <Error404 {...props} />}
                  /> */}
                  <Route path="/" render={(props) => <Index {...props} />} />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </QueryClientProvider>
        </UserContextProvider>
      )}
    </>
  );
};

export default MyApp;
