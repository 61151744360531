import React, { useEffect } from "react";
//import Highcharts from "highcharts";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import { chartOptions } from './chartOptions';


//import drilldow from "highcharts/modules/drilldown";
//import dataModule from "highcharts/modules/data";

//drilldow(Highcharts);
//dataModule(Highcharts);

function ChartStates({ mapGeoJson, data }) {
  window.Highcharts = Highcharts;

  const options = chartOptions({
    mapGeoJson,
    data
  });
  
  return (
    <HighchartsReact
      options={options}
      highcharts={Highcharts}
      constructorType={"Map"}
      // constructorType={"mapChart"}
    />
  );
}

export default ChartStates;
