import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services

import swal from "sweetalert";


//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import { Grid,
   Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormControl,
    TextField,
    Typography

} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';


const OrderApproval = (props) => {
  
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order-approval";
  let FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0; 
const UserId = UserInfo==0?'': UserInfo[0].id;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;


  const [dataItems, setDataItems] = useState([]);

  const [AFacilityName, setFacilityName] = useState(FacilityName);
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

// Facility Auto
const FacilityList = JSON.parse(localStorage.getItem("getFacilityListFromApprover"));
if(FacilityList.length>0){ 
  FacilityId=FacilityList[0].id;
}

const [currFacilityId, setCurrFacilityId] = useState(FacilityId);


  // Facility
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname='';
    if(newValue == null){
      rowId = '';
      Fname='';
     
    }else{
      rowId = newValue.id;
      Fname=newValue.name;
    }
    if(rowId!==currFacilityId){
  
     setCurrFacilityId(rowId);
     setFacilityName(Fname);
     let params2={action:"gUserListByFacility","menukey": menukey, "FacilityId":rowId};
     mutategUserListByFacility(params2);
     


     setLoading(true);
    }
  
 };

 React.useEffect(() => {
  localStorage.setItem(
    "FacilityId",
    currFacilityId
  );

 }, [currFacilityId]);

// Change User List By Facility

const {  mutate:mutategUserListByFacility} = useMutation(api.gUserListByFacility, {
  onSuccess: (data) => { 
    if (data.status == 200) {  
      
      localStorage.setItem(
        "UsersListByFacility",
        JSON.stringify(data.data.datalist)
      );

    }
  },
 
})

// End Change User List By Facility


  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);
  const [checked, setChecked] = React.useState(true);

  const [CompletedOrders, setCompletedOrders] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: currFacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    CompletedOrders:CompletedOrders,
    lan:lan,
    UserId:UserId
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getOrderInvApproval,
    {
      onSuccess: (data) => {
        
        FacilityList.forEach((item) => {
          if(item.id==currFacilityId){
            localStorage.setItem(
              "FacilityName",
              item.name
            ); 
          }            
         });
         
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );

  const { mutate } = useMutation(api.deleteOrderInv, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
      refetch();
      queryClient.getQueriesData("order-approval");
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
        duration: 10000,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });  
           
        }
    },
  });


  

  const handleApproveChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setCompletedOrders(event.target.checked);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=getExportOrdersBookApproval" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        currFacilityId +
        "&FacilityName=" +
        AFacilityName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate + "&CompletedOrders="+ CompletedOrders + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editOrderInvs(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: t(DispensingLanguage[lan][menukey]["Sl#"]),
      hozAlign: "center",
      width: 55,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Date"]),
      width: 110,
      field: "OrderDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order#"]),
      field: "OrderNo",
      width: 230,
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Type"]),
      field: "OrderType",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Amount"]),
      field: "OrderAmount",
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order To"]),
      field: "OrderingFromName",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Entry By"]),
      field: "OrderByName",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Approved By"]),
      field: "ApprovedByName",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Status"]),
      field: "bCompleted",
      hozAlign: "center",
      width: 90,
      formatter: "tickCross",
      headerHozAlign: "center",
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Remarks"]),
      field: "Remarks",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Actions"]),
      field: "custom",
      headerHozAlign: "center",
      headerSort: false,
      hozAlign: "center",
      width: 65,
      formatter: reactFormatter(<ActionButton />),
    },
  ];


  

  const editOrderInvs = (data) => {
    let route = `order-approval/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteOrderInvs = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this order!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bCompleted == 1)
      return (
        <>
          <ViewList
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <Edit
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />

          {/* <DeleteOutline
            onClick={() => {
              deleteOrderInvs(rowData);
            }}
          /> */}
        </>
      );
  }

  return (
    <>
 
      <div className={classes.OrderPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Order Approval"])}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["From"])}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["To"])}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                       defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserRole !=1))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      // disabled={UserRole==1?false:true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>

              <Grid item xs={2} sm={2}>
              <FormControlLabel className="OrderApprovalCheck"
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    checked={checked} 
                    onChange={handleApproveChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["To be approved"]}
              />
              </Grid>




              <Grid item xs={4} sm={4}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/order/add");
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add Order"])}
                  </Button> */}

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default OrderApproval;

const useStyles = makeStyles({
  OrderPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
