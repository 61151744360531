import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { 
  Grid ,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,

} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from '@material-ui/lab/Autocomplete';

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
const RecipientsList = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-subgroup-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;
  const ItemGroupId = localStorage.getItem("ItemGroupId");
  const ItemGroupName = localStorage.getItem("ItemGroupName");
  
  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
    // Facility Auto
   let Product_Group=JSON.parse(localStorage.getItem("ProductGroup"));
    let All_Product_Group = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
    const ProductGroupList = [All_Product_Group].concat(Product_Group); 
    const [currItemGroupId, setCurrItemGroupId] = useState("0");
    const [currItemGroupName, setCurrItemGroupName] = useState("All");
   

  // Facility
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let Gname = '';
    if (newValue == null) {
      rowId = '';
      Gname = '';
    } else {

      rowId = newValue.id;
      Gname = newValue.name;
    }

    if (rowId !== currItemGroupId) {

      // Facility List By User Change
      setCurrItemGroupId(rowId);
      setCurrItemGroupName(Gname);
     
       setLoading(true);
    }

  };

  React.useEffect(() => {
    localStorage.setItem(
      "ItemGroupId",
      currItemGroupId
    );
   
  
   }, [currItemGroupId]);

  
   React.useEffect(() => {
    localStorage.setItem(
      "ItemGroupName",
      currItemGroupName
    );
  
   }, [currItemGroupName]);


  // End Facility



  let params = {
    menukey: menukey,
    "action": "getDataList",
    ItemGroupId: currItemGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan: lan,
    UserId: UserId,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProductSubGroupList,
    {
      onSuccess: (data) => {
       
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );







  const { mutate:deleteApi } = useMutation(api.deleteProductSubGroup, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        api.getAllDropdown("ProductSubGroupList").then((response) => {
          localStorage.setItem(
            "ProductSubGroupList",
            JSON.stringify(response.datalist.ProductSubGroupList)
          );
        });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("productsubgroup");
       
        
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getProductSubgroupEntryData" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")+
        "&ItemGroupId=" +
        currItemGroupId+
        "&ItemGroupName=" +
        currItemGroupName+
        "&UserId=" +
        UserId+
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
    {
      title: DispensingLanguage[lan][menukey]['Product Subgroup Name'],
      field: "ProductSubGroupName",
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editRecipients= (data) => {
    let route = `product_subgroup/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteRecipients = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Data!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {

      if (willDelete) {
        deleteApi({ProductSubGroupId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }

    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editRecipients(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteRecipients(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.packSizePageTitle}>

      <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Product Subgroup Entry"])}
              </div>
            </div>
          </Grid>
        </div>

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

            <Grid item xs={3} sm={3}>
            <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="ProductGroupList"
                    disableClearable
                    options={ProductGroupList}
                  
                    // disabled={UserName == "admin" ? false : true}
                    onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                   defaultValue={ProductGroupList[ProductGroupList.findIndex(ProductGroupList => ProductGroupList.id == currItemGroupId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}
                   
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"

                      />
                    )}
                  />
                  
                </FormControl>
              </Grid>


              <Grid item xs={9} sm={9}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      if (ItemGroupId!="0") { 
                        props.history.push("/product_subgroup/add");
                      } else {
                        props.openNoticeModal({
                          isOpen: true,
                          msgtype: "0",
                          msg: DispensingLanguage[lan][menukey]["Please select a Product Group"],
                        });
                      } 

                     
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add Product SubGroup"])}
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>




            </Grid>
          </CardContent>
        </Card>

        <div>
        <div className="sw_relative">
        {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              // height={600}
              layout={"fitColumns"}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientsList;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
