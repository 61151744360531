import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official"; 

const NationalStockStatusReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);



  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currZoneIdId, setZoneId] = useState("0");
  const [currCommuneId, setcurrCommuneId] = useState("0");
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [MosTypelist, setMosTypelist] = useState([]);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);

  exporting(Highcharts); 

  // Facility Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;

    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );


  // React.useEffect(() => {

  //   if (currProductGTINId == 0) {
  //     let ProductGTINlistParam1 = { action: "gProductGTIN", menukey: "" }
  //     cproductgtinlist(ProductGTINlistParam1);
  //   }
  //   if (currCartonGTINId == 0) {
  //     let CartonGTINlistParam1 = { action: "gCartonGTIN", menukey: "" }
  //     cCartongtinlist(CartonGTINlistParam1);
  //   }

  // }, [currProductGroupId, currProductGTINId, currCartonGTINId]);


  // Product Group Change
  const handleZoneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';

    } else {
      rowId = newValue.id;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);

    }
  };


  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;

    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);

    }
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };


  let param1 = {
    menukey: menukey,
    action: "GetMosScale",
    lan: lan,
    FLevelId: "",
    FLevelName: "",

  };

  const { data: data1 } = useQuery(
    [param1],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setMosTypelist(data.data.datalist);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "NationalStockStatusTable",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,


  };

  const { data } = useQuery(
    [params],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data.data.datalist);
        

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [nationalStockCategory, setNationalStockCategory] = useState([]);
  const [nationalStockData, setNationalStockData] = useState([]);
  // const [nationalStockDateRange, setnationalStockDateRange] = useState([]);
  const option_National_Stock_Summary = () => {
    let National_Stock_Summary = {
      chart: {
        type: "bar",
        borderColor: "#C3DDEC",
        height: 600,
        exporting:{
          enabled: true
      }, 
         //   borderWidth: 1,
         //   plotBorderWidth: 1,
            margin: [50, 50, 50, 300],

      },
      title: {
        text:
          t(DispensingLanguage[lan][menukey]["National Stock Status Report"]),
      },
      xAxis: {
        categories: nationalStockCategory,
       
      },
      yAxis: { 
        min: 0,
        max:36,
        title: {
          text: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
         }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            crop: true,
            formatter: function () {
              return this.y>0?this.y:"";
            },
          },
        },
      },
      series: [
        {
         name :'',
          data: nationalStockData,
        },
      ],
    };

    return National_Stock_Summary;
  };


  let params1 = {
    menukey: menukey,
    action: "NationalStockStatusChart",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
  };
  const { data:stockDetailData } = useQuery([params1], api.getNationalStockkReport, {
    onSuccess: (data) => {

      setNationalStockCategory(data.data.NationalStockCategory.category);
      // console.log("kkkk",data.data.NationalStockCategory);
      // setnationalStockDateRange(data.data.NationalStockCategory.date_range);

      let Ndata = [];

      for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
        let newdata = {};
        newdata.y = data.data.NationalStockCategory.data[i].y;
        newdata.color = data.data.NationalStockCategory.data[i].color;

        Ndata.push(newdata);
      }
      setNationalStockData(Ndata);
    },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
  });



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "ProductGroup",
      headerSort: false,
      headerFilter: true,
      width: 200,
    },


    {
      title: DispensingLanguage[lan][menukey]["Products"],
      field: "ProductName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,


    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClosingStock",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 150,
      formatter: "money",
    },


  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=NationalStockStatusReport" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneIdId +
      "&DistrictId=" +
      currCommuneId +
      "&ItemGroupId=" +
       currProductGroupId +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["National Stock Status Report"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                {/* <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["Start date"]
                        }
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid> */}


                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}

                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

            </CardContent>
          </Card>


          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                         options={option_National_Stock_Summary()}
                      />
                    </div>
                  </div>
                 
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
            <div dangerouslySetInnerHTML={{__html: MosTypelist}} />
              {/* <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mylegend_area">
                        {MosTypelist.map((item, index) => {
                          return (
                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: item.ColorCode }}
                              ></div>
                              <div className="mylegend_label">{item.name}</div>
                              <div className="mylegend_val">
                             {DispensingLanguage[lan][menukey]["MOS"] }{":"} {item.MosLabel}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid> */}
            </CardContent>
          </Card>


          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["National Stock Status Report Table"])}

                  action={
                    <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NationalStockStatusReport;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
