import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// material /
import {
  Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Launch

} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

//Import Facility Reporting Rate
import FacilityReportingRate from "services/FacilityReportingRate";

import ChartStates from "../esigl_reports/ChartStates";
// import Highcharts from "highcharts/highmaps";
import statesData  from '../../../services/mapjsonfile/benin_regions.json';

const SvdlDashboard = (props) => {
  exporting(Highcharts);

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);

  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currDepartmentName, setDepartmentName] = useState(DepartMentName[0].name);
  const [currZoneIdId, setZoneId] = useState(Zonelist[0].id);
  const [currZonename, setZonename] = useState(Zonelist[0].name);
  const [currCommuneId, setcurrCommuneId] = useState(Communelist[0].id);
  const [currCommuneame, setCommunename] = useState(Communelist[0].name);
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);

  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  const [currYearId, setYearId] = useState(currentDate.getFullYear());
  const [acZoneValue, setACZoneValue] = useState({ id: Zonelist[0].id, name: Zonelist[0].name });
  const [acCommnueValue, setACCommnueValue] = useState({ id: Communelist[0].id, name: Communelist[0].name });

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(6, "days").format('YYYY-MM-DD')
  );
  const [isLoading, setLoading] = useState(false);

  const [geoJson, setGeoJson] = useState(statesData);
  const [dataItemsMap, setDataItemsMap] = useState([]);

  // Department Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }

    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
      setDepartmentName(rowName);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);
  };

  const { mutate: cZoneList } = useMutation(
    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setZoneListLocal(data.data.datalist);
          setACZoneValue({ id: Zonelist[0].id, name: Zonelist[0].name });
          setZoneId(Zonelist[0].id);
          setZonename(Zonelist[0].name);
        }
      },
    }
  );

  // Zone Change
  const handleZoneChange = (event, newValue) => {
    setACZoneValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);
      setZonename(rowName);
    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);
  };

  const { mutate: cCommuneList } = useMutation(
    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setCommunelist(data.data.datalist);
          setACCommnueValue({ id: Communelist[0].id, name: Communelist[0].name });
          setcurrCommuneId(Communelist[0].id);
          setCommunename(Communelist[0].name);
        }
      },
    }
  );

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setACCommnueValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setCommunename(rowName);
    }
  };

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };

  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);
    }
  };

  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProvinceName: currDepartmentName
  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  //Start National Stock Status (Percentage of Products)
  const [NationalStockStatusPercentageOfProducts, setOptionNationalStockStatusPercentageOfProducts] = useState([{name: 'No Data', y: 0, color: '#ffffff'}]);
  
  let PieChartParams = {
    menukey: menukey,
    action: "getNationalStockStatusPercentageOfProducts",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: PieChartLoad } = useQuery(
    [PieChartParams],
    api.getSVDLDashboardReport,
    {
      onSuccess: (data) => {
        handleRefetchCallForPieChart(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const handleRefetchCallForPieChart = (resdata) => {
    let seriesdata = resdata.data;
    
    setOptionNationalStockStatusPercentageOfProducts({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 400,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false
      },
      exporting: {
        filename: 'National_Stock_Status_Percentage_of_Products'
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            },
            formatter: function () {
               return Highcharts.numberFormat(this.y);
            }
          },
          tooltip: {
            shared:true
          }
        },
        pie: {					
          plotBorderWidth: 0,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            }
            ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
          },
          showInLegend: true,
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
            shared:true
          }
        }
      },
      series: [
        {
          name: DispensingLanguage[lan][menukey]["Value (%)"],
          colorByPoint: true,
          data: seriesdata,
          // data: [['a', 50], ['b', 25], ['c', 75]]
        },
      ],
    })
  };
  // End National Stock Status (Percentage of Products)

  //Start % of FS stockout of commodities
  const [PercentageOfFSStockoutOfCommodities, setOptionPercentageOfFSStockoutOfCommodities] = useState({"name":"% of FS stockout","duration":"","category":[],"series":[]});
  
  let LineChartParams = {
    menukey: menukey,
    action: "getPercentageOfFSStockoutOfCommodities",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: LineChartLoad } = useQuery(
    [LineChartParams],
    api.getSVDLDashboardReport,
    {
      onSuccess: (data) => {
        handleRefetchCallForLineChart(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const handleRefetchCallForLineChart = (resdata) => {
    let categorieslist = resdata.category;
    let seriesdata = resdata.data;
    
    setOptionPercentageOfFSStockoutOfCommodities({
      chart: {
        type: "spline",
      },
      title: { 
        text: ''
      },
      exporting: {
        filename: 'Percentage_Of_FS_Stockout_Of_Commodities'
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      xAxis: {
        // categories: ['a', 'b', 'c', 'd', 'e', 'f']
        categories: categorieslist
      },
      tooltip: {
        shared: true
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: DispensingLanguage[lan][menukey]["% of FS stockout"],
          data: seriesdata,
          // data: [['a',50],['b',25],['c',30],['d',20],['e',28],['f',36]],
          color: "#002f6c",
        },
      ],
    });
  };
  // End % of FS stockout of commodities

  //Percentage of Facilities with a Stockout of Key Items Map
  let MapParams = {
    menukey: menukey,
    action: "getPercentageOfFacilitiesWithStockoutOfKeyItemsMap",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: MapLoad } = useQuery(
    [MapParams],
    api.getSVDLDashboardReport,
    {
      onSuccess: (data) => {
        setDataItemsMap(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );
  //End Percentage of Facilities with a Stockout of Key Items Map

  //Start Percentage of facility by MOS scale
  const [PercentageOfFacilityByMOSScale, setOptionPercentageOfFacilityByMOSScale] = useState({"name":"% of FS stockout","duration":"","category":[],"series":[]});
  
  let StackBarChartParams = {
    menukey: menukey,
    action: "getPercentageOfFacilityByMOSScale",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: StackBarChartLoad } = useQuery(
    [StackBarChartParams],
    api.getSVDLDashboardReport,
    {
      onSuccess: (data) => {
        handleRefetchCallForStackBarChart(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const handleRefetchCallForStackBarChart = (resdata) => {
    let categorieslist = resdata.category;
    let seriesdata = resdata.data;
    let columnDecimalPoint = resdata.decimalpoint;
    let tooltipformat='<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.1f}%)<br/>';
    let scrollEnable = true;

    /*This is for chart scroll bar*/
    let totalSeries = 1;
    let scrollHeight = 0;//total height
    let chartHeight = getHeightFromRowNumber(11)-20;

    if(scrollEnable == true){
      if(resdata.category.length>0){
        totalSeries = resdata.category.length;
      }

      scrollHeight = totalSeries*40;// consider each bar height 40

      if(chartHeight >= scrollHeight){
        scrollHeight = 0;
      }
    }
    setOptionPercentageOfFacilityByMOSScale({
      chart: {
        type: "bar",
        height: chartHeight,
        scrollablePlotArea: {
          minHeight: scrollHeight,
          opacity: 1
        },
        marginRight: 40,
      },
      title: { 
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: categorieslist
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: DispensingLanguage[lan][menukey]["MOS Scale"]  //'Value Label'
        },
        reversedStacks: false//,
        // scrollbar: {
          // enabled: true
        // }
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: tooltipformat,
        valueDecimals: columnDecimalPoint,
        shared:true
      },
      exporting: {
        filename: 'Percentage_of_facility_by_MOS_scale'
      },
      navigation: {
        buttonOptions: {
          align: 'right'
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          borderWidth: 0,
          stacking: 'percent',
          dataLabels: {
            enabled: true
            ,style: {
              textShadow: false,
              textOutline: false 
            }
            ,formatter: function () {
               return Highcharts.numberFormat(this.y, columnDecimalPoint);
            }
          },
        }
      },
      series: seriesdata
    });
  };

  const getHeightFromRowNumber = (rowNumber) => {

    let height = 20;
    if(rowNumber == 1)
      height = 20;
    else{
      height =(rowNumber*20) + ((rowNumber-1)*20);
    }
    return height;
  }
  // End Percentage of facility by MOS scale

  //Start Blocks of Information
  const [BlocksOfInformation, setBlocksOfInformation] = useState();

  let BlocksOfInformationParams = {
    menukey: menukey,
    action: "getBlocksOfInformationForSVDLDashboard",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: BlocksOfInformationLoad } = useQuery(
    [BlocksOfInformationParams],
    api.getSVDLDashboardReport,
    {
      onSuccess: (data) => {
        setBlocksOfInformation(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  // End Blocks of Information
  
  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  { DispensingLanguage[lan][menukey]["svdl_Dashboard"] }
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      value={acZoneValue}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={acCommnueValue}
                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          
          {/* Facility Reporting Rate */}
          <FacilityReportingRate 
            ReportingLabelList={getFLvelelList} 
            ReportingValueList={getReportingRate}
            lan={lan}
            menukey={menukey}
          />
          {/* Facility Reporting Rate */}
                    
          {/* start row */}
          <div className="row">
            <div className="col-md-6 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["National Stock Status (Percentage of Products)"]}
                  /> 
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={NationalStockStatusPercentageOfProducts}
                      height={400}
                    />
                  </CardContent>
                </Card>
            </div>
            <div className="col-md-6 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["% of FS stockout of commodities"]}
                  /> 
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={PercentageOfFSStockoutOfCommodities}
                      height={400}
                    />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}

          {/* start row */}
          <div className="row">
            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Percentage of Facilities with a Stockout of Key Items Map"]}
                />
                <CardContent>
                  <ChartStates 
                    mapGeoJson={geoJson} 
                    data={dataItemsMap}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="col-md-6 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["Percentage of facility by MOS scale"]}
                  /> 
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={PercentageOfFacilityByMOSScale}
                      // height={400}
                    />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}

        {/* start row */}
        <div className="row">
            <div className="col-md-12">
                <Card className="sw_card">
                  <CardContent>
                    <div dangerouslySetInnerHTML={{__html: BlocksOfInformation}} />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}
         
        </div>
      </div>
    </>
  );
};

export default SvdlDashboard;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
