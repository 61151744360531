import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  CardHeader,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// import GMap from "../../../services/GMap";
const ProductListBrandViewFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "brands-list";

  const classes = useStyles();

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Brand View"]}
              </div>

              <div className="float-right sw_btn_control">
                {/* Action buttons */}
                {props.addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/certificate-manufacturer-authorization")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
                {/* End Action buttons */}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Brand Basic Information"]}
                    />

                  <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Generic Code"]}</div>
                              <div className="desc">{ formData.ItemCode ? (formData.ItemCode) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["SIGIP Code"]}</div>
                              <div className="desc">{ formData.SIGIPCode ? (formData.SIGIPCode) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Commercial"]}</div>
                              <div className="desc">{ formData.CommercialName ? (formData.CommercialName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Form"]}</div>
                              <div className="desc">{ formData.DosageFormName ? (formData.DosageFormName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Demander Address"]}</div>
                              <div className="desc">{ formData.DemanderAddress ? (formData.DemanderAddress) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Pays Fabricant"]}</div>
                              <div className="desc">{ formData.PaysFabricant ? (formData.PaysFabricant) : ('---') }</div>
                            </li>

                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Date Expiration DeLAMM"]}</div>
                              <div className="desc">{ formData.DateExpirationDeLAMM ? (formData.DateExpirationDeLAMM) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PGHT Presentation"]}</div>
                              <div className="desc">{ formData.PGHTPresentation ? (formData.PGHTPresentation) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Date Expiration"]}</div>
                              <div className="desc">{ formData.DateExpiration ? (formData.DateExpiration) : ('---') }</div>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                            
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Generic"]}</div>
                              <div className="desc">{ formData.ItemName ? (formData.ItemName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Product Status"]}</div>
                              <div className="desc">{ formData.ProductStatus ? (formData.ProductStatus) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Strength"]}</div>
                              <div className="desc">{ formData.StrengthName ? (formData.StrengthName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["DCI"]}</div>
                              <div className="desc">{ formData.DciName ? (formData.DciName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Pays Exploitant"]}</div>
                              <div className="desc">{ formData.PaysExploitant ? (formData.PaysExploitant) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Representant Localou Exploitant"]}</div>
                              <div className="desc">{ formData.RepresentantLocalouExploitant ? (formData.RepresentantLocalouExploitant) : ('---') }</div>
                            </li>

                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Cond Delivrance"]}</div>
                              <div className="desc"> { formData.CondDelivrance ? (formData.CondDelivrance) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PGHT Prix Public"]}</div>
                              <div className="desc">{formData.PGHTPrixPublic}</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Classe Therapeutique"]}</div>
                              <div className="desc"> { formData.ClasseTherapeutique ? (formData.ClasseTherapeutique) : ('---') }</div>
                            </li>

                          </ul>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Product Group"]}</div>
                              <div className="desc">{ formData.GroupName ? (formData.GroupName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Brand Code"]}</div>
                              <div className="desc">{ formData.BrandCode ? (formData.BrandCode) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Pack Size"]}</div>
                              <div className="desc">{ formData.PacksizeName ? (formData.PacksizeName) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Demander"]}</div>
                              <div className="desc">{ formData.DemanderName ? (formData.DemanderName) : ('---') }</div>
                            </li>                    
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Intitule Fabriquant"]}</div>
                              <div className="desc">{ formData.IntituleFabriquant ? (formData.IntituleFabriquant) : ('---') }</div>
                            </li>            
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Voie Administration"]}</div>
                              <div className="desc">{ formData.VoieAdministration ? (formData.VoieAdministration) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Code AMM"]}</div>
                              <div className="desc">{ formData.CodeAMM ? (formData.CodeAMM) : ('---') }</div>
                            </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["Date De Debut"]}</div>
                              <div className="desc">{ formData.DateDeDebut ? (formData.DateDeDebut) : ('----') }</div>
                            </li>

                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["SOBAPS Code"]}</div>
                              <div className="desc">{ formData.SOBAPSCode ? (formData.SOBAPSCode) : ('---') }</div>
                            </li>
                            
                          </ul>
                        </Grid>

                        </Grid>
                  </CardContent>

                  </Card>

                </Grid>



                <Grid item xs={12} sm={12}>
                <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Brand GS1 Information"]}
                    />
                  <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                           
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["EA GTIN"]}</div>
                              <div className="desc">{ formData.EAGTIN ? (formData.EAGTIN) : ('---') }</div>
                          </li>
                          
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PK GTIN"]}</div>
                              <div className="desc">{ formData.PKGTIN ? (formData.PKGTIN) : ('---') }</div>
                          </li>
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["CA GTIN"]}</div>
                              <div className="desc">{ formData.CAGTIN ? (formData.CAGTIN) : ('---') }</div>
                          </li>
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PL GTIN"]}</div>
                              <div className="desc"> { formData.PLGTIN ? (formData.PLGTIN) : ('---') }</div>
                            </li>
                           
                           
                          </ul>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["EA Qty"]}</div>
                              <div className="desc"> { formData.EAQty ? (formData.EAQty) : ('---') }</div>
                          </li>
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PK Qty"]}</div>
                              <div className="desc">{ formData.PKQty ? (formData.PKQty) : ('---') }</div>
                          </li>
                          
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["CA Qty"]}</div>
                              <div className="desc">{ formData.CAQty ? (formData.CAQty) : ('---') }</div>
                          </li>
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PL Qty"]}</div>
                              <div className="desc"> { formData.PLQty ? (formData.PLQty) : ('---') }</div>
                          </li>

                          </ul>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                         
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["EA Child GTIN"]}</div>
                              <div className="desc">{ formData.EAChildGTIN ? (formData.EAChildGTIN) : ('---') }</div>
                            </li>
                        
                            <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PK Child GTIN"]}</div>
                              <div className="desc">{ formData.PKChildGTIN ? (formData.PKChildGTIN) : ('---') }</div>
                            </li>
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["CA Child GTIN"]}</div>
                              <div className="desc">{ formData.CAChildGTIN ? (formData.CAChildGTIN) : ('---') }</div>
                          </li>
                          <li>
                              <div className="title">{DispensingLanguage[lan][menukey]["PL Child GTIN"]}</div>
                              <div className="desc">{ formData.PLChildGTIN ? (formData.PLChildGTIN) : ('---') }</div>
                          </li>
                            
                          </ul>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <ul className="item_details_list">
                          <li>
                            <div className="title">{DispensingLanguage[lan][menukey]["GPC Code"]}</div>
                            <div className="desc">{ formData.GPCCode ? (formData.GPCCode) : ('---') } </div>
                          </li>
                          </ul>
                        </Grid>

                      </Grid>
                  </CardContent>


                  </Card>

                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

    
        </Grid>
      </div>
    </div>

   
  );
};

export default ProductListBrandViewFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
