import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  CardHeader,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Clear, CloudUploadOutlined, PictureAsPdf } from "@material-ui/icons";
import swal from "sweetalert";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";
//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

const ManufacturerAuthorizationFormData = ({
  BrandNo,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "certificate-manufacturer-authorization";

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();

  const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));

  const { useState } = React;

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const [ManufacturerAuthUploadedFileName, setManufacturerAuthUploadedFileName] = useState();
  const [ErrorMgsManufacturerAuth, setErrorMgsManufacturerAuth] = useState();
  const [OldManufacturerAuthUploadedFileName, setOldManufacturerAuthUploadedFileName] = useState();
  const [isLoading, setLoading] = useState(false);
  const [ErrorMsgShowing, setErrorMsgShowing] = useState(false);

  const [ShowErrorMsg, setShowErrorMsg] = useState();

  const [uploadFormData, setFormData] = useState({
    menukey: menukey,
    lan: lan,
    UserName: UserName,
    BrandNo: BrandNo,
    ManufacturerAuthorization: "",
    BrandCode: "",
    ManufacturerId: "",
    DateExpiration: "",
    UploadStatus: "",
  });

const {  mutate: mutateBrandData } = useMutation(api.getBrandDataById, {
  onSuccess: (data) => { 
    if (data.status === 200) { 

      setFormData(data.data);
      setOldManufacturerAuthUploadedFileName(data.data.ManufacturerAuthorization);
    }
  }
});
const getBrandData = () => {

  let params={
    action:"getBrandDataById",
    menukey: menukey, 
    BrandNo: BrandNo,
  };
  mutateBrandData(params);
};

const APP_URL = process.env.REACT_APP_API_URL;

/* =============Manufacturer Authorization=========== */

const changeHandlerForManufacturerAuth = (event) => {

  let selectedFile = event.target.files[0];

  if(selectedFile == undefined){
    return;
  }

  setErrorMsgShowing(false);
  setLoading(true);
  setbuttonDisabled(false);

  let FileName = selectedFile.name;
  let FileExt = FileName.split(".").pop();
  let Filetype = FileExt.toLowerCase();

  //File size Greater then 5MiB!
  // const fileSizeNum = selectedFile.size / 1024 / 1024; // in MiB
  // const fileSize = fileSizeNum.toFixed(2);

  //The file Check extension
  if (Filetype == "pdf" || Filetype == "PDF") {
    setErrorMgsManufacturerAuth("");
    handleUploadFile(selectedFile);
  } else {
    setErrorMgsManufacturerAuth(
      DispensingLanguage[lan][menukey]["Sorry, only pdf file are allowed"]
    );
    // setManufacturerAuthUploadedFileName("");
    // uploadFormData["ManufacturerAuthorization"] = "";
    setbuttonDisabled(true);
    setLoading(false);
  }
};

const handleUploadFile = async (selectedFile) => {

  swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Confirm Manufacturer Authorization PDF File Upload"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      handleFileUploadForManufacturerAuth(selectedFile);
    }else{
      uploadFormData["ManufacturerAuthorization"] = OldManufacturerAuthUploadedFileName;
      uploadFormData["UploadStatus"] = '';
      setManufacturerAuthUploadedFileName('');
      
      setLoading(false);
      setbuttonDisabled(true);
    }
  });
};

const handleFileUploadForManufacturerAuth= (selectedFile) => {
const formData = new FormData();

formData.append("FileName", selectedFile);

formData.append("BrandCodeManufacturerId", uploadFormData.ManufacturerId);
formData.append("DateExpiration", uploadFormData.DateExpiration);
let authCertPath = GlobalSettings.auth_cert;
formData.append("authCertPath", authCertPath);

let finalUrl = APP_URL + "upload/auth_cert_upload.php";

fetch(finalUrl, {
  method: "POST",
  body: formData,
})
  .then((response) => response.json())
  .then((res) => {
    if (res.msgType == "success") {
      setManufacturerAuthUploadedFileName(res.FileName);
      uploadFormData["ManufacturerAuthorization"] = res.FileName;
      uploadFormData["UploadStatus"] = 'FileUpload';
      setErrorMgsManufacturerAuth("");
      handleSubmit();
    } else {
      setErrorMgsManufacturerAuth(res.msg);
    }
    setLoading(false);
  })
  .catch((error) => {
    setErrorMgsManufacturerAuth(error);
    setLoading(false);
  });
};

const { mutate } = useMutation(api.UploadManufacturerAuthorization, {
  onSuccess: (data) => {
    let msg = data.message;
    if (data.success == 1) {
      getBrandData();
      swal(msg, "", "success");

    } else {
      setShowErrorMsg(msg);
      setErrorMsgShowing(true);
    }
    
    uploadFormData["ManufacturerAuthorization"] = "";
    uploadFormData["UploadStatus"] = '';
    setManufacturerAuthUploadedFileName("");
    
    setLoading(false);
    setbuttonDisabled(true);
  },
});

const handleSubmit = (data) => {
  setLoading(true);
  setbuttonDisabled(true);
  mutate(uploadFormData);
}

const UploadFileDownload = (uploadFile) => {
  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const REPORT_URL = removeStrFromURL.replace('backend/', '');
  let authCertPath = GlobalSettings.auth_cert;
  let finalUrl = REPORT_URL + authCertPath+'/'+uploadFile;

  // let finalUrl = REPORT_URL + "writable_files/auth_cert/"+uploadFile;

  window.open(finalUrl);
};

const DeleteUploadFile = async (e) => {

  swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Once deleted, you will not be able to recover this PDF File!"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      onConfirmDeleteUploadFile();
    }
  });
};

const onConfirmDeleteUploadFile = () => {

  uploadFormData["ManufacturerAuthorization"] = "";
  uploadFormData["UploadStatus"] = 'FileDelete';
  setManufacturerAuthUploadedFileName("");

  handleSubmit();

  setbuttonDisabled(false);
}

useEffect(() => {
  getBrandData();

}, [BrandNo]);

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Upload Manufacturer Authorization"]}
              </div>

              <div className="float-right sw_btn_control">
                {/* Action buttons */}
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/certificate-manufacturer-authorization")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                {/* End Action buttons */}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Upload Manufacturer Authorization"]}
                    />
                      <CardContent>
                        <Grid container spacing={3}>
                        <Grid item xs={2} sm={2}></Grid>
                            
                          <Grid item xs={8} sm={8}>
                            {/* ====Upload Box=== */}
                            <div className="tp-upload-box marginTopBottom">
                              <div className="text-right sw_relative">
                                {isLoading && <LoaderForGlobal />}
                              </div>
                              <div className="tp-body">
                                <label class="label-ctm" for="ManufacturerAuthorization_selectFile">{DispensingLanguage[lan][menukey]["Manufacturer Authorization"]}</label>
                                <div className="tp-file-upload">
                                  <input
                                    type="text"
                                    name="ManufacturerAuthorization_selectFile"
                                    id="ManufacturerAuthorization_selectFile"
                                    value={ManufacturerAuthUploadedFileName}
                                    placeholder={
                                      DispensingLanguage[lan][menukey]["Choose a file"]
                                    }
                                    className="tp-form-control"
                                    readOnly={true}
                                  />
                                  <div className="file_browse_box">
                                    <input
                                      type="file"
                                      accept=".pdf, .PDF"
                                      name="ManufacturerAuthorization"
                                      id="ManufacturerAuthorization"
                                      className="tp-upload"
                                      onChange={changeHandlerForManufacturerAuth}
                                    />
                                    <label for="ManufacturerAuthorization" className="file_browse_icon">
                                      <span className="icon-upload">
                                        <CloudUploadOutlined />
                                      </span>
                                      {DispensingLanguage[lan][menukey]["Browse"]}
                                    </label>
                                  </div>
                                </div>
                                <div className="selectFile text-left">
                                  <span className="red">{ErrorMgsManufacturerAuth}</span>
                                </div>
                                {uploadFormData.ManufacturerAuthorization && 
                                <div className="attachment_view_card">
                                  <Button
                                      className="delete_icon"
                                      onClick={() => DeleteUploadFile('ManufacturerAuthorization')}
                                    >
                                    <Clear />
                                  </Button>
                                  <div className="view_icon">
                                    <PictureAsPdf
                                      onClick={() => UploadFileDownload(uploadFormData.ManufacturerAuthorization)}
                                    />  
                                  </div>
                                </div>
                                }
                              </div>
                            </div>
                            {/* ====Upload Box=== */}
                          </Grid>
                          <Grid item xs={2} sm={2}></Grid>
                        </Grid>
                      </CardContent>
                  </Card>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ManufacturerAuthorizationFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
