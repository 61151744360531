import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import SOBAPSReceiptsItems from './SOBAPSReceiptsItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";

const EditSOBAPSReceipts = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "sobaps-receipts";
// const FacilityId=localStorage.getItem('FacilityId');
const FacilityId = JSON.parse(localStorage.getItem("getCentralWarehouseId"));
const TransactionTypeId = 1;
   

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",  
    "FacilityId":FacilityId,
    TransactionTypeId:TransactionTypeId,
    ReceiveFrom:"",
    PreparedBy:"", 
    Remarks: "",
    StoreId:"",
    InvFrom:null,
    });
    const [selectedDate, setSelectedDate] = useState(
      new Date()
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  
  const [bFirst, setBFirst] = useState(true); 

  const [ItemGroupId, setItemGroupId] = useState("0");
   
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['receive', id], () =>

    api.getSobapsReceiveFromAsnListSingle({TransactionId:id,ItemGroupId:ItemGroupId, FacilityId:FacilityId}).then((res) => {
    
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDeletedDataSet([]);
      setBFirst(true);

      
      return res.data
    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })

  const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceivefromSupplierEntryData, {
    onSuccess: (data) => {
      if (data.status == 200) {
 
        setbStockUpdate(data.bStockUpdated);
        if(data.bStockUpdated==1){

          mutatePost(TransactionId);
        
        }else{
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal("Success!", "", "success");
        }


      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading1, mutate:mutatePost } = useMutation(api.updateStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        setbStockUpdate(1);
        setDeletedDataSet([]);

        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


 
  
  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
    

};
  const handleChange = (e) => {
    const { name, value } = e.target;
     
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const filterFandleChange = (e) => {
    setItemGroupId(e) ;
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "", 
      "FacilityId":FacilityId,
      TransactionTypeId:TransactionTypeId ,
      TransactionId:"",
      ReceiveFrom:"",      
      PreparedBy:"",
      Remarks: "",
      InvFrom:null,
    });
  };


  const handleModalCheck = () => {
    if (validateForm(formData,2)) {
      return true;
    }else{
      return false;
    }
  };

  const validateForm = (formData,checkSupplierInv) => {
    //let validateFields = [ "TransactionDate", "TransactionNo","PreparedBy"]
    let validateFields = checkSupplierInv==2?["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ReceiveFrom"]:["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","SupplierInvNo","ReceiveFrom"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv=(data)=>{
    console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }

  const handleUpdate = async (e) => {
    if (validateForm(formData,1)) {
    let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet}; 
    mutateUpdate(fDtat); 
    }
  };


  

 
  const handlePost = async (e) => {  

    
    if (validateForm(formData,1)) {
       
      //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
      //mutateUpdate(fDtat);

      // Start Post Alert
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
          mutateUpdate(fDtat); 
          
        }else{
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

      
    }
     
     
  };



  const { isLoading2, mutate:mutateDownloadIssueInvoice } = useMutation(api.updateDownloadIssueInvoice, {
    onSuccess: (data) => { 
      if (data.status == 200) { 
      
        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
                
         
         }else{

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })



  const DownloadIssueInvoice = async (e) => {  

    
    if (validateForm(formData,1)) {
       
          let fData={...formData};
          mutateDownloadIssueInvoice(fData); 
        
      };  
   
  };


  const setUpdatedDetailsGridDataHandler = (rows) => {
    
    
    if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
      mutateUpdate(fDtat); 
      }


    // const updateManyTableData = ManyTableData.map((row) => {
     
    //   if (row.SKU == rows.SKU) {
    //     row.Quantity = rows.Quantity;
    //     row.BatchNo = rows.BatchNo;
    //     row.ExpiryDate = rows.ExpiryDate;
    //   } 
    //   return row;
    // });
    // console.log('updateManyTableData: ', 'sdgfjsgfsgfjshgfdhsgfhdsfg');
    setManyTableData(rows); 
  };

  
  // useEffect(() => { 
    

  // }, []); 
 
   
  return (
    <>
      <SOBAPSReceiptsItems 
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        bFirst={bFirst}
        refetch={refetch}
        DownloadIssueInvoice={DownloadIssueInvoice}
        handleModalCheck={handleModalCheck}
        filterFandleChange={filterFandleChange} 

        {...props} />
    </>
  );
};

export default EditSOBAPSReceipts;