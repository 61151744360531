import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import {
  DeleteOutline, 
  FileCopy
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";
import ProductsModal from '../../../services/initialProductsModal'; 
import * as ScanBarcode from '../../../services/ScanBarcode';
import { cellFocusEditor,cellFocusEditorSerial, dateEditor  } from "../../../services/Common";
import moment from "moment";
import "../common.css";
import swal from "sweetalert";


import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import * as api from '../../../actions/api';
// react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';



let isDirty=false; 
let deletedDatas=[];
let transactionItemID =0;
let masterDirty=false;
const StockConfigurationFormData = (props) => {
  const { t, i18n } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const [bFirst, setBFirst] = useState(true); 
             
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "initial-stock-configuration";
  const FacilityId = localStorage.getItem('FacilityId');
  const FacilityName = localStorage.getItem('FacilityName');

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  let tblRef = null;
  const classes = useStyles();
  const [manyTableData, setManyTableData] = useState([]);
  const [IsCompleted, setIsCompleted] = useState(false);

  
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [SoureOpen, setSoureOpen] = useState(false); 
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [barcodeValue, setbarcodeValue] = useState("");
  const StoreId = localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const AStoreList = JSON.parse(localStorage.getItem("getStore"));
  const [isLoading, setLoading] = useState(true);
   
  
  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);
  const [timeStamp, setTimeStamp] = useState('');

  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  
  
  const AStoreIdRef = useRef();
  const handleStoreChange = (event) => {
    
    if(currStoreId!==event.target.value){
      if(props.IsCompleted==0)
      setcurrStoreId(event.target.value); 
      setLoading(true);     
      props.StoreOnchangeFn(event.target.value);
      // masterDirty=true;
      // onMasterSetDirty(); 
      
    }
  };



  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&StockTakeId=" + props.formData.StockTakeId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&StockTakeId=" + props.formData.StockTakeId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */



  

     const manycolumns = [ 
      { title: DispensingLanguage[lan][menukey]['Actions'], field: 'custom', headerSort:false, headerHozAlign:"center",   hozAlign: 'center',width: 70,
       formatter: reactFormatter(<ActionButton />) } ,
       { title: "StockTakeItemsId", field: "StockTakeItemsId", visible: false }, 
       { title: "bSerialSupported", field: "bSerialSupported", visible: false },
       {
        title: "GroupName",
        field: "GroupName",
        visible: false,
      },
      {
        title: "dirty",
        field: "dirty",
        visible: false,
      },
       { title: 'Id', field: 'id',  visible:false },
       { title: DispensingLanguage[lan][menukey]['SL'], width:50, hozAlign: 'center', formatter: "rownum",
       headerSort:false, headerHozAlign:"center"},
       
       
       { title: DispensingLanguage[lan][menukey]['Product_Code'], field: 'ItemCode',width:135,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Product'], field: 'ItemName',width:240, headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['BrandCode'], field: 'BrandCode',width:190,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['BrandName'], field: 'CommercialName',width:180,headerFilter:true,},
       { title: DispensingLanguage[lan][menukey]['Lot_No_Description'],
        field: 'BatchNo', hozAlign:"left",
        headerHozAlign:"left",width:100,
        headerFilter:true,
        editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '',   //"input" 
        cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
        cellEdited: function (cell) { 
           
          
        //  setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData(); 
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
        //  return;

        },
      
       },
       { title: DispensingLanguage[lan][menukey]['Expiry_Date'], field: 'ExpiryDate', 
         hozAlign:"left",headerHozAlign:"left",width:100,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?dateEditor:''): '', 
          cellEdited: function (cell) {
            
            const curcellval = cell.getValue();
            const currow = cell.getRow();
            const rowdata = currow.getData(); 

            
            //setDirty(true); 
            currow.getCell("dirty").setValue(1); 
            isDirty=true;
            transactionItemID=rowdata.id;
            onSetDisable();
          }
        },
        
        { 
          title: DispensingLanguage[lan][menukey]['Physical_Quantity'], 
          field: 'PhysicalQty',
          width:100, 
          hozAlign:"right",
          headerHozAlign:"right",
          editor: props.IsCompleted == 0 ? cellFocusEditor: '',   //"input"
          validator:["integer", "min:0"],
          cssClass: "tabluator-column-editable",
          cellEdited: function (cell) {
           
            //setDirty(true);
            const currow = cell.getRow();
            const rowdata = currow.getData();
            currow.getCell("dirty").setValue(1); 
            isDirty=true;
            transactionItemID=rowdata.id;
            onSetDisable();
          
          },
         },
       
       
        { 
       title: DispensingLanguage[lan][menukey]['AMC'], 
       field: 'AMC',
       width:100, 
       hozAlign:"right",
       headerHozAlign:"right",
       editor: props.IsCompleted == 0 ? cellFocusEditor: '',   //"input"
       validator:["integer", "min:0"],
       cssClass: "tabluator-column-editable",
       cellEdited: function (cell) {
       
        //setDirty(true);
        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();
       },
      },

      { title: DispensingLanguage[lan][menukey]['Purchase Price'], 
       field: 'PurchasePrice',
       width:110, 
       hozAlign:"right",  
       headerHozAlign:"right",
       cssClass: "tabluator-column-editable",
       editor: props.IsCompleted == 0? true : '', 
       cssClass: "tabluator-column-editable",
       cellEdited: function (cell) {
        
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
       
        const pricecell = currow.getCell("SalesPrice"); 
        const totprice =  curcellval!=""?(curcellval*(1 + (props.formData.PriceMarkupMax -props.formData.PriceMarkupMin))).toFixed(2):"";
        pricecell.setValue(totprice);
       // props.setUpdatedDetailsGridDataHandler(currow._row.data);
       
       //setDirty(true);
       currow.getCell("dirty").setValue(1);
       isDirty=true;
       transactionItemID=rowdata.id;
       onSetDisable();

       },
      },

      //  { title: DispensingLanguage[lan][menukey]['Adj_Quantity'], field: 'AdjQuantity',width:123, hozAlign:"right",headerHozAlign:"right"},
       { title: DispensingLanguage[lan][menukey]['Sales Price'], 
       field: 'SalesPrice',
       width:110, 
       hozAlign:"right",  
       headerHozAlign:"right",
       cssClass: "tabluator-column-editable",
       editor: props.IsCompleted == 0? true : '',
       cellEdited: function (cell) {
        
        //setDirty(true);
        const currow = cell.getRow();
        const rowdata = currow.getData();
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();
       }
      },
      { title: DispensingLanguage[lan][menukey]['EAGTIN'], field: 'EAGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:120,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
           
        },
        { title: DispensingLanguage[lan][menukey]['PKGTIN'], field: 'PKGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:115,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
         
        },
        { title: DispensingLanguage[lan][menukey]['CAGTIN'], field: 'CAGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:115,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
         
        },
        
        { title: DispensingLanguage[lan][menukey]['PLGTIN'], field: 'PLGTIN', 
         hozAlign:"left",headerHozAlign:"left",width:115,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
         
        },
        
        { title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: 'SSCCCarton', 
         hozAlign:"left",headerHozAlign:"left",width:125,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
         
        },
        
        { title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: 'SSCCPallet', 
         hozAlign:"left",headerHozAlign:"left",width:115,headerFilter:true,
         cssClass: props.isFirstPhysical==0?"tabluator-column-editable text-field-editable":'',
         editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?cellFocusEditor:''): '', 
         cellEdited: function (cell) {
          
          //setDirty(true);
          const currow = cell.getRow();
          const rowdata = currow.getData();
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          transactionItemID=rowdata.id;
          onSetDisable();
         }
         
        },
 
		   
		 


    
      
    ];

    const onSetDisable = () => { 
    // console.log(document.getElementById("AStoreId"));
    //alert(dirty)
      // if(isfirst)
      //  setDirty(false);
      //  else
      // setDirty(dirty!=dirty);
       if(isDirty==true){ 
       //  setDirty(true);
        // var element = document.getElementById("FID_AStoreId");
         document.getElementById("FID_AStoreId").classList.add("swDisabled");
         document.getElementById("FID_scanButton").classList.add("swDisabled");
         document.getElementById("is_dirty_message").classList.remove("dnone");
         document.getElementById("FID_scan_gtin_or_brandcode").classList.add("swDisabled"); 
         document.getElementById("FID_ItemGroupId").classList.add("swDisabled");
         document.getElementById("FID_pick_items_btn").classList.add("swDisabled");

        let dataArray=[];
        let gridData=tblRef.table.getData();
        gridData.forEach((row,i) => { 
          if(row.id==transactionItemID) {
            dataArray.push(row);
            transactionItemID=0;
          }
        });
        let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan,"action":"updateStockTake","UserName":UserName};  
        mutateCellUpdate(fDtat);
         
       }
       else{

        document.getElementById("FID_AStoreId").classList.remove("swDisabled");
        document.getElementById("FID_scanButton").classList.remove("swDisabled");
        document.getElementById("is_dirty_message").classList.add("dnone");
        document.getElementById("FID_scan_gtin_or_brandcode").classList.remove("swDisabled");
        document.getElementById("FID_ItemGroupId").classList.remove("swDisabled");
        document.getElementById("FID_pick_items_btn").classList.remove("swDisabled");
        
        
       }
       

    //    AStoreIdRef.current.disabled = isDirty;

      
      
     //  document.getElementById("FID_AStoreId").addClass('swDisabled');
    //   document.getElementById("ItemGroupId").disabled = isDirty;
       
      
    //   document.getElementById("scanButton").disabled = isDirty;
    //   document.getElementById("is_dirty_message").disabled = isDirty;
    //   //document.getElementById("pick_items").disabled = isDirty;
      
       
    }

    const onMasterSetDirty = () => { 
    
      if(masterDirty==true){ 
    
        document.getElementById("master_dirty_message").classList.remove("dnone");
    
          
      }else{ 
    
        document.getElementById("master_dirty_message").classList.add("dnone");
       
      }
      
    }

    const mutateCellUpdate=(fDtat) => { 
  
      const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null; 
        const apiUrl = process.env.REACT_APP_API_URL + "source/api/InitialStockConfiguration";
        let data=fDtat;
        
          fetch(apiUrl, {
            method: "POST", 
            headers: {
                
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${auth_token}`,
              "Consumer": process.env.REACT_APP_CONSUMER,
              "Secret": process.env.REACT_APP_SECRET,
            
            },
            body: JSON.stringify(data),
          })
          .then(function (res) {
            console.log(res);
           
            return res.json();
          })
          .then(function (data) {
            isDirty=false;
            onSetDisable();  
          })
          .catch(error => {
      
            props.openNoticeModal({
              isOpen: true,
              msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
              msgtype: 0,
            });
          
           });
      
      
       }


    const onPostBtnClick = () => { 
      
    //  props.setUpdatedDetailsGridDataHandler(tblRef.table.getData()); 
      setIsCompleted(true); 

      let dataArray=[];
      let gridData=tblRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      handlePost(dataArray,deletedDatas);
      //props.handlePost(tblRef.table.getData(),deletedDataSet);
  
    }


    const handlePost = async (rows) => {  

      let validForm = props.checkValidateFormPost(tblRef.table.getData(), 'post');
      
      if (validForm) {
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
          icon: "warning",
          buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
          t(DispensingLanguage[lan][menukey]["Yes"])],
          dangerMode: true,
        }).then((willAction) => {
          if (willAction) {
            //setStockPost(true);
            let newarray = rows.filter(element => parseFloat(element.PhysicalQty)>0); 
            let FinalData={...validForm,'ManyJsonSave':newarray,"IsCompleted":1,menukey: menukey,lan: lan, "UserName":UserName}; 
            mutateUpdate(FinalData); 
            //setManyTableData(rows);
   
          }else{
            //props.setbStockUpdate(0);
            props.stockPosted(0);
            let route = `${validForm.FacilityId}`;
            props.history.push(route);
          }
        });  
      }
        
       
       
    };
  
    const onSaveUpdateBtnClick = () => { 

      let dataArray=[];
      let gridData=tblRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      
      setUpdatedDetailsGridDataHandler(dataArray,deletedDatas); 
      //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 
    
    }


    const setUpdatedDetailsGridDataHandler = (rows) => {
      let validForm = props.checkValidateForm(rows,'edit');
      // console.log('isval: ', validForm);
        if (validForm) {
  
          // console.log('props.addProductForm: ', props.addProductForm);
          if(props.addProductForm){
            //when save
            //mutate(validForm);
          }else{
            //when update
            mutateUpdate(validForm); 
          }
          
  
          
        }
    };
    
    /*
    const { isLoading: isLoadingSave, mutate } = useMutation(api.saveStockTakeInv, {
      onSuccess: (data) => { 
        if (data.status == 200) {
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
        let route = `edit/${data.StockTakeId}`;
        props.history.push(route); 
        
        }else{
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    });

    */
  
    
    const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateInitialStockTakeInv, {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) { 
        //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          masterDirty=false;
          onMasterSetDirty();

          props.successSave(data.IsCompleted);
          if(data.data.IsCompleted==1){
           mutatePost({           
              FacilityId: props.formData.FacilityId,
              CompleteDate:props.formData['CompleteDate'],
              PreparedBy:props.formData['PreparedBy'],
              ApprovedBy:props.formData['ApprovedBy']
           });
          }
          else{
            props.successSave(data.data.IsCompleted); //refetch(); 
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
   
          }
  
        }else{ 
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      }
    });
    
  
  
    const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postInitialStockTake, {
      onSuccess: (data) => { 
        if (data.status == 200) { 
  
          //props.setbStockUpdate(1);
          //setDeletedDataSet([]);
  
          if(data.success==0){ 
            
            props.stockPosted(0);
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
           
           // swal(data.data.message, "", "warning"); 
           
           }else{


            // Change local storage
            api.getDependencyCombo({FacilityId:props.formData.FacilityId,task:'getFacilityListEqual99'})
            .then((response) => { 
              localStorage.setItem(
                "FacilityId",
                response.datalist.getFacilityListEqual99[0].id
              );
              localStorage.setItem(
                "DefaultFacilityId",
                response.datalist.getFacilityListEqual99[0].id
              );

              localStorage.setItem(
                "getFacilityListEqual99",
                JSON.stringify(response.datalist.getFacilityListEqual99)
              ); 
            });

            props.stockPosted(1);
            props.refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
  
            //swal(data.data.message, "", "success");
            
    
           } 
  
        }else{
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    })

 
    const addProduct = (NonOrderProductData) => { 
      
      let temNonordata=NonOrderProductData;
      let rows = [];
   
      let autoId=0;
      temNonordata.forEach((row,i) => {
        autoId++
        let newRow={} ;
        newRow.ItemNo =row.ItemNo; 
        newRow.ItemCode =row.ItemCode; 
        newRow.ItemName =row.ItemName; 
  
        newRow.StockTakeItemsId = -1; 
        
        newRow.SKU=autoId;
        newRow.id=autoId;   
  
        newRow.BatchNo = null;
        newRow.ExpiryDate =null; 
        newRow.StoreQty = null;
        newRow.PhysicalQty = null;
        newRow.AdjQuantity = 0;
        newRow.Remarks=null; 

        newRow.EAGTIN = row.EAGTIN;  
        newRow.PKGTIN = row.PKGTIN;  
        newRow.CAGTIN = row.CAGTIN;  
        newRow.PLGTIN = row.PLGTIN;  
        newRow.SSCCCarton = row.SSCCCarton;  
        newRow.SSCCPallet = row.SSCCPallet;  
        newRow.BrandNo = row.BrandNo; 
        newRow.PurchasePrice = row.PurchasePrice; 
        newRow.SalesPrice = row.SalesPrice;
        newRow.AMC = null;  
        newRow.dirty = 0;  
        newRow.GroupName = row.GroupName; 
        newRow.BrandCode = row.BrandCode;  
        newRow.CommercialName = row.CommercialName;   
        rows.push(newRow);
      
    });
    
   // props.setUpdatedDetailsGridDataHandler(rows,deletedDataSet); 
    setUpdatedDetailsGridDataHandler(rows,deletedDatas); 
    
  //   manyTableData.forEach((row,i) => {
  //     autoId++;
  //     let newRow={} ; 
  //     newRow.ItemNo =row.ItemNo;
  //     newRow.ItemCode =row.ItemCode;
      
  //     newRow.ItemName =row.ItemName; 
  //     newRow.StockTakeItemsId = row.StockTakeItemsId; 
  //     newRow.SKU=autoId;
  //     newRow.id=autoId;   

  //     newRow.BatchNo = row.BatchNo;
  //     newRow.ExpiryDate =row.ExpiryDate; 
  //     newRow.StoreQty = row.StoreQty;
  //     newRow.PhysicalQty = row.PhysicalQty;
  //     newRow.AdjQuantity = row.AdjQuantity;
  //     newRow.Remarks=row.Remarks; 

  //     newRow.EAGTIN = row.EAGTIN;  
  //     newRow.PKGTIN = row.PKGTIN;  
  //     newRow.CAGTIN = row.CAGTIN;  
  //     newRow.PLGTIN = row.PLGTIN;  
  //     newRow.SSCCCarton = row.SSCCCarton;  
  //     newRow.SSCCPallet = row.SSCCPallet;  
  //     newRow.BrandNo = row.BrandNo; 
  //     newRow.PurchasePrice = row.PurchasePrice; 
  //     newRow.SalesPrice = row.SalesPrice;  
  //     newRow.AMC = row.AMC;  
  //     newRow.dirty = row.dirty;
  //     newRow.GroupName = row.GroupName;
  //     newRow.BrandCode = row.BrandCode; 
  //     newRow.CommercialName = row.CommercialName;   
  

     
      
  //     rows.push(newRow);
      
  // }); 

  
  //      rows.sort((a, b) => (a.ItemName > b.ItemName) ? 1 : -1)
     


    //  setManyTableData(rows);  
    //  props.handleAdd(rows);  
      handleClose('Products');
 
    }; 


   
  
   


    const handleClose = (modalname) => {
      switch (modalname) {
        case "Products":
          setOpen(false);
          break;
        case "ProductsOpen":
          setOpen(true);
          break;
        case "OrderModal":
          setOpenOrder(false);
          break;
        case "OrderOpen":
          setOpenOrder(true);
           break; 


        default:
          break;
      }
    }

    



  useEffect(() => {
   
    if (tblRef == null) return;
    if(props.bFirst){
    setManyTableData(props.ManyTableData);
    }
    setIsCompleted(props.IsCompleted);
    deletedDatas=[];
    setDeletedDataSet([]);  
  }, [props.ManyTableData,props.IsCompleted]); 

/*
  useEffect(() => {
   
    isDirty=props.dirty;
    
    onSetDisable();
    deletedDatas=[];
    setDeletedDataSet([]);  
  }, [props.dirty]);

  */
  
  useEffect(() => {
   
    if (tblRef != null && timeStamp != "") { 
      tblRef.table.scrollToRow(timeStamp, "center", true);
      tblRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

   
  const goToDetails = (rowData) => {

    let rowobj = rowData; //{};
     

    let timeStamp = Math.round(new Date().getTime() / 100);
    setTimeStamp(timeStamp);
    //let autoId=0;
    let autoId=tblRef.table.getData().length;

    

    //tblRef.table.addRow({name:"Billy Bob", age:"12", gender:"male", height:1}, true);
    
   let tabledata=(prevRows) => {
      autoId++;

       
      const tmpPrevRows =tblRef.table.getData();// [...prevRows];
      
      const max = tmpPrevRows.reduce((prev, current)=> ( (prev.StockTakeItemsId > current.StockTakeItemsId) ? prev : current),0) //returns object
     // console.log(max.StockTakeItemsId+1)


       
      let setRposition = tmpPrevRows.findIndex((o) => o.StockTakeItemsId ==rowobj.StockTakeItemsId);
     
      
      const obj = { ...rowobj };
 
       
      obj.ItemNo =rowData.ItemNo;
      obj.ItemCode =rowData.ItemCode;
      
      obj.ItemName =rowData.ItemName; 
      obj.StockTakeItemsId = max.StockTakeItemsId+1; 
      obj.SKU=autoId;
      obj.id=timeStamp;   

      obj.BatchNo = null;//rowData.BatchNo;
      obj.ExpiryDate =null;//rowData.ExpiryDate; 
       
      obj.StoreQty = null;
      obj.PhysicalQty = null;
      obj.AdjQuantity = 0;
      obj.Remarks=null; 


     
      obj.PurchasePrice = rowData.PurchasePrice;  
      obj.SalesPrice = rowData.SalesPrice; 
     
      obj.EAGTIN = rowData.EAGTIN;  
      obj.PKGTIN = rowData.PKGTIN;  
      obj.CAGTIN = rowData.CAGTIN;  
      obj.PLGTIN = rowData.PLGTIN;  
      obj.SSCCCarton = rowData.SSCCCarton;  
      obj.SSCCPallet = rowData.SSCCPallet;  
      obj.BrandNo = rowData.BrandNo;  
     
      obj.AMC = rowData.AMC; 
      obj.dirty = 0;   
      obj.GroupName = rowData.GroupName;
      obj.BrandCode = rowData.BrandCode; 
      obj.CommercialName = rowData.CommercialName; 
           
      tmpPrevRows.splice(setRposition+1, 0, obj);
    
          

      return tmpPrevRows;
    };
    setManyTableData(tabledata); 
   // CopyBatch(tabledata); 
    setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    //tabledata.scrollTo();




  };
  const CopyBatch = (tabledata) => {
    
    props.handleAdd(tabledata); 
    
  };
   
  function ActionButton (propss) { 
    
   
    
    const rowData = propss.cell._cell.row.data; 
 
    
  
  //  if((rowData.StockTakeItemsId==-1)||(rowData.StockTakeItemsId==null)){
  //   if (tblRef !== null) tblRef.table.selectRow((rowData.id));
  //  }
   


    
    const cellValue = propss.cell._cell.value || 'Edit | Show';
    
    if(props.IsCompleted == 1)
    
    return (
      <>
        
      </>
    );
    else{


      
      if((rowData.StockTakeItemsId==null))

      return (
        <>
        
          
           <FileCopy
          onClick={() => {
             goToDetails(rowData);
          }}
        /> 
        </>
      );
      else return (
        <>
       
         <DeleteOutline
            onClick={() => {

              propss.cell.getRow().delete();
              console.log(tblRef.table.getData());
              
              // setManyTableData(tblRef.table.getData());
              if((rowData.StockTakeItemsId!=null)||(rowData.StockTakeItemsId!=-1)){
                let newDeletedDataSet=[rowData.StockTakeItemsId]; 
                deletedDatas=newDeletedDataSet.concat(deletedDatas);
                isDirty=true;
                onSetDisable();
              //  setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));   
                //props.deleteReceiveInv(tblRef.table.getData(),newDeletedDataSet.concat(deletedDataSet)); 
              } 
   
              
              


             // deleteReceiveInv(rowData);
            }}
          />
           <FileCopy
          onClick={() => {
            
            goToDetails(rowData);
          }}
        /> 
        </>
      );



    } 
  }
  const scan_gtin_or_brandcode = useRef(null);
  const handleRefetchCall = () => {
   // props.setUpdatedDetailsGridDataHandler(tblRef.table.getData()); 
    props.refetch();
    scan_gtin_or_brandcode.current.value = "";


  };

  const doActionScanBarcode = () => {
    
  
    const ScanBarcodeParams = {
        TransactionTypeId: -111,
        StoreId: props.formData.StoreId,
        TransactionId: props.formData.StockTakeId,
        FacilityId: props.formData.FacilityId,
        ReceiveFromId: 0,
        AdjTypeId: 0,
        lan: lan,
        menukey: menukey,
        handleRefetchCall: handleRefetchCall,
        UserId:UserId,
        UserName:UserName,
        UserRole:UserRole
      }
  
    ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, {...props});
  };


 
const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};

/*
const handleChangeGrp = (e) => {
  props.filterHandleChange(e.target.value);
  setCurrItemGroupId(e.target.value);
};
*/

const handleProductGroupChange = (event, newValue) => {

  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currItemGroupId) {
    if(props.addProductForm==false){
    props.filterHandleChange(rowId);
    }
    setCurrItemGroupId(rowId); 
  }
};


const backToList = () => {
   if(masterDirty==true){


    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willAction) => {
      if (willAction) { 
        masterDirty=false;
        props.history.push("/initial-stock-configuration");

      } 
    });

  }else props.history.push("/initial-stock-configuration")
  
};

const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     masterDirty=true;
     onMasterSetDirty(); 
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

};

//const [FormStoreId, setFormStoreId] = useState({ id: '', name: "" });
const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   //console.log("On change ev",val.id);
  switch(cases){
    /*
    case 'AStoreId': 

      if(props.IsCompleted==0)
      setcurrStoreId(val.id); 
      setLoading(true);     
      props.StoreOnchangeFn(val.id);

      setFormStoreId(val.id);        
      props.formData.AStoreId= val != null?val.id:""; 
      break;
      */
    
    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;

    case 'ApprovedBy': 
      setApprovedBy(val);
      props.formData.ApprovedBy= val != null?val.id:"";
      break;  
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
  //setFormStoreId(AStoreList[AStoreList.findIndex(FormStoreList => FormStoreList.id == props.formData.AStoreId)]); 
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByApproveList => UsersListByApproveList.id == props.formData.ApprovedBy)]);    
}, [props.formData.PreparedBy, props.formData.ApprovedBy]);


  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

          <div className="sw_page_heading">
            <div className="sw_heading_title">{ DispensingLanguage[lan][menukey]['Initial Stock Configuration']+' ('+FacilityName+')' }</div>
          
          <div className="float-right sw_btn_control">

            {/* Action buttons */}
          {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">

                <div className="mr-4 float-left">
                  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                </div>

                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.handleReset()}
                >
                  { DispensingLanguage[lan][menukey]['Reset'] }
                </Button>
                <Button
                 // disabled={isLoadingSave}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleSubmit()}
                >
                  { DispensingLanguage[lan][menukey]['Save'] } 
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/initial-stock-configuration")}
                >
                  { DispensingLanguage[lan][menukey]['Back_to_List'] } 
                </Button>
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">

                <div className="mr-4 float-left">
                  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                </div>

                 <Button
                  className="mr-2"
                  variant="contained"
                  type="reset" 
                  onClick={() => backToList()}
                >
                  { DispensingLanguage[lan][menukey]['Back_to_List'] } 
                </Button>
 

                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={onSaveUpdateBtnClick}
                  //onClick={() => props.handleUpdate()}
                  //disabled={props.IsCompleted}
                  disabled={props.IsCompleted || isLoadingUpdate || isLoadingPost}
                >
                  { DispensingLanguage[lan][menukey]['update'] }  
                </Button>

                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  disabled={props.IsCompleted || isLoadingUpdate || isLoadingPost}
                  onClick={onPostBtnClick}
                >
                 { DispensingLanguage[lan][menukey]['Finish'] }    
                </Button>

               

              </Grid>


          }
           {/* End Action buttons */}

          </div>
          </div>
        </Grid>
      </div>


     

         

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
          <Grid item xs={12} sm={12}>
          
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                     

              <Grid item xs={2} sm={2}>
                <FormControl id="FID_AStoreId" className={classes.fullWidth} >
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Store"]}
                  </InputLabel>
                  <Select
                  
                 //   disabled={isDirty?true:false}
                    labelId="demo-simple-select-helper-label"
                    id="AStoreId"
                    name="AStoreId"
                    ref={AStoreIdRef}
                    value={currStoreId}
                    // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                   // inputProps={{ readOnly: true }}
                  >

                    {AStoreList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl id="FID_AStoreId" className={classes.fullWidth}>
                    <Autocomplete
                      ref={AStoreIdRef}   
                      autoHighlight
                      disableClearable
                      id="AStoreId"
                      options={AStoreList}  
                      onChange={(event, value) => changeVal(event,value,'AStoreId')} 
                      getOptionLabel={(option) => option.name}
                      value={FormStoreId}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.AStoreId}
                        label={DispensingLanguage[lan][menukey]["Store"] }
                        variant="standard"
                        error={props.errorObject.AStoreId}
                        helperText={props.errorObject.AStoreId}
                        required
                        name="AStoreId" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid> */}

                    <Grid item xs={3} sm={3}>

                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          required
                          error={props.errorObject.StockTakeDate}
                          helperText={props.errorObject.StockTakeDate}
                          id="StockTakeDate"
                          label= { DispensingLanguage[lan][menukey]['Start_Date'] } 
                          className={classes.fullWidth}
                         // disableToolbar
                          variant="inline"
                          autoOk={true}
                          name="StockTakeDate"
                          fullWidth
                          showTodayButton={true}
                          value={props.formData.StockTakeDate||props.selectedDate}
                          format="dd/MM/yyyy"
                         // onChange={(e) => props.handleRDateChange(e, "StockTakeDate")}
                          onChange={(e) =>formOnchange(e,'handleRDateChange','StockTakeDate')}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={props.IsCompleted}
                          disableFuture={true}
                        />
                  </MuiPickersUtilsProvider>


                    
                    </Grid>
                   



              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth} >
                  <InputLabel id="demo-simple-select-helper-label">
                  
                    {DispensingLanguage[lan][menukey]["Prepared_By"]} *
                  </InputLabel>
                  <Select
                    error={props.errorObject.PreparedBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="PreparedBy"
                    name="PreparedBy"
                    value={props.formData.PreparedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                    disabled={props.IsCompleted}
                  >
                    {UsersListByFacility.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={props.errorObject.PreparedBy}>
                    {props.errorObject.PreparedBy}
                  </FormHelperText>
                </FormControl>
              </Grid> */}

                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.IsCompleted}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Prepared_By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required                  
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  
                    {DispensingLanguage[lan][menukey]["Approved_By"]}
                  </InputLabel>
                  <Select
                    error={props.errorObject.ApprovedBy}
                   
                    labelId="demo-simple-select-helper-label"
                    id="ApprovedBy"
                    name="ApprovedBy"
                    value={props.formData.ApprovedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                    disabled={props.IsCompleted}
                  >
                  {UsersListByFacility.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={props.errorObject.ApprovedBy}>
                    {props.errorObject.ApprovedBy}
                  </FormHelperText>
                </FormControl>
              </Grid> */}


                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.IsCompleted}                
                      autoHighlight
                      disableClearable
                      id="ApprovedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={ApprovedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.ApprovedBy}
                        label={DispensingLanguage[lan][menukey]["Approved_By"] }
                        variant="standard"
                        error={props.errorObject.ApprovedBy}
                        helperText={props.errorObject.ApprovedBy}
                        required
                        name="ApprovedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>



            <Grid item xs={3} sm={3}>

                  <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          
                          error={props.errorObject.CompleteDate}
                          helperText={props.errorObject.CompleteDate}
                          id="CompleteDate"
                          label= { DispensingLanguage[lan][menukey]['End Date'] } 
                          className={classes.fullWidth}
                         // disableToolbar
                          variant="inline"
                          autoOk={true}
                          name="CompleteDate"
                          fullWidth
                          showTodayButton={true}
                          value={props.formData.CompleteDate||props.selecteEnddDate}
                          format="dd/MM/yyyy"
                          
                          //onChange={(e) => props.handleRDateChange(e, "CompleteDate")}
                          onChange={(e) =>formOnchange(e,'handleRDateChange','CompleteDate')}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={props.IsCompleted}
                          disableFuture={true}
                        />
                  </MuiPickersUtilsProvider>
                    

            </Grid>
            

            

                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="StockTakeId"
                        name="StockTakeId"
                        label="StockTakeId"  
                        value={props.formData.StockTakeId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />


                    </Grid>  
            
                  </Grid>
                </Grid>





                
                <Grid container spacing={3}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>

                        <Grid container className="mb-2" spacing={1}>

                          {/* <Grid item xs={2} sm={2}>
                            <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                              <InputLabel id="demo-simple-select-helper-label">
                                {DispensingLanguage[lan][menukey]['Product Group']}
                              </InputLabel>
                              <Select
                                  error={props.errorObject.ItemGroupId}
                                  labelId="demo-simple-select-helper-label"
                                  id="ItemGroupId"
                                  name="ItemGroupId"
                                  value={currItemGroupId}
                                  fullWidth
                                  onChange={(e) =>handleChangeGrp(e)}
                                  disabled={dirty?true:false}
                                >
                                <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                                {
                                  GroupList.map(
                                    (item, index) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Select>
                              <FormHelperText error={props.errorObject.ItemGroupId}>
                                {props.errorObject.ItemGroupId}
                              </FormHelperText>
                            </FormControl>

                          </Grid> */}

                            <Grid item xs={2} sm={2}>
                              <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                                  <Autocomplete
                                      autoHighlight
                                      className="sw_chosen_filter"
                                      id="ItemGroupId"
                                      disabled={props.addProductForm==true?true:false}
                                      disableClearable
                                      options={GroupList}               
                                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                                      getOptionLabel={(option) => option.name}
                                      defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                                      renderOption={(option) => (
                                        <Typography className="sw_fontSize">{option.name}</Typography>
                                      )}
                                      
                                      renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                                        variant="standard"
                                        name="ItemGroupId"
                                      />
                                      )}
                                    />                   
                                  </FormControl>
                              </Grid>

                              {/*
                                <Grid item xs={3} sm={3} id="FID_scan_gtin_or_brandcode">
                                <FormControl className={classes.fullWidth}>
                                  <TextField
                                    disabled={dirty?true:(props.addProductForm==true?true: props.IsCompleted) || isLoadingUpdate || isLoadingPost}
                                    
                                    id="scan_gtin_or_brandcode"
                                    name="scan_gtin_or_brandcode"
                                    label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                      
                                    autoComplete="family-name"
                                    ref={scan_gtin_or_brandcode}
                                    onKeyPress={handleKeyPress} 
                                    onKeyDown={handleKeyDown} 
                                    onKeyUp={handleKeyUp}
                                  />
                                </FormControl>

                              
                            </Grid>
                            

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button
                                disabled={(props.addProductForm==true?true: props.IsCompleted) || isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>

                            */}
                            
                            <Grid item xs={3} sm={3} id="FID_scan_gtin_or_brandcode">
                              <FormControl className={classes.fullWidth}>
                                <TextField
                                  disabled={(props.addProductForm==true?true: props.IsCompleted)|| isLoadingUpdate || isLoadingPost}
                                  
                                  id="scan_gtin_or_brandcode"
                                  name="scan_gtin_or_brandcode"
                                  label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                   
                                  autoComplete="family-name"
                                  ref={scan_gtin_or_brandcode}
                                  onKeyPress={handleKeyPress} 
                                  onKeyDown={handleKeyDown} 
                                  onKeyUp={handleKeyUp}
                                  
                                  
                                />
                              </FormControl>
                                      
                            </Grid> 
                          {/* <Grid item xs={4} sm={4} id="FID_scan_gtin_or_brandcode">
                           <div className="search_card">
                                <form>  
                                  <div className="search_control_group dnone" id="scan_sscc_hide_show">
                                    <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                                    <input disabled={props.addProductForm==true?true: props.IsCompleted} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                                  </div>
                                  <div className="search_control_group"  id="FID_scan_gtin_or_brandcode">
                                    <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                                    <input disabled={dirty?true:(props.addProductForm==true?true: props.IsCompleted) || isLoadingUpdate || isLoadingPost} type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" ref={scan_gtin_or_brandcode}     onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                                  </div>
                                  <div className="search_control_group" id="FID_scanButton">
                                    <Button
                                      disabled={(props.addProductForm==true?true: props.IsCompleted) || isLoadingUpdate || isLoadingPost}
                                      id="scanButton"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => onClickScanBarcode()}
                                    >
                                      {DispensingLanguage[lan][menukey]["Scan"]}
                                    </Button>
                                  </div>
                                </form>
                              </div> 
                            </Grid> */}

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button

                                disabled={(props.addProductForm==true?true: props.IsCompleted)|| isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>


                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                            <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                            <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                          </div> 

                            
                            <Grid item xs={4} sm={4}>
                              <div className="float-left"> 
                              <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                              </div>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <div className="float-right sw_btn_control"  id="FID_pick_items_btn"> 
                                 <Button
                                  disabled={(dirty?true:props.IsCompleted) || isLoadingUpdate || isLoadingPost}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("ProductsOpen")}
                                  id="pick_items"
                                >
                                  {DispensingLanguage[lan][menukey]['Add Products']}  
                                </Button>
                              </div>
                            </Grid>


                            <ProductsModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} /> 




                        </Grid> 
 


                          
                          
                        <div>
                          <div className="uniqueName">
                            <ReactTabulator 

                            ref={(r) => (tblRef = r)} 
                            columns={manycolumns}                    
                            data={manyTableData} 
                            
                            layout={"fitColumns"}
                            height="340px"
                            options={{
                              groupBy: "GroupName",
                              columnCalcs: "both",
                            }}
                            
                            

                            />


                          </div>
                        </div> 


                        </CardContent>
                      </Card>
                    </Grid>

                    
                  </Grid>
              </Grid>
          </Grid>


        
      </Grid>
    </div>
  </div>
  );
};

export default StockConfigurationFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  }

 
});
