import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import swal from "sweetalert";
// Products screen
import Products from "./StockStatus";
import ProductAdd from "./AddStockStatus";
import ProductEdit from "./EditStockStatus";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1=obj;
    if(obj.duration==undefined)
    obj1={...obj,duration:900}
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   // let token = Constants.token;
  //   //console.log(Constants.token);
  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //     sessionStorage.clear();
  //   }
  // };

  // const checkAccess = () => {
  //   // let auth = JSON.parse(sessionStorage.getItem("User_info"));
  //   let auth = Constants.USER_INFO;
  //   if (auth) {
  //     let menu = auth[0].menu;
  //     if (menu.length > 0) {
  //       var check = menu.filter((name) => name.title.includes("Admin"));
  //       if (check.length == 0) {
  //         props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  return (
    <div>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.productContainer}`}>
        {/* <Switch>
          <Route path={`${path}/add`} component={ProductAdd}></Route>
          <Route path={`${path}/edit/:id`} component={ProductEdit}></Route>
          <Route path={`${path}/`} component={Products}></Route>
        </Switch> */}

        <Switch>
          <Route
            path={`${path}/add`}
            render={(props) => (
              <ProductAdd {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/edit/:id`}
            render={(props) => (
              <ProductEdit {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/`}
            render={(props) => (
              <Products {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
        </Switch>

        <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>

      </div>
    </div>
  );
};

export default Index;

const useStyles = makeStyles({
  productContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
