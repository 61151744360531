import React, { useState, useEffect } from "react"; //, useEffect
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import DispenserFormData from "./AdjustmentFormData";

import Constants from "../../../services/Constants";

const AddAdjustment = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "adjustment-invoice";
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndDate, setselectedEndDate] = useState(new Date());
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    AdjTypeId: "",
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    TransactionTypeId: 3,
    PreparedBy:"",
    ApprovedBy:"",
    SupplierInvDate:selectedEndDate,
    StoreId:StoreId,
    bfacility:0,
    adjIssueToFacility:''
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [IssueToFacility, setIssueToFacility] = useState("");

  const handleRDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const handleADateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEndDateChange = (date) => {
    setselectedEndDate(date);
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  let params = {
    menukey: "adjustment-invoice",
    FacilityId: FacilityId,
    TransactionTypeId: 3,
    TransactionDate: "",
  };
  const { data: invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
  });

  /*
  const { isLoading, mutate } = useMutation(api.saveAdjustment, {
    onSuccess: (data) => {
      if (data.status == 200) {

        let route = `edit/${data.TransactionId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });
  */
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const updateIspositive = (isPosVal) => {
    setIsPositive(isPosVal);
  };

  const handleChange = (e) => {
    if(e=='IssuedToFacility'){
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else{
    
    const { name, value } = e.target;
    if (name == "AdjTypeId") {
      console.log("e.target");
      console.log(e.target);
    }

    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      AdjTypeId: "",
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      TransactionTypeId: 3,
      adjIssueToFacility: IssueToFacility,
      PreparedBy:"",
      ApprovedBy:"",
      SupplierInvDate:"",
      StoreId:StoreId,
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "AdjTypeId",
      "adjIssueToFacility",
      "PreparedBy",
      "ApprovedBy",
      "SupplierInvDate",
      "StoreId",
      
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  /*
  const handleSubmit = async (e) => {
    console.log("from submit ");
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: "adjustment-invoice",
        lan: lan,
        UserName: UserName,
      };

      mutate(fDtat);
    }
  };
  */

  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
  };

  /*

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "adjustment-invoice",
          lan: lan,
          UserName: UserName,
        };
        mutate(fDtat);
      } 
      setManyTableData(rows.data);
    }
  };

  */

  function checkValidateForm(rows, callfrom){

    //console.log("All fff",rows);

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows.data,"menukey":menukey,"lan":lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function setFormDataForAdjust(val){
    setFormData(val);
  };

  useEffect(() => {}, [isPositive]);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        handleADateChange={handleADateChange}
        selectedDate={selectedDate}
        selectedEndDate={selectedEndDate}
        deleteReceiveInv={deleteReceiveInv}
       // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={true}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        checkValidateForm={checkValidateForm}
        setFormDataForAdjust={setFormDataForAdjust}
        {...props}
      />
    </>
  );
};

export default AddAdjustment;
