import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import { Grid, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

import RcProductModal from "../../../services/RcProductModal";

//const UserInfo = JSON.parse(sessionStorage.getItem("User_info"));



const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const ShipmentStatus = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "rc-product-entry-form";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(15);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [ManyselectData, setManySelectData] = useState([]);


  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const {
    error1,
    isError1,
    data: limitData,
    refetch: limitRefetch,
  } = useQuery(
    [
      {
        action: "getTotal",
        menukey: menukey,
      },
    ],
    api.getRCProduct,
    {
      onSuccess: (data) => {

        if (data != undefined)
          setTotalRow(Math.ceil(data[0].TotalRow / numberRowPerPage));

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }, {

    useErrorBoundary: (error) => error.response?.status >= 500,

  }
  );




  let params = {
    action: "ShowDataList",
    menukey: menukey,
    page_limit: numberRowPerPage,
    page_number: pageNumber,
    search_key: "",
  };
  const {
    error,
    isError,
    data: uomData,
    refetch: tableData,
  } = useQuery([params], api.getRCProductList, {
    onSuccess: (data) => {
      // setTotalRow(data.length);
      // setTotalRow(Math.round(data.length/numberRowPerPage));
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });



  const { mutate: deleteApi } = useMutation(api.deleteRCProduct, {
    onSuccess: (data) => {

      // console.log(data);


      if (data.data.status == 200) {


        // api.getAllDropdown('Unitofmeasurelist').then((response) => {
        //   localStorage.setItem(
        //     "Unitofmeasurelist",
        //     JSON.stringify(response.datalist.Unitofmeasurelist)
        //   );
        // }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        tableData();
        queryClient.getQueriesData("rcproductentry");

        //swal(data.data.message, "", "success");


      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }



    },
    onError: (data) => {

      // console.log("vbdsjgf");
    }


  });


  const { isLoading, mutate:mutateUpdate } = useMutation(api.saveRCProduct, {
    onSuccess: (data) => {
      if (data.status == 200) {
 
        // setbStockUpdate(data.bStockUpdated);
        handleClose('Products');
        tableData();
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoadingLang, mutate: bCheckedUpdate } = useMutation(
    api.updateRCProduct,
    {
      onSuccess: (data) => {
        
        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
      
      
          }else{   
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
        }

      },
    }
  );

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=getRCProductEntryData" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode")+
      "&StoreId=" +
      2 
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
         break; 

     

      default:
        break;
    }
  }

  const addProduct = (NonOrderProductData) => {  



    // setManySelectData(NonOrderProductData);
    // let fDtat={'ManyJsonSave':ManyTableData}; 
    // mutateUpdate(fDtat); 
    
    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;
      // newRow.ItemCode =row.ItemCode;
      // newRow.ItemName =row.ItemName;

      // manyTableData.forEach((rowssss,ij) => {
       
      //   if(rowssss.ItemNo==row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;

      //   } 
      // });

      newRow.SKU=autoId;
      newRow.id=autoId;  
      rows.push(newRow);
    
  });

 
  if(rows.length>0){
    let fDtat={'ManyJsonSave':rows,UserId:UserId,lan:lan}; 
       mutateUpdate(fDtat); 
   
   }
//   dataItems.forEach((row,i) => {
//     autoId++;
//     let newRow={} ; 
//     newRow.ItemNo =row.ItemNo;
//     newRow.ItemCode =row.ItemCode;
//     newRow.ItemName =row.ItemName;
//     newRow.GroupName =row.GroupName;

//     newRow.SKU=autoId;
//     newRow.id=autoId; 
   
//     rows.push(newRow);

   
  
// }); 

    // dataItems(rows);   
    // props.handleAdd(rows); 
    // handleClose('Products');
  };




  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }




  let sl = 0;
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      // formatter: "rownum",
      formatter: function (cell, formatterParams) {
        sl++;
        return (pageNumber - 1) * numberRowPerPage + sl;
      },
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",

    },
    {
      title:  "",
      field: "id",
      width: 110,
      visible: false,
      headerSort: false,
    },
    { title: DispensingLanguage[lan][menukey]["Product Code"], headerFilter: true, width: 200, field: "ItemCode" },
    { title: DispensingLanguage[lan][menukey]["Product Name"], headerFilter: true, field: "ItemName" },
    {
      title: DispensingLanguage[lan][menukey]["bFree"],
      field: "bFree",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bFree"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.id;
        cellDataUpdatePop(id, cell);
      },
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Actions"],
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 130,
    //   headerSort: false,
    //   headerHozAlign: "center",
    //   formatter: reactFormatter(<ActionButton />),
    // },
  ];

  const cellDataUpdatePop = (id, cell) => {
    let curcellval = cell.getValue();
    let bCheckedVal = curcellval;
    bCheckedUpdate({ id, bCheckedVal });

  };




  const editunit = (data) => {
    let route = `shipment_status/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Data!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({ ShipmentStatusId: data.id, "menukey": menukey, "lan": lan, "UserName": UserName, "action": "dataDalete" });

      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        />
      </>
    );
  }

  const handleChange = (event, value) => {
    console.log("value: ", value);
    console.log("event: ", event);

    setPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    // setNumberRowPerPage(parseInt(event.target.value, 10));
    //console.log('event.target.value: ', event.target.value);
    // setPageNumber(value);
  };


  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title_inner">
                {" "}
                {
                  DispensingLanguage[lan][menukey]["RC Product Entry Form"]
                }{" "}
              </div>
              <div className="float-right sw_btn_control">

                <Button
                  // disabled={props.addProductForm == true ? true : props.bStockUpdate}
                  //disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  color="info"
                  onClick={() => handleClose("ProductsOpen")}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i><span>&nbsp;</span>
                  {DispensingLanguage[lan][menukey]['Add RC Product']}
                </Button>


                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>

              <RcProductModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} />

            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              layout={"fitColumns"}

            />
          </div>
          <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentStatus;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
