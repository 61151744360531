import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Notification from "../../../services/Notification";
import swal from "sweetalert";
// Products screen
import ProductsListBrand from "./ProductsListBrand";
import ProductListBrandView from "./ProductListBrandView";

// import FacilityList from "./FacilityList";
// import FacilityMap from "./FacilityMap";
// import FacilityView from "./ViewFacility";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  let auth =sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0; 
  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: "token expired. Please login again",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  

  if (RedirectLogin) {
   // checkLogin();
    //setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsgObj({ isOpen: false });
  };

  return (
    <div>
      {auth==0?<ExamplesNavbar {...props} />:<AfterLoginNavbar {...props} />}
        
      <div className={`section signup-top-padding ${classes.productContainer}`}>
        <Switch>

        <Route
           path={`${path}/brand-view/:id`}
            render={(props) => (
              <ProductListBrandView {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>

          <Route
            path={`${path}/`}
            render={(props) => (
              <ProductsListBrand {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>



        </Switch>




        <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>
      </div>
    </div>
  );
};

export default Index;

const useStyles = makeStyles({
  productContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
