import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const WarehouseInvoiceModal = ({ handleClose, open, addProduct, IssuedTo, FormData, InvoiceType, ...props }) => {

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receipts";

  let tblRef = null;
  let submitBtnRef = useRef();

  const [dataItems, setDataItems] = useState([]);
  const classes = useStyles();
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");
  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
  const [selectedProducts, setselectedProducts] = useState([]);
  

  let params = {
    menukey: menukey,
    action: "getWarehouseInvoiceList",
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan:lan,
    InvoiceType: InvoiceType,  
    IssuedTo : IssuedTo
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProductsList,{
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const ProductColumns = [
    { title: "id", field: "FacilityName", visible: false },
   
    { title: DispensingLanguage[lan][menukey]['SL'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Facility'], field: "FacilityName",  headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Warehouse_Invoice_No'], field: "TransactionNo", headerFilter:true  },
    { title: DispensingLanguage[lan][menukey]['Warehouse_Invoice_Date'], field: "TransactionDate", headerFilter:true },
  ];

  let selected = [];
  const ProdustDataItems = data?.map((item, index) => { 
    return item;
  });

  const {mutate} = useMutation(api.saveSelectedIssueInvoice, {
    onSuccess: (data)=>{

      //console.log("Inserted Datasssssssssssss",data);
  
      //addProduct(data);

      //let route = `/receipts/edit/${data.id}`;
      //props.history.push(route);




      if (data.status == 200) { 
        handleClose('WarehouseInvoiceClose');
        let route = `/receipts/edit/${data.id}`;
          props.history.push(route);
          props.refetch();  
        
        if(data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
            
                
         
         }else{

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          
         
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.msg,
          msgtype: 0,
        });

        handleClose('WarehouseInvoiceClose');
      }








      
      
     
    }
  })

  const SaveSelectedProducts = () => {
    
    if(tblRef.table.getSelectedData().length>0){
      if (submitBtnRef == null) return;
      else submitBtnRef.current.disabled = true;
      FormData['IssueTransactionId'] = tblRef.table.getSelectedData()[0].TransactionId;
      mutate(FormData);
    }
   
    
  };


  useEffect(() => { 
    if (tblRef == null) return;
   }, []); 


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('WarehouseInvoiceClose')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >


        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('WarehouseInvoiceClose')}
        >
        {DispensingLanguage[lan][menukey]['SELECT ISSUE INVOICE']} 
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            selectable={1}
            columns={ProductColumns}
            data={dataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            ref={submitBtnRef}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
           {DispensingLanguage[lan][menukey]['Submit']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('WarehouseInvoiceClose')}
          >
            {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default WarehouseInvoiceModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});