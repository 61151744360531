import {
   MapContainer,
    TileLayer, 
   Polygon , CircleMarker,
   Tooltip
  } from 'react-leaflet';

   //import 'leaflet/dist/leaflet.css'; 
  //import Control from 'react-leaflet-control'; 
  import statesData  from '../../../services/mapjsonfile/benin_regions.json';
  import React from 'react';

  const center = [9.314623823, 2.31184834];
  const fillBlueOptions = { fillColor: 'blue' }

const MapLeaflet = (props) => {
 
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  console.log(props.MapData);



    return (


        <MapContainer
        center={center}
        zoom={7}
        style={{ width: '100%', height: '740px' }} 
      >
        


{/* 
        <LayersControl position="topright">
            <LayersControl.Overlay name="Marker with popup">
              <LayerGroup>
                  <Circle
                    center={center}
                    pathOptions={{ color: 'green', fillColor: 'green' }}
                    radius={100}
                  />
                </LayerGroup>
                <CircleMarker 
                    center={center}
                    pathOptions={{ "fillColor": "#9071ce",
                    "fillOpacity":.7,
                    "color": "#744ec2",
                    "weight": 2,
                    "opacity": .8
                  }}
                    radius={20}
                    >  
                <Tooltip>Tooltip for CircleMarker</Tooltip>
              </CircleMarker> 
              
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Layer group with circles">
              <LayerGroup> */}
            <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='</a>
           <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; 
           OpenStreetMap contributors</a>'
        />



        {
          statesData.features.map((state) => {
            const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);


            return (<Polygon
              pathOptions={{ 
                fillColor: '#FEFEC7',
                fillOpacity: 0.7, 
                weight: 1,
                opacity: 1,
                dashArray: 3,
                color: 'gray' 
              }}
              positions={coordinates}
              eventHandlers={{
                mouseover: (e) => {
                  // const layer = e.target;
                  // layer.setStyle({
                  //   dashArray: "",
                  //   fillColor: "#BD0026",
                  //   fillOpacity: 0.7,
                  //   weight: 2,
                  //   opacity: 1,
                  //   color: "white",
                  // })
                },
                mouseout: (e) => {
                  // const layer = e.target;
                  // layer.setStyle({
                  //   fillOpacity: 0.7,
                  //   weight: 2,
                  //   dashArray: "3",
                  //   color: 'white',
                  //   fillColor: '#FD8D3C'
                  // });
                },
                // mouseover: (e) => {
                //   const layer = e.target;
                //   layer.setStyle({
                //     dashArray: "",
                //     fillColor: "#BD0026",
                //     fillOpacity: 0.7,
                //     weight: 2,
                //     opacity: 1,
                //     color: "white",
                //   })
                // },
                // mouseout: (e) => {
                //   const layer = e.target;
                //   layer.setStyle({
                //     fillOpacity: 0.7,
                //     weight: 2,
                //     dashArray: "3",
                //     color: 'white',
                //     fillColor: '#FD8D3C'
                //   });
                // },
                click: (e) => {

                }
              }}
            />)
          })




        }

        {/* </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Feature group">
              
            </LayersControl.Overlay>
    </LayersControl> */}

        {
           props.MapData.aaData!=undefined?
          props.MapData.aaData.map((state) => {



            console.log(state);
            let coordinates =[state.Latitude, state.Longitude];
            let radious=((state.TotalBalance*40)/props.MapData.maxValue);//state.MAX

            if(radious<5) radious=5;

            return (
              <CircleMarker 
              center={coordinates}
              pathOptions={{ "fillColor": "#9071ce",
              "fillOpacity":.7,
              "color": "#744ec2",
              "weight": 2,
              "opacity": .8
            }}
              radius={radious}
              >  
          <Tooltip>{state.District }<br></br><b>{DispensingLanguage[lan][menukey]["Quantity"]}: </b>{new Intl.NumberFormat().format(state.TotalBalance ) } </Tooltip>
        </CircleMarker>




             )




          }


          ):<></>

        }


      </MapContainer>
    );
    


};

export default MapLeaflet;