import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import SettingsEntryFormData from "./SettingsEntryFormData.js";


const AddSettingsEntry = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "settings-entry";
const FacilityId = localStorage.getItem("FacilityId");
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    TabMaster: "",
    SKey: "",
    SValue: ""
   
  });
  const [errorObject, setErrorObject] = useState({});

  const queryClient = useQueryClient();

  

  const { isLoading, mutate } = useMutation(api.saveSettingsEntry, {
    onSuccess: (data) => {

      if(data.status==200) {

        
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
      // queryClient.getQueriesData("userlist");
      props.history.push("/settings_entry");
  
      }else{
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }


      
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TabMaster: "",
      SKey: "",
      SValue: ""
    });
  };

  const validateForm = (formData) => {
   
    let validateFields = ["TabMaster","SKey","SValue"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =   DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

 

/*   const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      swal("Success!", "", "success");
    }
  }; */

  const handleSubmit = async (e) => {
   
    //console.log("from submit ", formData);
    if (validateForm(formData)) {
      
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
    }
  };

  return (
    <>
      <SettingsEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddSettingsEntry;
