import React, { useState, useEffect,useContext,Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import {Select, FormControl,Grid, Card,CardHeader,CardContent,MenuItem,InputLabel} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../services/Service.js";



import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { Button } from "reactstrap";



const FacilityDashboard = (props) => {

  

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility-dashboard";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;


  // Filter Start

  const [Regionlist, setRegionlist] = useState([]); //JSON.parse(localStorage.getItem("Regionlist"));
  let TempZoneList = useState([]); // JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  let TempCommuneList = useState([]); //JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);
  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [Region, setRegion] = React.useState(true);
  

  if(Region){
    setRegion(false);  
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist);      
        })
        .catch((err) => {});
  }
   

  
  const cZoneList = () => {  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}
    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
}


const cCommuneList = () => {  
  let CommuneParam = {action:"Communelist",RegionId:currRegionId,ZoneId:currZoneId,menukey:""}
  Service.default
      .postApi("source/combo_generic.php",CommuneParam)
      .then((res) => {
         setCommunelist(res.datalist); 
      })
      .catch((err) => {});
}
 

  
const handleRegionChange = (event) => { 
    setcurrRegionId(event.target.value);
 };

 const handleZoneChange = (event) => {
   setcurrCommuneId(0);
   let CommuneParam = {action:"gCommunelist",RegionId:currRegionId,ZoneId:event.target.value,menukey:""}
   cCommuneList(CommuneParam);
   setcurrZoneId(event.target.value);
   
 };

 const handleCommuneChange = (event) => {
   setcurrCommuneId(event.target.value);
 };
 
 

 React.useEffect(() => {
   setcurrZoneId(0); 
   cZoneList();
 }, [currRegionId]);
 React.useEffect(() => {
   setcurrCommuneId(0);
   cCommuneList();
 }, [currRegionId,currZoneId]);


 React.useEffect(() => {

 }, [currRegionId,currZoneId,currCommuneId]);

  // End Filter

  
  const [dataItems, setDataItems] = useState([]);
  
  const getNumberOfFacilityTypeTable = useQuery(
    ["FacilityDashboard111"],
    () => api.getNumberOfFacilityTypeTable(currRegionId),
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Type"],
      field: "FTypeName",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "TotalFacilityType",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    }
    
  ];


  /* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=FacilityByTypeExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() + "&RegionId=" + currRegionId
  );
};

  /* =====End of Excel Export Code==== */
  

  // Start Faciliy Type Pie
  const [option_FacilitiesByType, setOption_FacilitiesByType] = useState([]);

  const getFacilityTypeChart = useQuery(
    ["FacilitiesByType111"],
    () =>
      api.getNumberOfFacilityTypeChart(
        currRegionId
      ),
    {

    onSuccess: (data) => {
      
        setOption_FacilitiesByType({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 290,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        
        series: [
          {
            name: DispensingLanguage[lan][menukey]["Facilities"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,

  });

  // End Faciliy Type Pie



  



  useEffect(() => {
    getNumberOfFacilityTypeTable.refetch();
    getFacilityTypeChart.refetch();
   
  }, [currRegionId]);





  return (
    <>
     <AfterLoginNavbar {...props} />
     <div className={`section signup-top-padding ${classes.Container}`}>
      <div className={classes.PageTitle}>

 
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
               {DispensingLanguage[lan][menukey]["Facilities By Type"]}
              </div>
            </div>
          </Grid>
        </div>




        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              

              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RegionId"
                    name="RegionId"
                    value={currRegionId}
                    onChange={handleRegionChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                   {Regionlist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}



          {/* new row */}
          <div className="row">

          <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facilities By Type"]}
                />
                <CardContent>

                <div className="row">
                    <div className="col-md-12 mb-4">
                      
                      <div className="HChat">
                      
                        <HighchartsReact
                        highcharts={Highcharts}
                        options={option_FacilitiesByType}
                      />
                      </div>


                    </div>
                </div>
                  
                  
                </CardContent>
              </Card>  
            </div>

          </div>
          {/* end of row */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facilities By Type"]}
                  action={
                    <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                  }
                 
                />
                
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>  
            </div>
            
          </div>
          {/* end of row */}


          

        </div>
      </div>
    </>
  );
};



export default FacilityDashboard;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});