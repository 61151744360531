import React, { useState, useEffect,useContext,Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import {Select, FormControl,Grid, Card,CardHeader,CardContent,MenuItem,InputLabel} from "@material-ui/core";
import { Map, GoogleApiWrapper, Marker,InfoWindow  } from 'google-maps-react';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../services/Service.js";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import {
  Launch
 } from "@material-ui/icons";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { Button } from "reactstrap";



const FacilityDashboard = (props) => {

  const mapStyles = {
    width: '92%',
    height: '90%',
    overflow:"hidden !important",
  };

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility-dashboard";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [MapLatitudeLongitude, setMapLatitudeLongitude] = useState([]);
  const [MapDataTable, setMapDataTable] = React.useState(true);

  // For Map
  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    markerParams:{}
  }); 

  const onMarkerClick = (props, marker, e) =>{   
    console.log(props.params);
     setState({
      selectedPlace: props,
      markerParams: props.params,
      activeMarker: marker,
      showingInfoWindow: true
    })
  };

    const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        selectedPlace: {},
        showingInfoWindow: false,
        activeMarker: null,
        markerParams:{}
      });
    }
  };

  // Filter Start

  const [Regionlist, setRegionlist] = useState([]); //JSON.parse(localStorage.getItem("Regionlist"));
  let TempZoneList = useState([]); // JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  let TempCommuneList = useState([]); //JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);
  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [Region, setRegion] = React.useState(true);
  

  if(Region){
    setRegion(false);  
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist);      
        })
        .catch((err) => {});
  }
   

  
  const cZoneList = () => {  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}
    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
}


const cCommuneList = () => {  
  let CommuneParam = {action:"Communelist",RegionId:currRegionId,ZoneId:currZoneId,menukey:""}
  Service.default
      .postApi("source/combo_generic.php",CommuneParam)
      .then((res) => {
         setCommunelist(res.datalist); 
      })
      .catch((err) => {});
}
 

  let ParamList={
    menukey: menukey,
    action: "getMapLatitudeLongitudeForDashboard",
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId
   }


  const getFacilityData = () => { 
  
    setMapDataTable(false);
    Service.default
        .postApi("source/api/api_pages/facility_list.php",ParamList)
        .then((res) => {

         
         
          setMapLatitudeLongitude(res.datalist);
          
    
        })
        .catch((err) => {});
   }



const handleRegionChange = (event) => { 
    setcurrRegionId(event.target.value);
 };

 const handleZoneChange = (event) => {
   setcurrCommuneId(0);
   let CommuneParam = {action:"gCommunelist",RegionId:currRegionId,ZoneId:event.target.value,menukey:""}
   cCommuneList(CommuneParam);
   setcurrZoneId(event.target.value);
   
 };

 const handleCommuneChange = (event) => {
   setcurrCommuneId(event.target.value);
 };
 
 

 React.useEffect(() => {
   setcurrZoneId(0); 
   cZoneList();
 }, [currRegionId]);
 React.useEffect(() => {
   setcurrCommuneId(0);
   cCommuneList();
 }, [currRegionId,currZoneId]);


 React.useEffect(() => {
   getFacilityData();
 }, [currRegionId,currZoneId,currCommuneId]);

  // End Filter


  // End Map
  
  const [dataItems, setDataItems] = useState([]);
  const [TFacility, setTotalFacility] = useState(0);

  // const { refetch } = useQuery(
  //   "FacilityDashboard",
  //   api.getDepartmentWiseFacilityType,
  //   {
  //     onSuccess: (data) => {
  //       setDataItems(data);
  //     },
  //   }
  // );

  

  const getDepartmentWiseFacilityType = useQuery(
    ["FacilityDashboard111"],
    () => api.getDepartmentWiseFacilityType(currRegionId),
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
      
    }
  );


  const getTotalFacility = useQuery(
    ["FacilityCount111"],
    () => api.TotalFacility(currRegionId),
    {
      onSuccess: (data1) => {
        setTotalFacility(data1[0].Total_Facility);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
      
    }
  );


  // const { refetch1 } = useQuery(
  //   "FacilityCount",
  //   api.TotalFacility,
  //   {
  //     onSuccess: (data1) => {
  //       console.log("fff",data1[0].Total_Facility);
  //       setTotalFacility(data1[0].Total_Facility);
        
        
  //     },
  //   }
  // );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "RegionName",
      headerSort: false,
      width: 85,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "f",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 65,
    },
    {
      title: DispensingLanguage[lan][menukey]["Labos Fournisseurs"],
      field: "ff",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Officines de Pharmacie"],
      field: "fff",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Grossistes Répartiteurs"],
      field: "ffff",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Structure du MS"],
      field: "fffff",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    }
  ];


  /* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=DepartmentWiseFacilityTypeExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() + "&RegionId=" + currRegionId
  );
};

  /* =====End of Excel Export Code==== */



  const goToFacilitiesByType = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/facilities-by-type`
    );
 }

  const goToDepartmentWiseFacilityType = () => {
     window.open(
       process.env.REACT_APP_BASE_NAME +`/facilities-list`
     );
  }


  const goToFacilityClassification = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/facilities-by-classification`
    );
   }


   const goToFacilityOwnerType = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +`/facility-owner-type`
    );
   }

  

  // Start Faciliy Type Pie
  const [option_FacilitiesByType, setOption_FacilitiesByType] = useState([]);

  const getFacilityTypeChart = useQuery(
    ["FacilitiesByType111"],
    () =>
      api.getNumberOfFacilityTypeChart(
        currRegionId
      ),
    {

  //const { data:BrandByExpiryDate } = useQuery("FacilitiesByType", api.getNumberOfFacilityTypeChart, {
    onSuccess: (data) => {
      
        setOption_FacilitiesByType({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 400,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        /*
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        },
        */

        series: [
          {
            name: DispensingLanguage[lan][menukey]["Facilities"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,

  });

  // End Faciliy Type Pie



  // Start Facility Classification Pie
  const [option_FacilityClassification, setOption_FacilityClassification] = useState([]);

  const getFacilityClassification = useQuery(
    ["FacilityClassification"],
    () =>
      api.getFacilityClassificationChart(
        currRegionId
      ),
    {

  //const { data:FacilityClassification } = useQuery("FacilityClassification", api.getFacilityClassificationChart, {
    onSuccess: (data) => {
      
      setOption_FacilityClassification({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 390,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        /*
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        }, */
        series: [
          {
            name: DispensingLanguage[lan][menukey]["Facilities"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,

  });

  // End Facility Classification Pie



  // Start Owner Type Pie
  /* const [option_OwnerType, setOption_OwnerType] = useState([]);

  const getFacilityOwnerType = useQuery(
    ["FacilityOwnerType"],
    () =>
      api.getFacilityOwnerTypeChart(
        currRegionId
      ),
    {

  //const { data:OwnerType } = useQuery("OwnerType", api.getFacilityOwnerTypeChart, {
    onSuccess: (data) => {
      
      setOption_OwnerType({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 400,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        */
        /*
        plotOptions: {
          series: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,formatter: function () {
                 return Highcharts.numberFormat(this.y);
      
              }
            },
            tooltip: {
              shared:true
            }
          },
          pie: {					
            plotBorderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
              ,style: {
                textShadow: false,
                textOutline: false 
              }
              ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
            },
            showInLegend: true,
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
              shared:true
            }
          }
        },

        credits: {
          enabled: false,
        }, */
       /* series: [
          {
            name: DispensingLanguage[lan][menukey]["Facilities"],
            colorByPoint: true,
            data: data.series_data,
          },
        ],
      })
    },

  });

  */

  // End Owner Type Pie



  useEffect(() => {
    getDepartmentWiseFacilityType.refetch();
    getTotalFacility.refetch();
    getFacilityTypeChart.refetch();
    getFacilityClassification.refetch();
    //getFacilityOwnerType.refetch();
  }, [currRegionId]);





  return (
    <>
     <AfterLoginNavbar {...props} />
     <div className={`section signup-top-padding ${classes.Container}`}>
      <div className={classes.PageTitle}>

 
        <div className="d-flex justify-product">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
               {DispensingLanguage[lan][menukey]["Facility Dashboard"]}
              </div>
            </div>
          </Grid>
        </div>




        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              

              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RegionId"
                    name="RegionId"
                    value={currRegionId}
                    onChange={handleRegionChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                   {Regionlist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["ZS Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ZoneId"
                    name="ZoneId"
                    value={currZoneId}
                    onChange={handleZoneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All ZS"]}</MenuItem>

                   {Zonelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Commune Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="DistrictId"
                    name="DistrictId"
                    value={currCommuneId}
                    onChange={handleCommuneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Commune"]}</MenuItem>

                   {Communelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid> */}

              

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}











          {/* new row */}
          <div className="row">

          <div className="col-md-7 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Health Facilities"]}
                />
                <CardContent>

                <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="stat-cell bgInfo mb-2">
                        <i className="fa fa-hospital bg-icon"></i>
                        <span className="text-xlg" id="total-patients">
                          { TFacility }
                        </span>
                        <br></br>
                        <span id="totalcase" className="text-bg">
                        {DispensingLanguage[lan][menukey]["Total Number of Facility"]}
                        </span>
                      </div>
                      
                        
                      <div className="HChat">
                      <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facilities By Type"]}
                  action={
                    <a href="javascript:void(0);" className="gotoBtn" onClick={goToFacilitiesByType}><Launch/></a>
                    }
                /> 
                        <HighchartsReact
                        highcharts={Highcharts}
                        options={option_FacilitiesByType}
                      />
                      </div>


                    </div>
                </div>
                  
                  
                </CardContent>
              </Card>  
            </div>

            <div className="col-md-5 mb-4">
              

        <Card className="sw_card Map_Style">

        <CardHeader
          title={DispensingLanguage[lan][menukey]["Facility List in Map"]}
        />


          <CardContent>
            
          <Grid container>
          <Grid item xs={12} sm={12}>
      <Map 
        google={props.google}
        zoom={7}
        style={mapStyles}
        initialCenter={
          {
            lat: 9.314623823,
            lng: 2.31184834
          }
        }

      >
      {
        MapLatitudeLongitude.map((item, index) => {
          //console.log("Im UUUUUUUUUU",item.FTypeIcon);
          //let gIcon = item.FTypeIcon;
          /*
          let finalUrl = "";
          if(currRegionId==0){
            finalUrl = "http://localhost/epharmacie/src/assets/img/marker_icon.png";
         
          }else{
             finalUrl = "http://localhost/epharmacie/src/assets/img/"+item.FTypeIcon;
           }

           */


          return (
            <Marker 
            onClick={onMarkerClick} 
            params={item}
             position={{ lat: item.Latitude, lng: item.Longitude}}
             icon={{

              url: require("assets/img/marker_icon.png")  ,
              //url: finalUrl,
            //  anchor: { x: 16, y: 16 },
            //  scaledSize: { height: 16, width: 16 },  
      
             }}
           /> 
          );
        })

      }  
      
      <InfoWindow
          marker={state.activeMarker}
          visible={state.showingInfoWindow}
          onClose={onClose}
        >
          <div>

          <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Name"]}
                                  </TableCell>
                                  <TableCell >
                                  {state.markerParams.FacilityName}
                                  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                    align="left"
                                  >
                                   {DispensingLanguage[lan][menukey]["Commune Name"]}
                                  </TableCell>
                                  
                                  <TableCell > {state.markerParams.DistrictName}  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                    align="left"
                                  >
                                   {DispensingLanguage[lan][menukey]["ZS Name"]}
                                  </TableCell>
                                  <TableCell >  {state.markerParams.ZoneName} </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Department Name"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.RegionName} </TableCell>
                                   
                                </TableRow>

                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Type"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FTypeName} </TableCell>
                                   
                                </TableRow>

                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Address"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FacilityAddress} </TableCell>
                                   
                                </TableRow>


                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Phone"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FacilityPhone} </TableCell>
                                   
                                </TableRow>

                              </TableBody>
                             
                            </Table>
                          </TableContainer>

           
          </div>
        </InfoWindow>
      

      </Map>
      </Grid>   
         </Grid>       
              
          </CardContent>
        </Card>





            </div>
            
            



            



            
          </div>
          {/* end of row */}




            {/* new row */}
            <div className="row">

                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Facility Classification"]}
                      action={
                        <a href="javascript:void(0);" className="gotoBtn" onClick={goToFacilityClassification}><Launch/></a>
                        }
                    />
                  
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={option_FacilityClassification}
                      />
                    </CardContent>
                  </Card>  
                </div>

              { /*
                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Owner Type"]}
                      action={
                        <a href="javascript:void(0);" className="gotoBtn" onClick={goToFacilityOwnerType}><Launch/></a>
                        }
                    />
                  
                    <CardContent>
                      <HighchartsReact
                          highcharts={Highcharts}
                          options={option_OwnerType}
                      />
                    </CardContent>
                  </Card>  
                </div>
                */}



                <div className="col-md-6 mb-4">

                <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Department Wise Facility Type"]}
                  action={
                    <div className="float-right sw_btn_control">
 
                    
                    {/* <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Number of Facility']+": "+TFacility}</h4> */}
                

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                   
                  </div>

                
                  }

      
                />
                


                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card> 

                </div>





            </div>
            {/* end of row */}

         

          {/* new row */}


          {/*
          
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Department Wise Facility Type"]}
                  action={
                    <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                  }
                />
                


                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>  
            </div>
          </div>

          */ }
  
          {/* end of row */}

        </div>
      </div>
    </>
  );
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };

  // ...
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCD7OEdGUC1V__0-mBJIoYifI5UtEILYbg'
})(FacilityDashboard);
//export default FacilityMap;

//export default FacilityDashboard;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});