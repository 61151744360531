import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import { 
  Select,
  item,
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  useEventCallback,
  FormControlLabel,
  Checkbox,
 } from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch

} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";


const FacilitySyncStatus = (props) => {

  let TempZoneList = useState([]);
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start
  const [acValue, setACValue] = useState("");
  const FacilityId = localStorage.getItem("FacilityId");


  const [Initialize, setInitialize] = React.useState(false);
// console.log('Initialize Data',Initialize);

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currDepartmentName, setDepartmentName] = useState(DepartMentName[0].name);
  const [currZoneIdId, setZoneId] = useState(Zonelist[0].id);
  const [currZonename, setZonename] = useState(Zonelist[0].name);
  const [currCommuneId, setcurrCommuneId] = useState(Communelist[0].id);
  const [currCommuneame, setCommunename] = useState(Communelist[0].name);
  const [MosTypelist, setMosTypelist] = useState([]);

  const [acZoneValue, setACZoneValue] = useState({ id: Zonelist[0].id, name: Zonelist[0].name });
  const [acCommnueValue, setACCommnueValue] = useState({ id: Communelist[0].id, name: Communelist[0].name });
  const [isLoading, setLoading] = useState(false);


  // Department Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
      setDepartmentName(rowName);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setACZoneValue({ id: Zonelist[0].id, name: Zonelist[0].name });
          setZoneId(Zonelist[0].id);
          setZonename(Zonelist[0].name);
          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );



  // Zone Change
  const handleZoneChange = (event, newValue) => {
    setACZoneValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);
      setZonename(rowName);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setACCommnueValue({ id: Communelist[0].id, name: Communelist[0].name });
          setcurrCommuneId(Communelist[0].id);
          setCommunename(Communelist[0].name);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setACCommnueValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;

    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setCommunename(rowName);

    }
  };


  const FlevelList_Name = JSON.parse(localStorage.getItem("FlevelListForReport"));
  let All_FlevelList_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FlevelList = [All_FlevelList_Name].concat(FlevelList_Name);

  const [currFlavelId, setCurrFacilitylist] = useState(FlevelList[0].id);
  const [FacilityLavel, setFacilityLavel] = useState([]);

  // handleFacility Level Change
  const handleFacilityLevelChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currFlavelId) {
      setCurrFacilitylist(rowId);
      setFacilityLavel(rowName);
    }
    let FacilityParam = { action: "getSyncStatusFacility",
    RegionId:currDepartmentId,
    ZoneId:currZoneIdId,
    DistrictId:currCommuneId,
    FTypeId:currFTypelId,
    FLevelId:rowId,
     lan:lan, menukey: "" }
    cFacilityList(FacilityParam);
  };


  React.useEffect(() => {
    if (currFlavelId == FlevelList[0].id) {
      setFacilityLavel(FlevelList[0].name);
    }
  }, [currFlavelId]);


  const FType_List = JSON.parse(localStorage.getItem("FTypeList"));
  let All_FType_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FTypeList = [All_FType_List].concat(FType_List);

  const [currFTypelId, setFTypeId] = useState(FTypeList[0].id);
  const [FacilityType, setFacilityType] = useState([]);

    const handleFTypeChange = (event, newValue) => {
      let rowId = '';
      let rowName = '';
      if (newValue == null) {
        rowId = '';
        rowName = '';
      } else {
        rowId = newValue.id;
        rowName = newValue.name;
      }
      if (rowId !== currFTypelId) {
        setFTypeId(rowId);
        setFacilityType(rowName);
      }
      let FacilityParam = { action: "getSyncStatusFacility",
      RegionId:currDepartmentId,
      ZoneId:currZoneIdId,
      DistrictId:currCommuneId,
      FTypeId:rowId,
      FLevelId:currFlavelId,
       lan:lan, menukey: "" }
      cFacilityList(FacilityParam);
    };



  const { mutate: cFacilityList } = useMutation(
    api.getSyncStatusFacilityList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setFacilityData(data.data.datalist);
          setACValue({ id: '0', name: "All"});
        }
      },
    }
  );


  const [Facility_list, setFacilityData] = useState(TempZoneList);
  let All_FacilityList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityListDataItem = [All_FacilityList].concat(Facility_list);
  // console.log("get Data",FacilityListDataItem);
  const [currFacilityId, setFacilityId] = useState("0");
  const [FacilityName, setFacilityName] = useState([]);


  const handleFacilityListChange = (event, newValue) => {
    setACValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName='';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currFacilityId) {
      setFacilityId(rowId);
      setFacilityName(rowName);
      
    }
  };


React.useEffect(() => {
  if(currFacilityId=='0'){
    let FacilityParam = { action: "getSyncStatusFacility",
      RegionId:currDepartmentId,
      ZoneId:currZoneIdId,
      DistrictId:currCommuneId,
      FTypeId:currFTypelId,
      FLevelId:currFlavelId,
       lan:lan, menukey: "" }
      cFacilityList(FacilityParam);
  }
    
  }, [currFacilityId]);

  const handlesetInitializeChange = (event) => {
    setInitialize(event.target.checked);
    setLoading(true);
   // setCompleteManufacturer(event.target.checked);
  };



  const [dataItems, setDataItems] = useState([]);
  let params = {
    menukey: menukey,
    action: "ESIGLFacilitySyncStatus",
    lan: lan,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    RegionId:currDepartmentId,
    ZoneId:currZoneIdId,
    DistrictId:currCommuneId,
    FTypeId:currFTypelId,
    FLevelId:currFlavelId,
    FacilityId:currFacilityId,
    bInitialize:Initialize,

  };

  const { data } = useQuery(
    [params],
    api.getFacilitySyncStatus,
    {
      onSuccess: (data) => {
        setDataItems(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: true,
    },


    {
      title: DispensingLanguage[lan][menukey]["Device Id"],
      field: "DeviceId",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width:80,
    },
    {
      title: DispensingLanguage[lan][menukey]["Device Type"],
      field: "DeviceType",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      // headerFilter: true,
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Device_Date"],
      field: "DeviceRegisterDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      // headerFilter: true,
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Meta_Sync_Start_Date_Time"],
      field: "MetaDataDownStartDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Meta_Sync_End_Date_Time"],
      field: "MetaDataDownEndDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sync_Start_Time"],
      field: "SyncStartDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sync_End_Time"],
      field: "SyncEndDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Level"],
      field: "FLevelName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "RegionName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Vesion"],
      field: "softwareVersion",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 80,
    },


  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=SyncStatusFacilityList" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneIdId +
      "&DistrictId=" +
      currCommuneId +
       "&ProvinceName=" +
       currDepartmentName +
       "&ZoneName=" +
       currZonename +
       "&BCZName=" +
       currCommuneame +
       "&FTypeId=" +
       currFTypelId +
       "&FLevelId=" +
       currFlavelId +
       "&FacilityId=" +
       currFacilityId +
       "&bInitialize=" +
        Initialize +
       "&FacilityLavel=" +
       FacilityLavel +
       "&FacilityType=" +
       FacilityType +
       "&FacilityName=" +
       FacilityName +

      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Facility Sync Status"])}
                </div>
                
              </div>
            </Grid>
          </div>

        {/* start of filter */}
                <Card className="sw_card sw_filter_card">
                   <CardContent className="sw_filterCardContent">
                     <Grid container>
                       <Grid item xs={12} sm={12}>
                         <Grid container spacing={1}>
                             <Grid item xs={12} sm={3}>
                             <FormControl className={classes.fullWidth}>
                               <Autocomplete
                                 autoHighlight
                                 className="sw_chosen_filter"
                                 id="DepartMentName"
                                 disableClearable
                                 options={DepartMentName}
                                 // disabled={UserRole == 1 ? false : true}
                                 onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                                 getOptionLabel={(option) => option.name || ""}
                                 defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                                 renderOption={(option) => (
                                   <Typography className="sw_fontSize">{option.name}</Typography>
                                 )}
       
                                 renderInput={(params) => (
                                   <TextField
                                     {...params}
                                     label={DispensingLanguage[lan][menukey]["Department Name"]}
                                     variant="standard"
       
                                   />
                                 )}
                               />
                             </FormControl>
                           </Grid>
       
                           <Grid item xs={12} sm={3}>
                             <FormControl className={classes.fullWidth}>
                               <Autocomplete
                                 autoHighlight
                                 className="sw_chosen_filter"
                                 id="Zonelist"
                                 disableClearable
                                 options={Zonelist}
                                 value={acZoneValue}
                                 onChange={(event, newValue) => handleZoneChange(event, newValue)}
                                 getOptionLabel={(option) => option.name || ""}
                                 // defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                                 renderOption={(option) => (
                                   <Typography className="sw_fontSize">{option.name}</Typography>
                                 )}
       
                                 renderInput={(params) => (
                                   <TextField
                                     {...params}
                                     label={DispensingLanguage[lan][menukey]["ZS Name"]}
                                     variant="standard"
       
                                   />
                                 )}
                               />
                             </FormControl>
                           </Grid>
       
                           <Grid item xs={12} sm={3}>
                             <FormControl className={classes.fullWidth}>
                               <Autocomplete
                                 autoHighlight
                                 className="sw_chosen_filter"
                                 id="Communelist"
                                 disableClearable
                                 options={Communelist}
                                 value={acCommnueValue}
                                 onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                                 getOptionLabel={(option) => option.name || ""}
                                 // defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                                 renderOption={(option) => (
                                   <Typography className="sw_fontSize">{option.name}</Typography>
                                 )}
       
                                 renderInput={(params) => (
                                   <TextField
                                     {...params}
                                     label={DispensingLanguage[lan][menukey]["Commune Name"]}
                                     variant="standard"
       
                                   />
                                 )}
                               />
                             </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                               <FormControl className={classes.fullWidth}>
                                 <Autocomplete
                                   autoHighlight
                                   className="sw_chosen_filter"
                                   id="FlevelList"
                                   disableClearable
                                   options={FlevelList}
                                   onChange={(event, newValue) => handleFacilityLevelChange(event, newValue)}
                                   getOptionLabel={(option) => option.name || ""}
                                   defaultValue={FlevelList[FlevelList.findIndex(FlevelList => FlevelList.id == currFlavelId)]}
                                   renderOption={(option) => (
                                     <Typography className="sw_fontSize">{option.name}</Typography>
                                   )}
       
                                   renderInput={(params) => (
                                     <TextField
                                       {...params}
                                       label={DispensingLanguage[lan][menukey]["Facility Level"]}
                                       variant="standard"
       
                                     />
                                   )}
                                 />
                               </FormControl>
                           </Grid>
                         </Grid>
                       </Grid>
                     

                      <Grid container className={classes.Padding_Button}>
                          <Grid item xs={12} sm={3}>
                              <FormControl className={classes.fullWidth}>
                                <Autocomplete
                                  autoHighlight
                                  className="sw_chosen_filter"
                                  id="FTypeList"
                                  disableClearable
                                  options={FTypeList}
                                  onChange={(event, newValue) => handleFTypeChange(event, newValue)}
                                  getOptionLabel={(option) => option.name || ""}
                                  defaultValue={FTypeList[FTypeList.findIndex(FTypeList => FTypeList.id == currFTypelId)]}
                                  renderOption={(option) => (
                                    <Typography className="sw_fontSize">{option.name}</Typography>
                                  )}
    
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={DispensingLanguage[lan][menukey]["Facility Type"]}
                                      variant="standard"
    
                                    />
                                  )}
                                />
                              </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                              <FormControl className={classes.fullWidth}>
                                <Autocomplete
                                autoHighlight
                                className="sw_chosen_filter"
                                id="FacilityListDataItem"
                                disableClearable
                                options={FacilityListDataItem}
                                value={acValue}
                                onChange={(event, newValue) => handleFacilityListChange(event, newValue)}
                                getOptionLabel={(option) => option.name || ""}
                                defaultValue={FacilityListDataItem[FacilityListDataItem.findIndex(FacilityListDataItem => FacilityListDataItem.id == currFacilityId)]}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
    
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={DispensingLanguage[lan][menukey]["Facility"]}
                                    variant="standard"
    
                                  />
                              )}
                              />
                              </FormControl>
                        </Grid>

                        <Grid item xs={3} sm={3} className={classes.Padding_checkbox}>
                          <FormControlLabel 
                            control={
                              <Checkbox
                                color="primary"
                                name="bInitializeFilter"
                                checked={Initialize} 
                                onChange={handlesetInitializeChange}
                                //value="no"
                              />
                            }
                            label={DispensingLanguage[lan][menukey]["Initialize"]}
                          />
                        </Grid>


                      </Grid>             
                 </Grid>
               </CardContent>
           </Card>
    {/* end of filter */}

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["Facility Sync Status List"])}

                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilitySyncStatus;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  Padding_Button:{
    marginTop:'10px'
  },
  Padding_checkbox:{
    marginTop:'18px'
  },
});
