import React, { useState, useEffect,useContext } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";

import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from "@material-ui/core";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import * as Service from "../../services/Service.js";
import Constants from "services/Constants.js";

import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";



const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },

  'tabulator-cell': {
    padding: '1px'
}


});


const DashboardPage = (props) => {

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">Dashboard</div>
            </div>
          </div>
 
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
