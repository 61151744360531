import React from "react";
import "assets/css/custom.css";

const FacilityReportingRate = ({ ReportingLabelList, ReportingValueList, lan, menukey }) => {
  //get Language
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));

  return (
      <>
      <div className='reportingRate'>
        <span className='report_title'>{DispensingLanguage[lan][menukey]["Reporting Rate"]}</span>
        <span className='report_title_val'>{DispensingLanguage[lan][menukey]["Total"]}: <span>{ReportingValueList['Total']}%</span></span>
        <span className='report_title_val'>{ReportingLabelList[99]}: <span>{ReportingValueList['HealthFaclilities']}%</span></span>
        <span className='report_title_val'>{ReportingLabelList[3]}: <span>{ReportingValueList['DistrictWarehouse']}%</span></span>
        <span className='report_title_val'>{ReportingLabelList[2]}: <span>{ReportingValueList['RegionalWarhouse']}%</span></span>
        <span className='report_title_val'>{ReportingLabelList[1]}: <span>{ReportingValueList['CentralWarehouse']}%</span></span>
      </div>
      </>
  );
};

export default FacilityReportingRate;
