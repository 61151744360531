import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

 
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import ProductsModal from "../../../services/ProductsModal";
import ProductsLotModal from "../../../services/ProductsLotModal";

import * as ScanBarcode from '../../../services/ScanBarcode';
import { cellFocusEditor, dateEditor } from "../../../services/Common";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import * as api from '../../../actions/api';
import swal from "sweetalert";


let isDirty=false;
let deletedDatas=[];
let transactionItemID =0;
let masterDirty=false;

const DispenserFormData = (props) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-invoice";
  const FacilityId=localStorage.getItem('FacilityId');
  const FacilityName=localStorage.getItem('FacilityName');

  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  const UserList =JSON.parse(localStorage.getItem("UserList"));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null; 
  let adjId = null;

  

  

  const [hasTimeElapsed, setHasTimeElapsed] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [openLot, setOpenLot] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [bIsPositive, setbIsPositive] = useState();  
  const [IssueToFacility, setIssueToFacility] = useState([]);
  const [swipeIssueToFacility, setswipeIssueToFacility] = useState([]); 
  const [IssueToFacilityVal, setIssueToFacilityVal] = useState(false); 
  const [firstIssueToFacility, setfirstIssueToFacility] = useState(''); 
  const [currentAdjType, setcurrentAdjType] = useState('');  
  
  
  const [bDisableAddProduct, setDisableAddProduct] = useState(props.DisableAddBtn);
  //const AdjType =JSON.parse(localStorage.getItem("gAdjustmentTypeByFacility"));
  const [AdjType, setAdjType] = useState([]); 

  const [SoureOpen, setSoureOpen] = useState(false); 
  const [currRowId, setCurrRowId] = useState(0);

  // Store
  const StoreId = localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const StoreList = JSON.parse(localStorage.getItem("getStore"));

  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);
  
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const userid = UserInfo == 0 ? "" : UserInfo[0].id;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  

  //console.log(UserInfo);

/* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_adjustment_invoice_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=" + currStoreId
     + "&TimeStamp=" + Date.now()
     + "&isPositive=" + props.isPositive
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_adjustment_invoice_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=" + currStoreId
     + "&TimeStamp=" + Date.now()
     + "&isPositive=" + props.isPositive
  );
};

/* =====End of Excel Export Code==== */
  
  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        setOpenLot(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
       
      case "LotOpen":
        setOpenLot(true);
        break;
        

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {

    let countrs=ReceiveDetailsRef.table.rowManager.displayRowsCount;//ReceiveDetailsRef.table.rowCount(); 

    if( bIsPositive){ 
      
      let rowsNotInGrid = [];
      
      NonOrderProductData.map((row, i) => {
        row.TransactionItemId = null; 
        row.Quantity = 0; 
        row.IsPositive = -1; 
        row.SKU = i + manyTableData.length+1+props.deleteReceiveInv.length;
      });


      // if (props.ManyTableData.length > 0) {
      //   rowsNotInGrid = NonOrderProductData.filter((obj1) => {
        
      //       return !props.ManyTableData.some((obj2) => (obj1.BatchNo === obj2.BatchNo && obj1.ExpiryDate === obj2.ExpiryDate 
      //         && obj1.ItemCode === obj2.ItemCode && obj1.BrandNo === obj2.BrandNo && obj1.EAGTIN === obj2.EAGTIN 
      //         && obj1.PKGTIN === obj2.PKGTIN && obj1.CAGTIN === obj2.CAGTIN && obj1.PLGTIN === obj2.PLGTIN
      //         && obj1.SSCCCarton === obj2.SSCCCarton && obj1.SSCCPallet === obj2.SSCCPallet ));
          
      //   });
      // } else {
        
      // }
      
      
      setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":NonOrderProductData,'deleteddata':deletedDatas});
    
      handleClose("Products");
    
  }else{

      let rows = [];
      let autoId=0;
       

      NonOrderProductData.forEach((row,i) => {
          autoId++;
          let newRow={} ;
          newRow.ItemNo =row.ItemNo;
          
          newRow.ItemCode =row.ItemCode;
        
          newRow.ItemName =row.ItemName;
          newRow.UnitPrice =row.UnitPrice;

          newRow.TransactionItemId = null; 
          newRow.CurrentStockQty = 0;
          newRow.Quantity =0;
          newRow.BatchNo = null;
          newRow.ExpiryDate = null;

          // Brand Info
          newRow.BrandNo =row.BrandNo;
          newRow.BrandCode =row.BrandCode;
          newRow.CommercialName =row.CommercialName;
          newRow.EAGTIN =null;
          newRow.PKGTIN =null;
          newRow.CAGTIN =null;
          newRow.PLGTIN =null;
          newRow.SSCCCarton =null;
          newRow.SSCCPallet =null;

          

          newRow.SKU=autoId; 
          newRow.id=autoId;  
          newRow.LineTotal=0; 
          rows.push(newRow);
        
      });
      setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":rows,'deleteddata':deletedDatas});
     
      
      handleClose("Products");

  }
};


const cellFocusEditorSerial2 = function(cell, onRendered, success, cancel, editorParams){

  const currow = cell.getRow();
  const rowdata = currow.getData();
  const bSerialSupported = rowdata.bSerialSupported;
  console.log("bSerialSupported",bSerialSupported);
  if(props.formData.AdjTypeId==11 && ((bSerialSupported==1)||(bSerialSupported==2))){
    return cell.getValue();
  }
  
  //create and style input
  let input = document.createElement("input");

  input.setAttribute("type", "text");

  input.style.padding = "4px";
  input.style.width = "100%";
  input.style.boxSizing = "border-box";

  input.value = cell.getValue()==''?null:cell.getValue();

  // if (cell.getValue() != undefined)
  // input.value = null;
  // else
  // input.value = cell.getValue();


  onRendered(function () {
      input.focus();
      input.select();
      input.style.height = "100%";
  });

  function successFunc(){
    success(input.value);
  }

  function keyHandlerFunc(e){
    if (e.keyCode == 27)
      cancel(input.value);
    //console.log(e);
  }

  //submit new value on blur or change
  input.addEventListener("change", successFunc);
  input.addEventListener("blur", successFunc);
  input.addEventListener("keyup", keyHandlerFunc);

  return input;
};

  const ReceiveDetailsColumns = [
    { title: "id", field: "SKU", visible: false },
    { title: "id", field: "TransactionItemId", visible: false },
    { title: "bSerialSupported", field: "bSerialSupported", visible: false }, 
    {
      title: "GroupName",
      field: "GroupName",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },    
    {
      title: DispensingLanguage[lan][menukey]['SL'],
      width: 55,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      //visible: bStockUpdate == 0 ?true:false,
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 60,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]['Product_Code'],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
    },
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName", headerFilter: true,width: 240 },
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 180 ,headerFilter:true},
    { title: DispensingLanguage[lan][menukey]['Brand Name'], field: 'CommercialName',width:180,headerFilter:true,},
    {
      title: DispensingLanguage[lan][menukey]['Current_stock_quantity'],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      visible:bIsPositive
    },
    {
      title: DispensingLanguage[lan][menukey]['Quantity_adjusted'],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0", function (cell, value, parameters) {
 
        if (value == "") return true;
        if (!props.isPositive) return true;
        const balance = cell.getRow().getCell('CurrentStockQty').getValue();
        const validstatus = parseInt(value) <= parseInt(balance);  
        return validstatus; 
      }],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditorSerial2 : "",
      cellEdited: function (cell) {
        
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal"); 
        var UnitPrice = rowdata.UnitPrice;
        if(UnitPrice==undefined){
        UnitPrice=0;
        }

        const totprice = UnitPrice * curcellval;
        pricecell.setValue(totprice);
         
        currow.getCell("dirty").setValue(1);  
        
       isDirty=true;
       transactionItemID=rowdata.id;
       onSetDisable(); 
      
      },
    }, //
    {
      title: DispensingLanguage[lan][menukey]['Lot_No_Description'],
      field: "BatchNo",
      width: 100,
      headerFilter: true,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
  
         
        currow.getCell("dirty").setValue(1);  
        
       isDirty=true;
       transactionItemID=rowdata.id;
       onSetDisable(); 
      
      },
    },
    {
      title: DispensingLanguage[lan][menukey]['Expiry_Date'],
      field: "ExpiryDate",
      width: 100,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: dateEditor)  : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
       // props.setUpdatedDetailsGridDataHandler(rowdata);
       
       currow.getCell("dirty").setValue(1); 
      
       isDirty=true;
       transactionItemID=rowdata.id;
       onSetDisable(); 
      },
    },

    {
      title: DispensingLanguage[lan][menukey]['EAGTIN'],
      field: "EAGTIN",
      width: 120,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); 
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();  
      },
    },

    {
      title: DispensingLanguage[lan][menukey]['PKGTIN'],
      field: "PKGTIN",
      width: 115,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
       
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();  
      },
    },

    {
      title: DispensingLanguage[lan][menukey]['CAGTIN'],
      field: "CAGTIN",
      width: 115,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();  
        currow.getCell("dirty").setValue(1);  
      },
    },

    {
      title: DispensingLanguage[lan][menukey]['PLGTIN'],
      field: "PLGTIN",
      width: 115,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
       
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable(); 
      },
    },

    {
      title: DispensingLanguage[lan][menukey]['SSCCCarton'],
      field: "SSCCCarton",
      width: 125,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
          
        currow.getCell("dirty").setValue(1);  
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();
      },
    },


    {
      title: DispensingLanguage[lan][menukey]['SSCCPallet'],
      field: "SSCCPallet",
      width: 115,
      headerFilter: true,
      cssClass: props.bStockUpdate==0?"tabluator-column-editable text-field-editable":'',
      editor: props.bStockUpdate == 0 ? (props.isPositive?false: cellFocusEditor)   : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
        currow.getCell("dirty").setValue(1);  
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable(); 
      },
    },
    
    {
      title: DispensingLanguage[lan][menukey]['Unit Price (CFA)'],
      field: "UnitPrice",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      //validator: ["float", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: bStockUpdate == 0 ?  (props.isPositive?false: cellFocusEditor)  : "",
     
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.Quantity * curcellval;
        pricecell.setValue(totprice);
       
        currow.getCell("dirty").setValue(1); 
        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();   
      }
      




    },
    {
      title: DispensingLanguage[lan][menukey]['Line Total (CFA)'],
      field: "LineTotal",
      width: 110,
      formatter: "money",
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
    },
   
  ]; 

 
  const mutateCellUpdate=(fDtat) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/receiveUpdate";
      let data=fDtat; 
        fetch(apiUrl, {
          method: "PUT", 
          headers: { 
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
    
          console.log(data);
          isDirty=false; 
          onSetDisable();  
        })
        .catch(error => { 
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
          

         });
    
    
     }
  const onSetDisable = () => { 
    
       if(isDirty==true){ 
        
          
         document.getElementById("FID_scanButton").classList.add("swDisabled");
         document.getElementById("is_dirty_message").classList.remove("dnone");
         document.getElementById("FID_scan_gtin_or_brandcode").classList.add("swDisabled");
         document.getElementById("FID_ItemGroupId").classList.add("swDisabled");
         document.getElementById("FID_pick_items_btn").classList.add("swDisabled");
         
          let dataArray=[];
          let gridData=ReceiveDetailsRef.table.getData();
          gridData.forEach((row,i) => { 

            if(row.id==transactionItemID) {
              dataArray.push(row);
              transactionItemID=0;
            }


          });
          let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan, UserName:UserName};  
          mutateCellUpdate(fDtat);


          
      
         
       }
       else{ 
        document.getElementById("FID_scanButton").classList.remove("swDisabled");
        document.getElementById("is_dirty_message").classList.add("dnone");
        document.getElementById("FID_scan_gtin_or_brandcode").classList.remove("swDisabled"); 
        document.getElementById("FID_ItemGroupId").classList.remove("swDisabled");
        document.getElementById("FID_pick_items_btn").classList.remove("swDisabled");
        
        
       }
       
  
       
    }



const onMasterSetDirty = () => {   
  if(masterDirty==true){ 
    props.formData.masterDirty=masterDirty;    
    document.getElementById("master_dirty_message").classList.remove("dnone");    
  }else{ 
    props.formData.masterDirty=masterDirty;  
    document.getElementById("master_dirty_message").classList.add("dnone");
  }
}


  const onPostBtnClick = () => {
    setbStockUpdate(true);
    let dataArray=[];
    let gridData=ReceiveDetailsRef.table.getData();
    gridData.forEach((row,i) => { 

      if(row.id==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }

    })

    handlePost({"datatype":"TableData","data":dataArray,"deletedDataSet":deletedDatas});
    
  };

  const handlePost = async (rows,deletedsetdata) => {  

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do_you_really_want_to_post_the_stock"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          
          let FinalData={...validForm,'ManyJsonSave':rows.data,"bStockUpdated":1,'deletedDataSet':rows.deletedsetdata,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows.data);
 
        }else{
  
          props.stockPosted(0);
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
    }
      
     
     
  };

  
  const onSaveUpdateBtnClick = () => { 
    let dataArray=[];
    let gridData=ReceiveDetailsRef.table.getData();
    gridData.forEach((row,i) => { 
      if(row.id==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    }) 
    setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":dataArray,"deletedDataSet":deletedDatas} ); 
   
  }


  

const setUpdatedDetailsGridDataHandler = (rows) => {
  
   //console.log('isval: ', rows);
  if (rows.datatype == "FormData") {
    props.setFormDataForAdjust(rows.IssueToFacility);
  } else {
    
    let validForm = props.checkValidateForm(rows.data,'edit');
    if (validForm) {

      // console.log('props.addProductForm: ', props.addProductForm);
      if(props.addProductForm){
        //when save
        mutateSave(validForm);
      }else if(rows.datatype == "ScanData"){
        
        mutateUpdateForScan(validForm); 
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
  }
};


const { isLoading: isLoadingSave, mutate:mutateSave } = useMutation(api.saveAdjustment, {
  onSuccess: (data) => { 
    if (data.status == 200) {


    masterDirty=false;
    onMasterSetDirty();

    props.openNoticeModal({
      isOpen: true,
      msg: data.message,
      msgtype: data.success,
    });

    let route = `edit/${data.TransactionId}`;
    props.history.push(route); 
    
    }else{
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
});





const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
  onSuccess: (data) => {
    // console.log('mutateUpdate data: ', data);
    if (data.status == 200) { 
    //  console.log("props: ",props.formData.TransactionId);
      //setbStockUpdate(data.bStockUpdated);
      masterDirty=false;
      onMasterSetDirty(); 

      props.successSave(data.bStockUpdated);
      if(data.bStockUpdated==1){
       mutatePost({
        menukey: menukey, 
        TransactionId: props.formData.TransactionId,
        TransactionTypeId: 3
      });
      }
      else{
        props.successSave(data.bStockUpdated); //refetch(); 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      }

    }else{ 
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
});
const {mutate:mutateUpdateForScan } = useMutation(api.updateReceiveInv, {
  onSuccess: (data) => {
    // console.log('mutateUpdate data: ', data);
    if (data.status == 200) { 
    //  console.log("props: ",props.formData.TransactionId);
      //setbStockUpdate(data.bStockUpdated);
      masterDirty=false;
      onMasterSetDirty(); 
      doActionScanBarcodeCopy();
        props.successSave(data.bStockUpdated); //refetch(); 
        
      

    }else{ 
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
});



const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.updateAdjStock, {

  onSuccess: (data) => { 
    if (data.status == 200) { 

      if(data.success==0){        
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

       
       }else{

        props.stockPosted(1);
        props.refetch();
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

       } 

    }else{
      props.refetch();
      props.stockPosted(0);
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }


})


 
   
  const {  mutate } = useMutation(api.getWarehouseList, {
    onSuccess: (data) => { 
      if (data.status == 200) {  

        //  let firstIssueToFacility=data.data.datalist.length==1?data.data.datalist[0].id:'';
        //  setfirstIssueToFacility(firstIssueToFacility);
        //  props.formData.IssueToFacility=firstIssueToFacility;
        
        setUpdatedDetailsGridDataHandler({"datatype":"FormData","IssueToFacility":props.formData});
         
        setIssueToFacility(data.data.datalist);
      }
    }
  });
  const {  mutate:getAdjType } = useMutation(api.getAdjType, {
    onSuccess: (data) => { 
      if (data.status == 200) {  
        setAdjType(data.data.datalist.gAdjustmentTypeByFacility);
      }
    }
  });

  let params = { 
    lan:lan,
    StoreId:currStoreId,
    task:'gAdjustmentTypeByFacility'
  };
  const {  data } = useQuery(
    [params],
    api.getAdjTypeList,
    {
      onSuccess: (data) => {
        setAdjType(data.data.datalist.gAdjustmentTypeByFacility);
      },
      
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
    
  );

   
  const comboChangeEvnt = (e) => {

    if(e.target.name=='StoreId'){ 
      getAdjType({StoreId:e.target.value,task:'gAdjustmentTypeByFacility'}); 
      props.handleChange(e);
      masterDirty=true;
      onMasterSetDirty();
      return;
    }
     
    if(AdjType[AdjType.findIndex(atclist => atclist.id == e.target.value)].NOT_ALLOW==1){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]['Cannot initiate']+' '+AdjType[AdjType.findIndex(atclist => atclist.id == e.target.value)].AdjType,
        msgtype: 0,
      });
      return;
    }
    
    setcurrentAdjType(e.target.value);
       
    props.formData.AdjTypeId=e.target.value;
     

   // AdjType[AdjType.findIndex(atclist => atclist.id == e.target.value)].NOT_ALLOW
    
    AdjType.map((row) => {
      if(row.id==e.target.value){
        
        let params={action:"getWarehouseFacility","menukey": menukey, "FacilityId":FacilityId,  
        PickListMethod: row.PickListMethod 
        };
        mutate(params);
 
       if(row.IsPositive==1){
         
        setbIsPositive(false); 
        props.updateIspositive(false);
        setDisableAddProduct(false); 
       }  
      
       else if(row.IsPositive==-1) {
        props.updateIspositive(true);
        setDisableAddProduct(false); 
        setbIsPositive(true); 
       } 
      
       else  {
        props.updateIspositive(true);
        setbIsPositive(true); 
        setDisableAddProduct(true); 
        
       }

      
      return; 
      }
      
    }); 
    props.handleChange(e);

    masterDirty=true;
    onMasterSetDirty();

  };


  const mutatedeleteTransactionItems=(pdata) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/deleteTransactionItems";
      //console.log("ddddd",currTransactionId);
      //let data=fDtat;
      //let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:CurFacility,TransactionId:CurTransactionId};
      let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:props.formData.FacilityId,TransactionId:props.formData.TransactionId};
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "POST", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
          
           
        })
        .catch(error => {

          props.refetch();
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     } 

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    const cellValue = props.cell._cell.value || "Edit | Show";
    if(bStockUpdate == 0)
    return (
      <>

        {/* <DeleteOutline
          onClick={() => {
           
              props.cell.getRow().delete(); 
               
              if((rowData.TransactionItemId!=null)||(rowData.TransactionItemId!=-1)){
                let newDeletedDataSet=[rowData.TransactionItemId]; 
                deletedDatas=newDeletedDataSet.concat(deletedDatas); 
                isDirty=true;
                onSetDisable();
              }  
          }}
        /> */}

          <DeleteOutline
            onClick={() => {
              props.cell.getRow().delete();
              mutatedeleteTransactionItems(rowData);
            }}
          />


      </>
    );
    else  return (
      <>
         
      </>
    );
  } 
  let bfalse=true;
  useEffect(() => {

    
    if (ReceiveDetailsRef == null) return;
   
    if(props.bFirst){
    setManyTableData(props.ManyTableData);
    }

    
    setbStockUpdate(props.bStockUpdate===1?true:false);
   
    setbIsPositive(props.isPositive);
    if(!props.addProductForm ){
    
   }else if(props.addProductForm){
      if(UsersListByFacility.length==2){
         
        if(RoleId!=1)
        props.formData.PreparedBy=userid; 
      //  props.formData.ApprovedBy=UsersListByFacility[1].id; 
      }else{
        props.formData.PreparedBy=null;
       // props.formData.ApprovedBy='';
        
      }

    } 
    

    
    

  }, [props.ManyTableData, props.bStockUpdate,props.isPositive]); 

  useEffect(() => {
 
    if(!props.addProductForm ){ 
    if(props.pickListMethod!=undefined){
      //setswipeIssueToFacility
      bfalse=false;
      setIssueToFacilityVal(props.formData.adjIssueToFacility);
      let params={action:"getWarehouseFacility","menukey": menukey, "FacilityId":FacilityId,  
      PickListMethod: props.pickListMethod
      };
      mutate(params);
    }
   }  
  }, [props.pickListMethod]); 



  
  // useEffect(() => {  
  //   isDirty=props.dirty; 
  //   onSetDisable();
  //   deletedDatas=[]; 
  // }, [props.dirty]);
  


  useEffect(() => {
  //  setIssueToFacilityVal(true);
     
    props.formData.adjIssueToFacility= IssueToFacilityVal;
   

    if(props.addProductForm ){
       
      if(IssueToFacility.length==1){
         
        setACValue(
          IssueToFacility[0]
        );
        
        props.formData.adjIssueToFacility=IssueToFacility[0].id;
      }else{
        setACValue({ id: '', name: "", FLevelId: 0, FLevelName: "", bfacility: 0 });
        
      }
     
    }else{
    
      if(IssueToFacility[IssueToFacility.findIndex(atclist => atclist.id == IssueToFacilityVal)]==undefined)
      setACValue( { id: '', name: "", FLevelId: 0, FLevelName: "", bfacility: 0 });
      else setACValue(IssueToFacility[IssueToFacility.findIndex(atclist => atclist.id == IssueToFacilityVal)]);
     // if(IssueToFacility.length==1){
     
     // }
    }
    

    bfalse=true;

  }, [IssueToFacility]); //

 
  

  const handleATCChange = (event, newValue) => {
    let rowId = '';
    if(newValue == null){
      rowId = '';
    }else{
      rowId = newValue.id;
    }

    props.formData.adjIssueToFacility=rowId;
    setIssueToFacilityVal(rowId);
    if(rowId!='')
    props.handleChange('IssuedToFacility');
    // let data = { ...formData };
    // data['atc'] = rowId;
    // setFormData(data);
 }; 



//  const handleChangeGrp = (e) => {
//   props.filterHandleChange(e.target.value);
//   setCurrItemGroupId(e.target.value);
// };

const handleProductGroupChange = (event, newValue) => {

  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currItemGroupId) {
    if(props.addProductForm==false){
    props.filterHandleChange(rowId);
    }
    setCurrItemGroupId(rowId); 
  }
};

const scan_gtin_or_brandcode = useRef(null);
const handleRefetchCall = () => {
  props.refetch();
  scan_gtin_or_brandcode.current.value = "";
};

const doActionScanBarcode = () => { 
 setUpdatedDetailsGridDataHandler({"datatype":"ScanData","data":[],'deleteddata':[]});
   
};

const doActionScanBarcodeCopy=()=>{
  const ScanBarcodeParams = {
    TransactionTypeId: 3,
    StoreId: props.formData.StoreId,
    TransactionId: props.formData.TransactionId,
    FacilityId: props.formData.FacilityId,
    ReceiveFromId: 0,
    AdjTypeId: props.formData.AdjTypeId,
    lan: lan,
    menukey: menukey,
    handleRefetchCall: handleRefetchCall,
    UserId:userid,
    UserName:UserName,
    UserRole:RoleId
  }

ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, {...props});
}


const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};


const backToList = () => {
  if(masterDirty==true){

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },

    }).then((willAction) => {
      if (willAction) { 
        masterDirty=false;
        props.history.push("/adjustment-invoice");

      } 
    });

  }else props.history.push("/adjustment-invoice")
  
};

const formOnchange = (e,cases,inputname) => { 
  console.log("On form change",inputname);
  //isDirty=true;
   masterDirty=true;
   onMasterSetDirty(); 
   if(cases=='handleChange')
   props.handleChange(e);
   else if(cases=='handleRDateChange')
   props.handleRDateChange(e,inputname);
   else if(cases=='handleADateChange')
   props.handleADateChange(e,inputname);
   

};

const [acValue, setACValue] = useState({ id: '', name: "", FLevelId: 0, FLevelName: "", bfacility: 0 });
useEffect(() => {
  if(acValue!=undefined){
    props.formData.bfacility=  acValue.bfacility;  
  }
 
}, [acValue]); //

const changeIssuedToval = (val) => {
  setACValue(val);
  if(val != null){ 
  
  props.formData.adjIssueToFacility= val.id;
  props.formData.bfacility= val.bfacility;

   masterDirty=true;
   onMasterSetDirty();

  }
  
  
};


//const [FormStoreId, setFormStoreId] = useState({ id: '', name: "" });
const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   console.log("On change ev",val.id);
  switch(cases){
    /*
    case 'StoreId': 
      setFormStoreId(val);        
      props.formData.StoreId= val != null?val.id:"";       
        getAdjType({StoreId:val.id,task:'gAdjustmentTypeByFacility'}); 
        props.handleChange(event);
        masterDirty=true;
        onMasterSetDirty();
        return;
      break;
      */
    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;

    case 'ApprovedBy': 
      setApprovedBy(val);
      props.formData.ApprovedBy= val != null?val.id:"";
      break;  
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
 // setFormStoreId(StoreList[StoreList.findIndex(FormStoreList => FormStoreList.id == props.formData.StoreId)]); 
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByApproveList => UsersListByApproveList.id == props.formData.ApprovedBy)]); 
    
}, [props.formData.PreparedBy, props.formData.ApprovedBy]);

// props.formData.StoreId,

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Adjustment_invoice_create_edit'] +' ('+FacilityName+')'}</div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">

<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>

                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => backToList()}
                        //onClick={() => props.history.push("/adjustment-invoice")}
                      >
                        {DispensingLanguage[lan][menukey]['Back_to_List']}
                      </Button>
                       
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
                      >
                       {DispensingLanguage[lan][menukey]['Submit']}
                      </Button>
                      
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">


<div className="mr-4 float-left">
  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
</div>
                    
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => backToList()}
                    >
                     {DispensingLanguage[lan][menukey]['Back_to_List']} 
                    </Button>

                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}//() => props.handleUpdate()
                    >
                     {DispensingLanguage[lan][menukey]['update']}
                    </Button>
                    <Button
                      //disabled={props.bStockUpdate || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost}
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]['POST']}
                    </Button>
                    

                    <Button
                    variant="contained"
                    color="primary"
                    className="mr-2 sw_icons"
                    onClick={() => PrintMany()}
                    >
                    <i class="fas fa-print"></i>
                    </Button>

                    <Button
                    variant="contained"
                    color="primary"
                    className="mr-2 sw_icons"
                    onClick={() => ExcelMany()}
                    >
                    <i className="far fa-file-excel"></i>
                    </Button>

                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>


                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Store"]} *
                    </InputLabel>
                    <Select
                      error={props.errorObject.StoreId}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="StoreId"
                      name="StoreId" 
                      value={ props.formData.StoreId}
                    // value={all}props.formData.StoreId
                     // onChange={(e) => props.handleChange(e)}
                      onChange={(e) => comboChangeEvnt(e)}
                      fullWidth
                      disabled={props.addProductForm==false?true: props.bStockUpdate}
                    >
                      
                      {StoreList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>

                    <FormHelperText error={props.errorObject.StoreId}>
                      {props.errorObject.StoreId}
                    </FormHelperText>

                  </FormControl>
                </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.addProductForm==false?true: props.bStockUpdate}        
                      autoHighlight
                      disableClearable
                      id="StoreId"
                      options={StoreList}  
                      onChange={(event, value) => changeVal(event,value,'StoreId')} 
                      getOptionLabel={(option) => option.name}
                      value={FormStoreId}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.StoreId}
                        label={DispensingLanguage[lan][menukey]["Store"] }
                        variant="standard"
                        error={props.errorObject.StoreId}
                        helperText={props.errorObject.StoreId}
                        required
                        id="StoreId"
                        name="StoreId" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid> */}


              
                  


                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true/*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label= {DispensingLanguage[lan][menukey]['Adjustment_Invoice#']}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="AdjTypeId-label">{DispensingLanguage[lan][menukey]['Adjustment_type']}*</InputLabel>
                      <Select
                        disabled={props.bStockUpdate?props.bStockUpdate:( props.ManyTableData.length==0?false:true)}
                        ref={(AdjTypeId) => (adjId = AdjTypeId)}
                        error={props.errorObject.AdjTypeId}
                        labelId="AdjTypeId-label"
                        id="AdjTypeId"
                        name="AdjTypeId"
                        ispositive=""
                        value={props.formData.AdjTypeId}
                        fullWidth
                        onChange={(e) => comboChangeEvnt(e)}
                      >
                        {
                        
                          AdjType.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.id} ispositive={item.IsPositive}>
                                {item.AdjType}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                      <FormHelperText error={props.errorObject.AdjTypeId}>
                        {props.errorObject.AdjTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>


                   <FormControl className={classes.fullWidth}>

                              
                   <Autocomplete
                          autoHighlight
                          disableClearable
                          disabled={props.bStockUpdate}
                          id="IssueToFacility"
                          options={IssueToFacility}
                          onChange={(event, newValue) => changeIssuedToval(newValue)}
                          getOptionLabel={(option) =>option.name} 
                          value={acValue} 
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{ option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.adjIssueToFacility}
                            label={DispensingLanguage[lan][menukey]["To_From"]}
                            variant="standard"
                            error={props.errorObject.adjIssueToFacility}
                            helperText={props.errorObject.adjIssueToFacility}
                            required
                            id="IssueToFacility"
                            name="IssueToFacility"
                            
                            fullWidth
                           


                          />
                          )}
                        />

                  
                              

                              </FormControl> 
                    
                  </Grid>

                {/* <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  { DispensingLanguage[lan][menukey]['Prepared_By'] } *   
                  </InputLabel>
                  <Select
                    error={props.errorObject.PreparedBy}
                    // helperText={props.errorObject.OrderBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="PreparedBy"
                    name="PreparedBy"
                    value={props.formData.PreparedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                    disabled={props.bStockUpdate}
                    //disabled={bCompleted===1}
                  >
                  {
                  UsersListByFacility.map(
                  (item, index) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>

                <FormHelperText error={props.errorObject.PreparedBy}>
                  {props.errorObject.PreparedBy}
                </FormHelperText>
                </FormControl>
              </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Prepared_By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required
                        id="PreparedBy"
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


              

              <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.SupplierInvDate}
                        helperText={props.errorObject.SupplierInvDate}
                        className={classes.fullWidth}
                       // disableToolbar
                        variant="inline"
                        id="SupplierInvDate"
                        label=  {DispensingLanguage[lan][menukey]['Prepared_Date']} 
                        autoOk={true}
                        name="SupplierInvDate"
                        fullWidth
                       // showTodayButton={true}
                        value={
                          props.formData.SupplierInvDate || props.selectedEndDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleADateChange(e, "SupplierInvDate")}
                        onChange={(e) =>formOnchange(e,'handleADateChange','SupplierInvDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                        //maxDate={new Date()}
                      />
                    </MuiPickersUtilsProvider>
              </Grid>


              {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  { DispensingLanguage[lan][menukey]['Approved_By'] } *  
                  </InputLabel>
                  <Select
                    error={props.errorObject.ApprovedBy}
                    // helperText={props.errorObject.ApprovedBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="ApprovedBy"
                    name="ApprovedBy"
                    value={props.formData.ApprovedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                    disabled={props.bStockUpdate}
                    //disabled={bCompleted===1}
                  >
                  {
                  UsersListByFacility.map(
                  (item, index) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>

                <FormHelperText error={props.errorObject.ApprovedBy}>
                  {props.errorObject.ApprovedBy}
                </FormHelperText>
                </FormControl>
            </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="ApprovedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={ApprovedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.ApprovedBy}
                        label={DispensingLanguage[lan][menukey]["Approved_By"] }
                        variant="standard"
                        error={props.errorObject.ApprovedBy}
                        helperText={props.errorObject.ApprovedBy}
                        required
                        id="ApprovedBy"
                        name="ApprovedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


              <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                       // disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label=  {DispensingLanguage[lan][menukey]['Approved_Date']} 
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                       // showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleRDateChange(e, "TransactionDate") }
                        onChange={(e) =>formOnchange(e,'handleRDateChange','TransactionDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
              </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      disabled={props.bStockUpdate}
                      // error={props.errorObject.Remarks}
                      // helperText={props.errorObject.SupplierInvNo}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      autoComplete="family-name"
                     // onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','Remarks')}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">

                   
                <Grid container spacing={1}>
                    
                    
                    {/* <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) =>handleChangeGrp(e)}
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid> */}

               <Grid item xs={2} sm={2}>
               <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ItemGroupId"
                      disabled={props.addProductForm==true?true:false}
                      disableClearable
                      options={GroupList}               
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                        name="ItemGroupId"
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>

                     
                      <Grid item xs={3} sm={3} id="FID_scan_gtin_or_brandcode">
                              <FormControl className={classes.fullWidth}>
                                <TextField
                                  disabled={(props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  
                                  id="scan_gtin_or_brandcode"
                                  name="scan_gtin_or_brandcode"
                                  label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                   
                                  autoComplete="family-name"
                                  ref={scan_gtin_or_brandcode}
                                  onKeyPress={handleKeyPress} 
                                  onKeyDown={handleKeyDown} 
                                  onKeyUp={handleKeyUp}
                                  
                                  
                                />
                              </FormControl>
                                      
                            </Grid> 

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button

                                disabled={(props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>
                    




                    <div className="search_control_group dnone" id="scan_sscc_hide_show">
                      <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                      <input disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                    </div> 

                      {/* <Grid item xs={5} sm={5}>

                        <div className="search_card">
                          <form>  
                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                              <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                            </div>
                            <div className="search_control_group" id="FID_scan_gtin_or_brandcode">
                              <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" ref={scan_gtin_or_brandcode} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                            </div>
                            <div className="search_control_group" id="FID_scanButton">
                              <Button
                                disabled={props.addProductForm==true?true: props.bStockUpdate}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()}
                              >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                              </Button>
                            </div>
                          </form>
                        </div>

                      </Grid> */}

                      <Grid item xs={2} sm={2}>
                        <div className="float-left"> 
                        <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                        </div>
                      </Grid>
                        <Grid item xs={4} sm={4}>
                          <div className="float-right sw_btn_control" id="FID_pick_items_btn">
                           
                            <Button
                              //disabled={props.bStockUpdate?props.bStockUpdate:bDisableAddProduct}
                              disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => props.isPositive?handleClose("LotOpen"):handleClose("ProductsOpen")}
                            >
                              {DispensingLanguage[lan][menukey]['Add_Items']} 
                            </Button>
                          </div>

                          
                             
                            {/* <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct} 
                            {...props}                          
                             /> */}
                          <ProductsModal 
                              handleClose={handleClose} 
                              open={open} 
                              addProduct={addProduct} 
                              gFacilityId={props.formData.FacilityId} 
                              gStoreId={props.formData.StoreId} 
                              {...props} />

                          <ProductsLotModal
                            handleClose={handleClose}
                            open={openLot}
                            addProduct={addProduct}
                            AdjTypeId={props.formData.AdjTypeId}  
                            {...props}
                           
                          />
                         
                         
                        </Grid>


                        </Grid>


                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        options={{
                          groupBy: "GroupName",
                          columnCalcs: "both",
                        }}
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
