import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
// material components
import { Select, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, Typography, TextField } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../services/Service.js";
import * as ScanBarcode from '../../services/ScanBarcode';


import {
  Launch
} from "@material-ui/icons";

import ProductSelectInPrescriptionModal from "../../services/ScanLogModal";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
const FakeMedicineDashboard = (props) => {

  const mapStyles = {
    width: '92%',
    height: '90%',
    overflow: "hidden !important",
  };

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;



  // For Map
  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    markerParams: {}
  });

  const onMarkerClick = (props, marker, e) => {
    console.log(props.params);
    setState({
      selectedPlace: props,
      markerParams: props.params,
      activeMarker: marker,
      showingInfoWindow: true
    })
  };

  const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        selectedPlace: {},
        showingInfoWindow: false,
        activeMarker: null,
        markerParams: {}
      });
    }
  };

  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const Facility_List = JSON.parse(localStorage.getItem("getFacilityListEqual99"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);

  const [currFacilityId, setCurrFacilityId] = useState("0");
  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);

  const [Regionlist, setRegionlist] = useState([]); //JSON.parse(localStorage.getItem("Regionlist"));
  let TempZoneList = useState([]); // JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  let TempCommuneList = useState([]); //JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);
  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [Region, setRegion] = React.useState(true);
  const [openSqlLog, setopenSqlLog] = useState(false);










  let ParamList = {
    menukey: menukey,
    action: "getMapLatitudeLongitudeForDashboard",
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId
  }






  const handleRegionChange = (event) => {
    setcurrRegionId(event.target.value);
  };





  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname = '';
    if (newValue == null) {
      rowId = '';
      Fname = '';
    } else {
      rowId = newValue.id;
      Fname = newValue.name;
    }


    if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);



    }




  };

  // End Facility Change




  // End Filter


  // End Map
  const [TotalScanlogcount, setTotScanLogcount] = useState([]);

  let params1 = {
    menukey: menukey,
    action: "TotalScanLogcount",
    lan: lan,
    FacilityId: currFacilityId,
  };

  const { data: totscanlogcount } = useQuery(
    [params1],
    api.getTotalScanCount,
    {
      onSuccess: (data) => {

        setTotScanLogcount(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const [dataItems, setDataItems] = useState([]);
  const [TFacility, setTotalFacility] = useState(0);



  let params = {
    menukey: menukey,
    action: "getScanLogError",
    lan: lan,
    FacilityId: currFacilityId,
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getScanLogData,
    {
      onSuccess: (data) => {

        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  const getTotalFacility = useQuery(
    ["FacilityCount111"],
    () => api.TotalFacility(currRegionId),
    {
      onSuccess: (data1) => {
        setTotalFacility(data1[0].Total_Facility);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );

  const [LogDataArray, setLogDataArray] = useState();

  const handleClose = (modalname) => {
    switch (modalname) {

      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };



  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
 

            setLogDataArray(JSON.parse(rowData.ScanJson));
            // console.log("gggggggggggggg",rowData);
            handleClose("ProductBatchOpen");

          }}
        />
      </>
    );

  }


  // useEffect(() => {
  //   //handleClose("ProductBatchOpen");
  // }, [LogDataArray]);


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: false,
      width: 200,
    },

    // {
    //   title: DispensingLanguage[lan][menukey]["Facility Id"],
    //   field: "FacilityId",
    //   headerSort: false,
    //   width: 85,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["User Name"],
    //   field: "userName",
    //   headerSort: false,
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    //   width: 80,

    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Device Id"],
    //   field: "DeviceId",
    //   headerSort: false,
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    //   width: 65,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Scan Count"],
    //   field: "ScanCount",
    //   headerSort: false,
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    //   width: 65,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Scan Page"],
      field: "ScanPage",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Scan Code"],
      field: "ScanCode",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Error Message"],
      field: "errorMsg",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Create Date"],
    //   field: "CreateTs",
    //   headerSort: false,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Update Date"],
    //   field: "UpdateTs",
    //   headerSort: false,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    // },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 8,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },

  ];



   /* =====Start of Excel Export Code==== */
   const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

   const PrintPDFExcelExportFunction = (reportType) => {
     let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
 
     window.open(
       finalUrl +
       "?action=FakeMedicineDashboardExport" +
       "&reportType=" +
       reportType +
        "&FacilityId=" +
        currFacilityId +
        "&FacilityName=" +
        currFacilityName +
       "&menukey=" +
       menukey +
       "&lan=" +
 
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
     );
   };
 
   /* =====End of Excel Export Code==== */



  const goToFacilitiesByType = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/facilities-by-type`
    );
  }


  const goToFacilityClassification = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/facilities-by-classification`
    );
  }


  const goToFacilityOwnerType = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/facility-owner-type`
    );
  }



  // Start Faciliy Type Pie
  let initialFacilitiesByType={
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 400,
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [ ],
  };
  const [option_FacilitiesByType, setOption_FacilitiesByType] = useState(initialFacilitiesByType);
  let initialFacilityClassification={
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 400,
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [],
  };
  const [option_FacilityClassification, setOption_FacilityClassification] = useState(initialFacilityClassification);

  let params3 = {
    menukey: menukey,
    action: "getNumberOfFacilityTypeChart",
    lan: lan,
    FacilityId: currFacilityId,
  };
  
  const { data: chart } = useQuery(
    [params3],
    api.getNumberOfScanPageChart,
    {

      //const { data:BrandByExpiryDate } = useQuery("FacilitiesByType", api.getNumberOfFacilityTypeChart, {
      onSuccess: (data) => {
 
        if(data.series_data==undefined)
          setOption_FacilitiesByType(initialFacilitiesByType)
         else 
          setOption_FacilitiesByType({
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
              height: 400,
            },
            title: {
              text: "",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
              point: {
                valueSuffix: "%",
              },
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: DispensingLanguage[lan][menukey]["Value (%)"],
                colorByPoint: true,
                 data: data.series_data,
              },
            ],
          })
        
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    });

  // End Faciliy Type Pie


  //------


  const scan_gtin_or_brandcode = useRef(null);
  const handleRefetchCall = () => {
    props.refetch();
    scan_gtin_or_brandcode.current.value = "";
  };
  const doActionScanBarcode = () => {



    const ScanBarcodeParams = {
      TransactionTypeId: 1,
      StoreId: '',
      TransactionId: '',
      FacilityId: FacilityId,
      FacilityLevelId: 1,
      ReceiveFromId: '',
      AdjTypeId: 0,
      lan: lan,
      menukey: menukey,
      handleRefetchCall: handleRefetchCall,
      UserId: UserId,
      UserName: UserName,
      UserRole: UserRole,
      ScanFor: "FakeMedicineDashboard"
    }

    ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, { ...props });
  };


  const onClickScanBarcode = () => {
    doActionScanBarcode();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      doActionScanBarcode();
    }
  }


  let altValue = "";
  const handleKeyDown = (e) => {
    if (e.altKey) {
      if (e.which !== 18) {
        altValue += e.which;
      }
      if (altValue === "969698105") {
        //this.value += String.fromCharCode(29);
        // scan_gtin_or_brandcode.current.value+= String.fromCharCode(29);
        document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.altKey === false) {
      altValue = "";
    }
  };

  //------




 


  let params4 = {
    menukey: menukey,
    action: "getNumberOfErrorMessageChart",
    lan: lan,
    FacilityId: currFacilityId,
  };

  const { data: chart2 } = useQuery(
    [params4],
    api.getNumberOfErrormsgChart,
    {

      //const { data:BrandByExpiryDate } = useQuery("FacilitiesByType", api.getNumberOfFacilityTypeChart, {
      onSuccess: (data) => {
        if(data.series_data==undefined)
        
        setOption_FacilityClassification(initialFacilityClassification);
        else
        

   
       
        setOption_FacilityClassification({
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 400,
          },
          title: {
            text: "",
          },
          tooltip: {
            pointFormat:  "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: DispensingLanguage[lan][menukey]["Value (%)"],
              colorByPoint: true,
               data: data.series_data,
            },
          ],
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    });



  // End Facility Classification Pie








  // useEffect(() => {
   
  //   // getTotalFacility.refetch();
  //   // getFacilityTypeChart.refetch();
  //   // getFacilityClassification.refetch();
  //   //getFacilityOwnerType.refetch();
  // }, [currFacilityId]);





  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>


          <div className="d-flex justify-product mb-4">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Fake Medicine Dashboard"]}
                </div>
              </div>
            </Grid>
          </div>


          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>






                <Grid item xs={4} sm={4}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                {/* <Grid item xs={2} sm={2} id="FID_scan_gtin_or_brandcode">
                    <FormControl className={classes.fullWidth}>
                    <TextField                    
                        id="scan_gtin_or_brandcode"
                        name="scan_gtin_or_brandcode"
                        label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]}                         
                        autoComplete="family-name"
                        ref={scan_gtin_or_brandcode}
                        onKeyPress={handleKeyPress} 
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp}
                        
                        
                    />
                    </FormControl>
                            
                </Grid> 

                <Grid item xs={1} sm={1} id="FID_scanButton">
                    <Button          
                    id="scanButton"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickScanBarcode()} 
                    >
                    {DispensingLanguage[lan][menukey]["Scan"]}
                    </Button> 
                </Grid>
                    


                <div className="search_control_group dnone" id="scan_sscc_hide_show">
                    <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                    <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                </div>

                 */}



              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-12">
              <Card className="sw_card mb-2">
                <CardContent>
                  <div className="row mb-3">

                    <div className="col-md-4">
                      <div className="stat-cell bgPrimary">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{TotalScanlogcount['Totalscanlog']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Total Number of Scan Log"]}</span>
                      </div>
                    </div>

                  </div>
                </CardContent>
              </Card>


            </div>



          </div>
          {/* end of row */}

          {/* new row */}
          <div className="row">

            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Scan Error Page Details"]}
                  // action={
                  //   <a href="javascript:void(0);" className="gotoBtn" onClick={goToFacilitiesByType}><Launch /></a>
                  // }
                />

                <CardContent>

                  <HighchartsReact
                    highcharts={Highcharts}
                    options={option_FacilitiesByType}
                  />

                </CardContent>
              </Card>
            </div>


            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Scan Error Message Details"]}
                  // action={
                  //   <a href="javascript:void(0);" className="gotoBtn" onClick={goToFacilityClassification}><Launch /></a>
                  // }
                />

                <CardContent>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={option_FacilityClassification}
                  />
                </CardContent>
              </Card>
            </div>


          </div>
          {/* end of row */}




          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">

              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Scan Log"]}
                  action={
                    <div className="float-right sw_btn_control">


                      {/* <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Number of Facility']+": "+TFacility}</h4> */}


                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>

                    </div>


                  }


                />



                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    height={370}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>

            </div>

            <ProductSelectInPrescriptionModal
              handleClose={handleClose}
              open={openSqlLog}
              LogDataArray={LogDataArray}
              {...props}
            />



          </div>
          {/* end of row */}





        </div>
      </div>
    </>
  );
};

export default FakeMedicineDashboard;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});