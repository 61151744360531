
import { id } from "date-fns/locale";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";
import * as Service from "../../services/Service.js";

// core components
function HomePageContent(props) {
  const [dataItems, setDataItems] = useState([]);
  const [getHomeData, setHomePage] = React.useState(true);

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }

  const menukey = "home";

  if(getHomeData){
    setHomePage(false);
    let ParamList={
      action:"getDataList",
      lan: lan
    }
    Service.default
        .postApi("source/api/api_pages/news.php", ParamList)
        .then((res) => {
          setDataItems(res.datalist);
        })
        .catch((err) => {});
 }

  React.useEffect(() => {
    setHomePage(true);
  }, [lan]);

  return (
    <>

      {/* button area */}
      <div className="sw_section" style={{backgroundColor: "#f7f7f7"}}>
       <div className="container-fluid">
          <div className="row">

              <div className="col-lg-4">
                  <div className="card sw_item_card">
                      <div className="swFixedHeight">
                        <div className="card_desc">
                          <h3 className="title"><Link 
                          href="javascript:void(0)"
                          onClick={(e) => {

                            let token = sessionStorage.getItem("token");
                          
                          if (token==null) {
                            props.history.push("/login");  
                          }else{
                            props.history.push("/generics-list"); 
                          } 
                          }} >{ DispensingLanguage[lan][menukey]["Generics"] }</Link></h3>
                          <p>{ DispensingLanguage[lan][menukey]["Generic drugs will, however, be sold under different brand names.."] }</p>
      
                            
                        </div>
                        <Link 
                            href="javascript:void(0)"
                            onClick={(e) => {
      
                              let token = sessionStorage.getItem("token");
                            
                            if (token==null) {
                              props.history.push("/login");  
                            }else{
                              props.history.push("/generics-list"); 
                            } 
                            }} 
                            className="btn btn-success goto_btn1 fixed_btn">{ DispensingLanguage[lan][menukey]["Browse"] }</Link>
                    </div>
                </div>
              </div>

              <div className="col-lg-4">
                  <div className="card sw_item_card">
                    <div className="swFixedHeight">      
                    <div className="card_desc">
                      <h3 className="title"><Link href="javascript:void(0)"
                      onClick={(e) => {

                        let token = sessionStorage.getItem("token");
                      
                      if (token==null) {
                        props.history.push("/login");  
                      }else{
                        props.history.push("/brands-list"); 
                      } 
                      }} 
                      
                      >{ DispensingLanguage[lan][menukey]["Brands"] }</Link></h3>
                      <p>{ DispensingLanguage[lan][menukey]["Every medicine must has a brand name, which is given by the pharmaceutical.."] }</p>
                      </div>
                        <Link href="javascript:void(0)"
                      onClick={(e) => {

                        let token = sessionStorage.getItem("token");
                      
                      if (token==null) {
                        props.history.push("/login");  
                      }else{
                        props.history.push("/brands-list"); 
                      } 
                      }}
                      
                      className="btn btn-warning goto_btn2 fixed_btn">{ DispensingLanguage[lan][menukey]["Browse"] }</Link>
                    </div>
                </div>
              </div>

              <div className="col-lg-4">
                  <div className="card sw_item_card">
                  <div className="swFixedHeight"> 
                    <div className="card_desc">
                      <h3 className="title"><Link href="javascript:void(0)"
                      onClick={(e) => {

                        let token = sessionStorage.getItem("token");
                      
                      if (token==null) {
                        props.history.push("/login");  
                      }else{
                        props.history.push("/facilities-list"); 
                      } 
                      }} >{ DispensingLanguage[lan][menukey]["Facilities"] }</Link></h3>
                      <p> { DispensingLanguage[lan][menukey]["The both public and private sector organizations are.."] }</p>
                      </div>
                        <Link href="javascript:void(0)"
                      onClick={(e) => {

                        let token = sessionStorage.getItem("token");
                      
                      if (token==null) {
                        props.history.push("/login");  
                      }else{
                        props.history.push("/facilities-list"); 
                      } 
                      }}
                      
                      className="btn btn-info goto_btn3 fixed_btn">{ DispensingLanguage[lan][menukey]["Browse"] }</Link>
                    </div>
                </div>
              </div>

          </div>
       </div>
      </div>
      {/* /button area/ */}

      {/* selection 2 */}
      <div className="sw_section" style={{backgroundColor: "#ffffff"}}>
       <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6">
              <div className="image_card">
                <img src={require("assets/img/dispensing_vector_small.png")} alt=""></img>
              </div>
            </div>
            <div className="col-lg-6">
         
              <div className="section_desc">
                <h3 className="title">{ DispensingLanguage[lan][menukey]["Implementation of ePharmacie.."]} </h3>
                {/* <h4> { DispensingLanguage[lan][menukey]["About ePharmacie"]} </h4> */}
                <p> { DispensingLanguage[lan][menukey]["ePharmacie simplifies management of controlling.."] }</p>
                
                 <h4>{ DispensingLanguage[lan][menukey]["Experience in Benin"] }</h4>
                  <p>{ DispensingLanguage[lan][menukey]["The Global Health Supply Chain.."] }</p>
                  <p>
                  <ul>
                    <li> { DispensingLanguage[lan][menukey]["Benin logistics data visualization system.."] }</li>
                    <li> { DispensingLanguage[lan][menukey]["Logistics management information system.."] }</li>
                    <li> { DispensingLanguage[lan][menukey]["Once installed in DRZS and FoSa.."] }</li>
                    <li> { DispensingLanguage[lan][menukey]["monitor the flow of pharmaceutical products.."] }</li>
                    <li> { DispensingLanguage[lan][menukey]["analyze pharmaceutical product availability.."] }</li>
                    <li> { DispensingLanguage[lan][menukey]["upgrade relevant electronic system.."] }</li>
                  </ul> 
                  </p>
                  <p>{ DispensingLanguage[lan][menukey]["as part of the implementation.."] }</p>
              </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 2/ */}
      <div className="sw_section" style={{backgroundColor: "#f7f7f7"}}>
        <div className="containe-fluid">
            <div className="row">
            {dataItems.map((item, index) => {
                  return (
            <div className="col-lg-6">
            <div className="card sw_item_card">
              <div className="card_desc">
                <h3 className="title" key={index.id}>
                
                  <Link 
                      link="javascript:void(0)"
                      onClick={(e) => {
                        let route = `details-post/${item.id}`;
                        props.history.push(route);
                      
                     }} >

                    {item.title}</Link></h3>
                <p>{item.short_description}</p>

                  <Link href="javascript:void(0)" onClick={(e) => {
                      let route = `details-post/${item.id}`;
                      props.history.push(route);
                  }}

                  className="more_btn">
                    { DispensingLanguage[lan][menukey]["Learn More"] }</Link>
              </div>
            </div>
            </div>
                  );
              })}
              
          </div>
        </div>
    </div>
      {/* selection 3 */}
      {/* <div className="sw_section" style={{backgroundColor: "#f7f7f7"}}>
        <div className="container-fluid">
            <div className="row">

              <div className="col-lg-6">
                  <div className="card sw_item_card">
                    <div className="card_desc">
                      <h3 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Product Promotion Samples Web Services"] }</a></h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                        incididunt ut labore et dolore magna aliqua.</p>
                        <a href="#" className="more_btn">{ DispensingLanguage[lan][menukey]["Learn More"] }</a>
                    </div>
                </div>
              </div>

              <div className="col-lg-6">
                  <div className="card sw_item_card">
                    <div className="card_desc">
                      <h3 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["About Covid Pharmaceuticals Order Transactions"] }</a></h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                        incididunt ut labore et dolore magna aliqua.</p>
                        <a href="#" className="more_btn">{ DispensingLanguage[lan][menukey]["Learn More"] }</a>
                    </div>
                </div>
              </div>

          </div>
        </div>
    </div> */}
    {/* /selection 3/ */}

    {/* selection 4 */}
    {/* <div className="sw_section black-overlay" style={{backgroundImage: "url("+require("assets/img/bg_3.jpg") +")"}}>
       <div className="container-fluid">
         <div className="row align-items-center">
            <div className="col-lg-6 mb15">
              <div className="section_desc">
                <h1 className="title">How Pharmaceutical Track and Trace System works?</h1>
                <p className="desc">The Pharmaceutical Track and Trace System was built to ensure 
                drug safety and prevent drug trafficking In this context, 
                it is the system that follows the process from production / import of drugs to reaching the end user. </p>
              </div>
            </div>
            <div className="col-lg-6 mb15">
              <div className="play_card">
                <a href="javascript:void(0)" className="play-btn" onClick={function noRefCheck(){setModalOpen(true);}}>
                <i class="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
         </div>
       </div>
      </div> */}
      {/* /selection 4/ */}

      {/* selection 5 */}
      {/* <div className="sw_section" style={{backgroundColor: "#e6edf5"}}>
        <div className="container-fluid"> */}
{/*
            <div className="row">
               <div className="col-lg-4 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_1.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Project Description"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The Pharmaceutical Track and Trace System is a form of the Tracking and Monitoring system implemented all over the world, adapted to the pharmaceutical industry."] }</p>
                    </div>
                </div>
              </div> */}

              {/* <div className="col-lg-6 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_1.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Scope"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The scope of this project being part of that of the drug traceability project.."] }</p>
                    </div>
                </div>
              </div>

              <div className="col-lg-6 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_2.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Goals"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The goals of the ePharmacie project is to provide services relating to .."] }</p>
                    </div>
                </div>
              </div> 

          </div>
*/}
          {/* <div className="row"> */}
              {/* <div className="col-lg-4 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_2.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Objective"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The objective of this project is to set up a.."] }</p>
                    </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_3.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Objective"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The objective of this project is to set up a.."] }</p>
                    </div>
                </div>
              </div>

              <div className="col-lg-6 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_4.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Expected Results"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The conceptual analysis and the conceptual data.."] }</p>
                    </div>
                </div>
              </div> */}

              {/* <div className="col-lg-4 mb15">
                  <div className="item_card">
                    <div className="sw_icon">
                      <img src={require("assets/img/icon_4.png")} alt=""></img>
                    </div>
                    <div className="item_desc">
                      <h1 className="title"><a href="#">{ DispensingLanguage[lan][menukey]["Working Principles"] }</a></h1>
                      <p>{ DispensingLanguage[lan][menukey]["The Pharmaceutical Track and Trace System uses electronic product code technology to supply and distribute medicines."] }</p>
                    </div>
                </div>
              </div> */}
          {/* </div>

        </div>
    </div> */}
    {/* /selection 5/ */}

    {/* selection 6 */}
    <div className="sw_section" style={{backgroundColor: "#fff"}}>
       <div className="container-fluid">
         <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="image_card">
                <img src={require("assets/img/app_image_2.jpg")} alt=""></img>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section_desc">
                <h1 className="title">{ DispensingLanguage[lan][menukey]["Get Integrated With Pharmaceutical Track and Trace System"] }</h1>
                <p className="desc">{ DispensingLanguage[lan][menukey]["Synchronize your applications with ITS using Pharmaceutical Track and Trace System API"] }</p>
                <div className="app_btn_card">
                  <div className="app_icon">
                    <a href="#"><img src={require("assets/img/google_play_store.png")} alt=""></img></a>
                  </div>
                </div>
              </div>
            </div>
         </div>
       </div>
      </div>
      {/* /selection 6/ */}

    </>
  );
}

export default HomePageContent;
