import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";
import FacilityFormData from "./FacilityFormData.js";
import * as Service from "../../../services/Service.js";

const ViewFacility = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facilities-list";

  const [formData, setFormData] = useState({
    FacilityId: "",
    FacilityCode: "",
    FacilityName: "",
    FTypeName:"",
    RegionName:"",
    ZoneName:"",
    DistrictName:"",
    OwnerTypeName:"",
    ServiceAreaName:"",
    FacilityAddress:"",
    FacilityInCharge:"",
    FacilityPhone:"",
    FacilityEmail:"",
    Latitude:"",
    Longitude:"",
    ExternalFacilityId:"",
    SOBAPSCode:"",
    GLN:""

  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  
  // let ParamList={action:"getFacilitySingleView",FacilityId:id,menukey:""}
  // Service.default
  //     .postApi("source/api/api_pages/facility_list.php",ParamList)
  //     .then((res) => {
  //       console.log("list of data",res);
  //       setFormData(res.data);
  //       return res.data;
  //     })
  //     .catch((err) => {});
  

  
  const { data } = useQuery(
    ["ViewFF", id],
    () =>
      api.getSingleListView(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateDci, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
  
        queryClient.getQueriesData("dci");
        props.history.push("/dci");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      FacilityId: "",
      FacilityCode: "",
      FacilityName: "",
      RegionName:"",
      FTypeName:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FacilityId","FacilityCode","FacilityName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
      
    }
  };

  return (
    <>
      <FacilityFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default ViewFacility;
