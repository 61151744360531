import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme,alpha } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField, 
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormLabel, 
  MenuItem, 
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  DeleteOutline,
  Edit,
  ViewList,
  FileCopy
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as api from "../../../actions/api";
import swal from "sweetalert";
import {useMutation, useQueryClient } from "react-query";

//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';


import "../common.css";


import { useTranslation } from "react-i18next";

import IssueLotModal from "../../../services/IssueLotModal";
import IssuedToOrderModal from '../../../services/IssuedToOrderModal';
import * as ScanBarcode from '../../../services/ScanBarcode';
import { cellFocusEditor, cellFocusEditorSerial, dateEditor } from "../../../services/Common";
import OrderModal from './OrderModal';

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = '';

let isDirty=false; 
let deletedDatas=[];
let transactionItemID =0;
let masterDirty=false;
const IssueinvoiceItems = (props) => {


  const queryClient = useQueryClient();

  //get ePharmaciyLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issue-invoice";
const FacilityId=localStorage.getItem('FacilityId');

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99")); 
const [FLevelId, setFLevelId] = useState(FacilityList.length==0?0:(FacilityList[FacilityList.findIndex(atclist => atclist.id == FacilityId)]['FLevelId']));

const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef =null;
  let IsssueId = null;

  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);

  const [openWarehouseInvoice, setWarehouseInvoice] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);


  

  const getFacilityListNotEqual99ANDSupplier =JSON.parse(localStorage.getItem("getFacilityListNotEqual99ANDSupplier"));

  const RecipientGroupList =JSON.parse(localStorage.getItem("getRecipientGroup"));
  //const IssuedToList =JSON.parse(localStorage.getItem("getIssuedToList"));
  
  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);
  // Store
  const StoreId = localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const StoreList = JSON.parse(localStorage.getItem("getStore"));

  const [radioValue, setRadioValue] = useState(1);

  const [IssuedTo, setIssuedTo] = useState([]);

  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));

  const [currRecipientGrpId, setcurrRecipientGrpId] = useState('');
  const [IssuedToGrpVal, setIssuedToGrpVal] = useState(false); 
  const [firstIssueToFacility, setfirstIssueToFacility] = useState(''); 

  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_issue_invoice_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_issue_invoice_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};

const FactureBillExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_issue_invoice_list_excel_for_bill.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     + "&TimeStamp=" + Date.now()
  );
};

const BarcodePDF = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/barcode_pdf.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&StoreId=" + StoreId
     +"&SerialDetails=" + bSerial
     + "&TimeStamp=" + Date.now()
  );
};


/* =====End of Excel Export Code==== */
  
  
const handleClose = (modalname) => {
  switch (modalname) {
    case "Products":
      setOpen(false);
      break;
    case "ProductsOpen":
      setOpen(true);
      break;
    case "OrderModal":
      setOpenOrder(false);
      break;
    case "OrderOpen":
      setOpenOrder(true);
       break; 

    case "WarehouseInvoiceClose":
    masterDirty=false;
    setWarehouseInvoice(false);
    break;

      case "WarehouseInvoicOpen":
      let modal_open = props.handleModalCheck();
      // alert(modal_open);
      // console.log("Modal opensssssss",modal_open.Promise)

      if(modal_open){
       setWarehouseInvoice(true);
      }
      break;

   

    default:
      break;
  }
}

const addProduct = (NonOrderProductData) => {  
 
  let temNonordata=NonOrderProductData;
  let rows = [];

  let autoId=0;
  temNonordata.forEach((row,i) => {
    autoId++
    let newRow={} ;
    newRow.OrderItemId ='';
    newRow.ItemNo =row.ItemNo;
    
    newRow.ItemCode =row.ItemCode;
    newRow.ItemName =row.ItemName;
    newRow.UnitPrice =row.UnitPrice;

    
    newRow.TransactionItemId = null;
    newRow.OrderRemainingQty = 0;

    newRow.CurrentStockQty = row.CurrentStockQty;
    newRow.BatchNo = row.BatchNo;
    newRow.ExpiryDate = row.ExpiryDate;

    // Brand Info
    newRow.BrandNo =row.BrandNo;
    newRow.BrandCode = row.BrandCode;
    newRow.CommercialName = row.CommercialName;
    newRow.EAGTIN =row.EAGTIN;
    newRow.PKGTIN =row.PKGTIN;
    newRow.CAGTIN =row.CAGTIN;
    newRow.PLGTIN =row.PLGTIN;
    newRow.SSCCCarton =row.SSCCCarton;
    newRow.SSCCPallet =row.SSCCPallet;

    // manyTableData.forEach((rowssss,ij) => {
     
    //   if(rowssss.ItemNo==row.ItemNo) {
    //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
    //     return;

    //   } 
    // });

   
   
    
    newRow.Quantity =0;
     
    newRow.SKU=autoId;
    newRow.id=autoId;  
    newRow.LineTotal=0; 
    rows.push(newRow);
  
});

//setUpdatedDetailsGridDataHandler(rows,deletedDatas); 
setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":rows});




/*
manyTableData.forEach((row,i) => {
  autoId++;
  let newRow={} ; 
  newRow.OrderItemId =row.OrderItemId;
  newRow.ItemNo =row.ItemNo;
  newRow.ItemCode =row.ItemCode;
  
  newRow.ItemName =row.ItemName;
  newRow.UnitPrice =row.UnitPrice; 
  newRow.TransactionItemId = row.TransactionItemId;
  newRow.OrderRemainingQty = row.OrderRemainingQty;
  newRow.CurrentStockQty = row.CurrentStockQty;
  newRow.Quantity =row.Quantity;
  newRow.BatchNo = row.BatchNo;
  newRow.ExpiryDate =row.ExpiryDate;


  // Brand Info
  newRow.BrandNo =row.BrandNo;
  newRow.BrandCode = row.BrandCode;
  newRow.CommercialName = row.CommercialName;
  newRow.EAGTIN =row.EAGTIN;
  newRow.PKGTIN =row.PKGTIN;
  newRow.CAGTIN =row.CAGTIN;
  newRow.PLGTIN =row.PLGTIN;
  newRow.SSCCCarton =row.SSCCCarton;
  newRow.SSCCPallet =row.SSCCPallet;


  newRow.SKU=autoId;
  newRow.id=autoId; 
  newRow.LineTotal=row.Quantity*row.UnitPrice; 

  

  rows.push(newRow);

 

}); 

  setManyTableData(rows);   
  props.handleAdd(rows); 
  */
  handleClose('Products');
};

const addWarehouseInvoice = (WarehouseInvoiceData) => {
  props.handleAddWarehouseInvoice(WarehouseInvoiceData);
  handleClose("WarehouseInvoice");
};



  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false }, 
    { title: "bSerialSupported", field: "bSerialSupported", visible: false },
    { title: "id", field: "id", visible: false }, 
    {
      title: "GroupName",
      field: "GroupName",
      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  width: 60, formatter: "rownum", hozAlign: "center", headerHozAlign:"center", headerSort:false  },

    { 
      title: DispensingLanguage[lan][menukey]['Actions'], 
      field: 'custom', 
      headerSort:false, 
      headerHozAlign:"center", 
      hozAlign: 'center',
      width: 60,
     formatter: reactFormatter(<ActionButton 
     /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */ />) } ,

     {
      title: "dirty",
      field: "dirty",
      visible: false,
    },

    { title: DispensingLanguage[lan][menukey]['Product_Code'], field: "ItemCode", width: 125 ,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName",headerFilter:true,width: 225},
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 190 ,headerFilter:true},
    { title: DispensingLanguage[lan][menukey]['Brand'], field: 'CommercialName',width:155,headerFilter:true,},
    { title: DispensingLanguage[lan][menukey]['Quantity_Ordered'], field: "OrderRemainingQty", width: 90, hozAlign: "right", headerHozAlign:"right"},

    { title:DispensingLanguage[lan][menukey]['Current_stock_quantity'],
    field: "CurrentStockQty",
    hozAlign: "right",
    headerHozAlign:"right",
    width: 90
    },
   
    { title: DispensingLanguage[lan][menukey]['Quantity_issued'], field: "Quantity",  
    width: 100, hozAlign: "right", headerHozAlign:"right",
      
      //validator:["integer", "min:0"],

      validator: ["integer", "min:0", function (cell, value, parameters) {
 
        if (value == "") return true;
        const balance = cell.getRow().getCell('CurrentStockQty').getValue();
        const validstatus = parseInt(value) <= parseInt(balance);  
        return validstatus; 
      }],



      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditorSerial: '',      
      cellEdited: function (cell) {

        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1); 

        const curcellval = cell.getValue();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);

        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable();

      } 
    },//
    { title: DispensingLanguage[lan][menukey]['Serial_Count'], 
    field: "SerialNo",
    width: 130,
    headerFilter: true,
    },
    { title: DispensingLanguage[lan][menukey]['Lot_No'], 
    field: "BatchNo",
    width: 120,
    headerFilter: true,
    },
    { title: DispensingLanguage[lan][menukey]['Expiry_Date'],
    field: "ExpiryDate" , 
    width: 100,
      
    },


    { 
      title: DispensingLanguage[lan][menukey]['EAGTIN'], 
      field: "EAGTIN", 
      width: 125 ,
      headerFilter: true,   
    },

   { 
    title: DispensingLanguage[lan][menukey]['PKGTIN'], 
    field: "PKGTIN", 
    width: 115 ,
    headerFilter: true,   
   },

  { 
    title: DispensingLanguage[lan][menukey]['CAGTIN'], 
    field: "CAGTIN", 
    width: 115 ,
    headerFilter: true,  
  },

  { 
    title: DispensingLanguage[lan][menukey]['PLGTIN'], 
    field: "PLGTIN", 
    width: 115,
    headerFilter: true,   
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: "SSCCCarton", width: 125,
    headerFilter: true,  
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: "SSCCPallet", width: 125,
    headerFilter: true,
  },


    { title: DispensingLanguage[lan][menukey]['Unit_Price'], 
    field: "UnitPrice" , width: 100, 
    hozAlign: "right", 
    headerHozAlign:"right",  
    },
    { title: DispensingLanguage[lan][menukey]['Line_Total'], field: "LineTotal" , 
      formatter: "money", hozAlign: "right", headerHozAlign:"right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      width: 100
       
    }
    
  ];

  const onSetDisable = () => { 
    
    if(isDirty==true){ 

      //document.getElementById("FID_AStoreId").classList.add("swDisabled");
      document.getElementById("FID_scanButton").classList.add("swDisabled");
      document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("FID_scan_gtin_or_brandcode").classList.add("swDisabled");
      document.getElementById("FID_ItemGroupId").classList.add("swDisabled");
      document.getElementById("FID_pick_items_btn").classList.add("swDisabled");

      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan,UserName:UserName};  
      mutateCellUpdate(fDtat);
      
    }
    else{

     //document.getElementById("FID_AStoreId").classList.remove("swDisabled");
     document.getElementById("FID_scanButton").classList.remove("swDisabled");
     document.getElementById("is_dirty_message").classList.add("dnone");
     document.getElementById("FID_scan_gtin_or_brandcode").classList.remove("swDisabled");
     document.getElementById("FID_ItemGroupId").classList.remove("swDisabled");
     document.getElementById("FID_pick_items_btn").classList.remove("swDisabled");
     
    }
    
 }


 const onMasterSetDirty = () => {   
  if(masterDirty==true){ 
    document.getElementById("master_dirty_message").classList.remove("dnone");    
  }else{ 
    document.getElementById("master_dirty_message").classList.add("dnone");
  }
  props.formData.masterDirty=masterDirty;   
}



 const mutateCellUpdate=(fDtat) => { 
  
  const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null; 
    const apiUrl = process.env.REACT_APP_API_URL + "source/api/receiveUpdate";
    let data=fDtat;
    
      fetch(apiUrl, {
        method: "PUT", 
        headers: {
            
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${auth_token}`,
          "Consumer": process.env.REACT_APP_CONSUMER,
          "Secret": process.env.REACT_APP_SECRET,
        
        },
        body: JSON.stringify(data),
      })
      .then(function (res) {
        console.log(res);
       
        return res.json();
      })
      .then(function (data) {
        isDirty=false;
        onSetDisable();  
      })
      .catch(error => {
  
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
          msgtype: 0,
        });
      
       });
  
  
   }
   
 
  const onPostBtnClick = () => { 
   // setbStockUpdate(true);
    //props.handlePost(ReceiveDetailsRef.table.getData(),deletedDatas); 

      setbStockUpdate(true); 
      onSetDisable();
      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      
      handlePost(dataArray,deletedDatas);

  }


  const handlePost = async (rows,deletedsetdata) => {  

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    //if (validateForm(formData,1)) {
       
      //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
      //mutateUpdate(fDtat);

      // Start Post Alert

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
 
          let FinalData={...validForm,'ManyJsonSave':rows,"bStockUpdated":1,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows);
 
        }else{
          //props.setbStockUpdate(0);
          props.stockPosted(0);
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
    }
      // End Post Alert

      
   // }
     
     
  };

  // const onDownloadOrderInvoiceBtnClick = () => { 
  //    props.DownloadOrderInvoice();
  //    masterDirty=false;
  //    onMasterSetDirty();
  //  }

   
  const onDownloadOrderInvoiceBtnClick = () => { 
    // setbDownloadIssueInvoice(true); 
    setUpdatedDetailsGridDataHandler({"datatype":"DownloadData","data":[],'deleteddata':[]}); 
   }
 
 
 
 
 
 
    
  
  const onDownloadIssueInvoiceBtnClickCopy=()=>{
    props.DownloadOrderInvoice();
    masterDirty=false;
    onMasterSetDirty();
    
  }


  const onSaveUpdateBtnClick = () => { 

    //props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData()); 
    let dataArray=[];
    let gridData=ReceiveDetailsRef.table.getData();
    gridData.forEach((row,i) => { 
      if(row.id==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    })
    
  //  setUpdatedDetailsGridDataHandler(dataArray,deletedDatas);

    setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":dataArray});

  }

  // const setUpdatedDetailsGridDataHandler = (rows) => {
  //   let validForm = props.checkValidateForm(rows,'edit');
  //   // console.log('isval: ', validForm);
  //     if (validForm) {

  //       // console.log('props.addProductForm: ', props.addProductForm);
  //       if(props.addProductForm){
  //         //when save
  //         mutateSave(validForm);
  //       }else{
  //         //when update
  //         mutateUpdate(validForm); 
  //       }
        

        
  //     }
  // };


  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows.data,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutateSave(validForm);
        }else if(rows.datatype == "ScanData"){
        
          mutateUpdateForScan(validForm); 
        }
        else if(rows.datatype == "DownloadData"){
        
          mutateUpdateForDownload(validForm); 
        }
        
        else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  

  const { isLoading: isLoadingSave, mutate:mutateSave } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      masterDirty=false;
      onMasterSetDirty(); 
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  



  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);

      masterDirty=false;
      onMasterSetDirty(); 

        props.successSave(data.bStockUpdated);
        if(data.bStockUpdated==1){
         mutatePost({ 
          menukey: menukey, 
          TransactionId: props.formData.TransactionId,
          TransactionTypeId: 2,
          });
        }
        else{
          props.successSave(data.bStockUpdated); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });
  

  const {mutate:mutateUpdateForScan } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        masterDirty=false;
        onMasterSetDirty(); 
        doActionScanBarcodeCopy();
         props.successSave(data.bStockUpdated); //refetch(); 
           
  
        
  
      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  const {mutate:mutateUpdateForDownload } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        masterDirty=false;
        onMasterSetDirty(); 
        onDownloadIssueInvoiceBtnClickCopy();
        props.successSave(data.bStockUpdated); //refetch(); 
         
        
  
      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        //props.setbStockUpdate(1);
        //setDeletedDataSet([]);

        if(data.success==0){ 
          
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          props.stockPosted(1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        props.refetch();
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })

  const deleteReceiveInv = (data) => { 
    let newarray = manyTableData.filter(element => element !== data); 
    setManyTableData(newarray); 
    props.deleteReceiveInv(data); 
  };

  

  const changeRadio = (value) => {
    setRadioValue(value);
  };

/* Recipients Change*/


const {  mutate } = useMutation(api.gIssuedToList, {
  onSuccess: (data) => { 
    if (data.status == 200) {  

     let firstIssueToFacility=data.data.datalist.length==1?data.data.datalist[0].id:'';

      setfirstIssueToFacility(firstIssueToFacility);
      props.formData.IssuedTo=firstIssueToFacility;
      
     // props.setUpdatedDetailsGridDataHandler({"datatype":"FormData","IssuedTo":props.formData});
       
      setIssuedTo(data.data.datalist);
      
    }
  }
});



const comboChangeEvnt = (e) => {

  //console.log("On change eval",e.target.value);
  
  props.formData.IssuedToGrp=e.target.value;

  RecipientGroupList.map((row) => {
    if(row.id==e.target.value){
      
      let params={
      action:"gIssuedToList",
      "menukey": menukey, 
      "IssuedToGroupId": props.formData.IssuedToGrp,
      "FacilityId":props.formData.FacilityId, 
      "StoreId":props.formData.StoreId,
      "lan": lan,  
      };

      mutate(params);

      return; 

    }
    
  }); 

  props.handleChange(e);
  masterDirty=true;
  onMasterSetDirty(); 

}


/* End */


const mutatedeleteTransactionItems=(pdata) => { 
  
  const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null; 
    const apiUrl = process.env.REACT_APP_API_URL + "source/api/deleteTransactionItems";
    //console.log("ddddd",currTransactionId);
    //let data=fDtat;
    //let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:CurFacility,TransactionId:CurTransactionId};
    let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:props.formData.FacilityId,TransactionId:props.formData.TransactionId};
    let data=fDtat;
    
      fetch(apiUrl, {
        method: "POST", 
        headers: {
            
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${auth_token}`,
          "Consumer": process.env.REACT_APP_CONSUMER,
          "Secret": process.env.REACT_APP_SECRET,
        
        },
        body: JSON.stringify(data),
      })
      .then(function (res) {
        console.log(res);
       
        return res.json();
      })
      .then(function (data) {
        
         
      })
      .catch(error => {

        props.refetch();
  
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
          msgtype: 0,
        });
      
       });
  
  
   } 



function ActionButton (props) { 

  const rowData = props.cell._cell.row.data;  

  const cellValue = props.cell._cell.value || 'Edit | Show';
 
  if(rowData.OrderItemId==''){
    if(bStockUpdate == 0)
    return (
      <>

       
        {/* <DeleteOutline
          onClick={() => {
            props.cell.getRow().delete();
            if((rowData.TransactionItemId!=null)||(rowData.TransactionItemId!=-1)){
              let newDeletedDataSet=[rowData.TransactionItemId]; 
              deletedDatas=newDeletedDataSet.concat(deletedDatas);
              isDirty=true;
              onSetDisable();
            }

          }}
        /> */}

          <DeleteOutline
            onClick={() => {
              props.cell.getRow().delete();
              mutatedeleteTransactionItems(rowData);
            }}
          />



      </>
    );
    else  return (
      <>
         
      </>
    );
    }
    else
    {
      return (
        <>
           
        </>
      );

    }
   
}

  
  let bfalse=true;
  useEffect(() => {

    
    if (ReceiveDetailsRef == null) return;
   
    if(props.bFirst){
    setManyTableData(props.ManyTableData);
    }
    deletedDatas=[];
    setbStockUpdate(props.bStockUpdate===1?true:false);

    if(!props.addProductForm )
      bfalse=false;
      setIssuedToGrpVal(props.formData.IssuedTo);

      let params={
        action:"gIssuedToList",
        "menukey": menukey, 
        "IssuedToGroupId": props.formData.IssuedToGrp,
        "FacilityId":props.formData.FacilityId, 
        "StoreId":props.formData.StoreId,
        "lan": lan,  
        };
  
        mutate(params);

  }, [props.ManyTableData, props.bStockUpdate]); //


  


useEffect(() => {
  
    props.formData.IssuedTo= IssuedToGrpVal;
    console.log(IssuedTo);
    setACValue(
      IssuedTo[IssuedTo.findIndex(atclist => atclist.id == IssuedToGrpVal)]);
    bfalse=true;

}, [IssuedTo]);

 



const handleReset = () => {
  setManyTableData([]);  
  props.handleReset();
};


// const handleChangeGrp = (e) => {
//   props.filterHandleChange(e.target.value);
//   setCurrItemGroupId(e.target.value);
// };

const handleProductGroupChange = (event, newValue) => {
  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currItemGroupId) {
    if(props.addProductForm==false){
    props.filterHandleChange(rowId);
    }
    setCurrItemGroupId(rowId); 
  }
};

const scan_gtin_or_brandcode = useRef(null);
const handleRefetchCall = () => {
  props.refetch();
  scan_gtin_or_brandcode.current.value = "";
};
 

const doActionScanBarcode = () => { 

 // if(scan_gtin_or_brandcode.current.value!='')
  setUpdatedDetailsGridDataHandler({"datatype":"ScanData","data":[],'deleteddata':[]});
    
 };
 
 const doActionScanBarcodeCopy=()=>{
  // let SupplierString = props.formData.ReceiveFrom;
  // const SupplierArr = SupplierString.split("_");
	// const SupplierId = SupplierArr[1];

  const ScanBarcodeParams = {
    TransactionTypeId: 2,
    StoreId: props.formData.StoreId,
    TransactionId: props.formData.TransactionId,
    FacilityId: props.formData.FacilityId,
    ReceiveFromId: 0,
    AdjTypeId: 0,
    lan: lan,
    menukey: menukey,
    handleRefetchCall: handleRefetchCall,
    UserId:UserId,
    UserName:UserName,
    UserRole:UserRole
  }

ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, {...props});
 }


const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};


const backToList = () => {
  if(masterDirty==true){

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willAction) => {
      if (willAction) { 
        masterDirty=false;
        props.history.push("/issue-invoice");

      } 
    });

  }else props.history.push("/issue-invoice")
  
};

const formOnchange = (e,cases,inputname) => { 
  console.log("On form change",inputname);
  //isDirty=true;
   masterDirty=true;
   onMasterSetDirty(); 
   if(cases=='handleChange')
   props.handleChange(e);
   else if(cases=='handleRDateChange')
   props.handleRDateChange(e,inputname);

};

const [acValue, setACValue] = useState({ id: '', name: "", FLevelId: 0, FLevelName: "", bfacility: 1 });

const changeIssuedToval = (val) => {
  setACValue(val);
  if(val != null){
  props.formData.IssuedTo= val.id;
  }
  
  masterDirty=true;
  onMasterSetDirty();
  
};



const [FormStoreId, setFormStoreId] = useState({ id: '', name: "" });
const [FormIssuedToGrp, setFormIssuedToGrp] = useState({ id: '', name: "" });
const [FormIssuedTo, setFormIssuedTo] = useState({ id: '', name: "" });
const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   console.log("On change ev",val.id);
  switch(cases){
    case 'StoreId': 
      setFormStoreId(val);        
      props.formData.StoreId= val != null?val.id:""; 
      break;
    case 'IssuedToGrp': 
      setFormIssuedToGrp(val);
      props.formData.IssuedToGrp= val != null?val.id:"";
      // Change Event
      props.formData.IssuedToGrp=val.id;
      RecipientGroupList.map((row) => {
        if(row.id==val.id){
          
          let params={
          action:"gIssuedToList",
          "menukey": menukey, 
          "IssuedToGroupId": props.formData.IssuedToGrp,
          "FacilityId":props.formData.FacilityId, 
          "StoreId":props.formData.StoreId,
          "lan": lan,  
          };

          mutate(params);
          return; 

        }
        
      }); 
      // End Change Event
      break;
    case 'IssuedTo': 
      setFormIssuedTo(val);
      props.formData.IssuedTo= val != null?val.id:"";
      break;

    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;

    case 'ApprovedBy': 
      setApprovedBy(val);
      props.formData.ApprovedBy= val != null?val.id:"";
      break;  
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

const [checked, setChecked] = React.useState(true);
const [bSerial, setbSerial] = useState(false);

const handleSerialChange = (event) => {
  setChecked(event.target.checked);
  //setLoading(true);
  setbSerial(event.target.checked);
  props.serialHandleChange(event.target.checked);
};

useEffect(() => { 
  setFormStoreId(StoreList[StoreList.findIndex(FormStoreList => FormStoreList.id == props.formData.StoreId)]); 
  setFormIssuedToGrp(RecipientGroupList[RecipientGroupList.findIndex(IssuedToGrpList => IssuedToGrpList.id == props.formData.IssuedToGrp)]);   
  setFormIssuedTo(IssuedTo[IssuedTo.findIndex(AIssuedToList => AIssuedToList.id == props.formData.IssuedTo)]); 
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByApproveList => UsersListByApproveList.id == props.formData.ApprovedBy)]); 
    
}, [props.formData.StoreId, props.formData.IssuedToGrp, props.formData.IssuedTo, props.formData.PreparedBy, props.formData.ApprovedBy]);
 

  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        
      <Grid item xs={12} sm={12}>

<div className="sw_page_heading">
    
    
    <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Issue_voucher_create_edit']+' ('+FacilityName+')'}</div>
    
    <div className="float-right sw_btn_control">

    {
    props.addProductForm ?
      <Grid item xs={12} className="mt-4 text-center">


      <div className="mr-4 float-left">
        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
      </div>



       <div className="float-right sw_btn_control">

       <Button
          className="mr-2"
          variant="contained"
          type="reset"
          //onClick={() => props.history.push("/issue-invoice")}
          onClick={() => backToList()}
        >
          {DispensingLanguage[lan][menukey]['Back to List']}
        </Button>

        {/* <Button
          className="mr-2"
          variant="contained"
          type="reset"
          onClick={() => handleReset()}
        >
          {DispensingLanguage[lan][menukey]['Reset']}
        </Button> */}

        <Button
          disabled={isLoadingSave}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
        >
          {DispensingLanguage[lan][menukey]['Submit']}
        </Button>
        
       </div>
      </Grid>
      :
      <Grid item xs={12} className="mt-2 text-center">


        <div className="mr-4 float-left">
          <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
        </div>

        
        <Button
          className="mr-2"
          variant="contained"
          type="reset"
          //onClick={() => props.history.push("/issue-invoice")}
          onClick={() => backToList()}
        >
         {DispensingLanguage[lan][menukey]['Back to List']}
        </Button>

        <Button
          disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onSaveUpdateBtnClick}
           
        >
         {DispensingLanguage[lan][menukey]['Update']}
        </Button>
        
        <Button 
          disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onPostBtnClick}
        >
          {DispensingLanguage[lan][menukey]['POST']}
        </Button>
        

        <Button
        variant="contained"
        color="primary"
        className="mr-2 sw_icons"
        onClick={() => PrintMany()}
        >
        <i class="fas fa-print"></i>
        </Button>

        <Button
        variant="contained"
        color="primary"
        className="mr-2 sw_icons"
        onClick={() => ExcelMany()}
        >
        <i className="far fa-file-excel"></i>
        </Button>

        <Button
        variant="contained"
        color="primary"
        className="mr-2 sw_icons"
        onClick={() => FactureBillExcelMany()}
        >
          {DispensingLanguage[lan][menukey]['FACTURE/BILL']}
        </Button>

      </Grid>
  }

    </div>
 </div>   
  
  
</Grid>
       

      </div>

      
      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}
 

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                  {/* <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Store"]} *
                    </InputLabel>
                    <Select
                      error={props.errorObject.StoreId}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="StoreId"
                      name="StoreId"               
                      value={ props.formData.StoreId}
                    // value={all}
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','StoreId')}
                      
                      fullWidth
                      disabled={props.addProductForm==false?true: props.bStockUpdate}
                    >
                      
                      {StoreList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>

                    <FormHelperText error={props.errorObject.StoreId}>
                      {props.errorObject.StoreId}
                    </FormHelperText>

                  </FormControl>
                </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.addProductForm==false?true: props.bStockUpdate}               
                      autoHighlight
                      disableClearable
                      id="StoreId"
                      options={StoreList}  
                      onChange={(event, value) => changeVal(event,value,'StoreId')} 
                      getOptionLabel={(option) => option.name}
                      value={FormStoreId}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.StoreId}
                        label={DispensingLanguage[lan][menukey]["Store"] }
                        variant="standard"
                        error={props.errorObject.StoreId}
                        helperText={props.errorObject.StoreId}
                        required
                        id="StoreId"
                        name="StoreId" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


                <Grid item xs={3} sm={3} 
                 hidden={FacilityList.length==0?true:(FLevelId==99?true:false)}
                >


            <FormControl
              disabled={props.addProductForm==true?false: props.bStockUpdate}
              className="sw_radiogroup"
              component="fieldset"
              required
              error={props.errorObject.IsPaid}
              helperText={props.errorObject.IsPaid}
            >
              <FormLabel component="legend">
                {DispensingLanguage[lan][menukey]["Status"]}
              </FormLabel>
              <RadioGroup
                row
                aria-label="IsPaid"
                name="IsPaid"
                value="1"
                //defaultValue="1"
               // value={props.formData.IsPaid}
                className="sw_radiogroup"
              >


                    <FormControlLabel
                      value="1"
                      checked={props.formData.IsPaid == 1}
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Paid"]}
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange')}
                    />
                    <FormControlLabel
                      value="0"
                      checked={props.formData.IsPaid == 0}
                      control={<Radio />} 
                      label={DispensingLanguage[lan][menukey]["Not Paid"]}
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange')}
                    />

               </RadioGroup>
            </FormControl>


                {/* <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="IsPaid"
                    name="IsPaid"
                    defaultValue="1"
                    value={props.formData.IsPaid}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Paid"]}
                      onClick={() => changeRadio(0)}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Not Paid"]}
                      onClick={() => changeRadio(1)}
                    />
                    
                  </RadioGroup>
                </FormControl> */}
              </Grid>

                    <Grid item xs={3} sm={3}>
                      <TextField
                         disabled={true/*bStockUpdate*/}
                         error={props.errorObject.TransactionNo}
                         helperText={props.errorObject.TransactionNo}
                         required
                        id="TransactionNo"
                        name="TransactionNo"
                        label= {DispensingLanguage[lan][menukey]['Issue_Invoice#']} 
                        // value={formData.FirstName || "Mahmudul"}
                        value={props.formData.TransactionNo||props.generatedInvoiceNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => props.handleChange(e)}
                      />
                    </Grid>
                       

             {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  { DispensingLanguage[lan][menukey]['Recipient_Group'] } *  
                  </InputLabel>
                  <Select
                    error={props.errorObject.IssuedToGrp}
                    required
                    ref={(IssuedToGrp) => (IsssueId = IssuedToGrp)}
                    labelId="demo-simple-select-helper-label"
                    id="IssuedToGrp"
                    name="IssuedToGrp"
                    value={props.formData.IssuedToGrp}
                    fullWidth
                    onChange={(e) => comboChangeEvnt(e)}
                    disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}
                          
                    //disabled={props.bStockUpdate}
                  >
                  {
                  RecipientGroupList.map(
                  (item, index) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>

                <FormHelperText error={props.errorObject.IssuedToGrp}>
                  {props.errorObject.IssuedToGrp}
                </FormHelperText>
                </FormControl>
            </Grid>  */}


           <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}                   
                      autoHighlight
                      ref={(IssuedToGrp) => (IsssueId = IssuedToGrp)}
                      disableClearable
                      id="IssuedToGrp"
                      options={RecipientGroupList}  
                      onChange={(event, value) => changeVal(event,value,'IssuedToGrp')} 
                      getOptionLabel={(option) => option.name}
                      value={FormIssuedToGrp}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.IssuedToGrp}
                        label={DispensingLanguage[lan][menukey]["Recipient_Group"] }
                        variant="standard"
                        error={props.errorObject.IssuedToGrp}
                        helperText={props.errorObject.IssuedToGrp}
                        required
                        id="IssuedToGrp"
                        name="IssuedToGrp" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
             </Grid> 




            


{/* <Grid item xs={3} sm={3}>


<FormControl className={classes.fullWidth}> 

 
   <Autocomplete
     disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}
                     
       autoHighlight
       disableClearable
       id="atc"
       options={IssuedTo}  
       onChange={(event, value) => changeIssuedToval(value)} 
       getOptionLabel={(option) => option.name}
       value={acValue}
       renderOption={(option) => (
         <Typography className="sw_fontSize">{option.name}</Typography>
       )}
       renderInput={(params) => (
       <TextField
         {...params}
         value={props.formData.IssuedTo}
         label={DispensingLanguage[lan][menukey]["Recipient"] }
         variant="standard"
         error={props.errorObject.IssuedTo}
         helperText={props.errorObject.IssuedTo}
         required
         id="IssuedTo"
         name="IssuedTo" 
         fullWidth
       />
       )}
     />
     

</FormControl> 
  
</Grid> */}



                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}                    
                      autoHighlight
                      disableClearable
                      id="IssuedTo"
                      options={IssuedTo}  
                      onChange={(event, value) => changeVal(event,value,'IssuedTo')} 
                      getOptionLabel={(option) => option.name}
                      value={FormIssuedTo}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.IssuedTo}
                        label={DispensingLanguage[lan][menukey]["Recipient"] }
                        variant="standard"
                        error={props.errorObject.IssuedTo}
                        helperText={props.errorObject.IssuedTo}
                        required
                        id="IssuedTo"
                        name="IssuedTo" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid> 



                {/* <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  { DispensingLanguage[lan][menukey]['Prepared_By'] } *   
                  </InputLabel>
                  <Select
                    error={props.errorObject.PreparedBy}
                    // helperText={props.errorObject.OrderBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="PreparedBy"
                    name="PreparedBy"
                    value={props.formData.PreparedBy}
                    fullWidth
                   // onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                    disabled={props.bStockUpdate}
                    //disabled={bCompleted===1}
                  >
                  {
                  UsersListByFacility.map(
                  (item, index) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>

                <FormHelperText error={props.errorObject.PreparedBy}>
                  {props.errorObject.PreparedBy}
                </FormHelperText>
                </FormControl>
              </Grid> */}

                <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Prepared_By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required
                        id="PreparedBy"
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


                    <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.SupplierInvDate}
                            helperText={props.errorObject.SupplierInvDate}
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            id="SupplierInvDate"
                            label= {DispensingLanguage[lan][menukey]['Prepared_Date']} 
                            autoOk={true}
                            name="SupplierInvDate"
                            fullWidth
                            showTodayButton={true}
                            value= {props.formData.SupplierInvDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            //onChange={(e) => props.handleRDateChange(e, "SupplierInvDate")}
                            onChange={(e) =>formOnchange(e,'handleRDateChange','SupplierInvDate')}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </MuiPickersUtilsProvider> 
                    </Grid>
                   
                 
                   
              {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                  { DispensingLanguage[lan][menukey]['Approved_By'] } *  
                  </InputLabel>
                  <Select
                    error={props.errorObject.ApprovedBy}
                    // helperText={props.errorObject.ApprovedBy}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="ApprovedBy"
                    name="ApprovedBy"
                    value={props.formData.ApprovedBy}
                    fullWidth
                    //onChange={(e) => props.handleChange(e)}
                    onChange={(e) =>  formOnchange(e,'handleChange','ApprovedBy')}
                    disabled={props.bStockUpdate}
                    //disabled={bCompleted===1}
                  >
                  {
                  UsersListByFacility.map(
                  (item, index) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>

                <FormHelperText error={props.errorObject.ApprovedBy}>
                  {props.errorObject.ApprovedBy}
                </FormHelperText>
                </FormControl>
            </Grid> */}


                <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="ApprovedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={ApprovedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.ApprovedBy}
                        label={DispensingLanguage[lan][menukey]["Approved_By"] }
                        variant="standard"
                        error={props.errorObject.ApprovedBy}
                        helperText={props.errorObject.ApprovedBy}
                        required
                        id="ApprovedBy"
                        name="ApprovedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>

            
                    <Grid item xs={3} sm={3}>

                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            error={props.errorObject.TransactionDate}
                            helperText={props.errorObject.TransactionDate}
                            className={classes.fullWidth}
                           // disableToolbar
                            required
                            variant="inline"                            
                            id="TransactionDate"
                            label= {DispensingLanguage[lan][menukey]['Approved_Date']} 
                            autoOk={true}
                            name="TransactionDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.TransactionDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            //onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                            onChange={(e) =>  formOnchange(e,'handleRDateChange','TransactionDate')}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </MuiPickersUtilsProvider> 
                     
                    </Grid>


                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="Remarks"
                        name="Remarks"
                        label= {DispensingLanguage[lan][menukey]['Remark_s']} 
                        value={props.formData.Remarks}
                        fullWidth
                        multiline
                        autoComplete="family-name"
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>  formOnchange(e,'handleChange','Remarks')}
                        
                      />
                    </Grid>
           

                    <Grid item xs={3} sm={3}>
                      <TextField
                       // disabled={props.bStockUpdate}
                        disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}
                          
                        error={props.errorObject.SupplierInvNo}
                        helperText={props.errorObject.SupplierInvNo}               
                        id="SupplierInvNo"
                        name="SupplierInvNo"
                        label={DispensingLanguage[lan][menukey]['Order#']} 
                        value={props.formData.SupplierInvNo}
                        fullWidth
                        autoComplete="family-name"
                        //onChange={(e) =>props.handleChange(e)}
                        onChange={(e) =>  formOnchange(e,'handleChange','SupplierInvNo')}
                      />
                    </Grid> 

                    <Grid item xs={1} sm={1}>
                        <Button
                        //  disabled={props.bStockUpdate}
                          disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}
                                  
                          className="pick_btn"
                          variant="contained"
                          color="success"             
                          onClick={() => handleClose("WarehouseInvoicOpen")}
                        >
                          ...
                        </Button>

                        
                    </Grid>


                    <Grid item xs={3} sm={3} hidden={FacilityList.length==0?true:(FLevelId==99?true:false)}>
                      <TextField
                       // disabled={props.bStockUpdate}
                       disabled={props.addProductForm==true?false: props.bStockUpdate}
                          
                        error={props.errorObject.AROfficer}
                        helperText={props.errorObject.AROfficer}               
                        id="AROfficer"
                        name="AROfficer"
                        label={DispensingLanguage[lan][menukey]['Administration and Resources Officer']} 
                        value={props.formData.AROfficer}
                        fullWidth
                        autoComplete="family-name"
                        //onChange={(e) =>props.handleChange(e)}
                        onChange={(e) =>  formOnchange(e,'handleChange','AROfficer')}
                      />
                    </Grid>

                    <Grid item xs={3} sm={3} hidden={FacilityList.length==0?true:(FLevelId==99?true:false)}>
                      <TextField
                       // disabled={props.bStockUpdate}
                       disabled={props.addProductForm==true?false: props.bStockUpdate}
                          
                        error={props.errorObject.CPhysician}
                        helperText={props.errorObject.CPhysician}               
                        id="CPhysician"
                        name="CPhysician"
                        label={DispensingLanguage[lan][menukey]['Coordinating Physician']} 
                        value={props.formData.CPhysician}
                        fullWidth
                        autoComplete="family-name"
                        //onChange={(e) =>props.handleChange(e)}
                        onChange={(e) =>  formOnchange(e,'handleChange','CPhysician')}
                      />
                    </Grid>


                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="TransactionId"
                        name="TransactionId"
                        label="TransactionId"  
                        value={props.formData.TransactionId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />


                    </Grid> 

                    
            
                  </Grid>
                </Grid>

                
                <Grid container spacing={3}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          <Grid container className="mb-2">



                    <Grid container spacing={1}>
                      
                      
                      {/* <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) =>handleChangeGrp(e)}
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>     */}


                      <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                            <Autocomplete
                                autoHighlight
                                className="sw_chosen_filter"
                                id="ItemGroupId"
                                disabled={props.addProductForm==true?true:false}
                                disableClearable
                                options={GroupList}               
                                onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                                getOptionLabel={(option) => option.name}
                                defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
                                
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={DispensingLanguage[lan][menukey]["Product Group"]}
                                  variant="standard"
                                  name="ItemGroupId"
                                />
                                )}
                              />                   
                            </FormControl>
                        </Grid>




                            <Grid item xs={3} sm={3} id="FID_scan_gtin_or_brandcode">
                              <FormControl className={classes.fullWidth}>
                                <TextField
                                  disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}
                                  
                                  id="scan_gtin_or_brandcode"
                                  name="scan_gtin_or_brandcode"
                                  label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                   
                                  autoComplete="family-name"
                                  ref={scan_gtin_or_brandcode}
                                  onKeyPress={handleKeyPress} 
                                  onKeyDown={handleKeyDown} 
                                  onKeyUp={handleKeyUp}
                                  
                                  
                                />
                              </FormControl>
                                      
                            </Grid> 

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button

                                disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>

                            {/* <Grid item xs={2} sm={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="bSerialFilter" 
                                    id="bSerialFilter"                                 
                                    onChange={handleSerialChange}
                                  />
                                }
                                label={DispensingLanguage[lan][menukey]["Serial Details"]}
                              />
                            </Grid> */}
                    




                    <div className="search_control_group dnone" id="scan_sscc_hide_show">
                      <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                      <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                    </div> 

                      

                      {/* <Grid item xs={5} sm={5}>

                        <div className="search_card">
                          <form>  
                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                              <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                            </div>
                            <div className="search_control_group" id="FID_scan_gtin_or_brandcode">
                              <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" ref={scan_gtin_or_brandcode} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                            </div>
                            <div className="search_control_group" id="FID_scanButton">
                              <Button
                                disabled={props.addProductForm==true?true: props.bStockUpdate}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()}
                              >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                              </Button>
                            </div>
                          </form>
                        </div>

                      </Grid> */}



                      <Grid item xs={2} sm={2}>
                        <div className="float-left"> 
                        <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                        </div>
                      </Grid>

                      


                            <Grid item xs={4} sm={4}>
                              <div className="float-right sw_btn_control" id="FID_pick_items_btn">
                                {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                                <Button
                                  disabled={(props.addProductForm==true?true: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate))|| isLoadingUpdate || isLoadingPost}
                                  
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={onDownloadOrderInvoiceBtnClick}
                                 
                                >
                                  <i class="fa fa-download" aria-hidden="true"></i><span>&nbsp;</span>
                                 {DispensingLanguage[lan][menukey]['Download_Order']} 
                                </Button> 


                                <Button
                                  disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}                         
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("ProductsOpen")}
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i><span>&nbsp;</span>
                                 {DispensingLanguage[lan][menukey]['Add_Items']} 
                                </Button> 

                                <Button
                                disabled={manyTableData.length>0? false:true}
                                variant="contained"
                                color="primary"
                                className="mr-2 sw_icons"
                                onClick={() => BarcodePDF()}
                                >
                                  <i class="fa fa-qrcode"></i>
                               
                                </Button>

                              </div>
                                
                              
                            </Grid>

                      </Grid>      


                      {/* <Grid item xs={12} sm={12}>
                           
                          <p id="is_dirty_message" className={classes.redtextcolor+' dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</p>
                          
                      </Grid> */}




                          </Grid> 
                          <IssueLotModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} />
                            
                          <IssuedToOrderModal
                          handleClose={handleClose}
                          open={openWarehouseInvoice}
                          addWarehouseInvoice={addWarehouseInvoice}
                          IssuedTo={props.formData.IssuedTo}
                          FormData={props.formData}
                          InvoiceType={"ORDER"}
                          refetch={props.refetch}
                          {...props}
                          />
                           <ReactTabulator
                                 
                                  ref={(r) => (ReceiveDetailsRef = r)}
                                  columns={ReceiveDetailsColumns}
                                  data={manyTableData}
                                  height="350px"
                                  options={{
                                    groupBy: "GroupName",
                                    columnCalcs: "both",
                                  }}
                                  layout={"fitColumns"}
                                />
                         
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* new row */} 
                  </Grid>
              </Grid>

 

            {/* </CardContent>
          </Card> */}
        </Grid>


       {/* Action buttons */}
         
      </Grid>
    </div>
  </div>
  );
};

export default IssueinvoiceItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  // redtextcolor: {
  //   color: "red", 
  //   fontWeight:600,
  //   textAlign: "center",
  //   lineHeight: "0px",
  //   paddingTop: "15px",
  // }
});
