import React, { useState, useEffect, useContext, Component,useRef } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select, Typography, TextField, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, useEventCallback } from "@material-ui/core";
import * as ScanBarcode from '../../../services/ScanBarcode';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import Notification from "../../../services/Notification";
import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ProductExpiredReport = (props) => {

  let TempZoneList = useState([]);
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const [scanObj, setscanObj] = useState({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});

  const [acValue, setACValue] = useState("");
  const [activeProductfre, setProductRefec] = useState("");

  const tmpStoreList = JSON.parse(localStorage.getItem("gStoreListforreceivesummary"));
  const [AStoreList, setStorelist] = useState(tmpStoreList);
  
  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  const [currStoreId, setStoreId] = useState();
  const [StoreName, setStoreName] = useState();


  

  const FlevelList = JSON.parse(localStorage.getItem("FlevelListForReport"));
  const [currFlavelId, setCurrFacilitylist] = useState(FlevelList[0].id);
  const [FacilityLavel, setFacilityLavel] = useState([]);

  // handleFacility Level Change
  const handleFacilityLevelChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currFlavelId) {
      setCurrFacilitylist(rowId);
      setFacilityLavel(rowName);
     // getStoreListData(rowId);
      setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
    }
    getFacilityData(rowId);

    let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: newValue.id,lan:lan, menukey: "" }
    cStoreList(StoreListParam);

  };


  React.useEffect(() => {
    if (currFlavelId == FlevelList[0].id) {
      setFacilityLavel(FlevelList[0].name);
      //getStoreListData(currFlavelId);
      let StoreListParam = { action: "getStoreForReceiveSummaryReport", FLevelId: currFlavelId,lan:lan, menukey: "" }
      cStoreList(StoreListParam);
    }



  }, [currFlavelId]);




  const getFacilityData = (rowId) =>{
    let FacilityParam = { action: "getFacility", FLevelId:rowId,lan:lan, menukey: "" }
    cFacilityList(FacilityParam);
  }

  const { mutate: cFacilityList } = useMutation(

    api.getFacility,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setFacilityData(data.data.datalist);
          setACValue({ id: '0', name: "All"});
        }
      },
    }
  );

  const [Facility_list, setFacilityData] = useState(TempZoneList);
  let All_FacilityList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityListDataItem = [All_FacilityList].concat(Facility_list);
  const [currFacilityId, setFacilityId] = useState("0");
  const [FacilityName, setFacilityName] = useState([]);


  const handleFacilityListChange = (event, newValue) => {
    setACValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName='';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currFacilityId) {
      setFacilityId(rowId);
      setFacilityName(rowName);
      setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
    }
  };


React.useEffect(() => {
  if(currFacilityId=='0'){
    getFacilityData(currFlavelId);
  }
    
  }, [currFacilityId]);


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM-DD') 
  );
  //console.log("Selected Date",selectedStartDate);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD') 
  );
  
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
  };




  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProdutlist] = useState(0);
  const [ProductGroupName, setProductGroupName] = useState([]);


  // Facility Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
      setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
    }

   
    getProductData(rowId);
    //getProductSerialTrackingReport.refetch();

  };


  const getProductData = (rowId) =>{
    let ProductParam = { action: "getProductItem", ItemGroupId:rowId,lan:lan, menukey: "" }
    cProductList(ProductParam);
  }

  const { mutate: cProductList } = useMutation(

    api.getProductListData,//api name
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setProductData(data.data.datalist);
          setProductRefec({ id: '0', name: "All"});
        }
      },
    }
  );


  // let TempZoneList = useState([]);
  const [Product_list, setProductData] = useState(TempZoneList);

  let All_ProduccgtList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const getProductList = [All_ProduccgtList].concat(Product_list);
  const [currProductId, setProductId] = useState("0");
  const [ProductName, setProductName] = useState([]);
// console.log(currProductId);


  const handleProductListChange = (event, newValue) => {
    setProductRefec(newValue);
    let rowId = '';
    let rowName= '';
    if (newValue == null) {
      rowId = '';
      rowName ='';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currProductId) {
      setProductId(rowId);
      setProductName(rowName);
      setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});
    }
   
  };


React.useEffect(() => {
  if(currProductId ==='0'){
    getProductData();
  }
 
  }, [currProductId]);

  // Store Change
  const handleStoreChange = (event, newValue) => {
    setACstoreValue(newValue);
    let rowId = '';
    let Sname = '';
    if (newValue == null) {
      rowId = '';
      Sname = '';
    } else {
      rowId = newValue.id;
      Sname = newValue.name;
    }
    if (rowId !== currStoreId) {
      setStoreId(rowId);
      setStoreName(Sname);
      setscanObj({GTIN:'',Brandcode:'',BatchNo:'',ExpiryDate:'',SerialNo:'',SSCC:''});

    }
  };

//store load by facility
const { mutate: cStoreList } = useMutation(
  api.StoreListForReport,
  {
    onSuccess: (data) => {
      if (data.status == 200) {

        // console.log("Dta list", data.data.datalist);
       
        setStorelist(data.data.datalist);
        setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});
        setStoreId(AStoreList[0].id);
        setStoreName(AStoreList[0].name);

      }

    },
  }
);


  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "getProductList",
    FLevelId: currFlavelId,
    FacilityId: currFacilityId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    ProductGroupId: currProductGroupId,
    ProductId: currProductId,
    StoreId: currStoreId,
    lan:lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.GeProductExpired,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  //-----------Scan Start--------------//

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1=obj;
    if(obj.duration==undefined)
    obj1={...obj,duration:900}
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  const handleRefetchCall = (data) => {
        console.log("Refetch Val",data);
         setDataItems(data.Data1);
         setscanObj(data.ScanObj);
  }

  const scan_gtin_or_brandcode = useRef(null);

  const doActionScanBarcode = () => {
 
    
    const ScanBarcodeParams = {
        ...params,
        TransactionTypeId: 1,         
        FacilityLevelId: 1,         
        AdjTypeId: 0, 
        handleRefetchCall: handleRefetchCall,
        UserId:UserId,
        UserName:UserName,
        UserRole:UserRole,
        ScanFor:"Reports",
        ErrorModal:openNoticeModal
      }
      
   ScanBarcode.ScanBarcodeAction(ScanBarcodeParams,  {...props}); 
  };
  
  
  const onClickScanBarcode = () => {  
    doActionScanBarcode();
  };
  
  const handleKeyPress = (event) => { 
    if(event.key === 'Enter'){    
      doActionScanBarcode();
    }
  }
  
  
  let altValue = "";
  const handleKeyDown = (e) => { 
    if (e.altKey) {    
      if (e.which !== 18) {
        altValue += e.which;
      }
      if (altValue === "969698105") {      
        document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
      }
    }
  };
  
  const handleKeyUp = (e) => {
    if (e.altKey === false) {
      altValue = "";
    }
  };

  //-----------Scan End---------------//



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "Itemcode",
      headerSort: false,
      headerFilter: true,
      width: 120,
    },
  
    {
      title: DispensingLanguage[lan][menukey]["Adj. Date"],
      field: "TransactionDate",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 90,
      formatter: function (cell) {
        let value = cell.getValue();
        if (value != null)
          return moment(value).format("DD/MM/YYYY");
        else
          return "";
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment_Invoice#"],
      field: "TransactionNo",
      headerFilter: true,
      headerSort: false,
      width: 180,
    },

    {
      title: DispensingLanguage[lan][menukey]["BrandCode"],
      field: "BrandCode",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       headerFilter: true,
       width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["CommercialName"],
      field: "CommercialName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
       headerFilter: true,
       width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerFilter: true,
      headerSort: false,
      width: 170,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
      formatter: function (cell) {
        let value = cell.getValue();
        if (value != null)
          return moment(value).format("DD/MM/YYYY");
        else
          return "";
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity_adjusted"],
      field: "Quantity",
      headerFilter: false,
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
  
    {
      title: DispensingLanguage[lan][menukey]["Unit_Price_CFA"],
      field: "UnitPrice",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",

    },
    {
      title: DispensingLanguage[lan][menukey]["Total_CFA"],
      field: "LineTotalPrice",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",

    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    if( (scanObj.GTIN == undefined) || ((scanObj.GTIN == '' ) && (scanObj.ExpiryDate == '') && (scanObj.SerialNo =='')
     && (scanObj.BatchNo == '') && (scanObj.Brandcode =='') && (scanObj.SSCC ==''))){
      window.open(
        finalUrl +
        "?action=getProductExpireReport" +
        "&reportType=" +
        reportType +
        "&FLevelId=" +
        currFlavelId +
        "&FacilityLavel=" +
        FacilityLavel +
        "&FacilityId=" +
        currFacilityId +
        "&FacilityName=" +
        FacilityName +
        "&ProductGroupId=" +
        currProductGroupId +
        "&ProductGroupName=" +
        ProductGroupName +
        "&ProductId=" +
        currProductId +
        "&ProductName=" +
        ProductName +
        "&StoreId=" +
        currStoreId +
        "&StoreName=" +
        StoreName +
        "&StartDate=" +
          StartDate +
          "&EndDate=" +
          EndDate +
          "&GTIN=" +
          scanObj.GTIN +
          "&ExpiryDate=" +
          scanObj.ExpiryDate +
          "&SerialNo=" +
          scanObj.SerialNo +
          "&BatchNo=" +
          scanObj.BatchNo +
          "&Brandcode=" +
          scanObj.Brandcode +
          "&SSCC=" +
          scanObj.SSCC +
        "&menukey=" +
        menukey +
        "&lan=" +
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
      )
     }else{
      window.open(
        finalUrl +
        "?action=getProductExpireReport" +
        "&reportType=" +
        reportType +
        "&FLevelId=" +
        currFlavelId +
        "&FacilityLavel=" +
        FacilityLavel +
        "&FacilityId=" +
        currFacilityId +
        "&FacilityName=" +
        FacilityName +
        "&ProductGroupId=" +
        currProductGroupId +
        "&ProductGroupName=" +
        ProductGroupName +
        "&ProductId=" +
        currProductId +
        "&ProductName=" +
        ProductName +
        "&StoreId=" +
        currStoreId +
        "&StoreName=" +
        StoreName +
        "&StartDate=" +
          StartDate +
          "&EndDate=" +
          EndDate +
          "&GTIN=" +
          scanObj.GTIN +
          "&ExpiryDate=" +
          scanObj.ExpiryDate +
          "&SerialNo=" +
          scanObj.SerialNo +
          "&BatchNo=" +
          scanObj.BatchNo +
          "&Brandcode=" +
          scanObj.Brandcode +
          "&SSCC=" +
          scanObj.SSCC +
          "&ScanFor=Reports"+
        "&menukey=" +
        menukey +
        "&lan=" +
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
      )
     }

  
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />

      <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>


      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
        <div className="d-flex justify-uiLanguage">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title1">
                {DispensingLanguage[lan][menukey]["Product_Expired_Report"]}
              </div>
              <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
            </div>
          </Grid>
          
            
          
        </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FlevelList"
                          disableClearable
                          options={FlevelList}
                          onChange={(event, newValue) => handleFacilityLevelChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          defaultValue={FlevelList[FlevelList.findIndex(FlevelList => FlevelList.id == currFlavelId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility Level"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FacilityListDataItem"
                          disableClearable
                          options={FacilityListDataItem}
                          value={acValue}
                          onChange={(event, newValue) => handleFacilityListChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          defaultValue={FacilityListDataItem[FacilityListDataItem.findIndex(FacilityListDataItem => FacilityListDataItem.id == currFacilityId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start date"]
                                }
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End date"]
                                }
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                  
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}

                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getProductList"
                          disableClearable
                          options={getProductList}
                          value={activeProductfre}
                          onChange={(event, newValue) => handleProductListChange(event, newValue)}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                  <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreList"
                      disableClearable
                      options={AStoreList}
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                     // defaultValue={AStoreList[AStoreList.findIndex(AStoreList => AStoreList.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Store"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                    {/* screen code here */}

                    <Grid item xs={4} sm={3} id="FID_scan_gtin_or_brandcode">
                    <FormControl className={classes.fullWidth}>
                    <TextField
                        //disabled={props.addProductForm==true?true:( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                      
                        id="scan_gtin_or_brandcode"
                        name="scan_gtin_or_brandcode"
                        label={DispensingLanguage[lan][menukey]["SCAN GTIN"]} 
                        
                        autoComplete="family-name"
                        ref={scan_gtin_or_brandcode}
                        onKeyPress={handleKeyPress} 
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp}
                        
                        
                    />
                    </FormControl>
                            
                </Grid> 

                <Grid item xs={1} sm={1} id="FID_scanButton">
                    <Button

                   // disabled={props.addProductForm==true?true: ( props.formData.InvFrom!=''?true:props.bStockUpdate)}
                    id="scanButton"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickScanBarcode()} 
                    >
                    {DispensingLanguage[lan][menukey]["Scan"]}
                    </Button> 
                </Grid>

                <div className="search_control_group dnone" id="scan_sscc_hide_show">
                    <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                    <input type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                </div>

                    </Grid>
                    </Grid>

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    options={{
                      groupBy: ["FacilityName","GroupName","ItemName"],
                      columnCalcs: "both",
                    }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default ProductExpiredReport;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});