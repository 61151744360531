import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery,useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  Typography,
  TextField,
  
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch
} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
const StockinTransit = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const Facility_List = JSON.parse(localStorage.getItem("getFacilityListForStockinTransit"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroup = [All_Product_Group].concat(Product_Group);



  const tmpStoreList = JSON.parse(localStorage.getItem("gStoreList"));
  const [AStoreList, setStorelist] = useState(tmpStoreList);
  // let All_StoreList = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const AStoreList = [All_StoreList].concat(gtmpStoreList);

  const [currFacilityId, setCurrFacilityId] = useState(FacilityList[0].id);
  const [currFacilityName, setCurrFacilityName] = useState([]);

  const [currProductGroupId, setProductGroupId] = useState("0");
  const [currIsStockId, setIsStockId] = useState("All");

  const [acValue, setACValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});
  const [currStoreId, setStoreId] = useState();
  const [currStoreName, setStoreName] = useState();
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );


  const [isLoading, setLoading] = useState(false);


  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname = '';
    if (newValue == null) {
      rowId = '';
      Fname = '';
    } else {
      rowId = newValue.id;
      Fname = newValue.name;
    }

    if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);

    }
  };




  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "getStockInTransitReport",
    lan: lan,
    FacilityId: currFacilityId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
   
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getStockStatusData,
    {
      onSuccess: (data) => {

        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["From Facility"],
      field: "FromFacilityName",
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["To Facility"],
      field: "ToFacilityName",
      headerSort: false,
      headerFilter: true,
    },


    {
      title: DispensingLanguage[lan][menukey]["Transaction No"],
      field: "TransactionNo",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 300,
      //formatter: "textarea",
    },
    {
      title: DispensingLanguage[lan][menukey]["Transaction Date"],
      field: "TransactionDate",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 300,
    },
    

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=StockinTransit" +
      "&reportType=" +
      reportType +
      "&FacilityId=" +
      currFacilityId +
      "&FacilityName=" +
      currFacilityName +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Stock in Transit Report"])}
                </div>
                {/* <div className="float-right sw_btn_control2">
                  <Button
                     className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div> */}
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FacilityList"
                      disableClearable
                      options={FacilityList}
                    
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

               
              

              


                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["Start date"]}
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      // disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={
                          DispensingLanguage[lan][menukey]["End date"]
                        }
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <div className="float-right sw_btn_control">

 
                  <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div>
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={435}
               
              />
            </div>
            {/* <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockinTransit;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
