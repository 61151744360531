import React from "react";
import "assets/css/custom.css";

const LoadingSpinner = () => {
  return (
	<div className="swPreloaderCenter">
	  <div className="sw_preloader">
		<span className="lds-dual-ring"></span>
	  </div>
	</div>
  );
};

export default LoadingSpinner;