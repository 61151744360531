import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";



//const UserInfo = JSON.parse(sessionStorage.getItem("User_info"));



const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const ProductGroup = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "product-group-entry";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(15);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const {
    error1,
    isError1,
    data: limitData,
    refetch: limitRefetch,
  } = useQuery(
    [
      {
        action: "getTotal",
        menukey: menukey,
      },
    ],
    api.getTotalProductgroup,
    {
      onSuccess: (data) => {
        
        if(data!=undefined)
        setTotalRow(Math.ceil(data[0].TotalRow / numberRowPerPage));

      }, 
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    },{ 

      useErrorBoundary: (error) => error.response?.status >= 500,
    
    }
  );

  


  let params = {
    action: "ShowDataList",
    menukey: menukey,
    page_limit: numberRowPerPage,
    page_number: pageNumber,
    search_key: "",
  };
  const {
    error,
    isError,
    data: uomData,
    refetch: tableData,
  } = useQuery([params], api.getProductGroupList, {
    onSuccess: (data) => {
      // setTotalRow(data.length);
      // setTotalRow(Math.round(data.length/numberRowPerPage));
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });



  const { mutate:deleteApi } = useMutation(api.deleteProductGroup, {
    onSuccess: (data) => {

     console.log(data);


      if (data.data.status == 200) {

        
        api.getAllDropdown('ProductGroup').then((response) => {
          localStorage.setItem(
            "ProductGroup",
            JSON.stringify(response.datalist.ProductGroup)
          );
        }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        tableData();
        queryClient.getQueriesData("productgroup");

        //swal(data.data.message, "", "success");
        
  
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
 


    },
    onError: (data) => {

      // console.log("vbdsjgf");
    }


  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getProductGroupEntryData" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")
        + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  let sl=0;
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
     // formatter: "rownum",
      formatter: function (cell, formatterParams) {
        sl++;
        return (pageNumber-1)*numberRowPerPage+sl;
      },
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
     
    },
    {
      title:  DispensingLanguage[lan][menukey]["ItemGroup Id"],
      field: "id",
      width: 70,
      headerSort: false,
      visible:false
    },
    { title: DispensingLanguage[lan][menukey]["Product Group Name"], headerFilter:true, field: "GroupName" },
    // {
    //   title: DispensingLanguage[lan][menukey]["Actions"],
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 130,
    //   headerSort: false,
    //   headerHozAlign: "center",
    //   formatter: reactFormatter(<ActionButton />),
    // },
  ];

  const editunit = (data) => {
    let route = `product_group/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Unit of measure!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({ItemGroupId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
        
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        />
      </>
    );
  }

  const handleChange = (event, value) => {
    console.log("value: ", value);
    console.log("event: ", event);

    setPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    // setNumberRowPerPage(parseInt(event.target.value, 10));
    //console.log('event.target.value: ', event.target.value);
    // setPageNumber(value);
  };
 

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title_inner">
                {" "}
                {
                  DispensingLanguage[lan][menukey]["Product Group"]
                }{" "}
              </div>
              <div className="float-right sw_btn_control">
                {/* <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/product_group/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add Product Group"]}
                </Button> */}
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
             
            />
          </div>
          <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage} 
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductGroup;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
