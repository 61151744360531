import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  FormGroup,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";


//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "order-type-entry";



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const RecipientGroupFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  function tabChangeHandler(event, newValue) {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Order Type Entry - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title= {DispensingLanguage[lan][menukey]['Order Type Entry Form'] }/>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={6} sm={8}>
                        <TextField
                          error={errorObject.LangText}
                          helperText={errorObject.LangText}
                          required
                          id="LangText"
                          name="LangText"
                          label={DispensingLanguage[lan][menukey]['Order Type Name'] }
                          // value={formData.FirstName || "Mahmudul"}
                          value={formData.LangText}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.bOrderCalculate}
                              onChange={(e) => handleCheck(e)}
                              name="bOrderCalculate"
                              value="on"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]['bOrder_Calculate'] }
                         
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.RecordId}
                          helperText={errorObject.RecordId}
                          hidden={true}
                          id="RecordId"
                          name="RecordId"
                          value={formData.RecordId}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/order_type")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']} 
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/order_type")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          {/* <Grid item xs={12} className="mt-2 text-center">
          <Button
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button
            className="mr-2"
            variant="contained"
            type="reset"
            onClick={() => props.history.push("/dispenser")}
          >
            Cancel
          </Button>
        </Grid> */}
        </Grid>
      </div>
    </div>
  );
};

export default RecipientGroupFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
