import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select, Typography, TextField, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, useEventCallback } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ShipmentReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);


  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  const [currProductGroupId, setCurrProdutlist] = useState(ProductGroup[0].id);
  const [ProductGroupName, setProductGroupName] = useState([]);

   console.log(ProductGroupName);

  // Facility Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
    }
   
  };


  React.useEffect(() => {
    if (currProductGroupId == ProductGroup[0].id) {
      setProductGroupName(ProductGroup[0].name);
    }
  }, [currProductGroupId]);
  
  const fundingsource = JSON.parse(localStorage.getItem("fundingsourceData"));

  let All_fundingsource = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const fundingsourceList = [All_fundingsource].concat(fundingsource);

  const [currfundingsourceId, setCurrfundingsourcetlist] = useState('0');
  const [fundingsourceName, setfundingsource] = useState([]);

  // console.log(currProductGroupId);

  // Facility Change
  const handlefundingsourceChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currfundingsourceId) {
      setCurrfundingsourcetlist(rowId);
      setfundingsource(rowName);
    }
   
  };


  const getShipment = JSON.parse(localStorage.getItem("getShipmentStatus"));

  let All_getShipment = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const getShipmentList = [All_getShipment].concat(getShipment);

  const [currsetShipmentId, setgetShipmentList] = useState('0');
  const [ShipmentName, setShipment] = useState([]);

  // console.log(currProductGroupId);

  // Facility Change
  const handleShipmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }if (rowId !== currsetShipmentId) {
      setgetShipmentList(rowId);
      setShipment(rowName);
    }
   
  };


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM') 
  );
  //console.log("Selected Date",selectedStartDate);

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM') 
  );
  
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };



  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getShipmentList",
    ProductGroupId: currProductGroupId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    fundingsourceId: currfundingsourceId,
    ShipmentId: currsetShipmentId,
    lan:lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getshipmentReport,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      headerFilter: false,
      headerSort: false,
      width: 150,

    },
    {
      title: DispensingLanguage[lan][menukey]["Products"],
      field: "ItemName",
      headerFilter: true,
      headerSort: false,
     

    },
    {
      title: DispensingLanguage[lan][menukey]["Funding Source"],
      field: "FundingSourceName",
      headerFilter: true,
      headerSort: false,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Status"],
      field: "ShipmentStatusDesc",
      headerSort: false,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of Expected Receive"],
      field: "ShipmentDate",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Qty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
    },


  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  let StartDate = moment(selectedStartDate).format("YYYY-MM");
   let EndDate = moment(selectedEndDate).format("YYYY-MM");
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=ShipmentReportList" +
      "&reportType=" +
      reportType +
      "&ProductGroupId=" +
      currProductGroupId +
      "&ProductGroupName=" +
      ProductGroupName +
      "&fundingsourceName=" +
      fundingsourceName +
      "&ShipmentName=" +
      ShipmentName +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&menukey=" +
      menukey +
      "&lan=" +
     localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
        <div className="d-flex justify-uiLanguage">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title1">
                {DispensingLanguage[lan][menukey]["Shipment Report"]}
              </div>
              <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
            </div>
          </Grid>
        </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}

                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="fundingsourceList"
                          disableClearable
                          options={fundingsourceList}

                          onChange={(event, newValue) => handlefundingsourceChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={fundingsourceList[fundingsourceList.findIndex(fundingsourceList => fundingsourceList.id == currfundingsourceId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Funding Source"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getShipmentList"
                          disableClearable
                          options={getShipmentList}

                          onChange={(event, newValue) => handleShipmentChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={getShipmentList[getShipmentList.findIndex(getShipmentList => getShipmentList.id == currsetShipmentId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Shipment Status"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                views={["year", "month"]}
                                variant="inline"
                                format="MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start date"]
                                }
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                views={["year", "month"]}
                                variant="inline"
                                format="MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End date"]
                                }
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                  
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height="600"
                    // options={{
                    //   groupBy: "GroupName",
                    //   columnCalcs: "both",
                    // }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default ShipmentReport;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});