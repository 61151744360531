import React, { useState } from "react";

// Auth token & services
import * as Service from "../../services/Service.js";

//Css import
import "assets/css/custom.css";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

//Page Components
import PostDetailsContent from "../pages-content/PostDetailsContent.js";

function DetailsPost(props) {
  return (
    <>
      {Service.default.authToken() != null ? (
        <AfterLoginNavbar {...props} />
      ) : (
        <IndexNavbar {...props} />
      )}
      <div className="wrapper">
        <div className="main min-height">
          <PostDetailsContent {...props}  />
        </div>
        <DarkFooter  {...props}  />
      </div>
    </>
  );
}

export default DetailsPost;
