import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import { Select, Typography, TextField, FormControl, Grid, Card, CardHeader, CardContent, MenuItem, InputLabel, useEventCallback } from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const MasterProductList = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);


  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "ProductGroupList",
    lan:lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   // mode: bEsigl

  };


  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.ProductGroup,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Product Group Name"],
      field: "GroupName",
      headerFilter: true,
      headerSort: false,


    },
    {
      title: DispensingLanguage[lan][menukey]["Short Name"],
      field: "ShortName",
      headerFilter: true,
      headerSort: false,


    },
   

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=ProductGroupExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
     localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now() 
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
              <Grid item xs={10} sm={10}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Product Group"]}
                </div>
              </div>
              </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right">
                  <Button
                 className={classes.sw_btn_control21}
                 
                  onClick={() => props.history.push("/esigl-reports")}
                >
                   {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>
                &nbsp; &nbsp;
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>
                   
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                

              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    // options={{
                    //   groupBy: "GroupName",
                    //   columnCalcs: "both",
                    // }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}




        </div>
      </div>
    </>
  );
};



export default MasterProductList;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  sw_btn_control21:
  {
    backgroundColor:"#e4e4e4",
    padding:"9px 10px",
    color: "#545252",
    fontWeight:"bold",
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  }
});