import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import IssueinvoiceItems from './IssueinvoiceItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";


const Editissueinvoice = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issue-invoice";
const FacilityId=localStorage.getItem('FacilityId');
const TransactionTypeId = 2;

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const userid = UserInfo == 0 ? "" : UserInfo[0].id;
const RoleId = UserInfo == 0 ? "" : UserInfo[0].RoleId;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
   

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionId:"",
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",  
    "FacilityId":FacilityId,
    TransactionTypeId:TransactionTypeId,
    ApprovedBy:"",
    IssuedToGrp:"",
    IssuedTo:"",
    PreparedBy:"", 
    Remarks: "",
    StoreId:"",
    IsPaid:1,
    ItemGroupId:"0",
    InvFrom:'',
    AROfficer:"",
    CPhysician:""
    });
    const [selectedDate, setSelectedDate] = useState(
      new Date()
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [SerialDetails, setSerialDetails] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  
  const [bFirst, setBFirst] = useState(true); 
  const [ItemGroupId, setItemGroupId] = useState("0");
  const [dirty, setDirty] = useState(false);
   
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['issue-invoice', id], () =>
    api.getReceiveInv({TransactionId:id,ItemGroupId:ItemGroupId,TransactionTypeId:TransactionTypeId,SerialDetails:SerialDetails}).then((res) => {
      setDirty(true);
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDeletedDataSet([]);
      setDirty(false);
      setBFirst(true);

      
      return res.data
    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
  })

  
  const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
 
        setbStockUpdate(data.bStockUpdated);
        if(data.bStockUpdated==1){

          mutatePost({ 
          menukey: menukey, 
          TransactionId: TransactionId,
          TransactionTypeId: 2,
          });
        
        }else{
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal("Success!", "", "success");
        }


      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading1, mutate:mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 


        console.log(data);

        setbStockUpdate(1);
        setDeletedDataSet([]);

        if(data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{

          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        setbStockUpdate(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


 
  
  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
    

};
  const handleChange = (e) => {
    
    if(e=='IssuedTo'){
      setErrorObject({ ...errorObject, ['IssuedTo']: null });
    }
    else{

    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;            
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });

    }


  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "", 
      "FacilityId":FacilityId,
      TransactionTypeId:TransactionTypeId ,
      TransactionId:"",
        
      PreparedBy:"",
      Remarks: "",
      InvFrom:'',
      AROfficer:"",
      CPhysician:""
    });
  };

  const handleModalCheck = () => {
    if (validateForm(formData,2)) {
      return true;
    }else{
      return false;
    }
  };

  const filterHandleChange = (e) => {
    setItemGroupId(e) ;
  };

  const serialHandleChange = (e) => {
    setSerialDetails(e) ;
  };

  const validateForm = (formData,checkSupplierInv) => {
    let validateFields = checkSupplierInv==2?["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ApprovedBy","IssuedToGrp","IssuedTo"]:
    ["TransactionDate", "TransactionNo","PreparedBy","StoreId","SupplierInvDate","ApprovedBy","IssuedToGrp","IssuedTo"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  /*
  const deleteReceiveInv=(data)=>{
    console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }

  */

  const deleteReceiveInv=(data,deletesetdata)=>{ 

    setUpdatedDetailGridData(data);
    setDeletedDataSet(deletesetdata);

  }

  /*

  const handleUpdate = async (e) => {
    if (validateForm(formData,1)) {
    let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
    mutateUpdate(fDtat); 
    }
  };

 
  const handlePost = async (rows,deletedsetdata) => {  

    
    if (validateForm(formData,1)) {
       
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet,menukey: menukey,lan: lan,"UserName":UserName};
          mutateUpdate(fDtat); 
          setManyTableData(rows); 
          
        }else{
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

      
    }
     
     
  };

  */


  //api.saveSelectedOrderInvoice
  const { isLoading2, mutate:mutateDownloadOrderInvoice } = useMutation(api.updateDownloadOrderInvoice, {
    onSuccess: (data) => { 
      if (data.status == 200) { 
      
        if(data.data.success==0){
          
          

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
                
         
         }else{

          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


  const DownloadOrderInvoice = async (e) => {  
   
    if (validateForm(formData,1)) {
       
          let fData={...formData};
          mutateDownloadOrderInvoice(fData); 
        
      };  
   
  };

  /*
  const setUpdatedDetailsGridDataHandler = (rows,deletedsetdata) => {
       
    if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan,"UserName":UserName}; 
      mutateUpdate(fDtat); 
      }
  };

  */

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData,1)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function successSave(bStockUpdated){
    if(bStockUpdated !==1 ){
      refetch();
    }
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };


  useEffect(() => {
    refetch();
   
  }, [ItemGroupId]);


  useEffect(() => {
    refetch();
   
  }, [SerialDetails]);

  
  return (
    <>
      <IssueinvoiceItems 

        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
       // handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
       // handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        bFirst={bFirst}
        DownloadOrderInvoice={DownloadOrderInvoice}
        handleModalCheck={handleModalCheck}
        filterHandleChange={filterHandleChange} 
        refetch={refetch}
        dirty={dirty}
        checkValidateForm={checkValidateForm}
        successSave={successSave}
        stockPosted={stockPosted}
        serialHandleChange={serialHandleChange}
        {...props} />
    </>
  );
};

export default Editissueinvoice;