import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import {
FormControl, Grid, Card, CardHeader, CardContent, Typography, TextField,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch

} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

//Import Facility Reporting Rate
import FacilityReportingRate from "services/FacilityReportingRate";
import PIVOT from "./PIVOT";
import PIVOT2 from "./Pivot1";
const SVDLStockDashboardAnalyse = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));


  const Warehouse = JSON.parse(localStorage.getItem("GetWarehouse"));
  const [currWarehouseId, setWarehouseId] = useState(Warehouse[0].id);
  const [currWarehouseame, setIWarehousename] = useState(Warehouse[0].name);

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);


  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  const [currYearId, setYearId] = useState(currentDate.getFullYear());




  const [isLoadingUi, setLoading] = useState(true);

  exporting(Highcharts);



  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };

  // Product Group Change
  const handleWarehouseChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currWarehouseId) {
      setWarehouseId(rowId);
      setIWarehousename(rowName);
    }
  };

 


  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);


    }
  };


  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);



    }
  };



  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    // RegionId: currDepartmentId,
    // ZoneId: currZoneIdId,
    // DistrictId: currCommuneId,
    // ItemGroupId: currProductGroupId,
    // MonthId: currmonthId,
    // Year: currYearId,
    // ZoneName: currZonename,
    // BCZName: currCommuneame,
    // ProvinceName: currDepartmentName,
    // getStatusListId: currGetStatusListId,
    // getStatusname: currGetStatusname,

  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);

        // console.log("rate", data.data.datalist);
        // console.log("fl", data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  //Pivot chart data

  const [pivotData, setpivotData] = useState([]);
  const [pivotfieldlist, setpivotfieldlist] = useState({});
  const [pivotcellstyle, setpivotfcellstyle] = useState({});
  //console.log('pivot color',pivotcellstyle);
  const [pivotfields, setpivotfields] = useState({});




  //const [getParentByProduct, setParentByProduct] = useState([]);
  //  console.log('Data pivto data',getParentByProduct);

  let ParentByProduct = {
    menukey: menukey,
    action: "StockDashboardAnalyseParentByProductList",
    lan: lan,
    WarehouseId: currWarehouseId,
    ItemGroupId: currProductGroupId,
    Warehouseame: currWarehouseame,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: Pivotreport } = useQuery(
    [ParentByProduct],
    api.StockDashboardAnalyseParentByProduct,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        //setParentByProduct(data.data.datalist);

        setpivotData(data.data.datalist.datalist);
        setpivotfieldlist(data.data.datalist.fieldlist);
        setpivotfcellstyle(data.data.datalist.cellstyle);


        let fields = data.data.datalist.fields;
        //console.log("+++++++++++++++++++++++++++++++");
        let tmpfields = [];
        for (let i = 0; i < data.data.datalist.fields.values.length; i++) {

          tmpfields.push({ id: data.data.datalist.fields.values[i].id, method: data.data.datalist.fields.values[i].method, "$field": "values", "$temp": true });


        }

        // if(data.data.datalist.datalist.length>0){
        //console.log("yyyyy",data.data.datalist.fields.columns);

        let tmpcolumn = [];
        for (let i = 0; i < data.data.datalist.fields.columns.length; i++) {

          tmpcolumn.push({ id: data.data.datalist.fields.columns[i].id, "$field": "columns" });


        }
        fields.rows = [{ id: fields.rows[0], "$field": "rows" }];
        fields.columns = tmpcolumn;
        fields.values = tmpfields;


        //  }
        setpivotfields(fields);
        // console.log(fields);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  //Pivot chart data 2

  const [pivotData2, setpivotData2] = useState([]);
  const [pivotfieldlist2, setpivotfieldlist2] = useState({});
  const [pivotcellstyle2, setpivotfcellstyle2] = useState({});
  //console.log('pivot color',pivotcellstyle);
  const [pivotfields2, setpivotfields2] = useState({});




  const [getParentByProduct, setParentByProduct] = useState([]);
   //console.log('Data pivto data',getParentByProduct);

  let ParentByProductPivot = {
    menukey: menukey,
    action: "StockDashboardAnalyseParentByProductChildpivotList",
    lan: lan,
    WarehouseId: currWarehouseId,
    ItemGroupId: currProductGroupId,
    Warehouseame: currWarehouseame,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
  };

  const { data: Pivotreport2 } = useQuery(
    [ParentByProductPivot],
    api.StockDashboardAnalyseParentByProduct,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setParentByProduct(data.data.datalist);

        setpivotData2(data.data.datalist.datalist);
        setpivotfieldlist2(data.data.datalist.fieldlist);
        setpivotfcellstyle2(data.data.datalist.cellstyle);


        let fields = data.data.datalist.fields;
        //console.log("+++++++++++++++++++++++++++++++");
        let tmpfields = [];
        for (let i = 0; i < data.data.datalist.fields.values.length; i++) {

          tmpfields.push({ id: data.data.datalist.fields.values[i].id, method: data.data.datalist.fields.values[i].method, "$field": "values", "$temp": true });


        }

        // if(data.data.datalist.datalist.length>0){
        //console.log("yyyyy",data.data.datalist.fields.columns);

        let tmpcolumn = [];
        for (let i = 0; i < data.data.datalist.fields.columns.length; i++) {

          tmpcolumn.push({ id: data.data.datalist.fields.columns[i].id, "$field": "columns" });


        }
        fields.rows = [{ id: fields.rows[0], "$field": "rows" }];
        fields.columns = tmpcolumn;
        fields.values = tmpfields;


        //  }
        setpivotfields(fields);
        // console.log(fields);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const [dataMos, setDataMosc] = useState([]);
 //console.log('mostdata',dataMos);
    let params = {
      menukey: menukey,
      action: "svdlStockDashboardAnalyseList",
      lan: lan,
      WarehouseId: currWarehouseId,
      ItemGroupId: currProductGroupId,
      Warehouseame: currWarehouseame,
      Year: currYearId,
      ProductGroup: currItemGroupname,
      MonthName: currMonthName,

    };



  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.svdlStockDashboardAnalyse,
    {
      onSuccess: (data) => {
        setDataMosc(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

//Map section


  //Start National Stock Status (Percentage of Products)
  const [svdlStockDashboardAnalysepinList, setOptiongetsvdlStockDashboardAnalysepinList] = useState([{name: 'No Data', y: 0, color: '#ffffff'}]);
  
  let PieChartParams = {
    menukey: menukey,
    action: "getsvdlStockDashboardAnalysepinList",
    lan: lan,
    WarehouseId: currWarehouseId,
    ItemGroupId: currProductGroupId,
    Warehouseame: currWarehouseame,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    
   
  };

  const { data: PieChartLoad } = useQuery(
    [PieChartParams],
    api.getsvdlStockDashboardAnalyse,
    {
      onSuccess: (data) => {
        handleRefetchCallForPieChart(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const handleRefetchCallForPieChart = (resdata) => {
    let seriesdata = resdata.data;
    
    setOptiongetsvdlStockDashboardAnalysepinList({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 400,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false
      },
      exporting: {
        filename: 'National_Stock_Status_Percentage_of_Products'
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            },
            formatter: function () {
               return Highcharts.numberFormat(this.y);
            }
          },
          tooltip: {
            shared:true
          }
        },
        pie: {					
          plotBorderWidth: 0,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            }
            ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
          },
          showInLegend: true,
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
            shared:true
          }
        }
      },
      series: [
        {
          name: DispensingLanguage[lan][menukey]["Value (%)"],
          colorByPoint: true,
          data: seriesdata,
          // data: [['a', 50], ['b', 25], ['c', 75]]
        },
      ],
    })
  };
  // End National Stock Status (Percentage of Products)


  const [dataMos1, setDataMosc2] = useState([]);
//  console.log('legent data 22',dataMos1);
    let params2 = {
      menukey: menukey,
      action: "svdlStockDashboardAnalyseList2",
      lan: lan,
      WarehouseId: currWarehouseId,
      ItemGroupId: currProductGroupId,
      Warehouseame: currWarehouseame,
      Year: currYearId,
      ProductGroup: currItemGroupname,
      MonthName: currMonthName,
      MonthName: currMonthName,

    };



  const {data: Pivotreport1 } = useQuery(
    [params2],
    api.svdlStockDashboardAnalyse,
    {
      onSuccess: (data) => {
        setDataMosc2(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

//Map section


// 2nd or many pi chart section 


  //Start National Stock Status (Percentage of Products)
  const [svdlStockDashboardAnalyFacilityList, setsvdlStockDashboardAnalyFacilitywiseList] = useState([{name: 'No Data', y: 0, color: '#ffffff'}]);
  // console.log('dataset Facility',svdlStockDashboardAnalyFacilityList)
  let PieChartParamsFacility = {
    menukey: menukey,
    action: "getsvdlStockDashboardAnalyFacilityList",
    lan: lan,
    WarehouseId: currWarehouseId,
    ItemGroupId: currProductGroupId,
    Warehouseame: currWarehouseame,
    Year: currYearId,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
   
  };

  const { data: PieChartFacility } = useQuery(
    [PieChartParamsFacility],
    api.getsvdlStockDashboardFacilityList,
    {
      onSuccess: (data) => {
        if(data.data != null){
         // console.log('get data',data.data.datalist);
          handleRefetchCallForPieFaciityChart(data.data.datalist);
        }
       
       //handleRefetchCallForPieFaciityChart(data.data.datalist);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const handleRefetchCallForPieFaciityChart = (resdata) => {
    //console.log('get map data',resdata[0].data);
   // let seriesdataFacility = resdata.data;
    let seriesdataFacility = resdata[0].data;
    
    setsvdlStockDashboardAnalyFacilitywiseList({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 400,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false
      },
      exporting: {
        filename: 'National_Stock_Status_Percentage_of_Products'
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            },
            formatter: function () {
               return Highcharts.numberFormat(this.y);
            }
          },
          tooltip: {
            shared:true
          }
        },
        pie: {					
          plotBorderWidth: 0,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
            ,style: {
              textShadow: false,
              textOutline: false 
            }
            ,format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)"
          },
          showInLegend: true,
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
            shared:true
          }
        }
      },
      series: [
        {
          name: DispensingLanguage[lan][menukey]["Value (%)"],
          colorByPoint: true,
          data: seriesdataFacility,
          // data: [['a', 50], ['b', 25], ['c', 75]]
        },
      ],
    })
  };
  // End National Stock Status (Percentage of Products)





  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=svdlFacilityReportingStatus" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["svdl_stock_dashboard_analyse"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
              
               
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Warehouse"
                      disableClearable
                      options={Warehouse}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleWarehouseChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Warehouse[Warehouse.findIndex(Warehouse => Warehouse.id == currWarehouseId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Warehouse"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            


            </CardContent>
          </Card>

          {/* Facility Reporting Rate */}
          <FacilityReportingRate 
            ReportingLabelList={getFLvelelList} 
            ReportingValueList={getReportingRate}
            lan={lan}
            menukey={menukey}
          />
          {/* Facility Reporting Rate */}


        {/* start row */}
        <div className="row">
            <div className="col-md-12">
                <Card className="sw_card">
                  <CardContent>
                    <div dangerouslySetInnerHTML={{__html: dataMos}} />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}

        {/* start row */}
         <div className="row mt-3">
            <div className="col-md-4 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["Stock Dashboard Analyse Parent"]}
                  /> 
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={svdlStockDashboardAnalysepinList}
                      height={400}
                    />
                  </CardContent>
                </Card>
            </div>
            <div className="col-md-8 mb-8">
                <Card className="sw_card">
                  <CardContent>
                    <PIVOT 
                      pivotfieldlist={pivotfieldlist} 
                      pivotfields={pivotfields} 
                      //mark={pivotcellstyle} 
                      pivotdata={pivotData} 
                      {...props} 
                    />
                  </CardContent>
                </Card>
            </div>
          </div>

        {/* start row */}
        <div className="row mt-3">
            <div className="col-md-12">
                <Card className="sw_card">
                  <CardContent>
                    <div dangerouslySetInnerHTML={{__html: dataMos1}} />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}
        {/* start row */}
        <div className="row mt-3">
            <div className="col-md-12">
            <Card className="sw_card">
                  
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={svdlStockDashboardAnalyFacilityList}
                      height={400}
                    />
                  </CardContent>
                </Card>
            </div>
          </div>
          {/* end row */}

          <div className="row mt-3">
            <div className="col-md-12">
                <Card className="sw_card">
                  <CardContent>
                    <PIVOT2 
                      pivotfieldlist={pivotfieldlist2} 
                      pivotfields={pivotfields2} 
                     // mark={pivotcellstyle2} 
                      pivotdata={pivotData2} 
                      {...props} 
                    />
                  </CardContent>
                </Card>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default SVDLStockDashboardAnalyse;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
