import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";


// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";




//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receipts";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const OrderModal =({ handleClose, openOrder, addOrder, OrderingFrom, TransactionId, gStoreId, ...props }) => {
  
  
let tblOrderRef = null;
  
  const [dataItems, setDataItems] = useState([]);
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");
  

  let params = {
    menukey: menukey,
    action: "getOrderBookData",
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan:lan,
    StoreId:StoreId,
    OrderingFrom:OrderingFrom
  };


  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProductsList,{
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  // refetchOnWindowFocus: false,
  // refetchOnmount: false,
  // refetchOnReconnect: false,
  // retry: false,
  // staleTime: 0,//1000 * 60 * 60 * 24,

  // const { data } = useQuery(
  //   "NonReceive",
  //   api.getNonReceivedOrderList
  // );
   
  const OrderModalColumns = [
    { title: "id", field: "id", visible: false }, 
    { title: DispensingLanguage[lan][menukey]['SL'], 
      formatter: "rownum", width:80, hozAlign: "center",
     headerHozAlign:"center", headerSort:false },


     { title: DispensingLanguage[lan][menukey]['Order_Date'],

     field: "OrderDate", 
     width:210,
      formatter: function (cell, formatterParams) {
      let value = cell.getValue();
      return (value==null?'':moment(value).format("DD/MM/YYYY"));
      
    } },

    { title: DispensingLanguage[lan][menukey]['Order_Type'],
    field: "OrderType"},

    { title: DispensingLanguage[lan][menukey]['Order#'],
     field: "OrderNo"  },
   
   
  ]; 

  // const OrderModaltDataItems = data?.map((item, index) => { 
  //   return item;
  // });

  const {mutate} = useMutation(api.saveNonReceivedOrder, {
    onSuccess: (data)=>{
       
      addOrder(data);
      //queryClient.getQueriesData('receive');
    }
  })
  const SaveSelectedOrder = () => { 

    if(tblOrderRef.table.getSelectedData().length>0)
    mutate({OrderId:tblOrderRef.table.getSelectedData()[0].OrderId, TransactionId:TransactionId, StoreId:gStoreId, language:lan}); 
   
  };

  
useEffect(() => { 
    if (tblOrderRef == null) return;
 

}, []); 

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('OrderModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('OrderModal')}
        >
      
        {DispensingLanguage[lan][menukey]['Add_Ordered']} 

        </BootstrapDialogTitle>
        <DialogContent dividers>  
          <ReactTabulator ref={(r) => (tblOrderRef = r)} 
           selectable={1} 
           columns={OrderModalColumns}
           data={dataItems}
           height="300px" 
          />
        </DialogContent>
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={SaveSelectedOrder}>
          {DispensingLanguage[lan][menukey]['Submit']}  
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('OrderModal')}>           
            {DispensingLanguage[lan][menukey]['Cancel']}  
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default OrderModal;
