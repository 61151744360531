
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import DateFnsUtils from "@date-io/date-fns";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useParams } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";
let tblRef = null;






const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const userId = UserInfo == 0 ? '' : UserInfo[0].id;

const DispenserFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const RoleList = JSON.parse(localStorage.getItem("RoleList"));
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));
  const [RoleacValue, setRoleValue] = useState({ id: '', name: "" });
  const [FacilityacValue, setFacilityValue] = useState({ id: '', name: "" });
  const [MasterData, setMasterData] = useState({});
  const { id } = useParams();

  const getRxLevel = (type = null) => {
    let level = "";
    if (formData.rxLevel) {
      level = formData.rxLevel;
    }
    return level;
  };
  const [value, setValue] = React.useState(0);
  const [ProvinceData, setProvinceData] = useState([]);
  const [ZoneData, setZoneData] = useState([]);
  const [BCZData, setBCZData] = useState([]);


  let params = {
    "menukey": menukey,
    action: 'getProvinceList',
    "UserId": id,
    "lan": lan,


  };
  const { refetch: refetch1 } = useQuery([params],
    api.getProvinceData,
    {
      onSuccess: (data) => {

        // console.log("zonedata",data);
        setProvinceData(data);


      }


    }, {
    enabled: Boolean(id),
  }
  );



  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {

    if (tblRef == null) return;
    setProvinceData(ProvinceData);

  }, [ProvinceData]);


  const { isLoadingLang, mutate: bCheckedUpdate } = useMutation(
    api.InsertMappingProvince,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          refetch1();
          refetch2();

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }

      },
    }
  );


  const { isLoadingLang1, mutate: bCheckedUpdateZone } = useMutation(
    api.InsertMappingZone,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          refetch2();
          refetch3();

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }


      },
    }
  );


  const { isLoadingLang2, mutate: bCheckedUpdateBCZ } = useMutation(
    api.InsertMappingBCZ,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          refetch3();

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }

      },
    }
  );
  const { isLoadingLang3, mutate: SelectDeselectUpdate } = useMutation(
    api.InsertDeleteSelectDeselect,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          refetch1();
          refetch2();
          refetch3();

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }



      },
    }
  );

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    // let labelName = props.labelName;
    // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }



  const columns = [
    {
      title: "RegionId",
      field: "RegionId",
      visible: false,
    },
    {
      title: "RegionMapId",
      field: "RegionMapId",
      visible: false,
    },
    {
      // title: DispensingLanguage[lan][menukey]["Role Access"],
      field: "chkValue",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="chkValue"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const RegionId = rowdata.RegionId;
        const RegionMapId = rowdata.RegionMapId;

        cellDataUpdatePop(RegionId, RegionMapId, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Departments"],
      field: "RegionName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];
  const cellDataUpdatePop = (RegionId, RegionMapId, cell) => {
    let curcellval = cell.getValue();
    let chkValue = curcellval;



    bCheckedUpdate({ id, RegionId, RegionMapId, chkValue });

  };

  let params1 = {
    "menukey": menukey,
    action: 'getZoneList',
    "UserId": id,
    "lan": lan,


  };
  const { refetch: refetch2 } = useQuery([params1],
    api.getZoneData,
    {
      onSuccess: (data) => {

        // console.log("zonedata",data);
        setZoneData(data);


      }


    }, {
    enabled: Boolean(id),
  }
  );


  const columns1 = [
    {
      title: "ZoneId",
      field: "ZoneId",
      visible: false,
    },
    {
      title: "ZoneMapId",
      field: "ZoneMapId",
      visible: false,
    },
    {
      // title: DispensingLanguage[lan][menukey]["Role Access"],
      field: "chkValue",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="chkValue"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const ZoneId = rowdata.ZoneId;
        const ZoneMapId = rowdata.ZoneMapId;

        cellDataUpdatePopzone(ZoneId, ZoneMapId, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];
  const cellDataUpdatePopzone = (ZoneId, ZoneMapId, cell) => {
    let curcellval = cell.getValue();
    let chkValue = curcellval;

    bCheckedUpdateZone({ id, ZoneId, ZoneMapId, chkValue });

  };


  let params2 = {
    "menukey": menukey,
    action: 'getBCZList',
    "UserId": id,
    "lan": lan,


  };

  const { refetch: refetch3 } = useQuery([params2],
    api.getBCZData,
    {
      onSuccess: (data) => {

        // console.log("BCZdata", data);
        setBCZData(data);


      }
    }, {
    enabled: Boolean(id),
  }
  );


  const columns2 = [
    {
      title: "DistrictId",
      field: "DistrictId",
      visible: false,
    },
    {
      title: "DistrictMapId",
      field: "DistrictMapId",
      visible: false,
    },
    {
      // title: DispensingLanguage[lan][menukey]["Role Access"],
      field: "chkValue",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="chkValue"
          fieldType="check-box"

        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const DistrictId = rowdata.DistrictId;
        const DistrictMapId = rowdata.DistrictMapId;

        cellDataUpdatePopBCZ(DistrictId, DistrictMapId, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "DistrictName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];

  const cellDataUpdatePopBCZ = (DistrictId, DistrictMapId, cell) => {
    let curcellval = cell.getValue();
    let chkValue = curcellval;

    bCheckedUpdateBCZ({ id, DistrictId, DistrictMapId, chkValue });

  };
  const SelectDeselect = (ProductGroupRegion, PRSelectDeselect) => {

    SelectDeselectUpdate({ id, ProductGroupRegion, PRSelectDeselect });
  }

  useEffect(() => {

    
    setRoleValue(RoleList[RoleList.findIndex(Role_List => Role_List.id == formData.role_id)]);
    setFacilityValue(FacilityList[FacilityList.findIndex(Facility_List => Facility_List.id == formData.FacilityId)]);


  }, [formData.role_id,formData.FacilityId]);

  const changeRoleId = (val) => {

    setRoleValue(val);


    if (val != null) {
      formData.role_id = val.id;
    } else {
      formData.role_id = "";
    }


  };
  
  const changeFacilityId = (val) => {

    setFacilityValue(val);


    if (val != null) {
      formData.FacilityId = val.id;
    } else {
      formData.FacilityId = "";
    }


  };
  
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["User - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">
                    <div className="float-right sw_btn_control">
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/user-entry")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/user-entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.name}
                      helperText={errorObject.name}
                      required
                      id="name"
                      name="name"
                      label={DispensingLanguage[lan][menukey]["Name"]}
                      value={formData.name}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.email}
                      helperText={errorObject.email}
                      required
                      id="email"
                      name="email"
                      label={DispensingLanguage[lan][menukey]["Email"]}
                      value={formData.email}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.loginname}
                      helperText={errorObject.loginname}
                      required
                      id="loginname"
                      name="loginname"
                      label={DispensingLanguage[lan][menukey]["Login Name"]}
                      value={formData.loginname}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.password}
                      helperText={errorObject.password}
                      required
                      id="password"
                      name="password"
                      type="password"
                      label={DispensingLanguage[lan][menukey]["Password"]}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.designation}
                      helperText={errorObject.designation}
                      required
                      id="designation"
                      name="designation"
                      label={DispensingLanguage[lan][menukey]["Designation"]}
                      value={formData.designation}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Language"]}*
                      </InputLabel>
                      <Select
                        error={errorObject.LangCode}
                        labelId="demo-simple-select-helper-label"
                        id="LangCode"
                        name="LangCode"
                        value={formData.LangCode || 'fr_FR'}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {LanguageList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.LangCode}>
                        {errorObject.LangCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>


                      <Autocomplete
                        disabled={formData.id == 1 ? true : false}
                        autoHighlight
                        id="role_id"
                        options={RoleList}
                        onChange={(event, value) => changeRoleId(value)}
                        getOptionLabel={(option) => option.name}
                        value={RoleacValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.role_id}
                            label={DispensingLanguage[lan][menukey]["Role"]}
                            variant="standard"
                            error={errorObject.role_id}
                            helperText={errorObject.role_id}
                            required
                            id="role_id"
                            name="role_id"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>

                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Role"]}*
                      </InputLabel>
                      <Select
                        disabled={formData.id == 1 ? true : false}
                        error={errorObject.role_id}
                        labelId="demo-simple-select-helper-label"
                        id="role_id"
                        name="role_id"
                        value={formData.role_id}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {RoleList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.role_id}>
                        {errorObject.role_id}
                      </FormHelperText>
                    </FormControl> */}
                  </Grid>

                  <Grid item xs={4} sm={4}>

                    <FormControl className={classes.fullWidth}>


                      <Autocomplete
                        autoHighlight
                        id="FacilityId"
                        options={FacilityList}
                        onChange={(event, value) => changeFacilityId(value)}
                        getOptionLabel={(option) => option.name}
                        value={FacilityacValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.FacilityId}
                            label={DispensingLanguage[lan][menukey]["Facility Name"]}
                            variant="standard"
                            error={errorObject.FacilityId}
                            helperText={errorObject.FacilityId}  
                            id="FacilityId"
                            name="FacilityId"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>

                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">

                        {DispensingLanguage[lan][menukey]["Facility Name"]}
                      </InputLabel>
                      <Select
                        error={errorObject.FacilityId}
                        labelId="demo-simple-select-helper-label"
                        id="FacilityId"
                        name="FacilityId"
                        value={formData.FacilityId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        <MenuItem value="">
                          {DispensingLanguage[lan][menukey]["All Facility"]}
                        </MenuItem>

                        {FacilityList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.FacilityId}>
                        {errorObject.FacilityId}
                      </FormHelperText>
                    </FormControl> */}
                  </Grid>


                  <Grid item xs={4} sm={4} className="sw_active_patient">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={formData.id == 1 ? true : false}
                          checked={formData.IsActive}
                          onChange={(e) => handleCheck(e)}
                          name="IsActive"
                          value="no"
                        />
                      }
                      label="Active"
                    />
                  </Grid>

                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>



        <Grid container spacing={3}>
          {/* new row */}
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <Card className="sw_card">
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <AppBar position="static" color="default" className="mb-2">
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={DispensingLanguage[lan][menukey]["Departments"]}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["ZS"]
                            }
                            {...a11yProps(1)}
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Commune"]
                            }
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </Box>
                    </AppBar>


                    <TabPanel value={value} index={0}>

                      <div className="float-left sw_btn_control">
                        <Grid item xs={12} className="mt-4 text-center">
                          <div className="float-left sw_btn_control">
                            <Button
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => SelectDeselect("Province", "SelectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Select All"]}
                            </Button>
                            <Button
                              className="mr-2"
                              variant="contained"

                              onClick={() => SelectDeselect("Province", "DeselectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Deselect All"]}
                            </Button>
                          </div>
                        </Grid>
                      </div>

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={columns}
                              data={ProvinceData}
                              layout={"fitColumns"}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>



                    <TabPanel value={value} index={1}>

                      <div className="float-left sw_btn_control">
                        <Grid item xs={12} className="mt-4 text-center">
                          <div className="float-left sw_btn_control">
                            <Button
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => SelectDeselect("Zone", "SelectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Select All"]}
                            </Button>
                            <Button
                              className="mr-2"
                              variant="contained"

                              onClick={() => SelectDeselect("Zone", "DeselectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Deselect All"]}
                            </Button>
                          </div>
                        </Grid>
                      </div>

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              columns={columns1}
                              layout={"fitColumns"}
                              data={ZoneData}
                              options={{
                                groupBy: "RegionName",
                                columnCalcs: "both",
                              }}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>

                      <div className="float-left sw_btn_control">
                        <Grid item xs={12} className="mt-4 text-center">
                          <div className="float-left sw_btn_control">
                            <Button
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => SelectDeselect("Bcz", "SelectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Select All"]}
                            </Button>
                            <Button
                              className="mr-2"
                              variant="contained"

                              onClick={() => SelectDeselect("Bcz", "DeselectAll")}
                            >
                              {DispensingLanguage[lan][menukey]["Deselect All"]}
                            </Button>
                          </div>
                        </Grid>
                      </div>

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              columns={columns2}
                              layout={"fitColumns"}
                              data={BCZData}

                              options={{
                                groupBy: "ZoneName",
                                columnCalcs: "both",
                              }}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </TabPanel>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>





      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
