import React, { forwardRef, useRef,Component  } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, MenuItem, Card, CardContent,Checkbox, FormControl, InputLabel, Select} from "@material-ui/core";
import { Map, GoogleApiWrapper, Marker,InfoWindow  } from 'google-maps-react';
import * as Service from "../../../services/Service.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";



import { Button } from "reactstrap";

 
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";


const FacilityMap = (props) => {

  const mapStyles = {
    width: '92%',
    height: '60%',
    overflow:"hidden !important",
  };

  const { useState } = React;
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facilities-list";
  const classes = useStyles();

  const [MapLatitudeLongitude, setMapLatitudeLongitude] = useState([]);
  const [MapDataTable, setMapDataTable] = React.useState(true);

  /*
  if(MapDataTable){
    setMapDataTable(false);
    
    let ParamList={action:"getMapLatitudeLongitude"}
    Service.default
          .postApi("source/api/api_pages/facility_list.php",ParamList)
        .then((res) => {
          console.log('res: ', res);


          setMapLatitudeLongitude(res.datalist);
        })
        .catch((err) => {});
  }
  */

  

  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    markerParams:{}
  }); 

  const onMarkerClick = (props, marker, e) =>{
     
    console.log(props.params);
     setState({
      selectedPlace: props,
      markerParams: props.params,
      activeMarker: marker,
      showingInfoWindow: true
    })
  };

    const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        selectedPlace: {},
        showingInfoWindow: false,
        activeMarker: null,
        markerParams:{}
      });
    }
  };


  // Filter Start

  const [Regionlist, setRegionlist] = useState([]); //JSON.parse(localStorage.getItem("Regionlist"));

  let TempZoneList = useState([]); // JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);

  let TempCommuneList = useState([]); //JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);

  
  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));


  const [Ownertypelist, setOwnertypelist] = useState([]);
  const [Servicearealist, setServicearealist] = useState([]);

  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [currFLevelId, setcurrFLevelId] = useState(0);
  const [currFTypeId, setcurrFTypeId] = useState(0);
  const [currOwnerTypeId, setOwnerTypeId] = useState(0);
  const [currServiceAreaId, setServiceAreaId] = useState(0);

  const [Region, setRegion] = React.useState(true);
  const [Ownertype, setOwnertype] = React.useState(true);
  const [ServiceArea, setServiceArea] = React.useState(true);
  
  const [FacilityCount,setFacilityCount] = useState(0);

  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }
   

  
  const cZoneList = () => {  
  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
  


}


const cCommuneList = () => {  
  let CommuneParam = {action:"Communelist",RegionId:currRegionId,ZoneId:currZoneId,menukey:""}

  Service.default
      .postApi("source/combo_generic.php",CommuneParam)
      .then((res) => {
 
         setCommunelist(res.datalist);
         
      })
      .catch((err) => {});
// }


}
 


  if(Ownertype){
    setOwnertype(false);
    let ParamList={action:"Ownertypelist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setOwnertypelist(res.datalist);
        })
        .catch((err) => {});
  }

  if(ServiceArea){
    setServiceArea(false);
    let ParamList={action:"Servicearealist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setServicearealist(res.datalist);
        })
        .catch((err) => {});
  }


  let ParamList={
    menukey: menukey,
    action: "getMapLatitudeLongitude",
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    FLevelId:currFLevelId,
    FTypeId:currFTypeId,
    ServiceAreaId:currServiceAreaId,
    OwnerTypeId:currOwnerTypeId
   }


  const getFacilityData = () => { 
  
    setMapDataTable(false);
    Service.default
        .postApi("source/api/api_pages/facility_list.php",ParamList)
        .then((res) => {
         
          setMapLatitudeLongitude(res.datalist);
    
        })
        .catch((err) => {});
}



const handleRegionChange = (event) => { 
    setcurrRegionId(event.target.value);
 };

 const handleZoneChange = (event) => {

   setcurrCommuneId(0);
   let CommuneParam = {action:"gCommunelist",RegionId:currRegionId,ZoneId:event.target.value,menukey:""}
   cCommuneList(CommuneParam);
   setcurrZoneId(event.target.value);
   
 };

 const handleCommuneChange = (event) => {
   setcurrCommuneId(event.target.value);
 };
 

 const handleFlevelChange = (event) => {
   setcurrFLevelId(event.target.value);
 };

 const handleFTypeChange = (event) => {
   setcurrFTypeId(event.target.value);
   

 };


 const handleOwnerTypeChange = (event) => {
   setOwnerTypeId(event.target.value);
 
 };

 const handleServiceAreaChange = (event) => {
   
   setServiceAreaId(event.target.value);
   
 };

 React.useEffect(() => {
   setcurrZoneId(0); 
   cZoneList();
 }, [currRegionId]);
 React.useEffect(() => {
   setcurrCommuneId(0);
   cCommuneList();
 }, [currRegionId,currZoneId]);


 React.useEffect(() => {
   getFacilityData();
 }, [currServiceAreaId,currRegionId,currZoneId,currCommuneId,currFTypeId,currOwnerTypeId]);

  // End Filter


  return (
    <>
      <div className={classes.facilityPageTitle}>
      

        {/* start of filter */}
        {/* <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Facility List in Map"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/facilities-list");
                  }}
                >
                   {DispensingLanguage[lan][menukey]["Back to List"]} 
                 
                </Button>
               

               
              </div>
            </div>
          </Grid> */}

        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Facility List in Map"]}
              </div>
            </div>
          </Grid>
        </div>


        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              {/* <Grid item xs={2} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    //checked={checked} 
                    onChange={handleChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["bDispensing"]}
              />

              </Grid> */}

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Department Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RegionId"
                    name="RegionId"
                    value={currRegionId}
                    onChange={handleRegionChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                   {Regionlist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["ZS Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ZoneId"
                    name="ZoneId"
                    value={currZoneId}
                    onChange={handleZoneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All ZS"]}</MenuItem>

                   {Zonelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Commune Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="DistrictId"
                    name="DistrictId"
                    value={currCommuneId}
                    onChange={handleCommuneChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Commune"]}</MenuItem>

                   {Communelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={1} sm={1}>
              <div className="float-right sw_btn_control">

                dfsdfsf
                </div>
              </Grid> */}

              <Grid item xs={3} sm={3}>

              
                <div className="float-right sw_btn_control">

                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/facilities-list");
                  }}
                >
                   {DispensingLanguage[lan][menukey]["Back to List"]} 
                 
                </Button>


                 

               
                </div>
              </Grid>



              {/* <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Level"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FLevelId"
                    name="FLevelId"
                    value={currFLevelId}
                    onChange={handleFlevelChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Level"]}</MenuItem>

                   {FlevelList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid> */}


              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Facility Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="FTypeId"
                    name="FTypeId"
                    value={currFTypeId}
                    onChange={handleFTypeChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Facility Type"]}</MenuItem>

                   {FTypeList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Owner Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="OwnerTypeId"
                    name="OwnerTypeId"
                    value={currOwnerTypeId}
                    onChange={handleOwnerTypeChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Owner Type"]}</MenuItem>

                   {Ownertypelist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Service Area"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="ServiceAreaId"
                    name="ServiceAreaId"
                    value={currServiceAreaId}
                    onChange={handleServiceAreaChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Service Area"]}</MenuItem>

                   {Servicearealist.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={3} sm={3}>
                
                  
                {/* <div className="float-right">
                 <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Facility']+": "+FacilityCount}</h4>
                 </div> */}
                  
                
              </Grid>


              


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}



        {/* <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
            <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Facility List in Map"]}
              </div>
            
              <Grid item xs={12} sm={12}>
                <div className="float-right sw_btn_control">

                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/facilities-list/");
                  }}
                >
                 Back to List
                </Button>

                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Card> */}

        
        <Card className="sw_card Map_Style">
          <CardContent>
            
          <Grid container>
          <Grid item xs={12} sm={12}>
      <Map 
        google={props.google}
        zoom={7}
        style={mapStyles}
        initialCenter={
          {
            lat: 9.314623823,
            lng: 2.31184834
          }
        }

      >
      {
        MapLatitudeLongitude.map((item, index) => {
          return (
            <Marker 
            onClick={onMarkerClick} 
            params={item}
             position={{ lat: item.Latitude, lng: item.Longitude}}
             icon={{

              url: require("assets/img/marker_icon.png")  ,
            //  anchor: { x: 16, y: 16 },
            //  scaledSize: { height: 16, width: 16 },  
      
          }}
           /> 
          );
        })

      }  
      
      <InfoWindow
          marker={state.activeMarker}
          visible={state.showingInfoWindow}
          onClose={onClose}
        >
          <div>

          <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Name"]}
                                  </TableCell>
                                  <TableCell >
                                  {state.markerParams.FacilityName}
                                  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                    align="left"
                                  >
                                   {DispensingLanguage[lan][menukey]["Commune Name"]}
                                  </TableCell>
                                  
                                  <TableCell > {state.markerParams.DistrictName}  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                    align="left"
                                  >
                                   {DispensingLanguage[lan][menukey]["ZS Name"]}
                                  </TableCell>
                                  <TableCell >  {state.markerParams.ZoneName} </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Department Name"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.RegionName} </TableCell>
                                   
                                </TableRow>

                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Type"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FTypeName} </TableCell>
                                   
                                </TableRow>

                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Address"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FacilityAddress} </TableCell>
                                   
                                </TableRow>


                                <TableRow>
                                <TableCell
                                    style={{
                                      "font-weight": "bold",
                                      "border-right": "1px solid antiquewhite",
                                    }}
                                  >
                                  {DispensingLanguage[lan][menukey]["Facility Phone"] }
                                  </TableCell>
                                  <TableCell > {state.markerParams.FacilityPhone} </TableCell>
                                   
                                </TableRow>

                              </TableBody>
                             
                            </Table>
                          </TableContainer>

           
          </div>
        </InfoWindow>
      

      </Map>
      </Grid>   
         </Grid>       
              
          </CardContent>
        </Card>
       
            
          

        
      </div>
    </>
  );
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };

  // ...
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCD7OEdGUC1V__0-mBJIoYifI5UtEILYbg'
})(FacilityMap);
//export default FacilityMap;

const useStyles = makeStyles({
  
  facilityPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  }
});
