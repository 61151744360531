import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  Typography,
  TextField, 
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel, 
  MenuItem, 
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import * as api from '../../../actions/api';
import {
  DeleteOutline,
  Edit,
  ViewList,
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';



import "../common.css";
import swal from "sweetalert";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar"; 
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import DispenseLotModal from "../../../services/DispenseLotModal";
import * as ScanBarcode from '../../../services/ScanBarcode';
import { cellFocusEditor,cellFocusEditorSerial, dateEditor } from "../../../services/Common";
import OrderModal from './OrderModal';

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = '';


let isDirty=false; 
let masterDirty=false;
let deletedDatas=[];
let transactionItemID =0;
const DispenseItems = (props) => {

  //get ePharmaciyLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "dispense";
const FacilityId=localStorage.getItem('FacilityId');
const FacilityName = localStorage.getItem("FacilityName");

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef =null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);
 
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);


  const [SoureOpen, setSoureOpen] = useState(false); 

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  

  const getFacilityListNotEqual99ANDSupplier =JSON.parse(localStorage.getItem("getFacilityListNotEqual99ANDSupplier"));

  const UsersListByFacility =JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);
  // Store
  const StoreId = useState(3);//localStorage.getItem("StoreId");
  const [currStoreId, setcurrStoreId] = useState(StoreId);
  const StoreList = JSON.parse(localStorage.getItem("getStore"));
  //const [currItemGroupId, setCurrItemGroupId] = useState('0');
  //const GroupList = JSON.parse(localStorage.getItem("GroupList"));


  const Product_Group = JSON.parse(localStorage.getItem("GroupList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GroupList = [All_Product].concat(Product_Group);
  const [currItemGroupId, setCurrItemGroupId] = useState(0);

  /* =====Start of Print Excel Many Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_dispense_invoice_entry_list_print.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=3"
     + "&TimeStamp=" + Date.now()
  );
};

const ExcelMany = () => {
  
  let finalUrl = EXCEL_EXPORT_URL + "report/esigl_dispense_invoice_entry_list_excel.php";

  window.open(
    finalUrl +"?menukey=" +menukey
     + "&lan=" + localStorage.getItem("LangCode")
     +"&TransactionId=" + props.formData.TransactionId
     + "&FacilityId=" + FacilityId
     + "&FacilityName=" + FacilityName
     + "&StoreId=3"
     + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */
  
  
  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
         break; 

     

      default:
        break;
    }
  }
  
  const addProduct = (NonOrderProductData) => {  
 
    let temNonordata=NonOrderProductData;
    let rows = [];
 
    let autoId=0;
    temNonordata.forEach((row,i) => {
      autoId++
      let newRow={} ;
      newRow.ItemNo =row.ItemNo;
      
      newRow.ItemCode =row.ItemCode;
      newRow.ItemName =row.ItemName;
      newRow.UnitPrice =row.UnitPrice;

      
      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;

      // Brand Info
      newRow.BrandNo =row.BrandNo;
      newRow.BrandCode = row.BrandCode;
      newRow.CommercialName = row.CommercialName;
      newRow.EAGTIN =row.EAGTIN;
      newRow.PKGTIN =row.PKGTIN;
      newRow.CAGTIN =row.CAGTIN;
      newRow.PLGTIN =row.PLGTIN;
      newRow.SSCCCarton =row.SSCCCarton;
      newRow.SSCCPallet =row.SSCCPallet;

      manyTableData.forEach((rowssss,ij) => {
       
        if(rowssss.ItemNo==row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;

        } 
      });

     
     
      
      newRow.Quantity =0;
       
      newRow.SKU=autoId;
      newRow.id=autoId;  
      newRow.LineTotal=0; 
      rows.push(newRow);
    
  });
  

   // let fDtat={...props.formData,'ManyJsonSave':rows,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan,UserName:UserName};  
   // mutateUpdate(fDtat);
   //setUpdatedDetailsGridDataHandler(rows,deletedDatas); 
   setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":rows});
    handleClose('Products');
  };


  const cellFocusEditorSerial2 = function(cell, onRendered, success, cancel, editorParams){

    const currow = cell.getRow();
    const rowdata = currow.getData();
    const bSerialSupported = rowdata.bSerialSupported;
    console.log("bSerialSupported",bSerialSupported);
    if((bSerialSupported==1)||(bSerialSupported==2)){
      return cell.getValue();
    }
    
    //create and style input
    let input = document.createElement("input");
  
    input.setAttribute("type", "text");
  
    input.style.padding = "4px";
    input.style.width = "100%";
    input.style.boxSizing = "border-box";
  
    input.value = cell.getValue()==''?null:cell.getValue();
  
    // if (cell.getValue() != undefined)
    // input.value = null;
    // else
    // input.value = cell.getValue();
  
  
    onRendered(function () {
        input.focus();
        input.select();
        input.style.height = "100%";
    });
  
    function successFunc(){
      success(input.value);
    }
  
    function keyHandlerFunc(e){
      if (e.keyCode == 27)
        cancel(input.value);
      //console.log(e);
    }
  
    //submit new value on blur or change
    input.addEventListener("change", successFunc);
    input.addEventListener("blur", successFunc);
    input.addEventListener("keyup", keyHandlerFunc);
  
    return input;
  };


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false }, 
    { title: "id", field: "id", visible: false }, 
    { title: "bSerialSupported", field: "bSerialSupported", visible: false }, 
    {
      title: "GroupName",
      field: "GroupName",
      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  width: 60, formatter: "rownum", hozAlign: "center", headerHozAlign:"center", headerSort:false  },

    { 
      title: DispensingLanguage[lan][menukey]['Actions'], 
      field: 'custom', 
      headerSort:false, 
      headerHozAlign:"center", 
      hozAlign: 'center',
      width: 65,
     formatter: reactFormatter(<ActionButton 
     /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */ />) } ,

     {
      title: "dirty",
      field: "dirty",
      visible: false,
    },

    { title: DispensingLanguage[lan][menukey]['Product_Code'], field: "ItemCode", width: 130 ,headerFilter:true,},

  
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName",headerFilter:true,width: 240},
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 190 ,headerFilter:true},
    { title: DispensingLanguage[lan][menukey]['Brand'], field: 'CommercialName',width:180,headerFilter:true,},

    { title:DispensingLanguage[lan][menukey]['Current_stock_quantity'],
      field: "CurrentStockQty",
      hozAlign: "right",
      headerHozAlign:"right",
      width: 100
      },
   
    { title: DispensingLanguage[lan][menukey]['Quantity_dispensed'],
      field: "Quantity", 
      width: 100, 
      hozAlign: "right", 
      headerHozAlign:"right",
     // validator:["integer", "min:0"],
      validator: ["integer", "min:0", function (cell, value, parameters) {
 
        if (value == "") return true;
        const balance = cell.getRow().getCell('CurrentStockQty').getValue();
        const validstatus = parseInt(value) <= parseInt(balance);  
        return validstatus; 
      }],

      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditorSerial2: '',      
      cellEdited: function (cell) {
        
        const currow = cell.getRow();
        currow.getCell("dirty").setValue(1); 

        const curcellval = cell.getValue();
        
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);

        isDirty=true;
        transactionItemID=rowdata.id;
        onSetDisable(); 

        //cellBlur(cell);
      } 
    },//
    { title: DispensingLanguage[lan][menukey]['Serial No'], 
    field: "SerialNo",
    width: 130,
    headerFilter: true,
    },
    { 
      
    title: DispensingLanguage[lan][menukey]['Lot No'], 
    field: "BatchNo",
    width: 120,
    headerFilter:true,
    },
    { 
    title: DispensingLanguage[lan][menukey]['Expiry_Date'], 
    field: "ExpiryDate" ,width:100,
    },

    { 
      title: DispensingLanguage[lan][menukey]['EAGTIN'], 
      field: "EAGTIN", 
      width: 125,
      headerFilter:true,    
    },

   { 
    title: DispensingLanguage[lan][menukey]['PKGTIN'], 
    field: "PKGTIN", 
    width: 115,
    headerFilter:true,   
   },

  { 
    title: DispensingLanguage[lan][menukey]['CAGTIN'], 
    field: "CAGTIN", 
    width: 115,
    headerFilter:true,      
  },

  { 
    title: DispensingLanguage[lan][menukey]['PLGTIN'], 
    field: "PLGTIN", 
    width: 115,
    headerFilter:true,      
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCCarton'], field: "SSCCCarton", width: 125, headerFilter:true,  
  },


  { 
    title: DispensingLanguage[lan][menukey]['SSCCPallet'], field: "SSCCPallet", width: 125,headerFilter:true,   
  },

    
    { title: DispensingLanguage[lan][menukey]['Unit_Price'], 
    field: "UnitPrice" , 
    width: 100, 
    hozAlign: "right", 
    headerHozAlign:"right", 
    },
    { title:  DispensingLanguage[lan][menukey]['Line_Total'], 
    field: "LineTotal" , 
    width: 120,
    formatter: "money", 
    hozAlign: "right", 
    headerHozAlign:"right",
    bottomCalc: "sum",
    bottomCalcFormatter: "money"
       
    },
    
  ];


  const onSetDisable = () => { 
    
    if(isDirty==true){ 
       
      document.getElementById("FID_scanButton").classList.add("swDisabled");
      document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("FID_scan_gtin_or_brandcode").classList.add("swDisabled"); 
      document.getElementById("FID_ItemGroupId").classList.add("swDisabled");
      document.getElementById("FID_pick_items_btn").classList.add("swDisabled");
      
      
      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      let fDtat={...props.formData,'ManyJsonSave':dataArray,'deletedDataSet':deletedDatas,menukey: menukey,lan: lan,UserName:UserName};  
      mutateCellUpdate(fDtat);

      
      
    }else{

     //document.getElementById("FID_AStoreId").classList.remove("swDisabled");
     document.getElementById("FID_scanButton").classList.remove("swDisabled");
     document.getElementById("is_dirty_message").classList.add("dnone");
     document.getElementById("FID_scan_gtin_or_brandcode").classList.remove("swDisabled");
     document.getElementById("FID_ItemGroupId").classList.remove("swDisabled");
     document.getElementById("FID_pick_items_btn").classList.remove("swDisabled");
     
     
    }
    
 }


 const onMasterSetDirty = () => { 
    
  if(masterDirty==true){ 

    document.getElementById("master_dirty_message").classList.remove("dnone");

      
  }else{ 

    document.getElementById("master_dirty_message").classList.add("dnone");
   
  }
  
}
   
const mutateCellUpdate=(fDtat) => { 
  
const auth_token = sessionStorage.getItem("token")
? sessionStorage.getItem("token")
: null; 
  const apiUrl = process.env.REACT_APP_API_URL + "source/api/receiveUpdate";
  let data=fDtat; 
    fetch(apiUrl, {
      method: "PUT", 
      headers: { 
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth_token}`,
        "Consumer": process.env.REACT_APP_CONSUMER,
        "Secret": process.env.REACT_APP_SECRET,
      
      },
      body: JSON.stringify(data),
    })
    .then(function (res) {
      console.log(res);
     
      return res.json();
    })
    .then(function (data) {

      console.log(data);
      isDirty=false;
      onSetDisable();  
    })
    .catch(error => {

      props.openNoticeModal({
        isOpen: true,
        msg: 'There was an error!',
        msgtype: 0,
      });
     // console.log(error);
      console.error('There was an error!', error);
     });


 }



 /*
 const { isLoading, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
  onSuccess: (data) => {
    if (data.status == 200) {
      props.refetch();
      isDirty=false;
      onSetDisable(); 
      
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

     


    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  }
})

*/
 
  const onPostBtnClick = () => { 
    //setbStockUpdate(true); 
    //props.handlePost(ReceiveDetailsRef.table.getData(),deletedDatas);

      setbStockUpdate(false);
      onSetDisable();
      let dataArray=[];
      let gridData=ReceiveDetailsRef.table.getData();
      gridData.forEach((row,i) => { 
        if(row.id==transactionItemID) {
          dataArray.push(row);
          transactionItemID=0;
        }
      });
      handlePost(dataArray,deletedDatas);
    

  }

  const handlePost = async (rows,deletedsetdata) => {  

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    //if (validateForm(formData,1)) {
       
      //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
      //mutateUpdate(fDtat);

      // Start Post Alert

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
          
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
 
          let FinalData={...validForm,'ManyJsonSave':rows,"bStockUpdated":1,'deletedDataSet':deletedsetdata,menukey: menukey,lan: lan, "UserName":UserName}; 
          mutateUpdate(FinalData); 
          setManyTableData(rows);
 
        }else{
          //props.setbStockUpdate(0);
          props.stockPosted(0);
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
    }
      // End Post Alert

      
   // }
     
     
  };

  const onSaveUpdateBtnClick = () => { 
   
    //props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData()); 

    let dataArray=[];
    let gridData=ReceiveDetailsRef.table.getData();
    gridData.forEach((row,i) => { 
      if(row.id==transactionItemID) {
        dataArray.push(row);
        transactionItemID=0;
      }
    })
    
    //setUpdatedDetailsGridDataHandler(dataArray,deletedDatas);
    setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":dataArray});
    
  }



  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows.data,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutateSave(validForm);
        }else if(rows.datatype == "ScanData"){
        
          mutateUpdateForScan(validForm); 
        }        
        else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  


  // const setUpdatedDetailsGridDataHandler = (rows) => {
  //   let validForm = props.checkValidateForm(rows,'edit');
  //   // console.log('isval: ', validForm);
  //     if (validForm) {

  //       // console.log('props.addProductForm: ', props.addProductForm);
  //       if(props.addProductForm){
  //         //when save
  //         mutateSave(validForm);
  //       }else{
  //         //when update
  //         mutateUpdate(validForm); 
  //       }
        

        
  //     }
  // };
  

  const { isLoading: isLoadingSave, mutate:mutateSave } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      masterDirty=false;
      onMasterSetDirty(); 

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  



  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
        
        masterDirty=false;
        onMasterSetDirty(); 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        props.successSave(data.bStockUpdated);
        if(data.bStockUpdated==1){
         mutatePost(props.formData.TransactionId);
        }
        else{
          props.successSave(data.bStockUpdated); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });
  

  const {mutate:mutateUpdateForScan } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        masterDirty=false;
        onMasterSetDirty(); 
        doActionScanBarcodeCopy();
          props.successSave(data.bStockUpdated); //refetch();  
         
  
      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });


  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postDispenseStock, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        //props.setbStockUpdate(1);
        //setDeletedDataSet([]);

        if(data.data.success==0){ 
          
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          props.stockPosted(1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        props.refetch();
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })








  const onUpdateBtnClick = () => {   
    isDirty=true;

    onSetDisable(); 
     
    
  }
 


  const mutatedeleteTransactionItems=(pdata) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/deleteTransactionItems";
      //console.log("ddddd",currTransactionId);
      //let data=fDtat;
      //let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:CurFacility,TransactionId:CurTransactionId};
      let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:props.formData.FacilityId,TransactionId:props.formData.TransactionId};
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "POST", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
          
           
        })
        .catch(error => {

          props.refetch();
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     } 
  const mutatedeleteTransactionItems1111111=(pdata) => { 
  
    const auth_token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null; 
      const apiUrl = process.env.REACT_APP_API_URL + "source/api/dispenseDeleteTransactionItems";
      //console.log("ddddd",currTransactionId);
      //let data=fDtat;
      //let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:CurFacility,TransactionId:CurTransactionId};
      let fDtat={...pdata,menukey: menukey,lan: lan, UserName:UserName, FacilityId:props.formData.FacilityId,TransactionId:props.formData.TransactionId};
      let data=fDtat;
      
        fetch(apiUrl, {
          method: "POST", 
          headers: {
              
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          
          },
          body: JSON.stringify(data),
        })
        .then(function (res) {
          console.log(res);
         
          return res.json();
        })
        .then(function (data) {
          
           
        })
        .catch(error => {

          props.refetch();
    
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey]['There was an error!- Please check your internet connection.'],
            msgtype: 0,
          });
        
         });
    
    
     } 


  function ActionButton (props) { 

    const rowData = props.cell._cell.row.data;  

    const cellValue = props.cell._cell.value || 'Edit | Show';
    if(bStockUpdate == 0)
    return (
      <>

        {/* <DeleteOutline
          onClick={() => {
            props.cell.getRow().delete();
            if((rowData.TransactionItemId!=null)||(rowData.TransactionItemId!=-1)){
              let newDeletedDataSet=[rowData.TransactionItemId]; 
              deletedDatas=newDeletedDataSet.concat(deletedDatas); 
              isDirty=true;
              onSetDisable();
            }

          }}
        /> */}

          <DeleteOutline
            onClick={() => {
              props.cell.getRow().delete();
              mutatedeleteTransactionItems(rowData);
            }}
          />


      </>
    );
    else  return (
      <>
         
      </>
    );
  }

  
  
  useEffect(() => {
    if (ReceiveDetailsRef == null) return; 
    if(props.bFirst){
      setManyTableData(props.ManyTableData); 
    }
    deletedDatas=[];
    setbStockUpdate(props.bStockUpdate);
}, [props.ManyTableData,props.bStockUpdate]); //


// useEffect(() => {

//   isDirty=false;//props.dirty;
//   onSetDisable();
//   deletedDatas=[];

// }, [props.dirty]);


useEffect(() => {
  if (ReceiveDetailsRef.table != null && timeStamp != '') {
 

    ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
    ReceiveDetailsRef.table.selectRow(timeStamp);


  }
}, [newBatchRowAdded])

const handleReset = () => {
  setManyTableData([]);  
  props.handleReset();
};


// const handleChangeGrp = (e) => {
//   props.filterHandleChange(e.target.value);
//   setCurrItemGroupId(e.target.value);
// };


const handleProductGroupChange = (event, newValue) => {
  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currItemGroupId) {
    if(props.addProductForm==false){
    props.filterHandleChange(rowId);
    }
    setCurrItemGroupId(rowId); 
  }
};

const scan_gtin_or_brandcode = useRef(null);
const handleRefetchCall = () => {
  props.refetch();
  scan_gtin_or_brandcode.current.value = "";
};
 


const doActionScanBarcode = () => { 
 // if(scan_gtin_or_brandcode.current.value!='')
  setUpdatedDetailsGridDataHandler({"datatype":"ScanData","data":[],'deleteddata':[]});
    
 };
 
 const doActionScanBarcodeCopy=()=>{
  // let SupplierString = props.formData.ReceiveFrom;
  // const SupplierArr = SupplierString.split("_");
	// const SupplierId = SupplierArr[1];

  const ScanBarcodeParams = {
    TransactionTypeId: 4,
    StoreId: props.formData.StoreId,
    TransactionId: props.formData.TransactionId,
    FacilityId: props.formData.FacilityId,
    //FacilityLevelId: 1,
    ReceiveFromId: 0,
    AdjTypeId: 0,
    lan: lan,
    menukey: menukey,
    handleRefetchCall: handleRefetchCall,
    UserId:UserId,
    UserName:UserName,
    UserRole:UserRole
  }

  ScanBarcode.ScanBarcodeAction(ScanBarcodeParams, {...props});
 }





const onClickScanBarcode = () => {  
  doActionScanBarcode();
};

const handleKeyPress = (event) => { 
  if(event.key === 'Enter'){    
    doActionScanBarcode();
  }
}


let altValue = "";
const handleKeyDown = (e) => { 
  if (e.altKey) {    
    if (e.which !== 18) {
      altValue += e.which;
    }
    if (altValue === "969698105") {      
      //this.value += String.fromCharCode(29);
      document.getElementById("scan_gtin_or_brandcode").value += String.fromCharCode(29);
    }
  }
};

const handleKeyUp = (e) => {
  if (e.altKey === false) {
    altValue = "";
  }
};


const backToList = () => {

  /*
  console.log("Back to listttt",props.addProductForm);
  if(props.addProductForm==true){
    
  }
  */
  

  if(masterDirty==true){

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willAction) => {
      if (willAction) { 
        masterDirty=false;
        props.history.push("/dispense");

      } 
    });

  }else props.history.push("/dispense")
  
};


const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     masterDirty=true;
     onMasterSetDirty(); 
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

};


const [PreparedBy, setPreparedBy] = useState({ id: '', name: "" });
const [ApprovedBy, setApprovedBy] = useState({ id: '', name: "" });

const changeVal = (event,val,cases) => {
   //console.log("On change ev",val.id);
  switch(cases){
    
    case 'PreparedBy': 
      setPreparedBy(val);
      props.formData.PreparedBy= val != null?val.id:"";
      break;

    case 'ApprovedBy': 
      setApprovedBy(val);
      props.formData.ApprovedBy= val != null?val.id:"";
      break;  
   
  break;
  
  }
 // if(val != null){    
    props.handleChange(event);
 // }
  
  masterDirty=true;
  onMasterSetDirty();
  
};

useEffect(() => { 
  setPreparedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByFacilityList => UsersListByFacilityList.id == props.formData.PreparedBy)]); 
  setApprovedBy(UsersListByFacility[UsersListByFacility.findIndex(UsersListByApproveList => UsersListByApproveList.id == props.formData.ApprovedBy)]);    
}, [props.formData.PreparedBy, props.formData.ApprovedBy]);
 


const handleSerialChange = (event) => {
  props.serialHandleChange(event.target.checked);
};


  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
      <Grid item xs={12} sm={12}>

<div className="sw_page_heading">
    
    
    <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['DISPENSE INVOICE - CREATE/EDIT']+' ('+FacilityName+')'}</div>
    
    <div className="float-right sw_btn_control">

    {
    props.addProductForm ?
      <Grid item xs={12} className="mt-4 text-center">
        
        <div className="mr-4 float-left">
          <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
        </div>

       <div className="float-right sw_btn_control">

       <Button
          className="mr-2"
          variant="contained"
          type="reset"
          
          //onClick={() => props.history.push("/dispense")}
          onClick={() => backToList()}
        >
          {DispensingLanguage[lan][menukey]['Return_to_List']}
        </Button>


        {/* <Button
          className="mr-2"
          variant="contained"
          type="reset"
          onClick={() => handleReset()}
        >
          {DispensingLanguage[lan][menukey]['Reset']}
        </Button> */}


        <Button
          disabled={isLoadingSave}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
        >
          {DispensingLanguage[lan][menukey]['Submit']}
        </Button>
        
       </div>
      </Grid>
      :
      <Grid item xs={12} className="mt-2 text-center">

        <div className="mr-4 float-left">
          <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
        </div>
        
        <Button
          className="mr-2"
          variant="contained"
          type="reset"
          //onClick={() => props.history.push("/dispense")}
          onClick={() => backToList()}
        >
         {DispensingLanguage[lan][menukey]['Return_to_List']}
        </Button>

        <Button
          disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onSaveUpdateBtnClick}
           
        >
         {DispensingLanguage[lan][menukey]['update']}
        </Button>
        
        <Button 
          disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onPostBtnClick}
        >
          {DispensingLanguage[lan][menukey]['POST']}
        </Button>
        

        <Button
        variant="contained"
        color="primary"
        className="mr-2 sw_icons"
        onClick={() => PrintMany()}
        >
        <i class="fas fa-print"></i>
        </Button>

        <Button
        variant="contained"
        color="primary"
        className="mr-2 sw_icons"
        onClick={() => ExcelMany()}
        >
        <i className="far fa-file-excel"></i>
        </Button>

      </Grid>
  }

    </div>
 </div>   
  
  
</Grid>


</div>

      
      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}
 

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                  <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Store"]} *
                    </InputLabel>
                    <Select
                      error={props.errorObject.StoreId}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="StoreId"
                      name="StoreId"               
                      value={ props.formData.StoreId}
                    // value={all}
                      onChange={(e) =>formOnchange(e,'handleChange','StoreId')}
                      
                      fullWidth
                      disabled={true}
                    >
                      
                      {StoreList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>

                    <FormHelperText error={props.errorObject.StoreId}>
                      {props.errorObject.StoreId}
                    </FormHelperText>

                  </FormControl>
                </Grid>


                <Grid item xs={3} sm={3}>
                      <TextField
                         disabled={true/*bStockUpdate*/}
                         error={props.errorObject.TransactionNo}
                         helperText={props.errorObject.TransactionNo}
                         required
                        id="TransactionNo"
                        name="TransactionNo"
                        label={DispensingLanguage[lan][menukey]['Dispense_Invoice']} 
                        // value={formData.FirstName || "Mahmudul"}
                        value={props.formData.TransactionNo||props.generatedInvoiceNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => formOnchange(e,'handleChange','TransactionNo') }
                      />
                    </Grid>

                    
                    {/* <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                        
                          {DispensingLanguage[lan][menukey]["Prepared By"]} *
                        </InputLabel>
                        <Select
                          error={props.errorObject.PreparedBy}
                         
                          required
                          labelId="demo-simple-select-helper-label"
                          id="PreparedBy"
                          name="PreparedBy"
                          value={props.formData.PreparedBy}
                          fullWidth
                          onChange={(e) =>  formOnchange(e,'handleChange','PreparedBy') 
                          }
                          disabled={props.bStockUpdate}
                        >
                        {UsersListByFacility.map((item, index) => { 
                          
                          
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={props.errorObject.PreparedBy}>
                          {props.errorObject.PreparedBy}
                        </FormHelperText>
                      </FormControl>
                    </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="PreparedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'PreparedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={PreparedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.PreparedBy}
                        label={DispensingLanguage[lan][menukey]["Prepared By"] }
                        variant="standard"
                        error={props.errorObject.PreparedBy}
                        helperText={props.errorObject.PreparedBy}
                        required
                        
                        name="PreparedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


                    <Grid item xs={3} sm={3}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disabled={props.bStockUpdate}
                            required
                            error={props.errorObject.SupplierInvDate}
                            helperText={props.errorObject.SupplierInvDate}
                            className={classes.fullWidth}
                           // disableToolbar
                            variant="inline"                            
                            id="SupplierInvDate"
                            label={DispensingLanguage[lan][menukey]['Prepared Date']} 
                            autoOk={true}
                            name="SupplierInvDate"
                            fullWidth
                            showTodayButton={true}
                            value={props.formData.SupplierInvDate||props.selectedDate}
                            format="dd/MM/yyyy"
                            onChange={(e) =>  formOnchange(e,'handleRDateChange','SupplierInvDate')  }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </MuiPickersUtilsProvider>                     
                    </Grid>
                   
                   
                   
                        {/* <Grid item xs={3} sm={3}>
                            <FormControl className={classes.fullWidth}>
                              <InputLabel id="demo-simple-select-helper-label">
                              { DispensingLanguage[lan][menukey]['Approved By'] } *  
                              </InputLabel>
                              <Select
                                error={props.errorObject.ApprovedBy}
                                // helperText={props.errorObject.ApprovedBy}
                                required
                                labelId="demo-simple-select-helper-label"
                                id="ApprovedBy"
                                name="ApprovedBy"
                                value={props.formData.ApprovedBy}
                                fullWidth
                                onChange={(e) =>  formOnchange(e,'handleChange','ApprovedBy')}
                                disabled={props.bStockUpdate}
                                //disabled={bCompleted===1}
                              >
                              {
                              UsersListByFacility.map(
                              (item, index) => {
                                return (
                                  <MenuItem value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              }
                              )}
                            </Select>

                            <FormHelperText error={props.errorObject.ApprovedBy}>
                              {props.errorObject.ApprovedBy}
                            </FormHelperText>
                            </FormControl>
                        </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      disabled={props.bStockUpdate}                
                      autoHighlight
                      disableClearable
                      id="ApprovedBy"
                      options={UsersListByFacility}  
                      onChange={(event, value) => changeVal(event,value,'ApprovedBy')} 
                      getOptionLabel={(option) => option.name}
                      value={ApprovedBy}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.ApprovedBy}
                        label={DispensingLanguage[lan][menukey]["Approved By"] }
                        variant="standard"
                        error={props.errorObject.ApprovedBy}
                        helperText={props.errorObject.ApprovedBy}
                        required
                        
                        name="ApprovedBy" 
                        fullWidth
                      />
                      )}
                    />
                    </FormControl>
                  </Grid>


                        <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                       // disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label=  {DispensingLanguage[lan][menukey]['Approved_Date']} 
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                       // showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>  
                          formOnchange(e,'handleRDateChange','TransactionDate')                          
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
              </Grid>


                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="Remarks"
                        name="Remarks"
                        label= {DispensingLanguage[lan][menukey]['Remark_s']} 
                        value={props.formData.Remarks}
                        fullWidth
                        multiline
                        autoComplete="family-name"
                        onChange={(e) => formOnchange(e,'handleChange','Remarks')}
                      />
                    </Grid>
                   



                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      <TextField 
                        id="TransactionId"
                        name="TransactionId"
                        label="TransactionId"  
                        value={props.formData.TransactionId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />


                    </Grid>
            
                  </Grid>
                </Grid>

                
                <Grid container spacing={3}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          <Grid container className="mb-2">
                <Grid container spacing={1}>
                      
                      
                      {/* <Grid item xs={2} sm={2}>
                        <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}
                          </InputLabel>
                          <Select
                              error={props.errorObject.ItemGroupId}
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={currItemGroupId}
                              fullWidth
                              onChange={(e) =>handleChangeGrp(e)}
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              GroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={props.errorObject.ItemGroupId}>
                            {props.errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>

                      </Grid> */}

                          <Grid item xs={2} sm={2}>
                            <FormControl id="FID_ItemGroupId" className={classes.fullWidth}>
                                <Autocomplete
                                    autoHighlight
                                    className="sw_chosen_filter"
                                    id="ItemGroupId"
                                    disabled={props.addProductForm==true?true:false}
                                    disableClearable
                                    options={GroupList}               
                                    onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={GroupList[GroupList.findIndex(AGroupList => AGroupList.id == currItemGroupId)]}
                                    renderOption={(option) => (
                                      <Typography className="sw_fontSize">{option.name}</Typography>
                                    )}
                                    
                                    renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={DispensingLanguage[lan][menukey]["Product Group"]}
                                      variant="standard"
                                      name="ItemGroupId"
                                    />
                                    )}
                                  />                   
                                </FormControl>
                          </Grid>



                      <Grid item xs={4} sm={4} id="FID_scan_gtin_or_brandcode">
                              <FormControl className={classes.fullWidth}>
                                <TextField
                                  disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}
                                  
                                  id="scan_gtin_or_brandcode"
                                  name="scan_gtin_or_brandcode"
                                  label={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} 
                                   
                                  autoComplete="family-name"
                                  ref={scan_gtin_or_brandcode}
                                  onKeyPress={handleKeyPress} 
                                  onKeyDown={handleKeyDown} 
                                  onKeyUp={handleKeyUp}
                                  
                                  
                                />
                              </FormControl>
                                      
                            </Grid> 

                            <Grid item xs={1} sm={1} id="FID_scanButton">
                                <Button

                                disabled={(props.addProductForm==true?true: props.bStockUpdate)|| isLoadingUpdate || isLoadingPost}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()} 
                                >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                                </Button> 
                            </Grid>
                    




                    <div className="search_control_group dnone" id="scan_sscc_hide_show">
                      <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                      <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                    </div> 


                      {/* <Grid item xs={5} sm={5}>

                        <div className="search_card">
                          <form>  
                            <div className="search_control_group dnone" id="scan_sscc_hide_show">
                              <label className="search_control_label" for="scan_sscc">{DispensingLanguage[lan][menukey]["SCAN SSCC"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_sscc" id="scan_sscc" placeholder={DispensingLanguage[lan][menukey]["SCAN SSCC"]} className="search_form_control" />
                            </div>
                            <div className="search_control_group" id="FID_scan_gtin_or_brandcode">
                              <label className="search_control_label" for="scan_gtin_or_brandcode">{DispensingLanguage[lan][menukey]["GS1 Bar Code"]}</label>
                              <input disabled={props.addProductForm==true?true: props.bStockUpdate} type="text" name="scan_gtin_or_brandcode" id="scan_gtin_or_brandcode" placeholder={DispensingLanguage[lan][menukey]["GS1 Bar Code"]} className="search_form_control" ref={scan_gtin_or_brandcode}  onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}/>
                            </div>
                            <div className="search_control_group" id="FID_scanButton">
                              <Button
                                disabled={props.addProductForm==true?true: props.bStockUpdate}
                                id="scanButton"
                                variant="contained"
                                color="primary"
                                onClick={() => onClickScanBarcode()}
                              >
                                {DispensingLanguage[lan][menukey]["Scan"]}
                              </Button>
                            </div>
                          </form>
                        </div>

                      </Grid> */}

                          {/* <Grid item xs={2} sm={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="bSerialFilter" 
                                    id="bSerialFilter"                                 
                                    onChange={handleSerialChange}
                                  />
                                }
                                label={DispensingLanguage[lan][menukey]["Serial Details"]}
                              />
                            </Grid> */}


                      <Grid item xs={3} sm={3}>
                        <div className="float-left"> 
                        <span id="is_dirty_message" className={'redtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                        </div>
                      </Grid>


                            <Grid item xs={2} sm={2}>
                              <div className="float-right sw_btn_control" id="FID_pick_items_btn">
                                {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                                <Button
                                  disabled={(props.addProductForm==true?true: props.bStockUpdate) || isLoadingUpdate || isLoadingPost} 
                                  //disabled={props.bStockUpdate}
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("ProductsOpen")}
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i><span>&nbsp;</span>
                                 {DispensingLanguage[lan][menukey]['Add_Items']} 
                                </Button> 



                              </div>

                             
                              <DispenseLotModal handleClose={handleClose} open={open} addProduct={addProduct} {...props} />

                            
                             
                            </Grid>
                          </Grid>


                          </Grid> 
                           <ReactTabulator
                                 
                                  ref={(r) => (ReceiveDetailsRef = r)}
                                  columns={ReceiveDetailsColumns}
                                  data={manyTableData}
                                  height="350px"
                                  options={{
                                    groupBy: "GroupName",
                                    columnCalcs: "both",
                                  }}
                                  layout={"fitColumns"}
                                />
                         
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* new row */} 
                  </Grid>
              </Grid>

 

            {/* </CardContent>
          </Card> */}
        </Grid>


       {/* Action buttons */}
         
      </Grid>
    </div>
  </div>
  );
};

export default DispenseItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  redtextcolor: {
    color: "red", 
    fontWeight:600,
    textAlign: "center",
    lineHeight: "0px",
    paddingTop: "15px",
  }
});
