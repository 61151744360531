import React, { useState } from "react";
import { useParams } from "react-router-dom";

import CertificateFormData from "./CertificateFormData.js";

const CertificateEdit = (props) => {

  const { id } = useParams();

  return (
    <>
      <CertificateFormData
        BrandNo={ id }
        {...props}
      />
    </>
  );
};

export default CertificateEdit;
