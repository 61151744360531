import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";

import ProductListBrandFormData from "./ProductListBrandFormData.js";
import * as Service from "../../../services/Service.js";

const ProductListBrandEdit = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const menukey = "brands-list";

  const [formData, setFormData] = useState({
    ItemNo: "",
    ItemGroupId:"",
    ProductSubGroupId:"",
    SIGIPCode:"",
    ProductStatusId:"",
    BrandCode:"",
    CommercialId:"",
    StrengthId:"",
    PacksizeId:"",
    DosageFormId:"",
    DciId:"",
    DemanderId:"",
    DemanderAddress:"",
    ManufacturerId:"",
    PaysExploitant:"",
    PaysFabricant:"",
    RepresentantLocalouExploitant:"",
    VoieAdministration:"",
    DateExpirationDeLAMM:"",
    CondDelivrance:"",
    CodeAMM:"",
    PGHTPresentation:"",
    PGHTPrixPublic:"",
    DateDeDebut:"",
    DateExpiration:"",
    ClasseTherapeutique:"",
    GPCCode:"",
    EAGTIN:"",
    EAQty:"",
    EAChildGTIN:"",
    PKGTIN:"",
    PKQty:"",
    PKChildGTIN:"",
    CAGTIN:"",
    CAQty:"",
    CAChildGTIN:"",
    PLGTIN:"",
    PLQty:"",
    PLChildGTIN:"",
    // SOBAPSCode:"",
    bSerialSupported:"",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();
  
  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };
  const { data } = useQuery(
    ["BrandView", id],
    () =>
     
      api.getSingleProductListBrandView(id).then((res) => {
        // console.log("kkkkkkkkkkkkkk",res.data);
        setFormData(res.data);
        return res.data;
      }),

    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateProductBrand, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
  
        queryClient.getQueriesData("dci");
        props.history.push("/brands-list-entry");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      BrandCode: "",
     
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["BrandCode","CommercialId","ItemNo","ItemGroupId","ProductSubGroupId","SIGIPCode","ProductStatusId","StrengthId","PacksizeId","DosageFormId","DciId","DemanderId","ManufacturerId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
      
    }
  };

  return (
    <>
      <ProductListBrandFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        handleRDateChange={handleRDateChange} 
        {...props}
      />
    </>
  );
};

export default ProductListBrandEdit;
