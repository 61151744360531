import React, { useState } from "react";
import { Link } from "react-router-dom";

// services
import * as Service from "../../services/Service.js";

function IndexNavbar(props) {

/*======Start of Language Switcher=====*/

const [Language, setLangguage] = useState(true);
const [languageList, setLanguageList] = useState([]);

//const [Dispensing_Language, setDispensing_Language] = useState(true);

  // if (Dispensing_Language) {
  //     setDispensing_Language(false);
  //     Service.default
  //     .postApi("source/combo_generic.php",{action:"getDispensingLanguageList"})
  //     .then((res) => {
  //       if (res.success == 1) {
  //         let DispensingLanguageData = res.datalist;
  //         localStorage.setItem("DispensingLanguage", JSON.stringify(DispensingLanguageData));
  //       }
  //     })
  //     .catch((err) => {});
  // }

  if (Language) {    
    Service.default
      .postApi("source/language_combo.php")
      .then((res) => {
        setLanguageList(res);
        setLangguage(false);
      })
      .catch((err) => {});
  }

  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  // Set a Cookie
  const setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  let gLangCode = getCookie('LangCode');

  if((gLangCode === '') || (gLangCode === undefined)){
    setCookie("LangCode", 'fr_FR', 7);
    setCookie("LangName", 'French', 7);
  }

  const onLanguageSwitch = (LangCode, LangName) => {
    setCookie("LangCode", LangCode, 7);
    setCookie("LangName", LangName, 7);

    // window.location.href = props.history.location.pathname;
    props.history.push(props.history.location.pathname);
  };

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "menu";

  /* =====End of Language Switcher===== */

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        //setNavbarColor("");
        setNavbarColor("sticky");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        //setNavbarColor("navbar-transparent");
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const onMobileMenuOpen = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.add("open");
  };
  const onMobileMenuClose = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.remove("open");
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <div id="sticky-header" className={"header-menu " + navbarColor}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">

              <div className="logo_card">
                <div className="logo">
                  <Link to="/home"><img alt="..." src={require("assets/img/logo.png")}></img></Link>
                </div>
                <div className="logo_desc">
                  <div className="title"><Link to="/home">ePharmacie</Link></div>
                  <div className="name"><Link to="/home">BENIN</Link></div>
                </div>
                <button
                  onClick={() => onMobileMenuOpen()}
                  className="mobile_menu_bars_icon"
                  type="button"
                >
                  <i className="fas fa-bars"></i>
                </button>

              </div>

            </div>
            <div className="col-lg-9">
              <div className="tp-mega-full">
                <div className="tp-menu align-self-center">
                  <nav className="desktop_menu">
                    <ul> 
                    <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/home");
                      }}>{DispensingLanguage[lan][menukey]["Home"]}</a>
                    </li>

                    {/* <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/faq");
                      }}>{DispensingLanguage[lan][menukey]["FAQ"]}</a>
                    </li>

                    <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/about");
                      }}>{DispensingLanguage[lan][menukey]["About"]}</a>
                    </li>

                    <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/contact");
                      }}>{DispensingLanguage[lan][menukey]["Contact"]}</a>
                    </li> */}

                    <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/login");
                      }}>{DispensingLanguage[lan][menukey]["Login"]}</a>
                      </li>
                    <li>
                      <a href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/signup");
                      }}>{DispensingLanguage[lan][menukey]["SignUp"]}</a>
                    </li>
                    </ul>
                  </nav>

                  <div className="lang_switcher_dropdown desktop_lang_switcher">
                    <ul className="lang_switcher">
                      <li><a href="javascript:void(0)">{ getCookie('LangName') }</a>
                        <ul className="lang_submenu">
                          {languageList.map((item, index) => {
                            if(item.LangCode != getCookie('LangCode')){
                              return (
                                  <li><a href="javascript:void(0)" onClick={() => onLanguageSwitch(item.LangCode, item.LangName)}>{item.LangName}</a></li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      {/* =========Mobile Menu======== */}
      <div id="mobileMenuWrapper" className="mobile-menu-wrapper">
        <div className="off-canvas-overlay">
          <div className="offcanvas-body">
            <div className="mobile_menu_close">
              <button
                onClick={() => onMobileMenuClose()}
                className="mobile_menu_close_icon"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <ul className="sw_mobile_menu">
              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/home");
                }}>{DispensingLanguage[lan][menukey]["Home"]}</a>
              </li>

              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/faq");
                }}>{DispensingLanguage[lan][menukey]["FAQ"]}</a>
              </li>

              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/about");
                }}>{DispensingLanguage[lan][menukey]["About"]}</a>
              </li>

              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/contact");
                }}>{DispensingLanguage[lan][menukey]["Contact"]}</a>
              </li>

              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/login");
                }}>{DispensingLanguage[lan][menukey]["Login"]}</a>
                </li>
              <li>
                <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault();
                props.history.push("/signup");
                }}>{DispensingLanguage[lan][menukey]["SignUp"]}</a>
              </li>
            </ul>
            
              <div className="lang_switcher_dropdown mobile_lang_switcher">
                  <ul className="lang_switcher">
                    <li><a href="javascript:void(0)">{ getCookie('LangName') }</a>
                      <ul className="lang_submenu">
                        {languageList.map((item, index) => {
                          if(item.LangCode != getCookie('LangCode')){
                            return (
                                <li><a href="javascript:void(0)" onClick={() => onLanguageSwitch(item.LangCode, item.LangName)}>{item.LangName}</a></li>
                            );
                          }
                        })}
                      </ul>
                    </li>
                  </ul>
              </div>

          </div>
        </div>
      </div>
      {/* =========Mobile Menu======== */}
      
    </>
  );
}

export default IndexNavbar;
