import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import swal from "sweetalert";
// ZS screen
import ZS from "./ZS";
import ZSAdd from "./AddZS";
import ZSEdit from "./EditZS";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  };
  
  if(RedirectLogin){
    checkLogin();
    setRedirectLogin(false);
  }


  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  
  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  return (
 
    <div>
    <AfterLoginNavbar {...props} />
    <div
      className={`section signup-top-padding ${classes.zsContainer}`}
    >
      <Switch>
        <Route
          path={`${path}/add`}
          render={(props) => (
            <ZSAdd {...props} openNoticeModal={openNoticeModal} />
          )}
        ></Route>
        <Route
          path={`${path}/edit/:id`}
          render={(props) => (
            <ZSEdit {...props} openNoticeModal={openNoticeModal} />
          )}
        ></Route>
        <Route
          path={`${path}/`}
          render={(props) => (
            <ZS {...props} openNoticeModal={openNoticeModal} />
          )}
        ></Route>
      </Switch>
      <Notification
        closeNoticeModal={closeNoticeModal}
        msgObj={msgObj}
        {...props}
      ></Notification>
    </div>
  </div>


  );
};

export default Index;

const useStyles = makeStyles({
  zsContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
