import React, { useState, useEffect, useRef} from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const ProductsModal = ({ handleClose, open, addProduct, gFacilityId,gStoreId, ...props }) => {

  const { t, i18n } = useTranslation();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "adjustment-invoice";

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  let tblRef = null;
  let submitBtnRef = useRef();

  const [dataItems, setDataItems] = useState([]);
  const classes = useStyles();
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");
  //const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroup"));
  const [selectedProducts, setselectedProducts] = useState([]);
  //const [currProductGroupId, setCurrProductGroupId] = useState(0);

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroup"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroupList = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProductGroupId] = useState(0);

  let params = {
    menukey: menukey,
    action: "getProductsForInitial",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan:lan,
    FacilityId:gFacilityId,
    StoreId:gStoreId,
    ProductGroupId:currProductGroupId,
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProductsForInitial,{
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "BrandNo", field: "BrandNo", visible: false },
    { title: "Unit Name", field: "UnitName", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "GroupName",
      headerFilter: true,
      width: 165,
      visible: false,
    },
    { title: DispensingLanguage[lan][menukey]['SL'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Product_Code'], field: "ItemCode", width: 130, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Product'], field: "ItemName", headerFilter:true  },
    { title: DispensingLanguage[lan][menukey]['Brand Code'], field: "BrandCode", width: 195, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Brand Name'], field: "CommercialName", headerFilter:true, width: 210,},
    { title: DispensingLanguage[lan][menukey]['Unit Price (CFA)'], field: "UnitPrice", width: 100, hozAlign: "right", headerHozAlign:"right"},
    // GTIN Hidden
    { title: "EAGTIN", field: "EAGTIN", visible: false },
    { title: "PKGTIN", field: "PKGTIN", visible: false },
    { title: "CAGTIN", field: "CAGTIN", visible: false },
    { title: "PLGTIN", field: "PLGTIN", visible: false },
    { title: "SSCCCarton", field: "SSCCCarton", visible: false },
    { title: "SSCCPallet", field: "SSCCPallet", visible: false },
    

  ];

  let selected = [];
  const ProdustDataItems = data?.map((item, index) => { 
    return item;
  });
  const SaveSelectedProducts = () => {
    if(tblRef.table.getSelectedData().length>0){
      if (submitBtnRef == null) return;
      else submitBtnRef.current.disabled = true;
    setselectedProducts(tblRef.table.getSelectedData());
    }
    
  };

/*
const [all, setAll] = React.useState('0');
const handleProductGroupChange = (event) => {
  setAll(event.target.value);
  setCurrProductGroupId(event.target.value);
  
};

*/

const handleProductGroupChange = (event, newValue) => {

  let rowId = '';
  if (newValue == null) {
    rowId = '';
  } else {
    rowId = newValue.id;
  }
  if (rowId !== currProductGroupId) {
    setCurrProductGroupId(rowId);
  }
};

  useEffect(() => { 
    if (tblRef == null) return;
    addProduct(selectedProducts);

}, [selectedProducts]); 


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >

       



        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Products')}
        >
        {DispensingLanguage[lan][menukey]['Add_Items']} 
        </BootstrapDialogTitle>

        
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
                  {/* <Grid item xs={5} sm={5}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Product Group"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="ItemGroupId"
                        name="ItemGroupId"
                        //value={currMonthId}
                        value={all}
                        onChange={handleProductGroupChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ProductGroupList.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>              */}


                          <Grid item xs={5} sm={5}>
                            <FormControl className={classes.fullWidth}>
                            <Autocomplete
                                autoHighlight
                                className="sw_chosen_filter"
                                id="ItemGroupId"
                                disableClearable
                                options={ProductGroupList}               
                                onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                                getOptionLabel={(option) => option.name}
                                defaultValue={ProductGroupList[ProductGroupList.findIndex(AProductGroupList => AProductGroupList.id == currProductGroupId)]}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
                                
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={DispensingLanguage[lan][menukey]["Product Group"]}
                                  variant="standard"                       
                                />
                                )}
                              />                   
                            </FormControl>
                        </Grid>

            </Grid>
          </CardContent>
        </Card>


        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: 'click',
              groupBy: "GroupName",
              columnCalcs: "both",
             }}
            selectable={true}
            columns={ProductColumns}
            data={dataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            ref={submitBtnRef}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
           {DispensingLanguage[lan][menukey]['Submit']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Products')}
          >
            {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});