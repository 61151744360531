import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,

} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from '@material-ui/lab/Autocomplete';

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
const SupplierList = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "supplier";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
  const UserId = UserInfo == 0 ? '' : UserInfo[0].id;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
  // Facility Auto
  let Facility_List = JSON.parse(localStorage.getItem("FacilityList"));
  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  //let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
  //const FacilityList = [All_Facility_label].concat(Facility_List); 
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);
  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);


  // Facility
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let FName = '';
    if (newValue == null) {
      rowId = '';
      FName='';
    } else {

      rowId = newValue.id;
      FName =newValue.name;

    }

    if (rowId !== currFacilityId) {

      // Facility List By User Change
      setCurrFacilityId(rowId);
      setCurrFacilityName(FName);
      setLoading(true);
    }
    

  };

  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );

  }, [currFacilityId]);



  // End Facility



  let params = {
    menukey: menukey,
    "action": "getDataList",
    FacilityId: currFacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    lan: lan,
    UserId: UserId,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getSupplier,
    {
      onSuccess: (data) => {

        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );







  const { mutate: deleteApi } = useMutation(api.deleteSupplier, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        // api.getAllDropdown("PackSizelist").then((response) => {
        //   localStorage.setItem(
        //     "PackSizelist",
        //     JSON.stringify(response.datalist.PackSizelist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("supplier");


      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=getSupplierEntryData" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode")+
      "&FacilityId=" +
      currFacilityId+
      "&FacilityName=" +
      currFacilityName +
      "&UserId=" +
      UserId+
      
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
    {
      title: DispensingLanguage[lan][menukey]['Supplier Id'],
      field: "SupplierId",
      headerSort: false,
      headerFilter: true,
      formatter: "textarea",
      width: 115,
    },
    {
      title: DispensingLanguage[lan][menukey]['SupplierName'],
      field: "SupplierName",
      headerSort: false,
      headerFilter: true,
      formatter: "textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]['Supplier Address'],

      field: "SupplierAddress",
      headerFilter: true,
      formatter: "textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]['Country'],
      width: 100,
      field: "CountryName",
      headerFilter: true,
      formatter: "textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]['Contact Name'],

      field: "ContactName",

    },
    {
      title: DispensingLanguage[lan][menukey]['Contact No'],
      width: 90,
      field: "ContactNo",

    },
    {
      title: DispensingLanguage[lan][menukey]['Fax'],
      width: 80,
      field: "Fax",

    },
    {
      title: DispensingLanguage[lan][menukey]['Email'],

      field: "Email",

    },
    {
      title: DispensingLanguage[lan][menukey]['URL'],
      width: 80,
      field: "Url",

    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editSupplier = (data) => {
    let route = `supplier/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteSupplier = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Pack Size!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {

      if (willDelete) {
        deleteApi({ SupplierId: data.id, "menukey": menukey, "lan": lan, "UserName": UserName, "action": "dataDalete", FacilityId:currFacilityId });
      }

    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editSupplier(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteSupplier(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.packSizePageTitle}>

        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Supplier"])}
              </div>
            </div>
          </Grid>
        </div>

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="FacilityList"
                    disableClearable
                    options={FacilityList}
                    // defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName != "admin")) ? -1 : FacilityList.find(item => item.id == currFacilityId)}
                    disabled={UserRole == 1 ? false : true}
                    onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>
              </Grid>


              <Grid item xs={9} sm={9}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      if (currFacilityId!="0") { 
                        props.history.push("/supplier/add");
                      } else {
                        props.openNoticeModal({
                          isOpen: true,
                          msgtype: "0",
                          msg: DispensingLanguage[lan][menukey]["Please select a facility"],
                        });
                      } 
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add Supplier"])}
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>




            </Grid>
          </CardContent>
        </Card>

        <div>
          <div className="sw_relative">
            {isLoading && <LoadingSpinnerOpaque />}
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                // height={600}
                layout={"fitColumns"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierList;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
