import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import * as Service from "../../../services/Service.js";

const CommuneFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "commune";
  const [Regionlist, setRegionlist] = useState([]);
  let TempZoneList = useState([]); 
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  const [Region, setRegion] = React.useState(true);
  const [currRegionId, setRegionIdvalue] = useState(0);

  if(Region){
    setRegion(false);
    
    let ParamList={action:"Regionlist"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setRegionlist(res.datalist); 
         
        })
        .catch((err) => {});
  }

  React.useEffect(() => {
    cZoneList();
  }, [Region]);

  const cZoneList = () => {  
  
    let ZoneParam = {action:"Zonelist",RegionId:currRegionId,menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ZoneParam)
        .then((res) => { 
           setZoneListLocal(res.datalist); 
        })
        .catch((err) => {});
  


}

  const classes = useStyles();
  
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Commune - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Commune Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>
              
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["Department"]}*
                        </InputLabel>
                        <Select
                          error={errorObject.MenuKey}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="RegionId"
                          value={formData.RegionId}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          {Regionlist.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={errorObject.RegionId}>
                            {errorObject.RegionId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>


                    

                    <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["ZS"]}*
                        </InputLabel>
                        <Select
                          error={errorObject.MenuKey}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="ZoneId"
                          value={formData.ZoneId}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          {Zonelist.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={errorObject.ZoneId}>
                            {errorObject.ZoneId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>


                      <Grid item xs={5} sm={5}>
                        <TextField
                          error={errorObject.DistrictName}
                          helperText={errorObject.DistrictName}
                          required
                          id="DistrictName"
                          name="DistrictName"
                          label={DispensingLanguage[lan][menukey]['Commune']}
                          value={formData.DistrictName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}></Grid>

                    </Grid>
                  </Grid>

                </Grid>

              
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']} 
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/commune")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
               {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/commune")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default CommuneFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
