import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";

import {
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Typography

} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';
import * as api from '../../../actions/api';

// react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';
import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
  DeleteOutline,
  Edit,
  ViewList
} from "@material-ui/icons";


const StockTake = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "stock-take";
  const FacilityId = localStorage.getItem('FacilityId');
  const FacilityName = localStorage.getItem('FacilityName');
  const StoreId = localStorage.getItem("StoreId");
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;


  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;

  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");


  // Facility Auto
  const FacilityList = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);
  const [currFacilityName, setCurrFacilityName] = useState(FacilityName);

  
  // const AStoreList = JSON.parse(localStorage.getItem("getStore"));
  const AStoreList = JSON.parse(localStorage.getItem("getStore")).length==0?[{id:0,name:''}]:JSON.parse(localStorage.getItem("getStore"));
  const [currStoreId, setcurrStoreId] = useState(AStoreList[0].id);
  const [acstoreValue, setACstoreValue] = useState({ id: AStoreList[0].id, name: AStoreList[0].name});

  const [selectedStartDate, setSelectedStartDate] = React.useState(

    //  moment(testDate).format('MM/DD/YYYY');
    moment().subtract(90, "days").format('YYYY-MM-DD')
  );





  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);

  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

  let params = {
    "menukey": menukey, "FacilityId": currFacilityId, "StoreId": currStoreId, "action": "getStockTakeDataList",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getStockTakeInvs,
    {
      onSuccess: (data) => {

        FacilityList.forEach((item) => {
          if(item.id==currFacilityId){
            localStorage.setItem(
              "FacilityName",
              item.name
            ); 
          }            
         });

        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

    }
  );


  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    let Fname='';
    if (newValue == null) {
      rowId = '';
      Fname='';
    } else {
      rowId = newValue.id;
      Fname= newValue.name;
    }


    if (rowId !== currFacilityId) {

      setCurrFacilityId(rowId);
      setCurrFacilityName(Fname);
      // Store Change
      let params1 = { action: "gStoreListByFacility", "menukey": menukey, "FacilityId": rowId, "TransactionTypeId": 3, "lan": lan };
      mutateStore(params1);

      // Facility List By User Change
      let params2 = { action: "gUserListByFacility", "menukey": menukey, "FacilityId": rowId };
      mutategUserListByFacility(params2);
      setLoading(true);


    }


  };



  // Store Combo Change
 /*
  const handleStoreChange = (event) => {

    if(currStoreId!==event.target.value){
      setcurrStoreId(event.target.value); 
      setLoading(true);
    }
  };
  */

  const handleStoreChange = (event, newValue) => {
    setACstoreValue(newValue);
    let rowId = '';
  
    if (newValue == null) {
      rowId = '';
    
    } else {
      rowId = newValue.id;
     
    }
    if (rowId !== currStoreId) {
      setcurrStoreId(rowId);
      setLoading(true);
    }
  };




  React.useEffect(() => {
    localStorage.setItem(
      "FacilityId",
      currFacilityId
    );
  
   }, [currFacilityId]);
  
   
  React.useEffect(() => {
    localStorage.setItem(
      "FacilityName",
      currFacilityName
    );
  
   }, [currFacilityName]);
  
  
  React.useEffect(() => {
   
    localStorage.setItem(
      "StoreId",
      currStoreId
    );
    
  }, [currStoreId]);

  // End Facility Change


  // Store

  const { mutate: mutateStore } = useMutation(api.gStoreListByFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {

        setcurrStoreId(data.data.datalist[0]['id']);
        setACstoreValue({ id: AStoreList[0].id, name: AStoreList[0].name});

        localStorage.setItem(
          "StoreId",
          JSON.stringify(data.data.datalist[0]['id'])
        );

        localStorage.setItem(
          "getStore",
          JSON.stringify(data.data.datalist)
        );



      }
    }
  })




  // Change User List By Facility

  const { mutate: mutategUserListByFacility } = useMutation(api.gUserListByFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {

        localStorage.setItem(
          "UsersListByFacility",
          JSON.stringify(data.data.datalist)
        );

      }
    }
  })

  // End Change User List By Facility



  const { mutate } = useMutation(api.deleteStockTakeInv, {
    onSuccess: (data) => {

      if (data.success == 1) {

        refetch();
        queryClient.getQueriesData('stock-take');

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format('YYYY-MM-DD');
    let EndDate = moment(selectedEndDate).format('YYYY-MM-DD');

    window.open(
      finalUrl +
      "?action=getStockTake" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" + FacilityId +
      "&FacilityName=" + currFacilityName +
      "&StoreId=" + currStoreId +
      "&StartDate=" + StartDate +
      "&EndDate=" + EndDate + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editStockTakeInvs(rowData);
  };

  const columns = [
    { title: 'Id', field: 'id', visible: false },
    { title: t(DispensingLanguage[lan][menukey]['SL']), hozAlign: 'center', width: 100, formatter: "rownum", headerHozAlign: "center", headerSort: false },
    {
      title: t(DispensingLanguage[lan][menukey]['Start_Date']), field: 'StockTakeDate', formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return moment(value).format("DD/MM/YYYY");
      }
    },
    {
      title: t(DispensingLanguage[lan][menukey]['End_Date']), field: 'CompleteDate', formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //console.log("vvvvvvvvvv",value);
        return (value == "" ? '' : moment(value).format("DD/MM/YYYY"));
      }
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Prepared_By"]),
      field: "PreparedByName",
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Approved_By"]),
      field: "ApprovedByName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Status"],
      width: 85,
      headerHozAlign: "center",
      field: "IsCompleted",
      hozAlign: "center",
      formatter: "tickCross",
    },
    { title: t(DispensingLanguage[lan][menukey]['Actions']), field: 'custom', headerHozAlign: "center", headerSort: false, hozAlign: 'center', width: 150, formatter: reactFormatter(<ActionButton />) }
  ];



  const handleChangePage = (data) => {
    setPageNumber(data);
    setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const editStockTakeInvs = (data) => {
    let route = `stock-take/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteStockTakeInvs = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
      text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this stock take!']),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {

      if (willDelete) {
        mutate(data.id);

      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || 'Edit | Show';

    if (rowData.IsCompleted == 1)
      return (
        <>

          <ViewList onClick={() => {
            editStockTakeInvs(rowData);
          }} />

        </>
      )
    else
      return (
        <>

          <Edit onClick={() => {
            editStockTakeInvs(rowData);
          }} />

          <DeleteOutline onClick={() => {
            deleteStockTakeInvs(rowData);
          }} />
        </>

      )

  }

  return (
    <>
      <div className={classes.OrderPageTitle}>

        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{t(DispensingLanguage[lan][menukey]['Stock Take List'])}</div>
            </div>
          </Grid>
        </div>


        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]['From'])}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]['To'])}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="FacilityList"
                    disableClearable
                    options={FacilityList}
                    // defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName != "admin")) ? -1 : FacilityList.find(item => item.id == currFacilityId)}
                    disabled={UserRole == 1 ? false : true}
                    onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Store"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="AStoreId"
                    name="AStoreId"
                    value={currStoreId}
                    // value={all}
                    onChange={handleStoreChange}
                    fullWidth
                  >

                    {AStoreList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid> */}


               <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="AStoreId"
                      disableClearable
                      options={AStoreList}               
                      value={acstoreValue}
                      onChange={(event, newValue) => handleStoreChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={AStoreList[AStoreList.findIndex(AStoreListA => AStoreListA.id == currStoreId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Store"]}
                        variant="standard"
                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>


              <Grid item xs={4} sm={4}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/stock-take/add");
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]['Add'])}
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>


                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}


        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)} />
          </div>
        </div>


      </div>
    </>
  );
};

export default StockTake;

const useStyles = makeStyles({
  OrderPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});