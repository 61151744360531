import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Constants from "../../services/Constants";
// reactstrap components

import * as Service from "../../services/Service.js";
import * as api from "../../actions/api.js";

import swal from "sweetalert";

import { UserContext } from "../../context/user-info-context";
import "assets/css/sw_menu.css";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function AfterLoginNavbar(props) {
  const userCtx = useContext(UserContext);

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const lan = localStorage.getItem("LangCode");

  const menukey = "menu";

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const setMenuTable = () => {
    let auth = sessionStorage.getItem("User_info");
    auth = JSON.parse(auth);
    if (auth) {
      if (menu.length == 0 && auth.length > 0) {
        // setMenu(auth[0].menu);
        setMenu(auth[0].menu1);

        //console.log("auth[0].menu: ", auth[0].menu1);
      }
    } else {
      props.history.push("login");
    }
  };

  function recurciveMenus(menu) {}

  React.useEffect(() => {
    setMenuTable();
    let auth = sessionStorage.getItem("User_info");
    auth = JSON.parse(auth);
    // let auth = Constants.USER_INFO;
    if (auth) {
      var isAdmin = auth[0].role.filter((name) => name.role.includes("Admin"));
      if (isAdmin.length > 0) {
        sessionStorage.setItem("userRole", "Admin");
      } else {
        sessionStorage.setItem("userRole", "User");
      }
    }

    let r = sessionStorage.getItem("userRole");
    setRole(r);
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        //  setNavbarColor("");
        setNavbarColor("sticky");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        //  setNavbarColor("navbar-transparent");
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const onMobileMenuOpen = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.add("open");
  };
  const onMobileMenuClose = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.remove("open");
  };
  const handleMenuChange = (url) => {

   
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0; 
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;


let actionC="getFacilityListEqual99";
if('/issue-invoice'==url) 
 actionC="getFacilityListEqualLevel3";  
 
 
if(UserRole==1)
{

   

    const auth_token = sessionStorage.getItem("token") ? sessionStorage.getItem("token")  : null; 
      let options = {};
      options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth_token}`,
          "Consumer": process.env.REACT_APP_CONSUMER,
          "Secret": process.env.REACT_APP_SECRET,
        },
      };


       api.getLoginDependencyCombo(options, actionC)
       .then((response) => {
        let resArr=response.datalist.getFacilityListEqual99;
         
        if('/issue-invoice'==url) 
         resArr=response.datalist.getFacilityListEqualLevel3; 


        if( resArr.length>0){
        
        api.gUserListByFacility({FacilityId:resArr[0]['id'],action:'gUserListByFacility',"menukey": '' })
        .then((res) => { 
          
          localStorage.setItem(
            "UsersListByFacility",
            JSON.stringify(res.data.datalist)
          );  
 
          let TransactionTypeId=1;
          if('/dispense'==url) 
          TransactionTypeId=4;
          else if(('/adjustment-invoice'==url)||('/stock-status'==url)||('/stock-take'==url)) 
           TransactionTypeId=3;
          
    
        api.gStoreListByFacility({FacilityId:resArr[0]['id'],action:'gStoreListByFacility',"menukey": '',"TransactionTypeId":TransactionTypeId,"lan":lan })
        .then((res) => {
          
          
          
            localStorage.setItem(
              "getStore",
              JSON.stringify(res.data.datalist)
            ); 
            
            let dispenseStore=res.data.datalist.length>0?res.data.datalist[0]['id']:'';
            api.getLoginDependencyCombo1(options, "ALL",resArr[0]['id'],dispenseStore )
            .then((res1) => {
              
              
              let resArr2=res1.datalist.getFacilityListEqual99;
         
              if('/issue-invoice'==url) 
              resArr2=res1.datalist.getFacilityListEqualLevel3; 



              localStorage.setItem(
                "getFacilityListForReport",
                JSON.stringify(res1.datalist.getFacilityListForReport)
              );
              localStorage.setItem(
                "gStoreList",
                JSON.stringify(res1.datalist.gStoreList)
              );

              localStorage.setItem(
                "StoreId",
                res1.datalist.gStoreList[0]['id'] 
              ); 
              localStorage.setItem(
                "DefaultStoreId",
                res1.datalist.gStoreList[0]['id'] 
              );



              

                localStorage.setItem(
                  "OrderTo",
                  JSON.stringify(res1.datalist.OrderTo)
                );

                
                
                localStorage.setItem(
                  "getFacilityListEqual99",
                  JSON.stringify(resArr2)
                );

                localStorage.setItem(
                  "gAdjustmentTypeByFacility",
                  JSON.stringify(res1.datalist.gAdjustmentTypeByFacility)
                );

                localStorage.setItem(
                  "getFacilityListNotEqual99ANDSupplier",
                  JSON.stringify(res1.datalist.getFacilityListNotEqual99ANDSupplier)
                );

                localStorage.setItem(
                  "getFacilityListEqual99ByLevelId",
                  JSON.stringify(res1.datalist.getFacilityListEqual99ByLevelId)
                );         

                localStorage.setItem(
                  "getStoreListForStockTake",
                  JSON.stringify(res1.datalist.getStoreListForStockTake)
                );
                localStorage.setItem(
                  "getRecipientGroup",
                  JSON.stringify(res1.datalist.getRecipientGroup)
                ); 
                localStorage.setItem(
                  "getStoreListByFacilityForA7report",
                  JSON.stringify(res1.datalist.getStoreListByFacilityForA7report)
                ); 
   
                localStorage.setItem(
                  "getFacilityListFromApprover",
                  JSON.stringify(res1.datalist.getFacilityListFromApprover)
                ); 
                 

                
                

              props.history.push(url); 
              
            })

           

  
          });
 
        });
      
       
        localStorage.setItem(
          "FacilityId", 
          resArr[0]['id']
        );
        localStorage.setItem(
          "DefaultFacilityId", 
          resArr[0]['id']
          );
         
        
          
          


        }else{
          props.history.push(url); 
        }
           
  

       });

      }else{


        let auth_token = sessionStorage.getItem("token") ? sessionStorage.getItem("token")  : null; 
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
            "Consumer": process.env.REACT_APP_CONSUMER,
            "Secret": process.env.REACT_APP_SECRET,
          },
        };

        localStorage.setItem(
          "FacilityId",
          localStorage.getItem("DefaultFacilityId")
          );
          localStorage.setItem(
            "StoreId",
            localStorage.getItem("DefaultStoreId")
          ); 
          
          


          
          api.getLoginDependencyCombo1(options, "ALL",localStorage.getItem("DefaultFacilityId"),localStorage.getItem("DefaultStoreId"))
          .then((res1) => { 

            let resArr=res1.datalist.getFacilityListEqual99;         
            if('/issue-invoice'==url) 
             resArr=res1.datalist.getFacilityListEqualLevel3; 

console.log("resArr",resArr);
              localStorage.setItem(
                "OrderTo",
                JSON.stringify(res1.datalist.OrderTo)
              );

              

              
              localStorage.setItem(
                "getFacilityListEqual99",
                JSON.stringify(resArr)
              );
              if(resArr.length==0){

                localStorage.setItem(
                  "FacilityId",0
                  );
                  localStorage.setItem(
                    "StoreId",0
                  ); 
              }



              localStorage.setItem(
                "getFacilityListForReport",
                JSON.stringify(res1.datalist.getFacilityListForReport)
              );
              localStorage.setItem(
                "gStoreList",
                JSON.stringify(res1.datalist.gStoreList)
              );
              

              localStorage.setItem(
                "gAdjustmentTypeByFacility",
                JSON.stringify(res1.datalist.gAdjustmentTypeByFacility)
              );

              localStorage.setItem(
                "getFacilityListNotEqual99ANDSupplier",
                JSON.stringify(res1.datalist.getFacilityListNotEqual99ANDSupplier)
              );

              localStorage.setItem(
                "getFacilityListEqual99ByLevelId",
                JSON.stringify(res1.datalist.getFacilityListEqual99ByLevelId)
              );

              localStorage.setItem(
                "getStoreListForStockTake",
                JSON.stringify(res1.datalist.getStoreListForStockTake)
              );
              localStorage.setItem(
                "getRecipientGroup",
                JSON.stringify(res1.datalist.getRecipientGroup)
              ); 

              localStorage.setItem(
                "getStoreListByFacilityForA7report",
                JSON.stringify(res1.datalist.getStoreListByFacilityForA7report)
              ); 
              localStorage.setItem(
                "getFacilityListFromApprover",
                JSON.stringify(res1.datalist.getFacilityListFromApprover)
              ); 
               
 
              
              let TransactionTypeId=1;
              if('/dispense'==url) 
              TransactionTypeId=4;
              else if(('/adjustment-invoice'==url)||('/stock-status'==url)||('/stock-take'==url)) 
               TransactionTypeId=3;
              
              if(('/adjustment-invoice'==url))
               api.getLoginAllDropdown(options, "AdjType").then((response) => {
                if (response.success == 1) { 
                  localStorage.setItem(
                    "AdjType",
                    JSON.stringify(response.datalist.AdjType)
                  );
                  }
                 });
                  


            api.gStoreListByFacility({FacilityId:localStorage.getItem("DefaultFacilityId"),action:'gStoreListByFacility',"menukey": '',"TransactionTypeId":TransactionTypeId,"lan":lan })
            .then((res) => { 
              
                localStorage.setItem(
                  "getStore",
                  JSON.stringify(res.data.datalist)
                ); 
                
                let dispenseStore=res.data.datalist.length>0?res.data.datalist[0]['id']:'';
                api.getLoginDependencyCombo1(options, "ALL",localStorage.getItem("DefaultFacilityId"),dispenseStore )
                .then((res1) => { 
    
                  
                    localStorage.setItem(
                      "OrderTo",
                      JSON.stringify(res1.datalist.OrderTo)
                    );
                    
                    localStorage.setItem(
                      "getFacilityListEqual99",
                      JSON.stringify(res1.datalist.getFacilityListEqual99)
                    );
    
                    localStorage.setItem(
                      "gAdjustmentTypeByFacility",
                      JSON.stringify(res1.datalist.gAdjustmentTypeByFacility)
                    );
    
                    localStorage.setItem(
                      "getFacilityListNotEqual99ANDSupplier",
                      JSON.stringify(res1.datalist.getFacilityListNotEqual99ANDSupplier)
                    );
    
                    localStorage.setItem(
                      "getFacilityListEqual99ByLevelId",
                      JSON.stringify(res1.datalist.getFacilityListEqual99ByLevelId)
                    );

                   
    
                    localStorage.setItem(
                      "getStoreListForStockTake",
                      JSON.stringify(res1.datalist.getStoreListForStockTake)
                    );
                    localStorage.setItem(
                      "getRecipientGroup",
                      JSON.stringify(res1.datalist.getRecipientGroup)
                    ); 
                    localStorage.setItem(
                      "getStoreListByFacilityForA7report",
                      JSON.stringify(res1.datalist.getStoreListByFacilityForA7report)
                    ); 
       
                    
    
                    
                    localStorage.setItem(
                      "getFacilityListFromApprover",
                      JSON.stringify(res1.datalist.getFacilityListFromApprover)
                    ); 
                     
    
                  props.history.push(url); 
                  
                })
    
               
    
      
              });  

         //   props.history.push(url); 
            
          });








        //  props.history.push(url); 

      }
    
   

  
  };
  
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      {/*============================*/}

      <div id="sticky-header" className={"header-menu " + navbarColor}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">

              <div className="logo_card">
                <div className="logo">
                  <Link to="/home"><img alt="..." src={require("assets/img/logo.png")}></img></Link>
                </div>
                <div className="logo_desc">
                  <div className="title"><Link to="/home">ePharmacie</Link></div>
                  <div className="name"><Link to="/home">BENIN</Link></div>
                </div>

                  <button
                  onClick={() => onMobileMenuOpen()}
                  className="mobile_menu_bars_icon"
                  type="button"
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>

            </div>
            <div className="col-lg-9">
              <div className="tp-mega-full">
                <div className="tp-menu align-self-center">
                  <nav className="desktop_menu">
                    <ul>
                      {menu.map((row, i) => {
                        return (
                          <li>
                            <Link
                              href="javascript:void(0)"
                              onClick={() => props.history.push(row.url)}
                            >
                              {DispensingLanguage[lan][menukey][row.title]}

                              {row.submenu.length > 0 ? (
                                <span className="tp-angle pull-right">
                                  <i className="fa fa-angle-down"></i>
                                </span>
                              ) : (
                                <></>
                              )}
                            </Link>

                            {row.submenu.length > 0 ? (
                              <ul className={"submenu " + row.position}>
                                {row.submenu.map((row1, i1) => {
                                  return (
                                    <li>
                                      <Link
                                        href="javascript:void(0)"
                                        onClick={()=>handleMenuChange(row1.url)  }
                                          // onClick={() =>
                                          //   props.history.push(row1.url)
                                          // }
                                      >
                                        {
                                          DispensingLanguage[lan][menukey][
                                            row1.title
                                          ] 
                                        }

                                        {row1.submenu.length > 0 ? (
                                          <span className="tp-angle pull-right">
                                            <i className="fa fa-angle-right"></i>
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </Link>

                                      {row1.submenu.length > 0 ? (
                                        <ul className="submenu right_position">
                                          {row1.submenu.map((row2, i2) => {
                                            return (
                                              <li>
                                                <Link
                                                  href="javascript:void(0)"
                                                  onClick={() =>handleMenuChange(row2.url) }
                                                //  onClick={() =>
                                                //   props.history.push(row2.url)
                                                // }
                                                >
                                                  {
                                                    DispensingLanguage[lan][
                                                      menukey
                                                    ][row2.title]
                                                  }
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        );
                      })}
                      
                    {/* <li>
                      <Link href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/faq");
                      }}>{DispensingLanguage[lan][menukey]["FAQ"]}</Link>
                    </li>

                    <li>
                      <Link href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/about");
                      }}>{DispensingLanguage[lan][menukey]["About"]}</Link>
                    </li>

                    <li>
                      <Link href="javascript:void(0)" onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/contact");
                      }}>{DispensingLanguage[lan][menukey]["Contact"]}</Link>
                    </li> */}

                      <li>
                        <Link href="#">
                          {DispensingLanguage[lan][menukey]["profile"]}
                          <span className="tp-angle pull-right">
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </Link>
                        <ul className="submenu right_position">
                          {/* <li>
                            <a href="#">My account</a>
                          </li>
                          <li>
                            <a href="#">My profile</a>
                          </li>
                          <li>
                            <a href="#">Admin Permissions</a>
                          </li> */}
                          <li>
                              <Link href="javascript:void(0)" onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/my-profile");
                              //props.history.push("/my-profile");
                              }}>{DispensingLanguage[lan][menukey]["My Profile"]}</Link>
                          </li>
                          <li>
                            <Link
                              href="javascript:void(0)"
                              onClick={(e) => {
                                e.preventDefault();
                                sessionStorage.clear();
                                setTimeout(() => {
                                  window.location.href = `${process.env.REACT_APP_BASE_NAME}/login`;
                                //  props.history.push("/login");
                                }, 1000);
                              }}
                            >
                              {DispensingLanguage[lan][menukey]["Logout"]}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =========Mobile Menu======== */}
      <div id="mobileMenuWrapper" className="mobile-menu-wrapper">
        <div className="off-canvas-overlay">
          <div className="offcanvas-body">
            <div className="mobile_menu_close">
              <button
                onClick={() => onMobileMenuClose()}
                className="mobile_menu_close_icon"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <ul className="sw_mobile_menu">
              {menu.map((row, i) => {
                return (
                  <li>
                    <Link
                      href="javascript:void(0)"
                      onClick={() => props.history.push(row.url)}
                    >
                      {DispensingLanguage[lan][menukey][row.title]}

                      {row.submenu.length > 0 ? (
                        <span className="tp-angle">
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <></>
                      )}
                    </Link>

                    {row.submenu.length > 0 ? (
                      <ul className="submenu">
                        {row.submenu.map((row1, i1) => {
                          return (
                            <li>
                              <Link
                                href="javascript:void(0)"
                                onClick={() => props.history.push(row1.url)}
                              >
                                {DispensingLanguage[lan][menukey][row1.title]}

                                {row1.submenu.length > 0 ? (
                                  <span className="tp-angle">
                                    <i className="fa fa-angle-right"></i>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </Link>

                              {row1.submenu.length > 0 ? (
                                <ul className="submenu">
                                  {row1.submenu.map((row2, i2) => {
                                    return (
                                      <li>
                                        <Link
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            props.history.push(row2.url)
                                          }
                                        >
                                          {
                                            DispensingLanguage[lan][menukey][
                                              row2.title
                                            ]
                                          }
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              })}

              <li>
                <Link href="#">
                  {DispensingLanguage[lan][menukey]["profile"]}

                  <span className="tp-angle">
                    <i className="fa fa-angle-down"></i>
                  </span>
                </Link>
                <ul className="submenu">
                  {/*<li>
                    <a href="#">My account</a>
                  </li>
                  <li>
                    <a href="#">My profile</a>
                  </li>
                  <li>
                    <a href="#">Admin Permissions</a>
                  </li>*/}
                  <li>
                    <Link
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        sessionStorage.clear();
                        setTimeout(() => {

                          window.location.href = `${process.env.REACT_APP_BASE_NAME}/login`;
                         // props.history.push("/login");
                        }, 1000);
                      }}
                    >
                      {DispensingLanguage[lan][menukey]["Logout"]}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* =========Mobile Menu======== */}
    </>
  );
}

export default AfterLoginNavbar;
