import React, { useState } from "react";
import { makeStyles} from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";


import { useTranslation } from "react-i18next";

//get DispensingLanguage



const FacilityLevelFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "facility-level-entry";
  
  
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Facility Level - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Facility Level Entry']} />
              <CardContent>
                <Grid container spacing={1}>
              
                  <Grid item xs={12} sm={12}>
                  <Grid container spacing={4}>
                     
                      <Grid item xs={6} sm={6}>
                      <TextField
                          error={errorObject.FLevelName}
                          helperText={errorObject.FLevelName}
                          required
                          id="FLevelName"
                          name="FLevelName"
                          label={DispensingLanguage[lan][menukey]['Facility Level']}
                          value={formData.FLevelName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                      <TextField
                          error={errorObject.FLevelShortName}
                          helperText={errorObject.FLevelShortName}
                          required
                          id="FLevelShortName"
                          name="FLevelShortName"
                          label={DispensingLanguage[lan][menukey]['Short Facility Level']}
                          value={formData.FLevelShortName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                      <TextField
                          error={errorObject.PriceMarkupMin}
                          helperText={errorObject.PriceMarkupMin}
                          required
                          id="PriceMarkupMin"
                          name="PriceMarkupMin"
                          label={DispensingLanguage[lan][menukey]['Min Markup Price']}
                          value={formData.PriceMarkupMin}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                      <TextField
                          error={errorObject.PriceMarkupMax}
                          helperText={errorObject.PriceMarkupMax}
                          required
                          id="PriceMarkupMax"
                          name="PriceMarkupMax"
                          label={DispensingLanguage[lan][menukey]['Max Markup Price']}
                          value={formData.PriceMarkupMax}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                    </Grid>


                    
                  </Grid>

                </Grid>

              
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                 {DispensingLanguage[lan][menukey]['Reset']} 
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
               {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/facility-label")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/facility-label")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default FacilityLevelFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
