import React, { useState, useEffect } from "react";

import {
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";

import * as api from '../../../actions/api';
import StockTakeFormData from './StockTakeFormData.js';

const AddStockTake = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "stock-take";
const FacilityId=localStorage.getItem('FacilityId');
const StoreId = localStorage.getItem("StoreId");
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

const [selectedDate, setSelectedDate] = useState(
  new Date()
);


const [selecteEnddDate, setSelecteEnddDate] = useState(
  null
 );
const [formData, setFormData] = useState({
  StockTakeDate: selectedDate,
  CompleteDate: selecteEnddDate,
  IsCompleted:0,
  ManyJsonSave:"",
  "FacilityId":FacilityId,
  StoreId:StoreId
});

const [ManyTableData, setManyTableData] = useState( []);

  const handleRDateChange = (date, field) => {
    
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();
  /*
  const { isLoading, mutate } = useMutation(api.saveStockTakeInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        let route = `edit/${data.StockTakeId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })

  */

  const handleChange = (e) => {
    const { name, value } = e.target;
    
      let data = { ...formData };
      console.log('formData: ', formData);
      data[name] = value;
      setFormData(data);
   


    setErrorObject({ ...errorObject, [name]: null });
  };


  const handleReset = () => {
    setFormData({
      StockTakeDate: selectedDate,
      CompleteDate: selecteEnddDate,
      IsCompleted:0,
      ManyJsonSave:"",
      "FacilityId":FacilityId,
      StoreId:StoreId  
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["StockTakeDate", "PreparedBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }
  /*
  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
    }
  };
  */

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,"menukey":menukey,"lan":lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  return (
    <>
      <StockTakeFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange}  
      handleReset={handleReset} 
     // handleSubmit={handleSubmit} 
      ManyTableData={ManyTableData} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      selecteEnddDate={selecteEnddDate} 
      checkValidateForm={checkValidateForm}
      {...props} />
    </>
  );
};

export default AddStockTake;