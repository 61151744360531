

import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";

import {
  Grid,
  Paper,
  CardContent,
  Card,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
const SettingsEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "settings-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const userId = UserInfo == 0 ? '' : UserInfo[0].id;

  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [TabList, setTabList] = useState([]);
  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  let tblRef = null;
  const { id } = useParams();
  const [value, setValue] = React.useState("DHIS2");

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }







  let params = {
    "menukey": menukey,
    action: 'getSettingsEntry',
    "UserId": userId,
    "lan": lan,
    "TabMaster": value,


  };
  const { refetch: refetch1 } = useQuery([params],
    api.getSettingsEntryData,
    {
      onSuccess: (data) => {

        // console.log("zonedata",data);
        setTabList(data.data.TabList);
        setDataItems(data.data.datalist);



      }


    }, {
    enabled: Boolean(id),
  }
  );



  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };



  // useEffect(() => {

  //   if (tblRef == null) return;
  //   setDhis2Data(Dhis2Data);

  // }, [Dhis2Data]);


  const { isLoadingLang, mutate: bCheckedUpdate } = useMutation(
    api.UpdateSettingUpdate,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          refetch1();


        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }

      },
    }
  );

  const columns = [
    {
      title: "SettingsId",
      field: "SettingsId",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]['TabMaster'],
      field: "TabMaster",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "text-field-editable",
      headerFilter: true,
      editor: false,

      // cellEdited: function (cell) {
      //   cellDataUpdatePop(cell);
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]['SKey'],
      field: "SKey",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerFilter: true,
      editor: true,

      cellEdited: function (cell) {
        cellDataUpdatePop(cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]['SValue'],
      field: "SValue",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerFilter: true,
      editor: true,

      cellEdited: function (cell) {
        cellDataUpdatePop(cell);
      },
    },

  ];
  const cellDataUpdatePop = (cell) => {
    let currow = cell.getRow();
    let rowdata = currow.getData();
    let SettingsId = rowdata.SettingsId;
    let TabMaster = rowdata.TabMaster;
    let SKey = rowdata.SKey;
    let SValue = rowdata.SValue;


    bCheckedUpdate({ UserName, userId, lan, SettingsId, TabMaster, SKey, SValue });

  };



  const TabName = TabList.map((item, index) =>
  <Tab
  label={DispensingLanguage[lan][menukey][item.TabMaster]}
  {...a11yProps(index)}
  value={item.TabMaster}
/>
);












  const deleteUser = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this user!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // deleteApi({user_id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "userDelete"});
      }
    });
  };




  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Settings Entry"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/settings_entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button>
                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                 onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                 onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>

        <Grid container spacing={3}>
          {/* new row */}
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <Card className="sw_card">
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <AppBar position="static" color="default" className="mb-2">
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                         {TabName}
                          {/* <Tab
                            label={DispensingLanguage[lan][menukey]["DHIS2"]}
                            {...a11yProps(0)}
                            value="DHIS2"
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Issue_Serial"]
                            }
                            {...a11yProps(1)}
                            value="Issue_Serial"
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Others"]
                            }
                            {...a11yProps(2)}
                            value="Others"
                          /> */}
                        </Tabs>
                      </Box>
                    </AppBar>

                  </Box>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>

        {/* new row */}
        <div className="row">
          <div className="col-md-12">
            <Card className="sw_card">
              {/*  <CardHeader
                  
                /> */}

              <CardContent>
                <div className="row">
                  <div className="col-md-12">
                    <div className="uniqueName">
                      <ReactTabulator
                        columns={columns}
                        data={dataItems}
                        layout={"fitColumns"}
                        height="450px"
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        {/* end row */}

      </div>
    </>
  );
};

export default SettingsEntry;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
