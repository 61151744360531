import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import * as api from "../../../actions/api";
import ShipmentEntryFormData from "./ShipmentEntryFormData.js";
// import history from './../../../history';
import Constants from "../../../services/Constants";
import moment from "moment";

const EditShipmentEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [formData, setFormData] = useState({
    FundingSourceId: "",
    ShipmentStatusId: "",
    ItemGroupId: "",
    ItemNo: "",
    ShipmentDate: "",
    Qty: "",
    EntryPort: "",
    FacilityId: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["shipmentEntry", id],
    () =>
      api.getShipmentData(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateShipmentData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        // api.getAllDropdown("PackSizelist").then((response) => {
        //   localStorage.setItem(
        //     "PackSizelist",
        //     JSON.stringify(response.datalist.PackSizelist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        queryClient.getQueriesData("shipmentEntry");
        props.history.push("/shipment-entry");
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });

  const checkNumberValidation = (name, value) => {
    if (name == "Price") {
      let newValue = "";
      if (
        value.split(".") &&
        value.split(".")[1] &&
        value.split(".")[1].length > 2
      ) {
        newValue = parseFloat(value).toFixed(2);
      } else {
        newValue = value;
      }
      setFormData({ ...formData, [name]: newValue });
    }

    /*if (name == "markUp") {
      value = value && value != "" ? parseInt(value) : value;
      if (value == "" || value == 0 || (value >= 0 && value <= 100)) {
        setFormData({ ...formData, [name]: value });
      }
    }*/
  };

  const handleChange = (e) => {

    const { name, value } = e.target;
    if (name == "Price") {
      checkNumberValidation(name, value);
    } else {

      let data = { ...formData };
      data[name] = value;


      setFormData(data);
    }
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      FundingSourceId: "",
      ShipmentStatusId: "",
      ItemGroupId: "",
      ItemNo: "",
      ShipmentDate: "",
      Qty: "",
      EntryPort: "",
      FacilityId: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ShipmentStatusId","FundingSourceId","ItemNo","ShipmentDate","Qty","FacilityId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  // const ValidateProductForDispensing = async (e) => {
  //   return;
  // };


//Date formate code here 

  const handleRDateChange = (date, field) => {
    let newDate;
    if(date==null || date==""){
      newDate="";
     }
     else{
       newDate = moment(date).format("YYYY-MM-DD");
     }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };


  return (
    <>
      <ShipmentEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        handleRDateChange={handleRDateChange}
        //ValidateProductForDispensing={ValidateProductForDispensing}
        {...props}
      />
    </>
  );
};

export default EditShipmentEntry;
