import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Typography, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone } from "@material-ui/icons";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import Autocomplete from '@material-ui/lab/Autocomplete';

const Facility = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility";
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [bDispensingValue, setbDispensing] = useState(false);
  const [isLoadingUi, setLoading] = useState(true);

  const [activedep, setDepartment] = useState({ id: '0', name: "All" });
  
  const [activecommun, setCommune] = useState({ id: '0', name: "All" });

  const Regionlist_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_Regionlist_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Regionlist = [All_Regionlist_Name].concat(Regionlist_Name);


  const [currRegionId, setcurrRegionId] = useState(Regionlist[0].id);
  //console.log('data RegionId',currRegionId);


  const FlevelList_Name = JSON.parse(localStorage.getItem("FlevelList"));
  let All_FlevelList_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FlevelList = [All_FlevelList_Name].concat(FlevelList_Name);


  const FTypeList_Name = JSON.parse(localStorage.getItem("FTypeList"));
  let All_FTypeList_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FTypeList = [All_FTypeList_Name].concat(FTypeList_Name);


  const Ownertypelist_Name = JSON.parse(localStorage.getItem("Ownertypelist"));
  let All_Ownertypelist_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Ownertypelist = [All_Ownertypelist_Name].concat(Ownertypelist_Name);


  //const Servicearealist = JSON.parse(localStorage.getItem("Servicearealist"));



  const Servicearea_Name = JSON.parse(localStorage.getItem("Servicearealist"));
  let All_Servicearea_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Servicearealist = [All_Servicearea_Name].concat(Servicearea_Name);




  const [FacilityCount, setFacilityCount] = useState(0);
  const [FacilityTableData, setFacilityTableData] = React.useState(true);


  const [currFLevelId, setcurrFLevelId] = useState(0);
  const [currFTypeId, setcurrFTypeId] = useState(0);

  const [currOwnerTypeId, setOwnerTypeId] = useState(0);
  const [currServiceAreaId, setServiceAreaId] = useState(0);

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);
  // console.log(Zonelist);


  const [currZoneId, setcurrZoneId] = useState("0");
  //console.log('data zone ID',currZoneId);


  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);
  //const [currCommuneId, setcurrCommuneId] = useState("0");

  const [currCommuneId, setcurrCommuneId] = useState("0");
  //console.log("data commune id",currCommuneId);




  const [bEsigl, setBESIGL] = useState(false);

  let params = {
    menukey: menukey,
    action: "getDataList",
    bDispensingValue: bDispensingValue,
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    FLevelId: currFLevelId,
    FTypeId: currFTypeId,
    ServiceAreaId: currServiceAreaId,
    OwnerTypeId: currOwnerTypeId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    mode: bEsigl

  };

  const handleRegionChange = (event, newValue) => {
    let rowId = '';
    //let rowName = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
      //rowName = newValue.name;
    }

    if (rowId !== currRegionId) {
      setcurrRegionId(rowId);
      setcurrZoneId("0");
      setcurrCommuneId("0");
    }

     let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
     cZoneList(ZoneParam);


  };


  const handleZoneChange = (event, newValue) => {
   // setcurrCommuneId(0);
    setDepartment(newValue);
    let rowId = '';
   // let rowName = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }
    if (rowId !== currZoneId) {
      setcurrZoneId(rowId);
      setcurrCommuneId("0");
      //setZoneName(rowName);

    }

    let CommuneParam = { action: "gCommunelist", RegionId: currRegionId, ZoneId: newValue.id, menukey: "" }
     cCommuneList(CommuneParam);

  };


  const handleCommuneChange = (event, newValue) => {
    setCommune(newValue)
    let rowId = '';
   // let rowName = '';
    if (newValue == null) {
      rowId = '';
     // rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
     // setDistrictName(rowName)

    }
  };





  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getFacilities,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { isLoadingLang, mutate: bDispenseUpdate } = useMutation(
    api.updateFacility,
    {
      onSuccess: (data) => {
        if (data.data.status == 200) {
          api.getAllDropdown('FacilityList').then((response) => {
            console.log(response);
            localStorage.setItem(
              "FacilityList",
              JSON.stringify(response.datalist.FacilityList)
            );
          })

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }

      },
    }
  );

  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setDepartment({ id: '0', name: "All" })
          // console.log("Dta list", data.data.RegionId);

          let CParam = { action: "gCommunelist", RegionId: data.data.RegionId, ZoneId: 0, menukey: "" }
          cCommuneList(CParam);



        }

      },
    }
  );


  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setCommune({ id: '0', name: "All" });
        }

      },
    }
  );

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setbDispensing(event.target.checked);
  };




  // const handleFlevelChange = (event) => {
  //   setcurrFLevelId(event.target.value);
  // };

   // Facility Change
 const handleFlevelChange = (event, newValue) => {
  let rowId = '';
 // let rowName = '';
  if (newValue == null) {
    rowId = '';
  //  rowName = '';
  } else {
    rowId = newValue.id;
   // rowName = newValue.name;
  }if (rowId !== currFLevelId) {
    setcurrFLevelId(rowId);
    //setFacilityLavel(rowName);
   
    
  }

};

  // const handleFTypeChange = (event) => {
  //   setcurrFTypeId(event.target.value);
  // };

  const handleFTypeChange = (event, newValue) => {
    let rowId = '';
   // let rowName = '';
    if (newValue == null) {
      rowId = '';
    //  rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }if (rowId !== currFLevelId) {
      setcurrFTypeId(rowId);
      //setFacilityLavel(rowName);
     
      
    }
  
  };


  // const handleOwnerTypeChange = (event) => {
  //   setOwnerTypeId(event.target.value);

  // };


  const handleOwnerTypeChange = (event, newValue) => {
    let rowId = '';
   // let rowName = '';
    if (newValue == null) {
      rowId = '';
    //  rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }if (rowId !== currFLevelId) {
      setOwnerTypeId(rowId);
      //setFacilityLavel(rowName);
     
      
    }
  
  };





  // const handleServiceAreaChange = (event) => {
  //   setServiceAreaId(event.target.value);

  // };

  const handleServiceAreaChange = (event, newValue) => {
    let rowId = '';
   // let rowName = '';
    if (newValue == null) {
      rowId = '';
    //  rowName = '';
    } else {
      rowId = newValue.id;
     // rowName = newValue.name;
    }if (rowId !== currServiceAreaId) {
      setServiceAreaId(rowId);
      //setFacilityLavel(rowName);
     
      
    }
  
  };




  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }




  const { mutate: deleteApi } = useMutation(api.deleteaFacility, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        // api.getAllDropdown("DosageFormlist").then((response) => {
        //   localStorage.setItem(
        //     "DosageFormlist",
        //     JSON.stringify(response.datalist.DosageFormlist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("facility");


      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }

    },
  });


  const handleBESIGLChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setBESIGL(event.target.checked);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=FacilityEntryExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&RegionId=" +
      currRegionId +
      "&ZoneId=" +
      currZoneId +
      "&CommuneId=" +
      currCommuneId +
      "&FTypeId=" +
      currFTypeId +
      "&ServiceAreaId=" +
      currServiceAreaId +
      "&OwnerTypeId=" +
      currOwnerTypeId +
      "&mode=" +
      bEsigl
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /*
  React.useEffect(() => {
      setcurrZoneId(0); 
      cZoneList();
    }, [currRegionId]);
    React.useEffect(() => {
      setcurrCommuneId(0);
      cCommuneList();
    }, [currRegionId,currZoneId]);
  
    */



  React.useEffect(() => {
    refetch();
  }, [currServiceAreaId, currRegionId, currZoneId, currCommuneId, currFTypeId, currOwnerTypeId]);


  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editFacility(rowData);
  };

  const columns = [

    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 60,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility_Id"],
      field: "id",
      width: 90,
      frozen: true,
      headerSort: false,
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      headerFilter: true,
      width: 120,
      field: "FacilityCode",
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerFilter: true,
      frozen: true,
      width: 260,
    },



    { title: "Id", field: "id", visible: false },
    /*
    {
      title: DispensingLanguage[lan][menukey]["Facility Level"],
      field: "FLevelName",
      hozAlign: "left",
      width: 150,
      headerFilter: true,
      headerHozAlign: "left",
    }, */
    
  
    {
      title: DispensingLanguage[lan][menukey]["Facility Type"],
      field: "FTypeName",
      hozAlign: "left",
      width: 135,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["Facility Classification"],
      field: "FClassificationName",
      hozAlign: "left",
      width: 165,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["Department Name"],
      field: "RegionName",
      hozAlign: "left",
      width: 147,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS Name"],
      field: "ZoneName",
      hozAlign: "left",
      width: 120,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune Name"],
      field: "DistrictName",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Supply Chain Owner"],
      field: "OwnerName",
      hozAlign: "left",
      width: 160,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Owner Type"],
      field: "OwnerTypeName",
      hozAlign: "left",
      width: 125,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Service Area"],
      field: "ServiceAreaName",
      hozAlign: "left",
      width: 125,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Address"],
      field: "FacilityAddress",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    }, {
      title: DispensingLanguage[lan][menukey]["Latitude"],
      field: "Latitude",
      hozAlign: "left",
      width: 90,
      headerFilter: true,
      headerHozAlign: "left",
    }, {
      title: DispensingLanguage[lan][menukey]["Longitude"],
      field: "Longitude",
      hozAlign: "left",
      width: 100,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility In-Charge"],
      field: "FacilityInCharge",
      hozAlign: "left",
      width: 145,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["Facility Phone"],
      field: "FacilityPhone",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Email"],
      field: "FacilityEmail",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["DHIS2 Facility Uid"],
      field: "ExternalFacilityId",
      hozAlign: "left",
      width: 140,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Client Code"],
      field: "SOBAPSCode",
      hozAlign: "left",
      width: 130,
      headerFilter: true,
      headerHozAlign: "left",
    },

    {
      title: DispensingLanguage[lan][menukey]["GLN"],
      field: "GLN",
      hozAlign: "left",
      width: 100,
      headerFilter: true,
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["eSIGLFacilityid"],
      field: "eSIGLFacilityid",
      hozAlign: "left",
      width: 100,
      headerFilter: true,
      headerHozAlign: "left",
    },

    /*

    {
      title: DispensingLanguage[lan][menukey]["bDispensing"],
      field: "bDispense",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 90,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bDispense"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.id;
        cellDataUpdatePop(id, cell);
      },
    },

    */



  ];

  const editFacility = (data) => {
    let route = `facility/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteFacility = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Facility!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({ FacilityId: data.id, "menukey": menukey, "lan": lan, "UserName": UserName, "action": "deleteaFacility", "bDispense": 0 });

      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editFacility(rowData);
          }}
        />

        <DeleteOutline
          onClick={() => {
            deleteFacility(rowData);
          }}
        />

      </>
    );
  }

  const cellDataUpdatePop = (id, cell) => {
    let curcellval = cell.getValue();
    let bDispenseVal = curcellval;

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text:
        bDispenseVal == true
          ? t(
            DispensingLanguage[lan][menukey][
            "You want to update this facility!"
            ]
          )
          : t(
            DispensingLanguage[lan][menukey][
            "You want to delete this facilities relevant transaction records!"
            ]
          ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        bDispenseUpdate({ id, bDispenseVal });

      } else {
        refetch();
      }
    });
  };


  return (
    <>
      <div className={classes.facilityPageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Facility"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              {/* <Grid item xs={2} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    //checked={checked} 
                    onChange={handleChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["bDispensing"]}
              />

              </Grid> */}

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="RegionId"
                      disableClearable
                      options={Regionlist}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                      //disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleRegionChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Regionlist[Regionlist.findIndex(Regionlist => Regionlist.id == currRegionId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      value={activedep}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />

                </FormControl>
              </Grid>

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={activecommun}
                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />


                </FormControl>
              </Grid>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FlevelList"
                      disableClearable
                      options={FlevelList}
                      onChange={(event, newValue) => handleFlevelChange(event, newValue)}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={FlevelList[FlevelList.findIndex(FlevelList => FlevelList.id == currFLevelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Level"]}
                          variant="standard"

                        />
                      )}
                    />


                </FormControl>
              </Grid>


              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">

                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/facility/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Facility"]}
                  </Button>

                  {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button> */}

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>








              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FTypeList"
                      disableClearable
                      options={FTypeList}
                      onChange={(event, newValue) => handleFTypeChange(event, newValue)}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={FTypeList[FTypeList.findIndex(FTypeList => FTypeList.id == currFTypeId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Type"]}
                          variant="standard"

                        />
                      )}
                    />

                </FormControl>
              </Grid>



              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Ownertypelist"
                      disableClearable
                      options={Ownertypelist}
                      onChange={(event, newValue) => handleOwnerTypeChange(event, newValue)}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={Ownertypelist[Ownertypelist.findIndex(Ownertypelist => Ownertypelist.id == currOwnerTypeId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Owner Type"]}
                          variant="standard"

                        />
                      )}
                    />

                </FormControl>
              </Grid>


              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Servicearealist"
                      disableClearable
                      options={Servicearealist}
                      onChange={(event, newValue) => handleServiceAreaChange(event, newValue)}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={Servicearealist[Servicearealist.findIndex(Servicearealist => Servicearealist.id == currServiceAreaId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Service Area"]}
                          variant="standard"

                        />
                      )}
                    />


                </FormControl>
              </Grid>


              <Grid item xs={2} sm={2}>
                <FormControlLabel className="marginTop10"
                  control={
                    <Checkbox
                      color="primary"
                      name="bDispensingFilter"
                      // checked={checked} 
                      onChange={handleBESIGLChange}

                    //value="no"
                    />
                  }
                  label={DispensingLanguage[lan][menukey]["eSIGL"]}
                />
              </Grid>
              <Grid item xs={2} sm={2}>


                <div className="float-right">
                  <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Facility'] + ": " + FacilityCount}</h4>
                </div>


              </Grid>

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">

          <div className="uniqueName">
            {isLoadingUi && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={400}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Facility;

const useStyles = makeStyles({
  facilityPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
