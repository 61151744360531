import React, { useState, useEffect } from "react";
// reactstrap components
import { container, Row, Col, Button } from "reactstrap";
import { Box, Typography } from "@material-ui/core";

function AboutPageContent(props) {
  const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  let lan = '';
  if(UserName == ''){
    lan = getCookie('LangCode');
  }else{
    lan = localStorage.getItem("LangCode");
  }
  const menukey = "about";

  return (
    <>
      {/* Selection 2 */}
      <div className="inner-section" style={{backgroundColor: "#f7f7f7"}}>
       <div className="container-fluid">
         <div className="row">
         <div className="col-lg-12">
          <div className="Error404" style={{minHeight: '60vh'}}>
            <div className="dislpy">
            <p className="MuiTypography_h1">
                  404
                </p>

            </div>
            <div className="EororData">
            <Typography variant="h4">
              The page you’re looking for doesn’t exist.
            </Typography>
            </div>
            <div className="EororData">
              <Button onClick={()=>{
              props.history.push('/home')
              }} className="btn-round btn btn-info btn-lg">Back Home</Button>
            </div>
            </div>
            </div>
         </div>
       </div>
      </div>
    </>
  );
}

export default AboutPageContent;
