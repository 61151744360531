import React, { useState, useEffect, useContext, Component } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Select,
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  useEventCallback,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  Link
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { Button } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ExpiryRates = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const [isLoadingUi, setLoading] = useState(true);

  let TempZoneList = useState([]);

  const [acValue, setACValue] = useState("");

  const [activedep, setDepartment] = useState({ id: '0', name: "All" });
  const [activecommun, setCommune] = useState({ id: '0', name: "All" });
  //  console.log('active valuie',activedep);
  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);

  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [DepartmentName, setDepartmentName] = useState([]);
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currDepartmentId) {
      setDepartmentName(rowName);
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };
  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setDepartment({ id: '0', name: "All" })
        }

      },
    }
  );
  // useEffect(()=>{
  //   setDepartment({ id: '0', name: "All"});

  // },[])

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);
  // console.log(Zonelist);
  const [currZoneIdId, setZoneId] = useState("0");

  const [ZoneName, setZoneName] = useState([]);

  const handleZoneChange = (event, newValue) => {
    setDepartment(newValue)
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);
      setZoneName(rowName);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };


  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setCommune({ id: '0', name: "All" });
        }

      },
    }
  );

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);
  const [currCommuneId, setcurrCommuneId] = useState("0");
  const [DistrictName, setDistrictName] = useState([]);
  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setCommune(newValue)
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;


    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setDistrictName(rowName)

    }
  };

  //Month filter section
  const currentDate = new Date();
  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const [currMonthId, setCurrMonthlist] = useState(currentDate.getMonth() + 1);
  const [MonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  // Month Change
  const handleMonthListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currMonthId) {
      setCurrMonthlist(rowId);
      setMonthName(rowName);
    }

  };


  // Year Field  Change
  const YearList = JSON.parse(localStorage.getItem("YearList"));

  const [currYearId, setCurrYearlist] = useState(currentDate.getFullYear());
  const [YearName, setYearName] = useState([]);

  // Year Field  Change
  const handleYearListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currYearId) {
      setCurrYearlist(rowId);
      setYearName(rowName);
    }

  };


  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  // let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProdutlist] = useState(ProductGroup[0].id);
  const [ProductGroupName, setProductGroupName] = useState([]);

  // Facility Change
  const handleProductChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
    }
    //getProductData(rowId);
  };

  React.useEffect(() => {
    if (currProductGroupId == ProductGroup[0].id) {
      setProductGroupName(ProductGroup[0].name);

    }
  }, [currProductGroupId]);


  //Flevel filter section
  const FacilityLists = JSON.parse(localStorage.getItem("getFacilityListForReport"));
  let All_Facility = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const FacilityList = [All_Facility].concat(FacilityLists);

  //console.log(FacilityList);
  const [currFacilityId, setCurrFacilitylist] = useState(0);
  const [FacilityName, setFacilityName] = useState([]);

  // Facility Change
  const handleReginalListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    } if (rowId !== currFacilityId) {
      setCurrFacilitylist(rowId);
      setFacilityName(rowName);
    }

  };

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const FacilityId = localStorage.getItem("FacilityId");

  const [dataItems, setDataItems] = useState([]);


  let params = {
    menukey: menukey,
    action: "getExpiryRatesList",
    DepartmentName: DepartmentName,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    ZoneName: ZoneName,
    DistrictId: currCommuneId,
    DistrictName: DistrictName,
    YearId: currYearId,
    YearName: YearName,
    ProductGroupId: currProductGroupId,
    ProductGroupName: ProductGroupName,
    FacilityName: FacilityName,
    MonthId: currMonthId,
    MonthName: MonthName,
    lan: lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    // mode: bEsigl

  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getExpiryRates,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const [dataItems2, setDataItems2] = useState([]);

  let params2 = {
    menukey: menukey,
    action: "getExpiryRatesList2",
    DepartmentName: DepartmentName,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    ZoneName: ZoneName,
    DistrictId: currCommuneId,
    DistrictName: DistrictName,
    YearId: currYearId,
    YearName: YearName,
    ProductGroupId: currProductGroupId,
    ProductGroupName: ProductGroupName,
    FacilityName: FacilityName,
    MonthId: currMonthId,
    MonthName: MonthName,
    lan: lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    // mode: bEsigl

  };

  const { isLoading1, error1, isError1, data2 } = useQuery(
    [params2],
    api.getExpiryRates,
    {
      onSuccess: (data) => {
        setDataItems2(data);
        setLoading(false);
        //setFacilityCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currMonthId,
    Year: currYearId,
    ZoneName: ZoneName,
    BCZName: DistrictName,
    ProvinceName: DepartmentName

  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);

        console.log("rate", data.data.datalist);
        console.log("fl", data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "ProvinceName",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 200,

    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      headerFilter: true,
      headerSort: false,
      width: 200,

    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "BCZName",
      headerFilter: true,
      headerSort: false,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Total_Expired"],
      field: "AdjustQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 180,
      formatter: "Number",
      // bottomCalc: "number",
      // bottomCalcFormatter: "number",
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing_Balance_CFA"],
      field: "ClosingStockValue",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 200,
      formatter: "money",
      // bottomCalc: "number",
      // bottomCalcFormatter: "number",
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry_Rate"],
      field: "LossRate",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 200,
      formatter: "money",
      // bottomCalc: "number",
      // bottomCalcFormatter: "number",
    },
  ];
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=getExpiryRateReport" +
      "&reportType=" +
      reportType +
      "&DepartmentName=" +
      DepartmentName +
      "&ZoneName=" +
      ZoneName +
      "&DistrictName=" +
      DistrictName +
      "&Year=" +
      currYearId +
      "&ProductGroupName=" +
      ProductGroupName +
      "&FacilityName=" +
      FacilityName +
      "&MonthName=" +
      MonthName +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */


  const columns2 = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "ProvinceName",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 250,

    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      headerFilter: true,
      headerSort: false,
      width: 250,

    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "BCZName",
      headerFilter: true,
      headerSort: false,
      width: 250,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["total_expireds"],
      field: "AdjustQty",
      headerSort: false,
      headerFilter: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 200,
      formatter: "number",
      // bottomCalc: "number",
      // bottomCalcFormatter: "number",
    },

  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL2 = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction2 = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=getExpiryRateReport2" +
      "&reportType=" +
      reportType +
      "&DepartmentName=" +
      DepartmentName +
      "&ZoneName=" +
      ZoneName +
      "&DistrictName=" +
      DistrictName +
      "&Year=" +
      currYearId +
      "&ProductGroupName=" +
      ProductGroupName +
      "&FacilityName=" +
      FacilityName +
      "&MonthName=" +
      MonthName +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title1">
                  {DispensingLanguage[lan][menukey]["Expiry_Rates"]}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>



          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="DepartMentName"
                          disableClearable
                          options={DepartMentName}
                          //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}
                          //disabled={UserRole == 1 ? false : true}
                          onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Department Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Zonelist"
                          disableClearable
                          options={Zonelist}
                          value={activedep}
                          onChange={(event, newValue) => handleZoneChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["ZS Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activecommun}
                          onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Commune Name"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FacilityList"
                          disableClearable
                          options={FacilityList}
                          onChange={(event, newValue) => handleReginalListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FacilityList[FacilityList.findIndex(FacilityList => FacilityList.id == currFacilityId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>


                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="info"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}

                          onChange={(event, newValue) => handleProductChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Product Group"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="MonthList"
                          disableClearable
                          options={MonthList}
                          onChange={(event, newValue) => handleMonthListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currMonthId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Month"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="YearList"
                          disableClearable
                          options={YearList}
                          //value={acValue}
                          onChange={(event, newValue) => handleYearListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Year"]}
                              variant="standard"

                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent" style={{ backgroundColor: "#2ca8ff" }}>
              <Grid item xs={12} sm={12}>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell className="legentId" align="center" style={{ borderBottom: "none", color: "white" }} >
                          <Link>{DispensingLanguage[lan][menukey]["Reporting Rate"]}</Link>
                          <Link> {DispensingLanguage[lan][menukey]["Total"]} : {getReportingRate['Total']}%</Link>
                          <Link> {getFLvelelList[99]} : {getReportingRate['HealthFaclilities']}% </Link>
                          <Link> {getFLvelelList[3]} : {getReportingRate['DistrictWarehouse']}%</Link>
                          <Link> {getFLvelelList[2]} : {getReportingRate['RegionalWarhouse']}% </Link>
                          <Link> {getFLvelelList[1]} : {getReportingRate['CentralWarehouse']}% </Link>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>

          </Card>

          {/* new row */}
          <div className="row">

            <div className="col-md-12 mb-4">
              <Card className="sw_card">

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={350}
                  // options={{
                  //   groupBy: ["FacilityName", "GroupName", "ItemName"],
                  //   columnCalcs: "both",
                  // }}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 mb-4">
              <Card className="sw_card sw_filter_card">
                <CardContent className="sw_filterCardContent">
                  <Grid container>
                    <Grid item xs={10} sm={10} style={{marginTop: "1%"}}>
                        <div>
                          {DispensingLanguage[lan][menukey]["Expiry Rates by Product Table"]}
                        </div>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <div className="float-right sw_btn_control">
                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction2("print")}
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction2("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className="sw_card">
                <CardContent>
                  <ReactTabulator
                    columns={columns2}
                    data={dataItems2}
                    layout={"fitColumns"}
                    height={350}
                    options={{
                      groupBy: "ProductName",
                      columnCalcs: "both",
                    }}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}

        </div>
      </div>
    </>
  );
};



export default ExpiryRates;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});