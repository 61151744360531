import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";

import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import { PictureAsPdfOutlined, CloudUploadOutlined, Refresh } from "@material-ui/icons";

import moment from "moment";
import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import * as Service from "../../services/Service.js";
import Constants from "services/Constants.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";

//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

const DemanderManufacturerDashboard = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const lan = localStorage.getItem("LangCode");
  const menukey = "demander-manufacturer-dashboard";
  const { t, i18n } = useTranslation();
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const DemanderList = JSON.parse(localStorage.getItem("Demanderlist"));
  const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));

  const [NumberOfBrandsDemander, setNumberOfBrandsDemander] = useState([]);
  const [NumberOfBrandsManufacturer, setNumberOfBrandsManufacturer] = useState([])
  const [currDemanderId, setCurrDemanderId] = useState(DemanderList[0].id);
  const [toggleButtonValue, setToggleButton] = React.useState("0");
  const [ToggleButtonExprirefilter, setToggleButtonExprirefilter] = React.useState("All");
  const [firstLoad, setFirstLoad] = useState(true);
  const [radioValue, setRadioValue] = useState("0");
  const [isLoading, setLoading] = useState(false);

  //Brand Upload/Download
  const [isDataGridHideShow, setDataGridHideShow] = useState(true);
  const [isUploadForm, setUploadForm] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [UploadedFileName, setUploadedFileName] = useState();
  const [ErrorMgs, setErrorMgs] = useState();
  const [ErrorMsgShowing, setErrorMsgShowing] = useState(false);
  const [ShowErrorMsg, setShowErrorMsg] = useState();

  const handleDemanderChange = (event, newValue) => {

    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    if (rowId !== currDemanderId) {
      setCurrDemanderId(rowId);
    }
  };

  let params = {
    menukey: menukey,
    action: "getNumberOfBrandsDemander",
    DemanderId: currDemanderId,
    lan: lan,
  };

  const { data: BrandsDemander } = useQuery(
    [params],
    api.getNumberOfBrandsDemander,
    {
      onSuccess: (data) => {

        setNumberOfBrandsDemander(data[0]);
       
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  let params1 = {
    menukey: menukey,
    action: "getNumberOfBrandsManufacturer",
    DemanderId: currDemanderId,
    lan: lan,
  };

  const { data: BrandsManufacturer } = useQuery(
    [params1],
    api.getNumberOfBrandsManufacturer,
    {
      onSuccess: (data) => {

        setNumberOfBrandsManufacturer(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  let params2 = {
    menukey: menukey,
    action: "getTotalBrandsManufacturerDemander",
    DemanderId: currDemanderId,
    lan: lan,
  };
  const [BrandDemanderManufacturerTotal, setBrandDemanderManufacturerTotal] = useState([]);

  const { data: PercOfBrandsHaveGTIN } = useQuery(
    [params2],
    api.getBrandDemanderManufacturerTotal,
    {
      onSuccess: (data) => {

        setBrandDemanderManufacturerTotal(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [BrandInfoByDemander, setBrandInfoByDemander] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "getBrandInformationByDemander",
    DemanderId: currDemanderId,
    ToggleButtonValue: toggleButtonValue,
    ToggleButtonExprirefilter: ToggleButtonExprirefilter,
    radioValue:radioValue,
    lan: lan,
  };

  const { data: TopFiveFabr, refetch } = useQuery(
    [params3],
    api.getBrandInfoByDemander,
    {
      onSuccess: (data) => {
        setBrandInfoByDemander(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  let params4 = {
    menukey: menukey,
    action: "getDemanderInfo",
    DemanderId: currDemanderId,
    lan: lan,
  };

  const [DemanderManufacturerInfo, setDemanderManufacturerInfo] = useState([])

  const { data: dem_manu_info } = useQuery(
    [params4],
    api.getDemanderManufacturerInfo,
    {
      onSuccess: (data) => {
        setDemanderManufacturerInfo(data[0]);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=DemanderManufacturerwiseBrandExport" +
      "&reportType=" + reportType +
      "&menukey=" + menukey +
      "&lan=" + localStorage.getItem("LangCode") +
      "&DemanderId=" + currDemanderId +
      "&ToggleButtonValue=" + toggleButtonValue +
      "&ToggleButtonExprirefilter=" + ToggleButtonExprirefilter +
      "&radioValue=" + radioValue +
      "&TimeStamp=" + Date.now()
    );
  };
  
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Status"],
      field: "ProductStatus",
      width: 50,
      headerSort: false,
      frozen: true,
      hozAlign: "center",
      headerHozAlign: "left",
      tooltip:true,
      formatter: function (cell) {
        let value = cell.getValue();
        let prodstatusid = cell.getRow(cell).getData().ProductStatusId;

        if (prodstatusid == 1)
          return "<span style='height: 15px;width: 15px;background-color: green;border-radius: 50%;display: inline-block;'></span> "; //+ value;
        else if (prodstatusid == 2)
          return "<span style='height: 15px;width: 15px;background-color: red;border-radius: 50%;display: inline-block;'></span> "; //+ value;
        else
          return "<span style='height: 15px;width: 15px;background-color: yellow;border-radius: 50%;display: inline-block;'></span> "; //+ value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Authorization"],
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 95,
      frozen:true,
      formatter: reactFormatter(<ActionButtonForManufacAutho />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Certificate"],
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 80,
      frozen:true,
      formatter: reactFormatter(<ActionButtonForCertificate />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Brand Code"],
      field: "BrandCode",
      width: 180,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Commercial"],
      field: "CommercialName",
      width: 100,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date Expiration"],
      field: "DateExpiration",
      width: 100,
      frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: function (cell) {
        let value = cell.getValue();
        const curdate = moment().format("YYYY-MM-DD");
        const expdate = moment(value).format("YYYY-MM-DD");
        if (value != null)
          if (expdate <= curdate)
            return "<span style='color:red;'>" + moment(value).format("DD/MM/YYYY") + "</span>";
          else
            return moment(value).format("DD/MM/YYYY");
        else
          return "";
      },
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["EA GTIN"],
      field: "EAGTIN",
      width: 120,
      headerFilter: true,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["EA Qty"],
      field: "EAQty",
      width: 70,
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
 
    {
      title: DispensingLanguage[lan][menukey]["PK GTIN"],
      field: "PKGTIN",
      width: 120,
      headerFilter: true,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PK Qty"],
      field: "PKQty",
      width: 70,
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },
 
    {
      title: DispensingLanguage[lan][menukey]["CA GTIN"],
      field: "CAGTIN",
      width: 120,
      headerFilter: true,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["CA Qty"],
      field: "CAQty",
      width: 70,
      // headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
      headerSort: false,
    },
 
    {
      title: DispensingLanguage[lan][menukey]["PL GTIN"],
      field: "PLGTIN",
      width: 120,
      headerFilter: true,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PL Qty"],
      field: "PLQty",
      width: 70,
      // headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Generic Code"],//DispensingLanguage[lan][menukey]["Generic Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
      // frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic"],
      field: "ItemName",
      headerFilter: true,
      width: 250,
      // frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"], //Produit Catégorie
      field: "GroupName",
      width: 140,
      // headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      visible:false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SIGIP Code"],
      field: "SIGIPCode",
      width: 80,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Strength"],
      field: "StrengthName",
      width: 90,
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pack Size"],
      field: "PacksizeName",
      width: 80,
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Form"],
      field: "DosageFormName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["DCI"],
      field: "DciName",
      width: 150,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Demander Address"],
      field: "DemanderAddress",
      width: 165,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pays Exploitant"],
      field: "PaysExploitant",
      width: 150,
       headerFilter: true,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Intitule Fabriquant"],
    //   field: "IntituleFabriquant",
    //   width: 150,
    //   headerFilter: true,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Pays Fabricant"],
      field: "PaysFabricant",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Representant Localou Exploitant"],
      field: "RepresentantLocalouExploitant",
      width: 150,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Voie Administration"],
      field: "VoieAdministration",
      width: 150,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date Expiration DeLAMM"],
      field: "DateExpirationDeLAMM",
      width: 160,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Cond Delivrance"],
      field: "CondDelivrance",
      width: 150,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Code AMM"],
      field: "CodeAMM",
      width: 120,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PGHT Presentation"],
      field: "PGHTPresentation",
      width: 135,
       headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["PGHT Prix Public"],
      field: "PGHTPrixPublic",
      width: 135,
       headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Date De Debut"],
      field: "DateDeDebut",
      width: 120,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
     
      // headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Classe Therapeutique"],
      field: "ClasseTherapeutique",
      width: 150,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["GPC Code"],
      field: "GPCCode",
      width: 120,
       headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Code"],
      field: "SOBAPSCode",
      width: 130,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander"],
      field: "DemanderName",
      width: 100,
      //frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander Country"],
      field: "DemanderCountry",
      width: 100,
      //frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      width: 120,
      //frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Country"],
      field: "ManufacturerCountry",
      width: 120,
      //frozen: true,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: "textarea",
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["EA Child GTIN"],
      field: "EAChildGTIN",
      width: 120,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PK Child GTIN"],
      field: "PKChildGTIN",
      width: 120,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["CA Child GTIN"],
      field: "CAChildGTIN",
      width: 120,
       headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PL Child GTIN"],
      field: "PLChildGTIN",
      width: 120,
       headerFilter: true,
    },
    {
      title: "Id",
      field: "Id",
      visible: false,
      width: 80
    },/*,
    {
      title: 'Key Product', //DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      width: 120,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },*/
  ];
  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      //console.log(tmpToggleButtonValue);
      setToggleButton(tmpToggleButtonValue);
    }
  };
  const handleChangeToggleExprire = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      setToggleButtonExprirefilter(tmpToggleButtonValue);
    }
  };
  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
  };

  const UploadFileDownload = (uploadFile) => {

    let authCertPath = GlobalSettings.auth_cert;
   
    const removeStrFromURL = process.env.REACT_APP_API_URL;
    const REPORT_URL = removeStrFromURL.replace('backend/', '');
  
    // let finalUrl = REPORT_URL + "writable_files/auth_cert/"+uploadFile;
    let finalUrl = REPORT_URL + authCertPath+'/'+uploadFile;
    window.open(finalUrl);
  };

  function ActionButtonForManufacAutho(props) {
    const rowData = props.cell._cell.row.data;
    let Manufacturer_Authorization = rowData.ManufacturerAuthorization;

    if(Manufacturer_Authorization != null){
      return (
        <>
          <div className="pdf_color">
            <PictureAsPdfOutlined
              onClick={() => {
                UploadFileDownload(Manufacturer_Authorization);
              }}
            />
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }

  function ActionButtonForCertificate(props) {
    const rowData = props.cell._cell.row.data;
    let CertificateName = rowData.CertificateName;

    if(CertificateName != null){
      return (
        <>
          <div className="pdf_color">
            <PictureAsPdfOutlined
              onClick={() => {
                UploadFileDownload(CertificateName);
              }}
            />
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }

  // Start of Brand Upload/Download Code

  const BrandExcelFileDownload = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/brand_excel_file_download_server.php";

    window.open(
      finalUrl +
      "?action=DemanderManufacturerwiseBrandExport" +
      "&reportType=" + reportType +
      "&menukey=" + menukey +
      "&lan=" + localStorage.getItem("LangCode") +
      "&DemanderId=" + currDemanderId +
      "&ToggleButtonValue=" + toggleButtonValue +
      "&ToggleButtonExprirefilter=" + ToggleButtonExprirefilter +
      "&radioValue=" + radioValue +
      "&TimeStamp=" + Date.now()
    );
  };

  const BrandDataImportFunction = () => {
    setDataGridHideShow(false);
    setUploadForm(true);
  };

  const BackToListFunction = () => {
    setDataGridHideShow(true);
    setUploadForm(false);
  };

  const [uploadFormData, setFormData] = useState({
    FileName: "",
    menukey: menukey,
    lan: lan,
  });

  const APP_URL = process.env.REACT_APP_API_URL;

  const changeHandlerForFileUpload = (event) => {

      let selectedFile = event.target.files[0];

      if(selectedFile == undefined){
        return;
      }

      setErrorMsgShowing(false);
      setLoading(true);
      setbuttonDisabled(false);

      let FileName = selectedFile.name;
      let FileExt = FileName.split(".").pop();
      let Filetype = FileExt.toLowerCase();

      //The file Check extension
      if (Filetype == "xlsx" || Filetype == "xls") {
        setErrorMgs("");
        handleFileUpload(selectedFile);
      } else {
        setErrorMgs(
          DispensingLanguage[lan][menukey]["Sorry, only excel file are allowed"]
        );
        setUploadedFileName("");
        uploadFormData["FileName"] = "";
        setbuttonDisabled(true);
        setLoading(false);
      }
  };

  const handleFileUpload = (selectedFile) => {
    const formData = new FormData();

    formData.append("FileName", selectedFile);

    let finalUrl = APP_URL + "upload/upload.php";

    fetch(finalUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.msgType == "success") {
          setUploadedFileName(res.FileName);
          uploadFormData["FileName"] = res.FileName;
          setErrorMgs("");
        } else {
          setErrorMgs(res.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMgs(error);
        setLoading(false);
      });
  };

  const { mutate } = useMutation(api.saveBrandDataImport, {
    onSuccess: (data) => {
      let msg = data.message;
      if (data.success == 1) {
        swal(msg, "", "success");
        refetch();
      } else {

        setShowErrorMsg(msg);
        setErrorMsgShowing(true);
      }

      uploadFormData["FileName"] = "";
      setUploadedFileName("");
      setLoading(false);
      setbuttonDisabled(true);
    },
  });

  const handleSubmit = (data) => {
    setLoading(true);
    setbuttonDisabled(true);
    let FileName = uploadFormData.FileName;
    if (FileName != "") {
      mutate(uploadFormData);
    } else {
      setErrorMgs(DispensingLanguage[lan][menukey]["Select a excel file"]);
      setLoading(false);
    }
  }

  const handleSubmitForExcelImport = async (e) => {

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Confirm Brand Data Import"]),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleSubmit();
      }
    });
  };

  // End of Brand Upload/Download Code

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>
          <div className="d-flex justify-product mb-1">
            <Grid item xs={8} sm={8}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Demander_Manufacturer_Dashboard"]}
                </div>
              </div>
            </Grid>

            <Grid item xs={4} sm={4}>
              <FormControl className={classes.fullWidth}>
                <Autocomplete
                  autoHighlight
                  className="sw_chosen_filter"
                  id="DemanderList"
                  disableClearable
                  options={DemanderList}
                  // hidden={UserRole == 1 ? false : true}
                  hidden={ UserRole == 1 ? false : (DemanderList.length > 1 ? false :true)}
                  onChange={(event, newValue) => handleDemanderChange(event, newValue)}
                  getOptionLabel={(option) => option.name}
                  defaultValue={DemanderList[DemanderList.findIndex(DemanderList => DemanderList.id == currDemanderId)]}
                  renderOption={(option) => (
                    <Typography className="sw_fontSize">{option.name}</Typography>
                  )}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={DispensingLanguage[lan][menukey]["Demander/Manufacturer"]}
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </div>

          {/* <div className="row"> */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card mb-2">
                        <CardHeader
                          title={DispensingLanguage[lan][menukey]["Demander/Manufacturer Information"]}
                        />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={6} sm={4}>
                              <ul className="item_details_list">

                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Demander Code"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['id'] ? (DemanderManufacturerInfo['id']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Address Line 2"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['AddressLine2'] ? (DemanderManufacturerInfo['AddressLine2']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Country Name"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['CountryNameFR'] ? (DemanderManufacturerInfo['CountryNameFR']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Email"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['Email'] ? (DemanderManufacturerInfo['Email']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["IsDemander"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['bDemander'] ? (DemanderManufacturerInfo['bDemander']) : ('---')}</div>
                                </li>
                              </ul>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <ul className="item_details_list">
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Demander/Manufacturer Name"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['DemanderName'] ? (DemanderManufacturerInfo['DemanderName']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["City"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['City'] ? (DemanderManufacturerInfo['City']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Contact Name"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['ContactName'] ? (DemanderManufacturerInfo['ContactName']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["URL"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['URL'] ? (DemanderManufacturerInfo['URL']) : ('---')}</div>
                                </li>
                                <li>

                                  <div className="title">{DispensingLanguage[lan][menukey]["IsManufacturer"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['bManufacturer'] ? (DemanderManufacturerInfo['bManufacturer']) : ('---')}</div>
                                </li>
                              </ul>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                              <ul className="item_details_list">
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Address Line 1"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['AddressLine1'] ? (DemanderManufacturerInfo['AddressLine1']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Zip"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['Zip'] ? (DemanderManufacturerInfo['Zip']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Contact#"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['Contact'] ? (DemanderManufacturerInfo['Contact']) : ('---')}</div>
                                </li>
                                <li>
                                  <div className="title">{DispensingLanguage[lan][menukey]["Demander/ManufacturerUser"]}</div>
                                  <div className="desc">{DemanderManufacturerInfo['UserName'] ? (DemanderManufacturerInfo['UserName']) : ('---')}</div>
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </div> */}

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className="float-left sw_btn_control">
                      <ToggleButtonGroup
                        className="sw_ToggleButtonGroup"
                        // color="primary"
                        value={toggleButtonValue}
                        exclusive
                        onChange={handleChangeToggle}

                      >
                        <ToggleButton value="0">
                          <div className="col-md-12">
                            <div>
                              <span className="text-xlg">{BrandDemanderManufacturerTotal['TotaldemanderManufacturer']}</span>
                              <br></br>
                              <span className="text-bg">{DispensingLanguage[lan][menukey]["Total Number of Brands"]}</span>
                            </div>
                          </div>
                        </ToggleButton>

                        <ToggleButton value="1">
                          <div className="col-md-12">
                            <div>
                              <span className="text-xlg">{NumberOfBrandsDemander['TotalBrand']}</span>
                              <br></br>
                              <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Brands by Demander"]}</span>
                            </div>
                          </div>
                        </ToggleButton>
                        <ToggleButton value="2">
                          <div className="col-md-12">
                            <div>
                              <span className="text-xlg">{NumberOfBrandsManufacturer['TotalBrand']}</span>
                              <br></br>
                              <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Brands by Manufacturer"]}</span>
                            </div>
                          </div>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* new row */}
          {/* <div className="row">
            <div className="col-md-12 mb-12">
              <Card className="sw_card mb-2">
                <CardContent>
                  <div className="row mb-3">
                  <div className="col-md-4">
                      <div className="stat-cell bgWarning">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{BrandDemanderManufacturerTotal['TotaldemanderManufacturer']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Total Number of Brands"]}</span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="stat-cell bgPrimary">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{NumberOfBrandsDemander['TotalBrand']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Brands by Demander"]}</span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="stat-cell bgInfo">
                        <i className="fa fa-file bg-icon"></i>
                        <span className="text-xlg">{NumberOfBrandsManufacturer['TotalBrand']}</span>
                        <br></br>
                        <span className="text-bg">{DispensingLanguage[lan][menukey]["Number of Brands by Manufacturer"]}</span>
                      
                      </div>
                    </div>

                  </div>

                </CardContent>
              </Card>

            </div>
          </div> */}
          {/* end of row */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Brand Information By Demander"]}
                />

                {/* Start Grid */}
                { isDataGridHideShow && 
                <CardContent>
                  <Grid container>

                    <Grid item xs={4} sm={4}>
                      <FormControl component="fieldset" className="mt-3 ml-3">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="row-radio-buttons-group"
                          defaultValue="0"
                        >

                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={DispensingLanguage[lan][menukey]["All"]}
                            onClick={() => changeRadio(0)}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={DispensingLanguage[lan][menukey]["Active"]}
                            onClick={() => changeRadio(1)}
                          />

                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={DispensingLanguage[lan][menukey]["Deactive"]}
                            onClick={() => changeRadio(2)}
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label={DispensingLanguage[lan][menukey]["Not Registered"]}
                            onClick={() => changeRadio(3)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <ToggleButtonGroup
                        className="sw_ToggleButtonGroup"
                        color="primary"
                        value={ToggleButtonExprirefilter}
                        exclusive
                        onChange={handleChangeToggleExprire}

                      >
                        <ToggleButton value="All">
                          {DispensingLanguage[lan][menukey]['All']}
                        </ToggleButton>
                        <ToggleButton value="3Month">
                          {DispensingLanguage[lan][menukey]['3 M']}
                        </ToggleButton>
                        <ToggleButton
                          value="6Month"
                        >
                          {DispensingLanguage[lan][menukey]['6 M']}
                        </ToggleButton>
                        <ToggleButton value="1Year">
                          {DispensingLanguage[lan][menukey]['1 Y']}
                        </ToggleButton>
                        <ToggleButton value="GreaterThan1Year">
                          {DispensingLanguage[lan][menukey]['> 1 Y']}
                        </ToggleButton>
                        <ToggleButton value="Expired">
                          {DispensingLanguage[lan][menukey]['Expired']}
                        </ToggleButton>
                        <ToggleButton value="NoExpriry">
                          {DispensingLanguage[lan][menukey]['No Expiry']}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <div className="float-right sw_btn_control">
                        <Button
                          color="info"
                          className="mr-2 sw_icons sw_group_btn"
                          onClick={() => BrandExcelFileDownload("excel")}
                        >
                          <i className="fa fa-download"></i><span className="sw_text">{DispensingLanguage[lan][menukey]['Download Template']}</span>
                        </Button>
                        <Button
                          color="info"
                          className="mr-2 sw_icons sw_group_btn"
                          onClick={() => BrandDataImportFunction()}
                        >
                          <i className="fa fa-upload"></i><span className="sw_text">{DispensingLanguage[lan][menukey]['Upload Template']}</span>
                        </Button>
                        <Button
                          color="info"
                          className="mr-2 sw_icons sw_group_btn"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                 
                  <ReactTabulator
                    columns={columns}
                    data={BrandInfoByDemander}
                    height={400}
                    layout={"fitColumns"}
                  />
                
                </CardContent>
                }

                {/* end Grid */}

                {/* Start Upload Code */}
                
                { isUploadForm && 
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className="float-right sw_btn_control">
                        <Button
                          style={{paddingTop:8, paddingBottom: 8, paddingLeft:15, paddingRight:15}}
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => BackToListFunction()}
                        >
                          {DispensingLanguage[lan][menukey]['Back to List']}
                        </Button>
                      </div>
                    </Grid>

                    <Grid item xs={2} sm={2}></Grid>
                    <Grid item xs={8} sm={8}>
                      {/* ====Upload Box=== */}
                      <div className="tp-upload-box marginTopBottom">
                        <div className="tp-header">
                            <Grid item xs={12} sm={12}>
                              {DispensingLanguage[lan][menukey]["Upload Template"]}
                            </Grid>
                        </div>
                        <div className="tp-body">
                          <div className="tp-file-upload">
                            <input
                              type="text"
                              name="selectFile"
                              id="selectFile"
                              value={UploadedFileName}
                              placeholder={
                                DispensingLanguage[lan][menukey]["Choose a file"]
                              }
                              className="tp-form-control"
                              readOnly={true}
                            />
                            <div className="file_browse_box">
                              <input
                                type="file"
                                name="FileName"
                                id="FileName"
                                className="tp-upload"
                                onChange={changeHandlerForFileUpload}
                              />
                              <label for="FileName" className="file_browse_icon">
                                <span className="icon-upload">
                                  <CloudUploadOutlined />
                                </span>
                                {DispensingLanguage[lan][menukey]["Browse"]}
                              </label>
                            </div>
                          </div>
                          <div className="selectFile text-left">
                            <span className="red">{ErrorMgs}</span>
                          </div>
                          <div className="SuccessMgs display-none"></div>
                        </div>
                        <div className="tp-footer text-right sw_relative">
                          {isLoading && <LoaderForGlobal />}
                        
                          <Button
                            disabled={buttonDisabled}
                            className="mr-3 pull-right"
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitForExcelImport()}
                          >
                            {DispensingLanguage[lan][menukey]["IMPORT DATA"]}
                          </Button>
                        </div>
                      </div>
                      {/* ====Upload Box=== */}
                    </Grid>
                    <Grid item xs={2} sm={2}></Grid>

                    {ErrorMsgShowing && 
                    <Grid item xs={12} sm={12}>
                      <div className="msg-error">
                        <div className="sw_error_label">{DispensingLanguage[lan][menukey]["Error"]}:</div>
                        <textarea className="error-msg-box" rows="5" value={ShowErrorMsg} readOnly={true}></textarea>
                      </div>
                    </Grid>
                    }

                  </Grid>
                </CardContent>
                }

                {/* end Upload Code */}

              </Card>
            </div>
          </div>
          {/* end of row */}
        </div>
      </div >
    </>
  );
};

export default DemanderManufacturerDashboard;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});