import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField, 
  Button,
  
  Card,
  CardContent,
  FormHelperText
} from "@material-ui/core";



//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';


import "../common.css";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { cellFocusEditor, dateEditor } from "../../../services/Common";

let isDirty=false; 
let deletedDatas=[];
let transactionItemID =0;

const SnigsItems = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "snigs-logistics-report-a7";
const FacilityId=localStorage.getItem('FacilityId');

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;



  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef =null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  //console.log("Set r batch");
  
  const [open, setOpen] = useState(false); 
  const [openOrder, setOpenOrder] = useState(false);
  const [openWarehouseInvoice, setWarehouseInvoice] = useState(false);


  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false); 

  
  // Store
  const StoreId = localStorage.getItem("StoreId");
  const [timeStamp, setTimeStamp] = useState('');

 /* =====Start of Excel Export Code==== */
 const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

 const PrintPDFExcelExportFunction = (reportType) => {
   let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

//console.log('Data ID',CFMStockId);
   window.open(
     finalUrl +
       "?action=snigsitemsprintExport" +
       "&reportType=" +
       reportType +
      "&CFMStockId=" + props.formData.CFMStockId + 
      //  "&FacilityId=" + props.formData.FacilityId +
        "&MonthId=" + props.formData.MonthId +
        "&YearId=" + props.formData.YearId +
      //  "&CountryId=" + props.formData.CountryId + 
      //  "&StatusId=" + props.formData.StatusId + 
      //  "&ItemGroupId=" + props.formData.ItemGroupId + 
        "&FacilityName=" + props.formData.FacilityName + 
       "&MonthName=" + props.formData.MonthName + 
       "&StatusName=" + props.formData.StatusName + 
      // "&FLevelId=" + FLevelId+ 
      // "&FTypeId=" + FTypeId + 
       "&menukey=" +
       menukey +
       "&lan=" +
       localStorage.getItem("LangCode")
       + "&TimeStamp=" + Date.now()
   );
 };
 /* =====End of Excel Export Code==== */

  
  const handleClose = (modalname) => {
    switch (modalname) {

      case "Products":
        setOpen(false);
        break;

      case "ProductsOpen":
        setOpen(true);
        break;

    

      default:
        break;
    }
  }
  
  

  


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false }, 
    { title: "id", field: "id", visible: false }, 
    {
      title: "GroupName",
      field: "GroupName",
      visible: false,
    },

   

    { title: DispensingLanguage[lan][menukey]['SL'],  width: 60, formatter: "rownum", hozAlign: "center", 
    headerHozAlign:"center", headerSort:false  },

    // { 
    //   title: DispensingLanguage[lan][menukey]['Actions'], 
    //   field: 'custom', 
    //   headerSort:false, 
    //   headerHozAlign:"center", 
    //   hozAlign: 'center',
    //   width: 65,
    //  formatter: reactFormatter(<ActionButton />) } ,

     {
      title: "dirty",
      field: "dirty",
      visible: false,
    },

   

    { title: DispensingLanguage[lan][menukey]['Designation'], field: "ItemName",headerFilter:true,width: 240},
    { title: DispensingLanguage[lan][menukey]['Opening Stock'], field: "OpStock_A", width: 90 ,hozAlign: "right",  cssClass: "tabluator-column-os",},
    { title: DispensingLanguage[lan][menukey]['Receive Qty'], field: 'ReceiveQty',width:90,hozAlign: "right",},

    { title: DispensingLanguage[lan][menukey]['Dispense Qty'], field: "DispenseQty", width: 95, hozAlign: "right", headerHozAlign:"right"},
    { title: DispensingLanguage[lan][menukey]['Total Sorties hors Dispensation'], field: "AdjustQty", width: 125, hozAlign: "right", headerHozAlign:"right"},
   
    { title: DispensingLanguage[lan][menukey]['Expired'], field: "TotalDispenseQty",  width: 100, hozAlign: "right", headerHozAlign:"right",
      
    },//
    { title: DispensingLanguage[lan][menukey]['Closing Balance'], field: "ClStock_A", width: 90, hozAlign: "right",
      
    
    },
    { title: DispensingLanguage[lan][menukey]['Risk of Expiry'], field: "ExpiryQty" , width: 125,hozAlign: "right",
     
      
    },

    
    { 
      title: DispensingLanguage[lan][menukey]['Expiry Date'], field: "ExpiryDate", width: 110,         
   },

   { title: DispensingLanguage[lan][menukey]['Stockout Days'], field: "StockoutDays", width: 90,hozAlign: "right"},

  { 
    title: DispensingLanguage[lan][menukey]['AMC'], field: "AMC", width: 80, hozAlign: "right",
    cssClass: "tabluator-column-amc",   
  },
  { title: DispensingLanguage[lan][menukey]['MOS'], field: "MOS", width: 80,hozAlign: "right",formatter:"html",

  // formatter: function (cell, formatterParams) {

  //       const currow = cell.getRow();
  //       const rowdata = currow.getData();

  //   let value = cell.getValue();
  //   if (value <= 0) {
  //     cell.getElement().style.backgroundColor = "#FF0000";
  //   } else {
  //     cell.getElement().style.backgroundColor = "transparent";
  //   }
    
  // },


},
  {title: DispensingLanguage[lan][menukey]['Observations'], field: "Remarks", width: 130,},

    
  ];
   
  

   

  
  useEffect(() => {
    if (ReceiveDetailsRef == null) return; 
    if(props.bFirst){
      setManyTableData(props.ManyTableData); 
    }
    
   
    
     
}, [props.ManyTableData]); //




useEffect(() => {
  if (ReceiveDetailsRef.table != null && timeStamp != '') { 
    ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
    ReceiveDetailsRef.table.selectRow(timeStamp);


  }
}, [newBatchRowAdded]); 







const backToList = () => {
  if(isDirty){

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you want to back to list without saving data"]),
      icon: "warning",
      // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      // t(DispensingLanguage[lan][menukey]["Yes"])],
      // dangerMode: true,
      buttons: {
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "sw_confirm_btn",
          closeModal: true,
        },
        
      },
    }).then((willAction) => {
      if (willAction) { 

        props.history.push("/snigs_logistics_report_a7");

      } 
    });

  }else props.history.push("/snigs_logistics_report_a7")
  
};

  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>

        <div className="sw_page_heading">
            
            
            <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Recieve_from_warehouse_create_edit']}</div>
            
            <div className="float-right sw_btn_control">

            {
            props.addProductForm ?
              <Grid item xs={12} className="mt-4 text-center">
               <div className="float-right sw_btn_control">

               <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/snigs_logistics_report_a7")}
                >
                  {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                
                
               </div>
              </Grid>
              :
              <Grid item xs={12} className="mt-2 text-center">
                
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  //onClick={() => props.history.push("/receipts")}
                  onClick={() => backToList()}
                >
                 {DispensingLanguage[lan][menukey]['Back_to_List']}
                </Button>

                <Button
                 hidden={props.formData.StatusId==1?(UserRole==10?true:(UserRole==2?true:false)):true} 
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleSubmit()}
                   
                >
                 {DispensingLanguage[lan][menukey]['Submit']}
                </Button>


                <Button 
                  hidden={props.formData.StatusId==2?(UserRole==11?true:(UserRole==2?true:false)):true}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleUnsubmit()}
                >
                  {DispensingLanguage[lan][menukey]['Unsubmit']}
                </Button>
                
                <Button 
                 // disabled={props.bStockUpdate}
                  hidden={props.formData.StatusId==2?(UserRole==11?true:(UserRole==2?true:false)):true} 
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handlePublish()}
                >
                  {DispensingLanguage[lan][menukey]['Publish']}
                </Button>

                <Button  
                  hidden={props.formData.StatusId==5?(UserRole==10?true:(UserRole==11?true:false)):true}                
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={()=>props.handleUnpublish()}
                >
                  {DispensingLanguage[lan][menukey]['Unpublish']}
                </Button>
                

                <Button
                variant="contained"
                color="primary"
                className="mr-2 sw_icons"
                onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                <i className="far fa-file-excel"></i>
                </Button>


              </Grid>
          }

            </div>
         </div>   
          
          
        </Grid>
       

      </div>

      
      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}
 

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>

                <Grid item xs={3} sm={3}>



                <ul className="item_details_list">
                    <li>
                      <div className="title">{DispensingLanguage[lan][menukey]["Facility"]}</div>
                      <div className="desc">{ props.formData.FacilityName ? (props.formData.FacilityName) : ('') }</div>
                    </li>
                </ul>



                        
                  
                  
                </Grid>


                

                  <Grid item xs={3} sm={3}>
                        <ul className="item_details_list">
                          <li>
                            <div className="title">{DispensingLanguage[lan][menukey]["Month"]}</div>
                            <div className="desc">{ props.formData.MonthName ? (props.formData.MonthName) : ('') }</div>
                          </li>
                      </ul>

                    </Grid>
                     

                    <Grid item xs={3} sm={3}>


                     <ul className="item_details_list">
                          <li>
                            <div className="title">{DispensingLanguage[lan][menukey]["Year"]}</div>
                            <div className="desc">{ props.formData.YearId ? (props.formData.YearId) : ('') }</div>
                          </li>
                      </ul>

                    
                    
                    
                    </Grid>


                    <Grid item xs={3} sm={3}>


                    <ul className="item_details_list">
                          <li>
                            <div className="title">{DispensingLanguage[lan][menukey]["Status"]}</div>
                            { props.formData.StatusId==1 ? 
                            <div className="desc"><span className="redColor"> { props.formData.StatusName ? (props.formData.StatusName) : ('') }</span></div>
                            :
                            <div className="desc"><span className="greenColor">{ props.formData.StatusName ? (props.formData.StatusName) : ('') }</span></div>
                              }
                          </li>
                      </ul>


                      
                     
              
                    </Grid>


                    <Grid item xs={12} sm={12}>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mylegend_area">
                        {props.LegendData.map((item, index) => {
                          return (
                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: item.ColorCode }}
                              ></div>
                              <div className="mylegend_label">{item.MosTypeName}</div>
                              <div className="mylegend_val">
                              {DispensingLanguage[lan][menukey]["MOS"]}: {item.MosLabel}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div> 

                    </Grid>




                   



                    <Grid item xs={3} sm={3}>
                      
                      <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />

                        <TextField 
                        id="FacilityId"
                        name="FacilityId"
                        label="FacilityId"  
                        value={props.formData.FacilityId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />

                      <TextField 
                        id="pMonth"
                        name="pMonth"
                        label="pMonth"  
                        value={props.formData.MonthId}
                        fullWidth
                        hidden
                        autoComplete="family-name" 
                      />
                      


                    </Grid>  
            
                  </Grid>
                </Grid>

                
                <Grid container spacing={1}>
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          <Grid container className="mb-2">
                           
                           
                          </Grid> 
                           <ReactTabulator
                                 
                                  ref={(r) => (ReceiveDetailsRef = r)}
                                  columns={ReceiveDetailsColumns}
                                  data={manyTableData}
                                  height="350px"
                                  options={{
                                    groupBy: "GroupName",
                                    columnCalcs: "both",
                                  }}
                                  layout={"fitColumns"}
                                />
                         
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* new row */} 
                  </Grid>







                  <Grid container spacing={1} className="mt-2">
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <Card className="sw_card">
                        <CardContent>
                          

                          <div className="sw_page_heading">
            
            
                      <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['SVDL Report Feedback']}
                      </div>
                      <br></br>
                      <br></br>

                      <Grid item xs={12} sm={12}>

                      <div class="qtitle-ctm">{DispensingLanguage[lan][menukey]['Previous Feedback']}</div>
                      
<aside class="widget widget_mfn_recent_comments" id="widget_mfn_recent_comments-2">
<div className="Recent_comments activity_area">
	<ul id="ChainDataId">


  {props.RemarksChainData.map((item, index) => {
                          return (
    
  <li>
    <div className="ActivityList">
	
	<div className="dateClass"><i className="fa fa-clock"></i> {item.EntryTime}</div>
	<div className="userClass"><i className="fa fa-user"></i> {item.name}</div>
  <br></br>
	<p>{item.Comments}</p>
	</div>
  </li>

  );
  })}

  </ul></div>
  </aside>
  
  
                            
                         

                      </Grid>
                      
                   
                    <Grid item xs={6} sm={6}>
                      <TextField
                         
                        error={props.errorObject2.Feedback}
                        helperText={props.errorObject2.Feedback}
                        required
                        rows={3}
                        id="Feedback"
                        name="Feedback"
                        label={DispensingLanguage[lan][menukey]['Feedback']} 
                        // value={formData.FirstName || "Mahmudul"}
                        value={props.formData2.Feedback}
                        fullWidth
                        multiline   
                       
                        autoComplete="family-name"
                        onChange={(e) => props.handleChange2(e)}
                       
                      />
                    </Grid>



                    


        <Grid item xs={12} className="mt-4 text-center">
        <div className="float-left sw_btn_control">


                <Button
                
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleFeedbackSubmit()}
                  
                >
                {DispensingLanguage[lan][menukey]['Submit']}
                </Button>


                

       
       
          </div>
          </Grid>
    
                    
                        
                        </div>
                           
                           
                         
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>








              </Grid>

 

            {/* </CardContent>
          </Card> */}
        </Grid>


       {/* Action buttons */}
         
      </Grid>
    </div>
  </div>
  );
};

export default SnigsItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  columnop: {
    backgroundColor: "red",
  },
  
  // redtextcolor: {
  //   color: "red", 
  //   fontWeight:600,
  //   textAlign: "center",
  //   lineHeight: "0px",
  //   paddingTop: "15px",
  // }
});
