import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { 
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const Demander = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "demander";

  const classes = useStyles();
  const { useState } = React;
  const [isLoading, setLoading] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  const [checkeddemander, setCheckeddemander] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [CompletedDemander, setCompletedDemander] = useState(true);
  const [CompletedManufacturer, setCompleteManufacturer] = useState(true);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();


  let params = {
    menukey: menukey,
    action: "getDataList",
    checkeddemander: checkeddemander,
    checkedManufacturer:checked,
    lan : lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { refetch } = useQuery(
    [params],
    api.getDemanders,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { mutate } = useMutation(api.deleteDemander, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

         api.getAllDropdown("Demanderlist").then((response) => {
          localStorage.setItem(
            "Demanderlist",
           JSON.stringify(response.datalist.Demanderlist)
           );
         });

        refetch();
        queryClient.getQueriesData("demander");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{   
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
    },
  });



  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=DemanderExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode")+
        "&checkeddemander=" +
        checkeddemander +
        "&checkedManufacturer=" +
        checked +
       "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
 
  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editDemander(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 60,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander User"],
      field: "DemanderUserName",
      width: 115,
      formatter:"textarea"
    },
    {
      title: t(DispensingLanguage[lan][menukey]["IsDemander"]),
      field: "bDemander",
      hozAlign: "center",
      width: 110,
      formatter: "tickCross",
      headerHozAlign: "center",
    },
    {
      title: t(DispensingLanguage[lan][menukey]["IsManufacturer"]),
      field: "bManufacturer",
      hozAlign: "center",
      width: 115,
      formatter: "tickCross",
      headerHozAlign: "center",
    },
    
   
    {
      title: DispensingLanguage[lan][menukey]["Demander Code"],
      width: 100,
      // headerFilter:true,
      field: "id",
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander Name"],
      headerFilter:true,
      field: "DemanderName",
      width: 300,
      
      formatter:"textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]["Address Line 1"],
      field: "AddressLine1",
      width: 200,
      formatter:"textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]["Address Line 2"],
      field: "AddressLine2",
      width: 100,
      formatter:"textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]["City"],
      field: "City",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Zip"],
      field: "Zip",
      width: 80,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country Name"],
      field: "CountryNameFR",
      width: 100,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["Contact Name"],
      field: "ContactName",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Contact#"],
      field: "Contact",
      width: 100,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Phone"],
    //   field: "Phone",
    //   width: 100,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Email"],
      field: "Email",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["URL"],
      field: "URL",
      width: 100,
    },
    
    // {
    //   title: DispensingLanguage[lan][menukey]["Manufacturer User"],
    //   headerFilter:true,
    //   field: "ManufacturerUserName",
    //   width: 150,
    // },
    
  ];

  const editDemander = (data) => {
    let route = `demander/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteDemander = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Demander!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editDemander(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteDemander(rowData);
          }}
        />
      </>
    );
  }
  const handleDemanderChange = (event) => {
    setCheckeddemander(event.target.checked);
    setLoading(true);
    setCompletedDemander(event.target.checked);
  };

  const handleManufacturerChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setCompleteManufacturer(event.target.checked);
  };

  return (
    <>
      <div className={classes.demanderPageTitle}>
       
      <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Demander"])}
              </div>
            </div>
          </Grid>
        </div>
       

 {/* start of filter */}
 <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
          
            <Grid item xs={1} sm={1}>
              <FormControlLabel 
                control={
                  <Checkbox
                    color="primary"
                    name="bDemanderFilter"
                    checked={checkeddemander} 
                    onChange={handleDemanderChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["IsDemander"]}
              />
              </Grid>
              <Grid item xs={3} sm={3}>
              <FormControlLabel 
                control={
                  <Checkbox
                    color="primary"
                    name="bManufacturerFilter"
                    checked={checked} 
                    onChange={handleManufacturerChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["IsManufacturer"]}
              />
              </Grid>

            


              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/demander/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add Demander"]}
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}


        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)} 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Demander;

const useStyles = makeStyles({
  demanderPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
