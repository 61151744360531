import React, { useState, useEffect, useContext, Component, useRef } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import {
FormControl, Grid, Card, CardHeader, CardContent, Typography, TextField,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


import {
  Launch

} from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

//Import Facility Reporting Rate
import FacilityReportingRate from "services/FacilityReportingRate";

const NationalStockStatusReport = (props) => {

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);


  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));


  const GetStatus_List = JSON.parse(localStorage.getItem("getstatusList"));

  // const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_Status_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const GetStatusList = [All_Status_Name].concat(GetStatus_List);

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));


  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currDepartmentName, setDepartmentName] = useState(DepartMentName[0].name);
  const [currZoneIdId, setZoneId] = useState(Zonelist[0].id);
  const [currZonename, setZonename] = useState(Zonelist[0].name);
  const [currCommuneId, setcurrCommuneId] = useState(Communelist[0].id);
  const [currCommuneame, setCommunename] = useState(Communelist[0].name);
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);



  const [currGetStatusListId, setGetstatusListId] = useState(GetStatusList[0].id);
  const [currGetStatusname, setGetStatusListname] = useState(GetStatusList[0].name);
  // const [MosTypelist, setMosTypelist] = useState([]);

  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  const [currYearId, setYearId] = useState(currentDate.getFullYear());

  const [acZoneValue, setACZoneValue] = useState({ id: Zonelist[0].id, name: Zonelist[0].name });

  const [acCommnueValue, setACCommnueValue] = useState({ id: Communelist[0].id, name: Communelist[0].name });




  const [isLoadingUi, setLoading] = useState(true);

  exporting(Highcharts);

  // Department Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
      setDepartmentName(rowName);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setACZoneValue({ id: Zonelist[0].id, name: Zonelist[0].name });
          setZoneId(Zonelist[0].id);
          setZonename(Zonelist[0].name);
          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );


  // Zone Change
  const handleZoneChange = (event, newValue) => {
    setACZoneValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneIdId) {
      setZoneId(rowId);
      setZonename(rowName);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setACCommnueValue({ id: Communelist[0].id, name: Communelist[0].name });
          setcurrCommuneId(Communelist[0].id);
          setCommunename(Communelist[0].name);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setACCommnueValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;

    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setCommunename(rowName);

    }
  };


  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };

  // GetstatusList Change
  const handlegetstatusListChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currGetStatusListId) {
      setGetstatusListId(rowId);
      setGetStatusListname(rowName);
    }
  };


  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);


    }
  };


  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);



    }
  };



  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProvinceName: currDepartmentName,
    getStatusListId: currGetStatusListId,
    getStatusname: currGetStatusname,

  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);

        // console.log("rate", data.data.datalist);
        // console.log("fl", data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [dataItems, setDataItems] = useState([]);
//console.log(dataItems);
  let params = {
    menukey: menukey,
    action: "getSvdlFacilityReportingStatusList",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneIdId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    getStatusListId: currGetStatusListId,
    getStatusname: currGetStatusname,

  };



  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getSvdlFacilityReportingStatus,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "RegionName",
      headerSort: false,
      headerFilter: true,
      width: 100,
    },


    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "DistrictName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      // headerFilter: true,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      // headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["DHIS2 Last Updated User/Date"],
      field: "LastUpdatedDateDHIS2",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Submit"],
      field: "LastSubmittedDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Publish"],
      field: "PublishedDt",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 100,
    },


  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=svdlFacilityReportingStatus" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneIdId +
      "&DistrictId=" +
      currCommuneId +
      "&ItemGroupId=" +
       currProductGroupId +
       "&MonthId=" +
       currmonthId +
       "&Year=" +
       currYearId +
       "&ProvinceName=" +
       currDepartmentName +
       "&ZoneName=" +
       currZonename +
       "&BCZName=" +
       currCommuneame +
       "&ProductGroup=" +
       currItemGroupname +
       "&MonthName=" +
       currMonthName +
       "&getStatusListId=" +
       currGetStatusListId +
       "&getStatusname=" +
       currGetStatusname +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
        <div className={classes.dispenserPageTitle}>

          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["SVDL_Facility_Reporting_Status"])}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      value={acZoneValue}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneIdId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={acCommnueValue}
                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="GetStatusList"
                      disableClearable
                      options={GetStatusList}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handlegetstatusListChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={GetStatusList[GetStatusList.findIndex(GetStatusList => GetStatusList.id == currGetStatusListId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Status"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>


            </CardContent>
          </Card>

          {/* Facility Reporting Rate */}
          <FacilityReportingRate 
            ReportingLabelList={getFLvelelList} 
            ReportingValueList={getReportingRate}
            lan={lan}
            menukey={menukey}
          />
          {/* Facility Reporting Rate */}

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["SVDL_Facility_Reporting_Status"])}

                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                      <ReactTabulator
                        columns={columns}
                        data={dataItems}
                        layout={"fitColumns"}
                        height="600"
                        options={{
                          groupBy: "FLevelName",
                          columnCalcs: "both",
                        }}
                      />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NationalStockStatusReport;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "745px",
  },
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
