import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

import { Typography, Grid, FormControl, InputLabel, Select,  MenuItem, Card, CardContent,Checkbox, TextField,FormControlLabel} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import * as Service from "../../../services/Service.js";

import Autocomplete from '@material-ui/lab/Autocomplete';

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { ViewList, CloudUploadOutlined} from "@material-ui/icons";


//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const CertificateManufacturer = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "certificate-manufacturer-authorization";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dataItems, setDataItems] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [Strength, setStrength] = React.useState(true);
  const [StrengthList, setStrengthList] = useState([]);
  const [currStrengthId, setStrengthId] = useState(0);

  const [Packsize, setPacksize] = React.useState(true);
  const [PacksizeList, setPacksizeList] = useState([]);
  const [currPacksizeId, setPacksizeId] = useState(0);

  const [DosageForm, setDosageForm] = React.useState(true);
  const [DosageFormList, setDosageFormList] = useState([]);
  const [currDosageFormId , setDosageFormId ] = useState(0);

  const [Dci, setDci] = React.useState(true);
  const [DciList, setDciList] = useState([]);
  const [currDciId , setDciId ] = useState(0);

  const [Demander, setDemander] = React.useState(true);
  const [DemanderList, setDemanderList] = useState([]);
  const [currDemanderId , setDemanderId ] = useState(0);

  const [ProductStatus, setProductStatus] = React.useState(true);
  const [ProductStatusList, setProductStatusList] = useState([]);
  const [currProductStatusId , setProductStatusId ] = useState(0);


  const [BrandCount,setBrandCount] = useState(0);
  const [ProductTableData, setProductTableData] = React.useState(true);
  const [bBrandsWithGTIN, setbBrandsWithGTIN] = useState(false);

  let params = {
    menukey: menukey,
    action: "getProductList",
    StrengthId: currStrengthId,
    PacksizeId: currPacksizeId,
    DosageFormId:currDosageFormId,
    DciId:currDciId,
    DemanderId:currDemanderId,
    ProductStatusId:currProductStatusId,
    BrandsWithGTIN:bBrandsWithGTIN,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getProductsListBrand,
    {
      onSuccess: (data) => { 
        setDataItems(data.datalist);
        setLoading(false);
        setBrandCount(data.datalist.length);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const getProductsData = () => {

      Service.default
          .postApi("source/api/api_pages/productListbrand.php", params)
          .then((res) => {
            setDataItems(res.datalist);
            setLoading(false);
            setBrandCount(res.datalist.length);
          })
          .catch((err) => {});
  }

  if(ProductTableData){
    setProductTableData(false);
    refetch();
   // getProductsData();
  }

  if(Strength){
    setStrength(false);
    let ParamList={action:"getStrengthList"}
    Service.default
        .postApi("source/combo_generic.php",ParamList)
        .then((res) => {
          setStrengthList(res.datalist);
        })
        .catch((err) => {});
  }

  const handleStrengthChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }
     params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: rowId,
      PacksizeId: currPacksizeId,
      DosageFormId:currDosageFormId,
      DciId:currDciId,
      DemanderId:currDemanderId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:bBrandsWithGTIN,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setStrengthId(rowId);
    refetch();
  };

  if(Packsize){
    setPacksize(false);
    let ParamList={action:"getPacksizeList"}
    Service.default
        .postApi("source/combo_generic.php", ParamList)
        .then((res) => {
          setPacksizeList(res.datalist);
        })
        .catch((err) => {});
  }

  const handlePacksizeChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: rowId,
      DosageFormId:currDosageFormId,
      DciId:currDciId,
      DemanderId:currDemanderId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:bBrandsWithGTIN,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setPacksizeId(rowId);
    refetch();
  };

  if(DosageForm){
    setDosageForm(false);
    let ParamList={action:"getDosageFormList"}
    Service.default
        .postApi("source/combo_generic.php", ParamList)
        .then((res) => {
          setDosageFormList(res.datalist);
        })
        .catch((err) => {});
  }

  const handleDosageFormChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId:rowId,
      DciId:currDciId,
      DemanderId:currDemanderId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:bBrandsWithGTIN,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDosageFormId(rowId);
    refetch();
  };

  if(Dci){
    setDci(false);
    let ParamList={action:"getDciList"}
    Service.default
        .postApi("source/combo_generic.php", ParamList)
        .then((res) => {
          setDciList(res.datalist);
        })
        .catch((err) => {});
  }

  const handleDciChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId:currDosageFormId,
      DemanderId:currDemanderId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:bBrandsWithGTIN,
      DciId:rowId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDciId(rowId);
    refetch();
  };

  if(Demander){
    setDemander(false);
    let ParamList={action:"getDemanderList"}
    Service.default
        .postApi("source/combo_generic.php", ParamList)
        .then((res) => {
          setDemanderList(res.datalist);
        })
        .catch((err) => {});
  }

  const handleDemanderChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId:currDosageFormId,
      DciId:currDciId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:bBrandsWithGTIN,
      DemanderId:rowId,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setDemanderId(rowId);
    refetch();
  };


  if(ProductStatus){
    setProductStatus(false);
    let ParamList={action:"getProductStatusList"}
    Service.default
        .postApi("source/combo_generic.php", ParamList)
        .then((res) => {
          setProductStatusList(res.datalist);
        })
        .catch((err) => {});
  }

  const handleProductStatusChange = (event, newValue) => {
    let rowId = 0;
    if(newValue == null){
      rowId = 0;
    }else{
      rowId = newValue.id;
    }

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId:currDosageFormId,
      DciId:currDciId,
      DemanderId:currDemanderId,
      ProductStatusId:rowId,
      BrandsWithGTIN:bBrandsWithGTIN,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setProductStatusId(rowId);
    refetch();
  };

  const [checked, setChecked] = React.useState(true);
  const handleBrandGTINChange = (event) => {

    params = {
      menukey: menukey,
      action: "getProductList",
      StrengthId: currStrengthId,
      PacksizeId: currPacksizeId,
      DosageFormId:currDosageFormId,
      DciId:currDciId,
      DemanderId:currDemanderId,
      ProductStatusId:currProductStatusId,
      BrandsWithGTIN:event.target.checked,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setbBrandsWithGTIN(event.target.checked);
    //setChecked(event.target.checked);
    refetch();
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ProductsListBrandExport" +
        "&reportType=" + reportType +
        "&menukey=" +  menukey +
        "&lan=" + localStorage.getItem("LangCode") +
        "&StrengthId=" + currStrengthId +
        "&PacksizeId=" + currPacksizeId +
        "&DosageFormId=" + currDosageFormId +
        "&DemanderId=" + currDemanderId +
        "&ProductStatusId=" + currProductStatusId +
        "&BrandsWithGTIN=" + bBrandsWithGTIN +
        "&DciId=" + currDciId
        + "&TimeStamp=" + Date.now()
    );
  };

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    viewBrand(rowData);
  };

  /* =====End of Excel Export Code==== */

  const viewBrand = (data) => {
    let route = `certificate-manufacturer-authorization/brand-view/${data.Id}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <ViewList
          onClick={() => {
            viewBrand(rowData);
          }}
        />
      </>
    );
  }

  const editManufacturerAuthorization = (data) => {
    let route = `certificate-manufacturer-authorization/manufacturer-authorization/${data.BrandNo}`;
    props.history.push(route);
  };
  
  function ActionButtonForManufacAutho(props) {
    const rowData = props.cell._cell.row.data;
    let Manufacturer_Authorization = rowData.ManufacturerAuthorization;

    let pdffileColor = '';
    if(Manufacturer_Authorization != null){
      pdffileColor = 'theme_color';
    }else{
      pdffileColor = 'black_color';
    }

    return (
      <>
        <div className={pdffileColor}>
          <CloudUploadOutlined
            onClick={() => {
              editManufacturerAuthorization(rowData);
            }}
          />
        </div>
      </>
    );
  }

  const editCertificate = (data) => {
    let route = `certificate-manufacturer-authorization/certificate/${data.BrandNo}`;
    props.history.push(route);
  };

  function ActionButtonForCertificate(props) {
    const rowData = props.cell._cell.row.data;
    let CertificateName = rowData.CertificateName;

    let pdffileColor = '';
    if(CertificateName != null){
      pdffileColor = 'theme_color';
    }else{
      pdffileColor = 'black_color';
    }

    return (
      <>
        <div className={pdffileColor}>
          <CloudUploadOutlined
            onClick={() => {
              editCertificate(rowData);
            }}
          />
        </div>
      </>
    );
  }

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      frozen:true,
    },
    {
      title: "",
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 40,
      frozen:true,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Authorization"],
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 100,
      frozen:true,
      formatter: reactFormatter(<ActionButtonForManufacAutho />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Certificate"],
      field: "custom",
      hozAlign: "center",
      headerSort: false,
      width: 90,
      frozen:true,
      formatter: reactFormatter(<ActionButtonForCertificate />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
      frozen:true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Generic"],
      field: "ItemName",
      headerFilter: true,
      width: 250,
      frozen:true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      width: 140,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["SIGIP Code"],
      field: "SIGIPCode",
      width: 115,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Status"],
      field: "ProductStatus",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Brand Code"],
      field: "BrandCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Commercial"],
      field: "CommercialName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Strength"],
      field: "StrengthName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pack Size"],
      field: "PacksizeName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Form"],
      field: "DosageFormName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["DCI"],
      field: "DciName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander"],
      field: "DemanderName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Demander Address"],
      field: "DemanderAddress",
      width: 165,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pays Exploitant"],
      field: "PaysExploitant",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Intitule Fabriquant"],
      field: "IntituleFabriquant",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Pays Fabricant"],
      field: "PaysFabricant",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Representant Localou Exploitant"],
      field: "RepresentantLocalouExploitant",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Voie Administration"],
      field: "VoieAdministration",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date Expiration DeLAMM"],
      field: "DateExpirationDeLAMM",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Cond Delivrance"],
      field: "CondDelivrance",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Code AMM"],
      field: "CodeAMM",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PGHT Presentation"],
      field: "PGHTPresentation",
      width: 135,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["PGHT Prix Public"],
      field: "PGHTPrixPublic",
      width: 135,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Date De Debut"],
      field: "DateDeDebut",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date Expiration"],
      field: "DateExpiration",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Classe Therapeutique"],
      field: "ClasseTherapeutique",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["GPC Code"],
      field: "GPCCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["EA GTIN"],
      field: "EAGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["EA Qty"],
      field: "EAQty",
      width: 85,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["EA Child GTIN"],
      field: "EAChildGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PK GTIN"],
      field: "PKGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PK Qty"],
      field: "PKQty",
      width: 85,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["PK Child GTIN"],
      field: "PKChildGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["CA GTIN"],
      field: "CAGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["CA Qty"],
      field: "CAQty",
      width: 85,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["CA Child GTIN"],
      field: "CAChildGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PL GTIN"],
      field: "PLGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["PL Qty"],
      field: "PLQty",
      width: 85,
      headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["PL Child GTIN"],
      field: "PLChildGTIN",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["SOBAPS Code"],
      field: "SOBAPSCode",
      width: 130,
      headerFilter: true,
    },
    {
      title: "Id",
      field: "Id",
      visible: false,
      width: 80 
    }
  ];

  return (
    <>
      <div className={classes.productPageTitle}>
 
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Products List"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      id="StrengthId"
                      name="StrengthId"
                      options={StrengthList}
                      onChange={(event, newValue) => handleStrengthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Strength"]}
                        variant="standard"
                      />
                      )}
                    />
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        autoHighlight
                        id="PacksizeId"
                        name="PacksizeId"
                        options={PacksizeList}
                        onChange={(event, newValue) => handlePacksizeChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Pack Size"]}
                          variant="standard"
                        />
                        )}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        autoHighlight
                        id="DosageFormId"
                        name="DosageFormId"
                        options={DosageFormList}
                        onChange={(event, newValue) => handleDosageFormChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Form"]}
                          variant="standard"
                        />
                        )}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={2} sm={2}>
                <div className="float-right">
                    <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Brand']+": "+BrandCount}</h4>
                  </div>
              </Grid>

              <Grid item xs={1} sm={1}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button> */}

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        autoHighlight
                        id="DciId"
                        name="DciId"
                        options={DciList}
                        onChange={(event, newValue) => handleDciChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["DCI"]}
                          variant="standard"
                        />
                        )}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        autoHighlight
                        id="DemanderId"
                        name="DemanderId"
                        options={DemanderList}
                        onChange={(event, newValue) => handleDemanderChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Demander"]}
                          variant="standard"
                        />
                        )}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                        autoHighlight
                        id="ProductStatusId"
                        name="ProductStatusId"
                        options={ProductStatusList}
                        onChange={(event, newValue) => handleProductStatusChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Status"]}
                          variant="standard"
                        />
                        )}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={3} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    //checked={checked} 
                    onChange={handleBrandGTINChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["Brands with GTIN"]}
              />

              </Grid>

              {/* <Grid item xs={3} sm={3}>
                <div className="float-right">
                    <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Brand']+": "+BrandCount}</h4>
                  </div>
              </Grid> */}

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
          {isLoading && <LoadingSpinnerOpaque />}
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={450}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateManufacturer;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
