import React, { useState,useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";
import * as api from '../../../actions/api';
import StockConfigurationFormData from './StockConfigurationFormData.js';
 

import { useTranslation } from "react-i18next";
import { set } from "date-fns";

 

const EditStockConfiguration = (props) => {

  
     
const StoreId = localStorage.getItem("StoreId");
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;



  const FacilityId=localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage")); 
  const menukey = "initial-stock-configuration";
  const lan = localStorage.getItem("LangCode");
  

  const { t, i18n } = useTranslation();

  
const [dirty, setDirty] = useState(false);

const [IsCompleted, setIsCompleted] = useState("0");
const [CurrStoreId, setCurrStoreId] = useState(localStorage.getItem("StoreId"));
const [ManyTableData, setManyTableData] = useState([]);
const [StockTakeId, setStockTakeId] = useState("0");
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
const [isFirstPhysical, setIsFirstPhysical] = useState(0);
const [bFirst, setBFirst] = useState(true); 
const [deletedDataSet, setDeletedDataSet] = useState([]);

const [ItemGroupId, setItemGroupId] = useState("0");

const handleDataTableChange = (e) => {

 };

 const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [selecteEnddDate, setSelecteEnddDate] = useState(
 null
);


const [formData, setFormData] = useState({
  StockTakeDate: selectedDate,
  CompleteDate: '',
  ApprovedBy: '',
  PreparedBy: '',
  IsCompleted:0,
  ManyJsonSave:"" ,
  StoreId:CurrStoreId,
  FacilityId:props.match.params.id,
  PriceMarkupMin:0,
  PriceMarkupMax:0
});


const [errorObject, setErrorObject] = useState({});
const { id } = useParams();

const handleRDateChange = (date, field) => {
 // setDirty(true);
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


const { data,refetch } = useQuery(['stock-take', id], () =>

    api.getInitialStockTakeInv({FacilityId:id,ItemGroupId:ItemGroupId,StoreId:formData.StoreId}).then((res) => {
      setDirty(true);
//const { data,refetch } = useQuery(['stock-take', id,], () =>
  //api.getInitialStockTakeInv(formData).then((res) => {
    setStockTakeId(props.match.params.id);
     setFormData(res.data); 
     setIsCompleted(res.data.IsCompleted);
     setManyTableData(res.data.ManyJsonSave);
     setUpdatedDetailGridData(res.data.ManyJsonSave);
    // setIsFirstPhysical(res.data.IsFirstPhysical);
    // setBFirst(true);
    setDeletedDataSet([]);
    setDirty(false);
    return res.data;

  }), {
  refetchOnWindowFocus: false,  
  enabled: Boolean(id)
}) 

/*
const { isLoading, mutate:mutateUpdate } = useMutation(api.updateInitialStockTakeInv, {
  onSuccess: (data) => {
  //  setDirty(true);
    if (data.status == 200) {
      
      if(data.data.success == 1){

        
        if(data.data.IsCompleted==1){
          
          
           mutatePost({FacilityId:props.match.params.id,
            CompleteDate:formData['CompleteDate'],
            PreparedBy:formData['PreparedBy'],
            ApprovedBy:formData['ApprovedBy']
          
          });

        
        }else{

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message ,
            msgtype: data.data.success
          });

        }

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message ,
          msgtype: data.data.success,
        });
      }


      
      refetch();

    }else{
      
      refetch();

      props.openNoticeModal({
        isOpen: true,
        msg: data.message ,
        msgtype: data.success,
      });
    }
  }
})

const { isLoading1, mutate:mutatePost } = useMutation(api.postInitialStockTake, {
  onSuccess: (data) => { 
    if (data.status == 200) { 
      refetch();
      setIsCompleted(1);
      
      if(data.success==1){ 


        
        api.getDependencyCombo({FacilityId:props.match.params.id,task:'getFacilityListEqual99'})
        .then((response) => { 
          localStorage.setItem(
            "FacilityId",
            response.datalist.getFacilityListEqual99[0].id
          );
          localStorage.setItem(
            "DefaultFacilityId",
            response.datalist.getFacilityListEqual99[0].id
          );

          localStorage.setItem(
            "getFacilityListEqual99",
            JSON.stringify(response.datalist.getFacilityListEqual99)
          ); 
          
        });
        
         
        setDeletedDataSet([]);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       } 

    }
    else{
      refetch();
      setIsCompleted(0);
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success
      });

    }
  }
})

*/

const deleteReceiveInv=(data,deletesetdata)=>{ 

  //setManyTableData(data);
  setUpdatedDetailGridData(data);
  setDeletedDataSet(deletesetdata);
   
  

}


const StoreOnchangeFn = (StoreValue) => {
   
    let data = { ...formData };
    data['StoreId'] = StoreValue;            
    setFormData(data); 
    setCurrStoreId(StoreValue); 
};
const handleAdd = (NonOrderProductData) => {
      
  setManyTableData(NonOrderProductData);
  setUpdatedDetailGridData(NonOrderProductData);
 
  

};

/*
const setUpdatedDetailsGridDataHandler = (rows,deletedsetdata) => {

  if (validateForm(formData,1)) { 
    let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedsetdata}; 
    mutateUpdate(fDtat); 
    } 
 
}

*/

const handleChange = (e) => {
  const { name, value } = e.target;
  
    let data = { ...formData };
    data[name] = value;            
    setFormData(data);
    setDirty(true);
  setErrorObject({ ...errorObject, [name]: null });
};

const filterHandleChange = (e) => {
  setItemGroupId(e) ;
};

const handleReset = () => {
  setFormData({
    StockTakeDate: selectedDate,
    CompleteDate: selecteEnddDate,
    ApprovedBy: '',
    PreparedBy: '',
    IsCompleted:0,
    ManyJsonSave:"" ,
    PriceMarkupMin:0,
    PriceMarkupMax:0
  
  });
};

const validateForm = (formData,checkSupplierInv) => {
  //let validateFields = [ "StockTakeDate", "PreparedBy"]
  let validateFields = checkSupplierInv==2?[ "StockTakeDate", "PreparedBy", "ApprovedBy", "CompleteDate"]:[ "StockTakeDate", "PreparedBy"]
  let errorData = {}
  let isValid = true
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = "field is Required !"
      isValid = false
    }
  })
  setErrorObject(errorData);
  return isValid
}

/*
const handleUpdate = async (e) => {
  if (validateForm(formData,1)) {
    let newarray = ManyTableData.filter(element => parseFloat(element.PhysicalQty)>0);
    
     
  let FinalData={...formData,'ManyJsonSave':newarray,'deletedDataSet':deletedDataSet}; 
  mutateUpdate(FinalData); 
  }
};

*/

/*
const handlePost = async (rows,deletedsetdata) => {  

  if (validateForm(formData,2)) {
    
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {

        
          let newarray = rows.filter(element => parseFloat(element.PhysicalQty)>0); 
          
          let FinalData={...formData,'ManyJsonSave':newarray,"IsCompleted":1,'deletedDataSet':deletedsetdata}; 
          mutateUpdate(FinalData);
          
          setManyTableData(rows); 
          
          

        }else{
          setIsCompleted(0);
          let route = `${StockTakeId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

    
  }
 
};

*/






function checkValidateForm(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function checkValidateFormPost(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,2)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function successSave(bStockUpdated){
  if(bStockUpdated !==1 ){
    refetch();
  }
};

function stockPosted(val){
  setIsCompleted(val);
  // setDeletedDataSet([]);
};


useEffect(() => { 

  
  console.log(CurrStoreId)
  refetch();   
}, [CurrStoreId]); 


useEffect(() => {
  refetch();
 
}, [ItemGroupId]);


  return (
    <>
      <StockConfigurationFormData 
      errorObject={errorObject} 
      addProductForm={false} 
      formData={formData} 
      selecteEnddDate={selecteEnddDate} 
      handleChange={handleChange} 
      handleRDateChange={handleRDateChange} 
      handleReset={handleReset} 
      //handleUpdate={handleUpdate}  

      handleAdd={handleAdd} 

      ManyTableData={updatedDetailGridData} 
      handleDataTableChange={handleDataTableChange} 
      IsCompleted={IsCompleted} 
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
     // handlePost={handlePost}
      bFirst={bFirst}
      isFirstPhysical={isFirstPhysical}
      deleteReceiveInv={deleteReceiveInv}  
      StoreOnchangeFn={StoreOnchangeFn}  
      refetch={refetch} 
      dirty={dirty} 
      filterHandleChange={filterHandleChange} 

      checkValidateForm={checkValidateForm}
      checkValidateFormPost={checkValidateFormPost}
      successSave={successSave}
      stockPosted={stockPosted}    
      {...props} />
    </>
  );
};

export default EditStockConfiguration;