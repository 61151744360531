import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormLabel,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { ColorPicker, createColor } from 'material-ui-color';
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "mos-type-for-facility-entry";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}



const MOSRangeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));

  //const [ColorCode, setColor] = useState(createColor("#00aabb"));
  const handleChangeColor = (name) => {

    // if (Object.keys(name).length == 0) {
    //   formData["ColorCode"]=name.css.backgroundColor;
    //   handleChangeColorAdd(name.css.backgroundColor);
    // }else{
    //   formData["ColorCode"]=name;
    //   handleChangeColorAdd(name);
    // }

    if (typeof (name) == "string") {
      formData["ColorCode"] = name;
      handleChangeColorAdd(name);
    } else {
      formData["ColorCode"] = name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }




    //setColor(name.css.backgroundColor);
  };



  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['MOS Range - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['MOS Range Entry Form']} />
              <CardContent>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={3} sm={3}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Facility Level"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.FLevelId}
                            labelId="demo-simple-select-helper-label"
                            id="FLevelId"
                            name="FLevelId"
                            value={formData.FLevelId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Facility Level"]}
                            </MenuItem>

                            {FlevelList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FLevelId}>
                            {errorObject.FLevelId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid item xs={3} sm={3}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Facility Type"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.FTypeId}
                            labelId="demo-simple-select-helper-label"
                            id="FTypeId"
                            name="FTypeId"
                            value={formData.FTypeId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Facility Type"]}
                            </MenuItem>

                            {FTypeList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FTypeId}>
                            {errorObject.FTypeId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.MosTypeName}
                          helperText={errorObject.MosTypeName}
                          required
                          id="MosTypeName"
                          name="MosTypeName"
                          label={DispensingLanguage[lan][menukey]['MOS Type']}
                          inputProps={{ maxLength: 50 }}
                          value={formData.MosTypeName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.MinMos}
                          helperText={errorObject.MinMos}
                          required
                          id="MinMos"
                          name="MinMos"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Minimum MOS']}
                          value={formData.MinMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.MaxMos}
                          helperText={errorObject.MaxMos}
                          required
                          id="MaxMos"
                          name="MaxMos"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Maximum MOS']}
                          value={formData.MaxMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.MosLabel}
                          helperText={errorObject.MosLabel}
                          required
                          id="MosLabel"
                          name="MosLabel"
                          label={DispensingLanguage[lan][menukey]['MOS Label']}
                          inputProps={{ maxLength: 10 }}
                          value={formData.MosLabel}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.IconMos}
                          helperText={errorObject.IconMos}
                          required
                          id="IconMos"
                          name="IconMos"
                          label={DispensingLanguage[lan][menukey]['Icon Mos']}
                          value={formData.IconMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.IconMos_Width}
                          helperText={errorObject.IconMos_Width}
                          required
                          id="IconMos_Width"
                          name="IconMos_Width"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Icon Mos Width']}
                          value={formData.IconMos_Width}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.IconMos_Height}
                          helperText={errorObject.IconMos_Height}
                          required
                          id="IconMos_Height"
                          name="IconMos_Height"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Icon Mos Height']}
                          value={formData.IconMos_Height}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} className="marginTop10">

                        <ColorPicker
                          error={errorObject.MaxMos}
                          helperText={errorObject.MaxMos}
                          required
                          name='ColorCode'
                          defaultValue='#00aabb'
                          value={formData.ColorCode}
                          onChange={handleChangeColor}

                        />

                      </Grid>





                    </Grid>
                  </Grid>



                </Grid>


              </CardContent>
            </Card>
          </Grid>


          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/mos_type_for_facility")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/mos_type_for_facility")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}

              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default MOSRangeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
