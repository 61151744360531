import React, { useState, Component, useEffect, useContext } from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Typography,
  TextField
} from "@material-ui/core";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import {
  Launch
} from "@material-ui/icons";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import * as Service from "../../../services/Service.js";
import Constants from "services/Constants.js";

import { UserContext } from "../../../context/user-info-context";
import { getDefaultMonthYear } from "../../../services/Common";
//Import Facility Reporting Rate
import FacilityReportingRate from "services/FacilityReportingRate";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";


const FacilityStockStatusbyProduct = (props) => {
  const mapStyles = {
    width: '92%',
    height: '90%',
    overflow: "hidden !important",
  };

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "reports";
  const { t, i18n } = useTranslation();
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;


  const DepartMent_Name = JSON.parse(localStorage.getItem("Regionlist"));
  let All_DepartMent_Name = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const DepartMentName = [All_DepartMent_Name].concat(DepartMent_Name);


  const FLevelList = JSON.parse(localStorage.getItem("FlevelList"));
  // let All_FLevel_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const FLevelList = [All_FLevel_List].concat(FLevel_List);

  let Temp_Zone_List = JSON.parse(localStorage.getItem("Zonelist"));
  const [TempZonelist, setZoneListLocal] = useState(Temp_Zone_List);
  let All_Zone_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Zonelist = [All_Zone_List].concat(TempZonelist);

  let Temp_Commune_List = JSON.parse(localStorage.getItem("Communelist"));
  const [TempCommuneList, setCommunelist] = useState(Temp_Commune_List);
  let All_Commune_List = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const Communelist = [All_Commune_List].concat(TempCommuneList);


  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroup"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  let TempItemList = JSON.parse(localStorage.getItem("getshipmentItem"));
  const [Productlist, setProductlist] = useState([]);
  // let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const Productlist = [All_Product].concat(tmpProductlist);

  const tmpmostypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const MosTypelist = [All_Product].concat(tmpmostypelist);

  const MonthList = JSON.parse(localStorage.getItem("MonthListReport"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));




  const [currDepartmentId, setDepartmentId] = useState(DepartMentName[0].id);
  const [currDepartmentName, setDepartmentName] = useState(DepartMentName[0].name);
  const [currZoneId, setZoneId] = useState(Zonelist[0].id);
  const [currZonename, setZonename] = useState(Zonelist[0].name);
  const [currCommuneId, setcurrCommuneId] = useState(Communelist[0].id);
  const [currCommuneame, setCommunename] = useState(Communelist[0].name);
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);
  const [currFLevelId, setFLevelId] = useState(FLevelList[0].id);
  const [currFLevelName, setFLevelName] = useState(FLevelList[0].name);

  const [currMostypeId, setMostypeId] = useState(MosTypelist[0].id);
  const [currMostypeName, setMostypeName] = useState(MosTypelist[0].name);
  const [acValue, setACValue] = useState({ id: Productlist.length > 0 ? Productlist[0].id : "", name: Productlist.length > 0 ? Productlist[0].name : "" });


  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);

  const [currYearId, setYearId] = useState(currentDate.getFullYear());

  const [acZoneValue, setACZoneValue] = useState({ id: Zonelist[0].id, name: Zonelist[0].name });

  const [acCommnueValue, setACCommnueValue] = useState({ id: Communelist[0].id, name: Communelist[0].name });


  // Department Change
  const handleDepartmentChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }


    if (rowId !== currDepartmentId) {
      setDepartmentId(rowId);
      setDepartmentName(rowName);
    }
    let ZoneParam = { action: "gZonelist", RegionId: newValue.id, menukey: "" }
    cZoneList(ZoneParam);


  };


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);
          setACZoneValue({ id: Zonelist[0].id, name: Zonelist[0].name });
          setZoneId(Zonelist[0].id);
          setZonename(Zonelist[0].name);
          // console.log("Dta list", data.data.RegionId);

        }

      },
    }
  );





  // Zone Change
  const handleZoneChange = (event, newValue) => {
    setACZoneValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currZoneId) {
      setZoneId(rowId);
      setZonename(rowName);

    }

    let CParam = { action: "gCommunelist", RegionId: currDepartmentId, ZoneId: newValue.id, menukey: "" }
    cCommuneList(CParam);

  };

  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);
          setACCommnueValue({ id: Communelist[0].id, name: Communelist[0].name });
          setcurrCommuneId(Communelist[0].id);
          setCommunename(Communelist[0].name);

        }

      },
    }
  );



  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setACCommnueValue(newValue);
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;

    }
    if (rowId !== currCommuneId) {
      setcurrCommuneId(rowId);
      setCommunename(rowName);

    }
  };


  // mostype  Change
  const handleMosTypeChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setMostypeId(rowId);
      setMostypeName(rowName);


    }
  };



  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';

    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
      setMonthName(rowName);


    }
  };


  // Year Change
  const handleYearChange = (event, newValue) => {
    let rowId = '';

    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);



    }
  };


  React.useEffect(() => {

    if (currProductGroupId == ProductGroup[0].id) {

      let ProductlistParam1 = { action: "gProductList", ItemGroupId: currProductGroupId, menukey: "" }
      cProductList(ProductlistParam1);
    }


  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = '';
    let rowName = '';
    if (newValue == null) {
      rowId = '';
      rowName = '';
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }

    let ProductlistParam = { action: "gProductList", ItemGroupId: newValue.id, menukey: "" }
    cProductList(ProductlistParam);
  };

  const { mutate: cProductList } = useMutation(
    api.aProductList,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          // console.log("Dta list", data.data.datalist);

          setProductlist(data.data.datalist);
          setACValue({ id: data.data.datalist.length > 0 ? data.data.datalist[0].id : "", name: data.data.datalist.length > 0 ? data.data.datalist[0].name : "" });


        }

      },
    }
  );

  // product Change
  const handleProductChange = (event, newValue) => {

    setACValue(newValue);

  };


  // FLevel  Change
  const handleFLevelChange = (event, newValue) => {
    let rowId = '';
    let rowname = '';
    if (newValue == null) {
      rowId = '';
      rowname = '';
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    if (rowId !== currFLevelId) {
      setFLevelId(rowId);
      setFLevelName(rowname);
    }

  };







  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);

  let params3 = {
    menukey: menukey,
    action: "FacilityReportingRate",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneId,
    DistrictId: currCommuneId,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    Year: currYearId,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProvinceName: currDepartmentName
  };

  const { data: reportingrate } = useQuery(
    [params3],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setReportingRate(data.data.datalist);
        setFLvelelList(data.data.fLevelList);

        // console.log("rate", data.data.datalist);
        // console.log("fl", data.data.fLevelList);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [getMosTypelist, setMosTypelist] = useState([]);


  let param1 = {
    menukey: menukey,
    action: "GetMosScale",
    lan: lan,
    FLevelId: currFLevelId,
    FLevelName: currFLevelName,

  };

  const { data: data1 } = useQuery(
    [param1],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setMosTypelist(data.data.datalist);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "FacilityStockStatusTable",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneId,
    DistrictId: currCommuneId,
    FLevelId: currFLevelId,
    ItemGroupId: currProductGroupId,
    ItemId: acValue.id,
    MonthId: currmonthId,
    MosTypeId: currMostypeId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    FLevelName: currFLevelName,
    ProductName: acValue.name,
    MosTypeName: currMostypeName,
  };

  const { data } = useQuery(
    [params],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data.data.datalist);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [MapLatitudeLongitude, setMapLatitudeLongitude] = useState([]);
  const [MapDataTable, setMapDataTable] = React.useState(true);
  // For Map
  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    markerParams: {}
  });

  const onMarkerClick = (props, marker, e) => {
    console.log("kkkkkkkjjj",props.params);
    setState({
      selectedPlace: props,
      markerParams: props.params,
      activeMarker: marker,
      showingInfoWindow: true
    })
  };

  const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        selectedPlace: {},
        showingInfoWindow: false,
        activeMarker: null,
        markerParams: {}
      });
    }
  };



  let ParamList = {
    menukey: menukey,
    action: "FacilityStockStatusbyproductMap",
    lan: lan,
    RegionId: currDepartmentId,
    ZoneId: currZoneId,
    DistrictId: currCommuneId,
    FLevelId: currFLevelId,
    ItemGroupId: currProductGroupId,
    ItemId: acValue.id,
    MonthId: currmonthId,
    MosTypeId: currMostypeId,
    Year: currYearId,
    ProvinceName: currDepartmentName,
    ZoneName: currZonename,
    BCZName: currCommuneame,
    ProductGroup: currItemGroupname,
    MonthName: currMonthName,
    FLevelName: currFLevelName,
    ProductName: acValue.name,
    MosTypeName: currMostypeName,
  }

  const { data:datamap ,refetch} = useQuery(
    [ParamList],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setMapLatitudeLongitude(data.data.datalist);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  // const getFacilityData = () => {

  //   setMapDataTable(false);
  //   Service.default
  //     .postApi("source/api/api_pages/EsiglProductStockReport.php", ParamList)
  //     .then((res) => {



  //       setMapLatitudeLongitude(res.datalist);


  //     })
  //     .catch((err) => { });
  // }

  React.useEffect(() => {
    refetch();
    // getFacilityData();
  }, [currDepartmentId, currZoneId, currCommuneId]);

  // End Map




  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: true,

    },


    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "Supplier",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,


    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClosingStock",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 80,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",

      width: 70,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 70,
      formatter: "money",
    },




  ];


  // const current = new Date();
  // const currentDate = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=FacilityStockStatusreportList" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      currDepartmentId +
      "&ZoneId=" +
      currZoneId +
      "&DistrictId=" +
      currCommuneId +
      "&FLevelId=" +
       currFLevelId +
       "&ItemGroupId=" +
       currProductGroupId +
       "&ItemId=" +
       acValue.id +
       "&MonthId=" +
       currmonthId +
       "&MosTypeId=" +
       currMostypeId +
       "&Year=" +
       currYearId +
       "&ProvinceName=" +
       currDepartmentName +
       "&ZoneName=" +
       currZonename +
       "&BCZName=" +
       currCommuneame +
       "&ProductGroup=" +
       currItemGroupname +
       "&MonthName=" +
       currMonthName +
       "&FLevelName=" +
       currFLevelName +
       "&ProductName=" +
       acValue.name +
       "&MosTypeName=" +
       currMostypeName +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */
  let temFTypeId = "";
  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding ${classes.Container}`}>
        <div className={classes.PageTitle}>

          <div className="d-flex justify-product mb-1">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Facility Stock Status by Product"]}
                </div>
                <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/svdl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]['Back_to_List']}
                  </Button>
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DepartMentName"
                      disableClearable
                      options={DepartMentName}
                      // disabled={UserRole == 1 ? false : true}
                      onChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={DepartMentName[DepartMentName.findIndex(DepartMentName => DepartMentName.id == currDepartmentId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Zonelist"
                      disableClearable
                      options={Zonelist}
                      value={acZoneValue}
                      onChange={(event, newValue) => handleZoneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Zonelist[Zonelist.findIndex(Zonelist => Zonelist.id == currZoneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={acCommnueValue}
                      onChange={(event, newValue) => handleCommuneChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={Communelist[Communelist.findIndex(Communelist => Communelist.id == currCommuneId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Commune Name"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FLevelList"
                      disableClearable
                      options={FLevelList}
                      onChange={(event, newValue) => handleFLevelChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FLevelList[FLevelList.findIndex(FLevelList => FLevelList.id == currFLevelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Level"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


              </Grid>
              <Grid container>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) => handleProductChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField

                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MosTypelist"
                      disableClearable
                      options={MosTypelist}
                      onChange={(event, newValue) => handleMosTypeChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MosTypelist[MosTypelist.findIndex(MosTypelist => MosTypelist.id == currMostypeId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Mos Type"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>


            </CardContent>
          </Card>

          {/* Facility Reporting Rate */}
          <FacilityReportingRate
            ReportingLabelList={getFLvelelList}
            ReportingValueList={getReportingRate}
            lan={lan}
            menukey={menukey}
          />
          {/* Facility Reporting Rate */}


          {/* new row */}
          <div className="row">

            <div className="col-md-6 mb-4">


              <Card className="sw_card Map_Style">

                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facility Stock Status by Product Map"]}
                />


                <CardContent>

                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Map
                        google={props.google}
                        zoom={7}
                        style={mapStyles}
                        initialCenter={
                          {
                            lat: 9.314623823,
                            lng: 2.31184834
                          }
                        }

                      >
                        {
                          MapLatitudeLongitude.map((item, index) => {
                            //console.log("Im UUUUUUUUUU",item.FTypeIcon);
                            //let gIcon = item.FTypeIcon;
                            /*
                            let finalUrl = "";
                            if(currRegionId==0){
                              finalUrl = "http://localhost/epharmacie/src/assets/img/marker_icon.png";
                           
                            }else{
                               finalUrl = "http://localhost/epharmacie/src/assets/img/"+item.FTypeIcon;
                             }
                  
                             */


                            return (
                              <Marker
                                onClick={onMarkerClick}
                                params={item}
                                position={{ lat: item.Latitude, lng: item.Longitude }}
                                icon={{

                                  url: require("assets/img/marker_icon.png"),
                                  //url: finalUrl,
                                  //  anchor: { x: 16, y: 16 },
                                  //  scaledSize: { height: 16, width: 16 },  

                                }}
                              />
                            );
                          })

                        }

                        <InfoWindow
                          marker={state.activeMarker}
                          visible={state.showingInfoWindow}
                          onClose={onClose}
                        >
                          <div>

                            <TableContainer>
                              <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["Facility Name"]}
                                    </TableCell>
                                    <TableCell >
                                      {state.markerParams.FacilityName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                      align="left"
                                    >
                                      {DispensingLanguage[lan][menukey]["Closing Balance"]}
                                    </TableCell>

                                    <TableCell > {state.markerParams.ClosingStock}  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                      align="left"
                                    >
                                      {DispensingLanguage[lan][menukey]["AMC"]}
                                    </TableCell>
                                    <TableCell >  {state.markerParams.AMC} </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["MOS"]}
                                    </TableCell>
                                    <TableCell > {state.markerParams.MOS} </TableCell>

                                  </TableRow>

                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["Supplier"]}
                                    </TableCell>
                                    <TableCell > {state.markerParams.Supplier} </TableCell>

                                  </TableRow>

                                 

                                </TableBody>

                              </Table>
                            </TableContainer>


                          </div>
                        </InfoWindow>


                      </Map>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>



            </div>


            <div className="col-md-6 mb-4">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facility Stock Status"]}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }


                />

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    height={540}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}

          <Card className="sw_card sw_filter_card">
                <CardContent className="sw_filterCardContent">
                <div dangerouslySetInnerHTML={{__html: getMosTypelist}} />
                  {/* <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className={classes.mylegend_area}>
                            {

                              getMosTypelist.map((item, index) => {

                                if (temFTypeId != item.FTypeId) {
                                  temFTypeId = item.FTypeId;
                                  return (

                                    <div className={classes.legend_group} >{item.FTypeName}</div>


                                  );


                                } else {
                                  return (
                                    <div className="my_legend">
                                      <div
                                        className="mylegend_color"
                                        style={{ background: item.ColorCode }}
                                      ></div>
                                      <div className="mylegend_label">{item.name}</div>
                                      <div className="mylegend_val">
                                        {DispensingLanguage[lan][menukey]["MOS"]}{":"} {item.MosLabel}
                                      </div>
                                    </div>
                                  );
                                }


                              })}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid> */}
                </CardContent>
              </Card>


        </div>
      </div>
    </>
  );
};


export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };

  // ...
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCD7OEdGUC1V__0-mBJIoYifI5UtEILYbg'
})(FacilityStockStatusbyProduct);


// export default FacilityStockStatusbyProduct;

const useStyles = makeStyles({
  Container: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },

  mylegend_area: {
    width: "100%",
    overflow: "hidden",
    float: "left",
    clear: "both",
    margin: "2px 0px",
    border: "1px solid #eeecec"
  },
  legend_group: {
    width: "100%",
    background: "#f0eded",
    paddingLeft: "5px",
    clear: "both",
    height: "20px"
  },
  mylegend: {
    width: "17%",
    float: "left",

    fontsize: "inherit",
    fontfamily: "inherit",
    color: "inherit"
  }

});